import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

const EditParagraphModal = ({ content, onCloseModal }) => {
  return (
    <div className='editParaModalOuterCon'>
      <div className='editParaModalInnerCon'>
        {' '}
        <FontAwesomeIcon
          //           className='RentPlatformHeadIcon1'
          className='icon'
          icon={faXmarkCircle}
          onClick={onCloseModal}
        />
      </div>{' '}
      <p className='editModalPlsCopy'>
        <b>
          Please, Copy and paste all your paragraphs into their input fields
        </b>
      </p>
      {content.map((x, i) => (
        <div key={`${i}`}>
          <p>
            <b style={{ marginRight: '5px' }}>{i + 1})</b>
            {x}
          </p>
        </div>
      ))}
    </div>
  )
}

export default EditParagraphModal
