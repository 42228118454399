import React, { useState, useEffect } from 'react'
import RentDetailsHead from '../components/RentAndSaleDetails/RentDetailsHead'
import RentDetailsBody from '../components/RentAndSaleDetails/RentDetailsBody'
import RentPoster from '../components/RentAndSaleDetails/RentPoster'
import RentDetailsResponse from '../components/RentAndSaleDetails/RentDetailsResponse'
import RentalRelatedTopic from '../components/RentAndSaleDetails/RentalRelatedTopic'
import {
  getRentPlatfrom,
  getRentPlatfromById,
  editRentPlatfromResponse,
} from '../Services/rentPlatformService'
import { getUserById } from '../Services/registerService'
//import LottieLoader from '../components/contactCaptivator/LottieLoader'
import { getCurrentUser } from '../Services/authService'
import ProfileSpinner from '../components/profile/ProfileSpinner'

const RentAndSaleDetails = ({ match }) => {
  const [platform, setPlatfrom] = useState({})
  const [poster, setPoster] = useState({})
  const [sameSubjects, setSameSubject] = useState([])
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState(0)
  const [responseData, setResponseData] = useState({
    love: false,
    helpful: false,
    cool: false,
  })

  useEffect(() => {
    getData()
    window.scrollTo(0, 0)
  }, [])
  const getData = async () => {
    try {
      setLoading(true)
      const ID = match.params.id
      const { data } = await getRentPlatfromById(ID)
      const { data: user } = data && (await getUserById(data.user_id))
      const { data: platforms } = await getRentPlatfrom()
      const others = platforms.filter((x) => x.subject === data.subject)
      const hisLike =
        data &&
        data.response_type &&
        data.response_type.filter(
          (x) => x.user == getCurrentUser() && getCurrentUser().id
        )

      if (hisLike && hisLike.length > 0) setResponseData(hisLike[0])
      setSameSubject(others)
      setPoster(user)
      setPlatfrom(data)
      setResponse(data.response_type ? data.response_type.length : 0)
      setLoading(false)
    } catch (ex) {}
  }

  const onClickLove = async () => {
    const data = {
      love: true,
      helpful: false,
      cool: false,
      user: getCurrentUser() && getCurrentUser().id,
    }
    setResponseData(data)
    getCurrentUser() && (await editRentPlatfromResponse(data, match.params.id))
  }
  const onClickHelpful = async () => {
    const data = {
      love: false,
      helpful: true,
      cool: false,
      user: getCurrentUser() && getCurrentUser().id,
    }
    setResponseData(data)
    getCurrentUser() && (await editRentPlatfromResponse(data, match.params.id))
  }

  const onClickCool = async () => {
    const data = {
      love: false,
      helpful: false,
      cool: true,
      user: getCurrentUser() && getCurrentUser().id,
    }
    setResponseData(data)
    getCurrentUser() && (await editRentPlatfromResponse(data, match.params.id))
  }

  if (loading)
    return (
      <div className='homeSpinnerContainer'>
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    )
  return (
    <div style={{ width: '100%' }}>
      <RentDetailsHead item={platform} />
      <RentDetailsBody item={platform} />
      <RentDetailsResponse
        onClickLove={onClickLove}
        onClickCool={onClickCool}
        onClickHelpful={onClickHelpful}
        responses={response}
        responseData={responseData}
      />
      <RentPoster user={poster} />
      {sameSubjects.filter((x) => x.id !== platform.id).length > 0 && (
        <RentalRelatedTopic
          items={sameSubjects.filter((x) => x.id !== platform.id)}
        />
      )}
    </div>
  )
}

export default RentAndSaleDetails
