import React from "react";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";

import "./listing.css";

const TitleListing = ({ listings = 0 }) => {
  const { t } = useTranslation();
  return (
    <div className="myListTitle">
      <h6>
        {t("Your_Listings")} <Chip label={listings} />
      </h6>
    </div>
  );
};

export default TitleListing;
