import React, { useState } from 'react'
import Spinner from '../components/Authentication/Spinner'
import { useTranslation } from 'react-i18next'

import '../components/Authentication/auth.css'
import { changeMyPassword } from './../Services/registerService'
// import Meta from './../Utils/Meta'
import Meta1 from '../Utils/Meta1'

const LoginNewPassword = ({ match, history }) => {
  const { t } = useTranslation()

  const [forgotCode, setForgotCode] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState('')
  const [loading, setLoading] = useState(false)

  //fuction to update password when forgot password
  const onSubmit = async (e) => {
    e.preventDefault()
    if (confirmPassword !== password) {
      setErrors(t('Confirm_password'))
    } else {
      try {
        setErrors('')
        setLoading(true)
        await changeMyPassword(match.params.id, { password, forgotCode })
        setLoading(false)
        history.push('/changed-successfully')
      } catch (ex) {
        if (
          (ex.response && ex.response.status === 400) ||
          (ex.response && ex.response.status === 500)
        ) {
          setErrors(ex.response.data)
          setLoading(false)
        }
      }
    }
  }

  return (
    <>
      <Meta1
        title={t(`Nawafrica | update_password`)}
        description='Change Nawafrica Password'
        link={'/newPassword/'}
      />

      <div style={{ overflow: 'hidden' }} className='loginOuterCon'>
        {loading && (
          <p className='loginLoadingContainer'>
            <Spinner />
          </p>
        )}
        {errors && (
          <div className='alert alert-danger loginErrorContainer'>{errors}</div>
        )}
        <div>
          <p style={{ textAlign: 'center' }}>
            <b className='title'>{t('New_Password')}</b>
          </p>

          <hr className='headerSeparator'></hr>
        </div>
        <div style={{ textAlign: 'center' }} className='form-group'>
          <form onSubmit={onSubmit}>
            <label htmlFor='exampleFormControlFile1'>
              <b>{t('Password')}</b>
            </label>
            <input
              placeholder={t('Password')}
              className='form-control'
              id='exampleFormControlFile1'
              type='password'
              onChange={(e) => {
                e.preventDefault()
                const { value } = e.currentTarget
                setPassword(value)
              }}
            />

            <label className='passwordLabel' htmlFor='exampleFormControlFile2'>
              <b>{t('Confirm_Password')}</b>
            </label>
            <input
              placeholder={t('Confirm_Password')}
              className='form-control'
              id='exampleFormControlFile2'
              type='password'
              onChange={(e) => {
                e.preventDefault()
                const { value } = e.currentTarget
                setConfirmPassword(value)
              }}
            />

            <label className='passwordLabel' htmlFor='exampleFormControlFile2'>
              <b>{t('Forgot_Code')}</b>
            </label>
            <input
              placeholder={t('Enter_Forgot')}
              className='form-control'
              id='exampleFormControlFile2'
              onChange={(e) => {
                e.preventDefault()
                const { value } = e.currentTarget
                setForgotCode(value)
              }}
            />

            <p style={{ textAlign: 'center' }}>
              <button
                style={{ cursor: 'pointer', outline: 'none' }}
                type='submit'
              >
                {t('Change_Password')}
              </button>
            </p>
          </form>
          <div className='loginFooter'></div>
        </div>
      </div>
      <div className='letMeGiveSomeSeparationOverAccountable'></div>
    </>
  )
}

export default LoginNewPassword
