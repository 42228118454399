export function mapAnimateLottie() {
  return {
    nm: 'Loading',
    mn: '',
    layers: [
      {
        ty: 4,
        nm: 'Shape Layer 4',
        mn: '',
        sr: 1,
        st: 0,
        op: 30.0000012219251,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [546, 266, 0],
                t: 13,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [546, 200, 0],
                t: 17,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [546, 266, 0],
                t: 21.0000008553475,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [45.137, 45.137], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 8, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 0.6471, 0], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [1, 0.6471, 0], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-167.432, -17.432], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 0,
      },
      {
        ty: 4,
        nm: 'Shape Layer 3',
        mn: '',
        sr: 1,
        st: -359.00001462237,
        op: 30.0000012219251,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [454, 266, 0],
                t: 9,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [454, 200, 0],
                t: 13,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [454, 266, 0],
                t: 17.0000006924242,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [45.137, 45.137], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 8, ix: 5 },
                d: [],
                c: { a: 0, k: [0.3569, 0.7608, 0.9059], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.3569, 0.7608, 0.9059], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-167.432, -17.432], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'Shape Layer 2',
        mn: '',
        sr: 1,
        st: 0,
        op: 30.0000012219251,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [365, 266, 0],
                t: 4,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [365, 200, 0],
                t: 9,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [365, 266, 0],
                t: 13.0000005295009,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [45.137, 45.137], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 8, ix: 5 },
                d: [],
                c: { a: 0, k: [0.0196, 0.0196, 0.0196], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.0196, 0.0196, 0.0196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-167.432, -17.432], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
      },
      {
        ty: 4,
        nm: 'Shape Layer 1',
        mn: '',
        sr: 1,
        st: 0,
        op: 30.0000012219251,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [277, 266, 0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [277, 200, 0],
                t: 4,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [277, 266, 0],
                t: 9.00000036657752,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [45.137, 45.137], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 8, ix: 5 },
                d: [],
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-167.432, -17.432], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 3,
      },
    ],
    ddd: 0,
    h: 500,
    w: 500,
    meta: {
      a: '',
      k: '',
      d: '',
      g: '@lottiefiles/toolkit-js 0.21.1',
      tc: '#ffffff',
    },
    v: '5.7.8',
    fr: 29.9700012207031,
    op: 30.0000012219251,
    ip: 0,
    assets: [],
  }
}
