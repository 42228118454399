import React from "react";
import Typical from "react-typical";

import video from "../../Videos/Office - 80314.mp4";

import "./PropertyManagement.css";

const PropHead = () => {
  return (
    <div className="PropManHeadOuterCon">
      <div className="propHeadContainer">
        {/* <FontAwesomeIcon className='icon' icon={faPersonShelter} /> */}
        <Typical
          steps={["HASSLE FREE PROPERTY MANAGEMENT.", 5000]}
          loop={1}
          wrapper="h1"
        />
        <Typical
          steps={[5000, "We manage everything about your property..."]}
          loop={1}
          wrapper="h2"
        />
      </div>
      <div className="propManArrowCon">
        <div className="scroll-arrow">
          <svg style={{ opacity: "0.5" }} width="30px" height="20px">
            <path
              stroke="#ffffff"
              fill="black"
              strokeWidth="2px"
              d="M2.000,5.000 L15.000,18.000 L28.000,5.000 "
            ></path>
          </svg>
        </div>
      </div>
      <video
        style={{ width: "100%", height: "100%" }}
        autostart="true"
        autoPlay
        loop
      >
        <source src={video} type="video/mp4" />
      </video>
    </div>
  );
};

export default PropHead;
