import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons'

const PolicyContactIcon = () => {
  return (
    <div style={{ display: 'flex', marginTop: '30px', width: '100%' }}>
      <div>
        <FontAwesomeIcon
          style={{
            color: 'black',
            fontSize: '30px',
            opacity: '0.8',
          }}
          icon={faPhoneVolume}
        />
      </div>
      <div style={{ flex: '1', padding: '0 20px' }}>
        <h6 style={{ fontWeight: 'bold', fontSize: '14px' }}>
          Owner and Software Developer
        </h6>
        <div style={{ fontSize: '15px', opacity: '0.9' }}>
          Software Development Office,
        </div>
        <div style={{ fontSize: '15px', opacity: '0.9' }}>
          Captivator Technologies inc.
        </div>
        <div style={{ fontSize: '15px', opacity: '0.9' }}>
          Cite six, Dallas inn hotel, Bota, Cameroon
        </div>
        <div
          style={{
            fontSize: '15px',
            opacity: '0.9',
            fontStyle: 'italic',
            margin: '15px 0',
          }}
        >
          To exercise your rights under this Notice, please send an email with
          the subject "Data Subject Rights Request" to the following:
        </div>
        <div>
          <span style={{ fontWeight: 'bold' }}>Owner contact email: </span>
          felix@captivatortechnologies.com
        </div>
      </div>
    </div>
  )
}

export default PolicyContactIcon
