import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import AddTenantFileInput from "../ManageTenants/AddTenantFileInput";
import AddTenantInput from "../ManageTenants/AddTenantInput";
import SignLeaseLoader from "./SignLeaseLoader";
import SuccessSign from "./SuccessSign";
import LoadingSpinner from "./LoadingSpinner";
import "./applications.css";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style1 = {
  width: "100%",
  border: "1px #035aa6 solid",
};

const ApplicationActionModal1 = ({
  open,
  handleClose,
  // onApplicantStatus,
  application,
  onChangeUIC,
  onChangeCN,
  onSubmitLeases,
  loadingPosting,
  errorPosting,

  // disabledReviewBtn,
  disableSignLease,
  otherPhotoPath,
  otherPhotoError,
  loadOtherPhoto,

  onChangeSD,
  onChangeED,
  valueSD,
  valueED,
  contactNumber,

  onClickImage,
  valueListing,
  leaseSignDone,
  onChangeStatus,
  loadingStatus,

  allStatus,
  listing_spaces,
}) => {
  const { t } = useTranslation();

  var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

  const sortColumn = {
    path: "dateposted",
    order: "asc",
  };

  const filterStatuses = (id) => {
    const status = allStatus.filter((all) => all.application_id === id);
    const sortedListing = _.orderBy(
      status,
      [sortColumn.path],
      [sortColumn.order]
    );
    const myStatus = sortedListing[sortedListing.length - 1];
    return myStatus
      ? myStatus
      : {
          accepted_or_denied: null,
          lease_signed: null,
          under_review: null,
          approved: null,
        };
  };

  function valuetext(value) {
    console.log(value);
    return `${value}°C`;
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          sx={
            width < 991
              ? {
                  color: "#035aa6",
                  font: "normal normal bold 13px/32px Merriweather Sans",
                  display: "flex",
                  border: "1px #035aa6 solid",
                }
              : {
                  color: "#035aa6",
                  font: "normal normal bold 18px/32px Merriweather Sans",
                  display: "flex",
                  border: "1px #035aa6 solid",
                }
          }
        >
          <div style={{ flex: "1" }}>
            {t("update_status",{
              applicant_name: application?.appicant_name?.toUpperCase()
            })}
          </div>
          <Button onClick={handleClose}>
            <ClearIcon />
          </Button>
        </DialogTitle>
        <DialogContent
          sx={
            width < 991 ? style1 : { width: 600, border: "1px #035aa6 solid" }
          }
        >
          <div className="appliModalBodyCon">
            <div className={"container1AppliModal"}>
              <b>{t("Current Status")}: </b>
              <span>
                {filterStatuses(application.id).lease_signed
                  ? t(filterStatuses(application.id).lease_signed)
                  : filterStatuses(application.id).approved
                  ? t(filterStatuses(application.id).approved)
                  : filterStatuses(application.id).accepted_or_denied
                  ? filterStatuses(application.id).accepted_or_denied
                  : filterStatuses(application.id).under_review
                  ? t(filterStatuses(application.id).under_review)
                  : "Received"}
              </span>
            </div>
            <div className="container3AppliModal">
              <div>
                <b
                  style={{
                    font: "normal normal bold 14px/32px Merriweather",
                  }}
                >
                  {t("Update Status")}:
                </b>
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {loadingStatus ? (
                    <span style={{ color: "#035aa6" }}>
                      <LoadingSpinner />
                    </span>
                  ) : (
                    <select
                      onChange={onChangeStatus}
                      style={{
                        font: "normal normal normal 14px/32px Merriweather",
                        cursor: "pointer",
                        borderRadius: "5px",
                        background: "#f5f5f5 0% 0% no-repeat padding-box",
                        border: "1px solid #bcbcbc",
                        cursor: "pointer",
                      }}
                    >
                      <option>{t("Received")}</option>
                      <option>{t("Under Review")}</option>
                      <option>{t("Approved")}</option>
                      <option>{t("Denied")}</option>
                    </select>
                  )}
                </span>
              </div>
            </div>
          </div>

          <hr style={{ margin: "10px 0", width: "100%" }} />

          <div
            className={
              disableSignLease ? "disableLeaseSign" : "appliModalLeasesCon"
            }
          >
            {loadingPosting ? (
              <div style={{ width: "100%", height: "100%" }}>
                <SignLeaseLoader />
              </div>
            ) : leaseSignDone ? (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SuccessSign />
              </div>
            ) : (
              <form onSubmit={onSubmitLeases}>
                <div className="row">
                  <div className="col-lg-6">
                    <AddTenantInput
                      title={t(`Apt/Studio/Office # *`)}
                      id="FN"
                      placeholder={t("eg Apt/Studio/Office Number")}
                      type="number"
                      onChange={onChangeCN}
                      value={contactNumber}
                      chip={listing_spaces?.length}
                      listing_spaces={listing_spaces}
                      listing={valueListing}
                    />
                    {/* listing_spaces */}
                  </div>

                  <div className="col-lg-6">
                    <AddTenantInput
                      title={t("Agreement Starting Date *")}
                      id="FN"
                      placeholder="28/03/2021"
                      type="date"
                      onChange={onChangeSD}
                      value={valueSD}
                    />
                  </div>

                  {valueListing.rent_or_sell === "Rental" && (
                    <div className="col-lg-6">
                      <AddTenantInput
                        title={t("Agreement Ending Date *")}
                        id="FN"
                        placeholder="eg 28/03/2022"
                        type="date"
                        onChange={onChangeED}
                        value={valueED}
                      />
                    </div>
                  )}

                  <div className="col-lg-6">
                    <AddTenantFileInput
                      title={t("Upload Multiple Files")}
                      id="UIC"
                      onChange={onChangeUIC}
                      uploadedImagesArray={otherPhotoPath}
                      loading={loadOtherPhoto}
                      error={otherPhotoError}
                      onClickImage={onClickImage}
                      accept="image/*"
                      multiple={true}
                    />
                  </div>
                  <div className="col-lg-6 myModalContainerForApplication">
                    <p>
                      {/* <input
                        onChange={onChangeDownload}
                        type='checkbox'
                        checked={downloadPDF}
                      />
                      {t('Download_PDF_of_agreement')} */}
                    </p>
                  </div>

                  <div className="col-lg-6 myModalContainerForApplication">
                    {/* <p>
                      <input
                        onChange={onChangeNoDownload}
                        type='checkbox'
                        checked={NodownloadPDF}
                      />
                      {t('Donot_Download_PDF_of_agreement')}
                    </p> */}
                  </div>
                </div>
                <button
                  style={{ boxShadow: "3px 1px 3px 0.5px #969696" }}
                  type="submit"
                  disabled={loadingPosting || disableSignLease}
                >
                  <span style={{ marginRight: "5px" }}>
                    <FontAwesomeIcon icon={faHandshake} />
                  </span>
                  {t("Sign Lease")}
                </button>
                {loadingPosting && (
                  <div className="applicationPostingContainer">
                    {t(".......Posting")}
                  </div>
                )}
                {errorPosting && (
                  <div className="alert alert-danger myErrorContainerForModalApplication">
                    {errorPosting}
                  </div>
                )}
              </form>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ApplicationActionModal1;
