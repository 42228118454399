import React from 'react'
import Pagination from '@mui/material/Pagination';

const PaginationMUI = ({count, onChange}) => {
if(count<2) return null
  return (
<div style={{margin:"10px 5px"}}>
    <Pagination  count={count} onChange={onChange}/>
    </div>
  )
}

export default PaginationMUI