import React from "react";
import BlogDetailsTitle from "./BlogDetailsTitle";

import "./BlogDetails.css";

const HeadBlogDetails = ({ item, onClickDelete }) => {
  return (
    <div className="HeadBlogDetCon">
      <BlogDetailsTitle onClickDelete={onClickDelete} item={item} />
      <img
        crossorigin="anonymous"
        style={{ width: "100%", height: "100%" }}
        src={item.image && item.image.url}
      />
    </div>
  );
};

export default HeadBlogDetails;
