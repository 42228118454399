import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

import PaySpinner from "../LeaseSignDetails/PaySpinner";
import Paid from "../LeaseSignDetails/Paid";
import ConfirmPayPassword from "../LeaseSignDetails/ConfirmPayPassword";
import "./manageTenantsDet.css";
import PriceAndQuantity from "../LeaseSignDetails/PriceAndQuantity";

const EditPaymentModal = ({
  valueAddPay,
  valueSubtractPay,
  onChangeAmount,
  editedSent,
  tenant,
  onSubmitEditPayment,
  listing,
  editError,
  valueAmount,
  onloadEdit,
  onClickPaid,
  mySignLease,
  confirmPassword,
  onClickConfirmEdit,
  onChangeConfirmEdit,
  errorConfirmEdit,
  confirmLoadingConfirmEdit,
  onChangeQuantity,
  quantity = 1,
}) => {
  const { t } = useTranslation();

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  return (
    <div
      className="modal fade"
      id="exampleModalEditPayment"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div
          style={{ boxShadow: "2px 5px 7px black" }}
          className="modal-content modalContentToEditPayment"
        >
          <div className="modal-header">
            <h5 className="modal-title editPaymentTitle" id="exampleModalLabel">
              {confirmPassword
                ? "Confirm Edit Of Payment"
                : !editedSent
                ? t("edit_user_payments", {
                    tenant: tenant ? capitalize(tenant) : "",
                  })
                : t("edit_user_payments", {
                    tenant: tenant ? capitalize(tenant) : "",
                  })}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form onSubmit={onSubmitEditPayment}>
            <div className="modal-body">
              {confirmPassword ? (
                <ConfirmPayPassword
                  onClick={onClickConfirmEdit}
                  onChange={onChangeConfirmEdit}
                  error={errorConfirmEdit}
                  confirmLoading={confirmLoadingConfirmEdit}
                  label="Confirm editing of payments by typing your nawafrica password"
                />
              ) : onloadEdit ? (
                <div className="EditPaymentSpinalContainer">
                  <PaySpinner />
                </div>
              ) : editedSent ? (
                <div data-dismiss="modal" aria-label="Close">
                  <Paid onClickPaid={onClickPaid} paid={editedSent} />
                </div>
              ) : (
                <>
                  <div className="questionHowMuchToEditContainer"></div>
                  {valueSubtractPay && (
                    <div className="editTheQuestionIsHere">
                      <label>
                        {t("How_Much_Do_You_Want_to_Subtract", {
                          currency: listing.currency,
                          country: listing.country,
                          applicant_name:
                            mySignLease && mySignLease.applicant_name
                              ? capitalize(mySignLease.applicant_name)
                              : "",
                          listing_name: mySignLease.listing_name,
                        })}
                      </label>
                      {/* <input
                        value={valueAmount}
                        onChange={onChangeAmount}
                        className='form-control'
                        style={{ width: '100%' }}
                        placeholder={`Amount in ${listing.currency}`}
                        type='number'
                        autoFocus
                      /> */}
                      {/* <TextField
                        sx={{ width: "100%" }}
                        type="number"
                        onChange={onChangeAmount}
                        id="filled-basic"
                        label={`Amount in ${listing.currency}`}
                        variant="filled"
                      />
                      <p>{commaSeparateNumber(valueAmount)}</p> */}
                      <PriceAndQuantity
                        // valueAmount={valueAmount}
                        listing={listing}
                        onChangeQuantity={onChangeQuantity}
                        quantity={quantity}
                      />
                    </div>
                  )}

                  {valueAddPay && (
                    <div className="editTheQuestionIsHere">
                      <label>
                        {
                          t("How_Much_Do_You_Want_to_Add", {
                            currency: listing.currency,
                            country: listing.country,
                            applicant_name:
                              mySignLease && mySignLease.applicant_name
                                ? capitalize(mySignLease.applicant_name)
                                : "",
                            listing_name: mySignLease.listing_name
                              ? capitalize(mySignLease.listing_name)
                              : "",
                          })
                          // `How Much Do You Want to Add In ${listing.currency}/${listing.country} to ${mySignLease.applicant_name} payment in ${mySignLease.listing_name}?`
                        }
                      </label>
                      {/* <TextField
                        type="number"
                        sx={{ width: "100%" }}
                        onChange={onChangeAmount}
                        id="filled-basic"
                        label={`Amount in ${listing.currency}`}
                        variant="filled"
                      />
                      <p>{commaSeparateNumber(valueAmount)}</p> */}
                      <PriceAndQuantity
                        // valueAmount={valueAmount}
                        listing={listing}
                        onChangeQuantity={onChangeQuantity}
                        quantity={quantity}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            {!editedSent && !confirmPassword && (
              <button
                style={{ boxShadow: "3px 1px 3px 0.5px black" }}
                type="submit"
                className="btn editSaveChangeBtn"
              >
                <span style={{ marginRight: "5px" }}>
                  <FontAwesomeIcon icon={faSave} />
                </span>
                {t("Save_changes")}
              </button>
            )}
            {!confirmPassword && (
              <p className="theErrorWhileEdit">{editError}</p>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPaymentModal;
