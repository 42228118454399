import React from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons'
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, TextField } from '@mui/material'

import './messages.css'

const SearchMessage = ({
  onChangeSearch,
  onChangeSort,
  onChangeContactDate,
  handleClickOpen
}) => {
  const { t } = useTranslation()

    // function use to capitalise first letters
    const capitalize = (s) => {
      return s.toLowerCase().replace(/\b./g, function (a) {
        return a.toUpperCase()
      })
    }
  
  return (
    <div className='listingSearchCon1'>
      <div className='addlistCons someMarginInListingSearchInput'>
        <Button
          variant='contained'
          style={{ outline: 'none' }}
          className='addlistConsMesageBtn'
          sx={{ color: '#fff', font: "normal normal bold 14px/19px Merriweather", backgroundColor: '#035aa6', borderRadius:"10px", textTransform: 'capitalize'}}
          data-toggle='modal'
          data-target='#exampleModalComposeMsg'
          onClick={handleClickOpen}
          startIcon={<FontAwesomeIcon icon={faEnvelopeOpenText} />}
        >
          {capitalize(t('Compose_Message'))}
        </Button>
      </div>

      <div className='inputFieldCon1 someMarginInListingSearchInput2'>
        <div style={{ flex: 1 }}>
          {/* <input
            className='myListingSearchInput'
            onChange={onChangeSearch}
            placeholder={t('Search_your_Sender')}
            style={{ outline: 'none' }}
          /> */}
        <Box sx={{ display: 'flex', alignItems: 'flex-end', width:"100%"}}>
        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField 
        inputProps={{
          style: { font: "normal normal 14px/25px Merriweather"},
        }}
        sx={{width:"100%", font: "normal normal 14px/25px Merriweather"}} onChange={onChangeSearch} id="input-with-sx" label={t('Search_your_Sender')} variant="standard" />
      </Box>
        </div>
        <div style={{ flex: 1, padding: '0 5px' }}>
          {/* <input
            className='myListingSearchInput'
            onChange={onChangeContactDate}
            placeholder={t('Contact Date DD/MM/YYYY')}
            style={{ outline: 'none' }}
          /> */}
        <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField   onChange={onChangeContactDate} id="input-with-sx" label={t('DD/MM/YYYY')} variant="standard" />
      </Box>
        </div>
      </div>

      <div className='listingSortDropdown someMarginInListingSearchInput'>
        <p>
          <span className='sortedBySpan'>{t('Sort_By')}</span>
          <span className='listingSortSelectSpan'>
            <select style={{ outline: 'none' }} onChange={onChangeSort}>
              <option value='dateposted'>{t('Date')}</option>
              <option value='sender_name'>{t('Sender')}</option>
              <option value='listing_name'>{t('Listing')}</option>
              <option value='listing_address'>{t('Address')}</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  )
}

export default SearchMessage
