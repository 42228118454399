import React from 'react'
import { useTranslation } from 'react-i18next'

import selectedCountryInputs from '../../Utils/translateInput'

import './addListing.css'

const Select = ({
  label,
  value,
  left,
  id,
  onChange,
  placeholder = 'CLICK and SELECT ONE',
  items,
}) => {
  const { t } = useTranslation()
  return (
    <div className={left}>
      <label className='addListSelectLabel' htmlFor={id}>
        {label}
      </label>

      <select
        value={value}
        type='email'
        className='form-control addListingSelect'
        onChange={onChange}
        id={id}
        placeholder={placeholder}
      >
        <option className='addListPlaceholderOption' value=''>
          {placeholder}
        </option>
        {items.map((item, index) => (
          <option key={`${index}`} className='addListingOption' value={item}>
            {id === 'keyword' ? t(item) : selectedCountryInputs(item)}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
