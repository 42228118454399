import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";

const SelectedItem1 = ({
  placeholder = "Choose a Country",
  search,
  value,
  items = [],
  onChange,
  width = "100%",
}) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      id="country-select-demo"
      sx={{ width, padding: "0" }}
      className={`selectInput form-control ${search}`}
      options={items}
      autoHighlight
      getOptionLabel={(option) => `${option.label ? option.label : option}`}
      onChange={onChange}
      renderOption={(props, option) => (
        <Box
          className={`selectInput form-control ${search}`}
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.code && (
            <img
              loading="lazy"
              width="20"
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              alt=""
            />
          )}
          {t(option.label ? option.label : option)}
        </Box>
      )}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            label={placeholder}
            inputProps={{
              ...params.inputProps,
              autoComplete: "off", // disable autocomplete and autofill
              style: {
                height: "15px",
                backgroundColor: "#ffffff 0% 0% no-repeat padding-box",
                font: "normal normal 300 18px/25px Merriweather",
              },
            }}
          />
        </>
      )}
    />
  );
};

export default SelectedItem1;
