import React from "react";
import { FaAngleDown } from "react-icons/fa";

const DownShowSideBar = ({ onUponUpSideBar }) => {
  return (
    <div
      style={{
        marginTop: "35px",
        width: "20%",
        zIndex: "3",
        position: "fixed",
      }}
    >
      <button onClick={onUponUpSideBar} className="signBarUpArrow">
        <FaAngleDown />
      </button>
    </div>
  );
};

export default DownShowSideBar;
