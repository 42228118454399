import React from "react";

const RentPoster = ({ user }) => {
  return (
    <>
      <h1 className="authorH1">About The Author</h1>
      <div className="rentSaleAuthorCon">
        <div className="rentSaleImageContainer">
          <div className="onlyImage">
            <img
              crossorigin="anonymous"
              src={
                user.picture &&
                user.picture.fileProfile &&
                user.picture.fileProfile.url
              }
            />
          </div>
        </div>
        <div className="rentSaleAuthorContent">
          <h1>{`${user.first_name} ${user.last_name}`.toUpperCase()}</h1>
          <p>
            Hi, I'm {user.first_name} a seasoned writer with years of experience
            in both sides of the rental industry. I focus on answering your
            questions about renting, as well as property ownership and
            management, in the hopes of making life as a renter or a landlord a
            bit easier.{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default RentPoster;
