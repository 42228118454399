import React from 'react'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

import './messageDetail.css'
import { getCurrentUser } from '../../Services/authService'

const UnreadMessages = ({
  message,
  senderUnreadMsg,
  onDeleteUnread,
  close = true,
}) => {
  const { t } = useTranslation()
  return (
    <div
      className={
        close ? 'unreadMessageForMessageDet' : 'unreadMessageForMessageDet1'
      }
      data-simplebar
    >
      <div className='unreadMessageHeadingContainer'>
        <div className='title'>
          <b>{t('All_Unread_messages_From', { name: message.sender_name })}</b>
        </div>
      </div>

      <div data-simplebar className='messageTableContainer'>
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th className='myListTh' scope='col'>
                {t('Sender')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Listing_Name')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Message')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {senderUnreadMsg &&
              senderUnreadMsg.map((message) => (
                <tr key={message.id}>
                  <th className={message.visited ? 'myListTd1' : 'myListTd'}>
                    <Link
                    to={getCurrentUser()?.post_rent_property === "I AM A PROPERTY OWNER"?`/portal/messages/message/${message.id}`:`/portal/tenants/message/${message.id}`}
                    >
                      {message.sender_name}
                    </Link>
                  </th>
                  <td className={message.visited ? 'myListTd1' : 'myListTd'}>
                    <Link
                  to={getCurrentUser()?.post_rent_property === "I AM A PROPERTY OWNER"?`/portal/messages/message/${message.id}`:`/portal/tenants/message/${message.id}`}
                    >
                      {message.listing_name}
                    </Link>
                  </td>
                  <td className={message.visited ? 'myListTd1' : 'myListTd'}>
                    <Link
                   to={getCurrentUser()?.post_rent_property === "I AM A PROPERTY OWNER"?`/portal/messages/message/${message.id}`:`/portal/tenants/message/${message.id}`}
                    >
                      {message.body.length > 35
                        ? message.body.slice(0, 35) + '..'
                        : message.body}
                    </Link>
                  </td>
                  <td className='approveUnapproveCon'>
                    <div className='approveUnapproveIconContainer'>
                      <button onClick={() => onDeleteUnread(message)}>
                        <span style={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                        {t('Delete')}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default UnreadMessages
