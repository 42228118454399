import axios from "axios";
import logger from "../Services/loggerService";

export function currencyListExchange(
  currencyfrom,
  currencyTo = "EUR",
  onExchangeData,
  exchangeError,
  setLoading
) {
  //Api Key=a507b56952-88c7ee96fc-sc0692
  try {
    setLoading(true);
    if (currencyfrom === currencyTo) {
      setLoading(false);
      onExchangeData(1);
      return;
    }
    var options = {
      method: "GET",
      url: "https://currency-exchange.p.rapidapi.com/exchange",
      params: { from: currencyfrom, to: currencyTo, q: "1.0" },
      headers: {
        "x-rapidapi-host": "currency-exchange.p.rapidapi.com",
        "x-rapidapi-key": "05279b6b9bmshc8e3d21a4c373ccp13108bjsnbece35678c93",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log("here is the response===", response);
        logger.log(response);
        onExchangeData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.log("here is the error===", error);
        logger.log(error);
        setLoading(false);
        exchangeError(error);
      });

    {
      /*  axios
      .get(
        `https://api.fastforex.io/fetch-one?from=${currencyfrom}&to=${currencyTo}&api_key=a507b56952-88c7ee96fc-sc0692`
      )
      .then((response) => {
        logger.log(response);
        onExchangeData(response.data.result[currencyTo]);
        setLoading(false);
      })
      .catch((error) => {
        logger.log(error);
        setLoading(false);
        exchangeError(error);
      });
    */
    }
    setLoading(false);
  } catch (error) {
    logger.log(error);
    exchangeError(error);
  }
}
