import React from 'react'

const PropBodyTitle = () => {
  return (
    <div className='propBodyTitle'>
      <p style={{ lineHeight: '1.8' }}>
        With our technology, coupled to our robust network of the best property
        technicians, Nawafrica provides a first class property management. with
        hands off support for a healthy environment around and within your
        property, We strive to make each of the properties we manage perform to
        their fullest potential by giving its staff the tools they need to
        succeed, by intelligently responding to the industry's market, and by
        overcoming any obstacles that might be presented along the way.
      </p>
    </div>
  )
}

export default PropBodyTitle
