import React from 'react'

const PaymentUpdateSpinner = () => {
return (
<div aria-hidden="true" className="spinner-border letMeSpinnerToUpatePay" role="status">
<span className="sr-only">Loading...</span>
</div>
)
}

export default PaymentUpdateSpinner
