import React from 'react'

const BlogHr = () => {
  return (
    <div className='blogHrCon'>
      <hr style={{ background: '#035aa6' }} />
      <span style={{ color: '#035aa6', fontWeight: 'bold' }}>ALL STORIES</span>
      <hr style={{ background: '#035aa6' }} />
    </div>
  )
}

export default BlogHr
