import React from 'react'
import PoliciesMainHeading from '../components/Policies/PoliciesMainHeading'
import PolicyHr from '../components/Policies/PolicyHr'

import MainHeadingDetails from '../components/PoliciesDetails.js/MainHeadingDetails'
import ModePlace from '../components/PoliciesDetails.js/ModePlace'
import OwnerDetails from '../components/PoliciesDetails.js/OwnerDetails'
import TypeDataCollect from '../components/PoliciesDetails.js/TypeDataCollect'
import PurposeProcess from '../components/PoliciesDetails.js/PurposeProcess'

const PolicyDetails = () => {
  return (
    <div
      style={{
        marginTop: '45px',
        padding: '50px 23%',
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      <PoliciesMainHeading />
      <MainHeadingDetails />
      <PolicyHr />
      <OwnerDetails />
      <PolicyHr />
      <TypeDataCollect />
      <PolicyHr />
      <ModePlace />
      <PolicyHr />
      <PurposeProcess />
    </div>
  )
}

export default PolicyDetails
