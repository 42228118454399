import React from 'react'
import { Link } from 'react-router-dom'

import Meta from './../Utils/Meta'
import '../components/Authentication/auth.css'

const LoginSuccessChange = () => {
  return (
    <div style={{ width: '100%', marginTop: '70px', height: '220px' }}>
      <Meta title={`Nawafrica | password update success`} />
      <div
        style={{ width: '60%', margin: '0 auto', textAlign: 'center' }}
        className='alert alert-info'
      >
        <h6>
          Congrat!!!, You have successfully change your password, you can now{' '}
          <Link to='/login'>Login to your account</Link>
        </h6>
      </div>
      <div className='letMeGiveSomeSeparationOverAccountable'></div>
    </div>
  )
}

export default LoginSuccessChange
