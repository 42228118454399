import React from 'react'
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBed } from '@fortawesome/free-solid-svg-icons'


const SliderBedroom = ({onChange}) => {
  function valuetext(value) {
    return `${value} bedroom${value>1?"s":""}`;
  }
  return (
    <Box width={"100%"}>
      <div  className='numberOfBedrooms'>
      <div style={{font: "normal normal 300 12px/25px Merriweather", width:"17%"}}>
      <FontAwesomeIcon
                style={{
             
                  opacity: '0.8',
                  fontSize: '16px',
                  // marginBottom: '20px',
                  cursor: 'pointer',
                }}
                icon={faBed}
              />
        </div>
        <div style={{flex:"1", display:"flex", justifyContent:"center", alignItems:"center"}}>
      <Slider  getAriaValueText={valuetext} onChange={onChange} defaultValue={0} aria-label="Default" valueLabelDisplay="auto" />
            
        </div>
      </div>
    </Box>
  )
}

export default SliderBedroom