import React from 'react'
import Lottie from 'react-lottie'
import { success_sign } from './success_sign'

const SuccessSign = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: success_sign(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={350} width={350} />
    </div>
  )
}

export default SuccessSign
