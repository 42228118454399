import React from "react";

const FaqHeading = () => {
  return (
    <div
      style={{
        backgroundImage: "url(../../images/boss.jpg)",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="faqHeadingOuterCon"
    >
      <h1 className="faqHeadH1">F.A.Qs</h1>
      <div className="faqHeadIconCon">
        <div className="scroll-arrow">
          <svg width="30px" height="20px">
            <path
              stroke="#ffffff"
              fill="none"
              strokeWidth="2px"
              d="M2.000,5.000 L15.000,18.000 L28.000,5.000 "
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default FaqHeading;
