import React from "react";

const DownloadApp = () => {
  return (
    <div className="aboutWhereParaCon1">
      <a
        style={{
          backgroundColor: "#7952b3",
          width: "350px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "30px",
          boxShadow: "3px 1px 3px 0.5px black",
        }}
        href="http://play.google.com/store/apps/details?id=com.felix.nawafrica"
        className="btn "
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          style={{ width: "20px", height: "30px", marginRight: "5px" }}
          src="./images/google-play-play.png"
        />
        <span
          style={{
            marginLeft: "7px",
            color: "white",
            font: "normal normal normal 15px/24px Merriweather",
          }}
        >
          Download app on{" "}
        </span>
        <span
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            marginLeft: "5px",
            color: "white",
            fontFamily: "'Amiri', serif",
          }}
        >
          google playstore
        </span>
      </a>
    </div>
  );
};

export default DownloadApp;
