import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getCurrentUser } from "../../Services/authService";
import EditIcon from "@mui/icons-material/Edit";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import AddTenantInput from "../ManageTenants/AddTenantInput";
import LoadingSpinner from "../Applications/LoadingSpinner";
import { useTranslation } from "react-i18next";

const EditLeaseStartMenu = ({
  onChangeSD,
  valueSD,
  title = "Edit Lease Starting Date *",
  onsubmit,
  editStartingDateError,
  loadingEditStartDate,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {getCurrentUser()?.post_rent_property === "I AM A PROPERTY OWNER" && (
        <Tooltip title={t("Edit lease starting date")}>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            style={{ marginLeft: 5 }}
          >
            <EditCalendarIcon style={{ fontSize: 16 }} />
          </IconButton>
        </Tooltip>
      )}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div style={{ width: 300, padding: 5 }}>
          {loadingEditStartDate ? (
            <LoadingSpinner />
          ) : (
            <>
              <AddTenantInput
                title={title}
                id="FN"
                placeholder="28/03/2021"
                type="date"
                onChange={onChangeSD}
                value={valueSD}
              />
              <div style={{ width: "100%", marginTop: 5 }}>
                <Button
                  startIcon={<EditCalendarIcon />}
                  style={{ background: "#035aa6" }}
                  variant="contained"
                  onClick={onsubmit}
                >
                  Submit
                </Button>
                {editStartingDateError && (
                  <p
                    style={{
                      marginTop: 5,
                      color: "red",
                      font: "normal normal normal 12px/25px Merriweather",
                    }}
                  >
                    {editStartingDateError}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </Menu>
    </>
  );
};

export default EditLeaseStartMenu;
