export function noWifiAnimation() {
  return {
    nm: 'Not Done',
    mn: '',
    layers: [
      {
        ty: 4,
        nm: 'Shape Layer 2',
        mn: '',
        sr: 1,
        st: 0,
        op: 899.000036617021,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.333, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [0, 0, 100],
                t: 0,
              },
              {
                o: { x: 0.333, y: 0 },
                i: { x: 0.667, y: -0.137 },
                s: [0, 0, 100],
                t: 4,
              },
              {
                o: { x: 0.333, y: 28.416 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 24,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 29.0000011811942,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [131, 130, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Shape 2',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [[0, 0]],
                    o: [[0, 0]],
                    v: [[153.888, 72.281]],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 9, ix: 5 },
                d: [],
                c: { a: 0, k: [0.9059, 0.2627, 0.2627], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.051, 0.4824, 0.9804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Shape 1',
            ix: 2,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-35.743, 34.684],
                      [34.684, -35.743],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 9, ix: 5 },
                d: [],
                c: { a: 0, k: [0.9059, 0.2627, 0.2627], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.051, 0.4824, 0.9804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'tm',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Filter - Trim',
            nm: 'Trim Paths 1',
            ix: 3,
            e: { a: 0, k: 100, ix: 2 },
            o: { a: 0, k: 0, ix: 3 },
            s: {
              a: 1,
              k: [
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0.667, y: 1 },
                  s: [100],
                  t: 24,
                },
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [0],
                  t: 29.0000011811942,
                },
              ],
              ix: 1,
            },
            m: 1,
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'wifi_black_24dp1 Outlines',
        mn: '',
        sr: 1,
        st: 0,
        op: 899.000036617021,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [12, 12, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.333, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [0, 0, 100],
                t: 0,
              },
              {
                o: { x: 0.333, y: 0 },
                i: { x: 0.667, y: 0.763 },
                s: [0, 0, 100],
                t: 4,
              },
              {
                o: { x: 0.333, y: -0.816 },
                i: { x: 0.667, y: 1 },
                s: [478.824, 478.824, 100],
                t: 24,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [444, 444, 100],
                t: 29.0000011811942,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [130, 130, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 1',
            ix: 1,
            cix: 2,
            np: 5,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [3.87, -3.86],
                      [0, 0],
                      [-2.76, -2.76],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [2.76, -2.76],
                      [0, 0],
                      [-3.86, -3.86],
                    ],
                    v: [
                      [-7, 1.535],
                      [-5, 3.535],
                      [5, 3.535],
                      [7, 1.535],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 2',
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.66, -1.66],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [-1.65, -1.66],
                    ],
                    v: [
                      [-3, 5.535],
                      [0, 8.535],
                      [3, 5.535],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 3',
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [6.08, -6.07],
                      [0, 0],
                      [-4.97, -4.97],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [4.97, -4.97],
                      [0, 0],
                      [-6.07, -6.07],
                    ],
                    v: [
                      [-11, -2.465],
                      [-9, -0.465],
                      [9, -0.465],
                      [11, -2.465],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'mm',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Filter - Merge',
                nm: 'Merge Paths 1',
                mm: 1,
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [12, 11.465], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
      },
      {
        ty: 4,
        nm: 'Settings Outlines',
        mn: '',
        sr: 1,
        st: 0,
        op: 899.000036617021,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [95.067, 95.067, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.004 },
                i: { x: 0.116, y: 1 },
                s: [0, 0, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.833, y: 1 },
                s: [100, 100, 100],
                t: 24,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [90, 90, 100],
                t: 29.0000011811942,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [130, 130, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-52.366, 0],
                      [0, -52.366],
                      [52.366, 0],
                      [0, 52.366],
                    ],
                    o: [
                      [52.366, 0],
                      [0, 52.366],
                      [-52.366, 0],
                      [0, -52.366],
                    ],
                    v: [
                      [0, -94.817],
                      [94.817, 0],
                      [0, 94.817],
                      [-94.817, 0],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.9686, 0.8235, 0.8706], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [95.067, 95.067], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 3,
      },
    ],
    ddd: 0,
    h: 260,
    w: 260,
    meta: { a: '', k: '', d: '', g: 'LottieFiles AE 1.0.0', tc: '#000000' },
    v: '4.8.0',
    fr: 29.9700012207031,
    op: 61.0000024845809,
    ip: 0,
    assets: [],
  }
}
