import React from 'react'
import {FaArrowLeft} from 'react-icons/fa'; 
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './messageDetail.css'


const BackButton = ({back="/portal/tenants/message"}) => {
  const {t}=useTranslation()
return (

  <Link to={back} className="btn btn-light goingBackToMessage"><FaArrowLeft className="iconBack"/>{t("go_back")}</Link>                                        

)
}

export default BackButton
