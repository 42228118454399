import { faPlusCircle, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import LeaseLoader from "../LeaseSignDetails/leaseLoader";
import PriceAndQuantity from "../LeaseSignDetails/PriceAndQuantity";

const NonMemberPayModal = ({
  onClickCloseModal,
  onSubmitPay,
  onChange,
  error,
  value,
  loadMyData,
  listing,
  quantity,
  onChangeQuantity,
}) => {
  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  return (
    <div className="nonMemberModalOuterCon">
      <div className="nonMemberModalInnerCon">
        {loadMyData ? (
          <LeaseLoader height="230px" />
        ) : (
          <>
            <button className="btn btn-light nonMemberPayCancelBtn">
              <FontAwesomeIcon
                style={{
                  color: "#035aa6",
                  fontSize: "25px",
                }}
                icon={faXmark}
                onClick={onClickCloseModal}
              />
            </button>

            <p
              style={{
                font: "normal normal bold 16px/25px Merriweather",
                textAlign: "center",
              }}
            >
              ADD AMOUNT TO TENANT PAYMENT
            </p>

            <form onSubmit={onSubmitPay}>
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "50px",
                  borderBottom: "1px solid #035aa6",
                  marginLeft: "10%",
                }}
              >
                <PriceAndQuantity
                  listing={listing}
                  quantity={quantity}
                  onChangeQuantity={onChangeQuantity}
                />
                {/* <input
                  placeholder='Amount To Add eg 5000'
                  style={{
                    width: '100%',
                    border: '1px solid white',
                    textAlign: 'center',
                    font: 'normal normal normal 16px/25px Merriweather',
                  }}
                  type='number'
                  onChange={onChange}
                  className='form-control'
                  autoFocus
                /> */}
              </div>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  style={{
                    background: "#035aa6",
                    width: "60%",
                    color: "white",
                  }}
                  className="btn"
                >
                  <span style={{ marginRight: "5px" }}>
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </span>
                  ADD
                </button>
              </div>
              {error && (
                <p
                  style={{
                    color: "red",
                    opacity: "0.8",
                    fontSize: "12px",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  {error}
                </p>
              )}
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default NonMemberPayModal;
