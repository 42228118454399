import React, { useContext } from "react";
import { FaAngleLeft } from "react-icons/fa";
import { useState, useEffect } from "react";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";

import ListingItems from "./ListingItems";
import Grid from "../../iconComponent/Grid";
import SidebarList from "./../../iconComponent/SidebarList";
import Feature from "../../iconComponent/Feature";
import Chat from "../../iconComponent/Chat";
import Gear from "../../iconComponent/Gear";
import { getCurrentUser } from "../../Services/authService";
import {
  getApprovedStatusByTenantId,
  getApprovedStatusByTenantId1,
} from "../../Services/applicationStatus";

import "./addListing.css";
import UnreadMsgsContext from "../../unreadmessages/context";

const SidebarTenant = ({ onClickCloseBar, path, chats }) => {
  const { t } = useTranslation();

  const leasesPath = "/portal/tenant/leases";
  const applicationPath = "/portal/tenant/applications";
  const messagesPath = "/portal/tenants/message";
  const [underReview, setUnderReview] = useState([]);

  const [cancelAgreement, setCancelAgreement] = useState(false);

  const {
    myLeaseSigned,
    tenantApplications,
    setMyLeaseSigned,
    myLeasesApplicationsOwed,
  } = useContext(UnreadMsgsContext);

  useEffect(() => {
    const socket = io("/");

    const getData = async () => {
      const { data: myReviews } = await getApprovedStatusByTenantId();
      if (myReviews) {
        const view = myReviews;
        const myUnSignedLeased =
          view &&
          view.filter(
            (d) => d.tenant_sign_lease !== true && d.deleted !== true
          );
        setUnderReview(myUnSignedLeased);
      }
    };

    getData();
    socket.on(`${getCurrentUser().id}myReview`, (data) => {
      if (data) {
        const myUnSignedLeased = data.filter(
          (d) => d.tenant_sign_lease !== true
        );
        setMyLeaseSigned(myUnSignedLeased);
        const myVideoToPlay = document.getElementById("myAudioPlay");
        myVideoToPlay && myVideoToPlay.play();
      }
    });

    socket.on(`${getCurrentUser().id}deleteApplication`, (data) => {
      const reviews = [...underReview];
      const undeletedReviews = reviews.filter((r) => r.id !== data.id);
      setMyLeaseSigned(undeletedReviews);
    });
  }, []);

  return (
    <>
      <div className="sidebarContainer">
        <div>
          <audio id="myAudioPlay" src="/images/snap.mp3"></audio>
        </div>

        <ListingItems
          href={"#"}
          icon={<Grid className="sidebarIconsClassMenu" />}
          title={<span className="sidebarMenuSpan">{t("Menu")}</span>}
          angle={
            <div className="angleIconContainer" onClick={onClickCloseBar}>
              <FaAngleLeft className="sidebarMenuLeftAngle" />
            </div>
          }
          path={path}
          classes={"ListingItemConAddList"}
        />

        <hr className="sidebarHr" />

        <div className="sideBarLinks">
          <ListingItems
            icon={
              <Feature
                style={
                  myLeasesApplicationsOwed?.length > 0 ? { color: "#8B0000" } : {}
                }
                className={
                  path === leasesPath
                    ? "sidebarIconLinkActive"
                    : "sidebarIconLink"
                }
              />
            }
            title={
              <span
                className={
                  path === leasesPath
                    ? "activeSidebarTitle"
                    : "sidebarLinkTitle"
                }
              >
                {t("Leases")}
              </span>
            }
            href="/portal/tenant/leases"
            path={path}
            classes={
              path === leasesPath ? "activePortalLink" : "ListingItemConAddList"
            }
            // underReview={underReview}
            closeDeal={cancelAgreement}
            chats={
              myLeaseSigned?.length === 0
                ? null
                : myLeaseSigned?.length > 99
                ? "99+"
                : myLeaseSigned?.length
            }
          />

          <ListingItems
            icon={
              <SidebarList
                className={
                  path === applicationPath
                    ? "sidebarIconLinkActive"
                    : "sidebarIconLink"
                }
              />
            }
            title={
              <span
                className={
                  path === applicationPath
                    ? "activeSidebarTitle"
                    : "sidebarLinkTitle"
                }
              >
                {t("Listing_Applied")}
              </span>
            }
            href={"/portal/tenant/applications"}
            path={path}
            chats={
              tenantApplications.length === 0
                ? null
                : tenantApplications.length > 99
                ? "99+"
                : tenantApplications.length
            }
            classes={
              path === applicationPath
                ? "activePortalLink"
                : "ListingItemConAddList"
            }
          />

          <ListingItems
            icon={
              <Chat
                className={
                  path === messagesPath
                    ? "sidebarIconLinkActive"
                    : "sidebarIconLink"
                }
              />
            }
            title={
              <span
                className={
                  path === messagesPath
                    ? "activeSidebarTitle"
                    : "sidebarLinkTitle"
                }
              >
                {t("Messages")}
              </span>
            }
            chats={chats == 0 ? null : chats > 99 ? "99+" : chats}
            path={path}
            href={"/portal/tenants/message"}
            classes={
              path === messagesPath
                ? "activePortalLink"
                : "ListingItemConAddList"
            }
          />

          <ListingItems
            icon={<Gear className="sidebarIconLink" />}
            title={<span className="sidebarLinkTitle">{t("Settings")}</span>}
            path={path}
            classes={"ListingItemConAddList"}
            href={"#"}
          />
        </div>
      </div>
    </>
  );
};

export default SidebarTenant;
