import React from "react";
import OutlinedInput from "@mui/material/OutlinedInput"
import { useTranslation } from "react-i18next";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "90vh",
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const TextInputKeyword = ({
  items = [],
  placeholder = "Tag",
  keyword,
  handleChange,
  width = "100%",
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <div style={{ width }} className="textInputKeywordCon">
      <FormControl sx={{ width: "100%", height: 50 }}>
        <InputLabel id="demo-multiple-checkbox-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={keyword}
          onChange={handleChange}
          input={<OutlinedInput style={{ background: "white" }} label="Tag" />}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                maxHeight: 150,
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {items.map((name) => (
            <MenuItem
              key={name}
              value={name}
              sx={{ background: "white" }}
              style={getStyles(name, keyword, theme)}
            >
              <Checkbox checked={keyword.indexOf(name) > -1} />
              <ListItemText primary={t(name)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TextInputKeyword;
