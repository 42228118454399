import React from "react";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

const ConfirmPayPassword = ({
  onClick,
  onClickPaypal,
  onChange,
  error,
  confirmLoading,
  option,
  label = "Confirm payment by typing your nawafrica password",
}) => {
  return (
    <div style={{ width: "100%", padding: 10 }}>
      <TextField
        label={label}
        id="filled-start-adornment"
        sx={{ m: 1, width: "100%" }}
        variant="filled"
        name="password"
        autoComplete="off"
        type="text"
        onChange={onChange}
      />
      <div style={{ width: "100%", padding: "7px 0" }}>
        {option == "Paypal" ? (
          <Button
            style={{ width: "100%", background: "#035aa6" }}
            variant="contained"
            onClick={onClickPaypal}
            disabled={confirmLoading}
          >
            Confirm Payment
          </Button>
        ) : (
          <Button
            style={{ width: "100%", background: "#035aa6" }}
            variant="contained"
            onClick={onClick}
            disabled={confirmLoading}
          >
            Confirm Payment
          </Button>
        )}
      </div>
      {error && (
        <div
          style={{
            background: "red",
            opacity: "0.7",
            color: "white",
            borderRadius: "5px",
            padding: "5px",
          }}
          className="makePaymentError --bs-danger-border-subtle"
        >
          {error}
        </div>
      )}
    </div>
  );
};

export default ConfirmPayPassword;
