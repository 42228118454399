import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './profile.css'

const DeleteButton = ({ onClick }) => {
  const { t } = useTranslation()

  return (
    <div>
      <button
        style={{ boxShadow: '3px 1px 3px 0.5px black', outline: 'none' }}
        onClick={onClick}
        className='profileDeleteBtn'
      >
        <span style={{ marginRight: '5px' }}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </span>
        {t('DELETE')}
      </button>
    </div>
  )
}

export default DeleteButton
