import React from 'react'

const OwnerDetails = () => {
  return (
    <div>
      <h5 style={{ fontWeight: 'bold', fontSize: '18px' }}>
        Owner and Software Developer
      </h5>
      <div style={{ fontSize: '15px', opacity: '0.9', marginTop: '10px' }}>
        Software Development Office,
      </div>
      <div style={{ fontSize: '15px', opacity: '0.9' }}>
        {' '}
        Captivator Technologies inc.
      </div>
      <div style={{ fontSize: '15px', opacity: '0.9' }}>
        Cite six, Dallas inn hotel, Bota, Cameroon
      </div>
      <div
        style={{
          fontSize: '15px',
          opacity: '0.9',
          marginTop: '10px',
          fontStyle: 'italic',
        }}
      >
        To exercise your rights under this Notice, please send an email with the
        subject "Data Subject Rights Request" to the following:
      </div>
      <div style={{ fontSize: '15px', marginTop: '10px' }}>
        <span style={{ fontWeight: 'bold', marginRight: '5px' }}>
          Owner contact email:
        </span>
        felix@captivatortechnologies.com
      </div>
    </div>
  )
}

export default OwnerDetails
