import { TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const TenantSeachName = ({ onChangeTenantName }) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px 0",
        width: "100%",
      }}
    >
      <TextField
        style={{ width: "30%" }}
        id="standard-basic"
        label={t("Search Tenant By Name")}
        variant="standard"
        inputProps={{
          style: { fontSize: 12, color: "#035aa6" },
        }}
        InputLabelProps={{
          style: { fontSize: 12, textAlign: "center", color: "#035aa6" },
        }}
        onChange={onChangeTenantName}
      />
    </div>
  );
};

export default TenantSeachName;
