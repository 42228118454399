import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

import BlogDetailBody from "../components/BlogDetails/BlogDetailBody";
import HeadBlogDetails from "../components/BlogDetails/HeadBlogDetails";
import { deleteBlogById, getBlog, getBlogById } from "../Services/blogService";
// import Meta from '../Utils/Meta'
import Meta1 from "../Utils/Meta1";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import DownloadApp from "../components/AboutUs/DownloadApp";

const BlogDetailsScreen = ({ match, history }) => {
  const [item, setItem] = useState({});
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState();

  useEffect(() => {
    getData();
  }, [history]);

  const getData = async () => {
    try {
      const id = match.params.id;
      setID(id);
      setLoading(true);
      const { data } = await getBlogById(id);
      if (!data) return history.push("/blog");
      const { data: items } = await getBlog();
      setBlogs(items);
      setItem(data);
      setLoading(false);
    } catch (ex) {
      history.push("/blog");
    }
  };

  const onClickBlogDeleted = async (id) => {
    if (window.confirm("Are you sure you want to delete this blog?")) {
      await deleteBlogById(id);
      history.push("/blog");
    }
  };

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
      </div>
    );
  return (
    <>
      <Meta1
        title={`Nawafrica | Blog Detail for ${item.title}`}
        description={`${item.title}`}
        link={`/blog/${item.id}`}
      />
      <div style={{ width: "100%", fontFamily: "'Montserrat', sans-serif" }}>
        <HeadBlogDetails onClickDelete={onClickBlogDeleted} item={item} />
        <BlogDetailBody
          onClickBlogDeleted={async (id) => {
            if (window.confirm("Are you sure you want to delete this blog?")) {
              const blog = [...blogs];
              const remainingBlog = blog.filter((b) => b.id !== id);
              setBlogs(remainingBlog);
              await deleteBlogById(id);
              if (id === match.params.id) {
                history.push("/blog");
              }
            }
          }}
          item={item}
          blogs={blogs}
          ID={ID}
        />

        <DownloadApp />
        <ScrollToTop smooth />
      </div>
    </>
  );
};

export default BlogDetailsScreen;
