import React from "react";
import { Image } from "cloudinary-react";

import "./messageDetail.css";

const MessagePicModal = ({ url }) => {
  return (
    <div
      className="modal fade"
      id="exampleModalShowMsgPic"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content specificLargeImageInMessageDetCon">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div style={{ backgroundColor: "lightgray" }} className="modal-body">
            {url.publicId ? (
              <Image
                crossOrigin="anonymous"
                className="specificImageInMessageDetToShow"
                cloudName="captivator"
                publicId={url.public_id}
                style={{ objectFit: "cover" }}
              />
            ) : (
              <img
                style={{ objectFit: "cover" }}
                crossOrigin="anonymous"
                className="specificImageInMessageDetToShow"
                src={url.url}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessagePicModal;
