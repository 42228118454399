import React, { useEffect } from 'react'
import Lottie from 'react-lottie'

import { done } from './doneFunction'

const DoneAnimate = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: done(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div
      style={{
        width: '400px',
        height: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
      }}
    >
      <Lottie options={defaultOptions} height={300} width={300} />
    </div>
  )
}

export default DoneAnimate
