import React from 'react'
import Lottie from 'react-lottie'
import { doneAnimate } from './msgDoneLottieAnimate'

const SentMsgDone = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: doneAnimate(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={250} width={250} />
    </div>
  )
}

export default SentMsgDone
