import React from "react";

const CardFlag = ({
  src = "https://upload.wikimedia.org/wikipedia/commons/thumb/7/77/Flag_of_Algeria.svg/1200px-Flag_of_Algeria.svg.png",
}) => {
  return (
    <div
      style={{
        width: "25px",
        height: "25px",
        overflow: "hidden",
        position: "absolute",
        zIndex: "2",
        borderRadius: "100%",
        margin: "2px",
      }}
    >
      <img
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          opacity: "0.8",
        }}
        src={src}
      />
    </div>
  );
};

export default CardFlag;
