import React from 'react'
import Lottie from 'react-lottie'
import { noWifiAnimation } from './no-wifi'

const NoWifi = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noWifiAnimation(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={50} width={50} />
    </div>
  )
}

export default NoWifi
