import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCode,
  faMobileScreenButton,
  faLock,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons'
import 'aos/dist/aos.css'

const CareerReactCarousel = () => {
  return (
    <Carousel interval={10000} autoPlay infiniteLoop>
      <div className='careerCarouselInnerCon'>
        <div className='careerImageCon'>
          <img
            crossorigin='anonymous'
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            src='../../images/it-team.jpg'
          />
        </div>
        <div className='careerWebDev'>
          <FontAwesomeIcon className='careerIconss' icon={faCode} />
          <h5>WEB DEVELOPERS</h5> Captivator technologies inc is seeking skilled
          web developers who are eager to push their limits as they work with
          highly skilled software engineers. Captivator technologies inc is
          looking for someone who can bring their technical expertise and
          creativity together with ours, A team that thinks
          differently.Captivator technologies inc. offers competitive salary and
          benefits package.Apply today.
        </div>
      </div>
      <div className='careerCarouselInnerCon'>
        <div className='careerImageCon'>
          <img
            crossorigin='anonymous'
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            src='../../images/mobile.jpg'
          />
        </div>
        <div className='careerMobileDev'>
          <FontAwesomeIcon
            className='careerIconss'
            icon={faMobileScreenButton}
          />
          <h5>MOBILE APP DEVELOPERS</h5>
          <p style={{ color: 'white', opacity: '1', fontSize: '16px' }}>
            We're looking for innovators. Captivator technologies inc. Is in
            need of mobile app developers who can use their skills to build apps
            that help solve problems in the community.We need mobile app
            developers. We are looking for passionate people who have the
            ability to develop captivating apps that solve issues in
            communities.Apply today.
          </p>
        </div>
      </div>
      <div className='careerCarouselInnerCon'>
        <div className='careerImageCon'>
          <img
            crossorigin='anonymous'
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            src='../../images/cyber.jpg'
          />
        </div>
        <div className='careerCyberSec'>
          <FontAwesomeIcon className='cyberSecIcon' icon={faLock} />
          <h5>CYBER SECURITY</h5>
          You have one shot at making a first impression.In a world where cyber
          crime is on a huge increase, Our cyber security experts are dedicated
          to ensuring that applications are protected against malicious
          users.Join our team and help us protect cyber space.Apply today!
        </div>
      </div>
      <div className='careerCarouselInnerCon'>
        <div className='careerImageCon'>
          <img
            crossorigin='anonymous'
            style={{ height: '450px', width: '100%', objectFit: 'cover' }}
            src='../../images/property.jpg'
          />
        </div>
        <div className='careerRealEst'>
          <FontAwesomeIcon className='careerIconss' icon={faWarehouse} />
          <h5>REAL ESTATE AND PROPERTY MANAGERS</h5>
          Captivator technologies inc. Our nawafrica real estate brand makes
          room for property managers who want to use our platform.Landlords love
          us because we take care of their properties. Nawafrica helps landlords
          keep tabs on their property while they're away so they never have to
          worry about anything again.For property managers looking to get their
          real estate empire international, Captivator technologies inc is here
          for you. Nawafrica makes room for local companies seeking an edge in
          the global marketplace by managing properties abroad. Contact us today
          to learn more about how we can help your company grow into new
          markets.
        </div>
      </div>
    </Carousel>
  )
}

export default CareerReactCarousel
