import React from 'react'
import './PropertyManagementDet.css'

const PropDetHead = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '35vh',
        background: 'black',
        display: 'flex',
        padding: '30px',
        opacity: '0.8',
        borderBottom: '5px solid #035aa6',
      }}
    >
      <div
        style={{
          width: '40%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <div
          style={{
            width: '250px',
            height: '250px',
            borderRadius: '100%',
            overflow: 'hidden',
            border: '3px solid #035aa6',
          }}
        >
          <img
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
            src={'../../images/it-team.jpg'}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',

          justifyContent: 'center',
          color: 'white',
          paddingLeft: '50px',
          flexDirection: 'column',
        }}
      >
        <h1 style={{ color: '#ffa500' }}>Nawafrica's Real Estate Agent</h1>
        <h2>James Bond</h2>
        <h3>Lagos, Nigeria</h3>
        <h4>237650432299</h4>
      </div>
    </div>
  )
}

export default PropDetHead
