//function that contains country code of all countries in Africa
export function callingCode() {
  let callingCodes = [
    { code: '+358 18', ctry: 'Åland Islands' },
    { code: '+93', ctry: 'Afghanistan' },
    { code: '+355', ctry: 'Albania' },
    { code: '+213', ctry: 'Algeria' },
    { code: '+1 684', ctry: 'American Samoa' },
    { code: '+376', ctry: 'Andorra' },
    { code: '+244', ctry: 'Angola' },
    { code: '+1 264', ctry: 'Anguilla' },
    { code: '+1 268', ctry: 'Antigua and Barbuda' },
    { code: '+54', ctry: 'Argentina' },
    { code: '+374', ctry: 'Armenia' },
    { code: '+297', ctry: 'Aruba' },
    { code: '+247', ctry: 'Ascension Island' },
    { code: '+61', ctry: 'Australia' },
    { code: '+672 1', ctry: 'Australian Antarctic Territory' },
    { code: '+43', ctry: 'Austria' },
    { code: '+994', ctry: 'Azerbaijan' },
    { code: '+1 242', ctry: 'Bahamas' },
    { code: '+973', ctry: 'Bahrain' },
    { code: '+880', ctry: 'Bangladesh' },
    { code: '+1 246', ctry: 'Barbados' },
    { code: '+375', ctry: 'Belarus' },
    { code: '+32', ctry: 'Belgium' },
    { code: '+501', ctry: 'Belize' },
    { code: '+229', ctry: 'Benin' },
    { code: '+1 441', ctry: 'Bermuda' },
    { code: '+975', ctry: 'Bhutan' },
    { code: '+591', ctry: 'Bolivia' },
    { code: '+387', ctry: 'Bosnia and Herzegovina' },
    { code: '+267', ctry: 'Botswana' },
    { code: '+47', ctry: 'Bouvet Island' },
    { code: '+55', ctry: 'Brazil' },
    { code: '+246', ctry: 'British Indian Ocean Territory' },
    { code: '+1 284', ctry: 'British Virgin Islands' },
    { code: '+673', ctry: 'Brunei' },
    { code: '+359', ctry: 'Bulgaria' },
    { code: '+226', ctry: 'Burkina Faso' },
    { code: '+257', ctry: 'Burundi' },
    { code: '+855', ctry: 'Cambodia' },
    { code: '+237', ctry: 'Cameroon' },
    { code: '+1', ctry: 'Canada' },
    { code: '+238', ctry: 'Cape Verde' },
    { code: '+599', ctry: 'Caribbean Netherlands' },
    { code: '+1 3459', ctry: 'Cayman Islands' },
    { code: '+236', ctry: 'Central African Republic' },
    { code: '+235', ctry: 'Chad' },
    { code: '+56', ctry: 'Chile' },
    { code: '+86', ctry: 'China' },
    { code: '+61 89164', ctry: 'Christmas Island' },
    { code: '+61 89162', ctry: 'Cocos (Keeling) Islands' },
    { code: '+57', ctry: 'Colombia' },
    { code: '+269', ctry: 'Comoros' },
    { code: '+682', ctry: 'Cook Islands' },
    { code: '+506', ctry: 'Costa Rica' },
    { code: '+385', ctry: 'Croatia' },
    { code: '+53', ctry: 'Cuba' },
    { code: '+599 9', ctry: 'Curaçao' },
    { code: '+357', ctry: 'Cyprus' },
    { code: '+420', ctry: 'Czech Republic' },
    { code: '+243', ctry: 'Democratic Republic of the Congo' },
    { code: '+45', ctry: 'Denmark' },
    { code: '+253', ctry: 'Djibouti' },
    { code: '+1 767', ctry: 'Dominica' },
    { code: '+1 809', ctry: 'Dominican Republic' },
    { code: '+670', ctry: 'East Timor' },
    { code: '+593', ctry: 'Ecuador' },
    { code: '+20', ctry: 'Egypt' },
    { code: '+503', ctry: 'El Salvador' },
    { code: '+240', ctry: 'Equatorial Guinea' },
    { code: '+291', ctry: 'Eritrea' },
    { code: '+372', ctry: 'Estonia' },
    { code: '+268', ctry: 'Eswatini' },
    { code: '+251', ctry: 'Ethiopia' },
    { code: '+500', ctry: 'Falkland Islands' },
    { code: '+298', ctry: 'Faroe Islands' },
    { code: '+679', ctry: 'Fiji' },
    { code: '+358', ctry: 'Finland' },
    { code: '+33', ctry: 'France' },
    { code: '+594', ctry: 'French Guiana' },
    { code: '+689', ctry: 'French Polynesia' },
    { code: '+262', ctry: 'French Southern and Antarctic Lands' },
    { code: '+241', ctry: 'Gabon' },
    { code: '+220', ctry: 'Gambia' },
    { code: '+995', ctry: 'Georgia' },
    { code: '+49', ctry: 'Germany' },
    { code: '+233', ctry: 'Ghana' },
    { code: '+350', ctry: 'Gibraltar' },
    { code: '+30', ctry: 'Greece' },
    { code: '+299', ctry: 'Greenland' },
    { code: '+1 473', ctry: 'Grenada' },
    { code: '+590', ctry: 'Guadeloupe' },
    { code: '+1 671', ctry: 'Guam' },
    { code: '+502', ctry: 'Guatemala' },
    { code: '+44 1481', ctry: 'Guernsey' },
    { code: '+224', ctry: 'Guinea' },
    { code: '+245', ctry: 'Guinea-Bissau' },
    { code: '+592', ctry: 'Guyana' },
    { code: '+509', ctry: 'Haiti' },
    { code: '+1 672', ctry: 'Heard Island and McDonald Islands' },
    { code: '+504', ctry: 'Honduras' },
    { code: '+852', ctry: 'Hong Kong' },
    { code: '+36', ctry: 'Hungary' },
    { code: '+354', ctry: 'Iceland' },
    { code: '+91', ctry: 'India' },
    { code: '+62', ctry: 'Indonesia' },
    { code: '+98', ctry: 'Iran' },
    { code: '+964', ctry: 'Iraq' },
    { code: '+353', ctry: 'Ireland' },
    { code: '+44 1624', ctry: 'Isle of Man' },
    { code: '+972', ctry: 'Israel' },
    { code: '+39', ctry: 'Italy' },
    { code: '+225', ctry: 'Ivory Coast' },
    { code: '+1 876', ctry: 'Jamaica' },
    { code: '+81', ctry: 'Japan' },
    { code: '+44 1534', ctry: 'Jersey' },
    { code: '+962', ctry: 'Jordan' },
    { code: '+7 6', ctry: 'Kazakhstan' },
    { code: '+254', ctry: 'Kenya' },
    { code: '+686', ctry: 'Kiribati' },
    { code: '+383', ctry: 'Kosovo' },
    { code: '+965', ctry: 'Kuwait' },
    { code: '+996', ctry: 'Kyrgyzstan' },
    { code: '+856', ctry: 'Laos' },
    { code: '+371', ctry: 'Latvia' },
    { code: '+961', ctry: 'Lebanon' },
    { code: '+266', ctry: 'Lesotho' },
    { code: '+231', ctry: 'Liberia' },
    { code: '+218', ctry: 'Libya' },
    { code: '+423', ctry: 'Liechtenstein' },
    { code: '+370', ctry: 'Lithuania' },
    { code: '+352', ctry: 'Luxembourg' },
    { code: '+853', ctry: 'Macao' },
    { code: '+261', ctry: 'Madagascar' },
    { code: '+265', ctry: 'Malawi' },
    { code: '+60', ctry: 'Malaysia' },
    { code: '+960', ctry: 'Maldives' },
    { code: '+223', ctry: 'Mali' },
    { code: '+356', ctry: 'Malta' },
    { code: '+692', ctry: 'Marshall Islands' },
    { code: '+596', ctry: 'Martinique' },
    { code: '+222', ctry: 'Mauritania' },
    { code: '+230', ctry: 'Mauritius' },
    { code: '+262 269', ctry: 'Mayotte' },
    { code: '+52', ctry: 'Mexico' },
    { code: '+691', ctry: 'Micronesia' },
    { code: '+373', ctry: 'Moldova' },
    { code: '+377', ctry: 'Monaco' },
    { code: '+976', ctry: 'Mongolia' },
    { code: '+382', ctry: 'Montenegro' },
    { code: '+1 664', ctry: 'Montserrat' },
    { code: '+212', ctry: 'Morocco' },
    { code: '+258', ctry: 'Mozambique' },
    { code: '+95', ctry: 'Myanmar' },
    { code: '+264', ctry: 'Namibia' },
    { code: '+674', ctry: 'Nauru' },
    { code: '+977', ctry: 'Nepal' },
    { code: '+31', ctry: 'Netherlands' },
    { code: '+599', ctry: 'Netherlands Antilles' },
    { code: '+687', ctry: 'New Caledonia' },
    { code: '+64', ctry: 'New Zealand' },

    { code: '+505', ctry: 'Nicaragua' },
    { code: '+227', ctry: 'Niger' },
    { code: '+234', ctry: 'Nigeria' },
    { code: '+683', ctry: 'Niue' },
    { code: '+672 3', ctry: 'Norfolk Island' },
    { code: '+850', ctry: 'North Korea' },
    { code: '+389', ctry: 'North Macedonia' },
    { code: '+44 28', ctry: 'Northern Ireland' },
    { code: '+1 670', ctry: 'Northern Mariana Islands' },
    { code: '+47', ctry: 'Norway' },
    { code: '+968', ctry: 'Oman' },
    { code: '+92', ctry: 'Pakistan' },
    { code: '+680', ctry: 'Palau' },
    { code: '+970', ctry: 'Palestine' },
    { code: '+507', ctry: 'Panama' },
    { code: '+675', ctry: 'Papua New Guinea' },
    { code: '+595', ctry: 'Paraguay' },
    { code: '+51', ctry: 'Peru' },
    { code: '+63', ctry: 'Philippines' },
    { code: '+64', ctry: 'Pitcairn' },
    { code: '+48', ctry: 'Poland' },
    { code: '+351', ctry: 'Portugal' },
    { code: '+1 787', ctry: 'Puerto Rico' },
    { code: '+974', ctry: 'Qatar' },
    { code: '+262', ctry: 'Réunion' },
    { code: '+242', ctry: 'Republic of the Congo' },
    { code: '+40', ctry: 'Romania' },
    { code: '+7', ctry: 'Russia' },
    { code: '+250', ctry: 'Rwanda' },
    { code: '+590', ctry: 'Saint Barthélemy' },
    { code: '+290', ctry: 'Saint Helena' },
    { code: '+55', ctry: 'Saint Kitts and Nevis' },
    { code: '+1 869', ctry: 'British Indian Ocean Territory' },
    { code: '+1 758', ctry: 'Saint Lucia' },
    { code: '+590', ctry: 'Saint Martin' },
    { code: '+508', ctry: 'Saint Pierre and Miquelon' },
    { code: '+1 784', ctry: 'Saint Vincent and the Grenadines' },
    { code: '+685', ctry: 'Samoa' },
    { code: '+378', ctry: 'San Marino' },
    { code: '+239', ctry: 'Sao Tome and Principe' },
    { code: '+966', ctry: 'Saudi Arabia' },
    { code: '+221', ctry: 'Senegal' },
    { code: '+381', ctry: 'Serbia' },
    { code: '+248', ctry: 'Seychelles' },
    { code: '+232', ctry: 'Sierra Leone' },
    { code: '+65', ctry: 'Singapore' },
    { code: '+421', ctry: 'Slovakia' },
    { code: '+86', ctry: 'Slovenia' },
    { code: '+677', ctry: 'Solomon Islands' },
    { code: '+252', ctry: 'Somalia' },
    { code: '+27', ctry: 'South Africa' },
    { code: '+500', ctry: 'South Georgia and the South Sandwich Islands' },
    { code: '+82', ctry: 'South Korea' },
    { code: '+211', ctry: 'South Sudan' },
    { code: '+34', ctry: 'Spain' },
    { code: '+94', ctry: 'Sri Lanka' },
    { code: '+249', ctry: 'Sudan' },
    { code: '+597', ctry: 'Suriname' },
    { code: '+47 79', ctry: 'Svalbard and Jan Mayen' },
    { code: '+46', ctry: 'Sweden' },
    { code: '+41', ctry: 'Switzerland' },
    { code: '+963', ctry: 'Syria' },
    { code: '+886', ctry: 'Taiwan' },
    { code: '+992', ctry: 'Tajikistan' },
    { code: '+255', ctry: 'Tanzania' },
    { code: '+66', ctry: 'Thailand' },
    { code: '+228', ctry: 'Togo' },
    { code: '+690', ctry: 'Tokelau' },
    { code: '+676', ctry: 'Tonga' },
    { code: '+1 868', ctry: 'Trinidad and Tobago' },
    { code: '+216', ctry: 'Tunisia' },
    { code: '+90', ctry: 'Turkey' },
    { code: '+993', ctry: 'Turkmenistana' },
    { code: '+1 649', ctry: 'Turks and Caicos Islands' },
    { code: '+688', ctry: 'Tuvalu' },
    { code: '+256', ctry: 'Uganda' },
    { code: '+380', ctry: 'Ukraine' },
    { code: '+971', ctry: 'United Arab Emirates' },
    { code: '+44', ctry: 'United Kingdom' },
    { code: '+1', ctry: 'United States' },
    { code: '+246', ctry: 'United States Minor Outlying Islands' },
    { code: '+598', ctry: 'Uruguay' },
    { code: '+998', ctry: 'Uzbekistan' },
    { code: '+678', ctry: 'Vanuatu' },
    { code: '+379', ctry: 'Vatican City' },
    { code: '+58', ctry: 'Venezuela' },
    { code: '+84', ctry: 'Vietnam' },
    { code: '+1 340', ctry: 'Virgin Islands, U.S.' },
    { code: '+681', ctry: 'Wallis and Futuna' },
    { code: '+212', ctry: 'Western Sahara' },
    { code: '+967', ctry: 'Yemen' },
    { code: '+260', ctry: 'Zambia' },
    { code: '+263', ctry: 'Zimbabwe' },
  ]

  return callingCodes
}
