import React from 'react'

import './addListing.css'

const Textarea = ({label, value, id, onChange, placeholder}) => {
return (
<>
<label className="addListTextareaLabel" htmlFor={id}>{label}</label>
<textarea value={value} rows="3"  type="text" className="form-control addListTextarea" onChange={onChange} id={id} placeholder={placeholder}/>
</>
)
}

export default Textarea
