import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const HeaderCrew = ({ title, subtitle }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, [])
  return (
    <>
      <h1
        data-aos='flip-up'
        style={{ textAlign: 'center', margin: '100px auto 20px auto' }}
      >
        {title}
      </h1>
      <h3 data-aos='flip-down' style={{ color: 'grey', textAlign: 'center' }}>
        {subtitle}
      </h3>
    </>
  )
}

export default HeaderCrew
