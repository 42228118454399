import React from 'react'

const SubHeading = ({ title }) => {
  return (
    <div>
      <h5 style={{ fontWeight: 'bold', fontSize: '17px', color: '#ffa500' }}>
        {title}
      </h5>
    </div>
  )
}

export default SubHeading
 