import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import _ from "lodash";
import { log } from "../Services/loggerService.js";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import LinearProgress from "@mui/material/LinearProgress";

import WelcomeUser from "../components/AddListing/WelcomeUser";
import TitleHeader from "../components/AddListing/TitleHeader";
import Input from "../components/AddListing/Input";
import HrInfo from "../components/AddListing/HrInfo";
import Textarea from "../components/AddListing/Textarea";
import Select from "../components/AddListing/Select";
import Checkbox from "../components/AddListing/Checkbox";
import SaveButton from "../components/AddListing/SaveButton";
import Chats from "../components/AddListing/Chats";
import Sidebar from "../components/AddListing/Sidebar";
import OpenButton from "../components/AddListing/OpenButton";
import AddressInput from "../components/AddListing/AddressInput";
import PositionCheckbox from "../components/AddListing/PositionCheckbox";
import LeasingType from "../components/AddListing/LeasingType";
import DownShowSideBar from "../components/AddListing/DownShowSideBar.js";

import { Countries } from "../Utils/regions";
import { Regions } from "../Utils/regions";
import { addListing, editListing, getListingsById } from "../Services/listings";

import "../components/AddListing/addListing.css";
import ProfileSpinner from "../components/profile/ProfileSpinner";
import { getCurrentUser } from "../Services/authService";
import UnreadMsgsContext from "./../unreadmessages/context";
import { uploadSingleFileToCloudinary } from "./../Utils/imageUploadToCloudinary";
import { uploadMultipleFileToCloudinary } from "./../Utils/imageUploadToCloudinary";
import { getCloudinarySignature } from "../Services/cloudinaryService";
import logger from "../Services/loggerService.js";
// import Meta from './../Utils/Meta'

import "../components/AddListing/addListing.css";
import Meta1 from "../Utils/Meta1.js";
import AddListingLoader from "../components/AddListing/AddListingLoader.js";
import TextInputKeywordAdd from "../components/AddListing/TextInputKeywordAdd.js";

const AddListing = ({ match, history }) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");

  const [listingType, setListingType] = useState("");
  const [listingTypes, setListingTypes] = useState([]);

  const [rentalOrSale, setRentalOrSale] = useState("");
  const [rentalOrSales, setRentalOrSales] = useState([]);

  const [bedrooms, setBedrooms] = useState("");
  const [spaces, setSpaces] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  const [kitchens, setKitchen] = useState("");
  const [garages, setGarages] = useState("");
  const [lotSize, setLotSize] = useState("");
  const [buildingSize, setBuildingSize] = useState("");

  const [keyword, setKeyword] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");

  const [states, setStates] = useState([]);
  const [state, setState] = useState("");

  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");

  const [streetAddress, setStreetAddress] = useState("");
  const [streetAddressCoord, setStreetAddressCoord] = useState({
    loading: false,
    coordinates: { lat: "", lng: "" },
  });

  const [zipCode, setZipCode] = useState("");
  const [details, setDetails] = useState("");

  const [price, setPrice] = useState("");
  const [advancePrice, setAdvancePrice] = useState("");
  const [currency, setCurrency] = useState("");

  const [mainPhotoPath, setMainPhotoPath] = useState("");
  const [mainPhotoError, setMainPhotoError] = useState("");
  const [loadMainPhoto, setLoadMainPhoto] = useState(false);

  const [parlourPhotoPath, setParlourPhotoPath] = useState("");
  const [parlourPhotoError, setParlourPhotoError] = useState("");
  const [loadParlourPhoto, setLoadParlourPhoto] = useState(false);

  const [bedroomPhotoPath, setBedroomPhotoPath] = useState("");
  const [bedroomPhotoError, setBedroomPhotoError] = useState("");
  const [loadBedroomPhoto, setLoadBedroomPhoto] = useState(false);

  const [otherPhotoPath, setOtherPhotoPath] = useState([]);
  const [otherPhotoError, setOtherPhotoError] = useState("");
  const [loadOtherPhoto, setLoadOtherPhoto] = useState(false);

  const [role, setRole] = useState([]);
  const [myRole, setMyRole] = useState("");

  const [publish, setPublish] = useState("");
  const [iAm, SetIAm] = useState("");

  const [loadingProgress, setLoadingProgress] = useState(0);
  const [uploadDataError, setUploadDataError] = useState("");

  const [location, setLocation] = useState({
    loaded: false,
    coordinates: {
      lat: "",
      lng: "",
    },
  });

  const [locationCountry, setLocationCountry] = useState("");
  const [locationError, setLocationError] = useState({
    loaded: false,
    error: "",
  });

  const [locationFromAddress, setLocationFromAddress] = useState({
    loaded: false,
    coordinates: {
      lat: "",
      lng: "",
    },
  });

  const [close, setClose] = useState(true);
  const [path, setPath] = useState("");
  const [upSideBar, setUpSideBar] = useState(false);

  const [loadingEditData, setLoadingEditData] = useState(false);

  const [sdkReady, setSdkReady] = useState(false);

  const [fixedAgreement, setFixedAgreement] = useState("");
  const [renewFixedAgreement, setRenewFixedAgreement] = useState("");
  const [continueMonthTonMonth, setContinueMonthTonMonth] = useState("");
  const [vacate, setVacate] = useState("");
  const [monthToMonth, setMonthToMonth] = useState("");
  const [soldAgreement, setSoldAgreement] = useState("");

  const [myImageData, setMyImageData] = useState({
    cloud_name: "",
  });
  const [langType, setLangType] = useState("");
  const [owner_phone_num, setOwner_phone_num] = useState("");
  const { unreadMsgs } = useContext(UnreadMsgsContext);

  const onSuccess = async (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });

    const { data: mapKey } = await axios.get("/map");

    //function to get towns and country base on the users location
    let url =
      mapKey &&
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coords.latitude},${location.coords.longitude}&key=${mapKey}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const myLocation = data.results[0].formatted_address;
        const locationArray = myLocation.split(",");
        setLocationCountry(locationArray[locationArray.length - 1].trim());
      })
      .catch((err) =>
        console.warn("error from onSuccess function", err.message)
      );
  };

  const onError = (error) => {
    setLocationError({
      loaded: true,
      error,
    });
    setLocation({
      loaded: false,
      coordinates: {
        lat: "",
        lng: "",
      },
    });
  };

  useEffect(() => {
    const mapScript = async () => {
      try {
        if (
          getCurrentUser() &&
          getCurrentUser().post_rent_property === "LOOKING TO BUY/RENT PROPERTY"
        )
          return history.push("/");
        //execute the script to usePlaceAutoComplete
        const { data: mapKey } = await axios.get("/map");

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = `https://maps.googleapis.com/maps/api/js?key=${mapKey}&libraries=places`;
        script.onload = () => {
          setSdkReady(true);
        };
        document.body.appendChild(script);

        let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${streetAddress}&key=${mapKey}`;
        fetch(url)
          .then((response) => response.json())
          .then((data) => {
            // logger.log('here is my location====', data)

            setStreetAddressCoord({
              loaded: true,
              coordinates: {
                lat: data.geometry ? data.geometry.location.lat : "",
                lng: data.geometry ? data.geometry.location.lng : "",
              },
            });
          })
          .catch((error) => {
            logger.log(error);
          });
      } catch (error) {
        logger.log("mapScript function error");
      }
    };

    mapScript();

    setCountries([...Countries()]);

    //code to get the user current location

    if (!("geolocation" in navigator)) {
      setLocation({
        ...location,
        loaded: true,
        error: {
          code: 0,
          message: "geolocation not supported",
        },
      });
    } else {
      navigator.geolocation.getCurrentPosition(onSuccess, onError, {
        enableHighAccuracy: true,
      });
    }

    localStorage.setItem("portalPath", "/portal/add/add_listing");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    window.scrollTo(0, 0);

    const listingTypes = [
      "Single family house",
      "Town house",
      "Apartment",
      "Studio",
      "Single Room",
      "Office",
      "Store/Shop",
      "Land",
      "Condos",
    ];
    setListingTypes(listingTypes);

    const rentalOrSale = ["Rental", "Sale"];
    setRentalOrSales(rentalOrSale);

    const Keywords = [
      "Pool",
      "Flowers",
      "Garden",
      "Playground",
      "Gym",
      "Balcony",
      "Air condition",
      "Furnished",
      "Inner Toilet",
      "Inner Kitchen",
      "Inner Bathroom",
      "Constant Water Supply",
      "Electricity",
      "Security",
      "Fence",
      "Internet",
      "Cable TV",
      "Parking",
      "Central Town",
      "Near School",
      "Near Hospital",
      "Near Market",
      "Near Police Station",
      "Near Bus Station",
      "Near Airport",
      "Near Beach",
      "Friendly Neighborhood",
      "Quiet Neighborhood",
      "Near Main Road",
    ];

    setKeywords(Keywords.sort());

    const roles = [
      "I am the Owner",
      "I am an Agent / Broker",
      "I am a Property Manager",
    ];
    setRole(roles);

    getAListingById();

    return () => {
      localStorage.setItem("portalPath", "");
    };
  }, []);

  //function to get a listing by id to Edit a listing
  const getAListingById = async () => {
    try {
      setLangType(cookies.get("i18next") || "en");
      const ID = match.params.id;
      if (ID) {
        setLoadingEditData(true);
        const { data } = await getListingsById(ID);
        const coordinateDate = {
          loaded: false,
          coordinates: {
            lat: data.location.coordinates.lat,
            lng: data.location.coordinates.lng,
          },
        };

        setLocationFromAddress(coordinateDate);
        setTitle(data.title);
        setListingType(data.listing_type);
        setRentalOrSale(data.rent_or_sell);
        setBedrooms(data.bedrooms);
        setBathrooms(data.bathrooms);
        setKitchen(data.kitchen);
        setGarages(data.garages);
        setLotSize(data.lot_size);
        setBuildingSize(data.building_size);
        setKeyword(data.keyword);
        setCountry(data.country);
        setState(data.state);
        setSpaces(data.quantity_of_space);
        setStreetAddress(data.street_address);
        setZipCode(data.zip_code);
        setCity(data.city);
        setDetails(data.details);
        setPrice(data.price);
        setAdvancePrice(data.advance_price);
        setMainPhotoPath(data.main_photo.path);
        setParlourPhotoPath(data.parlour_photo.path);
        setBedroomPhotoPath(data.bedroom_photo.path);
        setOtherPhotoPath(data.other_photos);
        setMyRole(data.listing_role);
        setPublish(data.publish);
        setLoadingEditData(false);
        setCurrency(data.currency);
        setOwner_phone_num(data.owner_phone_num);

        if (data.fixed_agreement) {
          setFixedAgreement("Fixed Agreement");
        }

        if (data.month_to_month_agreement) {
          setMonthToMonth("Month-To-Month Agreement");
        }

        if (data.agreement_after_fixed_agreement.continue_with_month_to_month) {
          setRenewFixedAgreement("Continue Month To Month");
        }
        if (data.agreement_after_fixed_agreement.renew_fixed_agreement) {
          setRenewFixedAgreement("renew fixed Agreement");
        }
        if (data.agreement_after_fixed_agreement.vacateP) {
          setVacate("vacate the premise");
        }

        const { data: imagesData } = await getCloudinarySignature();
        setMyImageData(imagesData);
      } else {
        setLoadingEditData(true);
        const { data: imagesData } = await getCloudinarySignature();
        if (imagesData) {
          setMyImageData(imagesData);
        }
        setLoadingEditData(false);
      }
    } catch (error) {
      log("getAListingById function error", error);
    }
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to open the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to upload the main photo using cloudinary
  const onChangeMainPhoto = async (e) => {
    e.preventDefault();
    const filesss = e.currentTarget.files[0];
    if (!filesss) return;
    if (filesss.size > 8323863) {
      setMainPhotoError(
        "large file, upload a smaller file not greater than 8MB"
      );
    } else {
      setLoadMainPhoto(true);
      setMainPhotoError("");
      let src = URL.createObjectURL(filesss);

      //convert to canvas
      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      let userImage = new Image();
      userImage.src = src;
      userImage.onload = () => {
        canvas.width = userImage.naturalWidth;
        canvas.height = userImage.naturalHeight;
        ctx.drawImage(userImage, 0, 0);

        //convert canvas to webP
        //     let webPImage = canvas.toDataURL('image/webp')

        canvas.toBlob(async (blob) => {
          const myImage = new File([blob], filesss.name, { type: blob.type });
          const result = await uploadSingleFileToCloudinary(
            myImage,
            myImageData
          );

          logger.log(result);
          if (
            !result ||
            !result.url ||
            (result && result.path && result.path["error"])
          ) {
            setMainPhotoError(
              "The was an error loading your image, please reload this image"
            );
            setLoadMainPhoto(false);
            return;
          }
          setMainPhotoPath(result);
          setLoadMainPhoto(false);
        }, "image/webp");
      };
    }
  };

  //function to upload the parlour photo using cloudinary
  const onChangeParlourPhoto = async (e) => {
    e.preventDefault();
    const filesss = e.currentTarget.files[0];
    if (!filesss) return;
    if (filesss.size > 8323863) {
      setParlourPhotoError(
        "large file, upload a smaller file not greater than 8MB"
      );
    } else {
      setLoadParlourPhoto(true);
      setParlourPhotoError("");

      let src = URL.createObjectURL(filesss);

      //convert to canvas
      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      let userImage = new Image();
      userImage.src = src;
      userImage.onload = () => {
        canvas.width = userImage.naturalWidth;
        canvas.height = userImage.naturalHeight;
        ctx.drawImage(userImage, 0, 0);

        canvas.toBlob(async (blob) => {
          const myImage = new File([blob], filesss.name, { type: blob.type });

          const result = await uploadSingleFileToCloudinary(
            myImage,
            myImageData
          );
          logger.log(result);
          if (
            !result ||
            !result.url ||
            (result && result.path && result.path["error"])
          ) {
            setParlourPhotoError(
              "The was an error loading your image, please reload this image"
            );
            setLoadParlourPhoto(false);
            return;
          }

          setParlourPhotoPath(result);
          setLoadParlourPhoto(false);
        }, "image/webp");
      };
    }
  };

  //function to upload the bedroom photo using cloudinary
  const onChangeBedroomPhoto = async (e) => {
    e.preventDefault();
    const filesss = e.currentTarget.files[0];
    if (!filesss) return;
    if (filesss.size > 8323863) {
      setBedroomPhotoError(
        "large file, upload a smaller file not greater than 8MB"
      );
    } else {
      setLoadBedroomPhoto(true);
      setBedroomPhotoError("");

      let src = URL.createObjectURL(filesss);

      //convert to canvas
      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      let userImage = new Image();
      userImage.src = src;
      userImage.onload = () => {
        canvas.width = userImage.naturalWidth;
        canvas.height = userImage.naturalHeight;
        ctx.drawImage(userImage, 0, 0);

        canvas.toBlob(async (blob) => {
          const myImage = new File([blob], filesss.name, { type: blob.type });

          const result = await uploadSingleFileToCloudinary(
            myImage,
            myImageData
          );

          logger.log(result);
          if (
            !result ||
            !result.url ||
            (result && result.path && result.path["error"])
          ) {
            setBedroomPhotoError(
              "The was an error loading your image, please reload this image"
            );
            setLoadBedroomPhoto(false);
            return;
          }

          setBedroomPhotoPath(result);
          setLoadBedroomPhoto(false);
        }, "image/webp");
      };
    }
  };

  // //function to upload the bedroom photo using cloudinary
  // const onChangeOtherPhoto = async (e) => {
  //   e.preventDefault()
  //   const filesss = e.currentTarget.files[0]
  //   if (!filesss) return
  //   if (filesss.size > 8323863) {
  //     setOtherPhotoError(
  //       'large file, upload a smaller file not greater than 8MB'
  //     )
  //   } else {
  //     setLoadOtherPhoto(true)
  //     setOtherPhotoError('')

  //     if (filesss.type.startsWith('image/')) {
  //       let src = URL.createObjectURL(filesss)

  //       //convert to canvas
  //       const canvas = document.createElement('canvas')
  //       let ctx = canvas.getContext('2d')

  //       let userImage = new Image()
  //       userImage.src = src
  //       userImage.onload = () => {
  //         canvas.width = userImage.naturalWidth
  //         canvas.height = userImage.naturalHeight
  //         ctx.drawImage(userImage, 0, 0)

  //         canvas.toBlob(async (blob) => {
  //           const myImage = new File([blob], filesss.name, { type: blob.type })

  //           const result = await uploadSingleFileToCloudinary(
  //             myImage,
  //             myImageData
  //           )

  //           logger.log('here is my image result others photo', result)
  //           if (
  //             !result ||
  //             !result.url ||
  //             (result && result.path && result.path['error'])
  //           ) {
  //             setOtherPhotoError(
  //               'The was an error loading your image, please reload this image'
  //             )
  //             setLoadOtherPhoto(false)
  //             return
  //           }

  //           setOtherPhotoPath([...otherPhotoPath, { ...result }])
  //           setLoadOtherPhoto(false)
  //         }, 'image/webp')
  //       }
  //     } else {
  //       const result = await uploadSingleFileToCloudinary(filesss, myImageData)
  //       setOtherPhotoPath([...otherPhotoPath, { ...result }])
  //       setLoadOtherPhoto(false)
  //     }
  //   }
  // }

  //function to upload multiple photos
  const onChangeOtherPhoto = async (e) => {
    e.preventDefault();
    setLoadOtherPhoto(true);
    const filesss = e.currentTarget.files;
    let myFile = [];
    for (const file of filesss) {
      if (file.size > 850683 && file.type.startsWith("video/")) {
        setOtherPhotoError(
          "large video file exist,Please upload a smaller video files not greater than 850683gb"
        );
        setLoadOtherPhoto(false);
      } else {
        const result = await uploadMultipleFileToCloudinary(file, myImageData);
        if (result) {
          myFile.push(result);
        } else {
          setOtherPhotoError("Network error please retry");
          setLoadOtherPhoto(false);
        }
      }
    }

    setOtherPhotoPath([...otherPhotoPath, ...myFile]);
    setLoadOtherPhoto(false);
  };

  // //helper function to upload multiple images to cloudinary
  // const uploadToCloudinaryWebP = async (file, loadingFile) => {
  //   logger.log('here is the file====', file, loadingFile.length)
  //   const result = await uploadSingleFileToCloudinary(file, myImageData)
  //   logger.log('here are the result now===', otherPhotoPath)
  //   setOtherPhotoPath([...otherPhotoPath, { ...result }])
  //   logger.log('images from the states==', otherPhotoPath)
  //   setLoadOtherPhoto(false)
  // }

  //function to submit data to the backend
  const SubmitPost = async (e) => {
    e.preventDefault();
    if (iAm && locationCountry != country) {
      return setUploadDataError(
        `The location of your country is ${locationCountry}`
      );
    }
    if (listingType === "Single Room" && bedrooms != 1)
      return setUploadDataError(
        "The number of bedroom for a Single Room listing Type must be equals 1"
      );
    if (listingType === "Studio" && bedrooms != 2)
      return setUploadDataError(
        "The number of bedrooms for Studio listing Type must be equals 2"
      );
    if (listingType === "Apartment" && bedrooms <= 2)
      return setUploadDataError(
        "The number of bedrooms for an Apartment listing Type must be greater than 2"
      );
    if (listingType === "Land" && bedrooms != 0)
      return setUploadDataError(
        "The number of bedrooms for Land listing Type must be equals 0"
      );

    if (!fixedAgreement && !monthToMonth && !soldAgreement) {
      setUploadDataError("Choose an agreement for this listing");
    } else {
      try {
        setUploadDataError("");
        const datas = {
          title,
          listing_type: listingType,
          location:
            iAm && !locationError.error
              ? location
              : locationFromAddress.coordinates.lat
              ? locationFromAddress
              : streetAddressCoord.coordinates.lat
              ? streetAddressCoord
              : {
                  loaded: false,
                  coordinates: {
                    lat: "",
                    lng: "",
                  },
                },
          rent_or_sell: rentalOrSale,
          bedrooms,
          bathrooms,
          kitchen: kitchens,
          garages,
          lot_size: lotSize,
          building_size: buildingSize,
          keyword,
          country,
          state,
          city,
          currency,
          spaces,
          street_address: streetAddress,
          zip_code: zipCode,
          details,
          price,
          advance_price: advancePrice,
          main_photo: mainPhotoPath,
          parlour_photo: parlourPhotoPath,
          bedroom_photo: bedroomPhotoPath,
          listing_role: myRole,
          publish: publish,
          other_photos: otherPhotoPath,
          fixedAgreement,
          renewFixedAgreement,
          continueMonthTonMonth,
          vacate,
          soldAgreement,
          monthToMonth,
          owner_phone_num,
        };

        const ID = match.params.id;
        if (ID) {
          const { data: list } = await editListing(datas, ID, (progress) =>
            setLoadingProgress(progress * 100)
          );

          history.push(`/portal/add/edit_available_space/${list.id}`);
          // window.location.assign("/");
          setUploadDataError("");
        } else {
          const { data: list } = await addListing(datas, (progress) => {
            setLoadingProgress(progress * 100);
          });

          history.push(`/portal/add/edit_available_space/${list.id}`);
          setUploadDataError("");
        }
      } catch (ex) {
        if (
          (ex.response && ex.response.status === 400) ||
          (ex.response && ex.response.status === 500)
        ) {
          setLoadingProgress(0);
          setUploadDataError(ex.response.data);
        }
      }
    }
  };

  //function the set the state if user chooses fixed lease checkbox
  const onChangeFixedLease = (e) => {
    const value = e.target.checked;
    if (value) {
      setMonthToMonth("");
      setFixedAgreement("Fixed Agreement");
      setRenewFixedAgreement("Renew Fixed Agreement");
      setSoldAgreement("");
    } else {
      setFixedAgreement("");
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("");
      setSoldAgreement("");
    }
  };

  const onClickFixedLease = () => {
    if (fixedAgreement) {
      setFixedAgreement("");
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("");
      setSoldAgreement("");
    } else {
      setMonthToMonth("");
      setFixedAgreement("Fixed Agreement");
      setRenewFixedAgreement("Renew Fixed Agreement");
      setSoldAgreement("");
    }
  };

  //function the set the state if user chooses to renew fixed lease checkbox
  const onChangeFixedLeaseRenew = (e) => {
    const value = e.target.checked;
    if (value) {
      setRenewFixedAgreement("Renew Fixed Agreement");
      setContinueMonthTonMonth("");
      setFixedAgreement("Fixed Agreement");
      setVacate("");
      setMonthToMonth("");
      setSoldAgreement("");
    } else {
      setRenewFixedAgreement("");
      setMonthToMonth("");
    }
  };

  const onClickFixedLeaseRenew = () => {
    if (renewFixedAgreement) {
      setRenewFixedAgreement("");
      setMonthToMonth("");
      setVacate("");
      setFixedAgreement("");
    } else {
      setRenewFixedAgreement("Renew Fixed Agreement");
      setContinueMonthTonMonth("");
      setFixedAgreement("Fixed Agreement");
      setVacate("");
      setMonthToMonth("");
      setSoldAgreement("");
    }
  };

  //function the set the state if user chooses to continue with month to month fixed lease checkbox
  const onChangeContinueLease = (e) => {
    const value = e.target.checked;
    if (value) {
      setContinueMonthTonMonth("Continue With Month-To-Month");
      setFixedAgreement("Fixed Agreement");
      setRenewFixedAgreement("");
      setVacate("");
      setMonthToMonth("");
      setSoldAgreement("");
    } else {
      setRenewFixedAgreement("");
      setMonthToMonth("");
      setVacate("");
      setFixedAgreement("");
      setContinueMonthTonMonth("");
    }
  };

  const onClickContinueLease = () => {
    if (continueMonthTonMonth) {
      setRenewFixedAgreement("");
      setMonthToMonth("");
      setVacate("");
      setFixedAgreement("");
      setContinueMonthTonMonth("");
    } else {
      setContinueMonthTonMonth("Continue With Month-To-Month");
      setFixedAgreement("Fixed Agreement");
      setRenewFixedAgreement("");
      setVacate("");
      setMonthToMonth("");
      setSoldAgreement("");
    }
  };

  //function the set the state if user chooses vacate lease checkbox
  const onChangeVacate = (e) => {
    const value = e.target.checked;
    if (value) {
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("Vacate");
      setFixedAgreement("Fixed Agreement");
      setMonthToMonth("");
      setSoldAgreement("");
    } else {
      setRenewFixedAgreement("");
      setMonthToMonth("");
      setVacate("");
      setFixedAgreement("");
      setContinueMonthTonMonth("");
    }
  };

  const onClickVacate = () => {
    if (vacate) {
      setRenewFixedAgreement("");
      setMonthToMonth("");
      setVacate("");
      setFixedAgreement("");
      setContinueMonthTonMonth("");
    } else {
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("Vacate");
      setFixedAgreement("Fixed Agreement");
      setMonthToMonth("");
      setSoldAgreement("");
    }
  };

  //function the set the state if user chooses month to mmonth checkbox
  const onChangeMonthToMonth = (e) => {
    const value = e.target.checked;
    if (value) {
      setMonthToMonth("Month-To-Month Agreement");
      setFixedAgreement("");
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("");
      setSoldAgreement("");
    } else {
      setMonthToMonth("");
      setSoldAgreement("");
      setFixedAgreement("");
    }
  };

  const onClickMonthToMonth = () => {
    if (monthToMonth) {
      setMonthToMonth("");
      setSoldAgreement("");
      setFixedAgreement("");
    } else {
      setMonthToMonth("Month-To-Month Agreement");
      setFixedAgreement("");
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("");
      setSoldAgreement("");
    }
  };

  //function to set value if we click sold agreement
  const onChangeSold = (e) => {
    const value = e.target.checked;
    if (value) {
      setMonthToMonth("");
      setFixedAgreement("");
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("");
      setSoldAgreement("Sold Agreement");
    } else {
      setMonthToMonth("");
      setSoldAgreement("");
      setMonthToMonth("");
      setFixedAgreement("");
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("");
    }
  };

  const onClickSold = () => {
    if (soldAgreement) {
      setMonthToMonth("");
      setSoldAgreement("");
      setMonthToMonth("");
      setFixedAgreement("");
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("");
    } else {
      setMonthToMonth("");
      setFixedAgreement("");
      setRenewFixedAgreement("");
      setContinueMonthTonMonth("");
      setVacate("");
      setSoldAgreement("Sold Agreement");
    }
  };

  //function to delete an image from other photos
  const ondeletePicture = (pic) => {
    const images = [...otherPhotoPath];
    const myImages = images.filter((img) => img !== pic);
    setOtherPhotoPath(myImages);
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  ///validate phone number
  function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(input_str);
  }

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  if (loadingEditData)
    return (
      <div className="listingDetailSpinnerContainer">
        <ProfileSpinner />
      </div>
    );

  return (
    <>
      <Meta1
        title={`${langType}.${t("Nawafrica | add listing", {
          name: getCurrentUser() && getCurrentUser().first_name,
        })}`}
        description="Add A Listing into Nawafrica Real Estate Marketplace"
        keywords="AddListing Nawafrica"
        link="/portal/add/add_listing"
      />

      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          onTakeBarUp={onTakeBarUp}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      <div className="addListingScreenOuterCon">
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        <WelcomeUser
          user={capitalize(getCurrentUser() && getCurrentUser().first_name)}
        />

        <div
          className={close ? "addListScreenInnerCon" : "addListScreenInnerCon1"}
        >
          <TitleHeader />
          <form onSubmit={SubmitPost}>
            <h6 className="info">{t("Listing_Info")}</h6>
            <div className="row addListingInputsFieldCon">
              <div className="col-lg-6">
                <Input
                  label={t("Title*")}
                  placeholder={t("Title_ph*")}
                  id="title"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setTitle(value);
                  }}
                  value={title}
                />
              </div>

              <div className="col-lg-6">
                <Select
                  label={t("Listing_Type*")}
                  placeholder={t("Listing_Type_ph*")}
                  id="ListingType"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setListingType(value);
                  }}
                  items={listingTypes}
                  left="leftInputField"
                  value={listingType}
                />
              </div>

              <div className="col-lg-6">
                <Select
                  label={t("For_rent_Or_For_Sale*")}
                  placeholder={t("For_rent_Or_For_Sale_ph*")}
                  id="RentOrSell"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setRentalOrSale(value);
                  }}
                  items={rentalOrSales}
                  value={rentalOrSale}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("#Bedrooms*")}
                  placeholder={t("#Bedrooms_ph*")}
                  id="bedrooms"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setBedrooms(value);
                  }}
                  left="leftInputField"
                  value={bedrooms}
                  type="number"
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("# of available quantity")}
                  placeholder={t("Number of properties available")}
                  id="spaces"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setSpaces(value);
                  }}
                  left="leftInputField"
                  value={spaces}
                  type="number"
                  disabled={match.params.id ? true : false}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("#Bathrooms*")}
                  placeholder={t("#Bathrooms_ph*")}
                  id="bathrooms"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setBathrooms(value);
                  }}
                  value={bathrooms}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("Kitchen*")}
                  placeholder={t("Kitchen_ph*")}
                  id="kitchen"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setKitchen(value);
                  }}
                  value={kitchens}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("#Garages*")}
                  placeholder={t("#Garages_ph*")}
                  id="garages"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setGarages(value);
                  }}
                  value={garages}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("Lot_Size")}
                  placeholder={t("Lot_Size_ph")}
                  id="lotSize"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setLotSize(value);
                  }}
                  value={lotSize}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("building_size")}
                  placeholder={t("building_size_ph")}
                  id="buildSize"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setBuildingSize(value);
                  }}
                  value={buildingSize}
                />
              </div>

              <div className="col-lg-6">
                <TextInputKeywordAdd
                  placeholder={t("Keyword_ph*")}
                  handleChange={(e) => {
                    const {
                      target: { value },
                    } = e;
                    setKeyword(
                      typeof value === "string" ? value.split(",") : value
                    );
                  }}
                  label={t("Keyword*")}
                  id="keyword"
                  items={keywords}
                  keyword={keyword}
                />
              </div>

              <HrInfo title={t("Address")} />

              <div className="col-lg-6">
                <Select
                  label={t("Country*")}
                  placeholder={t("Select_One")}
                  id="ctry"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setCountry(value);
                    let states = Regions();
                    if (value === "") {
                      setStates([]);
                      setStates([]);
                      setCurrency("");
                    } else {
                      let States = states.filter((s) => s.country === value)[0]
                        .states;
                      const Cities = states.filter(
                        (s) => s.country === value
                      )[0].cities;
                      let Currency = states.filter(
                        (s) => s.country === value
                      )[0].currency;
                      setCities(Cities);
                      setStates(States);
                      setCurrency(Currency[0]);
                    }
                  }}
                  items={countries}
                  value={country}
                />
              </div>

              <div className="col-lg-6">
                <Select
                  label={t("State*")}
                  placeholder={t("Select_One")}
                  id="state"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setState(value);
                  }}
                  items={states}
                  value={state}
                />
              </div>

              <div className="col-lg-4">
                {sdkReady && (
                  <AddressInput
                    settingLocationFromAddress={(data) =>
                      setLocationFromAddress(data)
                    }
                    settingStreetAddress={(data) => setStreetAddress(data)}
                    value={streetAddress}
                  />
                )}
              </div>
              <div className="col-lg-4">
                <Input
                  label={t("ZipCode")}
                  id="zip"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setZipCode(value);
                  }}
                  value={zipCode}
                />
              </div>

              <div className="col-lg-4">
                <Select
                  label={t("City*")}
                  placeholder={t("Select_One")}
                  id="city"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setCity(value);
                  }}
                  items={cities}
                  value={city}
                />
              </div>

              <div className="col-lg-12">
                <Textarea
                  label={t("Details*")}
                  placeholder={t("Details_ph*")}
                  id="details"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setDetails(value);
                  }}
                  value={details}
                />
              </div>

              {rentalOrSale && (
                <>
                  <HrInfo />

                  <div className="col-lg-6">
                    <Input
                      label={t("total_price", {
                        total:
                          rentalOrSale === "Rental"
                            ? "Price per Month*"
                            : "Total Price*",
                      })}
                      placeholder={t("total_price_ph", {
                        currency: currency ? `(${currency})` : "",
                      })}
                      id="price"
                      type="number"
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.currentTarget;
                        setPrice(value);
                      }}
                      value={price}
                    />
                  </div>

                  <div className="col-lg-6">
                    <Input
                      label={t("Advanced_Payment*")}
                      type="number"
                      id="AdvPay"
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.currentTarget;
                        setAdvancePrice(value);
                      }}
                      placeholder={t("total_price_ph", {
                        currency: currency ? `(${currency})` : "",
                      })}
                      value={advancePrice}
                    />
                  </div>
                </>
              )}

              <HrInfo title={t("Upload_Photo")} />

              <div className="col-lg-4">
                <Input
                  label={t("Main_Photo*")}
                  id="mainPhot"
                  placeholder="Browse"
                  type="file"
                  accept="image/*"
                  onChange={onChangeMainPhoto}
                  loading={loadMainPhoto}
                  imgSrc={mainPhotoPath}
                  error={mainPhotoError}
                  myImageData={myImageData}
                />
              </div>

              <div className="col-lg-4">
                <Input
                  label={t("Parlour*")}
                  id="parlour"
                  placeholder="Browse"
                  type="file"
                  accept="image/*"
                  onChange={onChangeParlourPhoto}
                  loading={loadParlourPhoto}
                  imgSrc={parlourPhotoPath}
                  error={parlourPhotoError}
                  myImageData={myImageData}
                />
              </div>

              <div className="col-lg-4">
                <Input
                  label={t("Bedroom*")}
                  id="bedroom"
                  placeholder="Browse"
                  type="file"
                  accept="image/*"
                  onChange={onChangeBedroomPhoto}
                  loading={loadBedroomPhoto}
                  imgSrc={bedroomPhotoPath}
                  error={bedroomPhotoError}
                  myImageData={myImageData}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("multiple_photo")}
                  id="others"
                  placeholder="Browse"
                  type="file"
                  accept={"image/*| video/*"}
                  onChange={onChangeOtherPhoto}
                  loading={loadOtherPhoto}
                  imgSrcOther={otherPhotoPath}
                  error={otherPhotoError}
                  multiple={true}
                  ondeletePicture={ondeletePicture}
                  myImageData={myImageData}
                />
              </div>

              <div className="col-lg-6"></div>

              <HrInfo title={t("Listing Role")} />

              <div className="col-lg-6">
                <Select
                  label={t("Listing_Role*")}
                  id="ListingRole"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setMyRole(value);
                  }}
                  placeholder={t("Select_One")}
                  items={role}
                  value={myRole}
                />
              </div>

              <div className="col-lg-6">
                <Input
                  label={t("Phone number")}
                  placeholder={t("Property owners phone number")}
                  id="POPN"
                  onChange={(e) => {
                    e.preventDefault();
                    const { value } = e.currentTarget;
                    setOwner_phone_num(value);
                  }}
                  value={owner_phone_num}
                />
              </div>

              <HrInfo title={t("Coordinates and Publish")} />

              <div className="col-lg-6">
                <PositionCheckbox
                  onChange={(e) => {
                    const value = e.target.checked;
                    if (value) {
                      SetIAm("Yes");
                    } else {
                      SetIAm("");
                    }
                  }}
                  value={iAm}
                  onClickCheckBox={() => {
                    if (iAm) {
                      SetIAm("");
                    } else {
                      SetIAm("Yes");
                    }
                  }}
                />
              </div>

              <div className="col-lg-6">
                <Checkbox
                  id="ListingRole"
                  placeholder={t("Select_One")}
                  onChange={(e) => {
                    const value = e.currentTarget.checked;
                    if (value) {
                      setPublish("publish");
                    } else {
                      setPublish("");
                    }
                  }}
                  value={publish}
                  onClickCheckBox={() => {
                    if (publish) {
                      setPublish("");
                    } else {
                      setPublish("publish");
                    }
                  }}
                />
              </div>

              <HrInfo title={t("Leasing Type Terms")} />

              <LeasingType
                onChangeFixedLease={onChangeFixedLease}
                onClickFixedLease={onClickFixedLease}
                valueFixedLease={fixedAgreement}
                onChangeFixedLeaseRenew={onChangeFixedLeaseRenew}
                onClickFixedLeaseRenew={onClickFixedLeaseRenew}
                valueRenewFixedLease={renewFixedAgreement}
                onChangeContinueLease={onChangeContinueLease}
                onClickContinueLease={onClickContinueLease}
                valueContinueLease={continueMonthTonMonth}
                onChangeVacate={onChangeVacate}
                onClickVacate={onClickVacate}
                valueVacate={vacate}
                onChangeMonthToMonth={onChangeMonthToMonth}
                onClickMonthToMonth={onClickMonthToMonth}
                valueMonthToMonth={monthToMonth}
                onChangeSold={onChangeSold}
                onClickSold={onClickSold}
                valueSold={soldAgreement}
              />

              <SaveButton loadingProgress={loadingProgress} />

              {uploadDataError && (
                <div className="alert alert-danger errorUploadingContainerForAddList">
                  {uploadDataError}
                </div>
              )}
            </div>
            {loadingProgress && (
              <div
                style={{
                  width: "90%",
                  margin: "15px auto",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ marginRight: 5 }}>{loadingProgress}%</div>
                <div style={{ flex: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={loadingProgress}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
      <ScrollToTop smooth />
    </>
  );
};

export default AddListing;
