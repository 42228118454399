import React from 'react'
import NewsListings from './NewsListings'
import NewsListItem from './NewsListItem'

const LatestPress = ({
  news,
  home,
  press,
  company,
  award,
  research,
  products,
  add,
  header,
  loading,
  onClickNewsDeleted,
  user
}) => {
  return (
    <div className='latestPressOuterCon'>
      <div className='latestPressInnerCon'>
        <NewsListItem
          home={home}
          press={press}
          company={company}
          award={award}
          research={research}
          products={products}
          add={add}
          user={user}
        />
        <NewsListings
          onClickNewsDeleted={onClickNewsDeleted}
          loading={loading}
          news={news}
          header={header}
        />
      </div>
    </div>
  )
}

export default LatestPress
