import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import ClearIcon from "@mui/icons-material/Clear";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import AddTenantInput from "../ManageTenants/AddTenantInput";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style1 = {
  width: "100%",
  border: "1px #035aa6 solid",
};

const EditLeaseEndDate = ({
  tenantName = "Felix",
  handleClose,
  open,
  onChange,
  value,
  onSubmit,
  endDateError,
  loadingEndDate,
}) => {
  const { t } = useTranslation();
  var width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContent
        sx={
          width < 991
            ? style1
            : {
                width: 600,
                border: "1px #035aa6 solid",
              }
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottom: "1px solid #035aa6",
            paddingBottom: "10px",
          }}
        >
          <div
            style={{
              flex: "1",
              font: "normal normal bold 14px/25px Merriweather",
            }}
          >
            {`${t("Update Lease End Date For")} ${tenantName}`}
          </div>
          <Button onClick={handleClose}>
            <ClearIcon />
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            padding: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loadingEndDate ? (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={40} sx={{ color: "#035aa6" }} />
            </div>
          ) : (
            <>
              <form
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <AddTenantInput
                  title="Agreement Ending Date *"
                  id="FN"
                  placeholder="28/03/2021"
                  type="date"
                  onChange={onChange}
                  value={value}
                  width="100%"
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{ margin: "15px 0" }}
                    onClick={onSubmit}
                    variant="contained"
                  >
                    Update Tenant Lease End Date
                  </Button>
                </div>
                <p style={{ color: "#ff6d60", fontSize: "12px" }}>
                  {endDateError}
                </p>
              </form>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditLeaseEndDate;
