import React, { useState } from "react";

import "./NewsroomDetails.css";

import {
  faTrashCan,
  faPenToSquare,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCurrentUser } from "./../../Services/authService";
import { Link } from "react-router-dom";

const NewsroomDetailsContainer = ({ data, onClickNewsDeleted }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const getMonth = (month) => {
    if (month == "01") return "JAN";
    if (month == "02") return "FEB";
    if (month == "03") return "MAR";
    if (month == "04") return "APR";
    if (month == "05") return "MAY";
    if (month == "06") return "JUN";
    if (month == "07") return "JUL";
    if (month == "08") return "AUG";
    if (month == "09") return "SEPT";
    if (month == "10") return "OCT";
    if (month == "11") return "NOV";
    return "DEC";
  };

  return (
    <div className="newsDetBodyCon">
      <p className="newsDetPInnerCon">
        <span>{data && data.catergory}</span>{" "}
        {getCurrentUser() && getCurrentUser().isadmin && (
          <>
            <span style={{ color: "white", margin: "0 7px" }}>/</span>
            <span>
              <FontAwesomeIcon
                style={{
                  color: "red",
                  opacity: "0.8",
                  fontSize: "16px",

                  cursor: "pointer",
                  margin: "0 7px",
                }}
                icon={faTrashCan}
                onClick={() => onClickNewsDeleted(data.id)}
              />
            </span>
            <span style={{ color: "white", margin: "0 7px" }}>/</span>
            <span>
              {" "}
              <Link to={`/newsroom/add/${data.id}`}>
                <FontAwesomeIcon
                  style={{
                    color: "grey",
                    fontSize: "16px",

                    cursor: "pointer",
                  }}
                  icon={faPenToSquare}
                />
              </Link>
            </span>
          </>
        )}
      </p>
      <b className="newsDetTitleB">{data && data.title} </b>
      <p className="newsDetDate">{`${
        data && data.create_date && data.create_date.toString().slice(8, 10)
      } ${getMonth(
        data && data.create_date && data.create_date.toString().slice(5, 7)
      )} ${
        data && data.create_date && data.create_date.toString().slice(0, 4)
      }`}</p>

      <button onClick={handleOpen} className="btn newsDetImageBtn">
        {open ? "Close" : "Open"} image{" "}
        <FontAwesomeIcon className="newsIcon" icon={faImage} />
      </button>

      {open && (
        <div className="newsDetImageCon">
          <img
            crossorigin="anonymous"
            src={data && data.image.url}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "10px",
              objectFit: "cover",
            }}
          />
        </div>
      )}
      <p className="newsDetSubtitle">{data && data.subtitle}</p>

      {data.paragraphs.map((p, i) => (
        <p className="newsDetPara" key={`${i}`}>
          {p}
        </p>
      ))}
    </div>
  );
};

export default NewsroomDetailsContainer;
