import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'

const BlogDetBackBtn = () => {
  return (
    <div className='blogDetBackBtnOuterCon'>
      <Link className='btn' to='/blog'>
        <div>
          <FontAwesomeIcon className='icon' icon={faArrowLeftLong} />
        </div>
        <div>Go Back</div>
      </Link>
    </div>
  )
}

export default BlogDetBackBtn
