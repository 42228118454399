import React from "react";

const PreferMethod = ({
  valuePhone,
  onChangePhone,
  valueEmail,
  onChangeEmail,
}) => {
  return (
    <div className="preferMethodCon">
      <label htmlFor="exampleFormControlInput1" className="form-label">
        Preferred Method of Contact{" "}
        <span style={{ color: "red", marginLeft: "5px" }}>*</span>
      </label>
      <div className="inputFieldsCon">
        <div className="innerContainer">
          <input
            className="termsConditionCheckbox"
            checked={valuePhone}
            type="checkbox"
            onChange={onChangePhone}
          />
          <span style={{ marginLeft: "5px" }}> Phone</span>
        </div>
        <div className="secondContainer innerContainer">
          <input
            className="termsConditionCheckbox"
            checked={valueEmail}
            type="checkbox"
            onChange={onChangeEmail}
          />
          <span style={{ marginLeft: "5px" }}> Email</span>
        </div>
      </div>
    </div>
  );
};

export default PreferMethod;
