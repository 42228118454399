import React from "react";

const PropJoinAgent = () => {
  return (
    <div
      style={{ backgroundImage: "url('../../images/team.jpg')" }}
      className="propManJoinCon"
    >
      <div className="propManJoinInnerCon">
        <h2>Join The Team Of Property Managers</h2>
        <div className="propManListCon">
          <p>
            Our world-class people and systems consistently deliver the
            following:{" "}
          </p>
          <div>
            <ul>
              <li>Property Operations</li>
              <li>Business Management and Administration</li>
              <li>Maintenance Services</li>
              <li>Construction Management</li>
              <li>Information Technology</li>
              <li>Marketing and Communications</li>
              <li>Training and Education</li>
            </ul>
          </div>
        </div>

        <a className="btn">Joint Our Team</a>
      </div>
    </div>
  );
};

export default PropJoinAgent;
