import React from 'react'

const PaypalCancel = () => {
  return (
    <div
    style={{
      width: '100%',
      height: '30vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      className='alert alert-info'
    >

        Successfully Cancel Payment Through Paypal

    </div>
  </div>
  )
}

export default PaypalCancel