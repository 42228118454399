import React from 'react'

const Browser = (props) => {
 return (
<svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" {...props}>
<g>
<path d="m116 272.899v98.101h40v-25c0-8.284 6.716-15 15-15s15 6.716 15 15v25h40v-98.101l-55-47.143zm80 13.101c0 8.284-6.716 15-15 15h-20c-8.284 0-15-6.716-15-15s6.716-15 15-15h20c8.284 0 15 6.716 15 15z"/>
<path d="m293.5 121h218.5v-65c0-8.284-6.716-15-15-15h-263.5z"/>
<path d="m286 151c-4.722 0-9.167-2.223-12-6l-78-104h-181c-8.284 0-15 6.716-15 15v400c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-305zm-105-50c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm-60 0c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm-60 0c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm216.389 196.19c-2.967 3.461-7.169 5.238-11.396 5.238-3.455.001-6.926-1.187-9.755-3.611l-.238-.204v87.387c0 8.284-6.716 15-15 15h-140c-8.284 0-15-6.716-15-15v-87.387l-.238.204c-6.289 5.392-15.759 4.665-21.15-1.627-5.392-6.29-4.663-15.759 1.627-21.15l95-81.429c5.617-4.814 13.906-4.814 19.523 0l95 81.429c6.29 5.392 7.018 14.86 1.627 21.15zm158.611 83.81h-110c-8.284 0-15-6.716-15-15s6.716-15 15-15h110c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-70h-110c-8.284 0-15-6.716-15-15s6.716-15 15-15h110c8.284 0 15 6.716 15 15s-6.716 15-15 15zm0-70h-110c-8.284 0-15-6.716-15-15s6.716-15 15-15h110c8.284 0 15 6.716 15 15s-6.716 15-15 15z"/>
</g>
</svg>

)
}

export default Browser
