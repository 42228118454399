import React from 'react'

import './addListing.css'

const HrInfo = ({title}) => {
return (
<div className="HrInfoConAddList">

  <hr/>            

{title&&

  <h6>{title} </h6>                 
}
                                                            
</div>
)
}

export default HrInfo
