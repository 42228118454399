import React from 'react'

const SignUpIcon = (props) => {
return (
<svg height="512pt" viewBox="0 -24 512 511" width="512pt" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="m400.601562 241.5c-61.425781 0-111.398437 49.972656-111.398437 111.398438 0 61.425781 49.972656 111.402343 111.398437 111.402343 61.425782 0 111.398438-49.972656 111.398438-111.402343 0-61.425782-49.976562-111.398438-111.398438-111.398438zm32.132813 126.398438h-17.132813v17.132812c0 8.285156-6.714843 15-15 15-8.285156 0-15-6.714844-15-15v-17.132812h-17.132812c-8.285156 0-15-6.714844-15-15 0-8.28125 6.714844-15 15-15h17.132812v-17.132813c0-8.28125 6.714844-15 15-15 8.285157 0 15 6.71875 15 15v17.132813h17.132813c8.285156 0 15 6.71875 15 15 0 8.285156-6.714844 15-15 15zm0 0"/>
<path d="m159.601562 223.300781c61.425782 0 111.398438-49.976562 111.398438-111.402343 0-61.425782-49.976562-111.398438-111.398438-111.398438-61.425781 0-111.402343 49.972656-111.402343 111.398438 0 61.429687 49.976562 111.402343 111.402343 111.402343zm0 0"/>
<path d="m291.453125 263.101562c-11.269531-16.496093-25.546875-30.792968-42.042969-42.066406-24.4375 20.148438-55.734375 32.265625-89.808594 32.265625-34.074218 0-65.371093-12.117187-89.8125-32.265625-42.097656 28.765625-69.789062 77.140625-69.789062 131.863282v56.234374c0 30.421876 24.746094 55.167969 55.164062 55.167969h208.867188c13.421875 0 25.734375-4.820312 35.308594-12.816406-24.820313-25.492187-40.140625-60.28125-40.140625-98.585937 0-34.066407 12.113281-65.359376 32.253906-89.796876zm0 0"/>
</svg>
)
}

export default SignUpIcon
