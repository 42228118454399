import http from './httpService'

export function postBlog(data, UploadProgress) {
  return http.post('/blog', data, {
    headers: {
      'Content-Type': 'application/json',
    },
    onUploadProgress: (progress) =>
      UploadProgress(progress.loaded / progress.total),
  })
}

export function editBlog(data, id, UploadProgress) {
  return http.put(`/blog/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
    onUploadProgress: (progress) =>
      UploadProgress(progress.loaded / progress.total),
  })
}

export function getBlog() {
  return http.get('/blog')
}

export function getBlogById(id) {
  return http.get(`/blog/${id}`)
}

export function deleteBlogById(id) {
  return http.delete(`/blog/${id}`)
}
