import React from 'react'

import './addListing.css'

const SpinnerPic = () => {
return (
<div className="spinner-border" role="status">
  <span className="sr-only">Loading...</span>
</div>
)
}

export default SpinnerPic
