import React from "react";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";

import "./messages.css";

const MessageTitle = ({ messages = 0 }) => {
  const { t } = useTranslation();
  return (
    <div className="applicationTitleCon">
      <h6>
        {t("Message_Inbox")} <Chip label={messages} />
      </h6>
    </div>
  );
};

export default MessageTitle;
