import React from 'react'
import { useTranslation } from 'react-i18next'

import './auth.css'

const Select = ({ onChange, id, items }) => {
  const { t } = useTranslation()

  return (
    <div className='rentOrBuyPropInputCon'>
      <label className={`passwordLabel`} htmlFor={id}>
        <b>{t('tenant_or_landlord')}</b>
      </label>
      <select onChange={onChange} className='form-control'>
        <option value=''>{t("CLICK_and_SELECT_ONE")}</option>
        {items.map((item, index) => (
          <option key={`${index}`} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Select
