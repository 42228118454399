import React from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { Box, TextField } from "@mui/material";

import "./applications.css";

const SearchApplicant = ({ onChangeSearch, onChangeSort }) => {
  const { t } = useTranslation();

  return (
    <div style={{ paddingRight: 7 }} className="listingSearchCon">
      <div className="addlistCons"></div>
      <div className="inputFieldCon someMarginInListingSearchInput">
        <Box sx={{ display: "flex", alignItems: "flex-end", width: "99%" }}>
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            inputProps={{
              style: { font: "normal normal 14px/25px Merriweather" },
            }}
            sx={{ width: "99%" }}
            onChange={onChangeSearch}
            id="input-with-sx"
            label={t("Search your Applicant")}
            variant="standard"
          />
        </Box>
      </div>

      <div className="listingSortDropdownn someMarginInListingSearchInput">
        <p>
          <span className="sortedBySpan">{t("Sort_By")}</span>
          <span className="listingSortSelectSpan">
            <select style={{ outline: "none" }} onChange={onChangeSort}>
              <option value="dateposted">{t("Date")}</option>
              <option value="appicant_name">{t("Applicant")}</option>
              <option value="listing_name">{t("Listing")}</option>
              <option value="listing_address">{t("Address")}</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  );
};

export default SearchApplicant;
