import React from 'react'
import { useTranslation } from 'react-i18next'

import './auth.css'

const HrContent = () => {
const {t}=useTranslation()
return (
<div className="hrContainerLogin">
<div className="firstLineContainer">
<hr  className="hrFirstLine"/>
</div>
<div className="paraContainer">
<p className="loginPara">{t("Or_Sign_in_With")}</p>

</div>
<div className="firstLineContainer">
<hr className="hrLastLine"/>
</div>
</div>
)
}

export default HrContent
