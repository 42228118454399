import React from 'react'

const AlertCustomise = ({ data }) => {
  return (
    <div
      style={{
        width: '450px',
        //         height: '300px',
        position: 'fixed',
        zIndex: '5',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '240px auto 0 auto',
        left: '0',
        textAlign: 'center',
        padding: '10px 20px',
        border: '2px solid #ffa500',
        borderRadius: '10px',
        right: '0',
        font: 'normal normal normal 14px/25px Merriweather',
        color: '#035aa6',
      }}
      className='animate__animated animate__heartBeat'
    >
      {data}
    </div>
  )
}

export default AlertCustomise
