import React, { useEffect, useState } from 'react'

import ComponentUnderConstruction from '../components/UnderConstruction/ComponentUnderConstruction'
import Meta1 from '../Utils/Meta1'
import Meta from './../Utils/Meta'

const Maintanance = () => {
  const [underConstruction, setUnderConstruction] = useState(false)

  useEffect(() => {
    setUnderConstruction(true)
  }, [])

  if (underConstruction)
    return (
      <>
        <Meta
          title='Nawafrica | Maintenance'
          description='My Nawafrica House Maintenance'
        />
        <ComponentUnderConstruction />
      </>
    )
  return (
    <>
      <Meta1
        title='Nawafrica | Maintenance'
        description='My Nawafrica House Maintainance'
        link='/portal/maintenance'
      />
      <div style={{ marginTop: '47px', width: '100%', height: '700px' }}></div>
    </>
  )
}

export default Maintanance
