import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import Sidebar from "../components/AddListing/Sidebar";
import OpenButton from "../components/AddListing/OpenButton";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import UnreadMsgsContext from "../unreadmessages/context";
import {
  clientGetAvailableSpaces,
  getListingsById,
} from "../Services/listings";
import Chats from "../components/AddListing/Chats";
import WelcomeUser from "../components/AddListing/WelcomeUser";
import { getCurrentUser } from "../Services/authService";
import SpaceList from "../components/EditAvailableSpace/SpaceList";
import EditSpaceFormField from "../components/EditAvailableSpace/EditSpaceFormField";
import { addListingSpace, editListingSpace } from "../Services/SpacesService";
import AlertCustomise from "../components/Home/AlertCustomise";
import "../components/EditAvailableSpace/EditAvailableSpace.css";

const EditAvailableSpace = ({ match, history }) => {
  const [listing, setListing] = useState({});
  const [availableSpaces, setAvailableSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState({});

  const [close, setClose] = useState(true);
  const [path, setPath] = useState("");
  const [upSideBar, setUpSideBar] = useState(false);

  const [spaceName, setSpaceName] = useState("");

  const { unreadMsgs } = useContext(UnreadMsgsContext);

  const [loading, setLoading] = useState(false);
  const [loadingAddSpace, setLoadingAddSpace] = useState(false);
  const [added, setAdded] = useState(false);

  const [loadingEdit, setLoadingEdit] = useState(false);
  const [editError, setEditError] = useState("");

  useEffect(() => {
    localStorage.setItem("portalPath", "/portal/add/add_listing");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    window.scrollTo(0, 0);
    getSpaceData();
  }, []);

  const getSpaceData = async () => {
    try {
      setLoading(true);
      const { data: listing } = await getListingsById(match?.params?.id);
      setListing(listing);
      const { data: spaces } = await clientGetAvailableSpaces(
        match?.params?.id
      );
      setAvailableSpaces(
        spaces.filter((space) => space.listing_id === match.params.id)
      );
      setLoading(false);
    } catch (error) {
      history.push("/notFound");
    }
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to open the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const onsubmitSpaceName = async () => {
    if (!spaceName) return setEditError("Please enter a space name");
    try {
      if (
        window.confirm(
          `Are you sure you want to edit this space number ${selectedSpace.space_number} to ${spaceName} ? `
        )
      ) {
        setLoadingEdit(true);
        setEditError("");
        const { data } = await editListingSpace(
          { name: spaceName },
          selectedSpace.id
        );
        setAvailableSpaces(data.space);
        setLoadingEdit(false);
        setSpaceName("");
      }
    } catch (error) {
      setEditError(error.message);
    }
  };

  const onClickAddSpace = async () => {
    if (
      window.confirm(
        "Are you sure you want add an available space to this listing ?"
      )
    ) {
      try {
        setLoadingAddSpace(true);
        const { data } = await addListingSpace({
          listing,
          availableSpaces,
        });
        setListing(data.listing);
        setAvailableSpaces(data.spaces);
        // setAvailableSpaces(data);
        setLoadingAddSpace(false);
        setAdded(true);
        let interval = setInterval(() => {
          setAdded(false);
          clearInterval(interval);
        }, 3000);
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const onClickSave = async () => {
    history.push("/");
  };

  return (
    <>
      {added && (
        <AlertCustomise
          data={
            "Available space added successfully. Click on the space to edit the space name if you wish to."
          }
        />
      )}
      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          onTakeBarUp={onTakeBarUp}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      <div className="editSpaceAllContainer">
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        <WelcomeUser
          user={capitalize(getCurrentUser() && getCurrentUser().first_name)}
        />
        <div
          className={
            close ? "editAvailableSpaceOuter" : "editAvailableSpaceOuter1"
          }
        >
          <p className="editAvailableSpaceTitle">
            Edit Default Available Spaces Numbering Of{" "}
            {!loading ? (
              capitalize(`${listing.title}`)
            ) : (
              <CircularProgress size={16} sx={{ color: "#035aa6" }} />
            )}
          </p>
          <Divider style={{ width: "100%" }} />
          <p className="editAvailableSpaceSubTitle">
            <span>
              Do you want to edit the default numbering of available spaces
              (1....
              {!loading ? (
                availableSpaces.length
              ) : (
                <CircularProgress
                  thickness={5}
                  size={10}
                  sx={{ color: "#035aa6" }}
                />
              )}
              ) for your listing ?. Click Save if you are okay with the
              available spaces numbering or when done with editing.
            </span>
            <span style={{ marginLeft: 5 }}>
              <Button
                startIcon={<SaveAsIcon />}
                style={{ background: "#035aa6" }}
                onClick={onClickSave}
                variant="contained"
              >
                Save
              </Button>
            </span>
          </p>
          <div className="editAvailableSpaceInner">
            <div className="editAvailableSpaceInnerLeft">
              <p
                style={{
                  font: "normal normal bold 14px/26px Merriweather",
                  textAlign: "center",
                }}
              >
                <span>Available Space Numbering </span>
                <span style={{ marginLeft: 5 }}>
                  {loading ? (
                    <CircularProgress size={10} sx={{ color: "#035aa6" }} />
                  ) : loadingAddSpace ? (
                    <CircularProgress size={10} sx={{ color: "#035aa6" }} />
                  ) : (
                    <Tooltip
                      title="Click to add available space"
                      placement="top"
                    >
                      <IconButton onClick={onClickAddSpace}>
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </span>
              </p>

              <SpaceList
                spaces={availableSpaces}
                listing={listing}
                onSelectSpace={(data) => setSelectedSpace(data)}
                selectedSpace={selectedSpace}
                loading={loading}
              />
            </div>
            <div className="editAvailableSpaceInnerRight">
              <EditSpaceFormField
                selectedSpace={selectedSpace}
                listing={listing}
                onChangeSpaceName={(e) => setSpaceName(e.target.value)}
                onClick={onsubmitSpaceName}
                loadingEdit={loadingEdit}
                editError={editError}
                value={spaceName}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAvailableSpace;
