import React from "react";
import { useTranslation } from "react-i18next";

import "./manageTenants.css";
import { Chip } from "@mui/material";

const TitleManageTenant = ({ tenants, title }) => {
  const { t } = useTranslation();

  return (
    <div className="applicationTitleCon">
      <h6 style={{ color: "#035aa6" }}>
        {title ? title : t("Your_Tenants")}{" "}
        <Chip style={{ color: "#035aa6" }} label={tenants} />
      </h6>
    </div>
  );
};

export default TitleManageTenant;
