import React, { useEffect, useState, useContext } from "react";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
// import cookies from 'js-cookie'

import TitlePrice from "../components/listingDetails/TitlePrice";
import Address from "../components/listingDetails/Address";
import MoreAboutListing from "../components/listingDetails/MoreAboutListing";
import Description from "../components/listingDetails/Description";
import LocationInMap from "../components/listingDetails/LocationInMap";
import OwnerOtherDetails from "../components/listingDetails/OwnerOtherDetails";
import ApplyButton from "../components/listingDetails/ApplyButton";
import Carousel from "../components/listingDetails/Carousel";
// import PictureModal from '../components/listingDetails/PictureModal'
import LeaseType from "../components/listingDetails/LeaseType";
import ProfileSpinner from "../components/profile/ProfileSpinner";
// import LottieLoader from '../components/contactCaptivator/LottieLoader'
import OtherListingInCity from "../components/listingDetails/OtherListingInCity";
import AlertCustomise from "../components/listingDetails/AlertCustomise";
import Spam from "../iconComponent/Spam";

import {
  clientGetAvailableSpaces,
  getListings,
  getListingsById,
  updateShare,
} from "../Services/listings";
import { getUserById } from "../Services/registerService";
import { applyForAListing } from "../Services/listings";
import { getAllApplications } from "../Services/listings";
import { getCurrentUser } from "../Services/authService";
import { likeAListing } from "../Services/listings";
import { getAllLikes } from "../Services/listings";
import { reportAListing } from "../Services/listings";

import "../components/AddListing/addListing.css";
import UnreadMsgsContext from "./../unreadmessages/context";
// import Meta from './../Utils/Meta'
import { getLang } from "../Utils/getLang";
import { getStatusOfTenant } from "./../Services/applicationStatus";
import langContext from "./../unreadmessages/langContext";
// import translateLang from '../Utils/translationLang'
import "../components/listingDetails/listingDetails.css";
import { trackEvent } from "../Utils/useGAEventTracker";

import {
  spliceCityArr,
  spliceCityArrSmall,
  spliceImage,
} from "../Utils/spliceCityArr";
import PictureModal1 from "../components/listingDetails/PictureModal1";
import Meta1 from "../Utils/Meta1";
import { Redirect } from "react-router-dom";
import KeyFeatures from "../components/listingDetails/KeyFeatures";

const ListingDetails = ({ match, history }) => {
  const { t } = useTranslation();
  const [clickOthers, setClickOthers] = useState(false);
  const [listing, setListing] = useState({
    title: "",
    rent_or_sell: "",
  });
  const [loadingListing, setLoadingListing] = useState(false);
  const [list_owner, setList_owner] = useState({
    verified: false,
    picture: {
      fileProfile: "",
    },
  });
  const [applyError, setApplyError] = useState("");
  const [applicants, setApplicants] = useState([]);
  const [appliedAlready, setAppliedAlready] = useState(false);

  const [likeError, setLikeError] = useState("");
  const [likers, setLikers] = useState([]);
  const [likedAlready, setLikedAlready] = useState(false);

  const [carouselImages, setCarouselImage] = useState([]);
  const [doubleCarouselImages, setDoubleCarouselImages] = useState([]);

  const [myReport, setMyReport] = useState("");
  const [errorReport, setErrorReport] = useState("");

  const [listingFromMap, setListingFromMap] = useState({});

  const [keys, setKey] = useState("");

  const [listingInCity, setListingInCity] = useState([]);
  const [listingInCityDisplay, setListingInCityDisplay] = useState([]);

  const [listingInCitySmall, setListingInCitySmall] = useState([]);

  const [theSelected, setTheSelected] = useState(null);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage1, setSelectedImage1] = useState();

  const [theTenantSignedStatus, setTheTenantSignedStatus] = useState();

  const [reportAlert, setReportAlert] = useState(false);

  const [sharings, setSharings] = useState(0);

  const [IDs, setID] = useState("");

  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const [lang, setLang] = useState("");
  const [YOUR_API_KEY, setYOUR_API_KEY] = useState("");
  const [description, setDescription] = useState("");

  const [likings, setLikings] = useState(false);

  const [totallyRejected, setTotallyRejected] = useState(false);

  const { unreadMsgs } = useContext(UnreadMsgsContext);
  const { lan, setLan } = useContext(langContext);
  const [availableSpace, setAvailableSpace] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!clickOthers) {
      getListing();
    }
    localStorage.setItem("portalOrManagement", "");
  }, []);

  //function to get all datas for a particular listing
  const getListing = async () => {
    try {
      const ID = match.params.id;
      setID(ID);
      if (ID) {
        setLoadingListing(true);
        const { data } = await getListingsById(ID);
        if (
          getCurrentUser() &&
          getCurrentUser().post_rent_property !== "I AM A PROPERTY OWNER"
        ) {
          const { data: applStatus } =
            data &&
            getCurrentUser() &&
            (await getStatusOfTenant(
              ID,
              getCurrentUser() && getCurrentUser().id
            ));

          setTheTenantSignedStatus(applStatus);
        }

        setListing(data);
        setDescription(data.details);
        const { data: listOwner } = await getUserById(data.list_owner_id);
        setList_owner(listOwner);

        setSharings(data.share);

        const { data: allListings } = await getListings();
        const publishData = allListings.filter((d) => d.publish === "publish");
        const allUndeletedListing = publishData.filter(
          (listing) => listing.status !== "deleted"
        );
        const listingInSameCity = allUndeletedListing.filter(
          (list) =>
            list.country == data.country &&
            list.id !== data.id &&
            list.city === data.city
        );
        const listingInSameCityBedrooms = allUndeletedListing.filter(
          (list) =>
            list.country == data.country &&
            list.city === data.city &&
            list.bedrooms == data.bedrooms &&
            list.id !== data.id
        );
        const allInCity = [...listingInSameCityBedrooms, ...listingInSameCity];

        const newArray = new Set();

        for (let i = 0; i < allInCity.length; i++) {
          newArray.add(allInCity[i]);
        }

        let newArrayMade = [];
        const resultMade = spliceCityArr(newArray, newArrayMade);

        let newArrayMadeSmall = [];
        const resultMadeSmall = spliceCityArrSmall(newArray, newArrayMadeSmall);

        setListingInCityDisplay(resultMade);
        setListingInCitySmall(resultMadeSmall);
        setListingInCity(Array.from(newArray));

        //function to apply for a listing
        const { data: listApplicants } = await getAllApplications(ID);
        setApplicants(listApplicants);

        //functions to like a listing
        const { data: likesListing } = await getAllLikes(ID);
        setLikers(likesListing);

        if (getCurrentUser()) {
          if (
            listApplicants.some(
              (applicant) => applicant.applicant_id === getCurrentUser().id
            )
          ) {
            setAppliedAlready(true);
          } else {
            setAppliedAlready(false);
          }

          if (
            likesListing.some((likes) => likes.liker_id === getCurrentUser().id)
          ) {
            setLikedAlready(true);
          } else {
            setLikedAlready(false);
          }
        }

        const carImages = data.other_photos;
        const parlourImage = data.parlour_photo;
        const bedroomImage = data.bedroom_photo;

        !bedroomImage.path["error"] && carImages.unshift(bedroomImage);
        !parlourImage.path["error"] && carImages.unshift(parlourImage);

        const imageResult = spliceImage(carImages);

        setDoubleCarouselImages(imageResult);
        setCarouselImage(carImages);

        const { data: keys } = await axios.get("/map");
        setKey({ googleMapsApiKey: keys, libraries: ["places"] });

        if (getCurrentUser) {
          localStorage.setItem("redirect", "");
        }

        const { data: space } = await clientGetAvailableSpaces(ID);
        setAvailableSpace(space);

        setLoadingListing(false);
      }
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        logger.log(
          "Error from getListing function in ListingDetail Component",
          ex
        );
        history.push("/notFound");
      }
    }
  };

  //function to get the date the listing was posted
  const calculateDate = (date) => {
    const postedDate = moment(parseInt(date));
    const nowDate = moment(Date.now());
    const diffInSec = nowDate.diff(postedDate, "seconds");
    const diffInMin = nowDate.diff(postedDate, "minutes");
    const diffInHour = nowDate.diff(postedDate, "hours");
    const diffInDay = nowDate.diff(postedDate, "days");
    let diffInMonth = nowDate.diff(postedDate, "months", true);
    diffInMonth = Math.round(diffInMonth) < 1 ? 1 : Math.round(diffInMonth);
    const diffInYear = nowDate.diff(postedDate, "years");

    if (diffInSec < 60)
      return t("diffInSec", { diffInSec, s: diffInSec > 1 ? "s" : "" });
    if (diffInMin < 60)
      return t("diffInMin", { diffInMin, s: diffInMin > 1 ? "s" : "" });
    if (diffInHour < 24)
      return t("diffInHour", { diffInHour, s: diffInHour > 1 ? "s" : "" });
    if (diffInDay < 30)
      return t("diffInDay", { diffInDay, s: diffInDay > 1 ? "s" : "" });
    if (diffInMonth < 12)
      return t("diffInMonth", { diffInMonth, s: diffInMonth > 1 ? "s" : "" });

    return t("diffInYear", { diffInYear, s: diffInYear > 1 ? "s" : "" });
  };

  //function that allows a tenant to apply for a listing
  const applyNow = async () => {
    if (getCurrentUser()) {
      if (
        listing.list_owner_id == getCurrentUser().id ||
        (getCurrentUser() &&
          getCurrentUser().post_rent_property == "I AM A PROPERTY OWNER")
      ) {
        alert(t("List_owners_Or_Landlords"));
      } else {
        try {
          console.log(applicants, getCurrentUser());
          if (
            applicants.some(
              (applicant) => applicant.applicant_id == getCurrentUser().id
            )
          ) {
            if (
              (theTenantSignedStatus &&
                theTenantSignedStatus.tenant_sign_lease) ||
              (theTenantSignedStatus &&
                theTenantSignedStatus.landlord_and_tenant_sign_lease)
            )
              return alert(
                t(
                  `You cannot unapplied for a lease that you have already signed`
                )
              );

            console.log("APPLIED NOW======");

            if (window.confirm(t("Are_you_sure_you_want"))) {
              const appli = [...applicants];
              const appl = appli.filter(
                (app) => app.applicant_id !== getCurrentUser().id
              );
              setApplicants(appl);
              setAppliedAlready(false);

              trackEvent(
                `${getCurrentUser().first_name} unapply`,
                `${listing.title}`,
                "apply Btn"
              );

              await applyForAListing(listing.id);
            }
          } else {
            const deletedAppl = listing.application_denied
              ? listing.application_denied
              : [];
            const numOfReject = deletedAppl.filter(
              (id) => id === getCurrentUser().id
            );

            if (numOfReject.length >= 4) {
              const interval = setInterval(() => {
                setTotallyRejected(true);
                // Hide the image after 3 seconds
                setTimeout(() => {
                  setTotallyRejected(false);
                }, 7000);
                clearInterval(interval);
              }, 8000);

              return;
            }

            const appli = [...applicants];
            const obj = {
              applicant_id: getCurrentUser().id,
              list_owner_id: list_owner.id,
              listing_id: listing.id,
            };

            appli.push(obj);
            setApplicants(appli);
            setAppliedAlready(true);
            trackEvent(
              `${getCurrentUser().first_name} apply`,
              `${listing.title}`,
              "apply Btn"
            );

            await applyForAListing(listing.id);
          }
        } catch (ex) {
          if (ex.response && ex.response.status === 400) {
            setApplyError(ex.response.data);
          }
        }
      }
    } else {
      localStorage.setItem("redirect", `/listing/${match.params.id}`);
      history.push("/login");
    }
  };

  // function to like and unliked a listing
  const handleLike = async () => {
    if (getCurrentUser()) {
      try {
        if (likers.some((liked) => liked.liker_id === getCurrentUser().id)) {
          const likes = [...likers];
          const like = likes.filter(
            (li) => li.liker_id !== getCurrentUser().id
          );
          setLikers(like);
          trackEvent(
            `${getCurrentUser().first_name} unliked`,
            listing.title,
            "liked btn"
          );
          setLikedAlready(false);
        } else {
          setLikings(true);
          window.navigator.vibrate(300);
          const likes = [...likers];
          const obj = {
            liker_id: getCurrentUser().id,
            liked: true,
            listing_id: listing.id,
          };

          likes.push(obj);
          setLikers(likes);
          setLikedAlready(true);
          trackEvent(
            `${getCurrentUser().first_name} liked`,
            listing.title,
            "liked btn"
          );
        }

        // setInterval(() => {
        //   setLikings(false)
        // }, 1800)

        await likeAListing(listing.id);
        setLikings(false);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          setLikeError(ex.response.data);
        }
      }
    } else {
      localStorage.setItem("redirect", `/listing/${match.params.id}`);
      history.push("/login");
    }
  };

  //function that shows a larger image if a particular image is clicked in the carousel
  const onImageClick = (image) => {
    setSelectedImage1(image);
  };

  const onClickNext = (index) => {
    let next = index + 1;
    if (next > carouselImages.length - 1) {
      // next = 0
      // setSelectedImage1(carouselImages[0])
    } else {
      // next = index + 1

      setSelectedImage1(carouselImages[next]);
    }
  };

  const onClickPrev = (index) => {
    let prev = index - 1;
    if (prev < 0) {
    } else {
      setSelectedImage1(carouselImages[prev]);
    }
  };

  //function use to report a listing to admin
  const onReport = async (e) => {
    try {
      e.preventDefault();

      const mess = {
        message: myReport,
      };

      await reportAListing(mess, listing.id);
      setMyReport("");
      trackEvent("Report listing", myReport, "Report listing Btn");

      setReportAlert(true);

      setInterval(() => {
        setReportAlert(false);
      }, 7000);
      setErrorReport("");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrorReport(ex.response.data);
      }
    }
  };

  const onClickCancel = () => {
    setSelectedImage1(null);
  };

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);
    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  const onHoverListing = (data) => {
    setTheSelected(data);
  };

  const onMouseLeave = (data) => {
    if (!theSelected) {
      setTheSelected(data);
    }
  };

  //function to shift the map to a given location when select a given listing
  const onClickSelectedListing = (listing) => {
    panTo({
      lat: listing.location.coordinates.lat
        ? listing.location.coordinates.lat
        : 4.0262755,
      lng: listing.location.coordinates.lng
        ? listing.location.coordinates.lng
        : 9.1927992,
    });
    setTheSelected(listing);
  };

  //function to shift the map to a given postion
  const panTo = React.useCallback(({ lat, lng }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(14);
    }
  }, []);

  const onHoverPicture = (image) => {
    setSelectedImage(image);
  };

  const onShareWindow = async () => {
    try {
      let shar = sharings + 1;
      setSharings(shar);
      await updateShare(IDs, {
        share: sharings + 1,
      });
    } catch (ex) {}
  };

  const onClickSelectedListingImage = (listing) => {
    window.location.assign(`/listing/${listing.id}`);
  };

  if (loadingListing || !keys)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );

  return (
    <>
      {selectedImage1 && (
        <PictureModal1
          selectedImage={selectedImage1}
          pictures={carouselImages}
          onClickCancel={onClickCancel}
          onClickNext={onClickNext}
          onClickPrev={onClickPrev}
        />
      )}
      {reportAlert && <AlertCustomise data={t("We_are_grateful")} />}
      {totallyRejected && (
        <AlertCustomise
          data={t(
            "Your application for this listing have been rejected 4 time, hence you are not authorize to apply again, please kindly apply for a different listing"
          )}
          background="#ffa500"
          color="white"
        />
      )}
      <Meta1
        title={`${getLang()}.${`Nawafrica | Listing Details (${listing.title})`}`}
        description={`Listing Details of ${listing.title}`}
        link={`/listing/${listing.id}`}
      />

      <div
        id="detailsHousesFoundHere"
        style={{ overflow: "hidden" }}
        className="listingDetailsOuterCon"
      >
        {/* container for more Information */}
        <div className="infoContainer">
          <TitlePrice
            verified={list_owner.verified}
            title={_.toUpper(
              listing.title.length > 25
                ? listing.title.slice(0, 25) + "..."
                : listing.title
            )}
            price={`${listing.price} ${listing.currency}`}
            rentOrSell={listing.rent_or_sell}
          />

          <Address
            street_address={listing.street_address}
            city={listing.city}
            region={listing.state}
            country={listing.country}
          />

          <MoreAboutListing
            listing={listing}
            listingType={listing.listing_type}
            rentOrSell={listing.rent_or_sell}
            bedroom={listing.bedrooms}
            kitchen={listing.kitchen}
            advancedPayment={`${commaSeparateNumber(listing.advance_price)} ${
              listing.currency
            }`}
            availableSpace={availableSpace}
          />

          {listing.keyword.length > 0 && <hr className="mapSeparator" />}

          <KeyFeatures keywords={listing.keyword.slice(0, 10)} />

          <hr className="mapSeparator" />

          <Description description={description} />
          <hr className="mapSeparator" />

          <LeaseType
            fixedLeased={
              listing.fixed_agreement ? `-${t("fix_lease_details")}` : ""
            }
            renewFixedLease={
              listing.agreement_after_fixed_agreement.renew_fixed_agreement
                ? `-${t("renew_fixed_lease")}`
                : ""
            }
            continueFixedLeaseMonthToMonth={
              listing.agreement_after_fixed_agreement
                .continue_with_month_to_month
                ? `-${t("continue_fix_with_mth_to_mth")}`
                : ""
            }
            mustVacateFixedLease={
              listing.agreement_after_fixed_agreement.vacateP
                ? `-${t("vacate_fix_lease")}`
                : ""
            }
            monthToMonthAgree={
              listing.month_to_month_agreement
                ? `-${t("month_to_month_lease")}`
                : ""
            }
            sold_agreement={listing.sold_agreement ? `-${t("sold_lease")}` : ""}
          />
          <hr className="mapSeparator" />

          <LocationInMap
            keys={keys}
            geoLocation={{
              lat: listing.location ? listing.location.coordinates.lat : "",
              lng: listing.location ? listing.location.coordinates.lng : "",
            }}
          />

          <hr className="mapSeparator" />

          <OwnerOtherDetails
            src={list_owner.picture}
            user={`${list_owner.first_name} ${list_owner.last_name}`}
            timeListed={calculateDate(listing.dateposted)}
            numOfAppl={applicants.length}
            numOfLikes={likers.length}
            liked={likedAlready}
            handleLike={handleLike}
            id={IDs}
            listing={listing}
            onShareWindow={onShareWindow}
            share={sharings}
            likings={likings}
          />

          {getCurrentUser() &&
            getCurrentUser().post_rent_property !== "I AM A PROPERTY OWNER" && (
              <ApplyButton
                onClick={applyNow}
                applicants={applicants}
                appliedAlready={appliedAlready}
              />
            )}
          {!getCurrentUser() && (
            <ApplyButton
              onClick={applyNow}
              applicants={applicants}
              appliedAlready={appliedAlready}
            />
          )}
        </div>

        {/* container for carousel Image*/}
        <div className="listingDetailsCarouselCon">
          <Carousel
            onClickMessage={() =>
              getCurrentUser() &&
              getCurrentUser().post_rent_property === "I AM A PROPERTY OWNER"
                ? history.push("/portal/messages/message")
                : history.push("/portal/tenants/message")
            }
            images={doubleCarouselImages}
            onImageClick={onImageClick}
            numberOfUnreadMsgs={
              unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
            }
            onHoverPicture={onHoverPicture}
            selectedImage={selectedImage}
          />
        </div>
      </div>

      {/* report a listing container */}
      <div className="reportListingCon">
        <hr className="topHr" />

        <p className="reportListingPara">
          <a
            data-toggle="collapse"
            href="#collapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <span>
              <Spam className="spamClass" />
            </span>
            <span className="spamPara">{t("Report_this_list")}</span>
          </a>
        </p>
        <hr className="bottomHr" />
      </div>
      <div className="collapse" id="collapseExample">
        <form onSubmit={onReport}>
          <div className="card card-body reportCollapseTextarea">
            {errorReport && (
              <p style={{ color: "red", textAlign: "center" }}>{errorReport}</p>
            )}
            <textarea
              value={myReport}
              onChange={(e) => {
                e.preventDefault();
                const { value } = e.currentTarget;
                setMyReport(value);
              }}
              rows="3"
              className="form-control"
              placeholder={t("Report_this_list_ph")}
            />
            <button
              type="submit"
              data-toggle="collapse"
              href="#collapseExample"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              {t("Submit")}
            </button>
          </div>
        </form>
      </div>

      {listingInCity.filter((list) => list.location.coordinates.lat !== "")
        .length > 0 && (
        <OtherListingInCity
          onClickSelectedListingImage={onClickSelectedListingImage}
          listings={listingInCity}
          listingInCityDisplay={listingInCityDisplay}
          listingInCitySmall={listingInCitySmall}
          onMapLoad={onMapLoad}
          keys={keys}
          theSelected={theSelected}
          onHoverListing={onHoverListing}
          onMouseLeave={onMouseLeave}
          listingFromMap={listingFromMap}
          onClickSelectedListing={onClickSelectedListing}
          onClickWindow={(data) => setTheSelected(data)}
          onPressImage={async (data) => {
            setLoadingListing(true);
            setClickOthers(true);
            setID(data.id);
            setListing(data);

            setDescription(data.details);

            setTheSelected(null);

            const { data: allListings } = await getListings();
            const publishData = allListings.filter(
              (d) => d.publish === "publish"
            );
            const allUndeletedListing = publishData.filter(
              (listing) => listing.status !== "deleted"
            );
            const listingInSameCity = allUndeletedListing.filter(
              (list) =>
                list.country == data.country &&
                list.id !== data.id &&
                list.city === data.city
            );
            const listingInSameCityBedrooms = allUndeletedListing.filter(
              (list) =>
                list.country == data.country &&
                list.city === data.city &&
                list.bedrooms == data.bedrooms &&
                list.id !== data.id
            );

            const allInCity = [
              ...listingInSameCityBedrooms,
              ...listingInSameCity,
            ];

            const newArray = new Set();

            for (let i = 0; i < allInCity.length; i++) {
              newArray.add(allInCity[i]);
            }

            let newArrayMade = [];
            const resultMade = spliceCityArr(newArray, newArrayMade);

            let newArrayMadeSmall = [];
            const resultMadeSmall = spliceCityArrSmall(
              newArray,
              newArrayMadeSmall
            );
            console.log(
              "HERE IS THE LISTING IN THE CITY",
              Array.from(newArray)
            );

            setListingInCityDisplay(resultMade);
            setListingInCitySmall(resultMadeSmall);
            setListingInCity(Array.from(newArray));

            const { data: listOwner } = await getUserById(data.list_owner_id);
            setList_owner(listOwner);
            //function to apply for a listing
            const { data: listApplicants } = await getAllApplications(data.id);
            setApplicants(listApplicants);

            //functions to like a listing
            const { data: likesListing } = await getAllLikes(data.id);
            setLikers(likesListing);

            if (
              listApplicants.some(
                (applicant) => applicant.applicant_id === getCurrentUser().id
              )
            ) {
              setAppliedAlready(true);
            } else {
              setAppliedAlready(false);
            }

            if (
              likesListing.some(
                (likes) => likes.liker_id === getCurrentUser().id
              )
            ) {
              setLikedAlready(true);
            } else {
              setLikedAlready(false);
            }

            const carImages = data.other_photos;
            const parlourImage = data.parlour_photo;
            const bedroomImage = data.bedroom_photo;

            !bedroomImage.path["error"] && carImages.unshift(bedroomImage);
            !parlourImage.path["error"] && carImages.unshift(parlourImage);

            const imageResult = spliceImage(carImages);

            setDoubleCarouselImages(imageResult);
            setCarouselImage(carImages);

            history.push(`/listing/${data.id}`);
            setLoadingListing(false);
          }}
        />
      )}

      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default ListingDetails;
