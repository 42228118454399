import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";

import AllLinks from "../components/AboutUs/AllLinks";
import TopHeadingImageOwner from "../components/AboutUs/TopHeadingImageOwner";
import CrewIntroStatement from "../components/AboutUs/CrewIntroStatement";
import CrewImagesContainer from "../components/AboutUs/CrewImagesContainer";
import OpositeCrewContainer from "../components/AboutUs/OpositeCrewContainer";
import HeaderCrew from "../components/AboutUs/HeaderCrew";
import DepartmentContainer from "../components/AboutUs/DepartmentContainer";
import DepartmentContainerOposite from "../components/AboutUs/DepartmentContainerOposite";
import DownloadApp from "../components/AboutUs/DownloadApp";

const AboutUsCrew = () => {
  const [aboutActive, setAboutActive] = useState(false);
  const [impactActive, setImpactActive] = useState(false);
  const [crewActive, setCrewActive] = useState(false);

  useEffect(() => {
    setCrewActive(true);
    window.scrollTo(0, 0);
  }, []);

  const a = () => {
    setAboutActive(true);
    setImpactActive(false);
    setCrewActive(false);
  };

  const b = () => {
    setImpactActive(true);
    setAboutActive(false);
    setCrewActive(false);
  };

  const c = () => {
    setCrewActive(true);
    setImpactActive(false);
    setAboutActive(false);
  };

  return (
    <div>
      <TopHeadingImageOwner team={true} />
      <AllLinks
        aboutActive={aboutActive}
        impactActive={impactActive}
        crewActive={crewActive}
        a={a}
        b={b}
        c={c}
      />
      <DownloadApp />
      <CrewIntroStatement />
      <CrewImagesContainer />
      <OpositeCrewContainer />
      <CrewImagesContainer boss={false} />
      <HeaderCrew
        subtitle="Captivator Technologies inc. is made up of several Department as listed below"
        title={"DEPARTMENTS"}
      />
      <DepartmentContainer
        title="IT DEPARTMENT"
        icon={
          <FontAwesomeIcon
            style={{
              color: "white",
              opacity: "1",
              fontSize: "30px",
              marginBottom: "20px",
            }}
            icon={faMicrochip}
          />
        }
        statement={`For companies that need IT services, we are here to provide reliable IT service that gets the job done right. Whether you're a small company looking for someone dependable or an enterprise client needing 24/7 support, our team can handle any size request. Contact us today and see what sets us apart from other providers. You want IT done right. We have an impeccable track record of successfully delivering projects that meet deadlines and exceed expectations. Our IT department is second to none. Contact us today and see what we have in store for you.`}
      />
      <DepartmentContainerOposite />
      <DownloadApp />
      <ScrollToTop smooth />
    </div>
  );
};

export default AboutUsCrew;
