import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import FacebookLogin from "react-facebook-login";
import Spinner from "../components/Authentication/Spinner";
import cookies from "js-cookie";

import { loginNowWithFacebook } from "./../Services/authService";
import "../components/Authentication/auth.css";
// import Meta from './../Utils/Meta'
import Meta1 from "../Utils/Meta1";

const LoginWithFacebook = () => {
  const [publicKey, setPublicKey] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [langType, setLangType] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getMyKey();
  }, []);

  //function to get facebook public key from the backend
  const getMyKey = async () => {
    try {
      const { data } = await axios.get("/facebook_auth_public_key");
      setLangType(cookies.get("i18next") || "en");
      setPublicKey(data);
    } catch (error) {}
  };

  //function to submit facebook credentials to the backend
  const responseFacebook = async (response) => {
    try {
      setLoading(true);
      const { data } = await loginNowWithFacebook(response);
      setLoading(false);
      localStorage.setItem("token", data);
      window.location = "/";
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        setError(ex.response.data);
      }
    }
  };

  return (
    <div
      style={{
        marginTop: "40px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: "url(/images/googleBackground.jpg)",
        width: "100%",
        height: "700px",
      }}
    >
      <Meta1
        title={`${langType}.Nawafrica | facebook login`}
        description="Login to Nawafrica With Facebook"
        link="/login/facebook"
      />

      <Link
        style={{ marginTop: "20px", marginLeft: "5px" }}
        to="/login"
        className="btn btn-light"
      >
        <FaArrowLeft style={{ marginRight: "5px" }} />
        go back
      </Link>

      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}

      <div className="fbLoginBtnContainer">
        <p
          style={{
            color: "black",
            font: "normal normal bold 16px/28px Merriweather",
          }}
        >
          Click here to Login
        </p>

        {publicKey && (
          <FacebookLogin
            appId={publicKey}
            autoLoad={true}
            fields="name,email ,picture"
            cssClass="facebookLoginBtn"
            // onClick={componentClicked}
            callback={responseFacebook}
          />
        )}

        <p
          style={{
            color: "red",
            font: "normal normal normal 10px/28px Merriweather",
          }}
        >
          {error}
        </p>
      </div>
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </div>
  );
};

export default LoginWithFacebook;
