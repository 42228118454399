import React from 'react'

const Avatar = (props) => {
return (
<>
<svg height="464pt" fill={props.fill}  viewBox="-8 0 464 464.00002" width="464pt" xmlns="http://www.w3.org/2000/svg" {...props}>
                    <path d="m224 121.574219 17.054688-25.574219h-34.109376zm0 0"/>
                    <path d="m0 272c0 22.089844 17.910156 40 40 40h48c13.253906 0 24 10.746094 24 24v112h32v-144c0-22.089844-17.910156-40-40-40h-32c-13.253906 0-24-10.746094-24-24v-88c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v88c0 4.417969 3.582031 8 8 8h96v-32h-72v-80c0-13.253906-10.746094-24-24-24h-32c-22.089844 0-40 17.910156-40 40zm0 0"/>
                    <path d="m448 272v-120c0-22.089844-17.910156-40-40-40h-32c-13.253906 0-24 10.746094-24 24v80h-72v32h96c4.417969 0 8-3.582031 8-8v-88c0-4.417969 3.582031-8 8-8s8 3.582031 8 8v88c0 13.253906-10.746094 24-24 24h-32c-22.089844 0-40 17.910156-40 40v144h32v-112c0-13.253906 10.746094-24 24-24h48c22.089844 0 40-17.910156 40-40zm0 0"/>
                    <path d="m152 200h32v48h80v-48h32v-64c-.066406-20.480469-15.621094-37.585938-36-39.59375l-36 53.992188-36-54.015626c-20.390625 2.007813-35.945312 19.128907-36 39.617188zm0 0" />
                    <path d="m16 325.199219h16v138.800781h-16zm0 0" />
                    <path d="m416 325.199219h16v138.800781h-16zm0 0" />
                    <path d="m232 312h-16v136h-32v16h80v-16h-32zm0 0"/>
                    <path d="m416 56c0 22.089844-17.910156 40-40 40s-40-17.910156-40-40 17.910156-40 40-40 40 17.910156 40 40zm0 0" />
                    <path d="m288.640625 296c1.75-12.164062 7.460937-23.410156 16.246094-32h-161.773438c8.785157 8.589844 14.496094 19.835938 16.246094 32zm0 0"/>
                    <path d="m264 40c0 22.089844-17.910156 40-40 40s-40-17.910156-40-40 17.910156-40 40-40 40 17.910156 40 40zm0 0"  />
                    <path d="m112 56c0 22.089844-17.910156 40-40 40s-40-17.910156-40-40 17.910156-40 40-40 40 17.910156 40 40zm0 0" />
             
                    </svg>
                                                          
</>
)
}

export default Avatar
