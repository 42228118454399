import React from 'react'
import { Link } from 'react-router-dom'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCurrentUser } from './../../Services/authService'

const BlogRecentPost = ({
  to = '/',
  recentContent = 'iaculis eu non diam phasellus vestibulum phasellus vestibulum phasellus vestibulum',
  recentDate = '03 DEC 2022',
  onClickBlogDeleted,
  onClickLink,
}) => {
  return (
    <div className='blogRecentOuterCon'>
      <div style={{ marginBottom: '0' }}>
        <Link style={{overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 5,
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical'}} className='blogRecentContentCon' to={to}>
          {recentContent}
        </Link>
      </div>
      <div className='date'>{recentDate} </div>
      {getCurrentUser() && getCurrentUser().isadmin && (
        <FontAwesomeIcon
          className='icon'
          icon={faTrashCan}
          onClick={onClickBlogDeleted}
        />
      )}
    </div>
  )
}

export default BlogRecentPost
