import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import DisplayCards from '../components/Home/DisplayCards'
import ProbDetTitle from '../components/PropertyManagementDet/ProbDetTitle'
import PropDetHead from '../components/PropertyManagementDet/PropDetHead'
import PropDetSearchInput from '../components/PropertyManagementDet/PropDetSearchInput'
import Pagination from '../components/Home/Pagination'

import { paginate } from '../Utils/pagination'
import { Countries, Regions } from '../Utils/regions'

import { getListings } from '../Services/listings'

const PropertyManagementDet = () => {
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [selectedItem, setSelectedItem] = useState({})

  const [africaCountries, setAfricaCountries] = useState([])
  const [selectedAfricaCountry, setSelectedAfricaCountry] = useState('')

  const [states, setStates] = useState([])
  const [selectedState, setSelectedState] = useState('')

  const [cities, setCities] = useState([])
  const [selectedCity, setSelectedCity] = useState('')

  const [currency, setCurrency] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState('')

  const [bedroom, setBedroom] = useState([])
  const [selectedBedroom, setSelectedBedroom] = useState('')

  const [listing, setListing] = useState([])
  const [selectedListing, setSelectedListing] = useState('')

  const [keyword, setKeyword] = useState([])
  const [selectedKeyword, setSelectedKeyword] = useState('')

  const [price, setPrice] = useState('')
  const [rentOrSell, setRentOrSell] = useState('')

  const [sortColumn, setSortColumn] = useState({
    path: 'dateposted',
    order: 'desc',
  })

  const [pageSize, setPageSize] = useState(12)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    getData()
    window.scrollTo(0, 0)
  }, [])

  const getData = async () => {
    try {
      const { data } = await getListings()
      const publishData = data.filter((d) => d.publish === 'publish')
      const allUndeletedListing = publishData.filter(
        (listing) => listing.status !== 'deleted'
      )
      const sortedListing = _.orderBy(
        allUndeletedListing,
        [sortColumn.path],
        [sortColumn.order]
      )
      setItems([...sortedListing])
    } catch (ex) {}
  }
  const onHoverListing = (listing) => {
    setSelectedItem(listing)
  }

  //function use for filtering and paginating listings
  const getPagedData = () => {
    let filtered = [...items]

    if (selectedBedroom) {
      filtered = filtered.filter(
        (filter) => filter.bedrooms === selectedBedroom
      )
    }

    if (selectedListing) {
      filtered = filtered.filter(
        (filter) => filter.listing_type === selectedListing
      )
    }

    if (price) {
      filtered = filtered.filter(
        (filter) => Number(filter.price) >= Number(price.trim())
      )
    }

    if (selectedKeyword) {
      filtered = filtered.filter((filter) => filter.keyword === selectedKeyword)
    }

    if (selectedCity) {
      filtered = filtered.filter(
        (filter) => filter.city.trim() === selectedCity.trim()
      )
    }

    if (selectedState) {
      filtered = filtered.filter((filter) => filter.state === selectedState)
    }

    if (selectedAfricaCountry) {
      filtered = filtered.filter(
        (filter) => filter.country === selectedAfricaCountry
      )
    }

    if (rentOrSell) {
      filtered = filtered.filter((filter) => filter.rent_or_sell === rentOrSell)
    }

    const itemss = paginate(filtered, currentPage, pageSize)

    return { totalCount: filtered.length, data: itemss }
  }

  //function that is call when a page is click
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  //function to go to next page when presses the next button
  const nextPage = () => {
    let currentPages = currentPage
    currentPages = currentPages + 1
    setCurrentPage(currentPages)
  }

  //function to go to previous page when press the previous button
  const previousPage = () => {
    let count = currentPage
    count = currentPage - 1
    count = count <= 1 ? 1 : count
    setCurrentPage(count)
  }

  //function to sort the searched listing base on a parameter
  const onChangeSort = (e) => {
    e.preventDefault()
    setCurrentPage(1)
    const isListings = [...items]
    const { value } = e.currentTarget
    const column = { ...sortColumn }
    column.path = value
    const sortedListing = _.orderBy(isListings, [column.path], [column.order])
    setItems(sortedListing)
  }
  const { totalCount, data: itemss } = getPagedData()

  return (
    <div
      style={{
        width: '100%',
        margin: '45px 0',
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      <PropDetHead />
      <Link
        to='/property-management'
        style={{
          position: 'absolute',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px 50px',
        }}
        className='btn btn-light'
      >
        {' '}
        <FontAwesomeIcon
          style={{ fontSize: '30px', marginRight: '7px' }}
          icon={faArrowLeftLong}
        />{' '}
        Go Back
      </Link>
      <ProbDetTitle
        title={`Here are all the ${items.length} Listings of James Bond`}
      />
      <PropDetSearchInput
        countries={Countries()}
        onChangeCountry={(e) => {
          const { value } = e.target
          setCurrentPage(1)
          let state = Regions()

          const Citiess =
            value !== ''
              ? state.filter((s) => s.country === value)[0].cities
              : []
          setSelectedCity('')
          setSelectedBedroom('')
          setSelectedListing('')
          setSelectedKeyword('')
          setCities(Citiess)
          setSelectedAfricaCountry(value)
        }}
        valueCountry={selectedAfricaCountry}
        cities={cities}
        placeholderPrice={
          items[0]
            ? `${t('Max_Price')} ${items[0] ? items[0].currency : ''}`
            : ''
        }
        valueCity={selectedCity}
        onChangePrice={(e) => {
          setCurrentPage(1)
          setPrice(e.currentTarget.value)
        }}
        valuePrice={`${price}`}
        onChangeEmpty1={(e) => {
          setCurrentPage(1)
          setSelectedBedroom(e.currentTarget.value)
        }}
        placeholderEmpty2=''
        onChangeEmpty2={(e) => {
          e.preventDefault()
          setCurrentPage(1)
          const { value } = e.currentTarget
          setSelectedListing(value)
        }}
        onChangeCity={(e) => {
          const { value } = e.currentTarget
          setCurrentPage(1)
          setSelectedCity(value)
        }}
        onChangeRentOrSale={(e) => {
          setCurrentPage(1)
          setRentOrSell(e.currentTarget.value)
        }}
        listingType={[
          'Apartment',
          'Studio',
          'Single Room',
          'Office',
          'Store/Shop',
          'Land',
          'Condos',
        ]}
        bedrooms={[
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
        ]}
        valuebedroom={selectedBedroom}
        valueListingType={selectedListing}
        //                onClickSearch={onClickSearch}
        onChangeSort={onChangeSort}
      />
      <DisplayCards
        items={itemss}
        selectedItem={selectedItem}
        onHoverListing={onHoverListing}
      />
      <Pagination
        itemsCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        nextPage={nextPage}
        previousPage={previousPage}
      />
    </div>
  )
}

export default PropertyManagementDet
