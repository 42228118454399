import React from "react";
import { useTranslation } from "react-i18next";

const AddTenantSelect = ({
  onChange,
  value,
  title,
  id,
  type = "text",
  placeholder,
  items = [],
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <label className="addTenantInputLabel" htmlFor={id}>
        {title}
      </label>
      <select
        placeholder={placeholder}
        value={value}
        type={type}
        id={id}
        onChange={onChange}
        className="form-control addTenantInput"
        style={{ cursor: "pointer" }}
        disabled={disabled}
      >
        <option value="">{t("Select a Listing")}</option>
        {items.map((item) => (
          <option key={`${item.id}`} value={item.id}>
            {item.title}
          </option>
        ))}
      </select>
    </>
  );
};

export default AddTenantSelect;
