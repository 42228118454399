import React, { useEffect } from "react";

import auth from "../Services/authService";
import "../components/Authentication/auth.css";
//import LottieLoader from '../components/contactCaptivator/LottieLoader'
import ProfileSpinner from "../components/profile/ProfileSpinner";
import { unActivate } from "../Services/registerService";

const LogoutScreen = () => {
  useEffect(() => {
    removeDatas();
  }, []);

  const removeDatas = async () => {
    unActivate();
    localStorage.setItem("portalPath", "");
    localStorage.setItem("redirect", "");

    auth.logout();
    window.location.assign("/");
  };
  return (
    <div className="homeSpinnerContainer">
      {/* <LottieLoader /> */}
      <ProfileSpinner />
    </div>
  );
};

export default LogoutScreen;
