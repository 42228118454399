import React from "react";
import Typical from "react-typical";
import "./newsroom.css";

const NewsHead = () => {
  return (
    <div
      className="newsHeadOuterCon"
      style={{
        backgroundImage: "url('../../images/newsroom.jpg')",
        backgroundColor: "lightgray",
      }}
    >
      <div className="newsHeadArrowDown">
        <div className="scroll-arrow">
          <svg style={{ opacity: "0.5" }} width="30px" height="20px">
            <path
              stroke="#ffffff"
              fill="black"
              strokeWidth="2px"
              d="M2.000,5.000 L15.000,18.000 L28.000,5.000 "
            ></path>
          </svg>
        </div>
      </div>
      <div className="newsHeadContentCon">
        <div className="newsroomHeadingTextContainer">
          <Typical
            steps={["NAWAFRICA'S MEDIA RESOURCES", 10000]}
            loop={1}
            wrapper="h1"
          />

          <b>
            Check out Nawafrica’s media room for our latest press releases, real
            estate analysis, information about the company , awards and
            recognition And all our products Have a question? Email:
            felix@captivatortechnologies.com
          </b>
        </div>
      </div>
    </div>
  );
};

export default NewsHead;
