import React from 'react'
import Lottie from 'react-lottie'
import { animateLottie } from './lottieAnimation'

const LoadingModal = ({ loading, showMessage }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animateLottie(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div className='loadingModalContainer'>
      <div className='loadingInnerContainer'>
        {loading && showMessage ? (
          <Lottie options={defaultOptions} height={200} width={200} />
        ) : (
          <div className='message'>
            We are grateful to hear from you and will get back to you as soon as
            posible
          </div>
        )}
      </div>
    </div>
  )
}

export default LoadingModal
