import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong, faTrash } from '@fortawesome/free-solid-svg-icons'
import { getCurrentUser } from '../../Services/authService'

const BlogDetailsTitle = ({ item, onClickDelete }) => {
  const getMonth = (month) => {
    if (month == '01') return 'JAN'
    if (month == '02') return 'FEB'
    if (month == '03') return 'MAR'
    if (month == '04') return 'APR'
    if (month == '05') return 'MAY'
    if (month == '06') return 'JUN'
    if (month == '07') return 'JUL'
    if (month == '08') return 'AUG'
    if (month == '09') return 'SEPT'
    if (month == '10') return 'OCT'
    if (month == '11') return 'NOV'
    return 'DEC'
  }
  return (
    <>
      <div className='blogDetBackBtn'>
        <Link className='btn' to='/blog'>
          <div>
            <FontAwesomeIcon className='icon' icon={faArrowLeftLong} />
          </div>
          <div>Go Back</div>
        </Link>
      </div>
      <div className='blogDetHeadTitleCon'>
        <h1>{item.title}</h1>

        <p>
          <span className='first'>BY</span>
          <span className='second'>{item.poster}</span>
          <span className='third'>ON</span>
          <span className='fourth'>
            {`${
              item.create_date && item.create_date.toString().slice(8, 10)
            } ${getMonth(
              item.create_date && item.create_date.toString().slice(5, 7)
            )} ${item.create_date && item.create_date.toString().slice(0, 4)}`}
          </span>
          {getCurrentUser() && getCurrentUser().isadmin && (
            <FontAwesomeIcon
              className='blogDetTrashIcon'
              icon={faTrash}
              onClick={() => onClickDelete(item.id)}
            />
          )}
        </p>
      </div>
    </>
  )
}

export default BlogDetailsTitle
