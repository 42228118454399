import React from 'react'

const VideotTitle = () => {
  return (
    <div className='aboutUsVideoTitleCon'>
      <h2>
        On nawafrica.com, we give Landlords, Property Owners or Property
        Managers a Platform to display their properties and allow interested
        buyers or prospective tenants to view and buy or rent them
      </h2>
    </div>
  )
}

export default VideotTitle
