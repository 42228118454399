import http from "./httpService";

export function register(formData) {
  return http.post(`/register`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function registerWithGoogle(data) {
  return http.post("/register/google_signup", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function registerWithFacebook(data) {
  return http.post("/register/facebook_signup", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

//function to get all registered users
export function registeredUsers() {
  return http.get(`/register`);
}

export function getUserById(id) {
  return http.get(`/register/${id}`);
}

export function updateNameAndContact(id, data) {
  return http.put(`/register/nameAndContact/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function updateAddress(id, data) {
  return http.put(`/register/address/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function updateDescription(id, data) {
  return http.put(`/register/description/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function deleteUser(id) {
  return http.delete(`/register/${id}`);
}

export function forgotYourPassword(data) {
  return http.post("/register/forgotPassword", data, {
    "Content-Type": "application/json",
  });
}

export function changeMyPassword(id, body) {
  return http.put(`/register/new-password/${id}`, body, {
    "Content-Type": "application/json",
  });
}

export function updateLandlordsPaymentMethod(id, body) {
  return http.put(`/register/landlord_payment/${id}`, body, {
    "Content-Type": "application/json",
  });
}

export function getLandlordsPaymentMethod(id) {
  return http.get(`/register/landlord_payment/${id}`);
}

//function to get all registered users
export function registeredAgent() {
  return http.get(`/register/agent`);
}

export function registerForPushNotification(id, data) {
  return http.put(`/expoPushTokens/fb/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getUsers() {
  return http.get(`/register`);
}

export function registerUserForVerification(data) {
  return http.post(`/register/verify`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function getVericationForAUser(id) {
  return http.get(`/register/verification/${id}`);
}

export function unActivate() {
  return http.put(`/register/unactive`);
}
