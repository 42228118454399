import React, { useContext } from "react";
import { FaAngleRight } from "react-icons/fa";
import Chat from "../../iconComponent/Chat";
import Feature from "../../iconComponent/Feature";
import Gear from "../../iconComponent/Gear";
import Grid from "../../iconComponent/Grid";
// import PlusSymbol from '../../iconComponent/PlusSymbol';
import SidebarList from "../../iconComponent/SidebarList";
import ListingItems from "./ListingItems";
import UnreadMsgsContext from "../../unreadmessages/context";
import "./addListing.css";

const OpenButtonTenant = ({ onClickOpenBar, path, chats }) => {
  const leasesPath = "/portal/tenant/leases";
  const applicationPath = "/portal/tenant/applications";
  const messagesPath = "/portal/messages/message";

  const { myLeaseSigned, tenantApplications, myLeasesApplicationsOwed } =
    useContext(UnreadMsgsContext);

  return (
    <div role="button" className="openButtonConTenant">
      <ListingItems
        href={"#"}
        icon={<Grid className="sidebarIconsClassMenu" />}
        openAngle={
          <FaAngleRight
            onClick={onClickOpenBar}
            className="sidebarMenuRightAngle"
          />
        }
        path={path}
        classes={"ListingItemConAddList"}
      />

      <hr className="sidebarHr" />

      <div className="sideBarLinks">
        <ListingItems
          icon={
            <Feature
              style={
                myLeasesApplicationsOwed?.length > 0 ? { color: "#8B0000" } : {}
              }
              className={
                path === leasesPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          href="/portal/tenant/leases"
          path={path}
          chats={
            myLeaseSigned.length === 0
              ? null
              : myLeaseSigned.length > 99
              ? "99+"
              : myLeaseSigned.length
          }
          classes={
            path === leasesPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <SidebarList
              className={
                path === applicationPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          href={"/portal/tenant/applications"}
          path={path}
          chat={
            tenantApplications.length === 0
              ? null
              : tenantApplications.length > 99
              ? "99+"
              : tenantApplications.length
          }
          classes={
            path === applicationPath
              ? "activePortalLink"
              : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <Chat
              className={
                path === messagesPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          chats={chats == 0 ? null : chats > 99 ? "99+" : chats}
          path={path}
          href={"/portal/tenants/message"}
          classes={
            path === messagesPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={<Gear className="sidebarIconLink" />}
          path={path}
          classes={"ListingItemConAddList"}
        />
      </div>
    </div>
  );
};

export default OpenButtonTenant;
