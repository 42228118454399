import {
  faThumbsDown,
  faThumbsUp,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ConfirmActivePayment = ({
  onClickCancel,
  selectedPayment,
  onClickYes,
  onClickNo,
  loadUpdateActive,
}) => {
  return (
    <div className='confirmActivePayOuterCon'>
      <FontAwesomeIcon
        className='kfirmActiveCon'
        icon={faXmarkCircle}
        onClick={onClickCancel}
      />

      <div className='confirmActivePaymentInnerCon'>
        <p
          style={{
            textAlign: 'center',
            font: 'normal normal bold 14px/25px Merriweather',
          }}
        >
          <b>CONFIRM ACTIVE PAYMENT OPTION</b>
        </p>

        <p style={{ font: 'normal normal normal 14px/25px Merriweather' }}>
          Do you confirm the {selectedPayment.payment_option}{' '}
          {selectedPayment.payment_credential.momo_number} as you active account
          option for all your payment transactions ?
        </p>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div
            style={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button onClick={onClickYes} className='btn kfirmYesBtn'>
              <span style={{ marginRight: '5px' }}>
                <FontAwesomeIcon icon={faThumbsUp} />
              </span>
              Yes
            </button>
          </div>
          <div
            style={{
              flex: '1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <button onClick={onClickNo} className='btn kfirmNoBtn'>
              <span style={{ marginRight: '5px' }}>
                <FontAwesomeIcon icon={faThumbsDown} />
              </span>
              No
            </button>
          </div>
        </div>
        {loadUpdateActive && (
          <div style={{ width: '100%', textAlign: 'center' }}>....loading</div>
        )}
      </div>
    </div>
  )
}

export default ConfirmActivePayment
