import React from "react";
import { useTranslation } from "react-i18next";

const PriceAndQuantity = ({
  valueAmount,
  listing,
  onChangeQuantity,
  quantity = 1,
}) => {
  //function to add commas in money
  const { t } = useTranslation();
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        margin: "10px 0",
        border: "1px solid #E5E5E5",
      }}
    >
      <div
        style={{
          width: "80px",
          padding: "7px 0",
          alignItems: "center",
          textAlign: "center",
          font: "normal normal bold 14px/25px Merriweather",
          height: "100%",
          borderRight: "1px solid #E5E5E5",
        }}
      >
        {t("AmountPay")} :
      </div>
      <div
        style={{
          flex: "1",
          padding: "7px 0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          font: "normal normal bold 18px/25px Merriweather",
          color: "#035aa6",
          borderRight: "1px solid #E5E5E5",
        }}
      >
        {commaSeparateNumber(`${Number(listing?.price) * Number(quantity)}`)}{" "}
        {listing?.currency}
      </div>
      <div
        style={{
          width: "30%",
          padding: "7px 0",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {listing?.rent_or_sell == "Rental" && (
          <select
            style={{
              width: "90%",
              padding: 5,
              borderRadius: 5,
              font: "normal normal normal 14px/25px Merriweather",
              cursor: "pointer",
            }}
            onChange={onChangeQuantity}
          >
            <option value={1}>{t("# Month")}(s)</option>
            {[...Array(100).keys()].map((i) => (
              <option key={i} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
};

export default PriceAndQuantity;
