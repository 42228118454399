import React from 'react'
import { useTranslation } from 'react-i18next'

const EditMyTotalAmount = ({
  selectedLeaseToEdit,
  listings,
  confirmEdit,
  cancelEdit,
}) => {
  const { t } = useTranslation()

  const theCurrency = () => {
    const theListing = listings.filter(
      (list) => list.id === selectedLeaseToEdit.listing_id
    )[0]
    return theListing && theListing.currency ? theListing.currency : ''
  }

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`)

    if (myVal.includes(',')) {
      return val
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
      }
      return val
    }
  }

  return (
    <div
      className='modal fade'
      id='exampleModalEditAmount'
      tabIndex='-1'
      role='dialog'
      aria-labelledby='exampleModalEditAmount'
      aria-hidden='true'
    >
      <div className='modal-dialog' role='document'>
        <div className='modal-content editLeaseSignInnerCon'>
          <div className='modal-header'>
            <h5 className='modal-title editModalHeading' id='exampleModalLabel'>
              {t('Confirmation_To_Edit')}
            </h5>
            <button
              type='button'
              className='close editLeaseSignCancelBtn'
              data-dismiss='modal'
              aria-label='Close'
            >
              <span aria-hidden='true'>&times;</span>
            </button>
          </div>
          <div className='modal-body modalInnerbodyContainerLeaseSign'>
            <p className='para'>
              {t("Do_you_confirm_or_Cancel_the_Sum", {
                selectedLeaseToEdit:`${selectedLeaseToEdit.edit_amount}`.startsWith('-')? commaSeparateNumber(
                  `${selectedLeaseToEdit.edit_amount}`.slice(
                    1,
                    `${selectedLeaseToEdit.edit_amount}`.length
                  )
                )
              :selectedLeaseToEdit.edit_amount
              ? commaSeparateNumber(selectedLeaseToEdit.edit_amount)
              : '',
              currency:theCurrency(),
              addOrSubtract:`${selectedLeaseToEdit.edit_amount}`.startsWith('-')
              ? 'SUBTRACT'
              : 'ADDED',
              list_name:selectedLeaseToEdit.listing_name
                ? selectedLeaseToEdit.listing_name.toUpperCase()
                : '',
              landlord:selectedLeaseToEdit.list_owner_name
              ? selectedLeaseToEdit.list_owner_name.toUpperCase()
              : ''
              })}
          
            </p>
          </div>
          <div className='editLeaseSignBtnContainer'>
            <div className='confirmChange'>
              <button
                data-dismiss='modal'
                aria-label='Close'
                onClick={() => confirmEdit(selectedLeaseToEdit)}
                type='button'
                className='btn confirmBtn'
              >
                {t("Confirm_Changes")}
              </button>
            </div>

            <div className='cancelChanges'>
              <button
                data-dismiss='modal'
                aria-label='Close'
                onClick={() => cancelEdit(selectedLeaseToEdit)}
                type='button'
                className='btn cancelChangesBtn'
              >
                {t("Cancel_Changes")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditMyTotalAmount
