import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

const FaqDrop2 = ({
  answer = 'Captivator Technologies is located in Limbe, South west region of Cameroon',
  question = 'Where is Captivator Technologies located ?',
  openWhere = false,
  onClick,
}) => {
  return (
    <div className='faqDrop2OuterCon'>
      <div
        onClick={onClick}
        className={openWhere ? 'openWhere10' : 'openWhere1'}
      >
        <div className='faqDrop2Question'>
          <b>{question}</b>
        </div>
        <div className='faqDrop2IconCon'>
          <FontAwesomeIcon
            className={openWhere ? 'fag-about-icon1' : 'fag-about-icon'}
            icon={faCaretDown}
          />
        </div>
      </div>

      <div className={openWhere ? 'openAnswer1' : 'openAnswer'}>
        <div className='faqDrop2Separator' />
        <div className='faqDrop2Answer'>{answer}</div>
      </div>
    </div>
  )
}

export default FaqDrop2
