import React from 'react'
import { useTranslation } from 'react-i18next'

import './applications.css'

const LoadingSpinner = () => {
  const { t } = useTranslation()

  return (
    <div className='spinner-border' role='status'>
      <span className='sr-only'>{t('Loading...')}</span>
    </div>
  )
}

export default LoadingSpinner
