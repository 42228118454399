import React, { useEffect, useState } from "react";

import NewsListItem from "../components/Newsroom/NewsListItem";
import NewsroomDetailsContainer from "../components/NewsroomDetails/NewsroomDetailsContainer";
import { deleteNewsById, getNewsById } from "../Services/newsService";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import logger from "../Services/loggerService";
import DownloadApp from "../components/AboutUs/DownloadApp";

const NewsroomDetails = ({ match, history }) => {
  const [selectedData, setSelectedData] = useState({
    paragraphs: [],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const id = match.params.id;
      const { data } = await getNewsById(id);
      setSelectedData(data);
      setLoading(false);
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        logger.log(ex.response.data);
      }
    }
  };

  const onClickNewsDeleted = async (id) => {
    try {
      await deleteNewsById(id);
      history.push("/newsroom");
    } catch (error) {
      logger.log(error);
    }
  };

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );

  return (
    <div className="newsroomAddOuterCon">
      <NewsListItem
        press={selectedData.catergory === "Press Release" ? true : false}
        company={selectedData.catergory === "Company info" ? true : false}
        award={selectedData.catergory === "Awards & Recognition" ? true : false}
        research={
          selectedData.catergory === "Nawafrica's Research" ? true : false
        }
        products={selectedData.catergory === "Our Products" ? true : false}
      />
      <NewsroomDetailsContainer
        onClickNewsDeleted={onClickNewsDeleted}
        data={selectedData}
      />
      <DownloadApp />
    </div>
  );
};

export default NewsroomDetails;
