import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'
import AOS from 'aos'
import 'aos/dist/aos.css'

const Recruitment = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 })
  }, [])
  return (
    <div className='careerRecruitmentCon'>
      <div className='careerRecrContent'>
        <h3
          // data-aos='fade-left'
          data-aos='flip-up'
          style={{ textAlign: 'center', color: '#ffa500' }}
        >
          RECRUITMENT AND JOB OPPORTUNIES
        </h3>
        <p>
          <a
            className='careerRecrLink'
            href='https://www.captivatortechnologies.com/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Captivator technologies inc.
          </a>{' '}
          is a software company whose success is achieve only through team work
          and excellent leadership. We have a team of hardworking experts on
          various technologically sectors such as Web develpers, mobile
          developer, cybersecurity experts who work tireless to ensure that
          every project is build with high level of expertise and also highly
          secure from cyber criminals. As one of the fast growing Tech company
          in Africa, we open up Job opportunity of highly skilled individuals in
          the tech domain. Our nawafrica.com brand was created for Real Estate
          and property manager.
        </p>
      </div>
      <div className='commitToReccon'>
        <div className='content'>
          <h1>
            We are Committed to Recruite Deligent and hardworking Individuals
          </h1>
          <p className='careerCommitted'>
            Captivator Technologies, Inc. is committed to recruiting diligent
            and hard working individuals who are interested and passionate about
            technology and to use technology to create measured impact in Africa
            and the world at large by building softwares that can help and solve
            the problem of humanity
            <p>
              <a
                className='btn careerCapTvBtn'
                href='https://www.captivatortechnologies.com/'
                target='_blank'
                rel='noopener noreferrer'
              >
                <div style={{ flex: '13' }}>
                  Go To Captivator technologies inc.
                </div>
                <div
                  style={{
                    flex: '1',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    className='careerArrowCapTv'
                    icon={faArrowRightLong}
                  />
                </div>
              </a>
            </p>
          </p>
        </div>

        <img
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src='../../images/applicant.jpg'
        />
      </div>
    </div>
  )
}

export default Recruitment
