export function leaseLottie() {
  return {
    v: '4.10.1',
    fr: 24,
    ip: 0,
    op: 72,
    w: 400,
    h: 400,
    nm: 'Comp 1',
    ddd: 0,
    assets: [
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Capa de formas 12',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: '0p833_0p833_0p167_0p167',
                    t: 24,
                    s: [81.5, 370.25, 0],
                    e: [445.5, 199.25, 0],
                    to: [60.6666679382324, -28.5, 0],
                    ti: [-60.6666679382324, 28.5, 0],
                  },
                  { t: 48 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [43, 43, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [28, 0],
                          [34.935, -19.483],
                          [31.619, 18.821],
                          [33, -14],
                          [57, 29],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-28, 0],
                          [-52, 29],
                          [-42, -25],
                          [-28.892, 12.257],
                          [-57, -29],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [367.75, -97],
                          [277, -75],
                          [155, -82],
                          [35, -82],
                          [-94, -82.326],
                          [-200, -74],
                          [-352.07, 320.209],
                          [499.162, 354.093],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Trazado 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Relleno 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transformar',
                  },
                ],
                nm: 'Forma 1',
                np: 3,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 144,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Capa de formas 2',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.167 },
                    n: '0p833_0p833_0p167_0p167',
                    t: 24,
                    s: [-133, 374, 0],
                    e: [231, 203, 0],
                    to: [60.6666679382324, -28.5, 0],
                    ti: [-60.6666679382324, 28.5, 0],
                  },
                  { t: 48 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [43, 43, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [28, 0],
                          [34.935, -19.483],
                          [31.619, 18.821],
                          [33, -14],
                          [57, 29],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-28, 0],
                          [-52, 29],
                          [-42, -25],
                          [-28.892, 12.257],
                          [-57, -29],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [367.75, -97],
                          [277, -75],
                          [155, -82],
                          [35, -82],
                          [-94, -82.326],
                          [-200, -74],
                          [-352.07, 320.209],
                          [499.162, 354.093],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Trazado 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 0.6470588235294118, 0, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Relleno 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transformar',
                  },
                ],
                nm: 'Forma 1',
                np: 3,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 144,
            st: 0,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'Capa de formas 5',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                n: ['0p833_0p833_0p167_0p167'],
                t: 15,
                s: [100],
                e: [0],
              },
              { t: 16 },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 0,
                s: [199, -14.000000000000002, 0],
                e: [199, 156, 0],
                to: [0, 28.3333339691162, 0],
                ti: [0, -28.9375, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 12,
                s: [199, 156, 0],
                e: [199, 164.066, 0],
                to: [0, 4.54861259460449, 0],
                ti: [0, -2.45892143249512, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 13,
                s: [199, 164.066, 0],
                e: [199, 166.125, 0],
                to: [0, 13.1843204498291, 0],
                ti: [0, -1.72074222564697, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 14,
                s: [199, 166.125, 0],
                e: [199, 168.375, 0],
                to: [0, 2.04166674613953, 0],
                ti: [0, -0.04166666790843, 0],
              },
              { t: 15 },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-1, -182.375, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                n: [
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                ],
                t: 0,
                s: [50, 50, 100],
                e: [50, 94, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                n: [
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                ],
                t: 12,
                s: [50, 94, 100],
                e: [70, 43.333, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                n: [
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                ],
                t: 13,
                s: [70, 43.333, 100],
                e: [104.25800000000001, 32, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                n: [
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                ],
                t: 14,
                s: [104.25800000000001, 32, 100],
                e: [212, 18, 100],
              },
              { t: 15 },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0.938, 0],
                      [0, -5.25],
                      [-4.563, 0.125],
                      [0.108, 4.624],
                    ],
                    o: [
                      [-0.813, 0.125],
                      [0, 4.813],
                      [4.563, -0.125],
                      [-0.125, -5.375],
                    ],
                    v: [
                      [-1.344, -193.078],
                      [-8.75, -180.5],
                      [-1.063, -172.313],
                      [6.938, -180.188],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Trazado 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.011764705882352941, 0.35294117647058826,
                    0.6509803921568628, 1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                nm: 'Relleno 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transformar',
              },
            ],
            nm: 'Forma 1',
            np: 3,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 60,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 8,
        ty: 4,
        nm: 'Capa de formas 3',
        sr: 1,
        ks: {
          o: {
            a: 1,
            k: [
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                n: ['0p833_0p833_0p167_0p167'],
                t: 46,
                s: [0],
                e: [100],
              },
              {
                i: { x: [0.833], y: [0.833] },
                o: { x: [0.167], y: [0.167] },
                n: ['0p833_0p833_0p167_0p167'],
                t: 47,
                s: [100],
                e: [100],
              },
              { t: 48 },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
          p: {
            a: 1,
            k: [
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 47,
                s: [199.98, 168.25, 0],
                e: [199.98, 158.037, 0],
                to: [0, -0.20375619828701, 0],
                ti: [0, 17.58864402771, 0],
              },
              {
                i: { x: 0.833, y: 0.833 },
                o: { x: 0.167, y: 0.167 },
                n: '0p833_0p833_0p167_0p167',
                t: 48,
                s: [199.98, 158.037, 0],
                e: [199.98, -10, 0],
                to: [-2.8421709430404e-14, -50.4047393798828, 0],
                ti: [0, 1.17485654354095, 0],
              },
              { t: 53 },
            ],
            ix: 2,
          },
          a: { a: 0, k: [-32, -31, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                n: [
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                ],
                t: 47,
                s: [-4, 1, 100],
                e: [1.5, 4, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                n: [
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                  '0p833_0p833_0p167_0p167',
                ],
                t: 48,
                s: [1.5, 4, 100],
                e: [2, 3, 100],
              },
              { t: 53 },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [308, 308], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Trazado elíptico 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.011764705882352941, 0.35294117647058826,
                    0.6509803921568628, 1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                nm: 'Relleno 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-31, -31], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transformar',
              },
            ],
            nm: 'Elipse 1',
            np: 3,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 60,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 9,
        ty: 4,
        nm: 'Capa de formas 4',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [199, 252.99999999999997, 0], ix: 2 },
          a: { a: 0, k: [-32, -31, 0], ix: 1 },
          s: { a: 0, k: [55.00000000000001, 55.00000000000001, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [308, 308], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Trazado elíptico 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [
                    0.011764705882352941, 0.35294117647058826,
                    0.6509803921568628, 1,
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 10, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                nm: 'Trazo 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-31, -31], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transformar',
              },
            ],
            nm: 'Elipse 1',
            np: 3,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 12,
                  s: [50],
                  e: [100],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 24,
                  s: [100],
                  e: [50],
                },
                { t: 48 },
              ],
              ix: 1,
            },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 12,
                  s: [50],
                  e: [0],
                },
                {
                  i: { x: [0.833], y: [0.833] },
                  o: { x: [0.167], y: [0.167] },
                  n: ['0p833_0p833_0p167_0p167'],
                  t: 24,
                  s: [0],
                  e: [50],
                },
                { t: 48 },
              ],
              ix: 2,
            },
            o: { a: 0, k: 180, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Recortar trazados 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 0,
        op: 60,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 10,
        ty: 4,
        nm: 'Capa de formas 1',
        td: 1,
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [199, 252.99999999999997, 0], ix: 2 },
          a: { a: 0, k: [-32, -31, 0], ix: 1 },
          s: { a: 0, k: [50, 50, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [308, 308], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Trazado elíptico 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.011764705882352941, 0.35294117647058826,
                    0.6509803921568628, 1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                nm: 'Relleno 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [-31, -31], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transformar',
              },
            ],
            nm: 'Elipse 1',
            np: 3,
            cix: 2,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 60,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 11,
        ty: 0,
        nm: 'Precomp. 1',
        tt: 1,
        refId: 'comp_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [200, 200, 0], ix: 2 },
          a: { a: 0, k: [200, 200, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        w: 400,
        h: 400,
        ip: 0,
        op: 144,
        st: 0,
        bm: 0,
      },
    ],
  }
}
