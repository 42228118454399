import React from 'react'

const InputContact = ({ name, placeholder, id, type = 'text', onChange }) => {
  return (
    <div style={{ width: '100%' }}>
      <label style={{ color: '#035aa6' }} htmlFor={id} className='form-label'>
        {name} <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
      </label>
      <input
        type={type}
        className='form-control'
        id={id}
        placeholder={placeholder}
        onChange={onChange}
      ></input>
    </div>
  )
}

export default InputContact
