import React from "react";
import { useTranslation } from "react-i18next";

import "./addListing.css";

const PositionCheckbox = ({ onChange, value, onClickCheckBox }) => {
  const { t } = useTranslation();
  return (
    <div onClick={onClickCheckBox} className="addListCheckboxCon">
      <div className="inputCon">
        <input
          className="postionChecked"
          checked={value}
          type="checkbox"
          onChange={onChange}
        />
      </div>
      <div className="paraCon">
        <p>{t("iam_in_position")}</p>
      </div>
    </div>
  );
};

export default PositionCheckbox;
