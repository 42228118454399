import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import FacebookLogin from "react-facebook-login";

import { registerWithFacebook } from "../Services/registerService";
import Spinner from "../components/Authentication/Spinner";

import { getLang } from "../Utils/getLang";
import Meta1 from "../Utils/Meta1";
import logger from "../Services/loggerService";

import "../components/Authentication/auth.css";

const SignupWithFacebook = ({ history }) => {
  const currentLanguageCode = cookies.get("i18next");
  const { t } = useTranslation();

  const [role, setRole] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const myItemFromLocalStorage = localStorage.getItem("facebook-signup-role");
    if (!myItemFromLocalStorage) {
      history.push("/facebook_signUp1");
    } else {
      setRole(myItemFromLocalStorage);
    }
    getMyKey();
  }, [history]);

  //function to get the facebook public key from the backend
  const getMyKey = async () => {
    try {
      const { data } = await axios.get("/facebook_auth_public_key");
      setPublicKey(data);
    } catch (error) {
      logger.log(error);
    }
  };

  //function to submit google credentials
  const responseFacebook = async (response) => {
    console.log(response);
    if (!response.email) {
      return setError(
        "Please, ensure that you are not using a facebook business account"
      );
    }
    try {
      setLoading(true);
      response.role = role;
      console.log(response);
      const { data } = await registerWithFacebook(response);
      localStorage.setItem("token", data);
      setLoading(false);
      window.location = "/profile";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setError(ex.response.data);
        setLoading(false);
      }
    }
  };

  return (
    <div
      style={{
        marginTop: "40px",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundImage: "url(/images/googleBackground.jpg)",
        width: "100%",
        height: "700px",
      }}
    >
      <Meta1
        title={`${getLang()}.${t(`Nawafrica | facebook_signup`)}`}
        description="Sign Up into Nawafrica With Facebook"
        link="/facebook_signUp1"
      />

      <Link
        style={{ marginTop: "20px", marginLeft: "5px" }}
        to="/facebook_signUp1"
        className="btn btn-light"
      >
        <FaArrowLeft style={{ marginRight: "5px" }} />
        go back
      </Link>
      {loading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}

      <div className="fbLoginBtnContainer">
        <p
          style={{
            color: "white",
            textAlign: "center",
            font: "normal normal bold 16px/28px Merriweather",
          }}
        >
          {t("Click_here_to_Sign_up")}
        </p>

        {publicKey && (
          <FacebookLogin
            appId={publicKey}
            cssClass="facebookLoginBtn"
            autoLoad={true}
            fields="name,email,picture"
            // onClick={componentClicked}
            callback={responseFacebook}
          />
        )}

        <p
          style={{
            color: "red",
            font: "normal normal normal 10px/28px Merriweather",
          }}
        >
          {currentLanguageCode === "en"
            ? error
            : currentLanguageCode === "fr"
            ? "E-mail déjà enregistré, veuillez vous connecter"
            : error}
        </p>
      </div>
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </div>
  );
};

export default SignupWithFacebook;
