import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons";

const CEOImage = () => {
  return (
    <div className="aboutCeoOutcon">
      <div className="aboutOwnerImageCon">
        <img
          style={{
            width: "100%",
            height: "100%",
          }}
          src="../../images/boss-leo.jpg"
        />
      </div>
      <div className="CEOImageContentCon">
        <div>
          {" "}
          <FontAwesomeIcon className="ceoIcon" icon={faBusinessTime} />
        </div>
        <p>
          <b style={{ fontSize: "25px" }}>Leonard Ayamba Ndifon</b>
        </p>
        <p>
          <i style={{ fontSize: "14px", color: "#035aa6" }}>
            <b>CEO/Founder of Captivator technologies inc.</b>
          </i>
        </p>
        <p style={{ fontSize: "16px" }}>
          Cybersecurity expert/Software Engineer
        </p>
        <p>United states of America</p>
      </div>
    </div>
  );
};

export default CEOImage;
