import React from 'react'
import { useTranslation } from 'react-i18next'

const WelcomeUser = ({ user }) => {
  const { t } = useTranslation()

  return (
    <div className='addListWelcomeConLease'>
      <h6>{t('Welcome_user', { user })}</h6>
    </div>
  )
}

export default WelcomeUser
