import React from "react";
import "simplebar";
import "simplebar/dist/simplebar.css";
import _ from "lodash";
import { FaGavel, FaHandshake } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import AddchartIcon from "@mui/icons-material/Addchart";
import Tooltip from "@mui/material/Tooltip";

import LoadingSpinner from "./LoadingSpinner";

import "./applications.css";

const ApplicationsTable = ({
  applicants,
  applicationSelected,
  allStatus,
  loadingStatus,
  users,
  loadingSearchListings,
  selectedApplicant,
  showTenantPrevLeases,
}) => {
  const { t } = useTranslation();

  const sortColumn = {
    path: "dateposted",
    order: "asc",
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const filteredUserContact = (id) => {
    const allUsers = [...users];

    const user = allUsers.filter((user) => user.id === id)[0];

    return user && user.contact ? user.contact : "--";
  };

  const filterStatuses = (id) => {
    const status = allStatus.filter((all) => all.application_id === id);
    const sortedListing = _.orderBy(
      status,
      [sortColumn.path],
      [sortColumn.order]
    );
    const myStatus = sortedListing[sortedListing.length - 1];
    return myStatus
      ? myStatus
      : { accepted_or_denied: null, lease_signed: null, under_review: null };
  };

  const TenantSign = (id) => {
    const status = allStatus.filter((all) => all.application_id === id);

    return status[0] && status[0].tenant_sign_lease ? true : false;
  };

  const BothSign = (id) => {
    const status = allStatus.filter((all) => all.application_id === id);

    return status[0] && status[0].landlord_and_tenant_sign_lease ? true : false;
  };

  function dateConvert(date) {
    const dateArray = date.split("-");

    return dateArray ? `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}` : "";
  }

  return (
    <>
      <div
        style={{ paddingRight: 7 }}
        data-simplebar
        className="applicationTableCon applTopTableCon visibleForLargeScreen"
      >
        <table className="table table-striped">
          <thead className="myListThead">
            <tr>
              <th
              className="myListTh"
              cope="col"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t("Applicants_Name")}
              </th>
              <th
              className="myListTh"
              scope="col"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t("Applicants_Tel")}
              </th>
              <th
              className="myListTh"
              scope="col"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t("Listing_Name")}
              </th>
              <th
              className="myListTh"
              scope="col"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t("Listing_Address")}
              </th>
              <th
              className="myListTh"
              scope="col"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t("Date_Applied")}
              </th>
              <th
              className="myListTh"
              scope="col"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t("Last_Contact_Date")}
              </th>
              <th
              className="myListTh"
              scope="col"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t("Approval_Status")}
              </th>
            </tr>
          </thead>
          <tbody>
            {applicants.map((appl) => (
              <tr key={`${appl.id}`} className="table-column">
                <th className="myListTd">
                  <a
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 1,
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                      clear: "both",
                      display: "inline-block",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                  >
                    <span>
                      {BothSign(appl.id) && (
                        <FaHandshake
                          data-tip="Agreement Sealed"
                          className="myHandShakeIcon"
                        />
                      )}
                      {TenantSign(appl.id) && (
                        <FaGavel
                          data-tip="Lease was Signed"
                          className="myHandShakeIcon"
                        />
                      )}
                    </span>
                    {capitalize(appl.appicant_name)}
                  </a>
                </th>
                <td className="myListTd">
                  <a
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                    style={{
                      clear: "both",
                      display: "inline-block",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {filteredUserContact(appl.applicant_id)}
                  </a>
                </td>
                <td className="myListTd">
                  <a
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 1,
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                  >
                    {capitalize(appl.listing_name)}
                  </a>
                </td>
                <td className="myListTd">
                  <a
                    href="#"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 1,
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                  >
                    {capitalize(appl.listing_address)}
                  </a>
                </td>
                <td className="myListTd">
                  <a
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                  >
                    {dateConvert(appl.create_date.slice(0, 10))}
                  </a>
                </td>
                <td className="myListTd">
                  <a
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                  >
                    {dateConvert(appl.update_date.slice(0, 10))}
                  </a>
                </td>
                <td className="myListTd">
                  {loadingStatus && applicationSelected === appl ? (
                    <span>
                      <LoadingSpinner />
                    </span>
                  ) : (
                    <div
                      // onClick={() => selectedApplicant(appl)}
                      className="applicationTableAtag"
                      style={{
                        display: "flex",
                        fontSize: "13px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        clear: "both",
                        display: "inline-block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {filterStatuses(appl.id).lease_signed
                        ? t(filterStatuses(appl.id).lease_signed)
                        : filterStatuses(appl.id).approved
                        ? t(filterStatuses(appl.id).approved)
                        : filterStatuses(appl.id).accepted_or_denied
                        ? filterStatuses(appl.id).accepted_or_denied
                        : filterStatuses(appl.id).under_review
                        ? t(filterStatuses(appl.id).under_review)
                        : t("recieved")}{" "}
                      {filterStatuses(appl.id).under_review && (
                        <Tooltip
                          title={`View and analyse signed lease history of  ${capitalize(
                            appl.appicant_name
                          )}`}
                        >
                          <button
                            onClick={() => showTenantPrevLeases(appl)}
                            style={{ padding: "2px" }}
                            className="btn btn-light"
                          >
                            <AddchartIcon sx={{ fontSize: "16px" }} />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ))}

            {loadingSearchListings && <span>{t(".....loading")}</span>}
          </tbody>
        </table>
        <ReactTooltip effect="solid" />
      </div>

      <div data-simplebar className="applicationTableCon5 visibleForMobile">
        <table className="table table-striped">
          <thead className="myListThead">
            <tr>
              <th className="myListTh" scope="col">
                {t("Applicants_Name")}
              </th>
              <th className="myListTh" scope="col">
                {t("Applicants_Tel")}
              </th>
              <th className="myListTh" scope="col">
                {t("Listing_Name")}
              </th>
              <th className="myListTh" scope="col">
                {t("Last_Contact_Date")}
              </th>
              <th className="myListTh" scope="col">
                {t("Approval_Status")}
              </th>
            </tr>
          </thead>
          <tbody>
            {applicants.map((appl, index) => (
              <tr key={`${appl.id}`} className="table-column">
                <th className="myListTd">
                  <a
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 1,
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                  >
                    <span>
                      {BothSign(appl.id) && (
                        <FaHandshake
                          data-tip="Agreement Sealed"
                          className="myHandShakeIcon"
                        />
                      )}
                      {TenantSign(appl.id) && (
                        <FaGavel
                          data-tip="Lease was Signed"
                          className="myHandShakeIcon"
                        />
                      )}
                    </span>
                    {capitalize(appl.appicant_name)}
                  </a>
                </th>
                <td className="myListTd">
                  <a
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 1,
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {filteredUserContact(appl.applicant_id)}
                  </a>
                </td>
                <td className="myListTd">
                  <a
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      lineClamp: 1,
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {capitalize(appl.listing_name)}
                  </a>
                </td>
                <td className="myListTd">
                  <a
                    href="#"
                    onClick={() => selectedApplicant(appl)}
                    className="applicationTableAtag"
                    // data-toggle='modal'
                    // data-target='#exampleModalApplicationTable'
                  >
                    {dateConvert(appl.update_date.slice(0, 10))}
                  </a>
                </td>
                <td className="myListTd">
                  {loadingStatus && applicationSelected === appl ? (
                    <span>
                      <LoadingSpinner />
                    </span>
                  ) : (
                    <div
                      // onClick={() => selectedApplicant(appl)}
                      className="applicationTableAtag"
                      style={{
                        display: "flex",
                        fontSize: "13px",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        clear: "both",
                        display: "inline-block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                      // data-toggle='modal'
                      // data-target='#exampleModalApplicationTable'
                    >
                      {filterStatuses(appl.id).lease_signed
                        ? t(filterStatuses(appl.id).lease_signed)
                        : filterStatuses(appl.id).approved
                        ? t(filterStatuses(appl.id).approved)
                        : filterStatuses(appl.id).accepted_or_denied
                        ? filterStatuses(appl.id).accepted_or_denied
                        : filterStatuses(appl.id).under_review
                        ? t(filterStatuses(appl.id).under_review)
                        : t("recieved")}{" "}
                      {filterStatuses(appl.id).under_review && (
                        <Tooltip
                          title={`View and analyse all previously signed lease of  ${capitalize(
                            appl.appicant_name
                          )}`}
                        >
                          <button
                            onClick={() => showTenantPrevLeases(appl)}
                            style={{ padding: "2px" }}
                            className="btn btn-light"
                          >
                            <AddchartIcon sx={{ fontSize: "16px" }} />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ))}

            {loadingSearchListings && <span>{t(".....loading")}</span>}
          </tbody>
        </table>
      </div>

      <ReactTooltip effect="solid" />
    </>
  );
};

export default ApplicationsTable;
