import React from 'react'
import { Link } from 'react-router-dom'
import ContactUsCard from '../contactCaptivator/ContactUsCard'

const ConsultServiceCards = () => {
  return (
    <div className='consultSerCardContainer'>
      <ContactUsCard
        title='Email Us'
        content={
          <>
            <p style={{ fontSize: '18px' }}>We're here to help</p>
            <p>
              <b style={{ color: '#035aa6', fontWeight: 'bolder' }}>
                Customer services
              </b>
            </p>
            <p>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
                href='mailto: sylvie@captivatortechnologies.com'
              >
                sylvie@captivatortechnologies.com
              </a>
            </p>
            <p>
              {' '}
              <b style={{ color: '#035aa6', fontWeight: 'bolder' }}>
                Technical support
              </b>
            </p>
            <p>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
                href='mailto: felix@captivatortechnologies.com'
              >
                felix@captivatortechnologies.com
              </a>
            </p>
            <p>
              <b style={{ color: '#035aa6', fontWeight: 'bolder' }}>
                Media enquiries
              </b>
            </p>
            <p>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
                href='mailto: media@captivatortechnologies.com'
              >
                media@captivatortechnologies.com
              </a>
            </p>
            <p>
              <b style={{ color: '#035aa6', fontWeight: 'bolder' }}>
                Blogs enquiries
              </b>
            </p>
            <p>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
                href='mailto: blog@captivatortechnologies.com'
              >
                blog@captivatortechnologies.com
              </a>
            </p>
            <p>
              <b style={{ color: '#035aa6', fontWeight: 'bolder' }}>
                Job enquiries
              </b>
            </p>
            <p>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
                href='mailto: application@captivatortechnologies.com'
              >
                application@captivatortechnologies.com
              </a>
            </p>
          </>
        }
        borderTop='10px solid #035aa6'
      />
      <ContactUsCard
        title='Call Us'
        middle={true}
        content={
          <>
            <p style={{ fontSize: '22px' }}>
              For general queries or technical questions, please call Monday -
              Saturday, 7AM - 5PM GMT
            </p>
            <p>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
                href='tel:237650432299'
              >
                <span>Cameroon :</span>
                <span>+237650432299</span>
              </a>
            </p>
            <p>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
                href='tel:237680500249'
              >
                <span>Cameroon :</span>
                <span>+237680500249</span>
              </a>
            </p>
            <p>
              <a
                style={{
                  color: 'black',
                  textDecoration: 'underline',
                }}
                href='tel:+12404608841'
              >
                <span>USA :</span>
                <span>+12404608841</span>
              </a>
            </p>
          </>
        }
        borderTop='10px solid #ffa500'
      />
      <ContactUsCard
        title='FAQs'
        content={
          <>
            <p style={{ fontSize: '22px' }}>
              Find answers to the most common questions in our
              <Link style={{ marginLeft: '5px' }} to='/faq'>
                FAQ section
              </Link>{' '}
              .
            </p>
          </>
        }
        borderTop='10px solid #035aa6'
      />
    </div>
  )
}

export default ConsultServiceCards
