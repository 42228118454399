import React from "react";
import { useTranslation } from "react-i18next";

import "./addListing.css";

const LeasingType = ({
  onChangeFixedLease,
  onClickFixedLease,
  valueFixedLease,
  onChangeFixedLeaseRenew,
  onClickFixedLeaseRenew,
  onChangeContinueLease,
  onClickContinueLease,
  valueContinueLease,
  onChangeVacate,
  onClickVacate,
  onChangeMonthToMonth,
  onClickMonthToMonth,
  valueMonthToMonth,
  valueVacate,
  valueRenewFixedLease,
  onChangeSold,
  onClickSold,
  valueSold,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <p style={{ cursor: "pointer" }} onClick={onClickFixedLease}>
        <span className="tenantSignModalAgreement">
          <input
            checked={valueFixedLease}
            onChange={onChangeFixedLease}
            type="checkbox"
          />
        </span>
        <span className="tenantSignModalAgreement">
          - {t("fix_lease")}
        </span>
      </p>
      <p
        onClick={onClickFixedLeaseRenew}
        style={{ cursor: "pointer" }}
        className="theLeftMarginInTenantSignModalAgreement"
      >
        <span className="tenantSignModalAgreement">
          <input
            checked={valueRenewFixedLease}
            onChange={onChangeFixedLeaseRenew}
            type="checkbox"
          />
        </span>
        <span className="tenantSignModalAgreement">
          -{t("renew_fixed_lease")}
        </span>
      </p>
      <p
        style={{ cursor: "pointer" }}
        onClick={onClickContinueLease}
        className="theLeftMarginInTenantSignModalAgreement"
      >
        <span className="tenantSignModalAgreement">
          <input
            checked={valueContinueLease}
            onChange={onChangeContinueLease}
            type="checkbox"
          />
        </span>
        <span className="tenantSignModalAgreement">
          -{t("continue_fix_with_mth_to_mth_switch")}
        </span>
      </p>
      <p
        style={{ cursor: "pointer" }}
        onClick={onClickVacate}
        className="theLeftMarginInTenantSignModalAgreement"
      >
        <span className="tenantSignModalAgreement">
          <input
            checked={valueVacate}
            onChange={onChangeVacate}
            type="checkbox"
          />
        </span>
        <span className="tenantSignModalAgreement">
          -{t("vacate_fix_lease")}
        </span>
      </p>

      <p onClick={onClickMonthToMonth} style={{ cursor: "pointer" }}>
        <span className="tenantSignModalAgreement">
          <input
            checked={valueMonthToMonth}
            onChange={onChangeMonthToMonth}
            type="checkbox"
          />
        </span>
        <span className="tenantSignModalAgreement">
          -{t("month_to_month_lease")}
        </span>
      </p>

      <p onClick={onClickSold} style={{ cursor: "pointer" }}>
        <span className="tenantSignModalAgreement">
          <input checked={valueSold} onChange={onChangeSold} type="checkbox" />
        </span>
        <span className="tenantSignModalAgreement">-{t("sold_lease")}</span>
      </p>
    </>
  );
};

export default LeasingType;
