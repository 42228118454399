import React from "react";
import video from "../../Videos/Office - 80314.mp4";

const TopHeadingImageOwner = ({ team }) => {
  return (
    <div className="aboutUsTopHeadImgOwnerCon">
      <div className="aboutUsTopHeadImgInnerOwnerCon">
        <div className="aboutOwnerH2ImageCon">
          <h1>
            {!team && (
              <span style={{ marginRight: "10px" }}>
                Nawafrica.com is one of the brands of
              </span>
            )}
            <a
              style={{ textDecoration: "none", color: "#ffa500" }}
              href="https://www.captivator.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              CAPTIVATOR TECHNOLOGIES INC.
            </a>{" "}
            {team && "is a fast growing Tech company in Africa"}
          </h1>
        </div>
      </div>
      {!team && (
        <img
          style={{ width: "100%", height: "100%" }}
          src="../../images/owner.jpeg"
        />
      )}
      {team && (
        <video
          style={{ width: "100%", height: "100%" }}
          autostart="true"
          autoPlay
          loop
        >
          <source src={video} type="video/mp4" />
        </video>
        //         <video
        //           style={{ width: '100%', height: '100%' }}
        //           controls
        //           autostart
        //           autoPlay
        //           src='/Videos/Office - 80314.mp4'
        //           type='video/mp4'
        //         />
      )}
    </div>
  );
};

export default TopHeadingImageOwner;
