import Cookies from 'js-cookie'

const languageCode = Cookies.get('i18next')

function selectedCountry(value) {
  // code postal
  if (languageCode === 'en' && value === 'Algeria') return 'Algeria'
  if (languageCode === 'fr' && value === 'Algeria') return 'Algérie'
  if (languageCode === 'en' && value === 'Angola') return 'Angola'
  if (languageCode === 'fr' && value === 'Angola') return "L'Angola"
  if (languageCode === 'en' && value === 'Benin') return 'Benin'
  if (languageCode === 'fr' && value === 'Benin') return 'Bénin'
  if (languageCode === 'en' && value === 'Botswana') return 'Botswana'
  if (languageCode === 'fr' && value === 'Botswana') return 'Botswana'
  if (languageCode === 'en' && value === 'Burkina_Faso') return 'Burkina Faso'
  if (languageCode === 'fr' && value === 'Burkina_Faso') return 'Burkina Faso'
  if (languageCode === 'en' && value === 'Burundi') return 'Burundi'
  if (languageCode === 'fr' && value === 'Burundi') return 'Burundi'
  if (languageCode === 'en' && value === 'Cameroon') return 'Cameroon'
  if (languageCode === 'fr' && value === 'Cameroon') return 'Cameroun'
  if (languageCode === 'en' && value === 'Cabo_Verde') return 'Cabo Verde'
  if (languageCode === 'fr' && value === 'Cabo_Verde') return 'Cabo Verde'
  if (languageCode === 'en' && value === 'Central_Africa_Republic')
    return 'Central Africa Republic'
  if (languageCode === 'fr' && value === 'Central_Africa_Republic')
    return 'République Centrafricaine'
  if (languageCode === 'en' && value === 'Chad') return 'Chad'
  if (languageCode === 'fr' && value === 'Chad') return 'Tchad'
  if (languageCode === 'en' && value === 'Comoros') return 'Comoros'
  if (languageCode === 'fr' && value === 'Comoros') return 'Comores'
  if (languageCode === 'en' && value === 'Congo') return 'Congo'
  if (languageCode === 'fr' && value === 'Congo') return 'Congo'
  if (languageCode === 'en' && value === 'Democratic_Republic_Of_The_Congo')
    return 'Democratic Republic Of The Congo'
  if (languageCode === 'fr' && value === 'Democratic_Republic_Of_The_Congo')
    return 'République Démocratique du Congo'
  if (languageCode === 'en' && value === 'Djibouti') return 'Djibouti'
  if (languageCode === 'fr' && value === 'Djibouti') return 'Djibouti'
  if (languageCode === 'en' && value === 'Egypt') return 'Egypt'
  if (languageCode === 'fr' && value === 'Egypt') return 'Egypte'
  if (languageCode === 'en' && value === 'Equatorial_Guinea')
    return 'Equatorial Guinea'
  if (languageCode === 'fr' && value === 'Equatorial_Guinea')
    return 'Guinée Équatoriale'
  if (languageCode === 'en' && value === 'Eritrea') return 'Eritrea'
  if (languageCode === 'fr' && value === 'Eritrea') return 'Érythrée'
  if (languageCode === 'en' && value === 'Ethiopia') return 'Ethiopia'
  if (languageCode === 'fr' && value === 'Ethiopia') return 'Ethiopie'
  if (languageCode === 'en' && value === 'French_Southern_territory')
    return 'French Southern territory'
  if (languageCode === 'fr' && value === 'French_Southern_territory')
    return 'Territoires du Sud français'
  if (languageCode === 'en' && value === 'Gabon') return 'Gabon'
  if (languageCode === 'fr' && value === 'Gabon') return 'Gabon'
  if (languageCode === 'en' && value === 'Gambia') return 'Gambia'
  if (languageCode === 'fr' && value === 'Gambia') return 'Gambie'
  if (languageCode === 'en' && value === 'Ghana') return 'Ghana'
  if (languageCode === 'fr' && value === 'Ghana') return 'Ghana'
  if (languageCode === 'en' && value === 'Guinea') return 'Guinea'
  if (languageCode === 'fr' && value === 'Guinea') return 'Guinée'
  if (languageCode === 'en' && value === 'Guinea_Bissau') return 'Guinea Bissau'
  if (languageCode === 'fr' && value === 'Guinea_Bissau') return 'Guinée Bissau'
  if (languageCode === 'en' && value === 'Cote_DIvoire') return "Cote d'Ivoire"
  if (languageCode === 'fr' && value === 'Cote_DIvoire') return "Côte d'Ivoire"
  if (languageCode === 'en' && value === 'Kenya') return 'Kenya'
  if (languageCode === 'fr' && value === 'Kenya') return 'Kenya'
  if (languageCode === 'en' && value === 'Lesotho') return 'Lesotho'
  if (languageCode === 'fr' && value === 'Lesotho') return 'Lesotho'
  if (languageCode === 'en' && value === 'Liberia') return 'Liberia'
  if (languageCode === 'fr' && value === 'Liberia') return 'Liberia'
  if (languageCode === 'en' && value === 'Libya') return 'Libya'
  if (languageCode === 'fr' && value === 'Libya') return 'Libye'
  if (languageCode === 'en' && value === 'Madagascar') return 'Madagascar'
  if (languageCode === 'fr' && value === 'Madagascar') return 'Madagascar'
  if (languageCode === 'en' && value === 'Malawi') return 'Malawi'
  if (languageCode === 'fr' && value === 'Malawi') return 'Malawi'
  if (languageCode === 'en' && value === 'Mali') return 'Mali'
  if (languageCode === 'fr' && value === 'Mali') return 'Mali'
  if (languageCode === 'en' && value === 'Mauritania') return 'Mauritania'
  if (languageCode === 'fr' && value === 'Mauritania') return 'Mauritanie'
  if (languageCode === 'en' && value === 'Mauritius') return 'Mauritius'
  if (languageCode === 'fr' && value === 'Mauritius') return 'Maurice'
  if (languageCode === 'en' && value === 'Mayotte') return 'Mayotte'
  if (languageCode === 'fr' && value === 'Mayotte') return 'Mayotte'
  if (languageCode === 'en' && value === 'Morocco') return 'Morocco'
  if (languageCode === 'fr' && value === 'Morocco') return 'Maroc'
  if (languageCode === 'en' && value === 'Mozambique') return 'Mozambique'
  if (languageCode === 'fr' && value === 'Mozambique') return 'Mozambique'
  if (languageCode === 'en' && value === 'Namibia') return 'Namibia'
  if (languageCode === 'fr' && value === 'Namibia') return 'Namibie'
  if (languageCode === 'en' && value === 'Niger') return 'Niger'
  if (languageCode === 'fr' && value === 'Niger') return 'Niger'
  if (languageCode === 'en' && value === 'Nigeria') return 'Nigeria'
  if (languageCode === 'fr' && value === 'Nigeria') return 'Nigeria'
  if (languageCode === 'en' && value === 'Reunion') return 'Reunion'
  if (languageCode === 'fr' && value === 'Reunion') return 'Réunion'
  if (languageCode === 'en' && value === 'Rwanda') return 'Rwanda'
  if (languageCode === 'fr' && value === 'Rwanda') return 'Rwanda'
  if (languageCode === 'en' && value === 'Saint_Helana') return 'Saint Helana'
  if (languageCode === 'fr' && value === 'Saint_Helana') return 'Sainte-Hélène'
  if (languageCode === 'en' && value === 'Sao_Tome_And_Principe')
    return 'Sao Tome And Principe'
  if (languageCode === 'fr' && value === 'Sao_Tome_And_Principe')
    return 'Sao Tomé et Principe'
  if (languageCode === 'en' && value === 'Senegal') return 'Senegal'
  if (languageCode === 'fr' && value === 'Senegal') return 'Sénégal'
  if (languageCode === 'en' && value === 'SeyChelles') return 'SeyChelles'
  if (languageCode === 'fr' && value === 'SeyChelles') return 'Les Seychelles'
  if (languageCode === 'en' && value === 'Sierra_leon') return 'Sierra leon'
  if (languageCode === 'fr' && value === 'Sierra_leon') return 'Sierra Leone'
  if (languageCode === 'en' && value === 'Somalia') return 'Somalia'
  if (languageCode === 'fr' && value === 'Somalia') return 'Somalie'
  if (languageCode === 'en' && value === 'South_Africa') return 'South Africa'
  if (languageCode === 'fr' && value === 'South_Africa') return 'Afrique du Sud'
  if (languageCode === 'en' && value === 'South_Sudan') return 'South Sudan'
  if (languageCode === 'fr' && value === 'South_Sudan') return 'Soudan du sud'
  if (languageCode === 'en' && value === 'Sudan') return 'Sudan'
  if (languageCode === 'fr' && value === 'Sudan') return 'Soudan'
  if (languageCode === 'en' && value === 'Swaziland') return 'Swaziland'
  if (languageCode === 'fr' && value === 'Swaziland') return 'Swaziland'
  if (languageCode === 'en' && value === 'Tanzania') return 'Tanzania'
  if (languageCode === 'fr' && value === 'Tanzania') return 'Tanzanie'
  if (languageCode === 'en' && value === 'Togo') return 'Togo'
  if (languageCode === 'fr' && value === 'Togo') return 'Togo'
  if (languageCode === 'en' && value === 'Tunisia') return 'Tunisia'
  if (languageCode === 'fr' && value === 'Tunisia') return 'Tunisie'
  if (languageCode === 'en' && value === 'Uganda') return 'Uganda'
  if (languageCode === 'fr' && value === 'Uganda') return 'Ouganda'
  if (languageCode === 'en' && value === 'Western_Sahara')
    return 'Western Sahara'
  if (languageCode === 'fr' && value === 'Western_Sahara')
    return 'Sahara occidental'
  if (languageCode === 'en' && value === 'Zambia') return 'Zambia'
  if (languageCode === 'fr' && value === 'Zambia') return 'Zambie'
  if (languageCode === 'en' && value === 'Zimbabwe') return 'Zimbabwe'
  if (languageCode === 'fr' && value === 'Zimbabwe') return 'Zimbabwe'

  if (languageCode === 'en' && value === 'Apartment') return 'Apartment'
  if (languageCode === 'fr' && value === 'Apartment') return 'Apartement'
  if (languageCode === 'en' && value === 'Single Room') return 'Single Room'
  if (languageCode === 'fr' && value === 'Single Room') return 'Chambre simple'
  if (languageCode === 'en' && value === 'Office') return 'Office'
  if (languageCode === 'fr' && value === 'Office') return 'Bureau'
  if (languageCode === 'en' && value === 'Store/Shop') return 'Store/Shop'
  if (languageCode === 'fr' && value === 'Store/Shop') return 'Boutique'
  if (languageCode === 'en' && value === 'Land') return 'Land'
  if (languageCode === 'fr' && value === 'Land') return 'Terre'
  if (languageCode === 'en' && value === 'Condos') return 'Condos'
  if (languageCode === 'fr' && value === 'Condos') return 'Condos'

  if (languageCode === 'en' && value === 'None') return 'None'
  if (languageCode === 'fr' && value === 'None') return 'Rien'
  if (
    languageCode === 'en' &&
    value === 'Garage-Pool-Flowers-Garden-Playground'
  )
    return 'Garage-Pool-Flowers-Garden-Playground'
  if (
    languageCode === 'fr' &&
    value === 'Garage-Pool-Flowers-Garden-Playground'
  )
    return 'Garage-Piscine-Fleurs-Jardin-Aire de Jeux'
  if (languageCode === 'en' && value === 'Garage-Pool-Flowers-Garden')
    return 'Garage-Pool-Flowers-Garden'
  if (languageCode === 'fr' && value === 'Garage-Pool-Flowers-Garden')
    return 'Garage-Piscine-Fleurs-Jardin'
  if (languageCode === 'en' && value === 'Pool-Flowers-Garden-Playground')
    return 'Pool-Flowers-Garden-Playground'
  if (languageCode === 'fr' && value === 'Pool-Flowers-Garden-Playground')
    return 'Piscine-Fleurs-Jardin-Aire de Jeux'
  if (languageCode === 'en' && value === 'Flowers-Garden-Playground')
    return 'Flowers-Garden-Playground'
  if (languageCode === 'fr' && value === 'Flowers-Garden-Playground')
    return 'Fleurs-Jardin-Aire de Jeux'
  if (languageCode === 'en' && value === 'Garage-Pool-Flowers')
    return 'Garage-Pool-Flowers'
  if (languageCode === 'fr' && value === 'Garage-Pool-Flowers')
    return 'Garage-Piscine-Fleurs'
  if (languageCode === 'en' && value === 'Garden-Playground')
    return 'Garden-Playground'
  if (languageCode === 'fr' && value === 'Garden-Playground')
    return 'Jardin-Aire de jeux'
  if (languageCode === 'en' && value === 'Pool-Flowers') return 'Pool-Flowers'
  if (languageCode === 'fr' && value === 'Pool-Flowers') return 'Piscine-Fleurs'
  if (languageCode === 'en' && value === 'Garage-Playground')
    return 'Garage-Playground'
  if (languageCode === 'fr' && value === 'Garage-Playground')
    return 'Garage-Cour de récréation'
  if (languageCode === 'en' && value === 'Garage-Pool') return 'Garage-Pool'
  if (languageCode === 'fr' && value === 'Garage-Pool') return 'Garage-Piscine'
  if (languageCode === 'en' && value === 'Garage-Garden') return 'Garage-Garden'
  if (languageCode === 'fr' && value === 'Garage-Garden') return 'Garage-Jardin'
  if (languageCode === 'en' && value === 'Garage-Flowers')
    return 'Garage-Flowers'
  if (languageCode === 'fr' && value === 'Garage-Flowers')
    return 'Garage-Fleurs'
  if (languageCode === 'en' && value === 'Pool-Garden') return 'Pool-Garden'
  if (languageCode === 'fr' && value === 'Pool-Garden') return 'Piscine-Jardin'
  if (languageCode === 'en' && value === 'Pool-Playground')
    return 'Pool-Playground'
  if (languageCode === 'fr' && value === 'Pool-Playground')
    return 'Piscine-Aire de jeux'
  if (languageCode === 'en' && value === 'Flowers-Garden')
    return 'Flowers-Garden'
  if (languageCode === 'fr' && value === 'Flowers-Garden')
    return 'Fleurs-Jardin'
  if (languageCode === 'en' && value === 'Flower-Playground')
    return 'Flower-Playground'
  if (languageCode === 'fr' && value === 'Flower-Playground')
    return 'Fleur-Aire de jeux'
  if (languageCode === 'en' && value === 'Garage') return 'Garage'
  if (languageCode === 'fr' && value === 'Garage') return 'Garage'
  if (languageCode === 'en' && value === 'Pool') return 'Pool'
  if (languageCode === 'fr' && value === 'Pool') return 'Piscine'
  if (languageCode === 'en' && value === 'Flowers') return 'Flowers'
  if (languageCode === 'fr' && value === 'Flowers') return 'Fleur'
  if (languageCode === 'en' && value === 'Garden') return 'Garden'
  if (languageCode === 'fr' && value === 'Garden') return 'Jardin'
  if (languageCode === 'en' && value === 'Playground') return 'Playground'
  if (languageCode === 'fr' && value === 'Playground') return 'Aire de jeux'

  if (languageCode === 'en' && value === 'Rental') return 'Rental'
  if (languageCode === 'fr' && value === 'Rental') return 'De Location'
  if (languageCode === 'en' && value === 'Sale') return 'Sale'
  if (languageCode === 'fr' && value === 'Sale') return 'Vente'

  if (languageCode === 'en' && value === 'I am the Owner')
    return 'I am the Owner'
  if (languageCode === 'fr' && value === 'I am the Owner')
    return 'Je suis le propriétaire'
  if (languageCode === 'en' && value === 'I am an Agent / Broker')
    return 'I am an Agent / Broker'
  if (languageCode === 'fr' && value === 'I am an Agent / Broker')
    return 'Je suis un agent / courtier'
  if (languageCode === 'en' && value === 'I am a Property Manager')
    return 'I am a Property Manager'
  if (languageCode === 'fr' && value === 'I am a Property Manager')
    return 'Je suis un Property Manager'
  if (languageCode === 'fr' && value === 'Country Code') return 'Code Postal'

  const role = [, ,]

  return value
}

export default selectedCountry
