import React, { useEffect } from "react";

import "../components/AddListing/addListing.css";
import Meta from "./../Utils/Meta";

const NotFoundScreen = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{
        font: "normal normal normal 20px/25px Merriweather",
      }}
      className="notFoundOuterCon"
    >
      <Meta title={`Nawafrica | not found`} />
      <div style={{ borderRadius: "5px" }} className="alert  my-5">
        <h3>Not Found</h3>
      </div>
    </div>
  );
};

export default NotFoundScreen;
