import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeftLong,
  faPlus,
  faMinus,
  faDoorOpen,
} from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

import ArticleInput from '../components/RentAndSaleArticlePost/ArticleInput'
import ArticleText from '../components/RentAndSaleArticlePost/ArticleText'
import ArticleSelect from '../components/RentAndSaleArticlePost/ArticleSelect'
import AddStoryFilesInput from '../components/BlogAddStory/AddStoryFilesInput'
import ReactTooltip from 'react-tooltip'
import { getCloudinarySignature } from '../Services/cloudinaryService'
import { uploadSingleFileToCloudinary } from '../Utils/imageUploadToCloudinary'
import {
  addRentPlatfrom,
  getRentPlatfromById,
  editRentPlatfrom,
} from '../Services/rentPlatformService'
//import AddStoryModal from '../components/BlogAddStory/AddStoryModal'
import EditParagraphModal from '../components/RentAndSaleArticlePost/EditParagraphModal'
import NewsLoading from '../components/Newsroom/NewsLoading'
//import LottieLoader from '../components/contactCaptivator/LottieLoader'
import ProfileSpinner from '../components/profile/ProfileSpinner'

const RentAndSaleArticlePost = ({ history, match }) => {
  const { t } = useTranslation()
  const [title, setTitle] = useState('')
  const [subject, setSubject] = useState('')
  const [allSubTitle, setAllSubtitle] = useState([''])
  const [allParagraph, setAllParagraphs] = useState([])
  const [subTopicPara, setSubTopicPara] = useState([
    {
      key: '1',
      subtitle: '',
      paragraph: (
        <ArticleText
          placeholder={`Create an Article for this paragraph`}
          id='FirstPara'
          title='First Paragraph'
          onChange={(e) => setAllParagraphs([e.target.value])}
        />
      ),
    },
  ])
  const [loading, setLoading] = useState(false)
  const [loadings, setLoadings] = useState(false)

  const [nums, setNum] = useState(1)
  const [error, setError] = useState('')
  const [myImageData, setMyImageData] = useState('')
  const [imageTitle, setImageTitle] = useState('')
  const [imageLoad, setImageLoad] = useState(false)
  const [imageError, setImageError] = useState('')
  const [imageResult, setImageResult] = useState()
  const [progress, setProgress] = useState(0)
  const [showLoader, setShowLoader] = useState(false)
  const [myParaContent, setMyParaContent] = useState([])
  const [open, setOpen] = useState(true)
  const [ID, setID] = useState()
  const [createDate, setCreateDate] = useState()

  const items = [
    'Resources',
    'Applications',
    'Renovation',
    'Collecting Rental',
    'Maintenance & Repairs',
    'Listing Your Rental',
    'Rent Payment',
    'Hygene',
    'Pets',
    'Taxes',
    'Legal',
    'Lease',
    'Insurance',
    'Complaints',
    'Investing',
  ]

  useEffect(() => {
    getData()
    window.scrollTo(0, 0)
  }, [])

  const getData = async () => {
    setLoadings(true)
    const { data: imagesData } = await getCloudinarySignature()
    setMyImageData(imagesData)

    const ID = match.params.id

    if (ID) {
      setID(ID)
      const { data } = await getRentPlatfromById(ID)
      setTitle(data.title)
      setSubject(data.subject)
      setImageResult(data.image)
      setImageTitle(data.image.original_filename)
      setMyParaContent(data.paragraphs)
      setCreateDate(data.create_date)
    }
    setLoadings(false)
  }

  const onChangeUSL = async (e) => {
    e.preventDefault()
    const filesss = e.currentTarget.files[0]
    if (filesss) {
      setImageTitle(filesss.name)
    }

    if (filesss && filesss.size > 8323863) {
      setImageError(t('large_file,_upload'))
    } else {
      setImageLoad(true)
      const result = await uploadSingleFileToCloudinary(filesss, myImageData)
      setImageResult(result)
      setImageLoad(false)
    }
  }

  const addParagraphs = () => {
    let num = nums
    num = num + 1
    let paraObj = {
      key: `${num}`,
      subtitle: (
        <ArticleInput
          id={`${num}`}
          placeholder={`Add Sub title ${num}`}
          title={`Sub title ${num}`}
          width='100%'
          onChange={(e) => setAllSubtitle([...allSubTitle, e.target.value])}
        />
      ),
      paragraph: (
        <ArticleText
          placeholder={`Create an Article for Paragraph ${num}`}
          id={`para ${num}`}
          title={`${num} Paragraph`}
          onChange={(e) => setAllParagraphs([...allParagraph, e.target.value])}
        />
      ),
    }
    const para = [...subTopicPara]
    para.push(paraObj)
    setSubTopicPara(para)
    // setAddParagraph(para)
    setNum(num)
  }

  const removeParagraphs = () => {
    if (subTopicPara.length > 1) {
      const addPara = [...subTopicPara]
      addPara.pop()
      const allPara = [...allParagraph]
      const subPara = [...allSubTitle]
      allPara.pop()
      subPara.pop()
      let num = nums - 1
      setNum(num)
      setSubTopicPara(addPara)
      setAllParagraphs(allPara)
      setAllSubtitle(subPara)
    }
  }

  const onPublush = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      let obj
      let paraArray = []

      for (let i = 0; i < allParagraph.length; i++) {
        obj = {
          subTitle: allSubTitle[i],
          Para: allParagraph[i],
        }

        paraArray.push(obj)
      }

      const datas = {
        title,
        subject,
        image: imageResult,
        paras: paraArray,
      }

      if (ID) {
        const datass = {
          title,
          subject,
          image: imageResult,
          paras: paraArray,
          createDate,
        }
        await editRentPlatfrom(datass, ID, (x) => setProgress(x * 100))
      } else {
        await addRentPlatfrom(datas, (x) => setProgress(x * 100))
      }

      setLoading(false)

      setTitle('')
      setSubject('')
      setImageResult()
      setImageTitle('')
      setAllParagraphs([])
      setAllSubtitle([''])
      setSubTopicPara([
        {
          key: '1',
          subtitle: '',
          paragraph: (
            <ArticleText
              placeholder={`Create an Article for this paragraph`}
              id='FirstPara'
              title='First Paragraph'
              onChange={(e) => setAllParagraphs([e.target.value])}
            />
          ),
        },
      ])
      history.push('/rent-and-sale-platform')
      // logger.log('here is the array==', paraArray)
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        // setLoadingProgress('')
        setProgress(0)
        setError(ex.response.data)
      }
    }
  }

  const onCloseModal = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  if (loadings)
    return (
      <div className='homeSpinnerContainer'>
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    )

  return (
    <>
      {loading && <NewsLoading loadingProgress={progress} />}

      <div
        style={{ backgroundImage: 'url(../images/publish.jpg)' }}
        className='rentSalePublishCon'
      >
        <div className='rentSaleAddBack'>
          {ID && (
            <button onClick={onCloseModal} className='btn btn-light'>
              <FontAwesomeIcon
                className='rentSaleAddOpenIcon'
                icon={faDoorOpen}
              />
              open modal
            </button>
          )}
        </div>
        {ID && open && (
          <EditParagraphModal
            onCloseModal={onCloseModal}
            content={myParaContent}
          />
        )}
        <div className='rentSalingBackBtnCon'>
          <Link className='btn btn-light' to='/rent-and-sale-platform'>
            <FontAwesomeIcon
              className='rentSaleBackArrowIcon'
              icon={faArrowLeftLong}
            />
            Go back
          </Link>
        </div>
        <div className='rentSaleInputsContainer'>
          <h1>PUBLISH AN ARTICLE</h1>
          <form style={{ width: '100%' }} onSubmit={onPublush}>
            <div className='rentSaleAddInnerCon'>
              <ArticleInput
                id={'Title'}
                placeholder='Title of Article'
                title='Title'
                width='100%'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <ArticleSelect
                id='select'
                title='Select An Article subject'
                items={items}
                onChange={(e) => setSubject(e.target.value)}
                value={subject}
              />

              <AddStoryFilesInput
                width='100%'
                width2='50%'
                onChange={onChangeUSL}
                label='Press Release Image'
                id='storyImage'
                photoName={imageTitle}
                loading={imageLoad}
                error={imageError}
                uploadImage={imageResult}
              />

              {subTopicPara.map((x) => (
                <div style={{ width: '100%' }} key={x.key}>
                  {x.subtitle}
                  {x.paragraph}
                </div>
              ))}

              <p className='rentSalePublishBtnCon'>
                <button className='btn' type='submit'>
                  Publish An Article
                </button>

                {error && (
                  <p
                    style={{
                      textAlign: 'center',
                      color: 'red',
                      opacity: '0.8',
                    }}
                  >
                    {error}
                  </p>
                )}
              </p>
            </div>
          </form>
          <button
            data-tip='Add a subtitle and a body paragraph'
            className='btn btn-light'
            onClick={addParagraphs}
            style={{
              padding: '5px',
              margin: '10px 0',
            }}
            type='reset'
          >
            <FontAwesomeIcon
              style={{
                color: '#035aa6',
                opacity: '.7',
                fontSize: '30px',
              }}
              icon={faPlus}
            />
          </button>
          {/* {subTopicPara.length > 1 && ( */}
          <button
            data-tip='Remove a subtitle and a body paragraph'
            className='btn btn-light'
            type='reset'
            disabled={loading}
            onClick={removeParagraphs}
            style={{
              padding: '5px',

              margin: '10px 10px 10px 10px',
            }}
          >
            <FontAwesomeIcon
              style={{
                color: 'red',
                opacity: '0.7',
                fontSize: '30px',
                cursor: 'pointer',
              }}
              icon={faMinus}
            />
          </button>
          {/* )} */}
          <ReactTooltip effect='solid' />
        </div>
      </div>
    </>
  )
}

export default RentAndSaleArticlePost
