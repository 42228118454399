import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./aboutUs.css";

const AllLinks = ({
  aboutActive = false,
  impactActive = false,
  crewActive = false,
  a,
  b,
  c,
}) => {
  return (
    <div className="allLinkContainer">
      <div>
        <Link onClick={a} className={aboutActive ? "a1" : "a"} to="/about-us">
          {" "}
          About nawafrica.com
        </Link>
        {aboutActive && <div className="aboutActiveUnderLine" />}
      </div>
      <div>
        <Link onClick={b} className={impactActive ? "b1" : "b"} to="/ownership">
          {" "}
          Ownership
          {impactActive && <div className="impactActiveUnderLine" />}
        </Link>
      </div>
      <div>
        <Link onClick={c} className={crewActive ? "c1" : "c"} to="/crew">
          {" "}
          Crew
          {crewActive && <div className="crewUnderLine" />}
        </Link>
      </div>
    </div>
  );
};

export default AllLinks;
