import React from "react";
import { useTranslation } from "react-i18next";

import SearchMapListingDet from "./SearchMapListingDet";
import SearchListingDisplayDetails from "./SearchListingDisplayDetails";
import SearchListingDisplayDetailSmall from "./SearchListingDisplayDetailSmall";

const OtherListingInCity = ({
  listings,
  listingInCitySmall,
  listingInCityDisplay,
  onMapLoad,
  keys,
  theSelected,
  onHoverListing,
  listingFromMap,
  onClickSelectedListing,
  onClickWindow,
  onPressImage,
  onClickSelectedListingImage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="otherListTitle">
        <h3>
          {t("other_listing_in_city_title", {
            number_of_listings: listings.length,
            s: listings.length > 1 ? "s" : "",
            city: listings[0].city,
            country: listings[0].country,
          })}
        </h3>
      </div>
      <div className="otherListMapListContainer">
        <div className="otherListMapContainer">
          <SearchMapListingDet
            listings={listings}
            onMapLoad={onMapLoad}
            keys={keys}
            theSelected={theSelected}
            onClickWindow={onClickWindow}
            onPressImage={onPressImage}
          />
        </div>

        <hr className="otherListSeparator" />

        <div
          style={{
            background: "#d3d3d3",
            paddingTop: "10px",
          }}
          className="otherListDisplayList"
        >
          <SearchListingDisplayDetails
            listings={listingInCityDisplay}
            listingFromMap={listingFromMap}
            onClickSelectedListing={onClickSelectedListing}
            onHoverListing={onHoverListing}
            theSelected={theSelected}
            onClickSelectedListingImage={onClickSelectedListingImage}
          />
        </div>

        <div
          style={{ background: "#d3d3d3", paddingTop: "20px" }}
          className="otherListDisplayListSmall"
        >
          <SearchListingDisplayDetailSmall
            listings={listingInCitySmall}
            listingFromMap={listingFromMap}
            onClickSelectedListing={onClickSelectedListing}
            onHoverListing={onHoverListing}
            onClickSelectedListingImage={onClickSelectedListingImage}
          />
        </div>
      </div>
    </>
  );
};

export default OtherListingInCity;
