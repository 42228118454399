import React from "react";
import { useTranslation } from "react-i18next";
import { PayPalButton } from "react-paypal-button-v2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCardAlt } from "@fortawesome/free-solid-svg-icons";
import { Box } from "@mui/material";

import Paid from "./Paid";
import { currencyCodes2 } from "../../Utils/currencyCodes";
import LeaseLoader from "./leaseLoader";
import LoadingSpinner from "../Applications/LoadingSpinner";
import ConfirmPayPassword from "./ConfirmPayPassword";
import PriceAndQuantity from "./PriceAndQuantity";
// import CheckoutForm from './CheckoutForm'

const MakePaymentModal = ({
  confirmLoading,
  listing,
  tenantPaymentMethods,
  onselectPaymentMethod,
  selectedPayment,
  onSubmitPayment,
  onChangeAmount,

  valueAmount,
  loadingPayment,
  paid,
  onClickPaid,
  error,
  loadingConversion,
  sdkReady,
  paypalAmount,
  onSuccess,
  paypalUSDListingConv,
  // paypalClientId,
  confirmPassword,
  onClickConfirmPayment,
  onClickConfirmPayment1,
  onChangeConfirmPasswordData,
  confirmPasswordPay,
  onChangeQuantity,
  quantity = 1,
}) => {
  console.log("Error=====", error);
  const { t } = useTranslation();

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }
  function getCurrency() {
    const countryObj = currencyCodes2().filter(
      (x) =>
        x.ctry === selectedPayment.payment_credential.country ||
        x.ctry === listing.country
    )[0];

    return countryObj ? countryObj : listing.currency;
  }

  console.log("Amount=====", valueAmount, paypalUSDListingConv);

  return (
    <div
      className="modal fade"
      id="exampleModalMakePayment"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div
          style={{ boxShadow: "3px 1px 3px 0.5px black" }}
          className="modal-content"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {confirmPassword
                ? "Confirm Payment"
                : paid
                ? t("Paid")
                : t("Payments_For", {
                    citeName:
                      listing.title &&
                      listing.title.replace(/\w\S*/g, (w) =>
                        w.replace(/^\w/, (c) => c.toUpperCase())
                      ),
                  })}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          {confirmPassword ? (
            <ConfirmPayPassword
              error={error}
              onChange={onChangeConfirmPasswordData}
              onClick={onClickConfirmPayment}
              onClickPaypal={onClickConfirmPayment1}
              confirmLoading={confirmLoading}
              option={selectedPayment && selectedPayment.payment_option}
            />
          ) : paid ? (
            <Paid onClickPaid={onClickPaid} paid={paid} />
          ) : loadingPayment ? (
            <LeaseLoader />
          ) : tenantPaymentMethods && tenantPaymentMethods.length == 0 ? (
            <div className="alert alert-info alertInfoForNoPaymentMethodAvailable">
              {t("No_Payment_Method_Available")},{" "}
              <a className="btn btn-light " href="/portal/tenant/leases">
                {t("Please_Add_A_Payment_Method")}
              </a>
            </div>
          ) : (
            <div className="modal-body">
              {tenantPaymentMethods &&
                tenantPaymentMethods.map((payment) => (
                  <div key={payment.id}>
                    {payment.payment_credential.momo_number ? (
                      <button
                        onClick={() => onselectPaymentMethod(payment)}
                        className={
                          payment.id === selectedPayment.id
                            ? "makePaymentActiveBtnLeaseSignDet btn"
                            : "makePaymentActiveBtnLeaseSignDet1 btn"
                        }
                        type="submit"
                      >
                        <div className="imageContainer">
                          <img
                            style={{ objectFit: "cover" }}
                            src="/images/mtn.png"
                          />
                        </div>
                        <div
                          style={{
                            flex: "1",
                            textAlign: "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            lineClamp: 2,
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {t(`Pay With`, {
                            with: payment.payment_credential.momo_number,
                          }).length > 29
                            ? t(`Pay With`, {
                                with: payment.payment_credential.momo_number,
                              }).slice(0, 27) + ".."
                            : t(`Pay With`, {
                                with: payment.payment_credential.momo_number,
                              })}
                        </div>
                      </button>
                    ) : payment.payment_credential.paypal_account ? (
                      <button
                        onClick={() => onselectPaymentMethod(payment)}
                        className={
                          payment.id === selectedPayment.id
                            ? "makePaymentActiveBtnLeaseSignDet btn"
                            : "makePaymentActiveBtnLeaseSignDet1 btn"
                        }
                        type="submit"
                      >
                        <div className="imageContainer">
                          <img
                            style={{ objectFit: "cover", borderRadius: "20px" }}
                            src="/images/paypal.jpg"
                          />
                        </div>
                        <div
                          style={{
                            flex: "1",
                            textAlign: "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            lineClamp: 2,
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {t(`Pay With`, {
                            with: payment.payment_credential.paypal_account,
                          }).length > 29
                            ? t(`Pay With`, {
                                with: payment.payment_credential.paypal_account,
                              }).slice(0, 27) + ".."
                            : t(`Pay With`, {
                                with: payment.payment_credential.paypal_account,
                              })}
                        </div>
                      </button>
                    ) : (
                      <button
                        onClick={() => onselectPaymentMethod(payment)}
                        className={
                          payment.id === selectedPayment.id
                            ? "makePaymentActiveBtnLeaseSignDet btn"
                            : "makePaymentActiveBtnLeaseSignDet1 btn"
                        }
                        type="submit"
                      >
                        <div className="imageContainer">
                          <FontAwesomeIcon
                            style={{
                              color: "#035aa6",
                              fontSize: "20px",
                            }}
                            icon={faCreditCardAlt}
                          />
                        </div>
                        <div
                          style={{
                            flex: "1",
                            textAlign: "left",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            lineClamp: 2,
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {t(`Pay With`, {
                            with: payment.payment_credential.card_number.replace(
                              /(\d{4}(?!\s))/g,
                              "$1 "
                            ),
                          }).length > 29
                            ? t(`Pay With`, {
                                with: payment.payment_credential.card_number.replace(
                                  /(\d{4}(?!\s))/g,
                                  "$1 "
                                ),
                              }).slice(0, 27) + ".."
                            : t(`Pay With`, {
                                with: payment.payment_credential.card_number.replace(
                                  /(\d{4}(?!\s))/g,
                                  "$1 "
                                ),
                              })}
                        </div>
                      </button>
                    )}
                  </div>
                ))}
              {selectedPayment && (
                <form onSubmit={onSubmitPayment}>
                  <hr className="makePaymentHr"></hr>

                  <div className="showingCommasInpayment">
                    {selectedPayment.payment_option == "Visa Card" &&
                      Number(paypalUSDListingConv) * Number(valueAmount) >
                        0 && (
                        <div
                          style={{
                            flex: "1",
                            display: "flex",
                            justifyContent: "flex-end",
                            fontSize: "12px",
                          }}
                        >
                          <span>Amount In USD : </span>
                          <span style={{ marginLeft: "5px" }}>
                            {" "}
                            ${Number(paypalAmount).toFixed(2)}{" "}
                          </span>
                        </div>
                      )}
                    {selectedPayment.payment_option == "Paypal" && (
                      <div
                        style={{
                          flex: "1",
                          display: "flex",
                          justifyContent: "flex-end",
                          fontSize: "12px",
                        }}
                      >
                        <span>Amount In USD : </span>
                        <span style={{ marginLeft: "5px" }}>
                          {" "}
                          ${Number(paypalAmount).toFixed(2)}{" "}
                        </span>
                      </div>
                    )}
                  </div>

                  <div className="makePaymentInputFields">
                    {selectedPayment.payment_option !== "Visa Card" ? (
                      <>
                        <PriceAndQuantity
                          valueAmount={valueAmount}
                          listing={listing}
                          onChangeQuantity={onChangeQuantity}
                          quantity={quantity}
                        />
                      </>
                    ) : (
                      <div className="alert alert-info visaCardIsStillUnderconstruction">
                        <Box sx={{ width: "100%", background: "white" }}>
                          <PriceAndQuantity
                            valueAmount={valueAmount}
                            listing={listing}
                            onChangeQuantity={onChangeQuantity}
                            quantity={quantity}
                          />
                        </Box>
                      </div>
                    )}
                  </div>
                  {loadingConversion ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {selectedPayment.payment_option === "MTN Mobile Money" ? (
                        <button
                          style={{ boxShadow: "3px 1px 3px 0.5px black" }}
                          type="submit"
                          className="btn makePaymentOnSubmitBtn"
                        >
                          <div className="imageContainer hereIsButtonImageCon">
                            <img
                              src={
                                selectedPayment.payment_option ===
                                "MTN Mobile Money"
                                  ? "/images/mtn.png"
                                  : "/images/paypal.jpg"
                              }
                            />
                          </div>
                          {t("Pay")}
                        </button>
                      ) : selectedPayment.payment_option == "Paypal" ? (
                        !sdkReady ? (
                          <LoadingSpinner />
                        ) : (
                          <PayPalButton
                            amount={`${Number(paypalAmount).toFixed(2)}`}
                            onSuccess={onSuccess}
                          />
                        )
                      ) : selectedPayment.payment_option == "Visa Card" ? (
                        <button
                          style={{ boxShadow: "3px 1px 3px 0.5px black" }}
                          type="submit"
                          className="btn makePaymentOnSubmitBtn"
                        >
                          <div className="imageContainer hereIsButtonImageCon">
                            <FontAwesomeIcon
                              style={{
                                color: "#035aa6",
                                fontSize: "20px",
                              }}
                              icon={faCreditCardAlt}
                            />
                          </div>
                          {t("Pay")}
                        </button>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  {error && (
                    <div
                      style={{
                        background: "red",
                        opacity: "0.7",
                        color: "white",
                        borderRadius: "5px",
                        padding: "5px",
                      }}
                      className="makePaymentError --bs-danger-border-subtle"
                    >
                      {error}
                    </div>
                  )}
                </form>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MakePaymentModal;
