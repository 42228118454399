import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const RentDetailsHead = ({ item }) => {
  const getMonth = (month) => {
    if (month == "01") return "JAN";
    if (month == "02") return "FEB";
    if (month == "03") return "MAR";
    if (month == "04") return "APR";
    if (month == "05") return "MAY";
    if (month == "06") return "JUN";
    if (month == "07") return "JUL";
    if (month == "08") return "AUG";
    if (month == "09") return "SEPT";
    if (month == "10") return "OCT";
    if (month == "11") return "NOV";
    return "DEC";
  };

  return (
    <div className="rentSaleDetOuterCon">
      <div className="rentSaleDetInnerCon">
        <div className="rentSaleH1InnerCon">
          <h1>{item.title}</h1>
          <div className="rentSaleBackDateCon">
            <div className="rentSaleBackCon">
              <Link to="/rent-and-sale-platform">
                <FontAwesomeIcon
                  className="RentPlatformHeadIcon1 addStyles"
                  icon={faArrowLeft}
                />
                <span>Go Back</span>
              </Link>
            </div>
            <div className="rentSaleIconDate">
              <FontAwesomeIcon
                className="RentPlatformHeadIcon1"
                icon={faClockRotateLeft}
              />

              <div className="dating">
                {item.create_date &&
                  `${item.create_date.toString().slice(8, 10)} ${getMonth(
                    item.create_date.toString().slice(5, 7)
                  )} ${item.create_date.toString().slice(0, 4)}`}
              </div>
            </div>
          </div>
        </div>
        <div style={{ flex: "1" }}>
          <img
            crossorigin="anonymous"
            src={item.image && item.image.url}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
      </div>
      <div className="rentSaleBackgroundCon" />
    </div>
  );
};

export default RentDetailsHead;
