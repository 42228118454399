import React, { useState, useEffect, useContext, useRef } from "react";
import _ from "lodash";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import cookies from "js-cookie";
import io from "socket.io-client";
import { useTranslation } from "react-i18next";

import SidebarTenant from "../components/AddListing/SideBarTenant";
import OpenButtonTenant from "../components/AddListing/OpenButtonTenant";
import Chats from "../components/AddListing/Chats";
import WelcomeUser from "../components/AddListing/WelcomeUser";
import ApplicationTitle from "../components/Applications/ApplicationTitle";
import SearchApplicantTenant from "../components/Applications/SearchApplicantTenant";
import ApplicationsTableTenant from "../components/Applications/ApplicationsTableTenant";

import { getApplications } from "../Services/listings";
import { getCurrentUser } from "../Services/authService";
import ProfileSpinner from "../components/profile/ProfileSpinner";
import { registeredUsers } from "../Services/registerService";
import { deleteApplicationById } from "../Services/listings";
import { getStatus } from "../Services/applicationStatus";
import UnreadMsgsContext from "./../unreadmessages/context";
// import Meta from './../Utils/Meta'

import "../components/Applications/applications.css";
import "../components/AddListing/addListing.css";
import Meta1 from "../Utils/Meta1";

const ApplicationsTenant = () => {
  const { t } = useTranslation();

  const [path, setPath] = useState("");
  const [close, setClose] = useState(true);

  const [applications, setApplications] = useState([]);
  const [allApplications, setAllApplications] = useState([]);
  const [allStatus, setAllStatus] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [fromSearch, setFromSearch] = useState(false);
  const [loadingSearchListings, setLoadingSearchListings] = useState(false);
  const [users, setUsers] = useState([]);

  const [langType, setLangType] = useState("");

  const socket = useRef(null);

  const { unreadMsgs } = useContext(UnreadMsgsContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    socket.current = io();
    localStorage.setItem("portalPath", "/portal/tenant/applications");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    getAllApplicants();
    return () => {
      localStorage.setItem("portalPath", "");
    };
  }, []);

  //function to get all datas for a giveb tenants application
  const getAllApplicants = async () => {
    if (getCurrentUser()) {
      try {
        setLangType(cookies.get("i18next") || "en");
        setLoading(true);
        const { data } = await getApplications();
        const myAppl = data.filter(
          (d) =>
            d.applicant_id === getCurrentUser().id && d.tenant_delete !== true
        );
        const sortedListing = _.orderBy(
          myAppl,
          [sortColumn.path],
          [sortColumn.order]
        );
        setApplications(sortedListing);
        setAllApplications(data);

        const { data: allUSers } = await registeredUsers();
        setUsers(allUSers);

        const { data: allStatus } = await getStatus();
        setAllStatus(allStatus);

        setLoading(false);
      } catch (ex) {
        if (
          (ex.response && ex.response.status === 400) ||
          (ex.response && ex.response.status === 500)
        ) {
          setLoading(false);
          setError(ex.response.data);
        }
      }
    }
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to upon the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to search an application
  const onSearchApplicant = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTable(value, applications);
      setApplications(tableData);
      setFromSearch(true);
    } else {
      try {
        setLoadingSearchListings(true);
        const { data: appl } = await getApplications();
        const myAppl = appl.filter(
          (d) => d.applicant_id === getCurrentUser().id
        );
        setApplications(myAppl);
        setLoadingSearchListings(false);
      } catch (error) {
        logger.log("error from onSearchApplicant function", error);
      }
    }
  };

  const searchTable = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].listing_name.toLowerCase();

      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //function to delete an application
  const onDeleteApplication = async (appli) => {
    if (
      window.confirm(t("Are you sure you want to delete this Applications ?"))
    ) {
      try {
        const myApplications = [...applications];
        const otherApplications = myApplications.filter(
          (appl) => appl.id !== appli.id
        );
        setFromSearch(false);
        setApplications(otherApplications);

        const myAllApplications = [...allApplications];
        const otherAllApplications = myAllApplications.filter(
          (appl) => appl.id !== appli.id
        );
        setAllApplications(otherAllApplications);

        await deleteApplicationById(appli.id);

        // await deleteAllStatus(appli)

        // socket.current.emit('deleteApplicationTenant', appli)
      } catch (ex) {
        logger.log("error from onDeleteApplication function", ex.response);
      }
    }
  };

  //sorting the application
  const onSort = (e) => {
    e.preventDefault();
    const isListings = [...applications];
    const { value } = e.currentTarget;
    const column = { ...sortColumn };
    column.path = value;
    const sortedListing = _.orderBy(isListings, [column.path], [column.order]);
    setApplications(sortedListing);
  };

  //function use to capitalise the first letters of a word
  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );
  if (error)
    return (
      <div className="homeSpinnerContainer">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  return (
    <>
      <Meta1
        title={`${langType}.Nawafrica | ${t("meta_application", {
          name: getCurrentUser().first_name,
        })}`}
        description="Show all Listings that I have Applied in Nawafrica"
        keywords="All Listing Apply Nawfrica"
        link="/portal/tenant/applications"
      />

      {close && (
        <SidebarTenant
          onClickCloseBar={onClickCloseBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButtonTenant
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <div className="applicationsScreenCon">
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        <WelcomeUser
          user={capitalize(getCurrentUser() && getCurrentUser().first_name)}
        />

        <ApplicationTitle
          appl={applications.length > 99 ? "99+" : applications.length}
        />

        <div
          className={
            close ? "applicationScreenTableCon" : "applicationScreenTableCon1"
          }
        >
          <SearchApplicantTenant
            onChangeSearch={onSearchApplicant}
            onChangeSort={onSort}
          />

          {applications.length > 0 ? (
            <ApplicationsTableTenant
              allStatus={allStatus}
              applicants={applications}
              fromSearch={fromSearch}
              loadingSearchListings={loadingSearchListings}
              users={users}
              allApplications={allApplications}
              onDeleteApplication={onDeleteApplication}
            />
          ) : loadingSearchListings ? (
            <div>.....loading</div>
          ) : (
            <div className="noListingAvailableYetCon">
              <div className="alert">No Application Available Yet</div>
            </div>
          )}
        </div>
      </div>
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default ApplicationsTenant;
