import React from 'react'

const PoliceNextSubHeading = () => {
  return (
    <>
      <div style={{ fontWeight: 'bold', fontSize: '19px' }}>
        Information on opting out of interest-based advertising
      </div>
      <p style={{ fontSize: '15px', marginTop: '10px' }}>
        In addition to any opt-out feature provided by any of the services
        listed in this document, Users may learn more on how to generally opt
        out of interest-based advertising within the dedicated section of the
        Cookie Policy.
      </p>
    </>
  )
}

export default PoliceNextSubHeading
