import React, { useState, useEffect, useRef, useContext } from "react";
import _ from "lodash";
import io from "socket.io-client";
import jsPDF from "jspdf";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import Sidebar from "../components/AddListing/Sidebar";
import OpenButton from "../components/AddListing/OpenButton";
import Chats from "../components/AddListing/Chats";
import WelcomeUser from "../components/AddListing/WelcomeUser";
import ApplicationTitle from "../components/Applications/ApplicationTitle";
import SearchApplicant from "../components/Applications/SearchApplicant";
import ApplicationsTable from "../components/Applications/ApplicationsTable";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import ApplicationActionModal from "../components/Applications/ApplicationActionModal";
import DownLoadHtmlPDF from "../Utils/DownLoadHtmlPDF";
import { myDate } from "../Utils/currentDate";

import "../components/AddListing/addListing.css";
import ProfileSpinner from "../components/profile/ProfileSpinner";
import UnreadMsgsContext from "./../unreadmessages/context";

import {
  clientGetAvailableSpaces,
  deleteApplicationByIdTotally,
  getApplications,
  getListings,
  getListingsById,
} from "../Services/listings";
import { getCurrentUser } from "../Services/authService";
// import Meta from './../Utils/Meta'
import { trackEvent } from "../Utils/useGAEventTracker";
import { registeredUsers, getUserById } from "../Services/registerService";
import {
  getStatus,
  deleteAStatusTotally,
  getStatusByApplicantsId,
} from "../Services/applicationStatus";
import { uploadMultipleFileToCloudinary } from "../Utils/imageUploadToCloudinary";
import { getCloudinarySignature } from "../Services/cloudinaryService";

import { createYourPDF } from "./../Services/myCreatePDF";
import "../components/Applications/applications.css";
import "../components/AddListing/addListing.css";
import DeleteOptions from "../components/Applications/DeleteOptions";
import CustomAlert from "../components/Applications/CustomAlert";
import TenantPreviousLeases from "../components/Applications/TenantPreviousLeases";
import Meta1 from "../Utils/Meta1";
import ApplicationActionModal1 from "../components/Applications/ApplicationActionModal1";

const Applications = ({ history }) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const [path, setPath] = useState("");
  const [close, setClose] = useState(true);
  const [upSideBar, setUpSideBar] = useState(false);

  const [applications, setApplications] = useState([]);
  const [applicationSelected, setApplicationSelected] = useState({});
  const [applicationStatus, setApplicationStatus] = useState("Recieved");
  const [selectedApplicants, setSelectedApplicants] = useState({});
  const [selectedListing, setSelectedListing] = useState({});
  const [selectedLandlord, setSelectedLandlord] = useState({});

  const [allStatus, setAllStatus] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "asc",
  });
  const [sortColumns, setSortColumns] = useState({
    path: "dateposted",
    order: "desc",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [fromSearch, setFromSearch] = useState(false);
  const [loadingSearchListings, setLoadingSearchListings] = useState(false);

  const [users, setUsers] = useState([]);

  const [photoNameIdCopy, setPhotoNameIdCard] = useState("");
  const [uploadIdCard, setUploadIdCard] = useState("");
  const [loadUploadIdCard, setLoadUploadIdCard] = useState(false);
  const [errorUploadIdCard, setErrorUploadIdCard] = useState("");

  const [jobTitle, setJobTitle] = useState("");
  const [contactNumber, setContactNumber] = useState("");

  const [errorPosting, setErrorPosting] = useState("");
  const [loadingPosting, setLoadingPosting] = useState(false);

  const [otherPhotoPath, setOtherPhotoPath] = useState([]);
  const [otherPhotoError, setOtherPhotoError] = useState("");
  const [loadOtherPhoto, setLoadOtherPhoto] = useState(false);

  const [agreementStart, setAgreementStart] = useState();
  const [agreementEnd, setAgreementEnd] = useState();

  const [downloadPDF, setDownloadPDF] = useState("");
  const [nodownloadPDF, setNodownloadPDF] = useState("no Download");
  const [print, setPrint] = useState(false);
  const [myImageData, setMyImageData] = useState({});
  const [langType, setLangType] = useState("");

  const [deleteQuestion, setDeleteQuestion] = useState(false);
  const [alertUnderReview, setAlertUnderReview] = useState(false);
  const [signFinish, setSignFinish] = useState(false);
  const [canDeleteLeaseSignTotally, setCanDeleteLeaseSignTotally] =
    useState(false);

  const [tenantPrevLease, setTenantPrevLease] = useState([]);
  const [prevLeaseEnterLeave, setPrevLeaseEnterLeave] = useState("");
  const [loadPrevLease, setLoadPrevLease] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [listings, setListings] = useState([]);
  const [listing_spaces, setListing_spaces] = useState([]);
  const [hoverAppl, setHoverAppl] = useState({});

  const [leaseSignDone, setLeaseSignDone] = useState(false);

  const [updated, setUpdated] = useState(false);
  const [status, setStatus] = useState("Recieved");

  const [alertUnderReviewAlready, setAlertUnderReviewAlready] = useState(false);

  const socket = useRef(null);

  const {
    unreadMsgs,
    applications: appl20,
    setApplications: setAppl20,
  } = useContext(UnreadMsgsContext);

  useEffect(() => {
    if (
      getCurrentUser() &&
      getCurrentUser().post_rent_property === "LOOKING TO BUY/RENT PROPERTY"
    )
      return history.push("/");
    socket.current = io();
    window.scrollTo(0, 0);
    localStorage.setItem("portalPath", "/portal/application/applicants");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    getAllApplicants();

    socket.current.on(
      `${getCurrentUser() && getCurrentUser().id}TenantSignLease`,
      (data) => {
        setAllStatus(data);
      }
    );

    socket.current.on(
      `${getCurrentUser() && getCurrentUser().id}deleteApplicationTenant`,
      (data) => {
        const myAppl = [...applications];
        const undeletedAppl = myAppl.filter((appl) => appl.id !== data.id);
        setApplications(undeletedAppl);
      }
    );

    return () => {
      localStorage.setItem("portalPath", "");
    };
  }, []);

  //function to get all application data in the backend
  const getAllApplicants = async () => {
    if (getCurrentUser()) {
      setLangType(cookies.get("i18next") || "en");
      try {
        setLoading(true);
        const { data } = await getApplications();
        const myAppl = data.filter(
          (d) => d.list_owner_id === getCurrentUser().id && !d.landlord_delete
        );
        const sortedListing = _.orderBy(
          myAppl,
          [sortColumns.path],
          [sortColumns.order]
        );
        setApplications(sortedListing);
        setFromSearch(false);

        const { data: allUSers } = await registeredUsers();
        setUsers(allUSers);

        const { data: allListings } = await getListings();
        setListings(allListings);

        const { data: allStatus } = await getStatus();
        setAllStatus(allStatus);

        const { data: mydatas } = await getUserById(
          getCurrentUser() && getCurrentUser().id
        );
        setSelectedLandlord(mydatas);

        const { data: imagesData } = await getCloudinarySignature();
        setMyImageData(imagesData);

        setLoading(false);
      } catch (ex) {
        if (
          (ex.response && ex.response.status === 400) ||
          (ex.response && ex.response.status === 500)
        ) {
          setLoading(false);
          setError(ex.response.data);
        }
      }
    }
  };

  //function to close sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to open side bar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to search an applicant

  const onSearchApplicant = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTable(value, applications);
      setApplications(tableData);
      setFromSearch(true);
    } else {
      try {
        setLoadingSearchListings(true);
        const { data: appl } = await getApplications();
        const myAppl = appl.filter(
          (d) => d.list_owner_id === getCurrentUser().id && !d.landlord_delete
        );
        setApplications(myAppl);
        setLoadingSearchListings(false);
      } catch (error) {
        logger.log("onSearchApplicant function error", error);
      }
    }
  };

  const searchTable = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].appicant_name.toLowerCase();

      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //sorting the applications
  const onSort = (e) => {
    e.preventDefault();
    const isListings = [...applications];
    const { value } = e.currentTarget;
    const column = { ...sortColumn };
    column.path = value;
    const sortedListing = _.orderBy(isListings, [column.path], [column.order]);
    setApplications(sortedListing);
  };

  //function to change the applicant status
  const selectedApplicant = async (appl) => {
    setPrevLeaseEnterLeave("leave");
    try {
      setApplicationSelected(appl);
      const { data: list } = await getListingsById(appl.listing_id);
      setSelectedListing(list);
      const { data: allListing_spaces } = await clientGetAvailableSpaces(
        list.id
      );
      setListing_spaces(allListing_spaces);
      const { data: user } = await getUserById(appl.applicant_id);
      setSelectedApplicants(user);
      setOpen(true);
    } catch (error) {
      alert(error.message);
    }
  };

  ///////////////////////////////////////////
  ///////////////////////////////////////////
  //change status functions
  const onChangeStatus = (e) => {
    const { value: status } = e.target;
    if (status === "Denied") {
      setPrevLeaseEnterLeave("leave");
      setDeleteQuestion(true);
      setOpen(false);
    } else if (status === "Under Review") {
      setStatus(status);
      setPrevLeaseEnterLeave("leave");
      if (filterStatuses(applicationSelected.id).lease_signed) {
        setAlertUnderReview(true);
        setInterval(() => {
          setAlertUnderReview(false);
        }, 7000);
        return;
      }

      if (
        filterStatuses(applicationSelected.id).under_review &&
        filterStatuses(applicationSelected.id).approved === null
      ) {
        setAlertUnderReviewAlready(true);
        setInterval(() => {
          setAlertUnderReviewAlready(false);
        }, 7000);
        return;
      }

      const myObj = applicationSelected;
      myObj.status = status;
      socket.current.emit("underReview", myObj, ({ errorNoApplicant }) => {
        if (errorNoApplicant) {
          logger.log(
            "error from onApplicantStatus function socket",
            errorNoApplicant
          );
        } else {
          trackEvent(
            `${getCurrentUser() && getCurrentUser().id} sign an application`,
            `websocket application ID ${myObj.id}`,
            "sign application btn"
          );
        }
      });

      setLoadingStatus(true);
      setApplicationStatus(status);

      socket.current.on(`${getCurrentUser().id}myReview`, (data) => {
        setAllStatus(data);
        setLoadingStatus(false);
        applicationSelected.approved = null;
        setUpdated(true);

        setInterval(() => {
          setUpdated(false);
        }, 5000);
        trackEvent(
          `landlord ${
            getCurrentUser() && getCurrentUser().id
          } recieve sign lease`,
          `sign lease ${data.id}`
        );
      });
    } else if (status === "Approved") {
      setStatus(status);
      setPrevLeaseEnterLeave("leave");
      if (filterStatuses(applicationSelected.id).lease_signed) {
        setAlertUnderReview(true);
        setInterval(() => {
          setAlertUnderReview(false);
        }, 7000);
        return;
      }

      /////////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////////
      /////if the application is under review, then the landlord can approve it
      if (filterStatuses(applicationSelected.id).under_review) {
        const myObj = applicationSelected;
        myObj.status = status;
        setLoadingStatus(true);

        socket.current.emit("underReviewApproved", myObj, ({ error, data }) => {
          if (error) {
            logger.log("error from onApplicantStatus function socket", error);
          } else {
            trackEvent(
              `${getCurrentUser() && getCurrentUser().id} sign an application`,
              `websocket application ID ${myObj.id}`,
              "sign application btn"
            );
          }
        });
      } else {
        const myObj = applicationSelected;
        myObj.status = status;
        setLoadingStatus(true);

        socket.current.emit(
          "noUnderReviewApproved",
          myObj,
          ({ error, data }) => {
            if (error) {
              logger.log("error from onApplicantStatus function socket", error);
            } else {
              trackEvent(
                `${
                  getCurrentUser() && getCurrentUser().id
                } sign an application`,
                `websocket application ID ${myObj.id}`,
                "sign application btn"
              );
            }
          }
        );
      }

      setLoadingStatus(true);
      setApplicationStatus(status);

      socket.current.on(`${getCurrentUser().id}myReview`, (data) => {
        setAllStatus(data);
        setLoadingStatus(false);
        setUpdated(true);
        applicationSelected.approved = "approved";
        setInterval(() => {
          setUpdated(false);
        }, 5000);
        trackEvent(
          `landlord ${
            getCurrentUser() && getCurrentUser().id
          } recieve sign lease`,
          `sign lease ${data.id}`
        );
      });
    } else if (status === "Received") {
      setStatus(status);
      setPrevLeaseEnterLeave("leave");
      if (filterStatuses(applicationSelected.id).lease_signed) {
        setAlertUnderReview(true);
        setInterval(() => {
          setAlertUnderReview(false);
        }, 7000);
        return;
      }

      const myObj = applicationSelected;
      myObj.status = status;
      setLoadingStatus(true);

      socket.current.emit("recievedStatus", myObj, ({ error, data }) => {
        if (error) {
          logger.log("error from onApplicantStatus function socket", error);
        } else {
          trackEvent(
            `${getCurrentUser() && getCurrentUser().id} sign an application`,
            `websocket application ID ${myObj.id}`,
            "sign application btn"
          );
        }
      });

      setLoadingStatus(true);
      setApplicationStatus(status);

      socket.current.on(`${getCurrentUser().id}myReview`, (data) => {
        setAllStatus(data);
        setLoadingStatus(false);
        setUpdated(true);
        applicationSelected.approved = null;

        setInterval(() => {
          setUpdated(false);
        }, 5000);
        trackEvent(
          `landlord ${
            getCurrentUser() && getCurrentUser().id
          } recieve sign lease`,
          `sign lease ${data.id}`
        );
      });
    }
  };

  //////////HELPER Delete function
  //delete this application only from table
  const onDeleteFromTable = async () => {
    try {
      setPrevLeaseEnterLeave("leave");
      if (!filterStatuses(applicationSelected.id).lease_signed) {
        setSignFinish(true);
        setDeleteQuestion(false);
        setInterval(() => {
          setSignFinish(false);
        }, 15000);
        return;
      }
      const appl = [...applications];
      const remainingApp = appl.filter(
        (app) => app.id !== applicationSelected.id
      );
      setApplications(remainingApp);

      await deleteAStatusTotally(applicationSelected.id);
      trackEvent(
        `${getCurrentUser() && getCurrentUser().id} delete an application`,
        applicationSelected.id
      );
      setDeleteQuestion(false);
    } catch (ex) {
      logger.log("onApplicantStatus function error", ex);
    }
  };

  /////////////////////////////////////////////////////////////
  ////DELETE THE APPLICATION TOTALLY
  const deleteTotally = async () => {
    try {
      if (filterStatuses(applicationSelected.id).lease_signed) {
        setCanDeleteLeaseSignTotally(true);
        setDeleteQuestion(false);
        setInterval(() => {
          setCanDeleteLeaseSignTotally(false);
        }, 7000);
        return;
      }
      const appl = [...applications];
      const remainingApp = appl.filter(
        (app) => app.id !== applicationSelected.id
      );
      setApplications(remainingApp);

      await deleteApplicationByIdTotally(applicationSelected.id);
      socket.current.emit("deleteApplication", applicationSelected);
      trackEvent(
        `${getCurrentUser() && getCurrentUser().id} delete an application`,
        applicationSelected.id
      );
      setDeleteQuestion(false);
    } catch (ex) {
      logger.log("onApplicantStatus function error", ex);
    }
  };

  const filterStatuses = (id) => {
    const status = allStatus.filter((all) => all.application_id === id);
    const sortedListing = _.orderBy(
      status,
      [sortColumn.path],
      [sortColumn.order]
    );
    const myStatus = sortedListing[sortedListing.length - 1];
    return myStatus
      ? myStatus
      : {
          accepted_or_denied: null,
          lease_signed: null,
          under_review: null,
          approved: null,
        };
  };

  //function to upload files to multer
  const onChangeUIC = async (e) => {
    e.preventDefault();
    setLoadOtherPhoto(true);
    const filesss = e.currentTarget.files;
    let myFile = [];
    for (const file of filesss) {
      if (file.size > 8323863) {
        setOtherPhotoError(t("large_file"));
        setLoadOtherPhoto(false);
      } else {
        const result = await uploadMultipleFileToCloudinary(file, myImageData);
        myFile.push(result);
      }
    }

    setOtherPhotoPath([...otherPhotoPath, ...myFile]);
    setLoadOtherPhoto(false);
  };

  //function to find out if the tenant has sign a lease so as to allow the landlord to sign the lease too
  const tenantNotSignYet = () => {
    const status = allStatus.filter(
      (status) => status.application_id === applicationSelected.id
    );
    return status;
  };

  //function to submit the signed lease info
  const onSubmitLeases = async (e) => {
    e.preventDefault();
    setPrevLeaseEnterLeave("leave");
    if (window.confirm(t("Are you sure you want to sign this lease ?"))) {
      if (tenantNotSignYet().length === 0) {
        alert(t("alert_tenant_sign_b4_u"));
      } else if (tenantNotSignYet()[0].tenant_sign_lease === false) {
        alert(t("tenant_sign_review_b4_u"));
      } else {
        try {
          if (downloadPDF) {
            setPrint(true);

            if (
              (contactNumber && agreementStart && agreementEnd) ||
              (selectedListing.rent_or_sell == "Sale" && agreementStart)
            ) {
              setTimeout(() => {
                //where our download PDF for an agreement
                const doc = new jsPDF("p", "pt", "a4");
                doc.html(document.querySelector("#thePrintedPDFAgreement"), {
                  callback: function (pdf) {
                    let pageCount = doc.internal.getNumberOfPages();
                    pdf.deletePage(pageCount + 1);
                    pdf.save(`${selectedListing.title}myDocument.pdf`);
                    setPrint(false);
                  },
                });
              }, 2000);
            }

            const myObj = {
              id: applicationSelected.id,
              listing_id: applicationSelected.listing_id,
              applicant_id: applicationSelected.applicant_id,
              list_owner_id: applicationSelected.list_owner_id,
              status: "Leases Signed",
              contact_number: contactNumber,
              agreementStart,
              agreementEnd,
              otherPhotoPath: otherPhotoPath ? otherPhotoPath : [],
            };

            function myTestingDates() {
              const myStartDate = agreementStart.slice(0, 10);
              const myYear = myStartDate.slice(0, 4);
              const myMonths = Number(`${myStartDate.slice(5, 7)}`) - 1;
              const myDays =
                `${myStartDate.slice(8, 11)}`[0] === `0`
                  ? myStartDate.slice(8, 11)[1]
                  : myStartDate.slice(8, 11);

              const today = new Date();
              const past = new Date(myYear, myMonths, myDays);

              const diff = Math.floor(today.getTime() - past.getTime());
              const day = 1000 * 60 * 60 * 24;

              var days = Math.floor(diff / day);
              const months = Math.floor(days / 30);
              //  var years = Math.floor(months/12)
              return days;
            }

            function myTestingEndDate() {
              const myStartDate = agreementStart?.slice(0, 10);
              const myYear = myStartDate.slice(0, 4);
              const myMonths = Number(`${myStartDate?.slice(5, 7)}`) - 1;
              const myDays =
                `${myStartDate?.slice(8, 11)}`[0] === `0`
                  ? myStartDate?.slice(8, 11)[1]
                  : myStartDate?.slice(8, 11);

              const myEndDate = agreementEnd?.slice(0, 10);
              const myEndYear = myEndDate?.slice(0, 4);
              const myEndMonths = Number(`${myEndDate?.slice(5, 7)}`) - 1;
              const myEndDays =
                `${myEndDate?.slice(8, 11)}`[0] === `0`
                  ? myEndDate?.slice(8, 11)[1]
                  : myEndDate?.slice(8, 11);

              const today = new Date(myEndYear, myEndMonths, myEndDays);
              const past = new Date(myYear, myMonths, myDays);

              const diff = Math.floor(today.getTime() - past.getTime());
              const day = 1000 * 60 * 60 * 24;

              var days = Math.floor(diff / day);
              const months = Math.floor(days / 30);
              //  var years = Math.floor(months/12)
              return days;
            }

            if (myTestingDates() > 0)
              return setErrorPosting(t("start_date_error"));
            if (
              selectedListing.listing_type !== "Land" &&
              agreementEnd &&
              myTestingEndDate() < 0
            )
              return setErrorPosting(t("end_date_error"));

            setErrorPosting("");
            setLoadingStatus(true);
            setApplicationStatus("Leases Signed");
            setLoadingPosting(true);
            socket.current.emit("underReview", myObj, ({ error }) => {
              setErrorPosting(error);
              setLoadingStatus(false);
              setLoadingPosting(false);
              setContactNumber("");
              setPrint(false);
            });
            // // const {data}=await postStatus(myObj)
            socket.current.on(`${getCurrentUser().id}myReview`, (data) => {
              setAllStatus(data);
              setLoadingStatus(false);
              setLoadingPosting(false);
            });

            if (
              (contactNumber && agreementStart && agreementEnd) ||
              (selectedListing.rent_or_sell == "Sale" && agreementStart)
            ) {
              await createYourPDF(myObj);
            }

            setPhotoNameIdCard("");
            setUploadIdCard("");
            setLoadUploadIdCard(false);
            setErrorUploadIdCard("");
            setJobTitle("");
            setOtherPhotoPath("");
            setOtherPhotoError("");
            setLoadOtherPhoto(false);
            setAgreementStart("");
            setAgreementEnd("");
          } else {
            if (!agreementStart)
              return setErrorPosting(
                "Starting date is required to sign a lease agreement"
              );

            if (!agreementEnd)
              return setErrorPosting(
                "End date is required to sign a lease agreement"
              );

            if (myTestingDates() > 0)
              return setErrorPosting(
                "Starting date must be Either a Date today or after today"
              );

            if (
              selectedListing.listing_type !== "Land" &&
              agreementEnd &&
              myTestingEndDate() < 0
            )
              return setErrorPosting(
                "Ending date must atleast be a day greater than the starting date"
              );

            if (!contactNumber)
              return setErrorPosting(
                "Apartment number is not allowed to be empty"
              );

            if (!agreementStart)
              return setErrorPosting(
                "Agreement starting date is not allowed to be empty"
              );

            if (selectedListing.rent_or_sell !== "Sale" && !agreementEnd)
              return setErrorPosting(
                "Agreement end date is not allowed to be empty rental leases"
              );

            if (selectedListing.rent_or_sell == "Sale" && !agreementStart)
              return setErrorPosting(
                "Agreement starting date is not allowed to be empty for Sale leases"
              );

            function myTestingDates() {
              const myStartDate = agreementStart.slice(0, 10);
              const myYear = myStartDate.slice(0, 4);
              const myMonths = Number(`${myStartDate.slice(5, 7)}`) - 1;
              const myDays =
                `${myStartDate.slice(8, 11)}`[0] === `0`
                  ? myStartDate.slice(8, 11)[1]
                  : myStartDate.slice(8, 11);

              const today = new Date();
              const past = new Date(myYear, myMonths, myDays);

              const diff = Math.floor(today.getTime() - past.getTime());
              const day = 1000 * 60 * 60 * 24;

              var days = Math.floor(diff / day);
              const months = Math.floor(days / 30);
              //  var years = Math.floor(months/12)
              return days;
            }

            function myTestingEndDate() {
              const myStartDate = agreementStart.slice(0, 10);
              const myYear = myStartDate.slice(0, 4);
              const myMonths = Number(`${myStartDate.slice(5, 7)}`) - 1;
              const myDays =
                `${myStartDate.slice(8, 11)}`[0] === `0`
                  ? myStartDate.slice(8, 11)[1]
                  : myStartDate.slice(8, 11);

              const myEndDate = agreementEnd.slice(0, 10);
              const myEndYear = myEndDate.slice(0, 4);
              const myEndMonths = Number(`${myEndDate.slice(5, 7)}`) - 1;
              const myEndDays =
                `${myEndDate.slice(8, 11)}`[0] === `0`
                  ? myEndDate.slice(8, 11)[1]
                  : myEndDate.slice(8, 11);

              const today = new Date(myEndYear, myEndMonths, myEndDays);
              const past = new Date(myYear, myMonths, myDays);

              const diff = Math.floor(today.getTime() - past.getTime());
              const day = 1000 * 60 * 60 * 24;

              var days = Math.floor(diff / day);
              const months = Math.floor(days / 30);
              //  var years = Math.floor(months/12)
              return days;
            }

            const space = listing_spaces.find(
              (space) => Number(space.space_number) === Number(contactNumber)
            );

            if (!space) {
              return setErrorPosting(
                `${selectedListing.listing_type} number must not be greater than ${listing_spaces.length} (ie listing available space) and must not be less than 1`
              );
            }

            const myObj = {
              id: applicationSelected.id,
              listing_id: applicationSelected.listing_id,
              applicant_id: applicationSelected.applicant_id,
              list_owner_id: applicationSelected.list_owner_id,
              status: "Leases Signed",
              contact_number: contactNumber,
              agreementStart,
              agreementEnd,
              otherPhotoPath: otherPhotoPath ? otherPhotoPath : [],
            };

            if (space && space.empty_space == false) {
              if (
                window.confirm(
                  `Are you sure you want to add ${capitalize(
                    applicationSelected?.appicant_name
                  )}
                  into ${
                    selectedListing?.listing_type
                  } Number ${contactNumber} of ${selectedListing?.title} ?.
                  ${
                    selectedListing?.listing_type
                  } Number ${contactNumber} is already occupied by ${
                    space?.occupant_name
                  } and
                  will be replaced by ${capitalize(
                    applicationSelected?.appicant_name
                  )}. Please close lease agreement with ${space?.occupant_name}
                  before proceeding. Or call Captivator technologies to total close this agreement before proceeding`
                )
              ) {
                setErrorPosting("");
                setLoadingStatus(true);
                setApplicationStatus("Leases Signed");
                setLoadingPosting(true);
                socket.current.emit("underReview", myObj, ({ error }) => {
                  setErrorPosting(error);
                  setLoadingStatus(false);
                  setLoadingPosting(false);
                  setLeaseSignDone(true);
                  setInterval(() => {
                    setLeaseSignDone(false);
                  }, 5000);
                });

                socket.current.on(`${getCurrentUser().id}myReview`, (data) => {
                  setAllStatus(data);
                  setLoadingStatus(false);
                  setLoadingPosting(false);
                  setLeaseSignDone(true);
                  setInterval(() => {
                    setLeaseSignDone(false);
                  }, 5000);
                });

                await createYourPDF(myObj);

                setPhotoNameIdCard("");
                setUploadIdCard("");
                setLoadUploadIdCard(false);
                setErrorUploadIdCard("");
                setJobTitle("");
                setContactNumber("");

                setOtherPhotoPath("");
                setOtherPhotoError("");
                setLoadOtherPhoto(false);
                setAgreementStart("");
                setAgreementEnd("");

                const theStatussses = allStatus.find(
                  (a) => a.application_id === applicationSelected.id
                );

                setAppl20(
                  appl20.filter.filter((app) => app.id !== theStatussses.id)
                );
              }
            } else {
              setErrorPosting("");
              setLoadingStatus(true);
              setApplicationStatus("Leases Signed");
              setLoadingPosting(true);
              socket.current.emit("underReview", myObj, ({ error }) => {
                setErrorPosting(error);
                setLoadingStatus(false);
                setLoadingPosting(false);
                setLeaseSignDone(true);
                setInterval(() => {
                  setLeaseSignDone(false);
                }, 5000);
              });

              socket.current.on(`${getCurrentUser().id}myReview`, (data) => {
                setAllStatus(data);
                setLoadingStatus(false);
                setLoadingPosting(false);
                setLeaseSignDone(true);
                setInterval(() => {
                  setLeaseSignDone(false);
                }, 5000);
              });

              await createYourPDF(myObj);

              setPhotoNameIdCard("");
              setUploadIdCard("");
              setLoadUploadIdCard(false);
              setErrorUploadIdCard("");
              setJobTitle("");
              setContactNumber("");

              setOtherPhotoPath("");
              setOtherPhotoError("");
              setLoadOtherPhoto(false);
              setAgreementStart("");
              setAgreementEnd("");

              const theStatussses = allStatus.find(
                (a) => a.application_id === applicationSelected.id
              );

              setAppl20(appl20.filter.filter((app) => app.id !== theStatussses.id));
            }
          }
        } catch (ex) {
          setLoadingPosting(false);
          setErrorPosting(ex.response ? ex.response.data : "");
        }
      }
    }
  };

  //function to disable the under review button if the under review action have already been carried out
  const disableUnderReviewBtn = () => {
    const status = allStatus.filter(
      (all) => all.application_id === applicationSelected.id
    );
    return status[0] && status[0].under_review ? true : false;
  };

  //function to disable the sign lease button until the under review action have been carried out for a particular applicant
  const disableSignLeaseBtn = () => {
    const status = allStatus.filter(
      (all) => all.application_id === applicationSelected.id
    );
    return (status[0] && status[0].lease_signed) ||
      status.length === 0 ||
      (status[0] && status[0].approved === null)
      ? true
      : false;
  };

  //function use to capitalise the first letters of a word
  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  //function to delete an image you don't wnant to upload when sign a lease
  const onDeleteImage = (image) => {
    const images = otherPhotoPath.filter((img) => img !== image);
    setOtherPhotoPath(images);
  };

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  //function to close the delete Container when click outerside twice
  document.getElementById("root").addEventListener("dblclick", (e) => {
    e.preventDefault();
    setDeleteQuestion(false);
    setPrevLeaseEnterLeave("leave");
  });

  const showTenantPrevLeases = async (appl, enterOrLeave = "enter") => {
    try {
      if (enterOrLeave == "enter") {
        setLoadPrevLease(true);
        setLoaded(true);
        setHoverAppl(appl);
        const { data } = await getStatusByApplicantsId(appl.applicant_id);
        setPrevLeaseEnterLeave(enterOrLeave);
        setTenantPrevLease(data);
        setLoadPrevLease(false);
      } else {
        setPrevLeaseEnterLeave(enterOrLeave);
      }
    } catch (ex) {
      logger.log(ex);
    }
  };

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );
  if (error)
    return (
      <div className="homeSpinnerContainer">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  return (
    <>
      {updated && (
        <div
          style={{
            width: "25%",
            padding: "5px",
            position: "fixed",
            zIndex: "100",
            background: "#ffa500",
            margin: "50px 37.5%",
            color: "white",
            textAlign: "center",
            borderRadius: "5px",
          }}
        >
          {t("alert_update_to",{
            applicant_name:capitalize(applicationSelected?.appicant_name),
            status:status
          })}
        </div>
      )}
      {loaded && (
        <TenantPreviousLeases
          tenantPrevLease={tenantPrevLease}
          loadPrevLease={loadPrevLease}
          prevLeaseEnterLeave={prevLeaseEnterLeave}
          listings={listings}
          hoverAppl={hoverAppl}
          onClickClose={() => {
            setDeleteQuestion(false);
            setPrevLeaseEnterLeave("leave");
          }}
        />
      )}
      <Meta1
        title={`${langType}.Nawafrica | ${t("meta_application", {
          name: getCurrentUser() && getCurrentUser().first_name,
        })}`}
        description="Show All Applicants Of My Nawafrica Listing"
        keywords="Applications Listing Application"
        link="/portal/application/applicants"
      />

      {deleteQuestion && (
        <DeleteOptions
          deleteTotally={deleteTotally}
          deleteFromTable={onDeleteFromTable}
        />
      )}
      {alertUnderReview && (
        <CustomAlert data={t("Please, you already sign this lease, it cannot be reverse to under reviews")} />
      )}
      {alertUnderReviewAlready && (
        <CustomAlert data={t("Please, you alread updated this application to under review")} />
      )}
      {signFinish && (
        <CustomAlert data={t("Please_delete_now")} />
      )}

      {canDeleteLeaseSignTotally && (
        <CustomAlert
          data={t("Please, you can't delete an application totally")}
        />
      )}
      <ApplicationActionModal1
        open={open}
        handleClose={() => setOpen(false)}
        application={applicationSelected}
        onChangeStatus={onChangeStatus}
        loadingStatus={loadingStatus}
        leaseSignDone={leaseSignDone}
        loadingPosting={loadingPosting}
        onClickImage={onDeleteImage}
        onChangeUIC={onChangeUIC}
        photoNameIdCopy={photoNameIdCopy}
        uploadIdCard={uploadIdCard}
        loadUploadIdCard={loadUploadIdCard}
        errorUploadIdCard={errorUploadIdCard}
        onChangeCN={(e) => {
          e.preventDefault();
          const { value } = e.currentTarget;
          setContactNumber(value);
        }}
        onSubmitLeases={onSubmitLeases}
        errorPosting={errorPosting}
        otherPhotoPath={otherPhotoPath}
        otherPhotoError={otherPhotoError}
        loadOtherPhoto={loadOtherPhoto}
        onChangeSD={(e) => {
          const { value } = e.target;
          setAgreementStart(value);
        }}
        onChangeED={(e) => {
          const { value } = e.target;
          setAgreementEnd(value);
        }}
        valueSD={agreementStart}
        valueED={agreementEnd}
        contactNumber={contactNumber}
        disabledReviewBtn={disableUnderReviewBtn()}
        disableSignLease={disableSignLeaseBtn()}
        downloadPDF={downloadPDF}
        onChangeDownload={(e) => {
          const value = e.target.checked;
          if (value) {
            setNodownloadPDF("");
            setDownloadPDF("Download");
          } else {
            setDownloadPDF("");
          }
        }}
        NodownloadPDF={nodownloadPDF}
        valueListing={selectedListing}
        onChangeNoDownload={(e) => {
          const value = e.target.checked;
          if (value) {
            setNodownloadPDF("noDownload");
            setDownloadPDF("");
          } else {
            setNodownloadPDF("");
          }
        }}
        allStatus={allStatus}
        listing_spaces={listing_spaces}
      />

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          path={path}
          onTakeBarUp={onTakeBarUp}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <div className="applicationsScreenCon">
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        <WelcomeUser
          user={capitalize(getCurrentUser() && getCurrentUser().first_name)}
        />

        <ApplicationTitle
          appl={applications.length > 99 ? "99+" : applications.length}
        />

        <div
          className={
            close ? "applicationScreenTableCon" : "applicationScreenTableCon1"
          }
        >
          <SearchApplicant
            onChangeSearch={onSearchApplicant}
            onChangeSort={onSort}
          />

          {applications.length > 0 ? (
            <ApplicationsTable
              loadingStatus={loadingStatus}
              applicants={applications}
              fromSearch={fromSearch}
              loadingSearchListings={loadingSearchListings}
              users={users}
              selectedApplicant={selectedApplicant}
              applicationStatus={applicationStatus}
              applicationSelected={applicationSelected}
              allStatus={allStatus}
              showTenantPrevLeases={showTenantPrevLeases}
            />
          ) : loadingSearchListings ? (
            <div>{t(".....loading")}</div>
          ) : (
            <div className="noListingAvailableYetCon">
              <div className="alert">{t("no_application")}</div>
            </div>
          )}
        </div>
      </div>

      {print && (
        <DownLoadHtmlPDF
          valueDateAgreedMonth={myDate().dayAndMonth}
          valueDateAgreedYear={myDate().year}
          valueLandlordName={capitalize(
            `${selectedLandlord.first_name} ${selectedLandlord.last_name}`
          )}
          valueEmail={selectedLandlord.email}
          valueLandlordCity={selectedLandlord.city}
          valueLandlordState={selectedLandlord.states}
          valueLandlordCountry={selectedLandlord.country}
          valueTenantName={capitalize(
            `${selectedApplicants.first_name} ${selectedApplicants.last_name}`
          )}
          valueProfession={selectedApplicants.profession}
          valueListCountry={selectedListing.country}
          valueListState={selectedListing.state}
          valueListCity={selectedListing.city}
          valueListAddress={selectedListing.street_address}
          valueListType={selectedListing.listing_type}
          valueAppartNum={contactNumber}
          valueBedrooms={selectedListing.bedrooms}
          valueBathroom={selectedListing.bathrooms}
          valueKitchen={selectedListing.kitchen}
          citeName={selectedListing.title.toUpperCase()}
          fixedAgreement={selectedListing.fixed_agreement}
          renewFixedAgreement={
            selectedListing.agreement_after_fixed_agreement
              ? selectedListing.agreement_after_fixed_agreement
                  .renew_fixed_agreement
              : false
          }
          continueWithMonthToMonth={
            selectedListing.agreement_after_fixed_agreement
              ? selectedListing.agreement_after_fixed_agreement
                  .continue_with_month_to_month
              : false
          }
          vacatePremise={
            selectedListing.agreement_after_fixed_agreement
              ? selectedListing.agreement_after_fixed_agreement.vacateP
              : false
          }
          monthToMonthAgreement={selectedListing.month_to_month_agreement}
        />
      )}
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default Applications;
