import React from 'react'

const HelpInfo = () => {
  return (
    <div className='consultantHelpInfo'>
      <b style={{ color: '#035aa6' }}>NEED HELP</b>
      <h1>Client services & technical support</h1>
      <p>
        Thank you for visiting our site. Please use one of the options below to
        inquire about our products and services
      </p>
    </div>
  )
}

export default HelpInfo
