import http from "./httpService"

export function getRecipentMessages(id){
return http.get(`/messages/${id}`)
}

export function deleteGivenMessageById(id){
return http.delete(`/messages/${id}`)
}

export function updateGivenMessageById(id){
return http.put(`/messages/${id}`)
}

export function getMessageById(id){
return http.get(`/messages/message/${id}`)
}

export function getAllMyUnreadMsgId(id){
return http.get(`/messages/unreadMsg/${id}`)
}

export function getRecipientUnreadMsgById(id){
 return http.get(`/messages/recipientUnreadMessages/${id}`)             
}
