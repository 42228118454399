import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import { CircularProgress } from "@mui/material";
import "simplebar";
import "simplebar/dist/simplebar.css";

let deviceWidth = window.innerWidth;

const SpaceList = ({
  spaces = [],
  listing,
  onSelectSpace,
  selectedSpace,
  loading,
}) => {
  console.log(deviceWidth);
  return !loading ? (
    <Box
      data-simplebar
      sx={
        deviceWidth <= 991
          ? { width: "100%", height: "150px", bgcolor: "background.paper" }
          : { width: "100%", height: "350px", bgcolor: "background.paper" }
      }
    >
      <nav aria-label="main mailbox folders">
        <List>
          {spaces
            .sort((a, b) => a.space_number - b.space_number)
            .map((space, index) =>
              index === spaces.length - 1 ? (
                <ListItem key={space.id} disablePadding>
                  <ListItemButton
                    style={
                      selectedSpace?.id === space.id
                        ? {
                            borderTop: "1px solid #035aa6",
                            borderLeft: "1px solid #035aa6",
                            borderRight: "1px solid #035aa6",
                            borderBottom: "1px solid #035aa6",
                            backgroundColor: "#035aa6",
                          }
                        : {
                            borderTop: "1px solid #035aa6",
                            borderLeft: "1px solid #035aa6",
                            borderRight: "1px solid #035aa6",
                            borderBottom: "1px solid #035aa6",
                          }
                    }
                    onClick={() => onSelectSpace(space)}
                  >
                    <ListItemIcon>
                      <HomeWorkIcon
                        style={
                          selectedSpace?.id == space.id
                            ? { color: "white" }
                            : null
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={
                        selectedSpace?.id === space.id
                          ? { color: "white" }
                          : null
                      }
                      primary={`${listing.listing_type} - #${
                        space.space_name ? space.space_name : space.space_number
                      }`}
                    />
                  </ListItemButton>
                </ListItem>
              ) : (
                <ListItem key={space.id} disablePadding>
                  <ListItemButton
                    style={
                      selectedSpace?.id === space.id
                        ? {
                            borderTop: "1px solid #035aa6",
                            borderLeft: "1px solid #035aa6",
                            borderRight: "1px solid #035aa6",
                            backgroundColor: "#035aa6",
                          }
                        : {
                            borderTop: "1px solid #035aa6",
                            borderLeft: "1px solid #035aa6",
                            borderRight: "1px solid #035aa6",
                          }
                    }
                    onClick={() => onSelectSpace(space)}
                  >
                    <ListItemIcon>
                      <HomeWorkIcon
                        style={
                          selectedSpace?.id == space.id
                            ? { color: "white" }
                            : null
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      style={
                        selectedSpace?.id === space.id
                          ? { color: "white" }
                          : null
                      }
                      primary={`${listing.listing_type} - #${
                        space.space_name ? space.space_name : space.space_number
                      }`}
                    />
                  </ListItemButton>
                </ListItem>
              )
            )}
        </List>
      </nav>
    </Box>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={45} sx={{ color: "#035aa6" }} />
    </div>
  );
};

export default SpaceList;
