import React from 'react'
import Progress from 'react-progressbar'

const NewsLoading = ({ loadingProgress }) => {
  console.log(loadingProgress)
  return (
    <div className='newsloadingCon'>
      {' '}
      <div className='addListingProgressBarCon'>
        <Progress color='#035aa6' completed={loadingProgress} />
        <div>{loadingProgress}%</div>
      </div>
    </div>
  )
}

export default NewsLoading
