import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";

import AgreementInputs from "./AgreementInputs";
import AgreementCheckbox from "./AgreementCheckbox";
import { FaHandshake } from "react-icons/fa";
import SignLeaseLoader from "../Applications/SignLeaseLoader";

import "./leases.css";
import HammerSign from "./HammerSign";

const AgreementLeaseModal1 = ({
  valueDateAgreedMonth,
  valueDateAgreedYear,
  valueLandlordName,
  valueEmail,
  valueLandlordCity,
  valueLandlordState,
  valueLandlordCountry,
  valueTenantName,
  valueProfession,

  valueListCountry,
  valueListState,
  valueListCity,
  valueListAddress,
  valueListType,
  onChangeAppartNum,
  valueBedrooms,
  valueBathroom,
  valueKitchen,
  citeName,
  submitAgreement,

  loadingSignLease,
  send,

  fixedAgreement,
  renewFixedAgreement,
  continueWithMonthToMonth,
  vacatePremise,
  monthToMonthAgreement,
  soldAgreement,
  NoCloseSignModal,
  onclickDone,
  onClickCancel,
}) => {
  const { t } = useTranslation();
  return (
    <div className="agreementModal1OuterCon">
      <div className="tenantSignLeaseModals">
        <div
          style={{
            width: "100%",

            background: "#035aa6",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "10px",
          }}
          className="modal-header"
        >
          <h3
            style={{ color: "white", textAlign: "center" }}
            className="agreementTitleHeader"
          >
            {t("Sign_Lease_For_{citeName}", { citeName })}
          </h3>
        </div>
        {loadingSignLease ? (
          <div style={{ margin: "20px 0" }}>
            <SignLeaseLoader />
          </div>
        ) : send ? (
          <HammerSign />
        ) : NoCloseSignModal ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px 0",
            }}
          >
            <div
              onClick={onclickDone}
              className="close "
              data-dismiss="modal"
              aria-label="Close"
            >
              {" "}
              <FontAwesomeIcon
                style={{
                  color: "white",
                  fontSize: "250px",
                  marginBottom: "20px",
                }}
                icon={faXmarkCircle}
              />
            </div>
          </div>
        ) : (
          <>
            <div className="modal-header">
              <h3 style={{ color: "white" }} className="agreementTitleHeader">
                {t("Sign_Lease_For_{citeName}", { citeName })}
              </h3>
            </div>
            <div className="modal-body">
              <div className="agreementInnerContainer">
                <button
                  onClick={onClickCancel}
                  type="button"
                  className="close cancelAgreementLeaseModal"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <FontAwesomeIcon
                    style={{
                      color: "black",
                      fontSize: "45px",
                    }}
                    icon={faXmark}
                  />
                </button>
                <img
                  style={{ objectFit: "cover" }}
                  className="logo201"
                  src="https://res.cloudinary.com/captivator/image/upload/v1686139959/udmrkoq8ko9cidbdlhub.webp"
                  alt="_"
                />
                <form onSubmit={submitAgreement}>
                  <h3 className="agreementInnerHeader">
                    {t("RESIDENTIAL_LEASE_AGREEMENT")}
                  </h3>

                  <p className="agreementPartyContainer">
                    <b>I.</b> <b>{t("THE_PARTIES")},</b>{" "}
                    <span>{t("This_Residential_Lease_Agreement")}</span>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueDateAgreedMonth}
                    </span>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      /{valueDateAgreedYear}
                    </span>
                    {t("is_between")};
                  </p>

                  <p className="agreementLandlordInfoCon">
                    <b>{t("Landlord")} : </b>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueLandlordName}
                    </span>
                    {t("with_a_mail_address_of")}
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueEmail}
                    </span>
                    ,{t("City_of")}
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueLandlordCity ? valueLandlordCity : "........."}
                    </span>
                    ,{t("State_of")}
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueLandlordState ? valueLandlordState : "........"}
                    </span>
                    , {t("Country")}
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueLandlordCountry ? valueLandlordCountry : "........"}
                    </span>
                    ({t("Landlord")}),{" "}
                    <b className="TheAndInAgreementLeaseModal">{t("AND")}</b>
                  </p>

                  <p className="agreementTenantInfoCon">
                    <b>{t("Tenant(s)")}: </b>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueTenantName}
                    </span>
                    , {t("Who_is")}
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueProfession ? valueProfession : "........"}
                    </span>
                    .
                  </p>

                  <p>{t(`Landlord_and_Tenant_are_hereby`)}</p>
                  <p>{t("NOW_THEREFORE_FOR_AND_IN_CONSIDERATION")} : </p>

                  <p>
                    <b>II. {t("LEASE_TYPE")}.</b> {t("This_agreement_shall")} :
                  </p>
                  <p className="agreementFixedLease">
                    {fixedAgreement && (
                      <p>
                        <span className="inlineAgreementLeaseModal">
                          <AgreementCheckbox />
                        </span>
                        <span className="inlineAgreementLeaseModal">
                          - {t("fixed_lease_signed")}
                        </span>
                      </p>
                    )}

                    {fixedAgreement && renewFixedAgreement && (
                      <span className="agreementLeaseModalCheckboxMargin">
                        <AgreementCheckbox />-
                        {t("Renew_Your_Fixed_Lease_Agreement")}.
                      </span>
                    )}

                    {fixedAgreement && continueWithMonthToMonth && (
                      <span className="inputCon5">
                        <AgreementCheckbox />-{t("continue_lease_under_same")}
                      </span>
                    )}

                    {fixedAgreement && vacatePremise && (
                      <span className="inputCon6">
                        <AgreementCheckbox /> -{"Must_vacate_the_Premises"}
                      </span>
                    )}
                  </p>

                  {monthToMonthAgreement && (
                    <p className="agreementMonthToMonth">
                      <span className="inputCon1">
                        <AgreementCheckbox />
                      </span>
                      <span>-{t("Month-to-Month_Lease.")}</span>
                      {t("month-to-month_agreement_starting")}{" "}
                      <span className="inputCon4">
                        <AgreementInputs placeholder="eg 30" />
                      </span>{" "}
                      {t("days_from_either_Party")}
                    </p>
                  )}

                  {soldAgreement && (
                    <p className="agreementMonthToMonth">
                      <span className="inputCon1">
                        <AgreementCheckbox />
                      </span>
                      <span>-{t("sold_lease_modal")}</span>
                    </p>
                  )}

                  <p className="agreementTheProperty">
                    <b>III. {t("THE_PROPERTY")}.</b>
                    <span>{t("The_Landlord_agrees_to_lease")}:</span>(
                    {t("Here_are_the_Property_Informations")})
                  </p>
                  <p className="agreementCountryStateCityCon">
                    <span className="countryStateCon">
                      <b>a) {t("Country")}</b>
                      <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                        {valueListCountry}
                      </span>
                    </span>
                    <span className="stateCon">
                      <b>b) {t("State")}</b>
                      <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                        {valueListState}
                      </span>
                    </span>
                  </p>

                  <p className="agreementCityAddressCon">
                    <span className="cityCon">
                      <b>c) {t("City")}</b>
                      <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                        {valueListCity}
                      </span>
                    </span>
                    <span className="addressCon">
                      <b>d) {t("Address")}</b>
                      <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                        {valueListAddress}
                      </span>
                    </span>
                  </p>
                  <p className="agreementResidentType">
                    <b>e) {t("Residential_Type")}</b>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueListType}
                    </span>
                  </p>
                  <p className="agreementAppartNum">
                    <b>f) {t("Apartment/studio/office/condos_Number")}</b>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {onChangeAppartNum}
                    </span>
                  </p>
                  <p className="agreementBedroom">
                    <b>g) {t("Bedrooms")}</b>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueBedrooms}
                    </span>
                  </p>
                  <p className="agreementBathroom">
                    <b>h) {t("Bathrooms")}</b>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueBathroom}
                    </span>
                  </p>
                  <p className="agreementKitchen">
                    <b>i) {t("Kitchen")}</b>
                    <span className="leftMarginAndUnderlineInAgreementLeaseModal">
                      {valueKitchen}
                    </span>
                  </p>

                  <span className="agreementSignLeaseBtn">
                    <button disabled={loadingSignLease} type="submit">
                      <FaHandshake className="handshakeAgreementLeaseModal" />{" "}
                      {t("Sign_Lease")}
                    </button>
                  </span>
                </form>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementLeaseModal1;
