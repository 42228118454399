import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWrench,
  faShower,
  faGlasses,
  faChartLine,
  faDoorOpen,
  faInfo,
  faCircleExclamation,
  faFeather,
} from '@fortawesome/free-solid-svg-icons'

import BlogRecentPost from '../Blog/BlogRecentPost'

const BlogDetailBody = ({ item, blogs, onClickBlogDeleted, ID }) => {
  const getMonth = (month) => {
    if (month == '01') return 'JAN'
    if (month == '02') return 'FEB'
    if (month == '03') return 'MAR'
    if (month == '04') return 'APR'
    if (month == '05') return 'MAY'
    if (month == '06') return 'JUN'
    if (month == '07') return 'JUL'
    if (month == '08') return 'AUG'
    if (month == '09') return 'SEPT'
    if (month == '10') return 'OCT'
    if (month == '11') return 'NOV'
    return 'DEC'
  }
  return (
    <div className='blogDetBodyCon'>
      <div className='blogDetBodyInnerCon'>
        <h4>
          <b>
            <div>
              {item && item.catergory === 'Home Improvement' ? (
                <FontAwesomeIcon className='iconssss' icon={faWrench} />
              ) : item && item.catergory === 'Home Hygiene' ? (
                <FontAwesomeIcon className='iconssss' icon={faShower} />
              ) : item && item.catergory === 'Tips & Advices' ? (
                <FontAwesomeIcon className='iconssss' icon={faGlasses} />
              ) : item && item.catergory === 'Market Trends' ? (
                <FontAwesomeIcon className='iconssss' icon={faChartLine} />
              ) : item && item.catergory === 'Job opportunities' ? (
                <FontAwesomeIcon className='iconssss' icon={faDoorOpen} />
              ) : item && item.catergory === 'CT80R Update' ? (
                <FontAwesomeIcon className='iconssss' icon={faInfo} />
              ) : item && item.catergory === 'Scammer Alert' ? (
                <FontAwesomeIcon
                  className='iconssss'
                  icon={faCircleExclamation}
                />
              ) : (
                <FontAwesomeIcon className='iconssss' icon={faFeather} />
              )}
            </div>
            <div style={{ font: "normal normal bold 28px/18px Merriweather-sans"}}>{item && item.subtitle}</div>
          </b>
        </h4>
        <p className='blogDetBodyCatergory'>{item && item.catergory}</p>
        <p className='blogDetBodyP'>{item.introduction}</p>

        <hr style={{ marginBottom: '20px' }} />
        {item &&
          item.paragraphs &&
          item.paragraphs.map((p, i) => (
            <p key={`${i}`} className='blogDetBodyP'>
              {p}
            </p>
          ))}

        <hr style={{ marginBottom: '20px' }} />
        {item &&
          item.points &&
          item.points.map((p, i) => (
            <>
              <h3
                style={{
                  color: '#035aa6',
                  fontWeight: 'bold',
                  // fontSize: '18px',
                  display:"flex",
                  flexDirection:"row",
                  font: "normal normal bold 28px/18px Merriweather-sans"
                }}
              >
                <div
                  style={{
                    background: '#035aa6',
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '100%',
                    height: '35px',
                    width: '35px',
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "15px",
                    fontSize: "18px",
                  }}
                >
                  {i + 1}.
                </div>{' '}
                <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                {p.subtitle}

                </div>
              </h3>
              <p key={`${i}`} className='blogDetBodyP'>
                {p.description}
              </p>
            </>
          ))}

        <hr style={{ marginBottom: '20px' }} />

        <>
          <h5
            style={{
              color: '#035aa6',
              fontWeight: 'bold',
              fontSize: '18px',
              textAlign: 'center',
              font: "normal normal bold 22px/18px Merriweather-sans",
              // textDecoration: 'underline',
            }}
          >
            Final Thought
            {/* {item && item.conclusion && item.conclusion.subtitle} */}
          </h5>
          <p className='blogDetBodyP'>
            {item && item.conclusion && item.conclusion.description}
          </p>
        </>
      </div>
      <div className='blogDetBodyRecentCon'>
        <h5>MOST RECENT STORIES</h5>
        {blogs.filter((p) => p.id != ID).length === 0 && (
          <div style={{fontSize:"14px", textAlign:"center"}} className='alert alert-info'>No Recently Posted Blog</div>
        )}
        {blogs
          .filter((p) => p.id != ID)
          .map((p) => (
            <div key={`${p.id}`}>
              <BlogRecentPost
                to={`/blog/${p.id}`}
                recentContent={p.paragraphs[0]}
                recentDate={`${
                  p && p.create_date.toString().slice(8, 10)
                } ${getMonth(p && p.create_date.toString().slice(5, 7))} ${
                  p && p.create_date.toString().slice(0, 4)
                }`}
                onClickBlogDeleted={() => onClickBlogDeleted(p.id)}
                onClickLink={() => (window.location = `/blog/${p.id}`)}
              />
            </div>
          ))}
      </div>
    </div>
  )
}

export default BlogDetailBody
