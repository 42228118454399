import React from 'react'

const SidebarList = (props) => {
return (
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 477.867 477.867" xmlSpace="preserve" {...props}>
<g>
	<g>
		<path d="M426.667,0H51.2C22.923,0,0,22.923,0,51.2v375.467c0,28.277,22.923,51.2,51.2,51.2h375.467
			c28.277,0,51.2-22.923,51.2-51.2V51.2C477.867,22.923,454.944,0,426.667,0z M153.6,375.467h-51.2
			c-9.426,0-17.067-7.641-17.067-17.067s7.641-17.067,17.067-17.067h51.2c9.426,0,17.067,7.641,17.067,17.067
			S163.026,375.467,153.6,375.467z M153.6,256h-51.2c-9.426,0-17.067-7.641-17.067-17.067s7.641-17.067,17.067-17.067h51.2
			c9.426,0,17.067,7.641,17.067,17.067S163.026,256,153.6,256z M153.6,136.533h-51.2c-9.426,0-17.067-7.641-17.067-17.067
			S92.974,102.4,102.4,102.4h51.2c9.426,0,17.067,7.641,17.067,17.067S163.026,136.533,153.6,136.533z M375.467,375.467h-153.6
			c-9.426,0-17.067-7.641-17.067-17.067s7.641-17.067,17.067-17.067h153.6c9.426,0,17.067,7.641,17.067,17.067
			S384.892,375.467,375.467,375.467z M375.467,256h-153.6c-9.426,0-17.067-7.641-17.067-17.067s7.641-17.067,17.067-17.067h153.6
			c9.426,0,17.067,7.641,17.067,17.067S384.892,256,375.467,256z M375.467,136.533h-153.6c-9.426,0-17.067-7.641-17.067-17.067
			s7.641-17.067,17.067-17.067h153.6c9.426,0,17.067,7.641,17.067,17.067S384.892,136.533,375.467,136.533z"/>
	</g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
)
}

export default SidebarList
