import React, { useState, useContext, useEffect } from "react";
import logger from "../Services/loggerService";
import _ from "lodash";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";

import Sidebar from "./../components/AddListing/Sidebar";
import OpenButton from "./../components/AddListing/OpenButton";
import Chats from "./../components/AddListing/Chats";
import WelcomeUser from "./../components/AddListing/WelcomeUser";
import SearchPayment from "./../components/Payment/SearchPayment";
import PaymentTable from "../components/Payment/PaymentTable";
// import LottieLoader from '../components/contactCaptivator/LottieLoader'
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import PaymentMethodModal from "../components/Payment/PaymentMethodModal";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";

import { getCurrentUser } from "./../Services/authService";
import UnreadMsgsContext from "./../unreadmessages/context";
// import Meta from './../Utils/Meta'
import { myDateToSort } from "../Utils/currentDate";
import { updateLandlordsPaymentMethod } from "../Services/registerService";
import { getLandlordsPaymentMethod } from "./../Services/registerService";
import {
  getLandlordsPayments,
  getPaymentDetailsOflandlordById,
  postAndDownloadPaymentService,
} from "../Services/payment";
import { getListings } from "../Services/listings";
import { getLang } from "../Utils/getLang";
import { trackEvent } from "../Utils/useGAEventTracker";
import Meta1 from "../Utils/Meta1";

const PaymentScreen = () => {
  const { t } = useTranslation();
  const [close, setClose] = useState(true);
  const [path, setPath] = useState("");
  const [upSideBar, setUpSideBar] = useState(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [loadingMtnMomo, setLoadingMtnMomo] = useState(false);
  const [updatedPaymentMethod, setUpdatedPaymentMethod] = useState({});

  const [loadingVisaCard, setLoadingVisaCard] = useState(false);

  const [palpalAccount, setPalpalAccount] = useState("");
  const [loadingPaypal, setLoadingPaypal] = useState("");

  const [continue_Bank_acc, setContinue_Bank_acc] = useState(false);

  const [visaCardNumber, setVisaCardNumber] = useState("");
  const [visaCardName, setVisaCardName] = useState("");
  const [visaCardCountry, setVisaCardCountry] = useState("");
  const [visaCardBankName, setVisaCardBankName] = useState("");

  const [underConstructionBankPayment, setUnderConstructionBankPayment] =
    useState(true);
  const [underConstructionPaypal, setUnderConstructionPaypal] = useState(true);

  const [allPayments, setAllPayments] = useState([]);
  const [allPaymentDetails, setAllPaymentDetails] = useState([]);
  const [selectedPaymentTable, setSelectedPaymentTable] = useState([]);

  const [loadPayment, setLoadPayment] = useState(false);
  const [loadingSearchPayment, setLoadingSearchPayment] = useState(false);
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });
  const [selectedPaymentToLandlord, setSelectedPaymentToLandlord] =
    useState("");
  const [myUserListings, setMyUserListings] = useState([]);

  const { unreadMsgs, setAllPayments: setMyAllPayments } =
    useContext(UnreadMsgsContext);

  useEffect(() => {
    localStorage.setItem("portalPath", "/portal/payment");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    window.scrollTo(0, 0);
    getAllDatas();
  }, []);

  //function to get all users payment data from the data base
  const getAllDatas = async () => {
    try {
      setLoadPayment(true);
      setMyAllPayments([]);
      const { data: myPayMethod } = await getLandlordsPaymentMethod(
        getCurrentUser() && getCurrentUser().id
      );
      setUpdatedPaymentMethod(myPayMethod);

      const { data: allMyPayments } = await getLandlordsPayments();
      const sortedPayments = _.orderBy(
        allMyPayments,
        [sortColumn.path],
        [sortColumn.order]
      );
      setAllPayments(sortedPayments);

      const { data: thePaymentDetails } = await getPaymentDetailsOflandlordById(
        getCurrentUser() && getCurrentUser().id
      );
      setAllPaymentDetails(thePaymentDetails);

      const { data: allListings } = await getListings();
      const usersListings = allListings.filter(
        (listing) => listing.list_owner_id === getCurrentUser().id
      );
      const allUndeletedListings = usersListings.filter(
        (listing) => listing.status !== "deleted"
      );
      setMyUserListings(allUndeletedListings);

      setLoadPayment(false);
      return () => {
        localStorage.setItem("portalPath", "");
      };
    } catch (ex) {
      logger.log(ex);
    }
  };

  // function use to capitalise first letters
  const capitalize = (s) => {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to open the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  const onhandleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (selectedPaymentMethod === "MTN Mobile Money") {
        if (!countryCode) return setError(t("Mobile_Money_Country"));
        if (!phoneNumber) return setError(t("Mobile_Number_is_required"));

        const myObj = {
          type: selectedPaymentMethod,
          account_credencials: {
            momo_number: `${countryCode}${phoneNumber}`,
            country: getCurrentUser().country ? getCurrentUser().country : "",
          },
        };

        trackEvent(
          `${
            getCurrentUser() && getCurrentUser().id
          } landlord choose payment method`,
          myObj.type,
          "submit choosen payment method btn"
        );

        setLoadingMtnMomo(true);
        const { data } = await updateLandlordsPaymentMethod(
          getCurrentUser() && getCurrentUser().id,
          myObj
        );
        setUpdatedPaymentMethod(data);
        setLoadingMtnMomo(false);
        setPhoneNumber("");
        setError("");
      } else if (selectedPaymentMethod === "Visa Card") {
        if (!visaCardNumber) return setError(t("Card_Number_is_not"));
        const myObj = {
          type: selectedPaymentMethod,
          account_credencials: {
            account_owner_name: visaCardName,
            account_owner_country: visaCardCountry,
            account_number: visaCardNumber,
            account_bank_name: visaCardBankName,
            country: getCurrentUser().country ? getCurrentUser().country : "",
          },
        };

        setLoadingVisaCard(true);
        const { data } = await updateLandlordsPaymentMethod(
          getCurrentUser() && getCurrentUser().id,
          myObj
        );
        setUpdatedPaymentMethod(data);
        setLoadingVisaCard(false);
        setVisaCardBankName("");
        setVisaCardName("");
        setVisaCardCountry("");
        setVisaCardBankName("");
        setVisaCardNumber("");
        setError("");
      } else {
        if (!palpalAccount) return setError(t("Paypal_Account_is_not_sup"));
        const myObj = {
          type: selectedPaymentMethod,
          account_credencials: {
            paypal_account: palpalAccount,
            country: getCurrentUser().country ? getCurrentUser().country : "",
          },
        };

        setLoadingPaypal(true);
        const { data } = await updateLandlordsPaymentMethod(
          getCurrentUser() && getCurrentUser().id,
          myObj
        );
        setUpdatedPaymentMethod(data);
        setLoadingPaypal(false);
        setError("");
      }
    } catch (ex) {
      setError(ex.response.message);
    }
  };

  ///function to search payments base on their parameter
  //a)PAYER
  const searchPayer = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTablePayer(value, allPayments);
      setAllPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);
        const { data: allMyPayments } = await getLandlordsPayments();
        const sortedPayments = _.orderBy(
          allMyPayments,
          [sortColumn.path],
          [sortColumn.order]
        );
        setAllPayments(sortedPayments);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTablePayer = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].payer_name.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //b)PAID DATE
  const searchPaymentDate = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value) {
      const tableData = searchTableCreatedDate(value, allPayments);
      setAllPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);
        const { data: allMyPayments } = await getLandlordsPayments();
        const sortedPayments = _.orderBy(
          allMyPayments,
          [sortColumn.path],
          [sortColumn.order]
        );
        setAllPayments(sortedPayments);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableCreatedDate = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].create_date.toLowerCase();
      const myTitle = myDateToSort(title);
      if (myTitle.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //c)AMOUNT
  const searchPaymentAmount = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value) {
      const tableData = searchTableAmount(value, allPayments);
      setAllPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);
        const { data: allMyPayments } = await getLandlordsPayments();
        const sortedPayments = _.orderBy(
          allMyPayments,
          [sortColumn.path],
          [sortColumn.order]
        );
        setAllPayments(sortedPayments);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableAmount = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].amount.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  ///d)LISTING
  const searchPaymentListing = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value) {
      const tableData = searchTableListing(value, allPayments);
      setAllPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);
        const { data: allMyPayments } = await getLandlordsPayments();
        const sortedPayments = _.orderBy(
          allMyPayments,
          [sortColumn.path],
          [sortColumn.order]
        );
        setAllPayments(sortedPayments);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableListing = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].listing_name.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  const sortPayments = (e) => {
    e.preventDefault();
    const paying = [...allPayments];
    const { value } = e.currentTarget;
    const column = { ...sortColumn };
    column.path = value;
    const sortedPayment = _.orderBy(paying, [column.path], [column.order]);
    setAllPayments(sortedPayment);
  };

  const onClickPayment = (payment) => {
    const myPays = [...selectedPaymentTable];
    if (myPays.includes(payment)) {
      const remainingPay = myPays.filter((pay) => pay !== payment);
      setSelectedPaymentTable(remainingPay);
    } else {
      myPays.push(payment);
      setSelectedPaymentTable(myPays);
    }
  };

  //function to download the receipt
  const ondownloadReceipt = async (payment) => {
    try {
      setSelectedPaymentToLandlord(payment);
      postAndDownloadPaymentService(payment, (data) =>
        setSelectedPaymentToLandlord(data)
      );
    } catch (error) {
      logger.log(error);
    }
  };

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  if (loadPayment)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );
  return (
    <>
      <PaymentMethodModal
        onClickPaymentMethod={(method) => {
          setSelectedPaymentMethod(method);
          setContinue_Bank_acc(false);
        }}
        selectedPaymentMethod={selectedPaymentMethod}
        onChangeCountryCode={(e) => setCountryCode(e.target.value)}
        onChangePhoneNumber={(e) => setPhoneNumber(e.target.value)}
        onhandleSubmit={onhandleSubmit}
        loadingMtnMomo={loadingMtnMomo}
        updatedPaymentMethod={updatedPaymentMethod}
        onChangeVisaCardNumber={(e) => setVisaCardNumber(e.target.value)}
        loadingVisaCard={loadingVisaCard}
        onChangePaypal={(e) => setPalpalAccount(e.target.value)}
        loadingPaypal={loadingPaypal}
        continue_Bank_acc={continue_Bank_acc}
        onClickContinue={() => setContinue_Bank_acc(true)}
        onChangeVisaCardName={(e) => setVisaCardName(e.target.value)}
        onChangeVisaCardCountry={(e) => setVisaCardCountry(e.target.value)}
        onChangeVisaCardBankName={(e) => setVisaCardBankName(e.target.value)}
        valueBankAccountOwnerName={visaCardName}
        valueBankCountry={visaCardCountry}
        valueBankName={visaCardBankName}
        valueAccountNumber={visaCardNumber}
        underConstructionBankPayment={underConstructionBankPayment}
        underConstructionPaypal={underConstructionPaypal}
        valueMomoNumber={phoneNumber}
        error={error}
      />

      <Meta1
        title={`${getLang()}.${t("meta_billing", {
          name: getCurrentUser().first_name,
        })}`}
        description="All My Billings And Payments In Nawafrica"
        link="/portal/payment"
      />

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
          onTakeBarUp={onTakeBarUp}
        />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <div style={{ width: "100%", marginTop: "45px" }}>
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        <WelcomeUser
          user={capitalize(getCurrentUser() && getCurrentUser().first_name)}
        />

        <div
          className={
            close ? "paymentsOuterContainer" : "paymentsOuterContainer1"
          }
        >
          <SearchPayment
            onChangeSearchPayer={searchPayer}
            onChangeSearchPayDate={searchPaymentDate}
            onChangeSearchPayAmount={searchPaymentAmount}
            onChangeSearchListing={searchPaymentListing}
            onChangeSort={sortPayments}
            updatedPaymentMethod={updatedPaymentMethod}
          />

          {allPayments.length > 0 ? (
            <PaymentTable
              allPayments={allPayments}
              loadingSearchPayment={loadingSearchPayment}
              allPaymentDetails={allPaymentDetails}
              selectedPaymentTable={selectedPaymentTable}
              onClickPayment={onClickPayment}
              myUserListings={myUserListings}
              ondownloadReceipt={ondownloadReceipt}
              selectedPaymentToLandlord={selectedPaymentToLandlord}
            />
          ) : loadingSearchPayment ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              {t(".....loading")}
            </div>
          ) : (
            <div className="noListingAvailableYetCon">
              <div className="alert">{t("No_Payment_Made_Yet")}</div>
            </div>
          )}
        </div>
      </div>
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default PaymentScreen;
