import React, { useEffect, useState } from "react";

import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import NewsHead from "../components/Newsroom/NewsHead";
import LatestPress from "../components/Newsroom/LatestPress";
import { deleteNewsById, getNews } from "../Services/newsService";
import Meta from "./../Utils/Meta";
import Meta1 from "../Utils/Meta1";
import { getCurrentUser } from "../Services/authService";
import { getUserById } from "../Services/registerService";
import logger from "../Services/loggerService";
import DownloadApp from "../components/AboutUs/DownloadApp";

const Newsroom = () => {
  const [underConstruction, setUnderConstruction] = useState(false);
  const [news, setNews] = useState([]);
  const [loading, setloading] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    setUnderConstruction(false);
    window.scrollTo(0, 0);
    getDatas();
  }, []);

  const getDatas = async () => {
    try {
      setloading(true);
      const { data } = await getNews();
      setNews(data);

      if (getCurrentUser()) {
        const { data: us } = await getUserById(
          getCurrentUser() && getCurrentUser().id
        );
        setUser(us);
      }
      setloading(false);
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        logger.log(ex.response.data);
      }
    }
  };

  const onClickNewsDeleted = async (id) => {
    try {
      const remainingNews = news.filter((n) => n.id !== id);
      setNews(remainingNews);
      await deleteNewsById(id);
    } catch (error) {
      logger.log(error);
    }
  };

  if (underConstruction)
    return (
      <>
        <Meta title={`Nawafrica | newsroom`} />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <div className="newsroomOuterCon">
      <Meta1
        title={`Nawafrica | newsroom`}
        description="Nawafrica Latest News updates"
        link="/newsroom"
      />

      <NewsHead />
      <LatestPress
        loading={loading}
        news={news}
        header="Latest Media Resources"
        home={true}
        onClickNewsDeleted={onClickNewsDeleted}
        user={user}
      />
      <DownloadApp />
    </div>
  );
};

export default Newsroom;
