import React from 'react'

const RentDetailsBody = ({ item }) => {
  return (
    <div className='rentDetailBodyCon'>
      {item.paragraphs &&
        item.paragraphs.map((x, i) => (
          <div key={`${i}`}>
            {x.subTitle && <h1>{x.subTitle}</h1>}
            <p>{x.Para}</p>
          </div>
        ))}
    </div>
  )
}

export default RentDetailsBody
