import React from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField } from '@mui/material'


import './applications.css'

const SearchApplicantTenant = ({ onChangeSearch, onChangeSort }) => {
  const { t } = useTranslation()

  return (
    <div className='listingSearchCon'>
      <div className='addlistCons'></div>

      <div className='inputFieldCon someMarginInListingSearchInput'>
        {/* <input
          className='myListingSearchInput'
          onChange={onChangeSearch}
          placeholder={t('Search your listings')}
        /> */}
               <Box sx={{ display: 'flex', alignItems: 'flex-end', width:"100%"}}>
        <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField  inputProps={{
          style: { font: "normal normal 14px/25px Merriweather"},
        }}   sx={{width:"100%"}}  onChange={onChangeSearch} id="input-with-sx" label={"Search your listings"} variant="standard" />
      </Box>
      </div>

      <div className='listingSortDropdown someMarginInListingSearchInput'>
        <p>
          <span className='sortedBySpan'>{t('Sort_By')}</span>
          <span className='listingSortSelectSpan'>
            <select onChange={onChangeSort}>
              <option value='dateposted'>{t('Date')}</option>
              <option value='listing_name'>{t('List_Name')}</option>
              <option value='list_owner_name'>{t('Landlord')}</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  )
}

export default SearchApplicantTenant
