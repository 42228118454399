import React from 'react'

const TermMainHeading = () => {
  return (
    <div style={{ width: '100%' }}>
      <h1
        style={{
          fontWeight: 'bolder',
          fontSize: '55px',
          marginTop: '70px',
          fontFamily: "'DM Serif Display', serif",
          marginBottom: '0',
        }}
      >
        Nawafrica Terms of Use
      </h1>
      <hr
        style={{
          width: '100%',
          marginTop: '15px',
          border: 'none',
          height: '2px',
          background: 'grey',
          opacity: '0.4',
        }}
      />
    </div>
  )
}

export default TermMainHeading
