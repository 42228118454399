import React from 'react'

const User = (props) => {
return (
<svg id="bold" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="m11.86 9.93h.01l4.9-1.67c.02-.09.02-.18.02-.26 0-.09-.01-.17-.04-.25-.08-.23-.23-.48-.44-.66v-2.19c0-1.62-.58-2.26-1.18-2.63-.31-.94-1.6-2.27-4.13-2.27-3 0-5.26 2.97-5.26 4.9 0 .8-.03 1.43-.06 1.91 0 .1-.01.19-.01.27-.22.2-.37.47-.44.73-.01.06-.02.12-.02.19 0 .78.44 1.91.5 2.04.06.17.19.31.36.39.01.04.02.1.02.22 0 1.06.91 2.06 1.41 2.54-.05 1.1-.36 1.86-.8 2.05l-3.92 1.3c-1.11.37-1.94 1.27-2.23 2.41l-.53 2.12c-.05.22 0 .46.14.64.14.18.36.29.59.29h11.21c-.3-.38-.58-.8-.84-1.25-.66-1.17-1.12-2.59-1.12-4.2v-4.01c0-1.18.75-2.22 1.86-2.61z"/>
<path d="m23.491 11.826-5.25-1.786c-.156-.054-.326-.054-.482 0l-5.25 1.786c-.305.103-.509.388-.509.71v4.018c0 4.904 5.474 7.288 5.707 7.387.188.079.398.079.586 0 .233-.1 5.707-2.483 5.707-7.387v-4.018c0-.322-.204-.607-.509-.71zm-2.205 3.792-2.75 3.5c-.35.447-1.006.512-1.437.142l-1.75-1.5c-.42-.359-.469-.991-.108-1.41.359-.419.991-.467 1.409-.108l.958.821 2.105-2.679c.341-.434.969-.511 1.404-.168.435.339.511.968.169 1.402z"/>
<path d=""/>
</svg>
)
}

export default User
