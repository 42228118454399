import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const AddStoryBtn = () => {
  return (
    <Link className='btn btn-light addStoryBtnLink' to='/addBlog'>
      <div
        style={{
          flex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon
          style={{
            color: '#035aa6',
            fontSize: '25px',
          }}
          icon={faPlus}
        />
      </div>
      <div style={{ flex: '5', marginLeft: '7px' }}>Add A Story</div>
    </Link>
  )
}

export default AddStoryBtn
