import React, { useContext } from "react";
import { FaAngleRight, FaCreditCard, FaUsers } from "react-icons/fa";
import Chat from "../../iconComponent/Chat";
import Feature from "../../iconComponent/Feature";
import Gear from "../../iconComponent/Gear";
import Grid from "../../iconComponent/Grid";
import PlusSymbol from "../../iconComponent/PlusSymbol";
import SidebarList from "../../iconComponent/SidebarList";
import ListingItems from "./ListingItems";
import User from "../../iconComponent/User";

import "./addListing.css";
import UnreadMsgsContext from "../../unreadmessages/context";

const OpenButton = ({ onClickOpenBar, path, chats }) => {
  const listingPath = "/portal/list/listing";
  const addListingPath = "/portal/add/add_listing";
  const applicationPath = "/portal/application/applicants";
  const messagesPath = "/portal/messages/message";
  const paymentPath = "/portal/payment";
  const tenantPath = "/portal/tenants";

  const maintanancePath = "/portal/maintenance";

  const {
    myListings: liste,
    applications,
    tenants,
    allPayments,
  } = useContext(UnreadMsgsContext);

  return (
    <div style={{ height: "550px" }} role="button" className="openButtonCon">
      <ListingItems
        href={"#"}
        icon={<Grid className="sidebarIconsClassMenu" />}
        openAngle={
          <FaAngleRight
            onClick={onClickOpenBar}
            className="sidebarMenuRightAngle"
          />
        }
        path={path}
        classes={"ListingItemConAddList"}
      />

      <hr className="sidebarHr" />

      <div className="sideBarLinks">
        <ListingItems
          icon={
            <SidebarList
              className={
                path === listingPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          href="/portal/list/listing"
          path={path}
          chats={
            liste.length === 0 ? null : liste.length > 99 ? "99+" : liste.length
          }
          classes={
            path === listingPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <PlusSymbol
              className={
                path === addListingPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          href={"/portal/add/add_listing"}
          path={path}
          classes={
            path === addListingPath
              ? "activePortalLink"
              : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <Feature
              className={
                path === applicationPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          href={"/portal/application/applicants"}
          path={path}
          chats={
            applications.length === 0
              ? null
              : applications.length > 99
              ? "99+"
              : applications.length
          }
          classes={
            path === applicationPath
              ? "activePortalLink"
              : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <Chat
              className={
                path === messagesPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          chats={chats === 0 ? null : chats > 99 ? "99+" : chats}
          path={path}
          href={"/portal/messages/message"}
          classes={
            path === messagesPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <FaUsers
              className={
                path === tenantPath
                  ? "sidebarIconLinkActive1"
                  : "sidebarIconLink1"
              }
            />
          }
          path={path}
          href={"/portal/tenants"}
          chats={
            tenants.length === 0
              ? null
              : tenants.length > 99
              ? "99+"
              : tenants.length
          }
          classes={
            path === tenantPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <User
              className={
                path === maintanancePath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          path={path}
          href={"/portal/maintenance"}
          classes={
            path === maintanancePath
              ? "activePortalLink"
              : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <FaCreditCard
              className={
                path === paymentPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          path={path}
          href={"/portal/payment"}
          chats={
            allPayments.length === 0
              ? null
              : allPayments.length > 99
              ? "99+"
              : allPayments.length
          }
          classes={
            path === paymentPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={<Gear className="sidebarIconLink" />}
          path={path}
          classes={"ListingItemConAddList"}
        />
      </div>
    </div>
  );
};

export default OpenButton;
