import React, { useEffect, useState, useContext } from "react";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import _ from "lodash";
import io from "socket.io-client";
import { saveAs } from "file-saver";
import ScrollToTop from "react-scroll-to-top";
import cookies from "js-cookie";

import {
  getMessageById,
  updateGivenMessageById,
  getAllMyUnreadMsgId,
  deleteGivenMessageById,
} from "./../Services/messageService";
import Chats from "../components/AddListing/Chats";
import BackButton from "../components/MessageDetail/BackButton";
import TopDeleteReply from "../components/MessageDetail/TopDeleteReply";
import MessageSubject from "../components/MessageDetail/MessageSubject";
import SenderDate from "../components/MessageDetail/SenderDate";
import Msg from "../components/MessageDetail/Msg";
import MessageImages from "../components/MessageDetail/MessageImages";
import BottomDeleteReply from "../components/MessageDetail/BottomDeleteReply";
import InboxModal from "../components/Messages/InboxModal";
import UnreadMessages from "../components/MessageDetail/UnreadMessages";
import Reply from "../components/MessageDetail/Reply";
import { getCurrentUser } from "../Services/authService";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import MessagePicModal from "./../components/MessageDetail/MessagePicModal";
import SideBarManagement from "./../components/ManageTenants/SideBarManagement";
import OpenButtonManagement from "../components/ManageTenants/OpenButtonManagement";
import UnreadMsgsContext from "./../unreadmessages/context";
import Meta from "./../Utils/Meta";
import { getLang } from "../Utils/getLang";

import "../components/MessageDetail/messageDetail.css";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import translateLang from "./../Utils/translationLang";
import { trackEvent } from "../Utils/useGAEventTracker";
import { uploadSingleFileToCloudinary } from "../Utils/imageUploadToCloudinary";
import { getCloudinarySignature } from "../Services/cloudinaryService";
import logger from "../Services/loggerService";

const ManagementMessageDet = ({ match, history }) => {
  const [close, setClose] = useState(true);
  const [path, setPath] = useState("");
  const [socket, setSocket] = useState();
  const [upSideBar, setUpSideBar] = useState(false);

  const [message, setMessage] = useState({});
  const [allSenderUnreadMsg, setAllSenderUnreadMsg] = useState([]);

  const [subjectReply, setSubjectReply] = useState("");
  const [messageReply, setMessageReply] = useState("");

  const [otherPhotoError, setOtherPhotoError] = useState("");
  const [loadOtherPhoto, setLoadOtherPhoto] = useState(false);
  const [otherPhotoPath, setOtherPhotoPath] = useState([]);
  const [submitError, setSubmitError] = useState("");

  const [loading, setLoading] = useState(true);

  const [selectedImage, setSelectedImage] = useState("");

  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [lang, setLang] = useState("");
  const [subj, setSubj] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [myImageData, setMyImageData] = useState({
    cloud_name: "",
  });
  const [YOUR_API_KEY, setYOUR_API_KEY] = useState();

  const { unreadMsgs } = useContext(UnreadMsgsContext);

  useEffect(() => {
    getMessageData();
    localStorage.setItem("managementPath", "/management/message");
    const myPath = localStorage.getItem("managementPath");
    setPath(myPath);

    const sock = io();
    setSocket(sock);

    sock.on(
      `${getCurrentUser() && getCurrentUser().id}unreadMsgTenant`,
      (data) => {
        const sortedListing = _.orderBy(
          data,
          [sortColumn.path],
          [sortColumn.order]
        );
        setAllSenderUnreadMsg(sortedListing);
      }
    );

    return () => {
      localStorage.setItem("managementPath", "");
    };
  }, []);

  //function to get all datas for a given message
  const getMessageData = async () => {
    try {
      setLoading(true);
      const message_id = match.params.id;
      await updateGivenMessageById(message_id);
      const { data: msg } = await getMessageById(message_id);
      setSubj(msg.subject);
      setMessageBody(msg.body);
      setMessage(msg);
      const { data: unread } = await getAllMyUnreadMsgId(msg.sender_id);
      setAllSenderUnreadMsg(unread);
      setLoading(false);

      const { data: imagesData } = await getCloudinarySignature();
      setMyImageData(imagesData);

      //function use to translation the description
      const currentLanguageCode = cookies.get("i18next") || "en";

      const initLang = currentLanguageCode;
      let fromLang = localStorage.getItem(`initialLang`);
      let toLang = initLang;
      let text = `${msg.body}`;

      const translationResult = translateLang(
        YOUR_API_KEY,
        text,
        fromLang,
        toLang
      );
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        history.push("/notFound");
        logger.log(
          "the error in getMessageData function in ManageMessageDet component",
          ex.response.data
        );
      }
    }
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to open the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to delete the message
  const onDelete = () => {
    deleteGivenMessageById(message.id);
    trackEvent(
      `${getCurrentUser() && getCurrentUser().id} delete a message`,
      `message is ${message.id}`,
      "delete message in message detail"
    );
    window.location = "/management/message";
  };

  //function to delete a given unread message
  const onDeleteUnread = async (message) => {
    try {
      const allMessages = [...allSenderUnreadMsg];
      const theRemainingMessage = allMessages.filter(
        (mess) => mess.id !== message.id
      );
      setAllSenderUnreadMsg(theRemainingMessage);
      trackEvent(
        `${getCurrentUser() && getCurrentUser().id} delete unread message`,
        `message is ${message.id}`,
        "delete unread message"
      );
      await deleteGivenMessageById(message.id);
    } catch (error) {
      logger.log(
        "error in onDeleteUnread function in ManageMessageDet component",
        error
      );
    }
  };

  //function to upload multiple files when replying the message using multer in backend
  const onChangeOtherPhoto = async (e) => {
    e.preventDefault();
    const filesss = e.currentTarget.files[0];
    if (!filesss) return;
    if (filesss.size > 8323863) {
      setOtherPhotoError(
        "large file, upload a smaller file not greater than 8MB"
      );
    } else {
      setLoadOtherPhoto(true);
      setOtherPhotoError("");

      if (filesss.type.startsWith("image/")) {
        let src = URL.createObjectURL(filesss);

        //convert to canvas
        const canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        let userImage = new Image();
        userImage.src = src;
        userImage.onload = () => {
          canvas.width = userImage.naturalWidth;
          canvas.height = userImage.naturalHeight;
          ctx.drawImage(userImage, 0, 0);

          canvas.toBlob(async (blob) => {
            const myImage = new File([blob], filesss.name, { type: blob.type });

            const result = await uploadSingleFileToCloudinary(
              myImage,
              myImageData
            );

            if (
              !result ||
              !result.url ||
              (result && result.path && result.path["error"])
            ) {
              setOtherPhotoError(
                "The was an error loading your image, please reload this image"
              );
              setLoadOtherPhoto(false);
              return;
            }

            setOtherPhotoPath([...otherPhotoPath, { ...result }]);
            setLoadOtherPhoto(false);
          }, "image/webp");
        };
      } else {
        const result = await uploadSingleFileToCloudinary(filesss, myImageData);
        setOtherPhotoPath([...otherPhotoPath, { ...result }]);
        setLoadOtherPhoto(false);
      }
    }
  };

  //function use to attach a selected emoji to the message
  const onEmojiClick = (event, emojiObject) => {
    const myEmojiMessage = messageReply + emojiObject.emoji;
    setMessageReply(myEmojiMessage);
  };

  //funtion to submit the replied message
  const onSubmitMessage = (e) => {
    e.preventDefault();
    const myData = {
      message: messageReply,
      subject: subjectReply,
      recipient_id: message.sender_id,
      recipient_name: message.sender_name,
      images: otherPhotoPath,
      listing_id: message.listing_id,
      listing_name: message.listing_name,
      listing_address: message.listing_address,
      sender: getCurrentUser().id,
      reply: {
        subject: message.subject,
        body: message.body,
      },
    };

    socket.emit("message", myData, (error) => {
      if (error) {
        setSubmitError(error);
      } else {
        alert("MESSAGE SENT");
        trackEvent(
          `${getCurrentUser() && getCurrentUser().id} sent a message`,
          myData.message,
          "send message btn"
        );
      }
    });

    if (messageReply && subjectReply) {
      setMessageReply("");
      setOtherPhotoPath([]);
      setSubjectReply("");
      setSubmitError("");
      const myVideoToPlay = document.getElementById("myVideoPlay");
      myVideoToPlay.play();
    }
  };

  //function to download send images and files by the landlord
  const onDownload = async (urls) => {
    const URL = urls.slice(15, urls.length);
    await axios
      .get(`/messages/download/${URL}`, { responseType: "blob" })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, `${URL}`);
      })
      .catch((err) => {
        logger.log(
          "this is the error from onDownload function in ManageMessageDet component",
          err
        );
      });
  };

  //function to enlarge a given image
  const showLargeImage = async (url) => {
    setSelectedImage(url);
    trackEvent("view large image", url, "show large image btn");
  };

  //function to delete a picture you dont want to send when replying the message
  const ondeletePicture = (pic) => {
    const images = [...otherPhotoPath];
    const myImages = images.filter((img) => img !== pic);
    setOtherPhotoPath(myImages);
  };

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  const onChangeLang = (lang) => {
    const currentLanguageCode = cookies.get("i18next") || "en";

    let fromLang = currentLanguageCode;
    let toLang = lang;
    let text = `${message.subject}`;

    const API_KEY = [YOUR_API_KEY];

    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += "&q=" + encodeURI(text);
    url += `&source=${fromLang}`;
    url += `&target=${toLang}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        logger.log(response);
        //     setSubj(response)
      })
      .catch((error) => {
        logger.log(error);
      });

    setLang(toLang);

    onchangeMessageLang(toLang);
  };

  const onchangeMessageLang = (toLangs) => {
    const currentLanguageCode = cookies.get("i18next") || "en";

    let fromLang = currentLanguageCode;
    let toLang = toLangs;
    let text = `${messageBody}`;

    const API_KEY = [YOUR_API_KEY];

    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += "&q=" + encodeURI(text);
    url += `&source=${fromLang}`;
    url += `&target=${toLang}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        logger.log(response);
        //     setMessageBody(response)
      })
      .catch((error) => {
        logger.log(error);
      });

    setLang(toLang);
  };

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
      </div>
    );
  return (
    <>
      <div>
        <audio
          style={{ display: "none" }}
          id="myVideoPlay"
          src="/images/messageSound.mp3"
        ></audio>
      </div>

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      <Meta title={`${getLang()}.Nawafrica | ${message.subject}`} />

      {close && !upSideBar && (
        <SideBarManagement
          onClickCloseBar={onClickCloseBar}
          path={path}
          onTakeBarUp={onTakeBarUp}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButtonManagement
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <MessagePicModal url={selectedImage} />

      <InboxModal
        onChangeSubject={(e) => {
          e.preventDefault();
          const { value } = e.target;
          setSubjectReply(value);
        }}
        onChangeMessage={(e) => {
          e.preventDefault();
          const { value } = e.target;
          setMessageReply(value);
        }}
        onChangeImages={onChangeOtherPhoto}
        otherPhotoPath={otherPhotoPath}
        onEmojiClick={onEmojiClick}
        loadOtherPhoto={loadOtherPhoto}
        message={messageReply}
        onSubmitMessage={onSubmitMessage}
        valueSubject={subjectReply}
        submitError={submitError}
        ondeletePicture={ondeletePicture}
      />

      <div className="backBtnChatsContainerMessageDet">
        <BackButton back="/management/message/" />
        <TopDeleteReply onDelete={onDelete} />

        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      </div>

      <div className="outerContainerSubjectBodyMessageDetail">
        <div
          className={
            close
              ? "innerContainerSubjectBodyMessageDetCon"
              : "innerContainerSubjectBodyMessageDetCon1"
          }
        >
          <MessageSubject
            onChangeLang={onChangeLang}
            subject={subj}
            lang={lang}
            close={close}
          />

          <div className="sendingDateOuterConMessageDet">
            <SenderDate message={message} />
          </div>

          <div className="messageBodyOuterConMessageDet">
            <Msg message={messageBody} />
          </div>

          {message.images && message.images.length > 0 ? (
            <div className="allSentImageOuterConMessageDet">
              {message.images.map((img) => (
                <React.Fragment key={img.public_id}>
                  <MessageImages
                    img={img}
                    onDownload={onDownload}
                    showLargeImage={showLargeImage}
                  />
                </React.Fragment>
              ))}
              <ReactTooltip effect="solid" />
            </div>
          ) : (
            ""
          )}

          <div style={{ width: "100%" }}>
            <BottomDeleteReply onDelete={onDelete} />
          </div>
          <ReactTooltip effect="solid" />
          {message.reply && <Reply message={message} />}
        </div>
      </div>

      {allSenderUnreadMsg.length > 0 && (
        <UnreadMessages
          message={message}
          senderUnreadMsg={allSenderUnreadMsg}
          onDeleteUnread={onDeleteUnread}
          close={close}
        />
      )}

      <ScrollToTop smooth />

      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default ManagementMessageDet;
