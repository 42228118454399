import React from 'react'

const Management = (props) => {
return (
<svg id="Layer_1" enableBackground="new 0 0 510.01 510.01" height="512" viewBox="0 0 510.01 510.01" width="512" xmlns="http://www.w3.org/2000/svg" {...props}>
                    <g>
                    <path d="m468.645 105 29.246-29.246-63.64-63.64-29.246 29.247v-41.361c-10.889 0-79.04 0-90 0v41.361l-29.246-29.247-63.64 63.64 29.246 29.246h-41.36v90h41.36l-29.247 29.246 63.64 63.64 29.246-29.246v41.36h90v-41.36l29.246 29.246 63.64-63.64-29.245-29.246h41.36v-90zm-108.64 90c-24.814 0-45-20.188-45-45 2.472-59.699 87.537-59.681 90 0 0 24.812-20.187 45-45 45z"/>
                    <path d="m.005 330.01h90v180h-90z"/>
                    <path d="m465.776 357.666-15.733 8.415c-95.685 50.745-85.426 45.485-89.228 47.177-5.304 2.36-15.175 6.752-30.778 6.752-26.158 0-49.467 0-75.031 0v-30h45.031c19.902-.829 19.82-29.196-.031-30h-62.959c-14.719 0-28.973 4.315-41.221 12.481-34.119 22.745-65.564 16.459-75.82 17.519v90c10.938 1.006 33.944-4.344 59.18 12.48 17.191 11.462 37.199 17.52 57.861 17.52h53.931c18.339 0 36.418-4.858 52.288-14.051l127.822-72.725 23.254-12.682c9.656-5.267 15.664-15.387 15.664-26.385v-.001c-.001-22.703-24.209-37.208-44.23-26.5z"/>
                    </g>
 </svg>

)
}

export default Management
