import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './addListing.css'

const SaveButton = ({ onClick, loadingProgress }) => {
  const { t } = useTranslation()
  return (
    <div className='saveButtonCon'>
      <button disabled={loadingProgress} type='submit' onClick={onClick}>
        <span style={{ marginRight: '5px' }}>
          <FontAwesomeIcon icon={faSave} />
        </span>
        {t('SAVE')}
        {/* POST */}
      </button>
    </div>
  )
}

export default SaveButton
