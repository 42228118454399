import React from 'react'
import Lottie from 'react-lottie'
import { likeListingAnimate } from './like_listing'

const LikeListing = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: likeListingAnimate(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={60} width={60} />
    </div>
  )
}

export default LikeListing
