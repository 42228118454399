import React from 'react'

const DescriptionContainer = ({ data, placeholder }) => {
  return (
    <div
      style={{
        width: '80%',
        marginLeft: '10%',
        marginTop: '20px',
        opacity: '1',
        borderRadius: '5px',
        background: '#f5f5f5 0% 0% no-repeat padding-box',
        padding: '15px',
        textAlign: 'center',
        //         border: '1px solid #035aa6',
        font: 'normal normal normal 12px/24px Merriweather',
        color: '#035aa6',
      }}
    >
      {data ? data : <span style={{ color: 'gray' }}>{placeholder}</span>}
    </div>
  )
}

export default DescriptionContainer
