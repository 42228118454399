import React from 'react'
import { FaReply } from 'react-icons/fa'
import { FaTrash } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'

import './messageDetail.css'
import { useTranslation } from 'react-i18next'

const TopDeleteReply = ({ onDelete }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className='topDeleteReplyOuterCon'>
        <div className='innerContainer'>
          <div data-toggle='modal' data-target='#exampleModalInbox'>
            <FaReply data-tip={t('reply')} className='theReplyIconInMessageDet' />
          </div>
          <FaTrash
            className='theDeleteIconInMessageDet'
            onClick={onDelete}
            data-tip={t('delete')}
          />
        </div>
      </div>
      <ReactTooltip effect='solid' />
    </>
  )
}

export default TopDeleteReply
