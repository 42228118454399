import React from "react";
import { useTranslation } from "react-i18next";
import "./styleCompo.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const PriceInput = ({
  onChangePrice,
  items = [],
  value,
  onChangeCurrency,
  width = "100%",
  type = "number",
  price = true,
}) => {
  const { t } = useTranslation();

  const styles = (theme) => ({
    textField: {
      width: "90%",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: 0,
      marginTop: 0,
      fontWeight: 500,
    },
    input: {
      color: "white",
    },
  });

  return (
    <div style={{ width }} className="priceCompoCon">
      <TextField
        onChange={onChangePrice}
        id="outlined-password-input"
        label={
          price
            ? `${t("Maximum price")} ${
                items.length > 0 ? `in ${items[0]}` : ""
              }`
            : t("Listing Title")
        }
        style={{ width: "100%" }}
        type={type}
        autoComplete="current-password"
        value={value}
        variant="filled"
        // inputProps are used to pass attributes native to the underlying
        // HTML input element, e.g. name, id, style.
        inputProps={{
          style: {
            height: "15px",
            backgroundColor: "#ffffff 0% 0% no-repeat padding-box",
            font: "normal normal 300 18px/25px Merriweather",
          },
        }}
        // InputProps (capital I) passes props to the wrapper Material
        // component. Can be  one of the following: Input, FilledInput,
        // OutlinedInput. You can pass here anything that the underlying
        // Material component uses: error, value, onChange, and classes.
        InputProps={{
          // Usually you don't need className, the `classes` object will
          // be sufficient. However, you can also use it and this will
          // add your class to the div of the InputBase.
          className: styles.slider_filter_input,
          classes: {
            // The list of keys you pass here depend on your variant
            // If for example you used variant="outlined", then you need
            // to check the CSS API of the OutlinedInput.
          },
        }}
      />
      {/* <select
        onChange={onChangeCurrency}
        className={`selectInput form-control`}
      >
        <option value=''>{items.length>0?items[0]:('currency')}</option>

        {items.map((item) => (
          <option value={item}>{item}</option>
        ))}
      </select>
      <input
        type='number'
        value={value}
        onChange={onChangePrice}
        className={`form-control`}
        placeholder={t('Maximum price')}
      /> */}
    </div>
  );
};

export default PriceInput;
