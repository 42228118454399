import React from 'react'

const ArticleText = ({ title, id, placeholder, onChange, value }) => {
  return (
    <div className='rentSaleAddTextarea'>
      <label style={{ opacity: '1' }} htmlFor={id}>
        {title}
      </label>
      <textarea
        onChange={onChange}
        placeholder={placeholder}
        id={id}
        className='form-control'
        cols='15'
        rows='15'
        value={value}
      />
    </div>
  )
}

export default ArticleText
