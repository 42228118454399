export function doneAnimate() {
  return {
    nm: 'avaliacao_camadas',
    mn: '',
    layers: [
      {
        ty: 4,
        nm: 'Camada de forma 8',
        mn: '',
        sr: 1,
        st: 0,
        op: 58.0000023623884,
        ip: 47.0000019143492,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [-145, -37, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0, 100, 100],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102.976, 100, 100],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-0.012, 100, 100],
                t: 58.0000023623884,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [217, 175.5, 0],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [217, 175.5, 0],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [217, 90.5, 0],
                t: 58.0000023623884,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 92.111, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Retângulo 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'rc',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Rect',
                nm: 'Caminho do retângulo 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 20, ix: 4 },
                s: { a: 0, k: [89, 13], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Traçado 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-184.5, -37.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 0,
      },
      {
        ty: 4,
        nm: 'Camada de forma 7',
        mn: '',
        sr: 1,
        st: 0,
        op: 58.0000023623884,
        ip: 47.0000019143492,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [-145, -37, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0, 100, 100],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102.976, 100, 100],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-0.012, 100, 100],
                t: 58.0000023623884,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [164, 210, 0],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [164, 210, 0],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102, 160, 0],
                t: 58.0000023623884,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 34.499, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Retângulo 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'rc',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Rect',
                nm: 'Caminho do retângulo 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 20, ix: 4 },
                s: { a: 0, k: [89, 13], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Traçado 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-184.5, -37.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'Camada de forma 6',
        mn: '',
        sr: 1,
        st: 0,
        op: 58.0000023623884,
        ip: 47.0000019143492,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [-145, -37, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0, 100, 100],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102.976, 100, 100],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-0.012, 100, 100],
                t: 58.0000023623884,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [177, 298.5, 0],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [177, 298.5, 0],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [81, 349.5, 0],
                t: 58.0000023623884,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: -26.142, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Retângulo 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'rc',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Rect',
                nm: 'Caminho do retângulo 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 20, ix: 4 },
                s: { a: 0, k: [89, 13], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Traçado 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-184.5, -37.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
      },
      {
        ty: 4,
        nm: 'Camada de forma 5',
        mn: '',
        sr: 1,
        st: 0,
        op: 58.0000023623884,
        ip: 47.0000019143492,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [-145, -37, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0, 100, 100],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102.976, 100, 100],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-0.012, 100, 100],
                t: 58.0000023623884,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [287.5, 306, 0],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [287.5, 306, 0],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [356.5, 365, 0],
                t: 58.0000023623884,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: -145.138, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Retângulo 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'rc',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Rect',
                nm: 'Caminho do retângulo 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 20, ix: 4 },
                s: { a: 0, k: [89, 13], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Traçado 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-184.5, -37.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 3,
      },
      {
        ty: 4,
        nm: 'Camada de forma 4',
        mn: '',
        sr: 1,
        st: 0,
        op: 58.0000023623884,
        ip: 47.0000019143492,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [-145, -37, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0, 100, 100],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102.976, 100, 100],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-0.012, 100, 100],
                t: 58.0000023623884,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [307, 246, 0],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [307, 246, 0],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [407, 246, 0],
                t: 58.0000023623884,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 180.248, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Retângulo 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'rc',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Rect',
                nm: 'Caminho do retângulo 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 20, ix: 4 },
                s: { a: 0, k: [89, 13], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Traçado 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-184.5, -37.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 4,
      },
      {
        ty: 4,
        nm: 'Camada de forma 3',
        mn: '',
        sr: 1,
        st: 0,
        op: 58.0000023623884,
        ip: 47.0000019143492,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [-145, -37, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0, 100, 100],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102.976, 100, 100],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-0.012, 100, 100],
                t: 58.0000023623884,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [319, 194.5, 0],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [319, 194.5, 0],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [397, 156.5, 0],
                t: 58.0000023623884,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: -206.269, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Retângulo 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'rc',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Rect',
                nm: 'Caminho do retângulo 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 20, ix: 4 },
                s: { a: 0, k: [89, 13], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Traçado 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-184.5, -37.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 5,
      },
      {
        ty: 4,
        nm: 'Camada de forma 2',
        mn: '',
        sr: 1,
        st: 0,
        op: 58.0000023623884,
        ip: 47.0000019143492,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [-145, -37, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0, 100, 100],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102.976, 100, 100],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-0.012, 100, 100],
                t: 58.0000023623884,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [225.5, 325, 0],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [225.5, 325, 0],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [225.5, 420, 0],
                t: 58.0000023623884,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: -92.181, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Retângulo 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'rc',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Rect',
                nm: 'Caminho do retângulo 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 20, ix: 4 },
                s: { a: 0, k: [89, 13], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Traçado 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-184.5, -37.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 6,
      },
      {
        ty: 4,
        nm: 'Camada de forma 1',
        mn: '',
        sr: 1,
        st: 0,
        op: 58.0000023623884,
        ip: 47.0000019143492,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [-145, -37, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0, 100, 100],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [102.976, 100, 100],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-0.012, 100, 100],
                t: 58.0000023623884,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [158, 250, 0],
                t: 47,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [158, 250, 0],
                t: 52,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [66, 250, 0],
                t: 58.0000023623884,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Retângulo 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'rc',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Rect',
                nm: 'Caminho do retângulo 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                r: { a: 0, k: 20, ix: 4 },
                s: { a: 0, k: [89, 13], ix: 2 },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Traçado 1',
                lc: 1,
                lj: 1,
                ml: 4,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 1, 1], ix: 3 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-184.5, -37.5], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 7,
      },
      {
        ty: 4,
        nm: 'lapis_avaliacao contornos',
        mn: '',
        sr: 1,
        st: 2.00000008146167,
        op: 90.0000036657751,
        ip: 9.00000036657752,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [306, 396, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [0.382, 0.382, 100],
                t: 9,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [112.373, 106.191, 100],
                t: 14,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [75.26, 87.542, 100],
                t: 17,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [94.771, 94.771, 100],
                t: 20,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [94.771, 94.771, 100],
                t: 30,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [94.771, 94.771, 100],
                t: 72,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [94.771, 94.771, 100],
                t: 82.0000033399285,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [259, 305, 0],
                t: 9,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [331, 403, 0],
                t: 14,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 0.667 },
                s: [317, 386, 0],
                t: 17,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 0.667 },
                s: [317, 386, 0],
                t: 20,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [317, 386, 0],
                t: 30,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [195, 169, 0],
                t: 36,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [218, 202, 0],
                t: 43,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [366, 108, 0],
                t: 50,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [361, 122, 0],
                t: 57,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [317, 406.5, 0],
                t: 72,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [317, 386, 0],
                t: 82.0000033399285,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [46.158],
                t: 9,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [-26.553],
                t: 14,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [15.068],
                t: 17,
              },
              { o: { x: 0.167, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 20 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [0],
                t: 30,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [-26.04],
                t: 36,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [-31.892],
                t: 43,
              },
              {
                o: { x: 0.181, y: -0.011 },
                i: { x: 0.667, y: 1 },
                s: [5.008],
                t: 50,
              },
              {
                o: { x: 0.346, y: 0.022 },
                i: { x: 0.667, y: 1 },
                s: [18.401],
                t: 58,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [-6.103],
                t: 72,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 82.0000033399285,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [1.911, 0],
                      [1.469, 1.468],
                      [-2.947, 2.947],
                      [0, 0],
                      [-2.937, -2.985],
                      [2.947, -2.947],
                      [0, 0],
                    ],
                    o: [
                      [-1.912, 0],
                      [-2.947, -2.937],
                      [0, 0],
                      [2.947, -2.985],
                      [2.947, 2.937],
                      [0, 0],
                      [-1.468, 1.468],
                    ],
                    v: [
                      [-8.313, 16.221],
                      [-13.642, 14.01],
                      [-13.642, 3.38],
                      [2.975, -13.236],
                      [13.642, -13.236],
                      [13.642, -2.607],
                      [-2.985, 14.01],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.3686, 0.3686, 0.3686], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [272.743, 454.782], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 2',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-32.438, 55.052],
                      [-55.053, 32.401],
                      [32.402, -55.052],
                      [55.053, -32.439],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.8824, 0.549, 0.1451], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [360.822, 387.076], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 3',
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [5.847, 5.809],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [5.847, -5.846],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-5.075, 30.08],
                      [21.879, 3.125],
                      [21.879, -18.02],
                      [9.82, -30.08],
                      [-27.726, 7.466],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.6392, 0.2784, 0.251], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [420.95, 324.558], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 4',
            ix: 4,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [29.275, 3.347],
                      [29.313, 3.272],
                      [6.698, -19.342],
                      [-28.314, 15.671],
                      [-29.313, 19.342],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.8392, 0.7686, 0.6941], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [299.071, 438.82], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 5',
            ix: 5,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [33.743, -53.711],
                      [53.712, -33.78],
                      [-33.743, 53.711],
                      [-53.711, 33.743],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.949, 0.6118, 0.1294], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [339.512, 365.766], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 6',
            ix: 6,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [5.846, -5.856],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-5.847, -5.856],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [28.758, -11.176],
                      [19.38, -20.553],
                      [-1.766, -20.553],
                      [-28.758, 6.439],
                      [-8.789, 26.408],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.8431, 0.3529, 0.2902], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [402.012, 305.615], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 7',
            ix: 7,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-2.462, -27.491],
                      [-2.537, -27.415],
                      [-17.506, 27.491],
                      [17.506, -7.522],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.9294, 0.8627, 0.7804], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [288.263, 426.999], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 8,
      },
      {
        ty: 4,
        nm: 'Camada 2 contornos',
        mn: '',
        sr: 1,
        st: 0,
        op: 90.0000036657751,
        ip: 36.0000014663101,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [64.3, 52.75, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 36,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [117.174, 103.81, 100],
                t: 50,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 55.0000022401959,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [237.85, 236.4, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [8.836],
                t: 36,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 50.0000020365418,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.667, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-55.2, 7.5],
                            [-54.8, 1.649],
                            [-43.45, -11.7],
                            [-44.45, -10.2],
                            [-43.05, -11.5],
                            [-52.7, 6.6],
                          ],
                        },
                      ],
                      t: 36,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.667, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-20.45, 52.5],
                            [-64.05, 8.899],
                            [-43.45, -11.7],
                            [-20.45, 11.3],
                            [-19.05, 10],
                            [-17.95, 51.1],
                          ],
                        },
                      ],
                      t: 43,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [-20.45, 52.5],
                            [-64.05, 8.899],
                            [-43.45, -11.7],
                            [-20.45, 11.3],
                            [43.45, -52.5],
                            [64.05, -31.9],
                          ],
                        },
                      ],
                      t: 50.0000020365418,
                    },
                  ],
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [64.3, 52.75], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 9,
      },
      {
        ty: 4,
        nm: 'Camada 4 contornos',
        mn: '',
        sr: 1,
        st: -1.00000004073083,
        op: 90.0000036657751,
        ip: 10.0000004073083,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [57.8, 35.6, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [115.639, -1.839, 100],
                t: 10,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [89.612, 115.052, 100],
                t: 15,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [110.426, 85.856, 100],
                t: 19,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 22,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 30.0000012219251,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [245.05, 80.95, 0],
                t: 10,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 110.95, 0],
                t: 15,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 89.95, 0],
                t: 19,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 0.667 },
                s: [226.05, 100.95, 0],
                t: 22,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [226.05, 100.95, 0],
                t: 30,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [214.05, 102.95, 0],
                t: 45,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [230.05, 100.95, 0],
                t: 59,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [226.05, 100.95, 0],
                t: 64.0000026067734,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [4.362],
                t: 10,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [-4.859],
                t: 15,
              },
              { o: { x: 0.167, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 19 },
              { o: { x: 0.167, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 22 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 30,
              },
              {
                o: { x: 0.17, y: 0.043 },
                i: { x: 0.615, y: 0.934 },
                s: [-3.529],
                t: 45,
              },
              {
                o: { x: 0.351, y: -0.078 },
                i: { x: 0.69, y: 1 },
                s: [1.351],
                t: 59,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 64.0000026067734,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [11.5, 0],
                      [0, -11.5],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, -11.5],
                      [-11.5, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [20.75, -7.25],
                      [20.75, -14.55],
                      [-0.05, -35.35],
                      [-20.85, -14.55],
                      [-20.85, -7.25],
                      [-57.55, -7.25],
                      [-57.55, 35.35],
                      [57.55, 35.35],
                      [57.55, -7.25],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.6275, 0.6275, 0.6275], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [57.8, 35.6], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 10,
      },
      {
        ty: 4,
        nm: 'Camada 3 contornos 2',
        mn: '',
        sr: 1,
        st: -8.00000032584668,
        op: 91.000003706506,
        ip: 12.00000048877,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [106.35, 131.65, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [100, 0.304, 100],
                t: 12,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [91.517, 117.504, 100],
                t: 17,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [106.598, 95.434, 100],
                t: 21,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 25,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 31.0000012626559,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 108.7, 0],
                t: 12,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 269.7, 0],
                t: 17,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 243.7, 0],
                t: 21,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 0.667 },
                s: [226.05, 251.7, 0],
                t: 25,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [226.05, 251.7, 0],
                t: 31.0000012626559,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 31,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-3.529],
                t: 46,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0.916],
                t: 60,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 65.0000026475043,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 1',
            ix: 1,
            cix: 2,
            np: 4,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-47.55, 47.65],
                      [47.65, 47.65],
                      [47.65, -47.55],
                      [-47.55, -47.55],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 2',
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [59.25, 59.25],
                      [-59.25, 59.25],
                      [-59.25, -59.25],
                      [59.25, -59.25],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'mm',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Filter - Merge',
                nm: 'Mesclar caminhos 1',
                mm: 1,
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.902, 0.9137, 0.9333], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [89.7, 133.3], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 2',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-18.5, 18.449],
                      [18.5, -18.45],
                      [-18.5, -18.45],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.902, 0.9137, 0.9333], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [193.95, 244.6], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 3',
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-106.1, -131.4],
                      [-106.1, 131.4],
                      [69.1, 131.4],
                      [69.1, 94.5],
                      [106.1, 94.5],
                      [106.1, -131.4],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [106.35, 131.65], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 11,
      },
      {
        ty: 4,
        nm: 'Camada 3 contornos',
        mn: '',
        sr: 1,
        st: -9.00000036657752,
        op: 90.0000036657752,
        ip: 11.0000004480392,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [106.35, 131.65, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [100, 0.304, 100],
                t: 11,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [91.517, 117.504, 100],
                t: 16,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [106.598, 95.434, 100],
                t: 20,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 24,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 30.0000012219251,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 108.7, 0],
                t: 11,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 269.7, 0],
                t: 16,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 243.7, 0],
                t: 20,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 0.667 },
                s: [226.05, 251.7, 0],
                t: 24,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [226.05, 251.7, 0],
                t: 30.0000012219251,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 30,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-3.529],
                t: 45,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0.916],
                t: 59,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 64.0000026067734,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 2',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-18.5, 18.449],
                      [18.5, -18.45],
                      [-18.5, -18.45],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.6902, 0.9373, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [193.95, 244.6], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 3',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-106.1, -131.4],
                      [-106.1, 131.4],
                      [69.1, 131.4],
                      [69.1, 94.5],
                      [106.1, 94.5],
                      [106.1, -131.4],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.6902, 0.9373, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [106.35, 131.65], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 12,
      },
      {
        ty: 4,
        nm: 'Camada 3 contornos 3',
        mn: '',
        sr: 1,
        st: -10.0000004073083,
        op: 89.0000036250443,
        ip: 10.0000004073083,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [106.35, 131.65, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [100, 0.304, 100],
                t: 10,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [91.517, 117.504, 100],
                t: 15,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [106.598, 95.434, 100],
                t: 19,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 23,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 29.0000011811942,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 108.7, 0],
                t: 10,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 269.7, 0],
                t: 15,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [226.05, 243.7, 0],
                t: 19,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 0.667 },
                s: [226.05, 251.7, 0],
                t: 23,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [226.05, 251.7, 0],
                t: 29.0000011811942,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 29,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [-3.529],
                t: 44,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0.916],
                t: 58,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 63.0000025660426,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 2',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-18.5, 18.449],
                      [18.5, -18.45],
                      [-18.5, -18.45],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.1686, 0.8353, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [193.95, 244.6], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 3',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-106.1, -131.4],
                      [-106.1, 131.4],
                      [69.1, 131.4],
                      [69.1, 94.5],
                      [106.1, 94.5],
                      [106.1, -131.4],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.1686, 0.8353, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [106.35, 131.65], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 13,
      },
      {
        ty: 4,
        nm: 'Layer 1 contornos',
        mn: '',
        sr: 1,
        st: 2.00000008146167,
        op: 92.0000037472368,
        ip: 2.00000008146167,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [132.4, 166.4, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [0, 0, 100],
                t: 2,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [102.242, 113.009, 100],
                t: 13,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [100.787, 89.379, 100],
                t: 19,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 24,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 32.0000013033867,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [226.05, 267.45, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [0],
                t: 2,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [364],
                t: 13,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [359],
                t: 19,
              },
              { o: { x: 0.167, y: 0 }, i: { x: 0.667, y: 1 }, s: [360], t: 24 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [360],
                t: 32,
              },
              {
                o: { x: 0.17, y: 0.043 },
                i: { x: 0.615, y: 0.934 },
                s: [356.471],
                t: 47,
              },
              {
                o: { x: 0.351, y: -0.078 },
                i: { x: 0.69, y: 1 },
                s: [361.351],
                t: 61,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [360],
                t: 66.0000026882351,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.6, 0],
                      [0, 0],
                      [0, 2.599],
                      [0, 0],
                      [-2.6, 0],
                      [0, 0],
                      [0, -2.6],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-2.6, 0],
                      [0, 0],
                      [0, -2.6],
                      [0, 0],
                      [2.599, 0],
                      [0, 0],
                      [-0.101, 2.5],
                    ],
                    v: [
                      [127.35, 166.15],
                      [-127.45, 166.15],
                      [-132.15, 161.451],
                      [-132.15, -161.45],
                      [-127.45, -166.15],
                      [127.45, -166.15],
                      [132.15, -161.45],
                      [132.15, 161.55],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.1961, 0.2902, 0.3686], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [132.4, 166.4], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 14,
      },
      {
        ty: 4,
        nm: 'Layer 1 contornos 3',
        mn: '',
        sr: 1,
        st: 1.00000004073083,
        op: 28.0000011404634,
        ip: 1.00000004073083,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [132.4, 166.4, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [0, 0, 100],
                t: 1,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [102.242, 113.009, 100],
                t: 12,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [100.787, 89.379, 100],
                t: 18,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 23,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 31.0000012626559,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [226.05, 267.45, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [0],
                t: 1,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [364],
                t: 12,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [359],
                t: 18,
              },
              { o: { x: 0.167, y: 0 }, i: { x: 0.667, y: 1 }, s: [360], t: 23 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [360],
                t: 31,
              },
              {
                o: { x: 0.17, y: 0.043 },
                i: { x: 0.615, y: 0.934 },
                s: [356.471],
                t: 46,
              },
              {
                o: { x: 0.351, y: -0.078 },
                i: { x: 0.69, y: 1 },
                s: [361.351],
                t: 60,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [360],
                t: 65.0000026475043,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.6, 0],
                      [0, 0],
                      [0, 2.599],
                      [0, 0],
                      [-2.6, 0],
                      [0, 0],
                      [0, -2.6],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-2.6, 0],
                      [0, 0],
                      [0, -2.6],
                      [0, 0],
                      [2.599, 0],
                      [0, 0],
                      [-0.101, 2.5],
                    ],
                    v: [
                      [127.35, 166.15],
                      [-127.45, 166.15],
                      [-132.15, 161.451],
                      [-132.15, -161.45],
                      [-127.45, -166.15],
                      [127.45, -166.15],
                      [132.15, -161.45],
                      [132.15, 161.55],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.2941, 0.4392, 0.5647], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [132.4, 166.4], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 15,
      },
      {
        ty: 4,
        nm: 'Layer 1 contornos 2',
        mn: '',
        sr: 1,
        st: 0,
        op: 28.0000011404634,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [132.4, 166.4, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [0, 0, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [102.242, 113.009, 100],
                t: 11,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [100.787, 89.379, 100],
                t: 17,
              },
              {
                o: { x: 0.167, y: 0 },
                i: { x: 0.667, y: 1 },
                s: [100, 100, 100],
                t: 22,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 30.0000012219251,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [226.05, 267.45, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [364],
                t: 11,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.667, y: 1 },
                s: [359],
                t: 17,
              },
              { o: { x: 0.167, y: 0 }, i: { x: 0.667, y: 1 }, s: [360], t: 22 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [360],
                t: 30,
              },
              {
                o: { x: 0.17, y: 0.043 },
                i: { x: 0.615, y: 0.934 },
                s: [356.471],
                t: 45,
              },
              {
                o: { x: 0.351, y: -0.078 },
                i: { x: 0.69, y: 1 },
                s: [361.351],
                t: 59,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [360],
                t: 64.0000026067734,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Grupo 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Caminho 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [2.6, 0],
                      [0, 0],
                      [0, 2.599],
                      [0, 0],
                      [-2.6, 0],
                      [0, 0],
                      [0, -2.6],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-2.6, 0],
                      [0, 0],
                      [0, -2.6],
                      [0, 0],
                      [2.599, 0],
                      [0, 0],
                      [-0.101, 2.5],
                    ],
                    v: [
                      [127.35, 166.15],
                      [-127.45, 166.15],
                      [-132.15, 161.451],
                      [-132.15, -161.45],
                      [-127.45, -166.15],
                      [127.45, -166.15],
                      [132.15, -161.45],
                      [132.15, 161.55],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Preenchimento 1',
                c: { a: 0, k: [0.4471, 0.6588, 0.8392], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [132.4, 166.4], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 16,
      },
    ],
    ddd: 0,
    h: 512,
    w: 512,
    meta: {
      a: '',
      k: '',
      d: '',
      g: '@lottiefiles/toolkit-js 0.21.1',
      tc: '#ffffff',
    },
    v: '4.10.1',
    fr: 29.9700012207031,
    op: 90.0000036657751,
    ip: 0,
    assets: [],
  }
}
