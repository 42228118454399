import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import InputContact from "../components/contactCaptivator/InputContact";
import PhoneNumberContact from "../components/contactCaptivator/PhoneNumberContact";
import TextAreaContact from "../components/contactCaptivator/TextAreaContact";
import PreferMethod from "../components/contactCaptivator/PreferMethod";
import SubmitButtonContact from "../components/contactCaptivator/SubmitButtonContact";
import Catergory from "../components/contactCaptivator/Catergory";
import LoadingModal from "../components/contactCaptivator/LoadingModal";
import { postContactUs } from "../Services/contactUs";
import { callingCode } from "../Utils/callingCode";
import Meta from "./../Utils/Meta";
import Meta1 from "../Utils/Meta1";

const ContactCaptivator = () => {
  const [underConstruction, setUnderConstruction] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [catergory, setCatergory] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState(false);
  const [em, setEm] = useState(false);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setUnderConstruction(false);
    window.scrollTo(0, 0);
  }, []);

  const onChangePhone = (e) => {
    const value = e.target.checked;
    if (value) {
      setPhone(true);
      setEm(false);
    } else {
      setPhone(false);
    }
  };

  const onChangeEmail = (e) => {
    const value = e.target.value;
    if (value) {
      setEm(true);
      setPhone(false);
    } else {
      setEm(false);
    }
  };

  const onsumitContactForm = async (e) => {
    e.preventDefault();
    try {
      if (countryCode == "")
        return setError("Country code is not suppose to be empty");

      if (!phone && !em)
        return setError("Preffer method of contact is required");

      setLoading(true);
      setShowMessage(true);
      const ctry = callingCode().filter((x) => x.code == countryCode)[0];
      const data = {
        firstName,
        lastName,
        countryCode,
        phoneNumber,
        country: ctry.ctry,
        email,
        catergory,
        message,
        phone,
        em,
      };

      await postContactUs(data);
      setLoading(false);
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setCountryCode("");
      setEmail("");
      setCatergory("");
      setMessage("");
      setPhone(false);
      setEm(false);
      setError("");

      setInterval(() => {
        setShowMessage(false);
      }, 7000);
    } catch (ex) {
      setError(ex.response.data);
      setLoading(false);
      setShowMessage(false);
    }
  };

  if (underConstruction)
    return (
      <>
        <Meta title="Nawafrica | contact us" />
        <ComponentUnderConstruction />
      </>
    );

  return (
    <>
      <Meta1
        title="Nawafrica | Contact Us"
        description="Contact Nawafrica Customer Services"
        keywords="Contact Nawafrica"
        link="/contactUsNow"
      />

      <div style={{ width: "100%" }}>
        <div className="containerUsOuterContainer">
          <Meta title="Nawafrica | contact us now" />

          {showMessage ? (
            <LoadingModal loading={loading} showMessage={showMessage} />
          ) : (
            <>
              <div className="contactUsInnerContainer">
                <form onSubmit={onsumitContactForm}>
                  <h5>CONTACT US FORM</h5>
                  <div className="nameContainer">
                    <div className="nameInputContainer">
                      <InputContact
                        name="First Name"
                        placeholder="First Name"
                        id="firstNameContact"
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className="nameInputContainer">
                      <InputContact
                        name="Last Name"
                        placeholder="Last Name"
                        id="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="emailContainer">
                    <div style={{ flex: "1" }}>
                      <PhoneNumberContact
                        onChangeSelect={(e) => setCountryCode(e.target.value)}
                        onChangeInput={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                    <div style={{ flex: "1", paddingLeft: "40px" }}>
                      <InputContact
                        name="Email"
                        placeholder="Ex:example@yahoo.com"
                        id="email"
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <Catergory
                    items={[
                      "About Captivator Technologies And Nawafrica",
                      "Authentication/Signing Up",
                      "Authorisation/Signing in",
                      "Commissions and Fees",
                      "About Applications",
                      "Tenant Portals",
                      "The Tenant Management Portal",
                      "The Listing Portal",
                      "Searching Input Fields",
                      "Signing of Lease",
                      "Messages",
                      "Payment of house rent",
                      "My Listings",
                      "Others",
                    ]}
                    onChange={(e) => setCatergory(e.target.value)}
                  />
                  <TextAreaContact
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <PreferMethod
                    valuePhone={phone}
                    onChangePhone={onChangePhone}
                    valueEmail={em}
                    onChangeEmail={onChangeEmail}
                  />

                  <SubmitButtonContact />
                  {error && <p className="contactUsError">{error}</p>}
                </form>
              </div>
            </>
          )}
          <ScrollToTop smooth />
        </div>
      </div>
    </>
  );
};

export default ContactCaptivator;
