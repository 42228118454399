import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

import AddTenantFileInput from "../ManageTenants/AddTenantFileInput";
import AddTenantInput from "../ManageTenants/AddTenantInput";
import { CircularProgress } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const VerifyAccount = ({
  open,
  onClose,
  onChangeID,
  onChangeImage,
  imgSrcOther,
  error,
  loading,
  onClickImage,
  onSubscribe,
  subscribeError,
  loadingVerify,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ width: 300, border: "2px solid #035aa6" }}>
          {loadingVerify && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100px",
              }}
            >
              <CircularProgress
                size={30}
                sx={{ fontSize: 30, color: "#035aa6" }}
              />
            </div>
          )}
          <div style={{ width: "100%" }}>
            <AddTenantInput
              placeholder="ID card | passport number"
              title="ID card number"
              onChange={onChangeID}
            />
            <AddTenantFileInput
              title="Screen Shot of ID Card | passport"
              onChange={onChangeImage}
              uploadedImagesArray={imgSrcOther}
              multiple={true}
              error={error}
              accept={"image/*"}
              id="verId"
              loading={loading}
              onClickImage={onClickImage}
            />
          </div>
          <div style={{ margin: "10px 0" }}>
            <Button
              sx={{ fontSize: "12px", background: "#035aa6" }}
              variant="contained"
              onClick={onSubscribe}
              disabled={loadingVerify}
            >
              Subscribe For verification
            </Button>
            {subscribeError && (
              <p style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
                {subscribeError}
              </p>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VerifyAccount;
