import React from 'react'
import { useTranslation } from 'react-i18next'

import '../listingDetails/listingDetails.css'

const MoreAboutListingLease = ({ advancedPayment }) => {
  const { t } = useTranslation()
  return (
    <div className='moreAboutListCon'>
      <p className='advancePaySpan'>
        {t('Advanced_Payment/Security_Fee')}: {advancedPayment}
      </p>
    </div>
  )
}

export default MoreAboutListingLease
