import React from 'react'
import Lottie from 'react-lottie'
import { mapAnimateLottie } from './mapAnimateLottie'

const MapLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: mapAnimateLottie(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={80} width={80} />
    </div>
  )
}

export default MapLoader
