import React from 'react'
import { useTranslation } from 'react-i18next'

import './messageDetail.css'

const MessageSubject = ({ subject, onChangeLang, lang, close }) => {
  const { t } = useTranslation()
  return (
    <div className='subjectInMessageDetCon'>
      <div className='innerContainerForSubject'>
        <b>{t('Subject')}:</b>
      </div>{' '}
      <div className='theSubjectMatter'>{subject}</div>
      {/* <div className={close ? 'langDropdown' : 'langDropdown1'}> */}
      {/* <p>{t('Translate_to')}:</p>
        <select
          style={{
            fontSize: '10px',
            width: '50%',
            padding: '0',
            borderRadius: '10px',
            height: '20px',
            cursor: 'pointer',
          }}
          value={lang}
          onChange={(e) => onChangeLang(e.target.value)}
        >
          <option value='en'>{t('English')}</option>
          <option value='fr'>{t('French')}</option>
          <option value='ke'>{t('Swahili')}</option>
          <option value='sa'>{t('Arabic')}</option>
        </select> */}
      {/* </div> */}
    </div>
  )
}

export default MessageSubject
