import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import './FAQ.css'

const Faqdrop1 = ({
  topic = ' About Captivator Technologies And Nawafrica',
  onClick,
  open,
}) => {
  return (
    <div className='faqdrop1OuterCon' onClick={onClick}>
      <div className='faqdrop1InnerCon'>
        <h4 style={{ fontWeight: 'bolder' }}>{topic}</h4>
      </div>
      <div className='faqdrop1IconCon'>
        <FontAwesomeIcon
          className={open ? 'fag-about-icon1' : 'fag-about-icon'}
          icon={faCaretDown}
        />
      </div>
    </div>
  )
}

export default Faqdrop1
