import React from "react";
import { Link } from "react-router-dom";
import { FaDownload } from "react-icons/fa";
import "simplebar";
import "simplebar/dist/simplebar.css";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import LoadingSpinner from "./LoadingSpinner";
import { trackEvent } from "../../Utils/useGAEventTracker";

import "./manageTenants.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import CloseBtnDeal from "../LeaseSignDetails/CloseBtnDeal";
import DebtFree from "../Leases/DebtFree";
import OwingAnimation from "../Leases/OwingAnimation";

const TenantTable = ({
  myTenants,
  loadingSearchListings,
  tenantToDownloadPDF,
  onDelete,
  downloadAgreement,
  listings,
  editUnregisteredTenant,
}) => {
  const { t } = useTranslation();

  function dateConvert(date) {
    const dateArray = date.split("-");
    return dateArray ? `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}` : "";
  }

  const listingCurrency = (lease) => {
    const theList = listings.filter((list) => list.id === lease.listing_id)[0];
    return theList ? theList.currency : "";
  };
  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  return (
    <>
      <div
        data-simplebar
        className="applicationTableCon1 reduceTheHeightWhenMobile  visibleForLargeScreen"
        style={{ overflowX: "scroll", width: "100%" }}
      >
        <table style={{ width: 1500 }} className="table table-striped">
          <thead className="myListThead">
            <tr>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Tenant_Name")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Listing_Name")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Listing_Address")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Apt/Studio/Office#")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Date_Rented")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Amount_Owing")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Nawafrica_Membership")}
              </th>
              <th className="myListTh tenantHeaders" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {myTenants.map((tenant) => (
              <tr
                style={
                  tenant.tenant_close_deal && tenant.landlord_close_deal
                    ? { background: "#ffe5b4" }
                    : {}
                }
                key={`${tenant.id}`}
                className="table-column"
              >
                <th className="myListTd">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {!tenant?.registered_tenant &&
                      !tenant.tenant_close_deal &&
                      !tenant.landlord_close_deal && (
                        <a
                          data-toggle="modal"
                          data-target="#exampleModalTenant"
                          href="#"
                        >
                          <IconButton
                            onClick={() => editUnregisteredTenant(tenant)}
                            style={{ zIndex: 5 }}
                          >
                            <EditIcon />
                          </IconButton>
                        </a>
                      )}
                    <Link
                      onClick={() =>
                        trackEvent(
                          "tenant details link",
                          `/portal/tenants/${tenant.id}`,
                          "link to tenant details"
                        )
                      }
                      className={
                        Number(tenant.amount_owed) <= 0
                          ? "goToLeaseSignDetails tableItemLetters"
                          : "goToLeaseSignDetails1 tableItemLetters"
                      }
                      to={
                        tenant.registered_tenant
                          ? `/portal/tenants/${tenant.id}`
                          : `/portal/tenants_nonmember/${tenant.id}`
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {Number(tenant.amount_owed) <= 0 ? (
                          <DebtFree />
                        ) : (
                          <OwingAnimation />
                        )}
                        {tenant.tenant_close_deal &&
                          !tenant.landlord_close_deal && (
                            <span style={{ marginRight: "5px" }}>
                              <CloseBtnDeal />
                            </span>
                          )}
                        {capitalize(`${tenant.tenant_name}`)}
                      </div>
                    </Link>
                  </div>
                </th>
                <td className="myListTd">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Link
                      onClick={() =>
                        trackEvent(
                          "tenant details link",
                          `/portal/tenants/${tenant.id}`,
                          "link to tenant details"
                        )
                      }
                      className={
                        Number(tenant.amount_owed) <= 0
                          ? "goToLeaseSignDetails tableItemLetters"
                          : "goToLeaseSignDetails1 tableItemLetters"
                      }
                      to={
                        tenant.registered_tenant
                          ? `/portal/tenants/${tenant.id}`
                          : `/portal/tenants_nonmember/${tenant.id}`
                      }
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {capitalize(
                        tenant.building_name.length > 17
                          ? tenant.building_name.slice(0, 15) + ".."
                          : tenant.building_name
                      )}
                    </Link>
                  </div>
                </td>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {tenant.house_address.length > 17
                      ? tenant.house_address.slice(0, 15) + ".."
                      : tenant.house_address}
                  </Link>
                </td>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                  >
                    {tenant.appartment_num}
                  </Link>
                </td>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                  >
                    {dateConvert(tenant.create_date.slice(0, 10))}
                  </Link>
                </td>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                  >
                    {tenant.amount_owed
                      ? tenant.amount_owed.startsWith("-")
                        ? 0
                        : commaSeparateNumber(tenant.amount_owed)
                      : 0}{" "}
                    {tenant && listingCurrency(tenant)}
                  </Link>
                </td>
                <td className="myListTd">
                  <Link
                    style={{
                      clear: "both",
                      display: "inline-block",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                  >
                    {tenant.registered_tenant ? t("Member") : t("Non_Member")}
                  </Link>
                </td>
                <td className="myListTd">
                  <div className="myListDeleteBtnLease">
                    <div style={{ flex: "1" }}>
                      <button
                        className="deleteBtn"
                        onClick={() => onDelete(tenant)}
                      >
                        {t("Delete")}
                      </button>
                    </div>
                    <div style={{ flex: "1" }}>
                      {tenant.id === tenantToDownloadPDF ? (
                        <div className="loadingTheAggreementInTenantTable">
                          <LoadingSpinner />
                        </div>
                      ) : tenant.registered_tenant ? (
                        <button
                          className="downloadBtn"
                          onClick={() => {
                            downloadAgreement(tenant);
                          }}
                        >
                          <FaDownload className="tenantHeaders" />{" "}
                          {t("Agreement")}
                        </button>
                      ) : tenant.sign_lease_img &&
                        tenant.sign_lease_img.filePath ? (
                        <a
                          onClick={() =>
                            trackEvent(
                              "land download agreement",
                              `https://res.cloudinary.com/captivator/image/upload/fl_attachment/${tenant.sign_lease_img.filePath.public_id}.${tenant.sign_lease_img.filePath.format}`,
                              "landlord tenant table download btn"
                            )
                          }
                          style={{ fontSize: "9px" }}
                          href={`https://res.cloudinary.com/captivator/image/upload/fl_attachment/${tenant.sign_lease_img.filePath.public_id}.${tenant.sign_lease_img.filePath.format}`}
                          download
                        >
                          <div className="noneMemberDownloadBtn">
                            <FaDownload className="tenantHeaders" />
                            Agreement
                          </div>
                        </a>
                      ) : (
                        <div
                          style={{
                            background: "grey",
                            color: "white",
                            padding: "7px 5px",
                            fontSize: "8px",
                            borderRadius: "5px",
                          }}
                        >
                          No Agreement
                        </div>
                      )}
                    </div>
                    {/* onClick={()=>downloadAgreementNonMember(tenant.sign_lease_img.filePath)} */}
                  </div>
                </td>
              </tr>
            ))}

            {loadingSearchListings && <div>{t(".....loading")}</div>}
          </tbody>
        </table>
      </div>

      <div
        data-simplebar
        className="applicationTableCon1 someMarginTop visibleForMobile"
      >
        <table className="table table-striped">
          <thead className="myListThead">
            <tr>
              <th className="myListTh" scope="col">
                {t("Tenant_Name")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Listing_Name")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Apt/Studio/Office#")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Date_Rented")}
              </th>
              <th className="myListTh" scope="col">
                {t("Amount_Owing")}
              </th>
              <th className="myListTh tenantHeaders" scope="col">
                {t("Nawafrica_Membership")}
              </th>
              <th className="myListTh" scope="col">
                {t("Action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {myTenants.map((tenant) => (
              <tr
                style={
                  tenant.tenant_close_deal && tenant.landlord_close_deal
                    ? { background: "#ffe5b4" }
                    : {}
                }
                key={`${tenant.id}`}
                className="table-column"
              >
                <th className="myListTd">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {!tenant?.registered_tenant &&
                      !tenant.tenant_close_deal &&
                      !tenant.landlord_close_deal && (
                        <a
                          data-toggle="modal"
                          data-target="#exampleModalTenant"
                          href="#"
                        >
                          <IconButton
                            onClick={() => editUnregisteredTenant(tenant)}
                            style={{ zIndex: 5 }}
                          >
                            <EditIcon />
                          </IconButton>
                        </a>
                      )}
                    <Link
                      onClick={() =>
                        trackEvent(
                          "tenant details link",
                          `/portal/tenants/${tenant.id}`,
                          "link to tenant details"
                        )
                      }
                      className={
                        Number(tenant.amount_owed) <= 0
                          ? "goToLeaseSignDetails tableItemLetters"
                          : "goToLeaseSignDetails1 tableItemLetters"
                      }
                      to={
                        tenant.registered_tenant
                          ? `/portal/tenants/${tenant.id}`
                          : `/portal/tenants_nonmember/${tenant.id}`
                      }
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {Number(tenant.amount_owed) <= 0 ? (
                          <DebtFree />
                        ) : (
                          <OwingAnimation />
                        )}
                        {tenant.tenant_close_deal &&
                          !tenant.landlord_close_deal && (
                            <span style={{ marginRight: "5px" }}>
                              <CloseBtnDeal />
                            </span>
                          )}
                        {capitalize(`${tenant.tenant_name}`)}
                      </div>
                    </Link>
                  </div>
                </th>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                  >
                    {capitalize(
                      tenant.building_name.length > 17
                        ? tenant.building_name.slice(0, 15) + ".."
                        : tenant.building_name
                    )}
                  </Link>
                </td>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                  >
                    {tenant.appartment_num}
                  </Link>
                </td>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                  >
                    {dateConvert(tenant.create_date.slice(0, 10))}
                  </Link>
                </td>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                  >
                    {tenant.amount_owed
                      ? tenant.amount_owed.startsWith("-")
                        ? 0
                        : commaSeparateNumber(tenant.amount_owed)
                      : 0}{" "}
                    {tenant && listingCurrency(tenant)}
                  </Link>
                </td>
                <td className="myListTd">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "tenant details link",
                        `/portal/tenants/${tenant.id}`,
                        "link to tenant details"
                      )
                    }
                    className={
                      Number(tenant.amount_owed) <= 0
                        ? "goToLeaseSignDetails tableItemLetters"
                        : "goToLeaseSignDetails1 tableItemLetters"
                    }
                    to={
                      tenant.registered_tenant
                        ? `/portal/tenants/${tenant.id}`
                        : `/portal/tenants_nonmember/${tenant.id}`
                    }
                  >
                    {tenant.registered_tenant ? t("Member") : t("Non_Member")}
                  </Link>
                </td>
                <td className="myListTd">
                  <span className="myListDeleteBtnLease">
                    <button
                      className="deleteBtn"
                      onClick={() => onDelete(tenant)}
                    >
                      <span style={{ marginRight: "5px" }}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </span>
                      Delete
                    </button>
                    {tenant.id === tenantToDownloadPDF ? (
                      <div className="loadingTheTenantDownloadAgreement">
                        <LoadingSpinner />
                      </div>
                    ) : tenant.registered_tenant ? (
                      <button
                        className="downloadBtn"
                        onClick={() => downloadAgreement(tenant)}
                      >
                        <FaDownload className="tenantHeaders" /> Agreement
                      </button>
                    ) : tenant.sign_lease_img &&
                      tenant.sign_lease_img.filePath ? (
                      <a
                        style={{ width: "180px", fontSize: "9px" }}
                        className="noneMemberDownloadBtn btn"
                        href={
                          tenant.sign_lease_img.filePath.format == "pdf"
                            ? `https://res.cloudinary.com/captivator/raw/upload/${tenant.sign_lease_img.filePath.public_id}.${tenant.sign_lease_img.filePath.format}`
                            : `https://res.cloudinary.com/captivator/image/upload/fl_attachment/${tenant.sign_lease_img.filePath.public_id}.${tenant.sign_lease_img.filePath.format}`
                        }
                        download
                      >
                        <FaDownload className="tenantHeaders" /> Agreement
                      </a>
                    ) : (
                      <div
                        style={{
                          background: "grey",
                          color: "white",
                          padding: "7px 5px",
                          fontSize: "8px",
                          borderRadius: "5px",
                        }}
                      >
                        No Agreement
                      </div>
                    )}
                  </span>
                </td>
              </tr>
            ))}

            {loadingSearchListings && <div>{t(".....loading")}</div>}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TenantTable;
