import React from 'react'

import './styleCompo.css'
import 'flag-icon-css/css/flag-icon.min.css'

const GlobeDropdown = ({ languages, onClick, currentLanguage }) => {
  return (
    <div className='globaldropDownContainer dropdown-menu'>
      <ul>
        {languages.map(({ code, name, country_code }) => (
          <li key={country_code}>
            <button
              className={'dropdown-item dropdownButtonNav'}
              style={{ cursor: 'pointer' }}
              onClick={() => onClick(code)}
              disabled={
                code === currentLanguage || code == 'ke' || code == 'sa'
              }
            >
              <span
                className={
                  currentLanguage === code || code == 'ke' || code == 'sa'
                    ? `flag-icon flag-icon-${country_code} mx-2 flag-icon-opacity1`
                    : `flag-icon flag-icon-${country_code} mx-2 flag-icon-opacity2`
                }
              ></span>
              {name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default GlobeDropdown
