import React from 'react'
import ReactPlayer from 'react-player'

const ShortVideo = () => {
  return (
    <div className='aboutShortVideoCon'>
      <ReactPlayer
        height='100%'
        width='100%'
        url='https://www.youtube.com/watch?v=jVa6dqS5b1o'
      />
    </div>
  )
}

export default ShortVideo
