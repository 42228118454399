import React from 'react'
import { Link } from 'react-router-dom'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash,
  faPenToSquare,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'

import { trackEvent } from '../../Utils/useGAEventTracker'

import './manageTenants.css'

const TableManageTenant = ({
  items,
  loadingSearchListings,
  applications,
  onPublish,
  onDelete,
}) => {
  const { t } = useTranslation()

  const filteredApplication = (listing_id) => {
    const applicationss = [...applications]

    const app = applicationss.filter((app) => app.listing_id === listing_id)

    return app.length
  }

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase()
    })
  }

  function dateConvert(date) {
    const dateArray = date.split('-')

    return dateArray ? `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}` : ''
  }

  return (
    <>
      <div data-simplebar className='myListTableCon visibleForLargeScreen'>
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th className='myListTh' scope='col'>
                {t('List_Name')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Date_Posted')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Applications_Recieved')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item) => (
                <tr key={`${item.id}`} className='table-column'>
                  <th className='myListTd'>
                    <Link
                      className='theListingLink'
                      to={`/listing/${item.id}`}
                      onClick={() =>
                        trackEvent(
                          'listing details',
                          `/listing/${item.id}`,
                          'listing detail link'
                        )
                      }
                    >
                      {capitalize(item.title)}
                    </Link>
                  </th>
                  <td className='myListTd'>
                    {dateConvert(item.create_date.slice(0, 10))}
                  </td>
                  <td className='myListTd'>{filteredApplication(item.id)}</td>

                  <td className='unpublishEditDeleteBtn'>
                    <div style={{ width: '98%', display: 'flex' }}>
                      <div className='myListPublishBtn'>
                        {item.publish === 'publish' && (
                          <button
                            className='unpublishBtn'
                            onClick={() => {
                              onPublish(item)
                              trackEvent(
                                'unpublish',
                                `unpublish ${item.id}`,
                                'unpublish btn'
                              )
                            }}
                          >
                            <span style={{ marginRight: '5px' }}>
                              <FontAwesomeIcon icon={faEyeSlash} />
                            </span>
                            {t('unpublish')}
                          </button>
                        )}
                        {item.publish === '' && (
                          <button
                            className='publishBtn'
                            onClick={() => {
                              onPublish(item)
                              trackEvent(
                                'publish',
                                `publish ${item.id}`,
                                'publish btn'
                              )
                            }}
                          >
                            <span style={{ marginRight: '5px' }}>
                              <FontAwesomeIcon icon={faEye} />
                            </span>
                            {t('publish')}
                          </button>
                        )}
                      </div>
                      <div className='myEditBtn'>
                        <Link
                          onClick={() =>
                            trackEvent(
                              'Edit listing',
                              `/edit_listing/${item.id}`,
                              'Edit listing btn'
                            )
                          }
                          to={`/edit_listing/${item.id}`}
                        >
                          <span style={{ marginRight: '5px' }}>
                            <FontAwesomeIcon icon={faPenToSquare} />
                          </span>
                          {t('Edit')}
                        </Link>
                      </div>
                      <div className='myListDeleteBtn'>
                        <button onClick={() => onDelete(item.id)}>
                          <span style={{ marginRight: '5px' }}>
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </span>
                          {t('Delete')}
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            {loadingSearchListings && <div>{t('.....loading')}</div>}
          </tbody>
        </table>
      </div>

      <div data-simplebar className='myListTableCon visibleForMobile'>
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th className='myListTh' scope='col'>
                {t('List_Name')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {items &&
              items.map((item) => (
                <tr key={`${item.id}`} className='table-column'>
                  <th className='myListTd'>
                    <Link
                      onClick={() =>
                        trackEvent(
                          'listing details',
                          `/listing/${item.id}`,
                          'listing detail link'
                        )
                      }
                      style={{ textDecoration: 'none' }}
                      to={`/listing/${item.id}`}
                    >
                      {capitalize(item.title)}
                    </Link>
                  </th>
                  <td className='unpublishEditDeleteBtn'>
                    <div className='myListPublishBtn'>
                      {item.publish ? (
                        <button
                          className='unpublishBtn'
                          onClick={() => {
                            onPublish(item)
                            trackEvent(
                              'unpublish',
                              `unpublish ${item.id}`,
                              'unpublish btn'
                            )
                          }}
                        >
                          <span style={{ marginRight: '5px' }}>
                            <FontAwesomeIcon icon={faEyeSlash} />
                          </span>
                          {t('unpublish')}
                        </button>
                      ) : (
                        <button
                          className='publishBtn'
                          onClick={() => {
                            onPublish(item)
                            trackEvent(
                              'publish',
                              `publish ${item.id}`,
                              'publish btn'
                            )
                          }}
                        >
                          <span style={{ marginRight: '5px' }}>
                            <FontAwesomeIcon icon={faEye} />
                          </span>
                          {t('publish')}
                        </button>
                      )}
                    </div>
                    <div className='myEditBtn'>
                      <Link
                        onClick={() =>
                          trackEvent(
                            'Edit listing',
                            `/edit_listing/${item.id}`,
                            'Edit listing btn'
                          )
                        }
                        style={{ textDecoration: 'none' }}
                        to={`/edit_listing/${item.id}`}
                      >
                        <span style={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </span>
                        {t('Edit')}
                      </Link>
                    </div>
                    <div className='myListDeleteBtn'>
                      <button onClick={() => onDelete(item.id)}>
                        <span style={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                        {t('Delete')}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            {loadingSearchListings && <div>{t('.....loading')}</div>}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TableManageTenant
