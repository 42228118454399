import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import 'simplebar'
import 'simplebar/dist/simplebar.css'
import './applications.css'

const ApplicationsTableTenant = ({
  applicants,
  allStatus,
  users,
  loadingSearchListings,
  onDeleteApplication,
}) => {
  const { t } = useTranslation('')

  const [sortColumn, setSortColumn] = useState({
    path: 'dateposted',
    order: 'asc',
  })

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase()
    })
  }

  const filteredUserContact = (id) => {
    const allUsers = [...users]

    const user = allUsers.filter((user) => user.id === id)[0]

    return user && user.contact ? user.contact : '--'
  }

  const filterStatuses = (id) => {
    const status = allStatus.filter((all) => all.application_id === id)
    const sortedListing = _.orderBy(
      status,
      [sortColumn.path],
      [sortColumn.order]
    )
    const myStatus = sortedListing[sortedListing.length - 1]
    return myStatus
      ? myStatus
      : { accepted_or_denied: null, lease_signed: null, under_review: null }
  }

  return (
    <>
      <div
        data-simplebar
        className='applicationTableCon1 reduceTheHeightForMobile visibleForLargeScreen'
      >
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th className='myListTh' scope='col'>
                {t('Listing_Name')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Landlords_Name')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Tel_No.')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Date_Applied')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Approval_Status')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {applicants.map((appl, index) => (
              <tr key={`${appl.id}`} className='table-column'>
                <th className='myListTd'>
                  <a
                    className='applicationTableAtag goToListingDet'
                    href={`/listing/${appl.listing_id}`}
                    style={{        overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    lineClamp: 1,
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical'}}
                  >
                    {capitalize(appl.listing_name)}
                  </a>
                </th>
                <td          style={{        overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    lineClamp: 1,
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical'}} className='myListTd'>{capitalize(appl.list_owner_name)}</td>
                <td className='myListTd'>
                  {filteredUserContact(appl.list_owner_id)}
                </td>
                <td className='myListTd'>{appl.create_date.slice(0, 10)}</td>
                <td className='myListTd'>
                  {filterStatuses(appl.id).lease_signed
                    ? t(filterStatuses(appl.id).lease_signed)
                    : filterStatuses(appl.id).accepted_or_denied
                    ? t(filterStatuses(appl.id).accepted_or_denied)
                    : filterStatuses(appl.id).under_review
                    ? t(filterStatuses(appl.id).under_review)
                    : t('Submitted')}
                </td>
                <td className='approveUnapproveCon'>
                  <div className='approveUnapproveIcon'>
                    <div className='myListDeleteBtn'>
                      <button
                        className='applicationTableAtag'
                        onClick={() => onDeleteApplication(appl)}
                      >
                        <span style={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                        {t('Delete')}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}

            {loadingSearchListings && <div>{t('.....loading')}</div>}
          </tbody>
        </table>
      </div>

      <div data-simplebar className='applicationTableCon7 visibleForMobile'>
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th className='myListTh' scope='col'>
                {t('Listing_Name')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Landlords_Name')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Approval_Status')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {applicants.map((appl, index) => (
              <tr key={`${appl.id}`} className='table-column'>
                <th className='myListTd'>
                  <a
                    className='applicationTableAtag'
                    style={{        overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    lineClamp: 1,
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical'}}
                    href={`/listing/${appl.listing_id}`}
                  >
                    {capitalize(appl.listing_name)}
                  </a>
                </th>
                <td          style={{        overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    lineClamp: 1,
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical'}} className='myListTd'>{capitalize(appl.list_owner_name)}</td>
                <td className='myListTd'>
                  {filterStatuses(appl.id).lease_signed
                    ? t(filterStatuses(appl.id).lease_signed)
                    : filterStatuses(appl.id).accepted_or_denied
                    ? t(filterStatuses(appl.id).accepted_or_denied)
                    : filterStatuses(appl.id).under_review
                    ? t(filterStatuses(appl.id).under_review)
                    : t('Submitted')}
                </td>
                <td className='approveUnapproveCon'>
                  <div className='approveUnapproveIcon'>
                    <div className='myListDeleteBtn'>
                      <button
                        className='deleteApplBtn'
                        onClick={() => onDeleteApplication(appl.id)}
                      >
                        <span style={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                        {t('Delete')}
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            ))}

            {loadingSearchListings && <div>.....loading</div>}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default ApplicationsTableTenant