import React from "react";

const TextAreaContact = ({ onChange }) => {
  return (
    <div className="contactTextAreaContainer">
      <label
        style={{ color: "#035aa6" }}
        htmlFor="messsage"
        className="form-label"
      >
        Message <span style={{ color: "red", marginLeft: "5px" }}>*</span>
      </label>
      <textarea
        type="email"
        className="form-control"
        rows="5"
        cols="100"
        id="messsage"
        placeholder="Reason"
        onChange={onChange}
      ></textarea>
    </div>
  );
};

export default TextAreaContact;
