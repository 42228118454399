import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons'

const EditParagraphModal = ({ content, onCloseModal }) => {
  return (
    <div className='rentSaleEditModalCon'>
      <div className='rentSaleEditModalCloseCon'>
        {' '}
        <FontAwesomeIcon
          //           className='RentPlatformHeadIcon1'
          style={{ fontSize: '35px', cursor: 'pointer' }}
          icon={faXmarkCircle}
          onClick={onCloseModal}
        />
      </div>{' '}
      <p className='rentSaleEditModalSubtitle'>
        <b>
          Please, Copy, paste and edit all your sub titles and paragraphs into
          their input fields
        </b>
      </p>
      {content.map((x, i) => (
        <div key={`${i}`}>
          <h3>
            {' '}
            {i + 1}. {x.subTitle}
          </h3>
          <p>{x.Para}</p>
        </div>
      ))}
    </div>
  )
}

export default EditParagraphModal
