import React from "react";
import { FaCreditCard } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { Box, TextField } from "@mui/material";

// import { callingCode } from "../../Utils/callingCode";
import PaymentUpdateSpinner from "../Payment/PaymentUpdateSpinner";
import SelectedItem1 from "../Home/SelectedItem1";
import { countries } from "../../Utils/countries";

const AddPaymentMethodModal = ({
  loadingMtnMomo,
  updatedPaymentMethod,
  onClickPaymentMethod,
  selectedPaymentMethod,
  onChangeCountryCode,
  onChangePhoneNumber,
  onhandleSubmit,
  onChangeVisaCardNumber,
  loadingVisaCard,
  onChangePaypal,
  loadingPaypal,
  valueCardNumber,
  onChangeVisaCardDate,
  valueCardDate,
  onChangeCvc,
  valueCardCvc,

  valueCountryCode,
  valuePhoneNumber,
  valuePaypal,
  addPaymentError,

  underConstructionBankPayment,
  underConstructionPaypal,
}) => {
  const { t } = useTranslation();

  function limitText(e) {
    if (e.target.value.length > 19) {
      e.target.value = e.target.value.substring(0, 19);
    }
  }

  function limitTextMonths(e) {
    if (e.target.value.length > 5) {
      e.target.value = e.target.value.substring(0, 5);
    }
  }

  function limitTextCvc(e) {
    if (e.target.value.length > 4) {
      e.target.value = e.target.value.substring(0, 4);
    }
  }

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalAddPaymentOption"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div
            style={{ boxShadow: "2px 3px 3px black" }}
            className="modal-content"
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {t("Choose_a_payment_method")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <ul className="list-group addPaymentMethodListItem">
              <li
                onClick={() => onClickPaymentMethod("MTN Mobile Money")}
                className="list-group-item"
              >
                <img
                  style={{ objectFit: "cover" }}
                  className="mtnImage"
                  src="/images/mtn.png"
                />
                {t("MTN_Mobile_Money")}
                {loadingMtnMomo ? (
                  <PaymentUpdateSpinner />
                ) : updatedPaymentMethod && updatedPaymentMethod.momo_number ? (
                  <FaCheck className="myCheckedIconInAddOption" />
                ) : selectedPaymentMethod === "MTN Mobile Money" ? (
                  <FaCheck className="otherCheckboxPaymentOption" />
                ) : (
                  ""
                )}
              </li>
              <li
                onClick={() => onClickPaymentMethod("Paypal")}
                className="list-group-item"
              >
                <img
                  style={{ objectFit: "cover" }}
                  className="paypalImage"
                  src="/images/paypal.jpg"
                />{" "}
                {t("Paypal")}
                {loadingPaypal ? (
                  <PaymentUpdateSpinner />
                ) : updatedPaymentMethod &&
                  updatedPaymentMethod.paypal_account ? (
                  <FaCheck className="myCheckedIconInAddOption" />
                ) : selectedPaymentMethod === "Paypal" ? (
                  <FaCheck className="otherCheckboxPaymentOption" />
                ) : (
                  ""
                )}
              </li>
              <li
                onClick={() => onClickPaymentMethod("Visa Card")}
                className="list-group-item"
              >
                <FaCreditCard className="visaCardImageIcon" />
                {t("Visa_Card")}
                {loadingVisaCard ? (
                  <PaymentUpdateSpinner />
                ) : updatedPaymentMethod &&
                  updatedPaymentMethod.visa_card_number ? (
                  <FaCheck className="myCheckedIconInAddOption" />
                ) : selectedPaymentMethod === "Visa Card" ? (
                  <FaCheck className="otherCheckboxPaymentOption" />
                ) : (
                  ""
                )}
              </li>
            </ul>

            <form onSubmit={onhandleSubmit}>
              {selectedPaymentMethod === "MTN Mobile Money" && (
                <>
                  <div className="modal-body modalBodyAddPaymentOption">
                    {/* <select
                      value={valueCountryCode}
                      onChange={onChangeCountryCode}
                      className='form-control momoCountryCode'
                    >
                      <option value=''>{t('Country_Code')}</option>
                      {callingCode().map((code) => (
                        <option key={code.ctry} value={code.code}>
                          {code.ctry}
                        </option>
                      ))}
                    </select> */}
                    <div className="addPaymentModalMomoSelect">
                      <SelectedItem1
                        leaseModal={true}
                        items={countries()}
                        onChange={onChangeCountryCode}
                        placeholder="Country Code"
                      />
                    </div>
                    <input
                      value={valuePhoneNumber}
                      onChange={onChangePhoneNumber}
                      type="text"
                      className="form-control momoInput"
                      placeholder={t("Mobile_Money_Number")}
                    />
                  </div>
                  <>
                    <button
                      disabled={loadingMtnMomo}
                      type="submit"
                      className="btn addMomoPaymentOption"
                      style={{ boxShadow: "3px 1px 3px 0.5px black" }}
                    >
                      {t("Add_Payment_Method")}
                    </button>
                    {addPaymentError && (
                      <div className="addMomoPaymentOptionError">
                        {addPaymentError}
                      </div>
                    )}
                  </>
                </>
              )}

              {underConstructionBankPayment &&
              selectedPaymentMethod === "Visa Card" ? (
                <div className="alert alert-info visaCardIsStillUnderconstruction">
                  {t("Paymentwith Visa Card is still under Construction")}
                </div>
              ) : (
                selectedPaymentMethod === "Visa Card" && (
                  <>
                    <div className="modal-body">
                      <div className="visaCardPaypalContainer">
                        <FaCreditCard className="paymentOptionVisaCardImageIcon" />
                        <input
                          value={valueCardNumber}
                          onKeyDown={limitText}
                          onKeyUp={limitText}
                          maxLength="19"
                          onChange={onChangeVisaCardNumber}
                          className="form-control cardNumberAddOption"
                          placeholder={t("Card_Number")}
                        />
                        <input
                          value={valueCardDate}
                          onKeyDown={limitTextMonths}
                          onKeyUp={limitTextMonths}
                          className="form-control dateCardPaymentOption"
                          onChange={onChangeVisaCardDate}
                          placeholder="MM/YY"
                        />
                        <input
                          value={valueCardCvc}
                          className="form-control cvcCardPaymentOption"
                          maxLength="4"
                          onChange={onChangeCvc}
                          placeholder="CVC"
                        />
                      </div>
                    </div>
                    <>
                      <button
                        disabled={loadingVisaCard}
                        type="submit"
                        className="btn addVisaCardOptionBtn"
                        style={{ boxShadow: "3px 1px 3px 0.5px black" }}
                      >
                        {t("Add_Payment_Method")}
                      </button>
                      {addPaymentError && (
                        <div className="addMomoPaymentOptionError">
                          {addPaymentError}
                        </div>
                      )}
                    </>
                  </>
                )
              )}

              {underConstructionPaypal && selectedPaymentMethod === "Paypal" ? (
                <div className="alert alert-info paypalIsStillUnderConstruction">
                  {t("Payment_with_Paypal")}
                </div>
              ) : (
                selectedPaymentMethod === "Paypal" && (
                  <>
                    <div className="modal-body">
                      {/* <input
                        value={valuePaypal}
                        onChange={onChangePaypal}
                        type='email'
                        className='form-control'
                        placeholder='Paypal account'
                        autoFocus
                      /> */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "flex-end",
                          width: "100%",
                        }}
                      >
                        <ForwardToInboxIcon
                          sx={{ color: "action.active", mr: 1 }}
                        />
                        <TextField
                          inputProps={{
                            style: {
                              font: "normal normal normal 14px/25px Merriweather",
                            },
                          }}
                          sx={{ width: "100%" }}
                          onChange={onChangePaypal}
                          id="input-with-sx"
                          label={t("Paypal email account")}
                          variant="filled"
                          type="email"
                        />
                      </Box>
                    </div>

                    <>
                      <button
                        disabled={loadingPaypal}
                        type="submit"
                        className="btn paypalAddOptionBtn"
                        style={{ boxShadow: "3px 1px 3px 0.5px black" }}
                      >
                        {t("Add_Payment_Method")}
                      </button>
                      {addPaymentError && (
                        <div className="paypalPaymentOptionError">
                          {addPaymentError}
                        </div>
                      )}
                    </>
                  </>
                )
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddPaymentMethodModal;
