import React from "react";
import { useTranslation } from "react-i18next";
import Chip from "@mui/material/Chip";

import "./applications.css";

const ApplicationTitle = ({ appl }) => {
  const { t } = useTranslation();

  return (
    <div className="applicationTitleCon">
      <h6>
        {t("Your Applications")} <Chip label={appl} />
      </h6>
    </div>
  );
};

export default ApplicationTitle;
