import React, { useEffect, useState } from "react";
import PropHead from "../components/PropertyManagement/PropHead";
import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import PropBody from "../components/PropertyManagement/PropBody";
import Meta from "./../Utils/Meta";
import { registeredAgent } from "../Services/registerService";
import Meta1 from "../Utils/Meta1";
import logger from "../Services/loggerService";

const PropertyManagement = () => {
  const [underConstruction, setUnderConstruction] = useState(false);
  const [agents, setAgents] = useState([]);
  const [agentss, setAgentss] = useState([]);

  useEffect(() => {
    setUnderConstruction(false);
    window.scrollTo(0, 0);
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await registeredAgent();
      let array = [];
      let arr = [];
      for (let i = 0; i < data.length; i++) {
        if (i === data.length - 1) {
          arr.push(data[i]);
          array.push(arr);
          return setAgentss(array);
          // logger.log('here are my data', array)
        } else if (arr.length < 6) {
          arr.push(data[i]);
        } else {
          array.push(arr);
          arr = [];
        }
        // logger.log(array)
      }
      setAgents(data);
    } catch (ex) {
      logger.log(ex.response && ex.response.data);
    }
  };

  if (underConstruction)
    return (
      <>
        <Meta title={`Nawafrica | Property Management`} />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <div
      style={{
        width: "100%",
        marginTop: "40px",
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      <Meta1
        title={`Nawafrica | Property Management`}
        description="Nawafrica Property Managers"
        link="/property-management"
      />
      <PropHead />
      <PropBody agents={agentss} />
    </div>
  );
};

export default PropertyManagement;
