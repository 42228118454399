export function priceTagAnimate() {
  return {
    nm: '9. price tag',
    mn: '',
    layers: [
      {
        ty: 4,
        nm: 'Layer 9 Outlines',
        mn: '',
        sr: 1,
        st: 0,
        op: 60,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [4.753, 5.236, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [17.174, 17.422, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0.076, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 2',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-0.539, -0.539],
                      [0.539, 0.539],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 0.6471, 0], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [2.056, 2.539], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 3',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.596, -0.595],
                      [-0.595, 0.596],
                      [-0.595, -0.595],
                      [0.596, -0.595],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [-0.596, 0.596],
                      [0.595, 0.596],
                      [0.596, -0.595],
                      [0.596, 0.596],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-1.079, -3.236],
                      [-2.156, -2.158],
                      [-2.156, -0.001],
                      [-0.001, -0.001],
                      [2.156, -0.001],
                      [2.156, 2.156],
                      [1.079, 3.236],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 0.6471, 0], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [4.753, 5.236], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 1',
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-0.539, -0.539],
                      [0.539, 0.539],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 0.6471, 0], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [7.448, 7.931], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'tm',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Filter - Trim',
            nm: 'Trim Paths 1',
            ix: 4,
            e: {
              a: 1,
              k: [
                { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 15 },
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [100],
                  t: 28,
                },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            s: {
              a: 1,
              k: [
                { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 28 },
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [100],
                  t: 40,
                },
              ],
              ix: 1,
            },
            m: 2,
          },
        ],
        ind: 0,
        parent: 1,
      },
      {
        ty: 4,
        nm: 'Layer 10 Outlines',
        mn: '',
        sr: 1,
        st: 0,
        op: 60,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [9.922, 9.953, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [9.922, 9.953, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [0], t: 10 },
              { o: { x: 0.441, y: 0 }, i: { x: 0.506, y: 1 }, s: [-4], t: 20 },
              { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [4], t: 35 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 46,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -0.55],
                      [0.37, -0.36],
                      [0, 0],
                      [0.55, 0],
                      [0.36, 0.36],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0.55],
                      [0, 0],
                      [-0.36, 0.37],
                      [-0.55, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.36, 0.36],
                    ],
                    v: [
                      [11, 2],
                      [10.41, 3.41],
                      [3.41, 10.41],
                      [2, 11],
                      [0.6, 10.42],
                      [-11, -1],
                      [-11, -11],
                      [-1, -11],
                      [10.42, 0.6],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                d: [],
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [16, 16], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 2',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, -1.105],
                      [1.105, 0],
                      [0, 1.105],
                      [-1.105, 0],
                    ],
                    o: [
                      [0, 1.105],
                      [-1.105, 0],
                      [0, -1.105],
                      [1.105, 0],
                    ],
                    v: [
                      [2, 0],
                      [0, 2],
                      [-2, 0],
                      [0, -2],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 2, ix: 5 },
                d: [],
                c: { a: 0, k: [1, 0.6471, 0], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [10, 10], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
    ],
    ddd: 0,
    h: 32,
    w: 32,
    meta: {
      a: '',
      k: '',
      d: '',
      g: '@lottiefiles/toolkit-js 0.21.1',
      tc: '#ffffff',
    },
    v: '5.7.4',
    fr: 30,
    op: 60,
    ip: 0,
    assets: [],
  }
}
