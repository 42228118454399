import React from 'react'

const Spam = (props) => {
return (
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512"  xmlSpace="preserve" {...props}>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="0" y1="258" x2="512" y2="258" gradientTransform="matrix(1 0 0 -1 0 514)">
	<stop  offset="0" />
	<stop  offset="0.021"/>
	<stop  offset="0.293"/>
	<stop  offset="0.554" />
	<stop  offset="0.796"/>
	<stop  offset="1" />
</linearGradient>
<path d="M328.901,512H183.099c-21.369,0-41.459-8.322-56.569-23.432L23.432,385.47
	C8.322,370.36,0,350.271,0,328.901V183.099c0-21.369,8.322-41.459,23.432-56.569L126.53,23.432C141.64,8.322,161.729,0,183.099,0
	h145.803c21.369,0,41.459,8.322,56.569,23.432L488.568,126.53c15.11,15.11,23.432,35.2,23.432,56.569v145.803
	c0,21.369-8.322,41.459-23.432,56.569c-7.811,7.811-20.474,7.811-28.284,0s-7.811-20.474,0-28.284
	c7.555-7.555,11.716-17.6,11.716-28.285V183.099c0-10.685-4.161-20.729-11.716-28.285L357.186,51.716
	C349.631,44.161,339.586,40,328.901,40H183.099c-10.685,0-20.729,4.161-28.285,11.716L51.716,154.814
	C44.161,162.369,40,172.414,40,183.099v145.803c0,10.685,4.161,20.729,11.716,28.285l103.098,103.098
	c7.555,7.555,17.6,11.716,28.285,11.716h145.803c10.685,0,20.729-4.161,28.285-11.716l32.049-32.049
	c7.811-7.811,20.474-7.811,28.284,0s7.811,20.474,0,28.284l-32.049,32.049C370.36,503.678,350.271,512,328.901,512z M276.01,387
	c0-11.046-8.954-20-20-20H256c-11.046,0-19.995,8.954-19.995,20s8.959,20,20.005,20C267.056,407,276.01,398.046,276.01,387z
	 M276,317V120c0-11.046-8.954-20-20-20s-20,8.954-20,20v197c0,11.046,8.954,20,20,20C267.046,337,276,328.046,276,317z"/>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
)
}

export default Spam
