import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Input from "../components/Authentication/Input";
import Select from "../components/Authentication/Select";
import Checkbox from "../components/Authentication/Checkbox";
import Spinner from "../components/Authentication/Spinner";

import { register } from "../Services/registerService";
import auth from "../Services/authService";

//import Meta from './../Utils/Meta'
import { getLang } from "../Utils/getLang";
import { trackEvent } from "../Utils/useGAEventTracker";
import Meta1 from "../Utils/Meta1";

import "../components/Authentication/auth.css";

const SignUpScreen = () => {
  const { t } = useTranslation();

  const selectItems = ["I AM A PROPERTY OWNER", "LOOKING TO BUY/RENT PROPERTY"];
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [renterOrBuyer, setRenterOrBuyer] = useState("");
  const [policies, setPolicies] = useState("");
  const [errors, setError] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //function to submit credentials when signing up
  const onRegister = async (e) => {
    e.preventDefault();
    if (!policies) return alert(t("accept_terms"));

    if (password !== confirmPassword) {
      setError(t("kfirm_diff_frm_pword"));
    } else {
      const data = {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        email,
        password,
        post_rent_property: renterOrBuyer,
        policies,
      };

      try {
        setError("");
        setLoading(true);
        let response = await register(data);
        setLoading(false);

        auth.loginWithJwt(response.headers["x-auth-token"]);
        trackEvent("user signed up", "user sign up", "sign up button");
        window.location = "/profile";
      } catch (ex) {
        if (
          (ex.response && ex.response.status === 400) ||
          (ex.response && ex.response.status === 500)
        ) {
          setError(ex.response.data);
          setLoading(false);
        }
      }
    }
  };

  return (
    <>
      <div className="signUpOuterCon">
        <Meta1
          title={`${getLang()}.${t("Nawafrica | create_account")}`}
          description="Create a Nawafrica Account"
          link="/signUp"
        />

        <div style={{ flex: "1" }}>
          <div className="SignUpInnerCon1">
            <form onSubmit={onRegister} autoComplete="off">
              <div className="titleContainer">
                <h3>{t("CREATE_AN_ACCOUNT")}</h3>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Input
                    label={t("First_Name*")}
                    placeholder={t("First_Name_ph*")}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.currentTarget;
                      setFirstName(value);
                    }}
                    id="RegisterFN"
                    fieldLabel="RegFN"
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    label={t("Last_Name*")}
                    placeholder={t("Last_Name_ph*")}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.currentTarget;
                      setLastName(value);
                    }}
                    id="RegisterLN"
                    fieldLabel="RegLN"
                  />
                </div>

                <div className="col-lg-6">
                  <Input
                    label={t("Middle_Name")}
                    placeholder={t("Middle_Name_ph")}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.currentTarget;
                      setMiddleName(value);
                    }}
                    id="RegisterMD"
                    fieldLabel="RegMD"
                  />
                </div>

                <div className="col-lg-6">
                  <Input
                    label={t("Email*")}
                    placeholder={t("Email_ph*")}
                    type="email"
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.currentTarget;
                      setEmail(value.toLowerCase());
                    }}
                    id="RegisterEmail"
                    fieldLabel="RegEmail"
                  />
                </div>

                <div className="col-lg-6">
                  <Input
                    label={t("Password*")}
                    placeholder={t("Password_ph*")}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.currentTarget;
                      setPassword(value);
                    }}
                    id="RegisterPass"
                    fieldLabel="RegPass"
                    type="password"
                  />
                </div>

                <div className="col-lg-6">
                  <Input
                    label={t("Confirm_Password*")}
                    placeholder={t("Confirm_Password_ph*")}
                    type="password"
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.currentTarget;
                      setConfirmPassword(value);
                    }}
                    id="RegisterCP"
                    fieldLabel="RegCP"
                  />
                </div>

                <div className="col-lg-12">
                  <Select
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.currentTarget;
                      setRenterOrBuyer(value);
                    }}
                    id="landlordOrTenant"
                    items={selectItems}
                  />
                </div>

                <div className="col-lg-12">
                  <Checkbox
                    onChange={(e) => {
                      const value = e.currentTarget.checked;
                      if (value) {
                        setPolicies("agreed");
                      } else {
                        setPolicies("");
                      }
                    }}
                    value={policies}
                  />
                </div>
              </div>

              <hr />

              <div className="btnContainer">
                <button
                  disabled={loading}
                  style={{ cursor: "pointer", outline: "none" }}
                  type="submit"
                >
                  {t("SAVE")}
                </button>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {errors && <div className="alert alert-danger">{errors}</div>}
                {loading && <Spinner />}
              </div>
            </form>
          </div>
        </div>

        <div style={{ flex: "1" }}>
          <div className="googleFbContainer">
            <h5>
              <span className="orSpan">{t("OR")}</span>
              <span style={{ marginLeft: "5px" }}>{t("Sign_Up_with")}</span>
            </h5>
            <div className="btnCon">
              <div>
                <Link
                  onClick={() =>
                    trackEvent(
                      "sign up with google",
                      "/google_signUp1",
                      "google signup btn"
                    )
                  }
                  to="/google_signUp1"
                  className="btn"
                >
                  <span>
                    <img src="/images/google.jpg" />
                  </span>
                  <span className="googleLinkSpan">Google</span>
                </Link>
              </div>
              <div>
                <Link
                  onClick={() =>
                    trackEvent(
                      "sign up with facebook",
                      "/facebook_signUp1",
                      "facebook sign up btn"
                    )
                  }
                  to="/facebook_signUp1"
                  style={{ marginTop: "20px" }}
                  className="btn"
                >
                  <span>
                    <img
                      style={{
                        width: "25px",
                        height: "20px",
                        borderRadius: "100%",
                      }}
                      src="/images/fb.jpg"
                    />
                  </span>
                  <span className="googleLinkSpan">Facebook</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default SignUpScreen;
