import React, { useEffect, useState } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import axios from 'axios'
import cookies from 'js-cookie'
import { GoogleLogin } from '@react-oauth/google'
import jwtDecode from 'jwt-decode'

import { loginNowWithGoogle } from '../Services/authService'
import Spinner from '../components/Authentication/Spinner'
import '../components/Authentication/auth.css'
import logger from '../Services/loggerService'
import Meta1 from '../Utils/Meta1'

const LoginWithGoogleScreen = (props) => {
  const [publicKey, setPublicKey] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const [langType, setLangType] = useState('')

  useEffect(() => {
    getMyKey()
    window.scrollTo(0, 0)

    /*global google */
    // google.accounts.id.initialize({
    //   client_id:
    //     '874024128644-vhh5lm0fdqp9gcoagv26joek19tp5ms2.apps.googleusercontent.com',
    //   callback: responseGoogle,
    // })

    // google.accounts.id.renderButton(
    //   document.getElementById('googleBtnContainer1'),
    //   { theme: 'outline', size: 'large' }
    // )
  }, [])

  //function to get google public key from the backend
  const getMyKey = async () => {
    try {
      setLangType(cookies.get('i18next') || 'en')
      const { data } = await axios.get('/google_auth_public_key')
      setPublicKey(data)
    } catch (error) {
      logger.log(error)
    }
  }

  //function to submit google credentials to the backend
  const responseGoogle = async (response) => {
    try {
      const myData = jwtDecode(response.credential)
      setLoading(true)
      const { data } = await loginNowWithGoogle(myData)
      setLoading(false)
      localStorage.setItem('token', data)
      const { state } = props.location
      window.location = state ? state.from.pathname : '/'
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        setError(ex.response.data)
        setLoading(false)
      }
    }
  }

  return (
    <div
      style={{
        marginTop: '40px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: 'url(/images/googleBackground.jpg)',
        width: '100%',
        height: '700px',
      }}
    >
      <Meta1
        title={`${langType}.Nawafrica | google login`}
        description='Login With Google Into Nawafrica'
        link='/login/google'
      />

      <Link
        style={{ marginTop: '20px', marginLeft: '5px' }}
        to='/login'
        className='btn btn-light'
      >
        <FaArrowLeft style={{ marginRight: '5px' }} />
        go back
      </Link>

      {loading && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </div>
      )}

      <div id='googleBtnContainer1' className='googleBtnContainer'>
        <GoogleLogin
          onSuccess={responseGoogle}
          onError={() => {
            logger.log('Login Failed')
          }}
        />
        ;
        {/* <p
          style={{
            color: 'black',
            font: 'normal normal bold 16px/28px Merriweather',
          }}
        >
          Click here to Login
        </p>

        <GoogleLogin
          onSuccess={(credentialResponse) => {
            logger.log(credentialResponse)
          }}
          onError={() => {
            logger.log('Login Failed')
          }}
        /> */}
        {/* {publicKey && (
          <GoogleLogin
            clientId={
              '605418182884-dkv63vodod8ete9a7ch33lop3iptv06q.apps.googleusercontent.com'
            }
            // clientSecret="XXu0ofrxNl1c0L1IrHG_8kgM"
            buttonText='Login'
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            render={(renderProps) => (
              <button
                className='googleLoginBtn'
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <span>
                  <img
                    style={{
                      height: '30px',
                      width: '30px',
                      borderRadius: '100%',
                    }}
                    src='/images/google.jpg'
                  />
                </span>
                <span>Google</span>
              </button>
            )}
          />
        )} */}
        <p
          style={{
            color: 'red',
            font: 'normal normal normal 10px/28px Merriweather',
          }}
        >
          {error}
        </p>
      </div>
      <div className='letMeGiveSomeSeparationOverAccountable'></div>
    </div>
  )
}

export default LoginWithGoogleScreen
