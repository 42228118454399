import React from 'react'

import './RentAndSaleArticlePost.css'

const ArticleInput = ({
  title,
  id,
  placeholder,
  onChange,
  width = '49%',
  value,
}) => {
  return (
    <div style={{ width }} className='rentSaleAddInputConing'>
      <label style={{ opacity: '1' }} htmlFor={id}>
        {title}
      </label>
      <input
        onChange={onChange}
        placeholder={placeholder}
        id={id}
        className='form-control'
        value={value}
      />
    </div>
  )
}

export default ArticleInput
