import React from "react";
import DownloadIcon from "../../iconComponent/DownloadIcon";
import { FaCamera } from "react-icons/fa";
import { Image } from "cloudinary-react";

import "./profile.css";
import { Avatar } from "@mui/material";

const ProfileImage = ({ imageSrc }) => {
  return (
    <>
      <div className="PictureOuterCon">
        <DownloadIcon className="downloadIcon" />
        <p>Upload Photo</p>
        {imageSrc.fileProfile && imageSrc.fileProfile.api_key ? (
          <Avatar
            className="profileImage"
            sx={{ width: "100%", height: "100%", position: "absolute" }}
            alt="Remy Sharp"
            src={imageSrc.fileProfile.url}
          />
        ) : // <Image
        //   crossorigin='anonymous'
        //   className='profileImage'
        //   cloudName='captivator'
        //   publicId={imageSrc.fileProfile.public_id}
        //   style={{ objectFit: 'cover' }}
        // />
        imageSrc.fileProfile ? (
          <Avatar
            className="profileImage"
            sx={{ width: "100%", height: "100%", position: "absolute" }}
            alt="Remy Sharp"
            src={imageSrc.fileProfile}
          />
        ) : (
          // <img
          //   style={{ objectFit: 'cover' }}
          //   crossorigin='anonymous'
          //   className='profileImage'
          //   src={imageSrc.fileProfile}
          // />
          //  :
          //  imageSrc.fileProfile&&imageSrc.fileProfile?
          //  <img className="profileImage" src={imageSrc.fileProfile} alt="_"/>
          ""
        )}

        <div className="camaraContainer">
          <a
            type="button"
            data-toggle="modal"
            data-target="#exampleModalCenterProfile"
          >
            <FaCamera className="camaraIcon" />
          </a>
        </div>
      </div>
    </>
  );
};

export default ProfileImage;
