import React from 'react'
import { useTranslation } from 'react-i18next'

import './profile.css'

const ProfileTextarea = ({ onChange, value }) => {
  const { t } = useTranslation()
  return (
    <>
      <textarea
        placeholder={t('please_say')}
        rows='5'
        value={value}
        onChange={onChange}
        className='profileTextarea'
      />
    </>
  )
}

export default ProfileTextarea
