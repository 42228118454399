import React from 'react'
import { useTranslation } from 'react-i18next'

import './addListing.css'

const TitleHeader = () => {
  const { t } = useTranslation()

  return (
    <div className='addListTitleHeaderCon'>
      <h4>{t('ADD_NEW_LISTING')}</h4>
    </div>
  )
}

export default TitleHeader
