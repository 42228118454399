import React from 'react'

const PropManageCard1 = ({
  src = '../../images/tech_image.jpg',
  content = 'Our focus is on the local market. Every community is staffed by the very best real estate professionals; each team member is specially trained to think like an owner and take pride in resident satisfaction.',
}) => {
  return (
    <div className='propManCardOuterCon1'>
      <div className='propManCardContentCon'>{content}</div>
      <div className='imageCon'>
        <img
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          src={src}
        />
      </div>
    </div>
  )
}

export default PropManageCard1
