import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrash, FaFolderMinus } from 'react-icons/fa'

const DeleteOptions = ({ deleteFromTable, deleteTotally }) => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        zIndex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div className='deleteOptionInnerCon'>
        <div
          style={{
            flex: '1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button className='btn deleteFromTableBtn' onClick={deleteFromTable}>
            <FaFolderMinus
              style={{ marginRight: '5px' }}
              className='myHandShakeIcon'
            />
            {t("Remove from table")}
          </button>
        </div>
        <div
          style={{
            flex: '1',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button className='btn deleteAndDenyBtn' onClick={deleteTotally}>
            <FaTrash
              style={{ marginRight: '5px' }}
              className='myHandShakeIcon'
            />
            {("Delete And Deny")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeleteOptions
