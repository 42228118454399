import React from "react";
import { useTranslation } from "react-i18next";

import "./listingDetails.css";

const MoreAboutListing = ({
  listing,
  listingType,
  rentOrSell,
  bedroom,
  kitchen,
  advancedPayment,
  availableSpace,
}) => {
  const { t } = useTranslation();
  return (
    <div className="moreAboutListCon">
      <p>
        <span className="rentOrSellSpan">
          {t("listingType_for_rentOrSale", {
            listingType: t(listingType),
            rentOrSell: t(rentOrSell),
          })}{" "}
        </span>
        <span className="bedroomSpan">
          {" "}
          | {bedroom} {t("bedroom(s)")},{" "}
        </span>
        <span className="kitchenSpan">
          {" "}
          | {kitchen} {t("Kitchen(s)")}
        </span>
        <span
          className={
            availableSpace.filter((space) => space.empty_space == true).length >
            0
              ? "availableSpaceSpan"
              : "availableSpaceSpan1"
          }
        >
          {" "}
          | {
            availableSpace.filter((space) => space.empty_space == true).length
          }{" "}
          / {availableSpace.length} {t(listing.listing_type)} {t("remaining")} |{" "}
        </span>
        <span className="advancePaySpan">
          {t("Advanced_Payment/Security_Fee")}: {advancedPayment}
        </span>
      </p>
    </div>
  );
};

export default MoreAboutListing;
