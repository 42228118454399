import http from "./httpService";

//function to Add a FAQ
export function addFaq(formData, UploadProgress) {
  return http.post(`/faq`, formData, {
    headers: {
      "Content-Type": "application/json",
    },
    onUploadProgress: (progress) =>
      UploadProgress(progress.loaded / progress.total),
  });
}

export function getAnswersFaq() {
  return http.get("/faq/answers");
}
