import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import BlogImage from './BlogImage'
import BlogDisplayContent from './BlogDisplayContent'

const BlogDisplayCard = ({
  id,
  src = '../../images/felix2.jpg',
  blogType = 'HOME IMPROVEMENT',
  blogTitle = 'Platea dictumst quisque platea sagittis',
  blogPoster = 'Felix',
  blogDatePosted = '03 OCT 2022',
  blogContent = 'quisque sagittis purus. Mattis vulputate enim nulla aliquet porttitor lacus luctus porttitor lacus luctus',
  onClickDelete,
  onClickEdit,
  isadmin,
}) => {
  useEffect(() => {
    AOS.init({ duration: 500 })
  }, [])
  return (
    <div className='blogDisplayCon'>
      <div data-aos='flip-up' className='blogDisplayInnerCon'>
        <BlogImage src={src} />
        <BlogDisplayContent
          id={id}
          blogType={blogType}
          blogTitle={blogTitle}
          blogPoster={blogPoster}
          blogDatePosted={blogDatePosted}
          blogContent={blogContent}
          onClickDelete={onClickDelete}
          onClickEdit={onClickEdit}
          isadmin={isadmin}
        />
      </div>
    </div>
  )
}

export default BlogDisplayCard
