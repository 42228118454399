import React from 'react'
import { useTranslation } from 'react-i18next'

import './auth.css'

const Checkbox = ({ onChange, value }) => {
  const { t } = useTranslation()

  return (
    <div className='checkboxOuterCon'>
      <div className='checkboxLabel'>
        <b className='registerCheckbox'>
          {t('I_agree_to_the')}{' '}
          <a className='termAtag' href='/policies'>
            {t("TERMS_AND_CONDITIONS")}
          </a>{' '}
          {t("of_nawafrica")}
        </b>
      </div>
      <div className='checkboxInput'>
        <input
          className='termsConditionCheckbox'
          checked={value}
          onChange={onChange}
          type='checkbox'
        />
      </div>
    </div>
  )
}

export default Checkbox
