import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import io from "socket.io-client";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";

import Chats from "../components/AddListing/Chats";
import WelcomeUser from "../components/AddListing/WelcomeUser";
import SearchMessage from "../components/Messages/SearchMessage";
import MessagesTable from "../components/Messages/MessagesTable";
import MessageTitle from "../components/Messages/MessageTitle";
import InboxModal from "../components/Messages/InboxModal";
import ComposeMessageModal from "../components/Messages/ComposeMessageModal";
import { getApplications } from "../Services/listings";
import { getCurrentUser } from "./../Services/authService";
import { getLang } from "../Utils/getLang";

import {
  getRecipentMessages,
  deleteGivenMessageById,
} from "./../Services/messageService";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import SideBarManagement from "./../components/ManageTenants/SideBarManagement";
import OpenButtonManagement from "../components/ManageTenants/OpenButtonManagement";
import UnreadMsgsContext from "./../unreadmessages/context";
import Meta from "./../Utils/Meta";

import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import { trackEvent } from "../Utils/useGAEventTracker";
import { uploadSingleFileToCloudinary } from "../Utils/imageUploadToCloudinary";
import { getCloudinarySignature } from "../Services/cloudinaryService";
import "../components/Messages/messages.css";

const ManageMessages = () => {
  const { t } = useTranslation();

  const [close, setClose] = useState(true);
  const [path, setPath] = useState("");
  const [upSideBar, setUpSideBar] = useState(false);

  const [applications, setApplications] = useState([]);
  const [sortColumns, setSortColumns] = useState({
    path: "appicant_name",
    order: "asc",
  });
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [otherPhotoError, setOtherPhotoError] = useState("");
  const [loadOtherPhoto, setLoadOtherPhoto] = useState(false);

  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [selectedApplication, setSelectedApplication] = useState();
  const [otherPhotoPath, setOtherPhotoPath] = useState([]);
  const [messages, setMessages] = useState([]);
  const [submitError, setSubmitError] = useState("");

  const [socket, setSocket] = useState();
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [loadingSearchMessage, setLoadingSearchMessage] = useState(false);
  const [myImageData, setMyImageData] = useState({
    cloud_name: "",
  });

  const { unreadMsgs, setUnreadMsgs } = useContext(UnreadMsgsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("managementPath", "/management/message");
    const myPath = localStorage.getItem("managementPath");
    setPath(myPath);

    const sock = io();
    setSocket(sock);
    sock.on(
      `${getCurrentUser() && getCurrentUser().id}messagesTenant`,
      (data) => {
        const sortedListing = _.orderBy(
          data,
          [sortColumn.path],
          [sortColumn.order]
        );
        setMessages(sortedListing);
      }
    );

    getAllMyDatas();
    return () => {
      localStorage.setItem("managePath", "");
    };
  }, []);

  //function to get messages sent to landlord
  const getAllMyDatas = async () => {
    try {
      setLoadingMessage(true);
      const { data } = await getApplications();
      const myAppl = data.filter(
        (d) => d.list_owner_id === getCurrentUser().id
      );
      const sortedListing = _.orderBy(
        myAppl,
        [sortColumns.path],
        [sortColumns.order]
      );
      setApplications(sortedListing);

      const { data: messages } = await getRecipentMessages(
        getCurrentUser() && getCurrentUser().id
      );
      const sortedMessages = _.orderBy(
        messages,
        [sortColumn.path],
        [sortColumn.order]
      );
      setMessages(sortedMessages);
      const { data: imagesData } = await getCloudinarySignature();
      setMyImageData(imagesData);
      setLoadingMessage(false);
    } catch (error) {
      logger.log(
        "the error from getAllMyDatas function in ManageMessage component",
        error
      );
    }
  };

  //function to close sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to upon sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to upload multiple photos when writing a message
  const onChangeOtherPhoto = async (e) => {
    e.preventDefault();
    const filesss = e.currentTarget.files[0];
    if (!filesss) return;
    if (filesss.size > 8323863) {
      setOtherPhotoError(
        "large file, upload a smaller file not greater than 8MB"
      );
    } else {
      setLoadOtherPhoto(true);
      setOtherPhotoError("");

      if (filesss.type.startsWith("image/")) {
        let src = URL.createObjectURL(filesss);

        //convert to canvas
        const canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        let userImage = new Image();
        userImage.src = src;
        userImage.onload = () => {
          canvas.width = userImage.naturalWidth;
          canvas.height = userImage.naturalHeight;
          ctx.drawImage(userImage, 0, 0);

          canvas.toBlob(async (blob) => {
            const myImage = new File([blob], filesss.name, { type: blob.type });

            const result = await uploadSingleFileToCloudinary(
              myImage,
              myImageData
            );

            if (
              !result ||
              !result.url ||
              (result && result.path && result.path["error"])
            ) {
              setOtherPhotoError(
                "The was an error loading your image, please reload this image"
              );
              setLoadOtherPhoto(false);
              return;
            }

            setOtherPhotoPath([...otherPhotoPath, { ...result }]);
            setLoadOtherPhoto(false);
          }, "image/webp");
        };
      } else {
        const result = await uploadSingleFileToCloudinary(filesss, myImageData);
        setOtherPhotoPath([...otherPhotoPath, { ...result }]);
        setLoadOtherPhoto(false);
      }
    }
  };

  //function to attach emoji to a message
  const onEmojiClick = (event, emojiObject) => {
    const myEmojiMessage = message + emojiObject.emoji;
    setMessage(myEmojiMessage);
  };

  //function to submit a message when writing it
  const onSubmitMessage = (e) => {
    e.preventDefault();
    const myData = {
      message,
      subject,
      selectedApplication,
      images: otherPhotoPath,
      sender: getCurrentUser().id,
    };

    socket.emit("message", myData, (error) => {
      if (error) {
        setSubmitError(error);
      } else {
        alert("MESSAGE SENT");
        trackEvent(
          `${getCurrentUser() && getCurrentUser().id} sent a message`,
          myData.message,
          "sent message btn"
        );
      }
    });

    if (message && selectedApplication && subject) {
      setMessage("");
      setSelectedApplication("");
      setOtherPhotoPath([]);
      setSubject("");
      setSubmitError("");
      const myVideoToPlay = document.getElementById("myVideoPlay");
      myVideoToPlay.play();
    }
  };

  //function to delete a message
  const onDelete = async (message) => {
    try {
      if (
        window.confirm("Are you sure you want to delete this message!!!!!?")
      ) {
        const allMessages = [...messages];
        const theRemainingMessage = allMessages.filter(
          (mess) => mess.id !== message.id
        );
        setMessages(theRemainingMessage);
        trackEvent(
          `${getCurrentUser() && getCurrentUser().id} delete a message`,
          `message delete is ${message.id}`,
          "manage tenant delete message"
        );
        await deleteGivenMessageById(message.id);
      }
    } catch (error) {
      logger.log(
        "error from onDelete function in ManageMessages component",
        error
      );
    }
  };

  //function to delete a picture or file you dont to send when writing a message
  const ondeletePicture = (pic) => {
    const images = [...otherPhotoPath];
    const myImages = images.filter((img) => img !== pic);
    setOtherPhotoPath(myImages);
  };

  //sorting the of listings
  const onSort = (e) => {
    e.preventDefault();
    const isListings = [...messages];
    const { value } = e.currentTarget;
    const column = { ...sortColumns };
    column.path = value;
    const sortedListing = _.orderBy(isListings, [column.path], [column.order]);
    setMessages(sortedListing);
  };

  //function to search a listing

  const onSearchSender = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTable(value, messages);
      setMessages(tableData);
      // setFromSearch(true)
    } else {
      try {
        setLoadingSearchMessage(true);
        const { data: messages } = await getRecipentMessages(
          getCurrentUser() && getCurrentUser().id
        );
        const sortedMessages = _.orderBy(
          messages,
          [sortColumn.path],
          [sortColumn.order]
        );
        setMessages(sortedMessages);
        setLoadingSearchMessage(false);
      } catch (error) {
        logger.log("error in onSearchSender function in MessagesScreen", error);
      }
    }
  };

  const searchTable = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].sender_name.toLowerCase();

      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  if (loadingMessage)
    return (
      <div className="homeSpinnerContainer">
        {/* <ProfileSpinner /> */}
        <ProfileSpinner />
      </div>
    );
  return (
    <>
      <div>
        <audio
          style={{ display: "none" }}
          id="myVideoPlay"
          src="/images/messageSound.mp3"
        ></audio>
      </div>

      <Meta
        title={`${getLang()}.${`${t("meta_message", {
          name: getCurrentUser().first_name,
        })}`}`}
      />

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      {close && !upSideBar && (
        <SideBarManagement
          onClickCloseBar={onClickCloseBar}
          path={path}
          onTakeBarUp={onTakeBarUp}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButtonManagement
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <div className="messagesScreenOuterCon">
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        <InboxModal />

        <ComposeMessageModal
          applications={applications}
          onChangeApplicationSelect={(e) => {
            e.preventDefault();
            const { value } = e.target;
            setSelectedApplication(value);
          }}
          onChangeSubject={(e) => {
            e.preventDefault();
            const { value } = e.target;
            setSubject(value);
          }}
          onChangeMessage={(e) => {
            e.preventDefault();
            const { value } = e.target;
            setMessage(value);
          }}
          onChangeImages={onChangeOtherPhoto}
          otherPhotoPath={otherPhotoPath}
          onEmojiClick={onEmojiClick}
          loadOtherPhoto={loadOtherPhoto}
          message={message}
          onSubmitMessage={onSubmitMessage}
          valueApplicant={selectedApplication}
          valueSubject={subject}
          submitError={submitError}
          ondeletePicture={ondeletePicture}
        />

        <WelcomeUser user={getCurrentUser() && getCurrentUser().first_name} />

        <MessageTitle />

        <div className={close ? "messagesInnerCon" : "messagesInnerCon1"}>
          <SearchMessage
            onChangeSearch={onSearchSender}
            onChangeSort={onSort}
          />

          {messages.length > 0 ? (
            <MessagesTable
              messages={messages}
              onDelete={onDelete}
              path={path}
              loadingSearchMessage={loadingSearchMessage}
              onClickMessage={(id) => {
                let msg = [...unreadMsgs];
                let remain = msg.filter((m) => m.id !== id);
                setUnreadMsgs(remain);
              }}
            />
          ) : loadingSearchMessage ? (
            <div>....loading</div>
          ) : (
            <div className="noListingAvailableYetCon">
              <div className="alert">No Message Available Yet</div>
            </div>
          )}
        </div>
      </div>
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default ManageMessages;
