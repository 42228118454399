export function animate() {
  return {
    nm: 'Paperplane',
    mn: '',
    layers: [
      {
        ty: 0,
        nm: 'Pre-comp 1',
        mn: '',
        sr: 1,
        st: 0,
        op: 147,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [400, 300, 0], ix: 1 },
          s: { a: 0, k: [179, 179, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [406, 306, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        w: 800,
        h: 600,
        refId: 'comp_0',
        ind: 0,
      },
    ],
    ddd: 0,
    h: 600,
    w: 800,
    meta: {
      a: '',
      k: '',
      d: '',
      g: '@lottiefiles/toolkit-js 0.21.1',
      tc: '#ffffff',
    },
    v: '5.5.8',
    fr: 50,
    op: 147,
    ip: 0,
    assets: [
      {
        nm: '',
        mn: '',
        layers: [
          {
            ty: 4,
            nm: 'planete Outlines - Group 4',
            mn: '',
            sr: 1,
            st: 0,
            op: 151,
            ip: 0,
            hd: false,
            cl: '',
            ln: '',
            ddd: 0,
            bm: 0,
            tt: 0,
            hasMask: false,
            td: 0,
            ao: 0,
            ks: {
              a: { a: 0, k: [453.672, 304.756, 0], ix: 1 },
              s: { a: 0, k: [50, 50, 100], ix: 6 },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [468.336, 323.378, 0],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [294.336, 323.378, 0],
                    t: 102,
                  },
                ],
                ix: 2,
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [50],
                    t: 38,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [50],
                    t: 88,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 120,
                  },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
            },
            ef: [],
            shapes: [
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'Group 4',
                ix: 1,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [6.742, 0],
                          [0.741, -0.14],
                          [0, 0.074],
                          [13.484, 0],
                          [1.669, -0.361],
                          [19.79, 0],
                          [3.317, -19.082],
                          [2.691, 0],
                          [0, -13.484],
                          [-0.048, -0.629],
                          [2.405, 0],
                          [0, -6.742],
                          [-6.742, 0],
                          [0, 0],
                          [0, 6.743],
                        ],
                        o: [
                          [-0.781, 0],
                          [0.001, -0.074],
                          [0, -13.484],
                          [-1.778, 0],
                          [-3.594, -18.742],
                          [-20.03, 0],
                          [-2.421, -0.804],
                          [-13.485, 0],
                          [0, 0.642],
                          [-1.89, -1.199],
                          [-6.742, 0],
                          [0, 6.743],
                          [0, 0],
                          [6.742, 0],
                          [0, -6.742],
                        ],
                        v: [
                          [75.134, 16.175],
                          [72.85, 16.396],
                          [72.856, 16.175],
                          [48.44, -8.241],
                          [43.262, -7.685],
                          [3.406, -40.591],
                          [-36.571, -6.995],
                          [-44.269, -8.241],
                          [-68.685, 16.175],
                          [-68.604, 18.079],
                          [-75.133, 16.175],
                          [-87.341, 28.383],
                          [-75.133, 40.592],
                          [75.134, 40.592],
                          [87.342, 28.383],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'fl',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Graphic - Fill',
                    nm: 'Fill 1',
                    c: { a: 0, k: [0.8157, 0.8235, 0.8275], ix: 4 },
                    r: 1,
                    o: { a: 0, k: 100, ix: 5 },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [453.672, 304.756], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
            ],
            ind: 0,
          },
          {
            ty: 4,
            nm: 'Merged Shape Layer',
            mn: '',
            sr: 1,
            st: 0,
            op: 151,
            ip: 0,
            hd: false,
            cl: '',
            ln: '',
            ddd: 0,
            bm: 0,
            tt: 0,
            hasMask: false,
            td: 0,
            ao: 0,
            ks: {
              a: { a: 0, k: [664.319, 256.2, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [390.319, 298.2, 0],
                    t: 0,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [390.319, 282.7, 0],
                    t: 44,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [390.319, 319.25, 0],
                    t: 110,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [390.319, 298.2, 0],
                    t: 150,
                  },
                ],
                ix: 2,
              },
              sa: { a: 0, k: 0 },
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.547, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.845, y: 1 },
                    s: [35],
                    t: 77,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 150,
                  },
                ],
                ix: 10,
              },
            },
            ef: [],
            shapes: [
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'planete Outlines - Group 1',
                ix: 1,
                cix: 2,
                np: 1,
                it: [
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 1',
                    ix: 1,
                    cix: 2,
                    np: 2,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [18.967, -3.189],
                              [-18.967, 19.935],
                              [-0.949, -19.935],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'fl',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Graphic - Fill',
                        nm: 'Fill 1',
                        c: { a: 0, k: [0.2235, 0.1922, 0.6745], ix: 4 },
                        r: 1,
                        o: { a: 0, k: 100, ix: 5 },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [236.879, 292.737], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [236.879, 292.737], ix: 1 },
                    s: { a: 0, k: [50, 50], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [633.939, 275.369], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'planete Outlines - Group 2',
                ix: 2,
                cix: 2,
                np: 1,
                it: [
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 2',
                    ix: 1,
                    cix: 2,
                    np: 2,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-98.335, 64.79],
                              [-105.619, 4.984],
                              [105.619, -64.79],
                              [-80.316, 24.919],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'fl',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Graphic - Fill',
                        nm: 'Fill 1',
                        c: { a: 0, k: [0.0745, 0.651, 0.5922], ix: 4 },
                        r: 1,
                        o: { a: 0, k: 100, ix: 5 },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [316.247, 247.882], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [316.247, 247.882], ix: 1 },
                    s: { a: 0, k: [50, 50], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [673.623, 252.941], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'planete Outlines - Group 3',
                ix: 3,
                cix: 2,
                np: 1,
                it: [
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 3',
                    ix: 1,
                    cix: 2,
                    np: 2,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-133.812, -42.171],
                              [133.812, -75.141],
                              [5.765, 75.141],
                              [-61.708, 18.402],
                              [124.227, -71.307],
                              [-87.011, -1.534],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'fl',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Graphic - Fill',
                        nm: 'Fill 1',
                        c: { a: 0, k: [0.0745, 0.651, 0.5922], ix: 4 },
                        r: 1,
                        o: { a: 0, k: 100, ix: 5 },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [297.638, 254.4], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [297.638, 254.4], ix: 1 },
                    s: { a: 0, k: [50, 50], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [664.319, 256.2], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'planete Outlines - Group 5',
            mn: '',
            sr: 1,
            st: 0,
            op: 151,
            ip: 0,
            hd: false,
            cl: '',
            ln: '',
            ddd: 0,
            bm: 0,
            tt: 0,
            hasMask: false,
            td: 0,
            ao: 0,
            ks: {
              a: { a: 0, k: [171.76, 193.166, 0], ix: 1 },
              s: { a: 0, k: [50, 50, 100], ix: 6 },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [327.38, 267.583, 0],
                    t: 0,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [482.38, 267.583, 0],
                    t: 150,
                  },
                ],
                ix: 2,
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 45,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100],
                    t: 102,
                  },
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 150,
                  },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
            },
            ef: [],
            shapes: [
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'Group 5',
                ix: 1,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [13.485, 0],
                          [4.38, -4.171],
                          [21.913, 0],
                          [3.575, -18.765],
                          [1.851, 0],
                          [0, -13.484],
                          [-0.011, -0.291],
                          [1.599, 0],
                          [0, -6.743],
                          [-6.742, 0],
                          [0, 0],
                          [0, 13.485],
                        ],
                        o: [
                          [-6.526, 0],
                          [-0.793, -21.719],
                          [-19.806, 0],
                          [-1.734, -0.391],
                          [-13.485, 0],
                          [0, 0.293],
                          [-1.4, -0.559],
                          [-6.742, 0],
                          [0, 6.742],
                          [0, 0],
                          [13.485, 0],
                          [0, -13.484],
                        ],
                        v: [
                          [59.669, -8.242],
                          [42.84, -1.506],
                          [2.287, -40.592],
                          [-37.576, -7.638],
                          [-42.962, -8.242],
                          [-67.378, 16.174],
                          [-67.356, 17.049],
                          [-71.878, 16.174],
                          [-84.086, 28.383],
                          [-71.878, 40.591],
                          [59.669, 40.591],
                          [84.086, 16.174],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'fl',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Graphic - Fill',
                    nm: 'Fill 1',
                    c: { a: 0, k: [0.8157, 0.8235, 0.8275], ix: 4 },
                    r: 1,
                    o: { a: 0, k: 100, ix: 5 },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [171.76, 193.166], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
            ],
            ind: 2,
          },
        ],
        id: 'comp_0',
        fr: 30,
      },
    ],
  }
}
