import React from "react";
import "simplebar";
import "simplebar/dist/simplebar.css";
import { useTranslation } from "react-i18next";

import "./leases.css";
import AgreementLeasesList1 from "./AgreementLeasesList1";

const AgreementLeasesList = ({
  myUnderReviews,
  selectedLeaseToSign,
  listings,
  users,
  onClick,
  onDeleteReview,
}) => {
  const { t } = useTranslation();

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const ListingTitle = (id) => {
    const listing = listings.filter((list) => list.id === id);

    return listing[0]
      ? {
          title:
            listing[0].title.length > 20
              ? capitalize(listing[0].title.slice(0, 20) + "..")
              : capitalize(listing[0].title),
          address: capitalize(listing[0].street_address),
        }
      : "";
  };

  const userName = (id) => {
    const usersss = users && users.filter((user) => user.id === id);
    return capitalize(
      `${usersss[0].first_name} ${usersss[0].last_name}`.length > 17
        ? `${usersss[0].first_name} ${usersss[0].last_name}`.slice(0, 17) + ".."
        : `${usersss[0].first_name} ${usersss[0].last_name}`
    );
  };

  return (
    <div
      style={{ padding: "0 2px" }}
      data-simplebar
      className="agreementListItemsCon"
    >
      <div className="ListItemInnerCon">
        <div className="listItemTitle">
          <h5>{t("SIGN_YOUR_LEASES")}</h5>
        </div>
        <hr style={{ width: "100%", margin: "5px 0" }} />
        <AgreementLeasesList1
          myUnderReviews={myUnderReviews}
          selectedLeaseToSign={selectedLeaseToSign}
          listings={listings}
          users={users}
          onClick={onClick}
          onDeleteReview={onDeleteReview}
        />
      </div>
    </div>
  );
};

export default AgreementLeasesList;
