import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";
import SearchIcon from "@mui/icons-material/Search";
import { Box, TextField } from "@mui/material";

import "../Listing/listing.css";
// import ReactTooltip from 'react-tooltip';

const SearchPaymentLeaseSign = ({
  onChangeSearchAmount,
  onChangeSearchPayDate,
  onChangeSearchPaymentMethod,
  onChangeSort,
  currency,
  closeDeal,
}) => {
  const { t } = useTranslation();

  return (
    <div className="listingSearchCon">
      <div className="searchPaymentLeaseMakePayBtn">
        {!closeDeal && (
          <a
            style={{ boxShadow: "3px 1px 3px 0.5px black" }}
            href="#"
            data-toggle="modal"
            data-target="#exampleModalMakePayment"
          >
            <span style={{ marginRight: "5px" }}>
              <FontAwesomeIcon
                style={{
                  fontSize: "20px",
                }}
                icon={faBagShopping}
              />
            </span>
            {t("Make_Payment")}
          </a>
        )}
      </div>

      <div className="inputFieldCon someMarginInLeasignDetailInput">
        {/* <input
          className='myListingSearchInput'
          onChange={onChangeSearchAmount}
          placeholder={t('Search_Amount_Paid')}
        /> */}
        <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            inputProps={{
              style: { font: "normal normal normal 14px/25px Merriweather" },
            }}
            sx={{ width: "100%" }}
            onChange={onChangeSearchAmount}
            id="input-with-sx"
            label={t("Search_Amount_Paid")}
            variant="standard"
          />
        </Box>
      </div>

      <div className="searchPaymentDateContainer someMarginInLeasignDetailInput">
        {/* <input
          className='myListingSearchInput'
          onChange={onChangeSearchPayDate}
          placeholder={t('Search_payment_Date')}
        /> */}
        <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            inputProps={{
              style: { font: "normal normal normal 14px/25px Merriweather" },
            }}
            sx={{ width: "100%" }}
            onChange={onChangeSearchPayDate}
            id="input-with-sx"
            label={t("Search_payment_Date")}
            variant="standard"
          />
        </Box>
      </div>

      <div className="searchPaymentMethodInputCon someMarginInLeasignDetailInput">
        {/* <input
          className='myListingSearchInput'
          onChange={onChangeSearchPaymentMethod}
          placeholder={t('Search_payment_method')}
        /> */}
        <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            inputProps={{
              style: { font: "normal normal normal 14px/25px Merriweather" },
            }}
            sx={{ width: "100%" }}
            onChange={onChangeSearchPaymentMethod}
            id="input-with-sx"
            label={t("Search_payment_method")}
            variant="standard"
          />
        </Box>
      </div>

      <div className="listingSortDropdown someLeaseMargin">
        <p>
          <span className="sortedBySpan">{t("Sort_By")}</span>
          <span className="listingSortSelectSpan">
            <select className="makePaymentSelect" onChange={onChangeSort}>
              <option value="dateposted">{t("Date")}</option>
              <option value="amount">{t("Amount", { currency })}</option>
              <option value="payment_method">{t("Payment_Method")}</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  );
};

export default SearchPaymentLeaseSign;
