import React from 'react'
import User from '../../iconComponent/User'

const VerifiedBadge = () => {
  return (
    <div
      data-tip='Verified'
      style={{
        position: 'absolute',
        zIndex: '1',
        right: '5px',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#035AA6',
        width: '30px',
        height: '60px',
        display: 'flex',
        clipPath: 'polygon(0 0, 100% 0, 100% 51%, 50% 100%, 0 54%)',
      }}
    >
      <User className='DetailsUserIcon1' />
    </div>
  )
}

export default VerifiedBadge
