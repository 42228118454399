import { Alert, Badge, Divider, IconButton } from "@mui/material";
import React from "react";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const TenantBadge = ({
  houseNumber = 1,
  color = "#035aa6",
  listing,
  users,
  space,
  tenants,
}) => {
  const { t } = useTranslation();
  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  return tenants?.find(
    (t) => t.listing_id === listing?.id && t.tenant_id == space?.occupant_id
  )?.registered_tenant ? (
    <HtmlTooltip
      title={
        <React.Fragment>
          <Typography
            sx={
              tenants?.find(
                (t) =>
                  t.listing_id == listing?.id &&
                  t.tenant_id == space?.occupant_id
              )?.amount_owed > 0
                ? {
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#ee2400",
                  }
                : {
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "#035aa6",
                  }
            }
            color="inherit"
            variant="h6"
          >
            {capitalize(`${listing?.title}`)} {t(listing?.listing_type)} #
            {houseNumber}
          </Typography>
          {users?.find((user) => user.id == space?.occupant_id)?.picture
            ?.fileProfile?.url && (
            <div
              style={{
                width: "100%",
                height: "150px",
                padding: "5px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTop: "1px solid #dadde9",
                borderBottom: "1px solid #dadde9",
                background: "#D3D3D3",
              }}
            >
              {users?.find((user) => user.id == space?.occupant_id)?.picture
                ?.fileProfile?.url ? (
                <img
                  src={
                    users?.find((user) => user.id == space?.occupant_id)
                      ?.picture?.fileProfile?.url
                  }
                  style={{ width: "100%", height: "100%" }}
                />
              ) : space.empty_space == true ? (
                <>
                  <Alert severity="info">{t("Empty")} {t(listing?.listing_type)}.</Alert>
                </>
              ) : (
                <div
                  style={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "50%",
                    background: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid #dadde9",
                  }}
                >
                  <PersonIcon style={{ fontSize: "50px" }} />
                </div>
              )}
            </div>
          )}

          {users?.find((user) => user.id == space?.occupant_id)?.first_name && (
            <Divider
              style={{ width: "100%", margin: "7px auto" }}
              orientation="horizontal"
              flexItem
            />
          )}

          {users?.find((user) => user.id == space?.occupant_id)?.first_name && (
            <p>
              <>
                <span
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Name")} :{" "}
                </span>
                <Typography
                  sx={
                    tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.amount_owed > 0
                      ? {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#ee2400",
                        }
                      : {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#035aa6",
                        }
                  }
                  color="inherit"
                  variant="p"
                >
                  {capitalize(
                    `${
                      users?.find((user) => user.id == space?.occupant_id)
                        ?.first_name
                    }`
                  )}{" "}
                  {capitalize(
                    `${
                      users?.find((user) => user.id == space?.occupant_id)
                        ?.last_name
                    }`
                  )}
                </Typography>
              </>
            </p>
          )}
          <p>
            {users?.find((user) => user.id == space?.occupant_id)
              ?.first_name && (
              <>
                <span
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Email")} :{" "}
                </span>
                <Typography
                  sx={
                    tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.amount_owed > 0
                      ? {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#ee2400",
                        }
                      : {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#035aa6",
                        }
                  }
                  color="inherit"
                  variant="p"
                >
                  {users?.find((user) => user.id == space?.occupant_id)?.email}
                </Typography>
              </>
            )}
          </p>
          {users?.find((user) => user.id == space?.occupant_id)?.first_name && (
            <p>
              <>
                <span
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Contact")} :{" "}
                </span>
                <Typography
                  sx={
                    tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.amount_owed > 0
                      ? {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#ee2400",
                        }
                      : {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#035aa6",
                        }
                  }
                  color="inherit"
                  variant="p"
                >
                  {users?.find((user) => user.id == space?.occupant_id)?.contact
                    ? users?.find((user) => user.id == space?.occupant_id)
                        ?.contact
                    : "  ----------"}
                </Typography>
              </>
            </p>
          )}
          {users?.find((user) => user.id == space?.occupant_id)?.first_name && (
            <p>
              <>
                <span
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Profession")} :{" "}
                </span>
                <Typography
                  sx={
                    tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.amount_owed > 0
                      ? {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#ee2400",
                        }
                      : {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#035aa6",
                        }
                  }
                  color="inherit"
                  variant="p"
                >
                  {users?.find((user) => user.id == space?.occupant_id)?.contact
                    ? users?.find((user) => user.id == space?.occupant_id)
                        ?.profession
                    : "  ----------"}
                </Typography>
              </>
            </p>
          )}

          {tenants?.find(
            (t) =>
              t.listing_id === listing?.id && t.tenant_id == space?.occupant_id
          )?.total_amount_paid && (
            <Divider
              style={{ width: "100%", margin: "7px auto" }}
              orientation="horizontal"
              flexItem
            />
          )}
          {tenants?.find(
            (t) =>
              t.listing_id === listing?.id && t.tenant_id == space?.occupant_id
          )?.total_amount_paid && (
            <p>
              <>
                <span
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Total Amount Paid")} :{" "}
                </span>
                <Typography
                  sx={
                    tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.amount_owed > 0
                      ? {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#ee2400",
                        }
                      : {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#035aa6",
                        }
                  }
                  color="inherit"
                  variant="p"
                >
                  {commaSeparateNumber(
                    tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.total_amount_paid
                  )}{" "}
                  {listing?.currency}
                </Typography>
              </>
            </p>
          )}

          {tenants?.find(
            (t) =>
              t.listing_id == listing?.id && t.tenant_id == space?.occupant_id
          )?.total_amount_paid && (
            <p>
              <>
                <span
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: "bold",
                  }}
                >
                  {t("Total Amount Owed")} :{" "}
                </span>
                <Typography
                  sx={
                    tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.amount_owed > 0
                      ? {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#ee2400",
                        }
                      : {
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#035aa6",
                        }
                  }
                  color="inherit"
                  variant="p"
                >
                  {tenants?.find(
                    (t) =>
                      t.listing_id == listing?.id &&
                      t.tenant_id == space?.occupant_id
                  )?.amount_owed >= 0
                    ? commaSeparateNumber(
                        tenants?.find(
                          (t) =>
                            t.listing_id == listing?.id &&
                            t.tenant_id == space?.occupant_id
                        )?.amount_owed
                      )
                    : 0}{" "}
                  {listing?.currency}
                </Typography>
              </>
            </p>
          )}
          {/*
          {space?.all_occupants && (
            <Divider
              style={{ width: "100%", margin: "7px auto" }}
              orientation="horizontal"
              flexItem
            />
          )} */}
          {/* {space?.all_occupants && (
            <Typography
              sx={
                tenants?.find(
                  (t) =>
                    t.listing_id === listing?.id &&
                    t.tenant_id == space?.occupant_id
                )?.amount_owed > 0
                  ? {
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#ee2400",
                    }
                  : {
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "12px",
                      color: "#035aa6",
                    }
              }
              color="inherit"
              variant="h6"
            >
              {" "}
              Names of all previous occupant of{" "}
              {capitalize(`${listing?.title}`)} {listing?.listing_type} #
              {houseNumber}
            </Typography>
          )} */}
          {/* .filter(o=>o!=space?.occupant_id) */}
          {/* {space?.all_occupants.filter(
            (o) => o.occupant_id != space?.occupant_id
          )?.length == 0 && (
            <Alert style={{ fontSize: "12px" }} severity="info">
              No previous occupant!
            </Alert>
          )}
          {space?.all_occupants
            .filter((o) => o.occupant_id != space?.occupant_id)
            .map((occupant, index) => (
              <ol key={`${index}`} type="1">
                <li>
                  {capitalize(`${occupant.occupant_name}`) +
                    " - " +
                    occupant.start_date}
                </li>
              </ol>
            ))} */}
        </React.Fragment>
      }
    >
      <Link
        to={
          space.empty_space == false &&
          `/portal/tenants/${
            tenants?.find(
              (t) =>
                t.listing_id == listing?.id && t.tenant_id == space?.occupant_id
            )?.id
          }`
        }
      >
        {space.empty_space == true && (
          <IconButton
            onClick={() =>
              alert(
                `${listing.listing_type} #${
                  space.space_number
                } is empty for ${capitalize(`${listing.title}`)}`
              )
            }
            type="button"
          >
            <Badge
              // style={{ margin: "7px" }}
              badgeContent={houseNumber}
              color="success"
            >
              <OtherHousesIcon
                style={{ cursor: "pointer", color }}
                color="action"
              />
            </Badge>
          </IconButton>
        )}
        {space.empty_space == false && (
          <IconButton type="button">
            <Badge
              // style={{ margin: "7px" }}
              badgeContent={houseNumber}
              color={
                tenants?.find(
                  (t) =>
                    t.listing_id == listing?.id &&
                    t.tenant_id == space?.occupant_id
                )?.amount_owed <= 0
                  ? "success"
                  : "error"
              }
            >
              <OtherHousesIcon
                style={{ cursor: "pointer", color }}
                color="action"
              />
            </Badge>
          </IconButton>
        )}
      </Link>
    </HtmlTooltip>
  ) : (
    <>
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography
              sx={
                tenants?.find(
                  (t) =>
                    t.listing_id == listing?.id &&
                    t.tenant_id == space?.occupant_id
                )?.amount_owed > 0
                  ? {
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#ee2400",
                    }
                  : {
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#035aa6",
                    }
              }
              color="inherit"
              variant="h6"
            >
              {capitalize(`${listing?.title}`)} {t(listing?.listing_type)} #
              {houseNumber}
            </Typography>
            {tenants?.find(
              (t) =>
                t.listing_id === listing?.id &&
                t.tenant_id == space?.occupant_id
            )?.picture?.url && (
              <div
                style={{
                  width: "100%",
                  height: "150px",
                  padding: "5px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderTop: "1px solid #dadde9",
                  borderBottom: "1px solid #dadde9",
                  background: "#D3D3D3",
                }}
              >
                {tenants?.find(
                  (t) =>
                    t.listing_id === listing?.id &&
                    t.tenant_id == space?.occupant_id
                )?.picture?.url ? (
                  <img
                    src={
                      tenants?.find(
                        (t) =>
                          t.listing_id === listing?.id &&
                          t.tenant_id == space?.occupant_id
                      )?.picture?.url
                    }
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <div
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      background: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #dadde9",
                    }}
                  >
                    <PersonIcon style={{ fontSize: "50px" }} />
                  </div>
                )}
              </div>
            )}

            {space.empty_space == true && (
              <>
                <Alert severity="info">{t("Empty")} {t(listing?.listing_type)}.</Alert>
              </>
            )}

            {tenants?.find(
              (t) =>
                t.listing_id == listing?.id && t.tenant_id == space?.occupant_id
            )?.tenant_name && (
              <Divider
                style={{ width: "100%", margin: "7px auto" }}
                orientation="horizontal"
                flexItem
              />
            )}

            {tenants?.find(
              (t) =>
                t.listing_id == listing?.id && t.tenant_id == space?.occupant_id
            )?.tenant_name && (
              <p>
                <>
                  <span
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Name")} :{" "}
                  </span>
                  <Typography
                    sx={
                      tenants?.find(
                        (t) =>
                          t.listing_id == listing?.id &&
                          t.tenant_id == space?.occupant_id
                      )?.amount_owed > 0
                        ? {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#ee2400",
                          }
                        : {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#035aa6",
                          }
                    }
                    color="inherit"
                    variant="p"
                  >
                    {capitalize(
                      `${
                        tenants?.find(
                          (t) =>
                            t.listing_id == listing?.id &&
                            t.tenant_id == space?.occupant_id
                        )?.tenant_name
                      }`
                    )}{" "}
                  </Typography>
                </>
              </p>
            )}

            {tenants?.find(
              (t) =>
                t.listing_id == listing?.id && t.tenant_id == space?.occupant_id
            )?.contact_number && (
              <p>
                <>
                  <span
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Contact")} :{" "}
                  </span>
                  <Typography
                    sx={
                      tenants?.find(
                        (t) =>
                          t.listing_id == listing?.id &&
                          t.tenant_id == space?.occupant_id
                      )?.amount_owed > 0
                        ? {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#ee2400",
                          }
                        : {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#035aa6",
                          }
                    }
                    color="inherit"
                    variant="p"
                  >
                    {tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.contact_number
                      ? tenants?.find(
                          (t) =>
                            t.listing_id == listing?.id &&
                            t.tenant_id == space?.occupant_id
                        )?.contact_number
                      : "  ----------"}
                  </Typography>
                </>
              </p>
            )}
            {tenants?.find(
              (t) =>
                t.listing_id == listing?.id && t.tenant_id == space?.occupant_id
            )?.job_title && (
              <p>
                <>
                  <span
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Profession")} :{" "}
                  </span>
                  <Typography
                    sx={
                      tenants?.find(
                        (t) =>
                          t.listing_id == listing?.id &&
                          t.tenant_id == space?.occupant_id
                      )?.amount_owed > 0
                        ? {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#ee2400",
                          }
                        : {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#035aa6",
                          }
                    }
                    color="inherit"
                    variant="p"
                  >
                    {tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.job_title
                      ? tenants?.find(
                          (t) =>
                            t.listing_id == listing?.id &&
                            t.tenant_id == space?.occupant_id
                        )?.job_title
                      : "  ----------"}
                  </Typography>
                </>
              </p>
            )}

            {tenants?.find(
              (t) =>
                t.listing_id === listing?.id &&
                t.tenant_id == space?.occupant_id
            )?.total_amount_paid && (
              <Divider
                style={{ width: "100%", margin: "7px auto" }}
                orientation="horizontal"
                flexItem
              />
            )}
            {tenants?.find(
              (t) =>
                t.listing_id === listing?.id &&
                t.tenant_id == space?.occupant_id
            )?.total_amount_paid && (
              <p>
                <>
                  <span
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Total Amount Paid")} :{" "}
                  </span>
                  <Typography
                    sx={
                      tenants?.find(
                        (t) =>
                          t.listing_id == listing?.id &&
                          t.tenant_id == space?.occupant_id
                      )?.amount_owed > 0
                        ? {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#ee2400",
                          }
                        : {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#035aa6",
                          }
                    }
                    color="inherit"
                    variant="p"
                  >
                    {commaSeparateNumber(
                      tenants?.find(
                        (t) =>
                          t.listing_id == listing?.id &&
                          t.tenant_id == space?.occupant_id
                      )?.total_amount_paid
                    )}{" "}
                    {listing?.currency}
                  </Typography>
                </>
              </p>
            )}

            {tenants?.find(
              (t) =>
                t.listing_id == listing?.id && t.tenant_id == space?.occupant_id
            )?.total_amount_paid && (
              <p>
                <>
                  <span
                    sx={{
                      textAlign: "center",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {t("Total Amount Owed")} :{" "}
                  </span>
                  <Typography
                    sx={
                      tenants?.find(
                        (t) =>
                          t.listing_id == listing?.id &&
                          t.tenant_id == space?.occupant_id
                      )?.amount_owed > 0
                        ? {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#ee2400",
                          }
                        : {
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#035aa6",
                          }
                    }
                    color="inherit"
                    variant="p"
                  >
                    {tenants?.find(
                      (t) =>
                        t.listing_id == listing?.id &&
                        t.tenant_id == space?.occupant_id
                    )?.amount_owed >= 0
                      ? commaSeparateNumber(
                          tenants?.find(
                            (t) =>
                              t.listing_id == listing?.id &&
                              t.tenant_id == space?.occupant_id
                          )?.amount_owed
                        )
                      : 0}{" "}
                    {listing?.currency}
                  </Typography>
                </>
              </p>
            )}
          </React.Fragment>
        }
      >
        <Link
          to={
            space.empty_space == false &&
            `/portal/tenants_nonmember/${
              tenants?.find(
                (t) =>
                  t.listing_id == listing?.id &&
                  t.tenant_id == space?.occupant_id
              )?.id
            }`
          }
        >
          {space.empty_space == true && (
            <IconButton
              onClick={() =>
                alert(
                  `${listing.listing_type} #${
                    space.space_number
                  } is empty for ${capitalize(`${listing.title}`)}`
                )
              }
              type="button"
            >
              <Badge
                // style={{ margin: "7px" }}
                badgeContent={houseNumber}
                color="success"
              >
                <OtherHousesIcon
                  style={{ cursor: "pointer", color }}
                  color="action"
                />
              </Badge>
            </IconButton>
          )}
          {space.empty_space == false && (
            <IconButton type="button">
              <Badge
                // style={{ margin: "7px" }}
                badgeContent={houseNumber}
                color={
                  tenants?.find(
                    (t) =>
                      t.listing_id == listing?.id &&
                      t.tenant_id == space?.occupant_id
                  )?.amount_owed <= 0
                    ? "success"
                    : "error"
                }
              >
                <OtherHousesIcon
                  style={{ cursor: "pointer", color }}
                  color="action"
                />
              </Badge>
            </IconButton>
          )}
        </Link>
      </HtmlTooltip>
    </>
  );
};

export default TenantBadge;
