import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListingAccordion from "./ListingAccordion";
import BarChartIcon from "@mui/icons-material/BarChart";
import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";

const ListingAnalytics = ({
  listings = [],
  onSelectLising,
  selectedListing,
  years,
  barChartData,
  grossPayment,
  tenants,
  lineChartData,
}) => {
  const { t } = useTranslation();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      // sx={{ width: "50vw" }}
      role="presentation"
      className="listingAnalyticsContainer"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: 7,
          color: "#035aa6",
        }}
      >
        {t("listing_analytics_title",
          {
            number_of_listings: listings.length,
            s: listings.length === 0 ? "s" : listings.length > 1 ? "s" : "",
          }
        )}
      </p>
      <Divider style={{ width: "100%" }} />
      <div style={{ width: "100%" }}>
        {listings.length == 0 ? (
          <Alert style={{ width: "70%", margin: "7px auto" }} severity="info">
           {t("no_listings_found_analytics")}
          </Alert>
        ) : (
          <ListingAccordion
            listings={listings}
            onSelectLising={onSelectLising}
            years={years}
            barChartData={barChartData}
            grossPayment={grossPayment}
            tenants={tenants}
            lineChartData={lineChartData}
            selectedListing={selectedListing}
          />
        )}
      </div>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            style={{
              textTransform: "none",
              border: "1px solid #035aa6",
              borderRadius: "5px",
              padding: "5px 10px",
              backgroundColor: "#ffffff",
              color: "#035aa6",
              boxShadow: "3px 1px 3px 0.5px black",
              fontWeight: "bold",
            }}
            startIcon={<BarChartIcon />}
            onClick={toggleDrawer(anchor, true)}
          >
            {t("Listings Analytics")}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default ListingAnalytics;
