import React, { useEffect } from 'react'
import ScrollToTop from 'react-scroll-to-top'
import Sentence1 from '../components/TermUse/Sentence1'
import Sentences from '../components/TermUse/Sentences'

import TermMainHeading from '../components/TermUse/TermMainHeading'
import { getLang } from '../Utils/getLang'
//import Meta from '../Utils/Meta'
import Meta1 from '../Utils/Meta1'

const TermUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div
      style={{
        marginTop: '10px',
        width: '100%',
        padding: '20px 5%',
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      <Meta1
        title={`${getLang()}-Nawafrica-Terms And Policies`}
        description='Nawafrica Terms And Policies'
        link='/terms-of-use'
      />
      <TermMainHeading />
      <Sentence1 />
      <Sentences
        title='Materials'
        sentence='The materials (including but not limited to the text, information, data, audio, video and computer programs) (the “Materials”) on this Site are provided by Captivator technologies as a service to the public and may be used for informational purposes only.'
      />
      <Sentences
        title='Copyrights'
        sentence='The Materials on this Site are copyrighted and any unauthorized use of any of the Materials may violate copyright, trademark, and other laws. You may not copy, reproduce, modify, publish, upload, post, transmit or distribute in any way the Materials unless you obtain permission in writing from Captivator technologies in advance, except that you are granted a non-exclusive, non-transferable, limited permission to access and display the web pages solely on your computer and for your personal, non-commercial use.'
      />
      <Sentences
        title='Updates to the Terms and Conditions Document'
        sentence='Captivator technologies may revise these terms and conditions for use at any time by updating the “Terms of Use” page. You should visit this page from time to time to review the then current terms and conditions for use because they are binding on you. Certain provisions of these terms and conditions may be superseded by expressly designated legal notices or terms located on particular pages at the Site.'
      />
      <ScrollToTop smooth />
    </div>
  )
}

export default TermUse
