import React from 'react'

import "./profile.css"

const UpdateSpinner = () => {
return (
<div aria-hidden="true" className="spinner-border updateSpinnerOuterContainer" role="status">
  <span className="sr-only">Loading...</span>
</div>
)
}

export default UpdateSpinner
