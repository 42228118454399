import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";

import "../Listing/listing.css";
// import ReactTooltip from 'react-tooltip';
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const SearchPaymentTenant1 = ({
  onChangeSearchAmount,
  onChangeSearchPayDate,
  onChangeSearchPaymentMethod,
  onChangeSort,
  currency,
  onClickAddPayment,
  closeDeal = true,
  tenant,
}) => {
  const { t } = useTranslation();
  return (
    <div className="listingSearchCon">
      {/* data-tip={`${updatedPaymentMethod.momo_number?`Now using MTN Mobile Money (${updatedPaymentMethod.momo_number.momo_number})`:updatedPaymentMethod.visa_card_number?"Now using Visa Card":updatedPaymentMethod.paypal_account?"Now using Paypal":"Please, Select a payment option"}`} */}
      <div className="searchPaymentLeaseMakePayBtn someMadeMargin">
        {tenant.tenant_close_deal && tenant.tenant_close_deal ? (
          ""
        ) : (
          <a
            onClick={onClickAddPayment}
            href="#"
            data-toggle="modal"
            data-target="#exampleModalEditPayment"
          >
            <span style={{ marginRight: "5px" }}>
              <FontAwesomeIcon icon={faPlusCircle} />
            </span>
            {t("Add Payment")}
          </a>
        )}
      </div>

      <div className="inputFieldCon separateTenantdetailsSearchInput">
        <input
          className="myListingSearchInput"
          onChange={onChangeSearchAmount}
          placeholder={t("Search_Amount_Paid")}
        />
      </div>

      <div className="editPayDateContainer separateTenantdetailsSearchInput">
        <input
          className="myListingSearchInput letUseSeparate"
          onChange={onChangeSearchPayDate}
          placeholder={t("Search_payment_Date")}
        />
      </div>

      <div className="editPayDateContainer separateTenantdetailsSearchInput">
        {/* <input
          className='myListingSearchInput letUseSeparate1'
          onChange={onChangeSearchPaymentMethod}
          placeholder={t('Search_payment_method')}
        /> */}
      </div>

      <div className="listingSortDropdown separateTenantdetailsSearchInput">
        <p>
          <span className="sortedBySpan">{t("Sort_By")}</span>
          <span className="listingSortSelectSpan">
            <select onChange={onChangeSort}>
              <option value="dateposted">{t("Date")}</option>
              <option value="amount">{t("Amount", { currency })}</option>
              <option value="payment_method">{t("Payment_Method")}</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  );
};

export default SearchPaymentTenant1;
