import React from 'react'

const AddPaymentSpinner = () => {
return (
<div className="spinner-border addPaymentOptionSpinner" role="status">
  <span className="sr-only">Loading...</span>
</div>
)
}

export default AddPaymentSpinner
