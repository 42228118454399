export function hammerSign() {
  return {
    nm: 'Hammer-Icon',
    mn: '',
    layers: [
      {
        ty: 4,
        nm: 'Blob 3',
        mn: '',
        sr: 1,
        st: 0,
        op: 35,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [2314.463, 910.249, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [194.463, 306.249, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 3',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0.049, 0],
                            [0.067, 0.219],
                            [0, 0],
                            [-0.269, 0.082],
                            [-0.082, -0.268],
                            [0, 0],
                            [0.269, -0.082],
                          ],
                          o: [
                            [-0.218, 0],
                            [0, 0],
                            [-0.082, -0.268],
                            [0.269, -0.082],
                            [0, 0],
                            [0.082, 0.269],
                            [-0.05, 0.015],
                          ],
                          v: [
                            [4.774, 15.426],
                            [4.288, 15.066],
                            [4.24, 14.835],
                            [4.577, 14.2],
                            [5.212, 14.538],
                            [5.26, 14.768],
                            [4.923, 15.403],
                          ],
                        },
                      ],
                      t: 16.5,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0.58, 0],
                            [0.79, 2.585],
                            [0, 0],
                            [-3.169, 0.969],
                            [-0.969, -3.169],
                            [0, 0],
                            [3.169, -0.969],
                          ],
                          o: [
                            [-2.569, 0],
                            [0, 0],
                            [-0.968, -3.169],
                            [3.169, -0.971],
                            [0, 0],
                            [0.968, 3.169],
                            [-0.584, 0.179],
                          ],
                          v: [
                            [5.035, 22.488],
                            [-0.701, 18.239],
                            [-1.275, 15.517],
                            [2.71, 8.026],
                            [10.201, 12.011],
                            [10.775, 14.733],
                            [6.79, 22.224],
                          ],
                        },
                      ],
                      t: 18,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0.58, 0],
                            [0.79, 2.585],
                            [0, 0],
                            [-3.169, 0.969],
                            [-0.969, -3.169],
                            [0, 0],
                            [3.169, -0.969],
                          ],
                          o: [
                            [-2.569, 0],
                            [0, 0],
                            [-0.968, -3.169],
                            [3.169, -0.971],
                            [0, 0],
                            [0.968, 3.169],
                            [-0.584, 0.179],
                          ],
                          v: [
                            [5.035, 22.488],
                            [-0.701, 18.239],
                            [-10.775, -14.733],
                            [-6.79, -22.224],
                            [0.701, -18.239],
                            [10.775, 14.733],
                            [6.79, 22.224],
                          ],
                        },
                      ],
                      t: 21,
                    },
                  ],
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0, 0.2039, 0.4], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [7, 19.5], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [2316.619, 914.324], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 0,
      },
      {
        ty: 4,
        nm: 'Blob 2',
        mn: '',
        sr: 1,
        st: 0,
        op: 35,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [2293.463, 982.249, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [173.463, 378.749, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 2',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0.064, 0],
                            [0.087, 0.285],
                            [-0.349, 0.107],
                            [0, 0],
                            [-0.107, -0.349],
                            [0.349, -0.107],
                            [0, 0],
                          ],
                          o: [
                            [-0.283, 0],
                            [-0.107, -0.349],
                            [0, 0],
                            [0.349, -0.107],
                            [0.107, 0.349],
                            [0, 0],
                            [-0.064, 0.02],
                          ],
                          v: [
                            [15.114, -4.371],
                            [14.482, -4.839],
                            [14.921, -5.665],
                            [15.193, -5.728],
                            [16.018, -5.289],
                            [15.579, -4.464],
                            [15.307, -4.4],
                          ],
                        },
                      ],
                      t: 15.75,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0.58, 0],
                            [0.79, 2.585],
                            [-3.17, 0.969],
                            [0, 0],
                            [-0.969, -3.169],
                            [3.17, -0.969],
                            [0, 0],
                          ],
                          o: [
                            [-2.569, 0],
                            [-0.968, -3.169],
                            [0, 0],
                            [3.171, -0.971],
                            [0.968, 3.169],
                            [0, 0],
                            [-0.584, 0.179],
                          ],
                          v: [
                            [14.012, 1.54],
                            [8.276, -2.709],
                            [12.261, -10.2],
                            [14.733, -10.776],
                            [22.224, -6.79],
                            [18.239, 0.701],
                            [15.767, 1.276],
                          ],
                        },
                      ],
                      t: 17.25,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0.58, 0],
                            [0.79, 2.585],
                            [-3.17, 0.969],
                            [0, 0],
                            [-0.969, -3.169],
                            [3.17, -0.969],
                            [0, 0],
                          ],
                          o: [
                            [-2.569, 0],
                            [-0.968, -3.169],
                            [0, 0],
                            [3.171, -0.971],
                            [0.968, 3.169],
                            [0, 0],
                            [-0.584, 0.179],
                          ],
                          v: [
                            [-16.488, 11.04],
                            [-22.224, 6.791],
                            [-18.239, -0.7],
                            [14.733, -10.776],
                            [22.224, -6.79],
                            [18.239, 0.701],
                            [-14.733, 10.776],
                          ],
                        },
                      ],
                      t: 20.0439453125,
                    },
                  ],
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0, 0.2039, 0.4], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [2277.994, 986.121], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'Blob 1',
        mn: '',
        sr: 1,
        st: 0,
        op: 35,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [2274.463, 935.749, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [154.463, 331.749, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 2',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0.084, 0],
                            [0.031, 0.014],
                            [0, 0],
                            [-0.052, 0.112],
                            [-0.112, -0.052],
                            [0, 0],
                            [0.052, -0.112],
                          ],
                          o: [
                            [-0.032, 0],
                            [0, 0],
                            [-0.112, -0.052],
                            [0.052, -0.112],
                            [0, 0],
                            [0.112, 0.052],
                            [-0.038, 0.081],
                          ],
                          v: [
                            [13.287, 6.086],
                            [13.192, 6.065],
                            [13.118, 6.034],
                            [13.011, 5.737],
                            [13.308, 5.629],
                            [13.382, 5.661],
                            [13.489, 5.958],
                          ],
                        },
                      ],
                      t: 15,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [2.255, 0],
                            [0.823, 0.386],
                            [0, 0],
                            [-1.406, 3.001],
                            [-2.998, -1.405],
                            [0, 0],
                            [1.406, -3.001],
                          ],
                          o: [
                            [-0.854, 0],
                            [0, 0],
                            [-3, -1.406],
                            [1.406, -3.001],
                            [0, 0],
                            [3, 1.406],
                            [-1.021, 2.177],
                          ],
                          v: [
                            [14.234, 12.675],
                            [11.691, 12.107],
                            [9.715, 11.258],
                            [6.83, 3.279],
                            [14.809, 0.393],
                            [16.785, 1.242],
                            [19.67, 9.221],
                          ],
                        },
                      ],
                      t: 16.5,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [
                        {
                          c: true,
                          i: [
                            [2.255, 0],
                            [0.823, 0.386],
                            [0, 0],
                            [-1.406, 3.001],
                            [-2.998, -1.405],
                            [0, 0],
                            [1.406, -3.001],
                          ],
                          o: [
                            [-0.854, 0],
                            [0, 0],
                            [-3, -1.406],
                            [1.406, -3.001],
                            [0, 0],
                            [3, 1.406],
                            [-1.021, 2.177],
                          ],
                          v: [
                            [14.234, 12.675],
                            [11.691, 12.107],
                            [-16.785, -1.242],
                            [-19.67, -9.221],
                            [-11.691, -12.107],
                            [16.785, 1.242],
                            [19.67, 9.221],
                          ],
                        },
                      ],
                      t: 18.3359375,
                    },
                  ],
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0, 0.2039, 0.4], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [2261.507, 928.914], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
      },
      {
        ty: 4,
        nm: 'Hammer 2',
        mn: '',
        sr: 1,
        st: 0,
        op: 35,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [2720.118, 950.559, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [600.118, 346.559, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Hammer 1',
            ix: 1,
            cix: 2,
            np: 5,
            it: [
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'HandleLines',
                ix: 1,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [3.938, 0],
                          [2.699, 0.914],
                          [0, 0],
                          [-1.061, 3.14],
                          [-3.14, -1.06],
                          [0, 0],
                          [-3.276, 1.65],
                          [-1.122, 3.491],
                          [1.7, 3.249],
                          [3.508, 1.067],
                          [0, 0],
                          [-0.938, 3.179],
                          [-3.179, -0.938],
                          [0, 0],
                          [-3.203, -6.121],
                          [2.114, -6.576],
                          [6.17, -3.107],
                        ],
                        o: [
                          [-2.764, 0.001],
                          [0, 0],
                          [-3.139, -1.061],
                          [1.061, -3.139],
                          [0, 0],
                          [3.477, 1.178],
                          [3.275, -1.649],
                          [1.122, -3.491],
                          [-1.7, -3.249],
                          [0, 0],
                          [-3.179, -0.936],
                          [0.937, -3.178],
                          [0, 0],
                          [6.659, 2.025],
                          [3.203, 6.12],
                          [-2.114, 6.576],
                          [-3.625, 1.825],
                        ],
                        v: [
                          [106.599, 58.954],
                          [98.361, 57.586],
                          [-128.082, -18.918],
                          [-131.845, -26.523],
                          [-124.24, -30.287],
                          [102.208, 46.219],
                          [112.677, 45.489],
                          [119.497, 37.519],
                          [118.6, 27.068],
                          [110.523, 20.374],
                          [-118.743, -47.198],
                          [-122.801, -54.649],
                          [-115.35, -58.708],
                          [113.965, 8.879],
                          [129.232, 21.503],
                          [130.92, 41.192],
                          [118.075, 56.207],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'fl',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Graphic - Fill',
                    nm: 'Fill 1',
                    c: { a: 0, k: [0, 0.2039, 0.4], ix: 4 },
                    r: 1,
                    o: { a: 0, k: 100, ix: 5 },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [2596.293, 911.314], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'Blocks',
                ix: 2,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 1',
                    ix: 1,
                    cix: 2,
                    np: 2,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 1,
                          k: [
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [
                                {
                                  c: true,
                                  i: [
                                    [0.944, 0],
                                    [0.599, 0.192],
                                    [0, 0],
                                    [-1.015, 3.154],
                                    [0, 0],
                                    [-1.668, 0.661],
                                    [0, 0],
                                    [0, 0],
                                    [-0.537, 1.671],
                                    [0, 0],
                                    [-1.627, 0.675],
                                    [0, 0],
                                    [0, 0],
                                    [-0.486, 1.513],
                                    [0, 0],
                                    [-3.152, -1.015],
                                    [0, 0],
                                    [1.015, -3.154],
                                    [0, 0],
                                    [0.854, -0.725],
                                    [0, 0],
                                    [1.976, 0.635],
                                    [0, 0],
                                    [-1.015, 3.155],
                                    [-3.153, -1.014],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.578, -1.572],
                                    [1.547, -0.642],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.56, -1.685],
                                    [1.649, -0.653],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [-1.015, 3.154],
                                    [-3.153, -1.014],
                                    [0, 0],
                                    [-0.679, -1.612],
                                    [0, 0],
                                    [0.439, -1.365],
                                    [0, 0],
                                    [1.416, -0.728],
                                  ],
                                  o: [
                                    [-0.618, 0],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [0, 0],
                                    [0.549, -1.708],
                                    [0, 0],
                                    [0, 0],
                                    [-0.922, -1.493],
                                    [0, 0],
                                    [0.539, -1.677],
                                    [0, 0],
                                    [0, 0],
                                    [-0.725, -1.415],
                                    [0, 0],
                                    [1.014, -3.155],
                                    [0, 0],
                                    [3.155, 1.014],
                                    [0, 0],
                                    [-0.343, 1.066],
                                    [0, 0],
                                    [-1.581, 1.342],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [1.014, -3.154],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.764, 1.491],
                                    [-0.578, 1.572],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.932, 1.51],
                                    [-0.559, 1.684],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [1.014, -3.155],
                                    [0, 0],
                                    [1.666, 0.535],
                                    [0, 0],
                                    [0.557, 1.322],
                                    [0, 0],
                                    [-0.487, 1.516],
                                    [-0.855, 0.439],
                                  ],
                                  v: [
                                    [20.221, 113.705],
                                    [18.385, 113.417],
                                    [-81.035, 81.454],
                                    [-84.911, 73.907],
                                    [-74.956, 42.941],
                                    [-71.455, 39.2],
                                    [-64.372, 36.391],
                                    [-68.493, 29.708],
                                    [-69.098, 24.722],
                                    [-42.35, -58.475],
                                    [-38.936, -62.182],
                                    [-31.119, -65.422],
                                    [-35.516, -74.003],
                                    [-35.888, -78.575],
                                    [-25.933, -109.54],
                                    [-18.385, -113.416],
                                    [81.035, -81.453],
                                    [84.911, -73.905],
                                    [74.956, -42.939],
                                    [73.125, -40.199],
                                    [62.618, -31.29],
                                    [56.901, -30.154],
                                    [-6.122, -50.416],
                                    [-9.998, -57.965],
                                    [-2.45, -61.84],
                                    [57.399, -42.598],
                                    [64.056, -48.243],
                                    [71.65, -71.865],
                                    [-16.345, -100.155],
                                    [-23.705, -77.266],
                                    [-17.473, -65.106],
                                    [-17.181, -60.3],
                                    [-20.515, -56.827],
                                    [-31.776, -52.16],
                                    [-56.828, 25.763],
                                    [-50.403, 36.182],
                                    [-49.815, 41.221],
                                    [-53.298, 44.909],
                                    [-64.399, 49.31],
                                    [-71.65, 71.867],
                                    [16.345, 100.157],
                                    [23.784, 77.02],
                                    [21.382, 71.321],
                                    [-39.016, 51.903],
                                    [-42.892, 44.355],
                                    [-35.344, 40.479],
                                    [27.678, 60.74],
                                    [31.372, 64.122],
                                    [35.706, 74.41],
                                    [35.888, 78.576],
                                    [25.933, 109.541],
                                    [22.961, 113.043],
                                  ],
                                },
                              ],
                              t: 16,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [
                                {
                                  c: true,
                                  i: [
                                    [1.308, 0.001],
                                    [0.855, 0.195],
                                    [0, 0],
                                    [-0.992, 3.189],
                                    [0, 0],
                                    [-1.668, 0.661],
                                    [0, 0],
                                    [0, 0],
                                    [-0.537, 1.671],
                                    [0, 0],
                                    [-1.627, 0.675],
                                    [0, 0],
                                    [0, 0],
                                    [-0.486, 1.513],
                                    [0, 0],
                                    [-3.152, -1.015],
                                    [0, 0],
                                    [1.015, -3.154],
                                    [0, 0],
                                    [0.854, -0.725],
                                    [0, 0],
                                    [1.976, 0.635],
                                    [0, 0],
                                    [-1.015, 3.155],
                                    [-3.153, -1.014],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.578, -1.572],
                                    [1.547, -0.642],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.56, -1.685],
                                    [1.649, -0.653],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [-1.015, 3.154],
                                    [-3.153, -1.014],
                                    [0, 0],
                                    [-0.679, -1.612],
                                    [0, 0],
                                    [0.439, -1.365],
                                    [0, 0],
                                    [1.866, -0.734],
                                  ],
                                  o: [
                                    [-0.856, -0.001],
                                    [0, 0],
                                    [-4.504, -1.029],
                                    [0, 0],
                                    [0.549, -1.708],
                                    [0, 0],
                                    [0, 0],
                                    [-0.922, -1.493],
                                    [0, 0],
                                    [0.539, -1.677],
                                    [0, 0],
                                    [0, 0],
                                    [-0.725, -1.415],
                                    [0, 0],
                                    [1.014, -3.155],
                                    [0, 0],
                                    [3.155, 1.014],
                                    [0, 0],
                                    [-0.343, 1.066],
                                    [0, 0],
                                    [-1.581, 1.342],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [1.014, -3.154],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.764, 1.491],
                                    [-0.578, 1.572],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.932, 1.51],
                                    [-0.559, 1.684],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [1.014, -3.155],
                                    [0, 0],
                                    [1.666, 0.535],
                                    [0, 0],
                                    [0.557, 1.322],
                                    [0, 0],
                                    [-0.476, 1.532],
                                    [-1.127, 0.443],
                                  ],
                                  v: [
                                    [42.571, 117.097],
                                    [39.99, 116.804],
                                    [-101.915, 84.369],
                                    [-108.274, 76.731],
                                    [-74.956, 42.941],
                                    [-71.455, 39.2],
                                    [-64.372, 36.391],
                                    [-68.493, 29.708],
                                    [-69.098, 24.722],
                                    [-42.35, -58.475],
                                    [-38.936, -62.182],
                                    [-31.119, -65.422],
                                    [-35.516, -74.003],
                                    [-35.888, -78.575],
                                    [-25.933, -109.54],
                                    [-18.385, -113.416],
                                    [81.035, -81.453],
                                    [84.911, -73.905],
                                    [74.956, -42.939],
                                    [73.124, -40.199],
                                    [62.618, -31.29],
                                    [56.901, -30.154],
                                    [-6.122, -50.416],
                                    [-9.998, -57.965],
                                    [-2.45, -61.84],
                                    [57.399, -42.598],
                                    [64.056, -48.243],
                                    [71.65, -71.865],
                                    [-16.345, -100.155],
                                    [-23.705, -77.266],
                                    [-17.473, -65.106],
                                    [-17.181, -60.3],
                                    [-20.515, -56.827],
                                    [-31.776, -52.16],
                                    [-56.828, 25.763],
                                    [-50.403, 36.182],
                                    [-49.815, 41.221],
                                    [-53.298, 44.909],
                                    [-64.399, 49.31],
                                    [-90.173, 74.683],
                                    [35.424, 103.391],
                                    [23.784, 77.02],
                                    [21.382, 71.321],
                                    [-39.016, 51.903],
                                    [-42.892, 44.355],
                                    [-35.344, 40.479],
                                    [27.678, 60.74],
                                    [31.372, 64.122],
                                    [35.706, 74.41],
                                    [35.888, 78.576],
                                    [49.575, 110.113],
                                    [46.28, 116.431],
                                  ],
                                },
                              ],
                              t: 17,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [
                                {
                                  c: true,
                                  i: [
                                    [0.817, -0.016],
                                    [0.515, 0.182],
                                    [0, 0],
                                    [-0.926, 3.166],
                                    [0, 0],
                                    [-1.668, 0.661],
                                    [0, 0],
                                    [0, 0],
                                    [-0.537, 1.671],
                                    [0, 0],
                                    [-1.627, 0.675],
                                    [0, 0],
                                    [0, 0],
                                    [-0.486, 1.513],
                                    [0, 0],
                                    [-3.152, -1.015],
                                    [0, 0],
                                    [1.015, -3.154],
                                    [0, 0],
                                    [0.854, -0.725],
                                    [0, 0],
                                    [1.976, 0.635],
                                    [0, 0],
                                    [-1.015, 3.155],
                                    [-3.153, -1.014],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.578, -1.572],
                                    [1.547, -0.642],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.56, -1.685],
                                    [1.649, -0.653],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [-1.015, 3.154],
                                    [-3.153, -1.014],
                                    [0, 0],
                                    [-0.679, -1.612],
                                    [0, 0],
                                    [0.439, -1.365],
                                    [0, 0],
                                    [1.236, -0.75],
                                  ],
                                  o: [
                                    [-0.535, 0.01],
                                    [0, 0],
                                    [-2.714, -0.958],
                                    [0, 0],
                                    [0.549, -1.708],
                                    [0, 0],
                                    [0, 0],
                                    [-0.922, -1.493],
                                    [0, 0],
                                    [0.539, -1.677],
                                    [0, 0],
                                    [0, 0],
                                    [-0.725, -1.415],
                                    [0, 0],
                                    [1.014, -3.155],
                                    [0, 0],
                                    [3.155, 1.014],
                                    [0, 0],
                                    [-0.343, 1.066],
                                    [0, 0],
                                    [-1.581, 1.342],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [1.014, -3.154],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.764, 1.491],
                                    [-0.578, 1.572],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.932, 1.51],
                                    [-0.559, 1.684],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [1.014, -3.155],
                                    [0, 0],
                                    [1.666, 0.535],
                                    [0, 0],
                                    [0.557, 1.322],
                                    [0, 0],
                                    [-0.445, 1.521],
                                    [-0.747, 0.453],
                                  ],
                                  v: [
                                    [17.217, 101.621],
                                    [15.633, 101.364],
                                    [-69.88, 71.148],
                                    [-73.119, 63.68],
                                    [-74.956, 42.941],
                                    [-71.455, 39.2],
                                    [-64.372, 36.391],
                                    [-68.493, 29.708],
                                    [-69.098, 24.722],
                                    [-42.35, -58.475],
                                    [-38.936, -62.182],
                                    [-31.119, -65.422],
                                    [-35.516, -74.003],
                                    [-35.888, -78.575],
                                    [-25.933, -109.54],
                                    [-18.385, -113.416],
                                    [81.035, -81.453],
                                    [84.911, -73.905],
                                    [74.956, -42.939],
                                    [73.125, -40.199],
                                    [62.618, -31.29],
                                    [56.901, -30.154],
                                    [-6.122, -50.416],
                                    [-9.998, -57.965],
                                    [-2.45, -61.84],
                                    [57.399, -42.598],
                                    [64.056, -48.243],
                                    [71.65, -71.865],
                                    [-16.345, -100.155],
                                    [-23.705, -77.266],
                                    [-17.473, -65.106],
                                    [-17.181, -60.3],
                                    [-20.515, -56.827],
                                    [-31.776, -52.16],
                                    [-56.828, 25.763],
                                    [-50.403, 36.182],
                                    [-49.815, 41.221],
                                    [-53.298, 44.909],
                                    [-64.399, 49.31],
                                    [-61.617, 61.419],
                                    [14.07, 88.163],
                                    [23.784, 77.02],
                                    [21.382, 71.321],
                                    [-39.016, 51.903],
                                    [-42.892, 44.355],
                                    [-35.344, 40.479],
                                    [27.678, 60.74],
                                    [31.372, 64.122],
                                    [35.706, 74.41],
                                    [35.888, 78.576],
                                    [22.221, 97.368],
                                    [19.597, 100.913],
                                  ],
                                },
                              ],
                              t: 19,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [
                                {
                                  c: true,
                                  i: [
                                    [0.944, 0],
                                    [0.599, 0.192],
                                    [0, 0],
                                    [-1.015, 3.154],
                                    [0, 0],
                                    [-1.668, 0.661],
                                    [0, 0],
                                    [0, 0],
                                    [-0.537, 1.671],
                                    [0, 0],
                                    [-1.627, 0.675],
                                    [0, 0],
                                    [0, 0],
                                    [-0.486, 1.513],
                                    [0, 0],
                                    [-3.152, -1.015],
                                    [0, 0],
                                    [1.015, -3.154],
                                    [0, 0],
                                    [0.854, -0.725],
                                    [0, 0],
                                    [1.976, 0.635],
                                    [0, 0],
                                    [-1.015, 3.155],
                                    [-3.153, -1.014],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.578, -1.572],
                                    [1.547, -0.642],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.56, -1.685],
                                    [1.649, -0.653],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [-1.015, 3.154],
                                    [-3.153, -1.014],
                                    [0, 0],
                                    [-0.679, -1.612],
                                    [0, 0],
                                    [0.439, -1.365],
                                    [0, 0],
                                    [1.416, -0.728],
                                  ],
                                  o: [
                                    [-0.618, 0],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [0, 0],
                                    [0.549, -1.708],
                                    [0, 0],
                                    [0, 0],
                                    [-0.922, -1.493],
                                    [0, 0],
                                    [0.539, -1.677],
                                    [0, 0],
                                    [0, 0],
                                    [-0.725, -1.415],
                                    [0, 0],
                                    [1.014, -3.155],
                                    [0, 0],
                                    [3.155, 1.014],
                                    [0, 0],
                                    [-0.343, 1.066],
                                    [0, 0],
                                    [-1.581, 1.342],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [1.014, -3.154],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.764, 1.491],
                                    [-0.578, 1.572],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0.932, 1.51],
                                    [-0.559, 1.684],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [0, 0],
                                    [-3.155, -1.014],
                                    [1.014, -3.155],
                                    [0, 0],
                                    [1.666, 0.535],
                                    [0, 0],
                                    [0.557, 1.322],
                                    [0, 0],
                                    [-0.487, 1.516],
                                    [-0.855, 0.439],
                                  ],
                                  v: [
                                    [20.221, 113.705],
                                    [18.385, 113.417],
                                    [-81.035, 81.454],
                                    [-84.911, 73.907],
                                    [-74.956, 42.941],
                                    [-71.455, 39.2],
                                    [-64.372, 36.391],
                                    [-68.493, 29.708],
                                    [-69.098, 24.722],
                                    [-42.35, -58.475],
                                    [-38.936, -62.182],
                                    [-31.119, -65.422],
                                    [-35.516, -74.003],
                                    [-35.888, -78.575],
                                    [-25.933, -109.54],
                                    [-18.385, -113.416],
                                    [81.035, -81.453],
                                    [84.911, -73.905],
                                    [74.956, -42.939],
                                    [73.125, -40.199],
                                    [62.618, -31.29],
                                    [56.901, -30.154],
                                    [-6.122, -50.416],
                                    [-9.998, -57.965],
                                    [-2.45, -61.84],
                                    [57.399, -42.598],
                                    [64.056, -48.243],
                                    [71.65, -71.865],
                                    [-16.345, -100.155],
                                    [-23.705, -77.266],
                                    [-17.473, -65.106],
                                    [-17.181, -60.3],
                                    [-20.515, -56.827],
                                    [-31.776, -52.16],
                                    [-56.828, 25.763],
                                    [-50.403, 36.182],
                                    [-49.815, 41.221],
                                    [-53.298, 44.909],
                                    [-64.399, 49.31],
                                    [-71.65, 71.867],
                                    [16.345, 100.157],
                                    [23.784, 77.02],
                                    [21.382, 71.321],
                                    [-39.016, 51.903],
                                    [-42.892, 44.355],
                                    [-35.344, 40.479],
                                    [27.678, 60.74],
                                    [31.372, 64.122],
                                    [35.706, 74.41],
                                    [35.888, 78.576],
                                    [25.933, 109.541],
                                    [22.961, 113.043],
                                  ],
                                },
                              ],
                              t: 21,
                            },
                          ],
                          ix: 2,
                        },
                      },
                      {
                        ty: 'fl',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Graphic - Fill',
                        nm: 'Fill 1',
                        c: { a: 0, k: [0, 0.2039, 0.4], ix: 4 },
                        r: 1,
                        o: { a: 0, k: 100, ix: 5 },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [2424.98, 856.554], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 2',
                    ix: 2,
                    cix: 2,
                    np: 2,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [2.536, 0],
                              [0.61, 0.196],
                              [-1.015, 3.155],
                              [0, 0],
                              [-3.154, -1.015],
                              [1.015, -3.155],
                              [0, 0],
                            ],
                            o: [
                              [-0.608, 0],
                              [-3.155, -1.014],
                              [0, 0],
                              [1.014, -3.153],
                              [3.155, 1.014],
                              [0, 0],
                              [-0.817, 2.544],
                            ],
                            v: [
                              [-9.927, 36.881],
                              [-11.764, 36.591],
                              [-15.64, 29.042],
                              [4.216, -32.716],
                              [11.764, -36.591],
                              [15.64, -29.043],
                              [-4.216, 32.716],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'fl',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Graphic - Fill',
                        nm: 'Fill 1',
                        c: { a: 0, k: [0.2392, 0.298, 0.4275], ix: 4 },
                        r: 1,
                        o: { a: 0, k: 100, ix: 5 },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [2474.221, 873.996], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [2474.221, 873.996], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [2474.221, 873.996], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'whiteFill',
                ix: 3,
                cix: 2,
                np: 3,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [6.5, -15],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [-6.5, 15],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [2355, 903],
                          [2357.391, 925.015],
                          [2442.15, 951.926],
                          [2453, 925],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 2',
                    ix: 2,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [9.5, -25],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [-9.5, 25],
                          [0, 0],
                        ],
                        v: [
                          [2504, 778.5],
                          [2406, 752],
                          [2396, 775.5],
                          [2486, 818],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'fl',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Graphic - Fill',
                    nm: 'Fill 1',
                    c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                    r: 1,
                    o: { a: 0, k: 100, ix: 5 },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [0, 0], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'Handle',
                ix: 4,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [-3.346, 10.408],
                          [0, 0],
                          [10.459, 3.182],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [10.354, 3.51],
                          [0, 0],
                          [3.346, -10.408],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-126.157, -24.598],
                          [100.291, 51.908],
                          [125.212, 39.359],
                          [125.212, 39.359],
                          [112.272, 14.638],
                          [-117.043, -52.949],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'fl',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Graphic - Fill',
                    nm: 'Fill 1',
                    c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                    r: 1,
                    o: { a: 0, k: 100, ix: 5 },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [2596.29, 911.31], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'CenterBlock',
                ix: 5,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-61.277, 24.518],
                          [-53.401, 37.29],
                          [27.951, 64.411],
                          [41.422, 46.279],
                          [61.277, -15.479],
                          [60.845, -37.907],
                          [-20.704, -64.411],
                          [-34.53, -58.68],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'fl',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Graphic - Fill',
                    nm: 'Fill 1',
                    c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 4 },
                    r: 1,
                    o: { a: 0, k: 100, ix: 5 },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [2422.871, 858.595], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'tr',
                a: { a: 0, k: [2710.29, 945.31], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 0,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 8,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 11.93,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 92],
                      t: 17.541,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 22,
                    },
                  ],
                  ix: 3,
                },
                sk: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 0,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 8,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 11.93,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 22,
                    },
                  ],
                  ix: 4,
                },
                p: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [2710.29, 945.31],
                      t: 0,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [2710.29, 945.31],
                      t: 8,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [2710.29, 945.31],
                      t: 11.93,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [2710.29, 945.31],
                      t: 22,
                    },
                  ],
                  ix: 2,
                },
                r: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 0,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 8,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [14],
                      t: 11.93,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [31],
                      t: 15,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [-12],
                      t: 16.688,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 22,
                    },
                  ],
                  ix: 6,
                },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 3,
      },
      {
        ty: 4,
        nm: 'Anvil',
        mn: '',
        sr: 1,
        st: 0,
        op: 35,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [2399.594, 1061.797, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 16,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [125, 71, 100],
                t: 17,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [83, 122, 100],
                t: 19,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 21,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [279.594, 457.797, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'Group 4',
                ix: 1,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 1',
                    ix: 1,
                    cix: 2,
                    np: 3,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [2.35, -7.788],
                              [0, 0],
                              [8.545, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-2.35, -7.788],
                              [0, 0],
                              [-8.545, 0],
                            ],
                            v: [
                              [-104.869, 6.739],
                              [104.869, 6.739],
                              [86.733, -6.739],
                              [-86.733, -6.739],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 2',
                        ix: 2,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [3.314, 0],
                              [0, 0],
                              [0, 3.314],
                              [0, 0],
                              [-17.059, 0],
                              [0, 0],
                              [0, -17.059],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-3.314, 0],
                              [0, 0],
                              [0, -17.059],
                              [0, 0],
                              [17.059, 0],
                              [0, 0],
                              [0, 3.314],
                            ],
                            v: [
                              [111.671, 18.739],
                              [-111.671, 18.739],
                              [-117.671, 12.739],
                              [-117.671, 12.198],
                              [-86.733, -18.739],
                              [86.733, -18.739],
                              [117.671, 12.198],
                              [117.671, 12.739],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'fl',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Graphic - Fill',
                        nm: 'Fill 1',
                        c: { a: 0, k: [0.2392, 0.298, 0.4275], ix: 4 },
                        r: 1,
                        o: { a: 0, k: 100, ix: 5 },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [2400.594, 1043.536], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'Group 2',
                    ix: 2,
                    cix: 2,
                    np: 3,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [2.35, -7.788],
                              [0, 0],
                              [8.544, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-2.35, -7.788],
                              [0, 0],
                              [-8.545, 0],
                            ],
                            v: [
                              [-72.488, 6.739],
                              [72.488, 6.739],
                              [54.354, -6.739],
                              [-54.353, -6.739],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 2',
                        ix: 2,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [3.314, 0],
                              [0, 0],
                              [0, 3.314],
                              [0, 0],
                              [-17.059, 0],
                              [0, 0],
                              [0, -17.059],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [-3.314, 0],
                              [0, 0],
                              [0, -17.059],
                              [0, 0],
                              [17.059, 0],
                              [0, 0],
                              [0, 3.314],
                            ],
                            v: [
                              [79.29, 18.739],
                              [-79.29, 18.739],
                              [-85.29, 12.739],
                              [-85.29, 12.198],
                              [-54.353, -18.739],
                              [54.354, -18.739],
                              [85.29, 12.198],
                              [85.29, 12.739],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'fl',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Graphic - Fill',
                        nm: 'Fill 1',
                        c: { a: 0, k: [0.2392, 0.298, 0.4275], ix: 4 },
                        r: 1,
                        o: { a: 0, k: 100, ix: 5 },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [2400.594, 1018.058], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [2400.594, 1018.058], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [2400.594, 1018.058], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'Group 6',
                ix: 2,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-13.772, 0],
                          [0, 0],
                          [0, -13.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -13.772],
                          [0, 0],
                          [13.773, 0],
                          [0, 0],
                        ],
                        v: [
                          [111.671, 12.739],
                          [-111.671, 12.739],
                          [-111.671, 12.198],
                          [-86.734, -12.739],
                          [86.734, -12.739],
                          [111.671, 12.198],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'fl',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Graphic - Fill',
                    nm: 'Fill 1',
                    c: { a: 0, k: [1, 0.6471, 0], ix: 4 },
                    r: 1,
                    o: { a: 0, k: 100, ix: 5 },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [2400.594, 1043.535], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'Group 7',
                ix: 3,
                cix: 2,
                np: 2,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-13.772, 0],
                          [0, 0],
                          [0, -13.772],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, -13.772],
                          [0, 0],
                          [13.773, 0],
                          [0, 0],
                        ],
                        v: [
                          [79.29, 12.739],
                          [-79.29, 12.739],
                          [-79.29, 12.198],
                          [-54.353, -12.739],
                          [54.353, -12.739],
                          [79.29, 12.198],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'fl',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Graphic - Fill',
                    nm: 'Fill 1',
                    c: { a: 0, k: [1, 0.6471, 0], ix: 4 },
                    r: 1,
                    o: { a: 0, k: 100, ix: 5 },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [2400.594, 1018.058], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'tr',
                a: { a: 0, k: [2400.594, 1018.058], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [2400.594, 1018.058], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 4,
      },
      {
        ty: 4,
        nm: 'CircleBg',
        mn: '',
        sr: 1,
        st: 0,
        op: 35,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [2484.862, 902.562, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [364.862, 298.562, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 5',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'el',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [440.361, 440.361], ix: 2 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [2484.148, 902.562], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 5,
      },
    ],
    ddd: 0,
    h: 618,
    w: 710,
    meta: {
      a: 'Math Notermans',
      k: 'Legal Hammer Icon Law Judge',
      d: 'Hammer hitting law board',
      g: 'LottieFiles AE 0.1.20',
      tc: '#ffffff',
    },
    v: '5.5.7',
    fr: 25,
    op: 35,
    ip: 0,
    assets: [],
  }
}
