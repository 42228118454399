import React from 'react'
import CloseBtnDeal from './CloseBtnDeal'

const CancelAgreementAlert = ({
  names = 'Daddy',
  date = '1/11/2022',
  listingName,
  visible = false,
  tenant,
}) => {
  if (!visible)
    return (
      <div className='cancelAgreementAlertCon'>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <span style={{ marginRight: '5px' }}>
            <CloseBtnDeal />
          </span>
          <b>Cancel Signed Lease Agreement</b>
        </div>
        <p style={{ marginTop: '10px' }}>
          {!tenant
            ? `Your Tenant ${names}, Want To Cancel The Signed Lease Agreement Which
          Was Signed on The ${date}, For ${listingName}`
            : `Your Landlord ${names}, Want To Cancel The Signed Lease Agreement Which
          Was Signed on The ${date}, For ${listingName}`}
        </p>
      </div>
    )
}

export default CancelAgreementAlert
