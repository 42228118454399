export function spliceCityArr(newArray, newArrayMade) {
  // let emptyArr = []
  // for (let i = 0; i < newArray.length; i++) {
  //   if (i % 4 !== 0) {
  //     emptyArr.push(newArray[i])
  //   } else {
  //     emptyArr.push(newArray[i])
  //     newArrayMade.push(emptyArr)
  //     emptyArr = []
  //   }
  // }

  const listCity0to4 = Array.from(newArray).slice(0, 4)
  listCity0to4.length > 0 && newArrayMade.push(listCity0to4)
  const listCity4to8 = Array.from(newArray).slice(4, 8)
  listCity4to8.length > 0 && newArrayMade.push(listCity4to8)
  const listCity8to12 = Array.from(newArray).slice(8, 12)
  listCity8to12.length > 0 && newArrayMade.push(listCity8to12)
  const listCity12to16 = Array.from(newArray).slice(12, 16)
  listCity12to16.length > 0 && newArrayMade.push(listCity12to16)
  const listCity16to20 = Array.from(newArray).slice(16, 20)
  listCity16to20.length > 0 && newArrayMade.push(listCity16to20)
  const listCity20to24 = Array.from(newArray).slice(20, 24)
  listCity20to24.length > 0 && newArrayMade.push(listCity20to24)
  const listCity24to28 = Array.from(newArray).slice(24, 28)
  listCity24to28.length > 0 && newArrayMade.push(listCity24to28)
  const listCity28to32 = Array.from(newArray).slice(28, 32)
  listCity28to32.length > 0 && newArrayMade.push(listCity28to32)
  const listCity32to36 = Array.from(newArray).slice(32, 36)
  listCity32to36.length > 0 && newArrayMade.push(listCity32to36)
  const listCity36to40 = Array.from(newArray).slice(36, 40)
  listCity36to40.length > 0 && newArrayMade.push(listCity36to40)
  const listCity40to44 = Array.from(newArray).slice(40, 44)
  listCity40to44.length > 0 && newArrayMade.push(listCity40to44)
  const listCity44to48 = Array.from(newArray).slice(44, 48)
  listCity44to48.length > 0 && newArrayMade.push(listCity44to48)
  const listCity48to52 = Array.from(newArray).slice(48, 52)
  listCity48to52.length > 0 && newArrayMade.push(listCity48to52)
  const listCity52to56 = Array.from(newArray).slice(52, 56)
  listCity52to56.length > 0 && newArrayMade.push(listCity52to56)
  const listCity56to60 = Array.from(newArray).slice(56, 60)
  listCity56to60.length > 0 && newArrayMade.push(listCity56to60)
  const listCity60to64 = Array.from(newArray).slice(60, 64)
  listCity60to64.length > 0 && newArrayMade.push(listCity60to64)
  const listCity64to68 = Array.from(newArray).slice(64, 68)
  listCity64to68.length > 0 && newArrayMade.push(listCity64to68)
  const listCity68to72 = Array.from(newArray).slice(68, 72)
  listCity68to72.length > 0 && newArrayMade.push(listCity68to72)
  const listCity72to76 = Array.from(newArray).slice(72, 76)
  listCity72to76.length > 0 && newArrayMade.push(listCity72to76)
  const listCity76to80 = Array.from(newArray).slice(76, 80)
  listCity76to80.length > 0 && newArrayMade.push(listCity76to80)
  const listCity80to84 = Array.from(newArray).slice(80, 84)
  listCity80to84.length > 0 && newArrayMade.push(listCity80to84)
  const listCity84to88 = Array.from(newArray).slice(84, 88)
  listCity84to88.length > 0 && newArrayMade.push(listCity84to88)
  const listCity88to92 = Array.from(newArray).slice(88, 92)
  listCity88to92.length > 0 && newArrayMade.push(listCity88to92)
  const listCity92to96 = Array.from(newArray).slice(92, 96)
  listCity92to96.length > 0 && newArrayMade.push(listCity92to96)
  const listCity96to100 = Array.from(newArray).slice(96, 100)
  listCity96to100.length > 0 && newArrayMade.push(listCity96to100)
  const listCity100to104 = Array.from(newArray).slice(100, 104)
  listCity100to104.length > 0 && newArrayMade.push(listCity100to104)
  const listCity108to112 = Array.from(newArray).slice(108, 112)
  listCity108to112.length > 0 && newArrayMade.push(listCity108to112)
  const listCity112to116 = Array.from(newArray).slice(112, 116)
  listCity112to116.length > 0 && newArrayMade.push(listCity112to116)
  const listCity116to120 = Array.from(newArray).slice(116, 120)
  listCity116to120.length > 0 && newArrayMade.push(listCity116to120)
  const listCity120to124 = Array.from(newArray).slice(120, 124)
  listCity120to124.length > 0 && newArrayMade.push(listCity120to124)
  const listCity124to128 = Array.from(newArray).slice(124, 128)
  listCity124to128.length > 0 && newArrayMade.push(listCity124to128)

  return newArrayMade
}

export function spliceCityArrSmall(newArray, newArrayMadeSmall) {
  // logger.log('here is the listing in function', newArray, newArrayMadeSmall)
  // let emptyArr = []
  // for (let i = 0; i < newArray.length; i++) {
  //   if (i % 2 !== 0) {
  //     emptyArr.push(newArray[i])
  //   } else {
  //     emptyArr.push(newArray[i])
  //     newArrayMadeSmall.push(emptyArr)
  //     emptyArr = []
  //   }
  // }
  const listCity0to2 = Array.from(newArray).slice(0, 1)
  listCity0to2.length > 0 && newArrayMadeSmall.push(listCity0to2)
  const listCity2to4 = Array.from(newArray).slice(1, 2)
  listCity2to4.length > 0 && newArrayMadeSmall.push(listCity2to4)
  const listCity4to6 = Array.from(newArray).slice(2, 3)
  listCity4to6.length > 0 && newArrayMadeSmall.push(listCity4to6)
  const listCity6to8 = Array.from(newArray).slice(3, 4)
  listCity6to8.length > 0 && newArrayMadeSmall.push(listCity6to8)
  const listCity8to10 = Array.from(newArray).slice(4, 5)
  listCity8to10.length > 0 && newArrayMadeSmall.push(listCity8to10)
  const listCity10to12 = Array.from(newArray).slice(5, 6)
  listCity10to12.length > 0 && newArrayMadeSmall.push(listCity10to12)
  const listCity12to14 = Array.from(newArray).slice(6, 7)
  listCity12to14.length > 0 && newArrayMadeSmall.push(listCity12to14)
  const listCity14to16 = Array.from(newArray).slice(7, 8)
  listCity14to16.length > 0 && newArrayMadeSmall.push(listCity14to16)
  const listCity16to18 = Array.from(newArray).slice(8, 9)
  listCity16to18.length > 0 && newArrayMadeSmall.push(listCity16to18)
  const listCity18to20 = Array.from(newArray).slice(9, 10)
  listCity18to20.length > 0 && newArrayMadeSmall.push(listCity18to20)

  return newArrayMadeSmall
}

export function spliceImage(carImages) {
  // let doubleCar = []
  // let emptyArr = []
  // for (let i = 0; i < carImages.length; i++) {
  //   if (i % 8 !== 0) {
  //     emptyArr.push(carImages[i])
  //   } else {
  //     emptyArr.push(carImages[i])
  //     doubleCar.push(emptyArr)
  //     emptyArr = []
  //   }
  // }

  let imageNoBracket = carImages.filter(
    (i) => i.id || i.url || !i.path['error']
  )

  // logger.log(
  //   'here are the data======',
  //   carImages.filter((i) => i.path['error'])
  // )

  const firstCar = imageNoBracket.slice(0, 10)
  const secondCar = imageNoBracket.slice(10, 20)
  const thirdCar = imageNoBracket.slice(20, 30)
  const fourthCar = imageNoBracket.slice(30, 40)
  const fifthCar = imageNoBracket.slice(40, 50)

  const doubleCar = []
  if (firstCar.length > 0) doubleCar.push(firstCar)
  if (secondCar.length > 0) doubleCar.push(secondCar)
  if (thirdCar.length > 0) doubleCar.push(thirdCar)
  if (fourthCar.length > 0) doubleCar.push(fourthCar)
  if (fifthCar.length > 0) doubleCar.push(fifthCar)

  return doubleCar
}
