import React from 'react'

import PoliciesSubHeading from '../Policies/PoliciesSubHeading'

const PurposeProcess = () => {
  return (
    <div>
      <PoliciesSubHeading childern='The purposes of processing' />
    </div>
  )
}

export default PurposeProcess
