export function leftArrowAnimate() {
  return {
    nm: 'arrow-right',
    mn: '',
    layers: [
      {
        ty: 4,
        nm: 'pointer',
        mn: '',
        sr: 1,
        st: 30,
        op: 30,
        ip: 5,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [791.624, 536.594, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-143.187, -286.406],
                      [143.2, 0.005],
                      [-143.2, 286.406],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 65, ix: 5 },
                d: [],
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'tm',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Filter - Trim',
            nm: 'Trim Paths 1',
            ix: 2,
            e: {
              a: 1,
              k: [
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0.667, y: 1 },
                  s: [49],
                  t: 7,
                },
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [100],
                  t: 18,
                },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            s: {
              a: 1,
              k: [
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0.667, y: 1 },
                  s: [49],
                  t: 7,
                },
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [0],
                  t: 18,
                },
              ],
              ix: 1,
            },
            m: 1,
          },
        ],
        ind: 0,
      },
      {
        ty: 4,
        nm: 'line',
        mn: '',
        sr: 1,
        st: 0,
        op: 30,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [498.349, 536.593, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Group 1',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-436.493, 0],
                      [436.493, 0],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 2,
                lj: 2,
                ml: 1,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 65, ix: 5 },
                d: [],
                c: { a: 0, k: [0.0118, 0.3529, 0.651], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [0, 0], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'tm',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Filter - Trim',
            nm: 'Trim Paths 1',
            ix: 2,
            e: {
              a: 1,
              k: [
                {
                  o: { x: 0.333, y: 0 },
                  i: { x: 0.667, y: 1 },
                  s: [0],
                  t: 0,
                },
                {
                  o: { x: 0.167, y: 0.167 },
                  i: { x: 0.833, y: 0.833 },
                  s: [100],
                  t: 7,
                },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            s: { a: 0, k: 0, ix: 1 },
            m: 1,
          },
        ],
        ind: 1,
      },
    ],
    ddd: 0,
    h: 1000,
    w: 1000,
    meta: {
      a: '',
      k: '',
      d: '',
      g: '@lottiefiles/toolkit-js 0.21.1',
      tc: '#ffffff',
    },
    v: '5.7.4',
    fr: 30,
    op: 30,
    ip: 0,
    assets: [],
  }
}
