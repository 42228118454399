import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHouseCircleCheck,
  faRadio,
  faBriefcase,
  faTrophy,
  faSearch,
  faCopyright,
  faFolderPlus,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const NewsListItem = ({
  home = false,
  press = false,
  company = false,
  award = false,
  research = false,
  products = false,
  add = false,
  user
}) => {

  return (
    <div className='newListItemOutercon'>
      <ul style={{ cursor: 'pointer' }} className='list-group'>
        <li style={{ padding: '0' }} className='list-group-item '>
          <Link
            style={{ textDecoration: 'none' }}
            to='/newsroom'
            className={home ? 'newsListItemLink' : 'newsListItemLink1'}
          >
            <div>
              <FontAwesomeIcon className='icon' icon={faHouseCircleCheck} />
            </div>
            <div className='textCon'>Home Press Room</div>
          </Link>
        </li>
        <li className='list-group-item pressReleaseLiCon'>
          <Link
            to='/newsroom/press-release'
            className={press ? 'newsListItemLink' : 'newsListItemLink1'}
            style={{ textDecoration: 'none' }}
          >
            <div>
              <FontAwesomeIcon className='icon' icon={faRadio} />
            </div>
            <div>Press Release</div>
          </Link>
        </li>
        <li className='list-group-item pressReleaseLiCon'>
          <Link
            style={{ textDecoration: 'none' }}
            to='/newsroom/company-info'
            className={company ? 'newsListItemLink' : 'newsListItemLink1'}
          >
            <div>
              <FontAwesomeIcon className='icon' icon={faBriefcase} />
            </div>
            <div>Company info</div>
          </Link>
        </li>
        <li className='list-group-item  pressReleaseLiCon'>
          <Link
            className={award ? 'newsListItemLink' : 'newsListItemLink1'}
            to='/newsroom/awards'
            style={{
              textDecoration: 'none',
            }}
          >
            <div>
              <FontAwesomeIcon className='icon' icon={faTrophy} />
            </div>
            <div>Awards & Recognition</div>
          </Link>
        </li>
        <li className='list-group-item  pressReleaseLiCon'>
          <div>
            <Link
              className={research ? 'newsListItemLink' : 'newsListItemLink1'}
              to='/newsroom/research'
              style={{
                textDecoration: 'none',
              }}
            >
              <FontAwesomeIcon className='icon' icon={faSearch} />
              <div>Nawafrica's Research</div>
            </Link>
          </div>
        </li>
        <li className='list-group-item  pressReleaseLiCon'>
          <Link
            to='/newsroom/products'
            className={products ? 'newsListItemLink' : 'newsListItemLink1'}
            style={{
              textDecoration: 'none',
            }}
          >
            <div>
              <FontAwesomeIcon className='icon' icon={faCopyright} />
            </div>
            <div>Our Products</div>
          </Link>
        </li>
        {user && user.isadmin && (
          <li className='list-group-item pressReleaseLiCon'>
            <Link
              to='/newsroom/add'
              className={add ? 'newsListItemLink' : 'newsListItemLink1'}
              style={{
                textDecoration: 'none',
              }}
            >
              <div>
                <FontAwesomeIcon className='icon' icon={faFolderPlus} />
              </div>
              <div>Add A Press Release</div>
            </Link>
          </li>
         )}
      </ul>
    </div>
  )
}

export default NewsListItem
