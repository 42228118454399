import React from 'react'

const ProbDetTitle = ({ title }) => {
  return (
    <div
      style={{
        width: '40%',
        fontSize: '20px',
        fontWeight: 'bolder',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '50px auto',
      }}
      className='alert alert-info'
    >
      {title}
    </div>
  )
}

export default ProbDetTitle
