import React from 'react'
import { FaAngleRight } from 'react-icons/fa'
import Chat from '../../iconComponent/Chat'
import { FaUsers } from 'react-icons/fa'
import Feature from '../../iconComponent/Feature'
import Gear from '../../iconComponent/Gear'
import Grid from '../../iconComponent/Grid'
import PlusSymbol from '../../iconComponent/PlusSymbol'
import SidebarList from '../../iconComponent/SidebarList'
import ListingItems from '../AddListing/ListingItems'
import User from '../../iconComponent/User'

import './manageTenants.css'

const OpenButtonManagement = ({ onClickOpenBar, path, chats }) => {
  const listingPath = '/management/listing'
  const addListingPath = '/management/add_listing'
  const tenantPath = '/management/tenants'
  const maintanancePath = '/management/maintenance'
  const messagesPath = '/management/message'

  return (
    <div role='button' className='openButtonCon'>
      <ListingItems
        icon={<Grid className='sidebarIconsClassMenu' />}
        openAngle={
          <FaAngleRight
            onClick={onClickOpenBar}
            className='sidebarMenuRightAngle'
          />
        }
        path={path}
        classes={'ListingItemConAddList'}
      />

      <hr className='sidebarHr' />

      <div className='sideBarLinks'>
        <ListingItems
          icon={
            <SidebarList
              className={
                path === listingPath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          href='/management/listing'
          path={path}
          classes={
            path === listingPath ? 'activePortalLink' : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={
            <PlusSymbol
              className={
                path === addListingPath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          href={'/management/add_listing'}
          path={path}
          classes={
            path === addListingPath
              ? 'activePortalLink'
              : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={
            <FaUsers
              className={
                path === tenantPath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          href={'/management/tenants'}
          path={path}
          classes={
            path === tenantPath ? 'activePortalLink' : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={
            <User
              className={
                path === maintanancePath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          chats='2'
          path={path}
          href={'/management/maintenance'}
          classes={
            path === maintanancePath
              ? 'activePortalLink'
              : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={
            <Chat
              className={
                path === messagesPath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          chats={chats}
          path={path}
          href={'/management/message'}
          classes={
            path === messagesPath ? 'activePortalLink' : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={<Gear className='sidebarIconLink' />}
          path={path}
          classes={'ListingItemConAddList'}
        />
      </div>
    </div>
  )
}

export default OpenButtonManagement
