import React from 'react'

const AddStoryTextarea1 = ({
  title,
  placeholder,
  onChange,
  value,
  rows = '10',
}) => {
  return (
    <div style={{ marginTop: '5px' }}>
      <label
        style={{
          fontWeight: 'bold',
        }}
        htmlFor={`${title}`}
        className='form-label'
      >
        {title}
      </label>

      <textarea
        className='form-control'
        id={`${title}`}
        placeholder={placeholder}
        rows={rows}
        style={{ borderRadius: '10px' }}
        onChange={onChange}
        value={value}
      ></textarea>
    </div>
  )
}

export default AddStoryTextarea1
