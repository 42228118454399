import React from 'react'
import PoliciesSubHeading from '../Policies/PoliciesSubHeading'

const TypeDataCollect = () => {
  return (
    <div>
      <PoliciesSubHeading childern='Types of Data collected' />
      <p
        style={{
          marginTop: '25px',
          marginBottom: '0',
          fontSize: '14px',
          opacity: '0.9',
        }}
      >
        Among the types of Personal Data that this Website collects, by itself
        or through third parties, there are: Cookies; Usage Data; email address;
        phone number.
      </p>
      <p
        style={{
          marginTop: '0',
          fontSize: '14px',
          opacity: '0.9',
          marginBottom: '0',
        }}
      >
        Complete details on each type of Personal Data collected are provided in
        the dedicated sections of this privacy policy or by specific explanation
        texts displayed prior to the Data collection.
      </p>
      <p
        style={{
          marginTop: '0',
          fontSize: '14px',
          opacity: '0.9',
          marginBottom: '0',
        }}
      >
        Personal Data may be freely provided by the User, or, in case of Usage
        Data, collected automatically when using this Website. Unless specified
        otherwise, all Data requested by this Website is mandatory and failure
        to provide this Data may make it impossible for this Website to provide
        its services. In cases where this Website specifically states that some
        Data is not mandatory, Users are free not to communicate this Data
        without consequences to the availability or the functioning of the
        Service.
      </p>
      <p
        style={{
          marginTop: '0',
          fontSize: '14px',
          opacity: '0.9',
        }}
      >
        Users who are uncertain about which Personal Data is mandatory are
        welcome to contact the Owner. Any use of Cookies – or of other tracking
        tools – by this Website or by the owners of third-party services used by
        this Website serves the purpose of providing the Service required by the
        User, in addition to any other purposes described in the present
        document and in the Cookie Policy, if available.
      </p>
      <p
        style={{
          fontSize: '14px',
          opacity: '0.9',
        }}
      >
        Users are responsible for any third-party Personal Data obtained,
        published or shared through this Website and confirm that they have the
        third party's consent to provide the Data to the Owner.
      </p>
    </div>
  )
}

export default TypeDataCollect
