import React from 'react'
import Lottie from 'react-lottie'
import { hammerSign } from './hammer-sign'

const HammerSign = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: hammerSign(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={250} width={250} />
    </div>
  )
}

export default HammerSign
