import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { Image, Video } from "cloudinary-react";
import { useTranslation } from "react-i18next";
import { setConfig, buildUrl, buildVideoUrl } from "cloudinary-build-url";

import Chat from "./../../iconComponent/Chat";
import "./listingDetails.css";
import { Nothumb } from "../../Utils/cloudinaryThumbnail";
import { getCurrentUser } from "../../Services/authService";

setConfig({
  cloudName: "captivator",
});

const Carousel = ({
  images,
  onImageClick,
  numberOfUnreadMsgs = 0,
  onHoverPicture,
  selectedImage,
  onClickMessage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {getCurrentUser() && (
        <div onClick={onClickMessage} className="recievedMessageCon1">
          <div className="badge">{numberOfUnreadMsgs}</div>
          <Chat className="headerSearchIcon" />
        </div>
      )}

      <div
        id="carouselExampleControls"
        className="carousel slide carouselOuterCon"
        data-ride="carousel"
      >
        <div className="carousel-inner">
          {images.map((imageArray, index) => (
            <div
              key={`${index}`}
              className={
                index === 0
                  ? "carousel-item active carouselImagesCon"
                  : "carousel-item carouselImagesCon"
              }
            >
              <div className="row">
                {imageArray.map((images, index) => {
                  let src;
                  if (images.format === "mp4") {
                    src = buildVideoUrl(images.url, {
                      transformations: {
                        resize: {
                          type: "scale",
                          width: 600,
                          height: 500,
                        },
                      },
                    });
                  } else {
                    src = buildUrl(
                      images.public_id ? images.url : images.path.url,
                      {
                        transformations: {
                          resize: {
                            type: "scale",
                            width: 600,
                            height: 400,
                          },
                        },
                      }
                    );
                  }

                  return (
                    <div
                      key={`${index}`}
                      className="col-lg-6 col-md-6 col-sm-6 col-xs-6 carouselingTheImage"
                      onMouseEnter={() => onHoverPicture(images)}
                      onMouseLeave={() => onHoverPicture(null)}
                    >
                      {images.format === "mp4" ? (
                        <a
                          onClick={() => onImageClick(images)}
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                        >
                          {images.public_id ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItem: "center",
                                backgroundColor: "lightgray",
                                overflow: "hidden",
                              }}
                            >
                              {/* <div
                                className={
                                  selectedImage === images
                                    ? 'animate__animated animate__bounceInDown clickToSeeDetailContainer21'
                                    : 'animate__animated animate__bounceInDown clickToSeeDetailContainer'
                                }
                              >
                                {selectedImage && selectedImage.title
                                  ? selectedImage.title
                                  : t('click to watch video')}
                              </div> */}

                              <Video
                                crossOrigin="anonymous"
                                style={
                                  selectedImage == images
                                    ? {
                                        opacity: "0.5",
                                        objectFit: "cover",
                                        boxShadow: "3px 1px 3px 0.5px #969696",
                                      }
                                    : {
                                        opacity: "1",
                                        objectFit: "cover",
                                        // boxShadow: '2px 5px 7px grey',
                                        boxShadow: "3px 1px 3px 0.5px #969696",
                                      }
                                }
                                playsInline
                                controls
                                loop
                                className="listingDetailsCarouselImage"
                                cloudName="captivator"
                                publicId={images.public_id}
                              />
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItem: "center",
                                backgroundColor: "lightgray",
                                overflow: "hidden",
                              }}
                            >
                              {/* <div
                                className={
                                  selectedImage === images
                                    ? 'animate__animated animate__bounceInDown clickToSeeDetailContainer21'
                                    : 'animate__animated animate__bounceInDown clickToSeeDetailContainer'
                                }
                              >
                                {selectedImage && selectedImage.title
                                  ? selectedImage.title
                                  : t('click to watch video')}
                              </div> */}
                              <video
                                style={
                                  selectedImage == images
                                    ? { opacity: "0.5" }
                                    : { opacity: "1" }
                                }
                                playsInline
                                controls
                                loop
                                className="listingDetailsCarouselImage"
                                src={images.url}
                              />
                            </div>
                          )}
                        </a>
                      ) : (
                        <a
                          onClick={() => onImageClick(images)}
                          type="button"
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                        >
                          {images.public_id ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItem: "center",
                                backgroundColor: "lightgray",
                              }}
                            >
                              {/* <div
                                className={
                                  selectedImage === images
                                    ? 'animate__animated animate__bounceInDown clickToSeeDetailContainer21'
                                    : 'animate__animated animate__bounceInDown clickToSeeDetailContainer'
                                }
                              >
                                {selectedImage && selectedImage.title
                                  ? t('click to view larger image of', {
                                      title: t(selectedImage.title),
                                    })
                                  : t('click to watch larger image')}
                              </div> */}

                              <img
                                style={
                                  selectedImage == images
                                    ? {
                                        opacity: "0.5",
                                        objectFit: "cover",
                                        boxShadow: "3px 1px 3px 0.5px #969696",
                                        borderRadius: "5px",
                                      }
                                    : {
                                        opacity: "1",
                                        objectFit: "cover",
                                        boxShadow: "3px 1px 3px 0.5px #969696",
                                        borderRadius: "5px",
                                      }
                                }
                                crossOrigin="anonymous"
                                className="listingDetailsCarouselImage"
                                src={src}
                              />

                              {/* <Image
                              style={
                                selectedImage == images
                                  ? { opacity: '0.5' }
                                  : { opacity: '1' }
                              }
                              className='listingDetailsCarouselImage'
                              cloudName='captivator'
                              publicId={images.public_id}
                            /> */}
                            </div>
                          ) : images.path.public_id ? (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItem: "center",
                                backgroundColor: " lightgray",
                              }}
                            >
                              {/* <div
                                className={
                                  selectedImage === images
                                    ? 'animate__animated animate__bounceInDown clickToSeeDetailContainer21'
                                    : 'animate__animated animate__bounceInDown clickToSeeDetailContainer'
                                }
                              >
                                {selectedImage && selectedImage.title
                                  ? t('click to view larger image of', {
                                      title: t(selectedImage.title),
                                    })
                                  : t('click to watch larger image')}
                              </div> */}
                              {Nothumb(images.path.url, "100%", "100%") && (
                                <img
                                  crossOrigin="anonymous"
                                  style={
                                    selectedImage == images
                                      ? {
                                          opacity: "0.5",
                                          objectFit: "cover",
                                          boxShadow:
                                            "3px 1px 3px 0.5px #969696",
                                          borderRadius: "5px",
                                        }
                                      : {
                                          opacity: "1",
                                          objectFit: "cover",
                                          boxShadow:
                                            "3px 1px 3px 0.5px #969696",
                                          borderRadius: "5px",
                                        }
                                  }
                                  className="listingDetailsCarouselImage"
                                  src={src}
                                />
                              )}
                              {/* <Image
                              style={
                                selectedImage == images
                                  ? { opacity: '0.5' }
                                  : { opacity: '1' }
                              }
                              className='listingDetailsCarouselImage'
                              cloudName='captivator'
                              publicId={images.path.public_id}
                            /> */}
                            </div>
                          ) : (
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItem: "center",
                                backgroundColor: "lightgray",
                              }}
                            >
                              {/* <div
                                className={
                                  selectedImage === images
                                    ? 'animate__animated animate__bounceInDown clickToSeeDetailContainer21'
                                    : 'animate__animated animate__bounceInDown clickToSeeDetailContainer'
                                }
                              >
                                {selectedImage && selectedImage.title
                                  ? t('click to view larger image of', {
                                      title: t(selectedImage.title),
                                    })
                                  : t('click to watch larger image')}
                              </div> */}
                              <img
                                style={
                                  selectedImage == images
                                    ? {
                                        opacity: "0.5",
                                        objectFit: "cover",
                                        boxShadow: "3px 1px 3px 0.5px #969696",
                                        borderRadius: "5px",
                                      }
                                    : {
                                        opacity: "1",
                                        objectFit: "cover",
                                        boxShadow: "3px 1px 3px 0.5px #969696",
                                        borderRadius: "5px",
                                      }
                                }
                                crossOrigin="anonymous"
                                className="listingDetailsCarouselImage"
                                src={images.url}
                              />
                            </div>
                          )}
                        </a>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
        {images.length > 1 && (
          <a
            className="carousel-control-prev listingDetailsPrevBtn"
            href="#carouselExampleControls"
            role="button"
            data-slide="prev"
          >
            <FaChevronLeft className="listingDetailsChevron" />
            <span className="sr-only">Previous</span>
          </a>
        )}
        {images.length > 1 && (
          <a
            className="carousel-control-next listingDetailsPrevBtn"
            href="#carouselExampleControls"
            role="button"
            data-slide="next"
          >
            <FaChevronRight className="listingDetailsChevron" />
            <span className="sr-only">Next</span>
          </a>
        )}
      </div>
    </>
  );
};

export default Carousel;
