import React from 'react'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close';

const TenantPreviousLeases = ({
  tenantPrevLease,
  loadPrevLease,
  prevLeaseEnterLeave,
  listings,
  hoverAppl,
  onClickClose
}) => {
  const { t } = useTranslation()

  const getListingCurrency = (id) => {
    const listing = listings.filter((l) => l.id == id)[0]
    return listing.currency
  }

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase()
    })
  }

  return (
    <div
      className={
        prevLeaseEnterLeave === 'enter'
          ? 'prevEnterLeaveEnter'
          : 'prevEnterLeaveLeave'
      }
    >
         <button onClick={onClickClose} style={{borderRadius:"100%", height:"50px", width:"50px"}} className='btn btn-light'>
                  <CloseIcon />
                </button>
      {prevLeaseEnterLeave === 'enter' && (
        <h6
          style={{
            textAlign: 'center',
            font: 'normal normal bold 16px/25px Merriweather',
            color: '#035aa6',
          }}
        >
          {t("signed_lease_history",{
            tenant_name: capitalize(hoverAppl?.appicant_name)
          })}
        </h6>
      )}
      {loadPrevLease ? (
        <div>.....loading</div>
      ) : tenantPrevLease.length === 0 && prevLeaseEnterLeave === 'enter' ? (
        <div
          style={{
            marginTop: '20px',
            padding: '7px',
            background: '#035aa6',
            color: 'white',
            textAlign: 'center',
          }}
        >
          {prevLeaseEnterLeave === 'enter' && (
            <div
              style={{ font: 'normal normal normal 14px/28px Merriweather' }}
            >


              {t("Tenant have not signed any lease yet")}

            </div>
          )}
        </div>
      ) : (
        <div>
          {prevLeaseEnterLeave === 'enter' && (
            <table className='table table-striped'>
              <thead className='myListThead'>
                <tr>
                  <th
                    style={{ fontSize: '13px' }}
                    className='myListTh'
                    scope='col'
                  >
                    {t('Leases')}
                  </th>
                  <th
                    style={{ fontSize: '13px' }}
                    className='myListTh'
                    scope='col'
                  >
                    {t('Amount Owed')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {tenantPrevLease.map((l) => (
                  <tr className='table-column'>
                    <th style={{ fontSize: '13px' }} className='myListTd'>{l.listing_name}</th>
                    <td
                      style={
                        Number(l.amount_owed) > 0
                          ? { padding: '7px 0', color: 'red', opacity: '0.8', fontSize: '13px' }
                          : { padding: '7px 0', fontSize: '13px'}
                      }
                      className='myListTd removeTablePadding'
                    >
                      {Number(l.amount_owed) > 0 ? l.amount_owed : 0}
                      {getListingCurrency(l.listing_id)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  )
}

export default TenantPreviousLeases
