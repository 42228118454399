import React from 'react'
import { useTranslation } from 'react-i18next'

import './listingDetails.css'

const LeaseType = ({
  fixedLeased,
  sold_agreement,
  renewFixedLease,
  continueFixedLeaseMonthToMonth,
  mustVacateFixedLease,
  monthToMonthAgree,
}) => {
  const { t } = useTranslation()

  return (
    <div className='leaseTypeOuterCon '>
      <b>{t('Lease_Type')}</b>
      <div
        style={{
          margin: '7px 0',
          width: '100%',
          borderRadius: '5px',
          padding: '10px',
        }}
      >
        <div>
          <div className='inlineClass myStylingForLeaseSignInDetail'>
            {fixedLeased}
          </div>
        </div>
        <div className='fixedLeaseConditionCon'>
          <div>
            <div className='inlineClass myStylingForLeaseSignInDetail'>
              {renewFixedLease}
            </div>
          </div>
          <div>
            <div className='inlineClass myStylingForLeaseSignInDetail'>
              {continueFixedLeaseMonthToMonth}
            </div>
          </div>
          <div>
            <div className='inlineClass myStylingForLeaseSignInDetail'>
              {mustVacateFixedLease}
            </div>
          </div>
        </div>
        <div className='myStylingForLeaseSignInDetail'>{monthToMonthAgree}</div>
        <div className='myStylingForLeaseSignInDetail'>{sold_agreement}</div>
      </div>
    </div>
  )
}

export default LeaseType
