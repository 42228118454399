import React, { useState, useEffect, useContext } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import logger from '../Services/loggerService'
import ScrollToTop from 'react-scroll-to-top'
import { useTranslation } from 'react-i18next'

import WelcomeUser from '../components/AddListing/WelcomeUser'
import DownShowSideBar from '../components/AddListing/DownShowSideBar'
import Chats from '../components/AddListing/Chats'
import TitleListing from '../components/Listing/TitleListing'
import TableManageTenant from '../components/ManageTenants/TableManageTenant'
import ProfileSpinner from './../components/profile/ProfileSpinner'
import {
  getApplications,
  getListings,
  publishAListing,
} from '../Services/listings'
import { getCurrentUser } from '../Services/authService'
import { deleteAListing } from './../Services/listings'
import SideBarManagement from '../components/ManageTenants/SideBarManagement'
import OpenButtonManagement from '../components/ManageTenants/OpenButtonManagement'
import SearchManagement from '../components/ManageTenants/SearchManagement'
import UnreadMsgsContext from './../unreadmessages/context'
import Meta from './../Utils/Meta'
import { getLang } from '../Utils/getLang'
import '../components/Listing/listing.css'
import Meta1 from '../Utils/Meta1'

const ListingScreenManagement = ({ history }) => {
  const { t } = useTranslation()

  const [close, setClose] = useState(true)
  const [path, setPath] = useState('')
  const [upSideBar, setUpSideBar] = useState(false)

  const [myListings, setMyListings] = useState('')
  const [applications, setApplications] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [applyError, setApplyError] = useState('')

  const [sortColumn, setSortColumn] = useState({
    path: 'title',
    order: 'asc',
  })

  const [loadingSearchListings, setLoadingSearchListings] = useState(false)
  const [fromSearch, setFromSearch] = useState(false)

  const { unreadMsgs } = useContext(UnreadMsgsContext)

  useEffect(() => {
    if (
      getCurrentUser() &&
      getCurrentUser().post_rent_property === 'LOOKING TO BUY/RENT PROPERTY'
    )
      return history.push('/')
    localStorage.setItem('managementPath', '/management/listing')
    const myPath = localStorage.getItem('managementPath')
    setPath(myPath)
    window.scrollTo(0, 0)
    getAllListings()
    return () => {
      localStorage.setItem('managementPath', '')
    }
  }, [])

  //function to all listings posted by a given landlord
  const getAllListings = async () => {
    try {
      //get All Listing
      setLoading(true)
      const { data: allListings } = await getListings()
      const usersListings = allListings.filter(
        (listing) => listing.list_owner_id === getCurrentUser().id
      )
      setMyListings(usersListings)

      //get all applications

      const { data } = await getApplications()
      setApplications(data)

      setLoading(false)
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 400)
      ) {
        setLoading(false)
        setError(ex.response.data)
      }
    }
  }

  //function to close the side bar
  const onClickCloseBar = () => {
    setClose(false)
  }

  //function to open the sidebar
  const onClickOpenBar = () => {
    setClose(true)
  }

  //function to publish or unpublish a listing
  const onPublish = async (listing) => {
    try {
      let allItems = [...myListings]
      const index = allItems.indexOf(listing)
      const theListing = allItems.splice(index, 1)[0]
      if (theListing.publish === 'publish') {
        if (
          window.confirm('Are you sure you want to unpublish this listing ?')
        ) {
          theListing.publish = 'unpublish'
        }
      } else {
        theListing.publish = 'publish'
      }
      allItems.splice(index, 0, theListing)

      setMyListings(allItems)

      await publishAListing(listing.id)
    } catch (ex) {
      setApplyError(ex.response.data)
    }
  }

  //function to delete a listing from the listing table
  const onDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this listing ?')) {
      try {
        let allItems = [...myListings]
        const all = allItems.filter((item) => item.id !== id)
        setMyListings(all)
        await deleteAListing(id)
      } catch (ex) {
        logger.log(
          'error from onDelete function in ListingScreenManagement component',
          ex
        )
      }
    }
  }

  //function to search a listing
  const onSearchListing = async (e) => {
    e.preventDefault()
    const { value } = e.currentTarget
    if (value) {
      const tableData = searchTable(value, myListings)
      setMyListings(tableData)
      setFromSearch(true)
    } else {
      try {
        setLoadingSearchListings(true)
        const { data: allListings } = await getListings()
        const usersListings = allListings.filter(
          (listing) => listing.list_owner_id === getCurrentUser().id
        )
        setMyListings(usersListings)
        setLoadingSearchListings(false)
      } catch (error) {
        logger.log(
          'onSearchListing function error in ListingScreenManagement',
          error
        )
      }
    }
  }

  const searchTable = (value, data) => {
    let filteredArray = []
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase()
      let title = data[i].title.toLowerCase()

      if (title.includes(value)) {
        filteredArray.push(data[i])
      }
    }
    return filteredArray
  }

  //sorting the of listings
  const onSort = (e) => {
    e.preventDefault()
    const isListings = [...myListings]
    const { value } = e.currentTarget
    const column = { ...sortColumn }
    column.path = value
    const sortedListing = _.orderBy(isListings, [column.path], [column.order])
    setMyListings(sortedListing)
  }

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true)
  }

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase()
    })
  }

  if (loading)
    return (
      <div className='homeSpinnerContainer'>
        {/* <ProfileSpinner /> */}
        <ProfileSpinner />
      </div>
    )
  if (error)
    return (
      <div className='homeSpinnerContainer'>
        <div className='alert alert-danger'>{error}</div>
      </div>
    )
  return (
    <>
      <Meta1
        title={`${getLang()}.${t('meta_list', {
          userName: getCurrentUser().first_name,
        })}`}
      />
      {close && !upSideBar && (
        <SideBarManagement
          onClickCloseBar={onClickCloseBar}
          path={path}
          onTakeBarUp={onTakeBarUp}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? '99+' : unreadMsgs.length
          }
        />
      )}
      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      {!close && (
        <OpenButtonManagement
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? '99+' : unreadMsgs.length
          }
        />
      )}

      <div className='myListingOuterContainer'>
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? '99+' : unreadMsgs.length
          }
        />

        <WelcomeUser
          user={capitalize(getCurrentUser() && getCurrentUser().first_name)}
        />

        <div className={close ? 'myListingTableCon' : 'myListingTableCon1'}>
          <TitleListing />

          <SearchManagement
            onChangeSearch={onSearchListing}
            onChangeSort={onSort}
          />
          {/* {myListings.length > 0 && <PleaseDelete />} */}

          {myListings.length > 0 ? (
            <TableManageTenant
              items={myListings}
              applications={applications}
              onPublish={onPublish}
              onDelete={onDelete}
              loadingSearchListings={loadingSearchListings}
              fromSearch={fromSearch}
            />
          ) : loadingSearchListings ? (
            <div>.....loading</div>
          ) : (
            <div className='noListingAvailableYetCon'>
              <div className='alert'>
                No Listing Posted,{' '}
                <Link className='btn btn-light' to='/management/add_listing'>
                  Please Post A Listing
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <ScrollToTop smooth />
      <div className='letMeGiveSomeSeparationOverAccountable'></div>
    </>
  )
}

export default ListingScreenManagement
