import React from 'react';
import {Route,Redirect} from "react-router-dom"
import auth from "../Services/authService";

const ProtectedRoute=({path, component:Component, render,...rest})=>{

return (
<Route
{...rest}

exact
render={props=>{
if(!auth.getCurrentUser()) return <Redirect to={{
pathname:"/login",
state:{from:props.location}
}}
/>;

return Component?<Component {...props}/>:render(props);

}}
/>

)}

export default ProtectedRoute