import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Divider } from "@mui/material";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import TenantBadge from "./TenantBadge";
import TenantSeachName from "./TenantSeachName";
import { useTranslation } from "react-i18next";

const TenantAccordion = ({
  listings,
  tenants,
  availableSpaces,
  users,
  onChangeTenantName,
}) => {
  const { t } = useTranslation();
  return (
    <div className="tenantAccordionCon">
      {listings?.map((listing) => (
        <Accordion className="accordionContainer">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              display: "flex",
              justifyContent:
              "space-between",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
             }}

          >
            <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              flex: 1
              }}>
              <Typography>{listing.title}</Typography>
            </div>
            <div
            style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              flex: 1
            }}
            >
              <Typography>{t(listing.listing_type)}</Typography>
            </div>
            <div
             style={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              flex: 1
            }}
             >
              <Typography>{listing.city}</Typography>
            </div>
            <div style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              flex: 1
              }}>
              <Typography>{t(listing.country)}</Typography>
            </div>
            <div style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              flex: 1
              }}>
              <Typography>
                {tenants?.filter((t) => t.listing_id === listing.id).length}{" "}
                {t("tenant")}(s)
              </Typography>
            </div>
            <div style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              flex: 1
              }}>
              <Typography>
                {
                  availableSpaces?.filter(
                    (a) => a.empty_space === true && a.listing_id === listing.id
                  ).length
                }{" "}
                {t("Empty spaces")}(s)
              </Typography>
            </div>
            <div style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              flex: 1
               }}
               >
              <Typography>
                {
                  availableSpaces?.filter((a) => a.listing_id === listing.id)
                    .length
                }{" "}
                {t("Available space")}(s)
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "5px" }}>
            <div className="tenantAccordionConInner">
              <TenantSeachName onChangeTenantName={onChangeTenantName} />

              <Divider />
              <div
                style={{
                  width: "100%",
                  margin: "0 auto",
                  minHeight: "80px",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {availableSpaces?.filter((a) => a.listing_id === listing.id)
                  .length === 0 && (
                  <Alert style={{width:"40%", margin:"0 30%" }} severity="info">
                    {t("No Space Available for this listing")}
                  </Alert>
                )}

                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    container
                    spacing={1}
                  >
                    {availableSpaces.length > 0 &&
                      availableSpaces
                        .sort((a, b) => a.space_number - b.space_number)
                        ?.filter((a) => a.listing_id === listing.id)
                        .map((a) => (
                          <React.Fragment
                            key={`${a.id}`}
                            style={{ margin: "5px" }}
                          >
                            <Grid item xs={1} md={1} lg={1} xl={1}>
                              <TenantBadge
                                houseNumber={a.space_number}
                                color={
                                  tenants?.find(
                                    (t) =>
                                      t.listing_id === listing?.id &&
                                      t.tenant_id == a?.occupant_id
                                  )?.amount_owed > 0
                                    ? "#ee2400"
                                    : a.empty_space === false
                                    ? "#035aa6"
                                    : "#ffa500"
                                }
                                listing={listing}
                                users={users}
                                space={a}
                                tenants={tenants}
                                availableSpaces={availableSpaces}
                              />
                            </Grid>
                          </React.Fragment>
                        ))}
                  </Grid>
                </Box>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default TenantAccordion;
