import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

import TopHeadingImageOwner from "../components/AboutUs/TopHeadingImageOwner";
import CEOImage from "../components/AboutUs/CEOImage";
import AllLinks from "./../components/AboutUs/AllLinks";

import OwnershipContain from "../components/AboutUs/OwnershipContain";

const AboutUsOwnership = () => {
  const [aboutActive, setAboutActive] = useState(false);
  const [impactActive, setImpactActive] = useState(false);
  const [crewActive, setCrewActive] = useState(false);

  useEffect(() => {
    setImpactActive(true);
    window.scrollTo(0, 0);
  }, []);

  const a = () => {
    setAboutActive(true);
    setImpactActive(false);
    setCrewActive(false);
  };

  const b = () => {
    setImpactActive(true);
    setAboutActive(false);
    setCrewActive(false);
  };

  const c = () => {
    setCrewActive(true);
    setImpactActive(false);
    setAboutActive(false);
  };
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: "20px",
        fontFamily: "'Montserrat', sans-serif",
        fontSize: "18px",
      }}
    >
      <TopHeadingImageOwner owner={impactActive} />
      <AllLinks
        aboutActive={aboutActive}
        impactActive={impactActive}
        crewActive={crewActive}
        a={a}
        b={b}
        c={c}
      />

      <CEOImage />
      <OwnershipContain />

      <div className="aboutWhereParaCon1">
        <a
          style={{
            backgroundColor: "#7952b3",
            width: "350px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "20px",
          }}
          href="http://play.google.com/store/apps/details?id=com.felix.nawafrica"
          className="btn "
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{ width: "20px", height: "30px", marginRight: "5px" }}
            src="./images/google-play-play.png"
          />
          <span style={{ marginLeft: "7px", color: "white" }}>
            Download app on{" "}
          </span>
          <span
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              marginLeft: "5px",
              color: "white",
              fontFamily: "'Amiri', serif",
            }}
          >
            google playstore
          </span>
        </a>
      </div>
      <ScrollToTop smooth />
    </div>
  );
};

export default AboutUsOwnership;
