import React from 'react'

const Sentence1 = () => {
  return (
    <div style={{ marginTop: '70px', lineHeight: '2' }}>
      Terms and Conditions for Use of This Web Site Your access to and use of
      this web site (the “Site”) is conditioned on acceptance of the terms and
      conditions set forth herein. Read these terms before using the Site. By
      accessing, using, viewing, browsing, etc. the Site, you agree to these
      terms. If you do not agree to these terms, do not access the Site in any
      way.
    </div>
  )
}

export default Sentence1
