import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, TextField } from "@mui/material";

import "./manageTenants.css";
import TenantAnalytics from "./TenantAnalytics";

const SearchTenants = ({
  onChangeSearch,
  onChangeSort,
  onResetTenant,
  myTenants = [],
  myListings = [],
  availableSpaces = [],
  users = [],
  onChangeListingTitle,
  onChangeListingType,
  onChangeListingCountry,
  onChangeListingCity,
  onChangeTenantName,
}) => {
  const { t } = useTranslation();
  return (
    <div className="listingSearchCon">
      <div className="addlistCons">
        <a
          style={{ boxShadow: "3px 1px 3px 0.5px black" }}
          data-toggle="modal"
          data-target="#exampleModalTenant"
          className="searchButtonOhh"
          href="#"
          onClick={onResetTenant}
        >
          <span style={{ marginRight: "5px" }}>
            <FontAwesomeIcon icon={faPlus} />
          </span>
          {t("Add_Tenant")}
        </a>
        <div className="tenantAnalyticsSearchCon">
          <TenantAnalytics
            myTenants={myTenants}
            myListings={myListings}
            availableSpaces={availableSpaces}
            users={users}
            onChangeListingTitle={onChangeListingTitle}
            onChangeListingType={onChangeListingType}
            onChangeListingCountry={onChangeListingCountry}
            onChangeListingCity={onChangeListingCity}
            onChangeTenantName={onChangeTenantName}
          />
        </div>
      </div>

      <div className="inputFieldCon someMarginInListingSearchInput">
        {/* <div className='someLeftAndRightMargin'> */}
        {/* <input
          className='myListingSearchInput'
          onChange={onChangeSearch}
          placeholder={t('Search_your_Tenant')}
          style={{ outline: 'none' }}
        /> */}
        {/* </div> */}
        <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            inputProps={{
              style: { font: "normal normal 14px/25px Merriweather" },
            }}
            sx={{ width: "100%", font: "normal normal 14px/25px Merriweather" }}
            onChange={onChangeSearch}
            id="input-with-sx"
            label={t("Search_your_Tenant")}
            variant="standard"
          />
        </Box>
      </div>

      <div className="listingSortDropdown someMarginInListingSearchInput">
        <p>
          <span className="sortedBySpan">{t("Sort_By")}</span>
          <span className="listingSortSelectSpan">
            <select style={{ outline: "none" }} onChange={onChangeSort}>
              <option value="dateposted">Date</option>
              <option value="tenant_name">{t("Tenant_Name")}</option>
              <option value="building_name">{t("Listing_Name")}</option>
              <option value="house_address">{t("Listing_Address")}</option>
              <option value="appartment_num">{t("House_Number")}</option>
              <option value="amount_owed">{t("Amount_Owed")}</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  );
};

export default SearchTenants;
