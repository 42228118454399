import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import BlogHead from "../components/Blog/BlogHead";
import BlogBody from "../components/Blog/BlogBody";
import { deleteBlogById, getBlog } from "../Services/blogService";
import BlogPagination from "../components/Blog/BlogPagination";
import { paginate } from "../Utils/pagination";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import { getUserById } from "./../Services/registerService";
import { getCurrentUser } from "../Services/authService";
import Meta1 from "../Utils/Meta1";
import PaginationMUI from "../components/Home/PaginationMUI";
import DownloadApp from "../components/AboutUs/DownloadApp";

const Blog = ({ history }) => {
  const [underConstruction, setUnderConstruction] = useState(false);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUser] = useState({});
  const [error, setError] = useState("");

  useEffect(() => {
    setUnderConstruction(false);
    window.scrollTo(0, 0);
    getBlogs();
  }, []);

  const getBlogs = async () => {
    try {
      setLoading(true);
      const { data } = await getBlog();
      setItems(data);
      if (getCurrentUser()) {
        const { data: us } = await getUserById(
          getCurrentUser() && getCurrentUser().id
        );
        setUser(us);
      }
      setLoading(false);
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 500) ||
        (ex.response && ex.response.status === 400)
      ) {
        setError("Opp!!!!, sorry Check your Network connection");
      }
    }
  };

  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  const nextPage = () => {
    if (currentPage != items.length) {
      let p = currentPage;
      p = p + 1;
      setCurrentPage(p);
    }
  };

  const previousPage = () => {
    if (currentPage != 1) {
      let p = currentPage;
      p = p - 1;
      setCurrentPage(p);
    }
  };

  const getDatas = () => {
    const itemss = paginate(items, currentPage, pageSize);

    return { totalCount: items.length, data: itemss };
  };

  const { totalCount, data: itemss } = getDatas();

  if (underConstruction)
    return (
      <>
        <Meta1
          title="Nawafrica | blog post"
          description="Nawafrica Blog Page"
          keywords="Nawafrica Blog"
          link="/blog"
        />
        <ComponentUnderConstruction />
      </>
    );
  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
      </div>
    );
  return (
    <div style={{ width: "100%", fontFamily: "'Montserrat', sans-serif" }}>
      <BlogHead />
      <BlogBody
        items={itemss}
        onClickDelete={async (id) => {
          if (window.confirm("Are you sure you want to delete this blog?")) {
            const blogs = [...itemss];
            const remainingBlog = blogs.filter((b) => b.id !== id);
            setItems(remainingBlog);
            await deleteBlogById(id);
          }
        }}
        onClickEdit={(id) => history.push(`/addBlog/${id}`)}
        user={user}
      />

      {itemss.length > 0 ? (
        <PaginationMUI
          count={Math.ceil(totalCount / pageSize)}
          onChange={handlePageChange}
        />
      ) : (
        // <BlogPagination
        //   itemsCount={totalCount}
        //   pageSize={pageSize}
        //   currentPage={currentPage}
        //   onPageChange={handlePageChange}
        //   nextPage={nextPage}
        //   previousPage={previousPage}
        // />
        <div className="alert alert-info noBlogAvailableCon">
          No Blog Available Yet, Please Check Later
        </div>
      )}

      <DownloadApp />
      <ScrollToTop smooth />
    </div>
  );
};

export default Blog;
