import React from 'react'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { FaDownload } from 'react-icons/fa'
import { FaChevronCircleDown } from 'react-icons/fa'
import { FaChevronCircleUp } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import LoadingSpinner from './../ManageTenants/LoadingSpinner'

const TableTenantPaymentNonmen = ({
  payments = [],
  listingCurrency,
  onClickPayment,
  loadingSearchPayment,
  selectedPaymentTable,
  ondownloadReceipt,
  selectedPaymentToLandlord,
  paymentDetails,
}) => {
  const { t } = useTranslation()

  function dateConvert(date) {
    const dateArray = date.split('-')
    return dateArray ? `${dateArray[2]}/${dateArray[1]}/${dateArray[0]}` : ''
  }

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`)
    if (myVal.includes(',')) {
      return val
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
      }
      return val
    }
  }

  return (
    <>
      <div
        data-simplebar
        className='leasesTableCon20 tenantDetailsPaymentTableCon'
      >
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              {/* <th className="myListTh" scope="col">Bill/mth</th> */}
              <th className='myListTh' scope='col'>
                {t('Amount_Paid')} /{listingCurrency}
              </th>
              <th className='myListTh' scope='col'>
                {t('Date_Paid')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Payment_Method')}
              </th>
              {/* <th className="myListTh" scope="col"> receipt</th> */}
            </tr>
          </thead>
          <tbody>
            {payments.map((payment) => (
              <React.Fragment key={payment.id}>
                <tr className='table-column'>
                  <td className='myListTd' style={{ paddding: '0' }}>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExample${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '20px',
                      }}
                    >
                      {selectedPaymentTable.includes(payment) ? (
                        <FaChevronCircleUp
                          style={{ zIndex: '-1' }}
                          onClick={() => onClickPayment(payment)}
                          className='faChevronCircleUp'
                        />
                      ) : (
                        <FaChevronCircleDown
                          style={{ zIndex: '-1' }}
                          onClick={() => onClickPayment(payment)}
                          className='faChevronCircleUp'
                        />
                      )}
                      {commaSeparateNumber(
                        Math.round(payment.amount * 100) / 100
                      )}
                    </a>
                  </td>
                  <td className='myListTd' style={{ padding: '0' }}>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExample${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '50px',
                      }}
                    >
                      {dateConvert(payment.create_date.slice(0, 10))}
                    </a>
                  </td>
                  <td className='myListTd' style={{ padding: '0' }}>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExample${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '50px',
                      }}
                    >
                      {payment.payment_method}
                    </a>
                  </td>
                </tr>

                <div
                  className='collapse editPaymenDetailsContainer'
                  id={`collapseExample${payment.id}`}
                >
                  <div className='card card-body'>
                    <table className='table table-striped'>
                      <thead className='myListThead'>
                        <tr>
                          <th className='myListTh' scope='col'>
                            {t('Month')}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('Year')}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('amount_paid')}/{listingCurrency}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('amount_owed/mth')}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('status')}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('Receipt')}
                          </th>
                        </tr>
                      </thead>

                      {paymentDetails &&
                        paymentDetails
                          .filter(
                            (detail) =>
                              detail.payments_to_landlord_id === payment.id
                          )
                          .map((pay) => (
                            <tr key={pay.id} className='table-column'>
                              <td className='myListTd'>
                                {pay.month_pay === '1'
                                  ? t('Jan')
                                  : pay.month_pay === '2'
                                  ? t('Feb')
                                  : pay.month_pay === '3'
                                  ? t('Mar')
                                  : pay.month_pay === '4'
                                  ? t('Apr')
                                  : pay.month_pay === '5'
                                  ? t('May')
                                  : pay.month_pay === '6'
                                  ? t('Jun')
                                  : pay.month_pay === '7'
                                  ? t('Jul')
                                  : pay.month_pay === '8'
                                  ? t('Aug')
                                  : pay.month_pay === '9'
                                  ? t('Sep')
                                  : pay.month_pay === '10'
                                  ? t('Oct')
                                  : pay.month_pay === '11'
                                  ? t('Nov')
                                  : t('Dec')}
                              </td>
                              <td className='myListTd'>{pay.year_pay}</td>
                              <td className='myListTd'>
                                {commaSeparateNumber(pay.amount_pay)}
                              </td>
                              <td className='myListTd'>
                                {commaSeparateNumber(pay.amount_owed)}/
                                {pay.month_pay === '1'
                                  ? t('Jan')
                                  : pay.month_pay === '2'
                                  ? t('Feb')
                                  : pay.month_pay === '3'
                                  ? t('Mar')
                                  : pay.month_pay === '4'
                                  ? t('Apr')
                                  : pay.month_pay === '5'
                                  ? t('May')
                                  : pay.month_pay === '6'
                                  ? t('Jun')
                                  : pay.month_pay === '7'
                                  ? t('Jul')
                                  : pay.month_pay === '8'
                                  ? t('Aug')
                                  : pay.month_pay === '9'
                                  ? t('Sep')
                                  : pay.month_pay === '10'
                                  ? t('Oct')
                                  : pay.month_pay === '11'
                                  ? t('Nov')
                                  : t('Dec')}
                              </td>
                              <td className='myListTd'>{pay.status_pay}</td>
                              <td className='myListTd'>
                                {pay === selectedPaymentToLandlord ? (
                                  <div>
                                    <LoadingSpinner />
                                  </div>
                                ) : (
                                  <button
                                    onClick={() => ondownloadReceipt(pay)}
                                    className='btn btn-sm downloadingTheReceiptPayment'
                                  >
                                    <FaDownload /> {t('receipt')}
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                    </table>
                    <button
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      data-target={`#collapseExample${payment.id}`}
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      className='btn closeTheDetailsBtn'
                    >
                      {t('close')}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ))}

            {loadingSearchPayment && <div>{t('.....loading')}</div>}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default TableTenantPaymentNonmen
