import React from "react";
import Reveal from "react-reveal/Reveal";

import "./styleCompo.css";

const NumberOfListingsBar = ({ children }) => {
  return (
    <div className="numberOfListingCon">
      <Reveal effect="fadeInUp">
        <h3 className="animate__animated animate__fadeInn">{children}</h3>
      </Reveal>
    </div>
  );
};

export default NumberOfListingsBar;
