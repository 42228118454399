import React from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search';
import { Box, TextField } from '@mui/material'

import './leases.css'

const SearchLease = ({ onChangeSearch, onChangeSort }) => {
  const { t } = useTranslation()

  return (
    <div className='listingSearchCon'>
      <div className='addlistCons'></div>

      <div className='inputFieldCon leaseInputSearchContainer'>
        {/* <input
          className='myListingSearchInput1'
          onChange={onChangeSearch}
          placeholder={t('Search your leases')}
        /> */}
        <Box sx={{ display: 'flex', alignItems: 'flex-end', width:"100%"}}>
        <SearchIcon sx={{ color: 'action.active', mr: 1}} />
        <TextField        
        inputProps={{
          style: { font: "normal normal normal 14px/25px Merriweather"},
        }}  
        sx={{width:"100%"}}
        onChange={onChangeSearch} id="input-with-sx" label={t('Search your leases')} variant="standard" />
      </Box>
      </div>

      <div className='listingSortDropdown1 someLeaseMargin'>
        <span className='sortedBySpan'>{t('Sort_By')}</span>
        <span className='listingSortSelectSpan'>
          <select onChange={onChangeSort}>
            <option value='dateposted'>{t('Date')}</option>
            <option value='list_owner_name'>{t('Landlord')}</option>
            <option value='listing_name'>{t('Lease')}</option>
            <option value='listing_address'>{t('Address')}</option>
            <option value='amount_owed'>{t('Amount_owed')}</option>
            <option value='total_amount_paid'>{t('Total_Amount_Paid')}</option>
          </select>
        </span>
      </div>
    </div>
  )
}

export default SearchLease
