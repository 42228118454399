import React from 'react'

import './profile.css'

const Input = ({
  labelValue,
  value = '',
  placeholder,
  fieldInput,
  onChange,
  id,
  fieldLabel,
}) => {
  return (
    <>
      <div className='profileInputOuterCon'>
        <label className={`passwordLabel ${fieldLabel}`} htmlFor={id}>
          <b>{labelValue}</b>
        </label>
        <input
          value={value ? value : ''}
          placeholder={placeholder}
          onChange={onChange}
          className={`form-control ${fieldInput}`}
          id={id}
          autoComplete='off'
        />
      </div>
    </>
  )
}

export default Input
