import React from 'react'
import { Helmet } from 'react-helmet-async'

const Meta1 = ({
  title = 'Nawafrica Real Estate',
  description = 'Search Properties In Africa',
  keywords = 'Rent house, buy house, rent land, buy land',
  link = '/',
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link rel='canonical' href={link} />
    </Helmet>
  )
}

export default Meta1
