export function owingAnimation() {
  return {
    nm: 'CirclePop',
    mn: '',
    layers: [
      {
        ty: 4,
        nm: 'circle 2',
        mn: '',
        sr: 1,
        st: 0,
        op: 750,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [-264, -41, 0], ix: 1 },
          s: { a: 0, k: [20, 20, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [15, 15, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [67, 67], ix: 2 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.9922, 0.0235, 0.0235], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-264, -40.75], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'circle',
        mn: '',
        sr: 1,
        st: 0,
        op: 750,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: { a: 0, k: [-264, -41, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 0,
              },
              { h: 1, s: [40, 40, 100], t: 25 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 26,
              },
              { h: 1, s: [40, 40, 100], t: 50 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 51,
              },
              { h: 1, s: [40, 40, 100], t: 75 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 76,
              },
              { h: 1, s: [40, 40, 100], t: 100 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 101,
              },
              { h: 1, s: [40, 40, 100], t: 125 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 126,
              },
              { h: 1, s: [40, 40, 100], t: 150 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 151,
              },
              { h: 1, s: [40, 40, 100], t: 175 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 176,
              },
              { h: 1, s: [40, 40, 100], t: 200 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 201,
              },
              { h: 1, s: [40, 40, 100], t: 225 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 226,
              },
              { h: 1, s: [40, 40, 100], t: 250 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 251,
              },
              { h: 1, s: [40, 40, 100], t: 275 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [20, 20, 100],
                t: 276,
              },
              { h: 1, s: [40, 40, 100], t: 300 },
              { h: 1, s: [20, 20, 100], t: 301 },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [15, 15, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 0,
              },
              { h: 1, s: [0], t: 25 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 26,
              },
              { h: 1, s: [0], t: 50 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 51,
              },
              { h: 1, s: [0], t: 75 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 76,
              },
              { h: 1, s: [0], t: 100 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 101,
              },
              { h: 1, s: [0], t: 125 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 126,
              },
              { h: 1, s: [0], t: 150 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 151,
              },
              { h: 1, s: [0], t: 175 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 176,
              },
              { h: 1, s: [0], t: 200 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 201,
              },
              { h: 1, s: [0], t: 225 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60],
                t: 226,
              },
              { h: 1, s: [0], t: 250 },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Ellipse 1',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'el',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Ellipse',
                nm: 'Ellipse Path 1',
                d: 1,
                p: { a: 0, k: [0, 0], ix: 3 },
                s: { a: 0, k: [67, 67], ix: 2 },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.9922, 0.0235, 0.0235], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [-264, -40.75], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
      },
    ],
    ddd: 0,
    h: 30,
    w: 30,
    meta: {
      a: 'Sabbir',
      k: 'teal',
      d: 'Circle pop',
      g: 'LottieFiles AE 0.1.21',
      tc: '#008080',
    },
    v: '5.5.7',
    fr: 25,
    op: 25,
    ip: 0,
    assets: [],
  }
}
