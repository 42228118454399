import React, { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import FaqDrop from "../components/FAQ/FaqDrop";
import FaqDrop2 from "../components/FAQ/FaqDrop2";
import FaqHeading from "../components/FAQ/FaqHeading";
import ProgressBarFaq from "../components/FAQ/ProgressBarFaq";
// import Meta from '../Utils/Meta'
import Meta1 from "../Utils/Meta1";
import DownloadApp from "../components/AboutUs/DownloadApp";
import { Button } from "@mui/material";
import { addFaq, getAnswersFaq } from "../Services/faqService";

const FAQ = () => {
  const [open, setOpen] = React.useState(false);
  const [openWhere1, setOpenWhere1] = React.useState(false);
  const [openWhere2, setOpenWhere2] = React.useState(false);
  const [openWhere3, setOpenWhere3] = React.useState(false);
  const [openWhere4, setOpenWhere4] = React.useState(false);
  const [openWhere5, setOpenWhere5] = React.useState(false);

  const [open2, setOpen2] = React.useState(false);
  const [openWhere11, setOpenWhere11] = React.useState(false);
  const [openWhere22, setOpenWhere22] = React.useState(false);
  const [openWhere33, setOpenWhere33] = React.useState(false);
  const [openWhere34, setOpenWhere34] = React.useState(false);

  const [open3, setOpen3] = React.useState(false);
  const [openWhere31, setOpenWhere31] = React.useState(false);
  const [openWhere32, setOpenWhere32] = React.useState(false);

  const [open4, setOpen4] = React.useState(false);
  const [openWhere41, setOpenWhere41] = React.useState(false);
  const [openWhere42, setOpenWhere42] = React.useState(false);
  const [openWhere43, setOpenWhere43] = React.useState(false);
  const [openWhere44, setOpenWhere44] = React.useState(false);

  const [open5, setOpen5] = React.useState(false);
  const [openWhere51, setOpenWhere51] = React.useState(false);
  const [openWhere52, setOpenWhere52] = React.useState(false);

  const [open6, setOpen6] = React.useState(false);
  const [openWhere61, setOpenWhere61] = React.useState(false);
  const [openWhere62, setOpenWhere62] = React.useState(false);
  const [openWhere63, setOpenWhere63] = React.useState(false);

  const [open7, setOpen7] = React.useState(false);
  const [openWhere71, setOpenWhere71] = React.useState(false);
  const [openWhere72, setOpenWhere72] = React.useState(false);
  const [openWhere73, setOpenWhere73] = React.useState(false);
  // req.body.catergory, req.body.question, req.body.answer

  const [catergory, setCatergory] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [loadingProgress, setLoadingProgress] = React.useState(0);
  const [answeringFaq, setAnsweringFaq] = React.useState([]);
  const [errorPost, setErrorPost] = React.useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getFaqData();
  }, []);

  const getFaqData = async () => {
    const { data: answersFaq } = await getAnswersFaq();
    setAnsweringFaq(answersFaq);
  };

  const postAFaq = async () => {
    try {
      if (catergory == "")
        return setErrorPost("Catergory is not allowed to be empty");

      if (question == "")
        return setErrorPost("Question is not allowed to be empty");

      const datas = {
        catergory,
        question,
      };

      await addFaq(datas, (progress) =>
        setLoadingProgress(`${progress * 100}`)
      );
      setCatergory("");
      setQuestion("");
      setLoadingProgress(0);
    } catch (ex) {
      setErrorPost(ex.response.data);
    }
  };

  return (
    <>
      <Meta1
        title="Nawafrica | FAQ"
        description="FAQ About Nawafrica"
        keywords="Frequently Ask Questions About Nawafrica"
        link="/faq"
      />
      <FaqHeading />
      <DownloadApp />
      <div className="faqContentCon">
        <FaqDrop
          open={open}
          onClick={() => setOpen(!open)}
          content={
            <>
              <FaqDrop2
                onClick={() => setOpenWhere1(!openWhere1)}
                openWhere={openWhere1}
              />
              <FaqDrop2
                onClick={() => setOpenWhere2(!openWhere2)}
                openWhere={openWhere2}
                question="Is Captivator Technologies a Registered company ?"
                answer="Yes, Captivator Technologies is a registered company in Cameroon. And it is a limited liability company."
              />
              <FaqDrop2
                onClick={() => setOpenWhere3(!openWhere3)}
                openWhere={openWhere3}
                question="Who is the CEO of Captivator Technologies ?"
                answer="The CEO of Captivator technologies is Leonard Ayamba Ndifon"
              />
              <FaqDrop2
                onClick={() => setOpenWhere4(!openWhere4)}
                openWhere={openWhere4}
                question="Who should i contact when i have an issue ?"
                answer="You can contact us via our website by clicking the contact us now link and submit the contact us form, and also you can come to our office and submit your complaint. Finally you can contact us on our facebook platform."
              />
              <FaqDrop2
                onClick={() => setOpenWhere5(!openWhere5)}
                openWhere={openWhere5}
                question="Are the founders of Captivator technologies of siblings?"
                answer="Yes, all the founders of Captivator Technologies are siblings. They are all from the same family.Who come together to use technology to help better the life of Africans."
              />
            </>
          }
        />
        <FaqDrop
          open={open2}
          topic="Opening An Account"
          onClick={() => setOpen2(!open2)}
          content={
            <>
              <FaqDrop2
                onClick={() => setOpenWhere11(!openWhere11)}
                openWhere={openWhere11}
                question="How do i open up an account as a landlord?"
                answer='Click on the sign up open  and fill in the form,  in the last input field select "I am a property owner", finall press the submit button.'
              />
              <FaqDrop2
                onClick={() => setOpenWhere22(!openWhere22)}
                openWhere={openWhere22}
                question="How do i open up an account as a Tenant Or as Buyer?"
                answer='Click on the sign up open  and fill in the form,  in the last input field select "Looking to Buy/Rent Property", finall press the submit button.'
              />
              <FaqDrop2
                onClick={() => setOpenWhere33(!openWhere33)}
                openWhere={openWhere33}
                question="How secure is my nawafrica account?"
                answer="Captivator technologies has a cyber secure expert who is responsible to make sure all accounts open in Nawafrica is extremely secure and beside that the web developers are so profession and build the web application in such a way that all password is highly coded and salted hence protecting the appplication from cyber criminals"
              />
              <FaqDrop2
                onClick={() => setOpenWhere34(!openWhere34)}
                openWhere={openWhere34}
                question="I have forgotten my password, our can i reset the password of my account?"
                answer="If at any given moment you forgot your password or just wishes to reset your password, you can click on the forgotten password link, and Submitted your email, your will be given a code that you will use to reset your password"
              />
            </>
          }
        />

        <FaqDrop
          open={open3}
          topic="Commissions and Fees"
          onClick={() => setOpen3(!open3)}
          content={
            <>
              <FaqDrop2
                onClick={() => setOpenWhere31(!openWhere31)}
                openWhere={openWhere31}
                question="Does it cost anything amount to open up an account as a landlord or a tenant?"
                answer="Opening an account as a tenant or landlord does not cost anything. Weither opening an account as a tenant or a landlord it is totally free and does not cost a dime"
              />
              <FaqDrop2
                onClick={() => setOpenWhere32(!openWhere32)}
                openWhere={openWhere32}
                question="Are the nawafrica commission and fee expensive ?"
                answer="The commissions and fees for nawafrica is extremely cheap and Affordable considering the fact that most Africans are living below poverty level. Commissions and fees should not be factor or any reason why a persons would not want to use Nawafrica as a platform, be it a tenant or a property owner"
              />

              <FaqDrop2
                onClick={() => setOpenWhere33(!openWhere33)}
                openWhere={openWhere33}
                question="What currency does Nawafrica use ?"
                answer="Captivator technologies uses currencies that are used by country all over the world to make transactions."
              />
              <FaqDrop2
                onClick={() => setOpenWhere34(!openWhere34)}
                openWhere={openWhere34}
                question="How do I avoid a scam when making payment ?"
                answer="KYC rule(know your customer) is the how to prevent yourselve from being scam, ensure that you know your landlord and you are living in the particular listing before executing any payment"
              />
            </>
          }
        />

        <FaqDrop
          open={open4}
          topic="More about the Listing Portal"
          onClick={() => setOpen4(!open4)}
          content={
            <>
              <FaqDrop2
                onClick={() => setOpenWhere41(!openWhere41)}
                openWhere={openWhere41}
                question="What are the uses of the listing portable ?"
                answer="The Listing Portal is consist of various links that helps the landlord to add listing, view all his listings, see all other users that he have applied to a particular listing that he/ she have posted to make decision on the application weither under review, Sign  or delete lease, he/ she can send and recieve messages to all his applicants and finally view all his/ her Bills and payment made to him"
              />
              <FaqDrop2
                onClick={() => setOpenWhere43(!openWhere43)}
                openWhere={openWhere43}
                question="How do the property owner sign the application sent to him ?"
                answer="To sign your application, click on the listing portal, then click on applications, and after that click on the application that you which to sign and click on under review and wait for the tenant to sign. After the teant have signed the lease you can then sign the lease to add that particular applicant to your list of tenants."
              />
              <FaqDrop2
                onClick={() => setOpenWhere42(!openWhere42)}
                openWhere={openWhere42}
                question="Do every registered users of nawafrica contain the Listing portal ?"
                answer="No, Only Users who register as a property owners contain the listing portal, if you register as a tenant, you won;t have the listing portal button"
              />
              <FaqDrop2
                onClick={() => setOpenWhere44(!openWhere44)}
                openWhere={openWhere44}
                question="How do i verify my listing ?"
                answer="To verify your listing, contact our office carrying along identity card, and a passport size photograph. Your listing will be verified and label as verify.  You can also verify by contacting our heading through our office whatsapp number 650432299"
              />
            </>
          }
        />
        <FaqDrop
          open={open5}
          topic="More about the Tenant Management Portal"
          onClick={() => setOpen5(!open5)}
          content={
            <>
              <FaqDrop2
                onClick={() => setOpenWhere51(!openWhere51)}
                openWhere={openWhere51}
                question="What are the uses of the Tenant Management ?"
                answer="The Tenant Management portal exposes the users into several functionalities, some of which include View and manage all the tenants of a given listing thereby monitoring their payments made for that listing as well as the amount owed by that Particular tenant. It also use by the landlord to view all the maintenants complaints made by his tenants so as to contact a repairer to give tenants with the best customer service. It also have Link used in sending and recieving messages to all his tenants and applicants"
              />
              <FaqDrop2
                onClick={() => setOpenWhere52(!openWhere52)}
                openWhere={openWhere52}
                question="Do every registered users of nawafrica contain the Tenant management Portal ?"
                answer="No, Only Users who register as a property owners contain the Tenant management portal, if you register as a tenant, you won;t have the Tenant management button"
              />
            </>
          }
        />

        <FaqDrop
          open={open6}
          topic="More about Tenant Portals?"
          onClick={() => setOpen6(!open6)}
          content={
            <>
              <FaqDrop2
                onClick={() => setOpenWhere61(!openWhere61)}
                openWhere={openWhere61}
                question="How can i sign my application as a tenant?"
                answer="Before you sign an a lease as a tenant, the landlord have to make sure the application is under review. To view if an application is under review, click on the Tenant Portal and then on the lease link and on the right hand side, the application that is under review will appear, click on it to view the agreement and finally click sign lease"
              />
              <FaqDrop2
                onClick={() => setOpenWhere62(!openWhere62)}
                openWhere={openWhere62}
                question="As a tenant, How do i make payment to a particular property that i am renting or have been bought ?"
                answer="To make payment for a given listing, firstly on the lease component, you have to add a payment method, and secondly, click on the property you wish to make payment on the lease table, when on the details of that particular listing, click on the make payment button. finally, choose the payment method you which to make payment and fill in the amount, and then submit your payment"
              />
              <FaqDrop2
                onClick={() => setOpenWhere63(!openWhere63)}
                openWhere={openWhere63}
                question="How Do i know if i am owing my Landlord ?"
                answer="To know if you are are owing your landlord for a given leasing, click on the tenant portal, and then click on the lease and finally check on the lease table, the amount owed will be indicated there. to view details click the lease and see all information about your payment for the lease"
              />
            </>
          }
        />

        <FaqDrop
          open={open7}
          topic="More about Applications ?"
          onClick={() => setOpen7(!open7)}
          content={
            <>
              <FaqDrop2
                onClick={() => setOpenWhere71(!openWhere71)}
                openWhere={openWhere71}
                question="How can i apply for a listing as a tenant?"
                answer="To apply for a listing as a tenant, You should click for on the listing you wishes to apply and screw down to where you see the blue button apply button. Click on the apply button to apply for that particular listing."
              />
              <FaqDrop2
                onClick={() => setOpenWhere72(!openWhere72)}
                openWhere={openWhere72}
                question="How do i view all my applications as tenant ?"
                answer="To view all your applications as a tenant, click on the tenant portal and on the side bar , click on applications. All your applications will be display on the application table."
              />
              <FaqDrop2
                onClick={() => setOpenWhere73(!openWhere73)}
                openWhere={openWhere73}
                question="How do i sign an application as a landlord ?"
                answer="To sign an application as a tenant, click on the Listing portal, and then on the side bar, click on applications. On the applications table, click on the application that you want to want sign the lease. Click the under Review button and want for the tenant who apply for this listing to sign the lease. And finally, you can the lease."
              />
            </>
          }
        />
        <hr style={{ margin: "50px 0", width: "100%" }} />

        <div
          style={{
            width: "100%",
            padding: "20px",
            border: "2px solid #035aa6",
            borderRadius: "5px",
          }}
        >
          <Autocomplete
            disablePortal
            onChange={(e, values) => setCatergory(values)}
            value={catergory}
            id="combo-box-demo"
            options={[
              "About Captivator Technologies And Nawafrica",
              "Opening An Account",
              "Commissions and Fees",
              "More about the Listing Portal",
              "More about the Tenant Management Portal",
              "More about Tenant Portals",
              "More about Applications",
            ]}
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Areas Of Interest" />
            )}
          />
          <TextField
            sx={{ width: "100%" }}
            id="standard-basic"
            label="Ask A Question"
            variant="standard"
            onChange={(e) => setQuestion(e.target.value)}
            value={question}
          />

          <Button
            onClick={postAFaq}
            sx={{ margin: "10px 10%", width: "80%" }}
            variant="contained"
          >
            Submit Question
          </Button>
          {errorPost && (
            <p style={{ color: "red", textAlign: "center", fontSize: "12px" }}>
              {errorPost}
            </p>
          )}
          {loadingProgress != 0 && (
            <div style={{ width: "100%", margin: "5px 0" }}>
              <ProgressBarFaq progress={loadingProgress} />
            </div>
          )}
        </div>
        <DownloadApp />
      </div>
      <ScrollToTop smooth />
    </>
  );
};

export default FAQ;
