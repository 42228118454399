import React from 'react'

const StoryParagraph = ({ sentence }) => {
  return (
    <div className='ourStoryPara'>
      <p>{sentence}</p>
    </div>
  )
}

export default StoryParagraph
