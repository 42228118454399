import React from 'react'
import { FaAngleLeft, FaUsers, FaAngleUp } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import ListingItems from '../AddListing/ListingItems'
import Grid from '../../iconComponent/Grid'
import SidebarList from './../../iconComponent/SidebarList'
import PlusSymbol from '../../iconComponent/PlusSymbol'
import Chat from '../../iconComponent/Chat'
import Gear from '../../iconComponent/Gear'
import User from '../../iconComponent/User'

import './manageTenants.css'

const SideBarManagement = ({ onClickCloseBar, path, chats, onTakeBarUp }) => {
  const { t } = useTranslation()

  const listingPath = '/management/listing'
  const addListingPath = '/management/add_listing'
  const tenantPath = '/management/tenants'
  const maintanancePath = '/management/maintenance'
  const messagesPath = '/management/message'

  return (
    <div className='sidebarContainer'>
      <ListingItems
        href={'#'}
        icon={<Grid className='sidebarIconsClassMenu' />}
        title={<span className='sidebarMenuSpan'>{t('Menu')}</span>}
        angle={
          <div className='angleIconContainer' onClick={onClickCloseBar}>
            <FaAngleLeft className='sidebarMenuLeftAngle' />
          </div>
        }
        path={path}
        classes={'ListingItemConAddList'}
      />

      <hr className='sidebarHr' />

      <div className='sideBarLinks'>
        <ListingItems
          icon={
            <SidebarList
              className={
                path === listingPath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          title={
            <span
              className={
                path === listingPath ? 'activeSidebarTitle' : 'sidebarLinkTitle'
              }
            >
              {t('Listings')}
            </span>
          }
          href='/management/listing'
          path={path}
          classes={
            path === listingPath ? 'activePortalLink' : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={
            <PlusSymbol
              className={
                path === addListingPath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          title={
            <span
              className={
                path === addListingPath
                  ? 'activeSidebarTitle'
                  : 'sidebarLinkTitle'
              }
            >
              {t('Add_Listing')}
            </span>
          }
          href={'/management/add_listing'}
          path={path}
          classes={
            path === addListingPath
              ? 'activePortalLink'
              : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={
            <FaUsers
              className={
                path === tenantPath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          title={
            <span
              className={
                path === tenantPath ? 'activeSidebarTitle' : 'sidebarLinkTitle'
              }
            >
              {t('Tenants')}
            </span>
          }
          href={'/management/tenants'}
          path={path}
          classes={
            path === tenantPath ? 'activePortalLink' : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={
            <User
              className={
                path === maintanancePath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          title={
            <span
              className={
                path === maintanancePath
                  ? 'activeSidebarTitle'
                  : 'sidebarLinkTitle'
              }
            >
              {t('Mantenance')}
            </span>
          }
          path={path}
          href={'/management/maintenance'}
          classes={
            path === maintanancePath
              ? 'activePortalLink'
              : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={
            <Chat
              className={
                path === messagesPath
                  ? 'sidebarIconLinkActive'
                  : 'sidebarIconLink'
              }
            />
          }
          title={
            <span
              className={
                path === messagesPath
                  ? 'activeSidebarTitle'
                  : 'sidebarLinkTitle'
              }
            >
              {t('Messages')}
            </span>
          }
          chats={chats}
          path={path}
          href={'/management/message'}
          classes={
            path === messagesPath ? 'activePortalLink' : 'ListingItemConAddList'
          }
        />

        <ListingItems
          icon={<Gear className='sidebarIconLink' />}
          title={<span className='sidebarLinkTitle'>{t('Settings')}</span>}
          path={path}
          classes={'ListingItemConAddList'}
          href={'#'}
        />
      </div>

      <button onClick={onTakeBarUp} className='signBarUpArrow'>
        <FaAngleUp />
      </button>
    </div>
  )
}

export default SideBarManagement
