import React from 'react'

const RentDetailsResponse = ({
  onClickLove,
  onClickCool,
  onClickHelpful,
  responses = 0,
  responseData,
}) => {
  return (
    <div className='rentSaleResponseCon'>
      <h1 className='firstH1'>What Do You Think</h1>
      <h1 className='secondH1'>
        {responses === 0 ? 'No' : responses} Responses
      </h1>
      <div className='rentSaleResponseBtnCon'>
        <button
          style={
            responseData.love
              ? {
                  background: '#035aa6',
                  border: '1px solid #035aa6',
                  color: 'white',
                }
              : {
                  background: 'white',
                  border: '1px solid #035aa6',
                  color: '#035aa6',
                }
          }
          onClick={onClickLove}
          className='btn rentLove'
        >
          LOVE 💘
        </button>
        <button
          style={
            responseData.cool
              ? {
                  marginLeft: '10px',
                  background: '#035aa6',
                  border: '1px solid #035aa6',
                  color: 'white',
                }
              : {
                  marginLeft: '10px',
                  background: 'white',
                  border: '1px solid #035aa6',
                  color: '#035aa6',
                }
          }
          className='btn rentCool'
          onClick={onClickCool}
        >
          COOL 😎
        </button>
        <button
          style={
            responseData.helpful
              ? {
                  marginLeft: '10px',
                  background: '#035aa6',
                  border: '1px solid #035aa6',
                  color: 'white',
                }
              : {
                  marginLeft: '10px',
                  background: 'white',
                  border: '1px solid #035aa6',
                  color: '#035aa6',
                }
          }
          className='btn rentHelpful'
          onClick={onClickHelpful}
        >
          HELPFUL 😁
        </button>
      </div>
    </div>
  )
}

export default RentDetailsResponse
