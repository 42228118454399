import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'

import AddStoryInput from '../components/BlogAddStory/AddStoryInput'
import AddStorySelect from '../components/BlogAddStory/AddStorySelect'
import AddStoryFilesInput from '../components/BlogAddStory/AddStoryFilesInput'
import NewsHead from '../components/Newsroom/NewsHead'
import NewsListItem from '../components/Newsroom/NewsListItem'
import NewsLoading from '../components/Newsroom/NewsLoading'
import AddStoryTextarea from '../components/BlogAddStory/AddStoryTextarea'
import { getCloudinarySignature } from '../Services/cloudinaryService'
import { useTranslation } from 'react-i18next'
import { uploadSingleFileToCloudinary } from '../Utils/imageUploadToCloudinary'
import { getCurrentUser } from '../Services/authService'
import { editNews, getNewsById, postNews } from '../Services/newsService'
import EditParagraphModal from '../components/BlogAddStory/EditParagraphModal'
import logger from '../Services/loggerService'

const NewsroomAddRelease = ({ history, match }) => {
  const [imageLoad, setImageLoad] = useState(false)
  const [allParagraph, setAllParagraphs] = useState([])
  const [myImageData, setMyImageData] = useState({})
  const [imageResult, setImageResult] = useState()
  const [imageTitle, setImageTitle] = useState('')
  const [addParagraph, setAddParagraph] = useState([
    {
      para: (
        <AddStoryTextarea
          onChange={(e) => setAllParagraphs([e.target.value])}
          num='1'
          value={allParagraph[0]}
        />
      ),
      id: 1,
    },
  ])
  const [nums, setNum] = useState(1)
  const [errorBlog, setErrorBlog] = useState('')
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [catergory, setCatergory] = useState('')
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(`0`)
  const [showLoader, setShowLoader] = useState(false)
  const [ID, setID] = useState()
  const [open, setOpen] = useState(true)
  const [theSelectedItem, setTheSelectedItem] = useState({})

  const catergories = [
    'Press Release',
    'Company info',
    'Awards & Recognition',
    "Nawafrica's Research",
    'Our Products',
  ]
  const [editParagraphs, setEditParagraphs] = useState([])

  const { t } = useTranslation()

  useEffect(() => {
    getData()
    window.scrollTo(0, 0)
  }, [])

  const getData = async () => {
    setID(match.params.id)
    try {
      const { data: imagesData } = await getCloudinarySignature()
      setMyImageData(imagesData)
      if (match.params.id) {
        const { data } = await getNewsById(match.params.id)
        setTheSelectedItem(data)
        setTitle(data.title)
        setSubTitle(data.subtitle)
        setCatergory(data.catergory)
        setImageResult(data.image)
        setImageTitle(data.image.original_filename)
        setEditParagraphs(data.paragraphs)
      }
    } catch (error) {
      logger.log(error)
    }
  }

  //function to upload the signed lease document to the backend of the added tenant
  const onChangeUSL = async (e) => {
    e.preventDefault()
    const filesss = e.currentTarget.files[0]
    if (filesss) {
      setImageTitle(filesss.name)
    }

    if (filesss && filesss.size > 8323863) {
      setErrorBlog(t('large_file,_upload'))
    } else {
      setImageLoad(true)
      const result = await uploadSingleFileToCloudinary(filesss, myImageData)
      setImageResult(result)
      setImageLoad(false)
    }
  }

  const addParagraphs = () => {
    let num = nums
    num = num + 1
    let paraObj = {
      id: num,
      para: (
        <AddStoryTextarea
          onChange={(e) => {
            setAllParagraphs([...allParagraph, e.target.value])
          }}
          num={num}
          // value={allParagraph[num - 1]}
        />
      ),
    }
    const para = [...addParagraph]

    para.push(paraObj)
    setAddParagraph(para)
    setNum(num)
  }

  const removeParagraphs = () => {
    if (addParagraph.length > 1) {
      const addPara = [...addParagraph]
      addPara.pop()
      const allPara = [...allParagraph]
      allPara.pop()

      let num = nums - 1
      setNum(num)

      setAddParagraph(addPara)
      setAllParagraphs(allPara)
    }
  }

  const postStory = async (e) => {
    e.preventDefault()
    if (allParagraph.length === 0) {
      setError('Paragraph is not supposed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    if (!title) {
      setError('Title is not allowed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    if (!subTitle) {
      setError('Sub title is not allowed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    if (!catergory) {
      setError('Catergory is not allowed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    if (!imageResult) {
      setError('Image is not allowed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    if (allParagraph.length === 0) {
      setLoading(false)
      setError('Paragraph is not allowed to be empty')
      setShowLoader(false)
      return
    }

    const data = {
      title,
      subTitle,
      catergory,
      image: imageResult,
      paragraphs: allParagraph,
      poster: getCurrentUser() && getCurrentUser().first_name,
    }
    try {
      setLoading(true)
      setShowLoader(true)
      if (ID) {
        data.createDate = theSelectedItem.create_date
        await editNews(data, ID, (p) => setProgress(`${p * 100}`))
      } else {
        await postNews(data, (p) => setProgress(`${p * 100}`))
      }

      setShowLoader(false)

      setLoading(false)
      setTitle('')
      setSubTitle('')
      setCatergory('')
      setImageResult()
      setAllParagraphs([''])
      setAllParagraphs([''])
      setAllParagraphs([])
      setImageTitle('')
      setNum(1)
      setAddParagraph([
        {
          para: (
            <AddStoryTextarea
              onChange={(e) => setAllParagraphs([e.target.value])}
              num='1'
            />
          ),
          id: 1,
        },
      ])

      setLoading(false)
      setProgress(`0`)
      history.push('/newsroom')
    } catch (ex) {
      setProgress('0')
      setError(ex.response.data)
    }
  }

  const onCloseModal = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  return (
    <>
      {ID && (
        <button onClick={onCloseModal} className='btn btn-light newsOpenModal'>
          <FontAwesomeIcon className='icon' icon={faDoorOpen} />
          open modal
        </button>
      )}

      {ID && open && (
        <EditParagraphModal
          onCloseModal={onCloseModal}
          content={editParagraphs}
        />
      )}
      {loading && <NewsLoading loadingProgress={progress} />}
      <NewsHead />
      <div className='newsAddOuterCon mobileAddNewsCon'>
        <NewsListItem add={true} />
        <div className='newsInputAddCon'>
          <h1>CREATE A MEDIA RESOURCE</h1>
          <form onSubmit={postStory}>
            <AddStoryInput
              label='Title'
              placeholder='Press release title'
              id='title'
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            <AddStoryInput
              label='Sub Title'
              placeholder='Press release sub-title'
              id='subtitle'
              onChange={(e) => setSubTitle(e.target.value)}
              value={subTitle}
            />
            <AddStorySelect
              label='Media Resource Catergory'
              value={catergory}
              onChange={(e) => setCatergory(e.target.value)}
              catergories={catergories}
            />
            <AddStoryFilesInput
              onChange={onChangeUSL}
              label='Press Release Image'
              id='storyImage'
              photoName={imageTitle}
              loading={imageLoad}
              error={errorBlog}
              uploadImage={imageResult}
            />
            {addParagraph.map((p) => (
              <div key={`${p.id}`} style={{ width: '100%' }}>
                {p.para}
              </div>
            ))}

            <p>
              <button className='btn newsroomAddBtnSubmit' type='submit'>
                Post A Press Release
              </button>

              {error && <p className='newsAddError'>{error}</p>}
            </p>
          </form>

          <button
            data-tip='Add a body paragraph'
            className='btn btn-light newsAddPlus'
            onClick={addParagraphs}
            type='reset'
          >
            <FontAwesomeIcon className='icon' icon={faPlus} />
          </button>
          {addParagraph.length > 1 && (
            <button
              data-tip='Remove a body paragraph'
              className='btn btn-light newsMinusBtn'
              type='reset'
              disabled={loading}
              onClick={removeParagraphs}
            >
              <FontAwesomeIcon className='icon' icon={faMinus} />
            </button>
          )}
          <ReactTooltip effect='solid' />
        </div>
        {/* <AddRelease
          imageTitle={imageTitle}
          errorBlog={errorBlog}
          imageResult={imageResult}
          catergory={catergory}
          error={error}
          imageLoad={imageLoad}
          removeParagraphs={removeParagraphs}
          addParagraphs={addParagraphs}
          onSubmit={postStory}
          loading={loading}
          valueTitle={title}
          valueSubTitle={subTitle}
          valueSelect={catergory}
        /> */}
      </div>
    </>
  )
}

export default NewsroomAddRelease
