import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWarehouse } from '@fortawesome/free-solid-svg-icons'

const DepartmentContainerOposite = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, [])
  return (
    <div className='crewDDepartmentOuterCon'>
      <div className='opCrewDepInnerCon'>
        <div className='crewDepImageCon' data-aos='fade-right'>
          <img
            style={{ width: '100%', height: '100%' }}
            src='../../images/housing.jpg'
          />
        </div>
        <div data-aos='fade-left' className='crewDepContent'>
          <FontAwesomeIcon className='crewDepHouseIcon' icon={faWarehouse} />
          <p style={{ textAlign: 'center' }}>
            <b className='title'>
              REAL ESTATE AND PROPERTY MANAGEMENT DEPARTMENT
            </b>
          </p>
          <p className='crewDepStatement'>
            Have you been mismanaged by someone else? We offer property
            management services that bring value to your property. Contact us
            today for more information on how we can help you manage and bring
            value to your property. Property management done right. Your
            property is important to us. Let us take care of it for you so that
            you can focus on what matters.Are you tired of being mismanaged? Our
            Real estate and property management department offers exclusive
            services tailored to each client's needs. Click here to learn more.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DepartmentContainerOposite
