import React from 'react'

const PoliciesCard = ({
  icon,
  firstTitle = 'Advertising',
  secondTitle = 'TechTarget, Terminus and Salesforce',
  description = 'Personal Data: Trackers; Usage Data',
}) => {
  return (
    <div style={{ marginTop: '30px', display: 'flex' }}>
      <div style={{ width: '10%' }}>{icon}</div>
      <div style={{ flex: '1', padding: '0 10px' }}>
        <h5 style={{ fontWeight: 'bolder', fontSize: '14px', opacity: '0.9' }}>
          {firstTitle}
        </h5>
        <div
          style={{
            fontWeight: '600',
            fontSize: '15px',
            opacity: '0.9',
            marginTop: '10px',
          }}
        >
          {secondTitle}
        </div>
        <div style={{ fontSize: '15px', opacity: '0.9' }}>{description}</div>
      </div>
    </div>
  )
}

export default PoliciesCard
