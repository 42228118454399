import React, { useEffect, useState, useContext } from "react";
import ScrollToTop from "react-scroll-to-top";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import HeaderSearch from "../components/Home/HeaderSearch";
import NumberOfListingsBar from "../components/Home/NumberOfListingsBar";
import DisplayCards from "../components/Home/DisplayCards";
import Pagination from "../components/Home/Pagination";
import Footer1 from "../components/Home/Footer1";
import ProfileSpinner from "../components/profile/ProfileSpinner";
import { getClientAllAvailableSpaces, getListings } from "../Services/listings";
import { paginate } from "../Utils/pagination";
import { Countries } from "../Utils/regions";
import { Regions } from "../Utils/regions";
import selectedCountryInputs from "../Utils/translateInput";
import UnreadMsgsContext from "./../unreadmessages/context";
import "../components/Home/styleCompo.css";
import { getCurrentUser } from "../Services/authService";
import { countries } from "../Utils/countries";
// import Meta from './../Utils/Meta'
import Meta1 from "../Utils/Meta1";
import PaginationMUI from "../components/Home/PaginationMUI";
import { getUsers } from "../Services/registerService";
import DisplayCardGrid from "../components/Home/DisplayCardGrid";
import formatArrayCount from "../Utils/convertArrayToKMB";

const LandForSell = ({ history }) => {
  const { t } = useTranslation();

  const [listings, setListings] = useState([]);
  const [loadingListings, setLoadingListings] = useState(false);
  const [error, setError] = useState("");

  const [pageSize, setPageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const [africaCountries, setAfricaCountries] = useState([]);
  const [selectedAfricaCountry, setSelectedAfricaCountry] = useState("");

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const [bedroom, setBedroom] = useState([]);
  const [selectedBedroom, setSelectedBedroom] = useState("");

  const [listing, setListing] = useState([]);
  const [selectedListing, setSelectedListing] = useState("");

  const [keyword, setKeyword] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);

  const [price, setPrice] = useState("");

  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState({
    loaded: false,
    error: "",
  });
  const [userLocation, setUserLocation] = useState({
    country: "",
    city: "",
  });

  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [langType, setLangType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const [users, setUsers] = useState([]);

  const [availableSpaces, setAvailableSpaces] = useState([]);

  const { unreadMsgs } = useContext(UnreadMsgsContext);

  const [title, setTitle] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllListings();
    localStorage.setItem("portalOrManagement", "");
  }, []);

  //function to get all datas for lands for sale
  const getAllListings = async () => {
    try {
      setLangType(cookies.get("i18next") || "en");
      setLoadingListings(true);
      const { data } = await getListings();
      const { data: spaces } = await getClientAllAvailableSpaces();
      setAvailableSpaces(spaces);
      const myData = data.filter(
        (d) => d.rent_or_sell === "Sale" && d.listing_type === "Land"
      );
      const publishData = myData.filter((d) => d.publish === "publish");
      const allUndeletedListing = publishData.filter(
        (listing) => listing.status !== "deleted"
      );
      // const allUndeletedListing = publishData.filter(
      //   (listing) => listing.status !== "deleted" && listing.reviewed === true
      // );
      const sortedListing = _.orderBy(
        allUndeletedListing,
        [sortColumn.path],
        [sortColumn.order]
      );
      // const sortedListing = _.orderBy(myData, [sortColumn.path], [sortColumn.order]);
      let sortedListing1 = [];

      for (let i = 0; i < sortedListing.length; i++) {
        let list = sortedListing[i];
        list.remainingSpace = spaces.filter(
          (space) => space.listing_id === list.id && space.empty_space == true
        ).length;
        sortedListing1.push(list);
      }

      setListings([
        ...sortedListing1.filter((listing) => listing.remainingSpace > 0),
        ...sortedListing1.filter((listing) => listing.remainingSpace === 0),
      ]);
      setLoadingListings(false);

      // fields involve to fill the select drop down in the search container
      let Africas = Countries();
      setAfricaCountries(Africas);

      const { data: userss } = await getUsers();
      setUsers(userss);

      const Bedrooms = [];
      setBedroom(Bedrooms);

      const Listingss = [];
      setListing(Listingss);

      const Keywords = [
        "Pool",
        "Flowers",
        "Garden",
        "Playground",
        "Gym",
        "Balcony",
        "Constant Water Supply",
        "Electricity",
        "Security",
        "Fence",
        "Internet",
        "Cable TV",
        "Parking",
        "Central Town",
        "Near School",
        "Near Hospital",
        "Near Market",
        "Near Police Station",
        "Near Bus Station",
        "Near Airport",
        "Near Beach",
        "Friendly Neighborhood",
        "Quiet Neighborhood",
        "Near Main Road",
      ];
      setKeyword(Keywords.sort());

      //code to get the user current location

      if (!("geolocation" in navigator)) {
        setLocation({
          ...location,
          loaded: true,
          error: {
            code: 0,
            message: "geolocation not supported",
          },
        });
      } else {
        navigator.geolocation.getCurrentPosition(onSuccess, onError, {
          enableHighAccuracy: true,
        });
      }
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 500) ||
        (ex.response && ex.response.status === 400)
      ) {
        setError("NO INTERNET");
      }
    }
  };

  //function to get the latitude and longitude
  const onSuccess = (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });

    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${
      location.coords.latitude
    },${
      location.coords.longitude
    }&key=${"AIzaSyA2CpB2hPku6Sivjnh8AItdex87_F46ovE"}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const myLocation = data.results[0].formatted_address;
        const locationArray = myLocation.split(",");
        const locationObj = {
          country: locationArray[locationArray.length - 1].trim(),
          city: selectedCountryInputs(
            locationArray[locationArray.length - 2].trim()
          ),
        };
        setUserLocation(locationObj);

        setSelectedAfricaCountry(
          locationArray[locationArray.length - 1].trim()
        );
        setSelectedCity(
          selectedCountryInputs(locationArray[locationArray.length - 2].trim())
        );
      })
      .catch((err) =>
        console.warn(
          "error from onSuccess function in LandForSell component",
          err.message
        )
      );
  };

  const onError = (error) => {
    setLocationError({
      loaded: true,
      error,
    });
    setLocation({
      loaded: false,
      coordinates: {
        lat: "",
        lng: "",
      },
    });
  };

  //function use for filtering and paginating listings
  const getPagedData = () => {
    let filtered = [...listings];

    if (selectedBedroom) {
      filtered = filtered.filter(
        (filter) => filter.bedrooms === selectedBedroom
      );
    }

    if (selectedListing) {
      filtered = filtered.filter(
        (filter) => filter.listing_type === selectedListing
      );
    }

    if (price) {
      filtered = filtered.filter(
        (filter) => Number(filter.price) >= Number(price.trim())
      );
    }

    if (selectedKeyword.length > 0) {
      filtered = filtered.filter((filter) =>
        filter.keyword.some((r) => selectedKeyword.includes(r))
      );
    }

    if (selectedCity) {
      filtered = filtered.filter(
        (filter) => filter.city.trim() === selectedCity.trim()
      );
    }

    if (selectedState) {
      filtered = filtered.filter(
        (filter) => filter.state.trim() === selectedState.trim()
      );
    }

    if (selectedAfricaCountry) {
      filtered = filtered.filter(
        (filter) => filter.country === selectedAfricaCountry
      );
    }

    if (title) {
      filtered = filtered.filter((filter) =>
        filter.title.toLowerCase().includes(title.toLowerCase())
      );
    }

    const items = paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, data: items };
  };

  //function that is call when a page is click
  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  //function to go to next page when presses the next button
  const nextPage = () => {
    let currentPages = currentPage;
    currentPages = currentPages + 1;
    setCurrentPage(currentPages);
  };

  //function to go to previous page when press the previous button

  const previousPage = () => {
    let count = currentPage;
    count = currentPage - 1;
    count = count <= 1 ? 1 : count;
    setCurrentPage(count);
  };

  const onHoverListing = (listing) => {
    setSelectedItem(listing);
  };

  const homeTitle = t("SEARCH_LANDS_FOR_SALE");

  const { totalCount, data: items } = getPagedData();
  if (loadingListings)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );
  if (error)
    return (
      <div className="homeSpinnerContainer">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  return (
    <div className="homeOuterContainer">
      <Meta1
        title={`${langType}.${t("Nawafrica | Lands_for_sale")}`}
        description="Buy Lands In Africa"
        keywords="Buy Land Africa For Sale"
        link="/buy-a-land"
      />

      <HeaderSearch
        title={title}
        onClickMessage={() =>
          getCurrentUser().post_rent_property === "I AM A PROPERTY OWNER"
            ? history.push("/portal/messages/message")
            : history.push("/portal/tenants/message")
        }
        numberOfUnreadMsgs={
          unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
        }
        homeTitle={homeTitle}
        latestItem={items.slice(0, 10)}
        itemsCountry={countries()}
        rentOrSell="Sale"
        onChangeCountry={(e, values) => {
          let state = Regions();

          let value = values ? values.label.trim() : "";

          if (value === "All Countries" || !value) {
            setSelectedCity("");

            setSelectedCurrency("");

            setSelectedState("");

            setSelectedListing("");

            setSelectedBedroom("");

            setPrice("");

            setSelectedKeyword([]);

            setSelectedAfricaCountry("");

            setCurrentPage(1);

            return;
          }

          if (
            selectedCity ||
            selectedCurrency ||
            selectedState ||
            selectedListing ||
            selectedBedroom ||
            price ||
            (selectedKeyword && !selectedAfricaCountry)
          ) {
            setSelectedCity("");

            setSelectedCurrency("");

            setSelectedState("");

            setSelectedListing("");

            setSelectedBedroom("");

            setPrice("");

            setSelectedKeyword([]);

            setCurrentPage(1);
          }

          setSelectedAfricaCountry(value);

          const statess =
            value !== ""
              ? state.filter((s) => s.country === value)[0].states
              : [];

          const Citiess =
            value !== ""
              ? state.filter((s) => s.country === value)[0].cities
              : [];

          let Currencys =
            value !== ""
              ? state.filter((s) => s.country === value)[0].currency
              : [];

          setStates(statess);

          setCities(Citiess);

          setCurrency(Currencys);
        }}
        itemsState={states}
        onChangeState={(e, values) => {
          let value = values ? values.trim() : "";
          if (value) {
            setCurrentPage(1);
            setSelectedState(value);
          } else {
            setCurrentPage(1);
            setSelectedState("");
          }
        }}
        itemsCity={cities}
        onChangeCity={(e, values) => {
          let value = values ? values.trim() : "";
          if (value) {
            setCurrentPage(1);
            setSelectedCity(value);
          } else {
            setCurrentPage(1);
            setSelectedCity("");
          }
        }}
        itemsBedroom={bedroom}
        onChangeBedroom={(e, values) => {
          let value = values ? Number(values) : "";
          if (value > 0) {
            setCurrentPage(1);
            setSelectedBedroom(`${value}`);
          } else {
            setCurrentPage(1);
            setSelectedBedroom("");
          }
        }}
        itemsListingType={listing}
        onChangeListingType={(e, values) => {
          let value = values ? values.trim() : "";
          if (value) {
            setCurrentPage(1);
            setSelectedListing(value);
          } else {
            setCurrentPage(1);
            setSelectedListing("");
          }
        }}
        itemsCurrency={currency}
        onChangeCurrency={(e) => {
          let value = e.currentTarget.value.trim();
          if (value) setCurrentPage(1);
          setSelectedCurrency(value);
        }}
        itemsKeyword={keyword}
        onChangeKeyword={(e, values) => {
          const {
            target: { value },
          } = e;
          setCurrentPage(1);
          setSelectedKeyword(
            typeof value === "string" ? value.split(",") : value
          );
        }}
        onChangePrice={(e) => {
          let value = e.currentTarget.value.trim();
          if (value) setCurrentPage(1);
          setPrice(value);
        }}
        onChangeListingTitle={(e) => {
          let value = e.currentTarget.value.trim();
          if (value) setCurrentPage(1);
          setTitle(value);
        }}
        valueCountry={selectedAfricaCountry}
        valueState={selectedState}
        valueCity={selectedCity}
        valueBedroom={selectedBedroom}
        valueListingType={"Land"}
        valueKeyword={selectedKeyword}
        valuePrice={price}
        valueTitle={title}
        selectedAfricaCountry={selectedAfricaCountry}
        selectedState={selectedState}
        selectedCity={selectedCity}
        selectedBedroom={selectedBedroom}
        selectedListing={"Land"}
        selectedKeyword={selectedKeyword}
        price={price}
      />
      <NumberOfListingsBar
        children={`${t("numberOfList_displayBar_Land", {
          totalCount: totalCount === 0 ? t("No") : formatArrayCount(items),
          selectedListing: selectedListing ? selectedListing : t("Listing"),
          selectedAfricaCountry: selectedAfricaCountry
            ? `${
                selectedCity && selectedCountryInputs(selectedCity) + ", "
              }${selectedCountryInputs(selectedAfricaCountry)}`
            : userLocation.city && selectedAfricaCountry
            ? `${userLocation.city}, ${userLocation.country}`
            : t("Nawafrica_Marketplace"),
          s: totalCount > 1 ? "s" : "",
        })} ${t("For_Sale")}`}
      />
      <DisplayCardGrid
        selectedItem={selectedItem}
        items={items}
        onHoverListing={onHoverListing}
        users={users}
        availableSpaces={availableSpaces}
      />
      {/* <Pagination
        itemsCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
        onPageChange={handlePageChange}
        nextPage={nextPage}
        previousPage={previousPage}
      /> */}
      <PaginationMUI
        count={Math.ceil(totalCount / pageSize)}
        onChange={handlePageChange}
      />
      <div className="homeHrContainer">
        <hr />
      </div>
      <Footer1 />
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </div>
  );
};

export default LandForSell;
