import React from 'react'
import ReactTooltip from 'react-tooltip'

import User from '../../iconComponent/User'
import './listingDetails.css'
import PriceTag from './PriceTag'

const TitlePrice = ({ verified = false, title, price, rentOrSell }) => {
  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`)

    if (myVal.includes(',')) {
      return val
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
      }
      return val
    }
  }

  return (
    <div className='listingDetailTitleCon'>
      <div>
        <span>
          {verified && <User data-tip='Verified' className='DetailsUserIcon' />}
        </span>
        <span className={'titleSpan'}>{title}</span>
      </div>
      <div className='priceMonthContainer'>
        <span style={{ borderRadius: '5px' }} className='pricePerMonthSpan'>
          <div
            style={{ position: 'absolute', right: '0px', marginTop: '22PX' }}
          >
            <PriceTag />
          </div>
          <span className='priceSpan'>{commaSeparateNumber(price)}</span>
          {rentOrSell === 'Rental' && <span className='monthSpan'>/mo</span>}
        </span>
      </div>
      <ReactTooltip effect='solid' />
    </div>
  )
}

export default TitlePrice
