import React, { useEffect } from 'react'
import CareerReactCarousel from './CareerReactCarousel'
import AOS from 'aos'
import 'aos/dist/aos.css'

const CareerBodyIntro = () => {
  useEffect(() => {
    AOS.init({ duration: 4000 })
  }, [])
  return (
    <div className='careerIntroCon'>
      <h3 data-aos='flip-up' style={{ textAlign: 'center', color: '#035aa6' }}>
        SKILLS AND CAREERS
      </h3>
      <p>
        {' '}
        <span style={{ marginLeft: '80px' }}>There</span> are some careers and
        skills that are golden to Captivator technologies inc. and nawafrica.com
        has created a wide range of opportunities for people who have have
        develope this skills and are looking for opportunities that can match
        the skills which he have developed
      </p>
      <CareerReactCarousel />
    </div>
  )
}

export default CareerBodyIntro
