import React from 'react'

import './manageTenants.css'

const LoadingSpinner = () => {
return (
<div class="spinner-border" role="status">
  <span class="sr-only">Loading...</span>
</div>
)
}

export default LoadingSpinner