import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClockRotateLeft,
  faTrashCan,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { getCurrentUser } from "../../Services/authService";

const RentalAndSalePlatformSale = ({ item, onClickDelete }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const getMonth = (month) => {
    if (month == "01") return "JAN";
    if (month == "02") return "FEB";
    if (month == "03") return "MAR";
    if (month == "04") return "APR";
    if (month == "05") return "MAY";
    if (month == "06") return "JUN";
    if (month == "07") return "JUL";
    if (month == "08") return "AUG";
    if (month == "09") return "SEPT";
    if (month == "10") return "OCT";
    if (month == "11") return "NOV";
    return "DEC";
  };
  return (
    <div style={{ width: "100%" }}>
      <div className="rentSaleDisplayContainer" data-aos="flip-down">
        <div className="imageContainer">
          <Link to={`/rent-and-sale-platform/${item.id}`}>
            <img
              crossorigin="anonymous"
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={item.image && item.image.url}
            />
          </Link>
        </div>
        <div className="rentSaleContentContainer">
          <h2>{item.title.slice(0, 90)}</h2>
          <p>{item && item.paragraphs[0].Para.slice(0, 150)}</p>
        </div>

        <>
          <div className="rentSaleFooter">
            <FontAwesomeIcon
              className="RentPlatformHeadIcon1"
              icon={faClockRotateLeft}
            />
            <div className="rentSaleDateCon">
              {`${item.create_date.toString().slice(8, 10)} ${getMonth(
                item.create_date.toString().slice(5, 7)
              )} ${item.create_date.toString().slice(0, 4)}`}
            </div>
            {getCurrentUser() && getCurrentUser().isadmin && (
              <>
                <span className="rentSaleScom">/</span>
                <FontAwesomeIcon
                  className="rentSaleIconDelete"
                  icon={faTrashCan}
                  onClick={onClickDelete}
                />

                <span className="rentSaleScom">/</span>
                <Link to={`/rent-and-sale-platform/publish/${item.id}`}>
                  <FontAwesomeIcon
                    className="rentSaleIconEdit"
                    icon={faPenToSquare}
                  />
                </Link>
              </>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default RentalAndSalePlatformSale;
