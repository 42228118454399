import React, { useEffect } from 'react'
import ScrollToTop from 'react-scroll-to-top'

import PoliciesMainHeading from '../components/Policies/PoliciesMainHeading'
import PolicyHr from '../components/Policies/PolicyHr'
import PoliciesSubHeading from '../components/Policies/PoliciesSubHeading'
import PoliciesIcons from '../components/Policies/PoliciesIcons'
import PoliceNextSubHeading from '../components/Policies/PoliceNextSubHeading'
import PolicieFurther from '../components/Policies/PolicieFurther'
import PolicyContact from '../components/Policies/PolicyContact'
import PolicyFooter from '../components/Policies/PolicyFooter'
import Meta1 from '../Utils/Meta1'

const Policies = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div
      style={{
        marginTop: '45px',
        padding: '50px 23%',
        fontFamily: "'Montserrat', sans-serif",
      }}
    >
      <Meta1
        title={`Nawafrica | Privacy Policies`}
        description='Nawafrica Privacy Policies'
        link='/policies'
      />
      <PoliciesMainHeading />
      <PolicyHr />
      <PoliciesSubHeading />
      <PoliciesIcons />
      <PolicyHr />
      <PoliceNextSubHeading />
      <PolicieFurther />
      <PolicyHr />
      <PolicyContact />
      <PolicyHr />
      <PolicyFooter />
      <ScrollToTop smooth />
    </div>
  )
}

export default Policies
