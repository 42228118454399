import _ from "lodash";

//pagination algorithm
export function paginate(items, pageNumber, pageSize) {
  const startIndex = (pageNumber - 1) * pageSize;
  return _(items)
          .slice(startIndex)
          .take(pageSize)
          .value();
          
}