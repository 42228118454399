import React from 'react'
import { useTranslation } from 'react-i18next'

import BackButton from '../components/MessageDetail/BackButton'
import Meta from './../Utils/Meta'
import '../components/Authentication/auth.css'

const LoginForgotPassMsg = () => {
  const { t } = useTranslation()

  return (
    <div style={{ width: '100%', marginTop: '70px', height: '220px' }}>
      <Meta title={t(`Nawafrica | check_your_email`)} />

      <BackButton back='/fogot_password' />

      <div
        style={{ width: '60%', margin: '0 auto', textAlign: 'center' }}
        className='alert alert-info'
      >
        <a
          style={{ textDecoration: 'none', color: '#035aa6' }}
          href='https://www.yahoo.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          <h6>{t('Check_Your_Email')}</h6>
        </a>
      </div>
      <div className='letMeGiveSomeSeparationOverAccountable'></div>
    </div>
  )
}

export default LoginForgotPassMsg
