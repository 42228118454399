import React, { useEffect, useState, useContext } from "react";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import _ from "lodash";
import io from "socket.io-client";
import { saveAs } from "file-saver";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { getLang } from "../Utils/getLang";

import {
  getMessageById,
  updateGivenMessageById,
  getAllMyUnreadMsgId,
  deleteGivenMessageById,
} from "./../Services/messageService";
import Chats from "../components/AddListing/Chats";
import BackButton from "../components/MessageDetail/BackButton";
import SidebarTenant from "./../components/AddListing/SideBarTenant";
import OpenButtonTenant from "./../components/AddListing/OpenButtonTenant";
import TopDeleteReply from "../components/MessageDetail/TopDeleteReply";
import MessageSubject from "../components/MessageDetail/MessageSubject";
import SenderDate from "../components/MessageDetail/SenderDate";
import Msg from "../components/MessageDetail/Msg";
import MessageImages from "../components/MessageDetail/MessageImages";
import BottomDeleteReply from "../components/MessageDetail/BottomDeleteReply";
import InboxModal from "../components/Messages/InboxModal";
import UnreadMessages from "../components/MessageDetail/UnreadMessages";
import Reply from "../components/MessageDetail/Reply";
import { getCurrentUser } from "../Services/authService";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import MessagePicModal from "../components/MessageDetail/MessagePicModal";
import translateLang from "../Utils/translationLang";
import UnreadMsgsContext from "./../unreadmessages/context";
import { uploadSingleFileToCloudinary } from "../Utils/imageUploadToCloudinary";
import { getCloudinarySignature } from "../Services/cloudinaryService";
import { trackEvent } from "../Utils/useGAEventTracker";
import "../components/MessageDetail/messageDetail.css";

import Meta1 from "../Utils/Meta1";

const MessageDetailsScreen = ({ match, history }) => {
  const { t } = useTranslation();

  const [close, setClose] = useState(true);
  const [path, setPath] = useState("");
  const [socket, setSocket] = useState();

  const [message, setMessage] = useState({});
  const [allSenderUnreadMsg, setAllSenderUnreadMsg] = useState([]);

  const [subjectReply, setSubjectReply] = useState("");
  const [messageReply, setMessageReply] = useState("");

  const [otherPhotoError, setOtherPhotoError] = useState("");
  const [loadOtherPhoto, setLoadOtherPhoto] = useState(false);
  const [otherPhotoPath, setOtherPhotoPath] = useState([]);
  const [submitError, setSubmitError] = useState("");

  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [loading, setLoading] = useState(true);

  const [selectedImage, setSelectedImage] = useState("");
  const [myImageData, setMyImageData] = useState({});

  const [lang, setLang] = useState("");
  const [subj, setSubj] = useState("");
  const [messageBody, setMessageBody] = useState("");
  const [YOUR_API_KEY, setYOUR_API_KEY] = useState();

  const [loadSendingMsg, setLoadSendingMsg] = useState(false);
  const [messageDone, setMessageDone] = useState(false);

  const { unreadMsgs, setUnreadMsgs } = useContext(UnreadMsgsContext);

  useEffect(() => {
    getMessageData();
    localStorage.setItem("portalPath", "/portal/tenants/message");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);

    const sock = io();
    setSocket(sock);

    sock.on(`${getCurrentUser() && getCurrentUser().id}unreadMsg`, (data) => {
      const sortedListing = _.orderBy(
        data,
        [sortColumn.path],
        [sortColumn.order]
      );
      setAllSenderUnreadMsg(sortedListing);
    });
    return () => {
      localStorage.setItem("portalPath", "");
    };
  }, [match?.params?.id]);

  //function to get all datas of a given message
  const getMessageData = async () => {
    try {
      setLoading(true);
      const message_id = match.params.id;
      await updateGivenMessageById(message_id);
      const { data: msg } = await getMessageById(message_id);
      setSubj(msg.subject);
      setSubjectReply(msg.subject);
      setMessageBody(msg.body);
      setMessage(msg);

      const { data: unread } = await getAllMyUnreadMsgId(msg.sender_id);
      setAllSenderUnreadMsg(unread);
      const { data: imagesData } = await getCloudinarySignature();
      setMyImageData(imagesData);
      setLoading(false);

      //function use to translation the description
      const currentLanguageCode = cookies.get("i18next") || "en";

      const initLang = currentLanguageCode;
      let fromLang = localStorage.getItem(`initialLang`);
      let toLang = initLang;
      let text = `${msg.body}`;

      const translationResult = translateLang(
        YOUR_API_KEY,
        text,
        fromLang,
        toLang
      );
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        history.push("/notFound");
        logger.log(
          "the error in getMessageData function in ManageMessageDet component",
          ex.response.data
        );
      }
    }
  };

  //function to close sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to open sidebars
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to Delete the message
  const onDelete = async () => {
    try {
      if (window.confirm(t("Are_you_sure_you!!!!"))) {
        await deleteGivenMessageById(message.id);
        trackEvent(
          `${
            getCurrentUser() && getCurrentUser().id
          } delete message in message details`,
          `message is ${message.id}`,
          "detlete message Btn"
        );
        window.location = "/portal/tenants/message";
      }
    } catch (error) {
      logger.log(error);
    }
  };

  //function to delete unread messages
  const onDeleteUnread = async (message) => {
    try {
      if (window.confirm(t("Are_you sure_you_want_unread"))) {
        const allMessages = [...allSenderUnreadMsg];
        const theRemainingMessage = allMessages.filter(
          (mess) => mess.id !== message.id
        );
        setAllSenderUnreadMsg(theRemainingMessage);
        trackEvent(
          `${
            getCurrentUser() && getCurrentUser().id
          } delete an unread message in message details`,
          `message is ${message.id}`,
          "delete unread message"
        );
        await deleteGivenMessageById(message.id);
      }
    } catch (error) {
      logger.log(error);
    }
  };

  //function to upload multiple photos when submitting a message
  const onChangeOtherPhoto = async (e) => {
    e.preventDefault();
    const filesss = e.currentTarget.files[0];
    if (!filesss) return;
    if (filesss.size > 8323863) {
      setOtherPhotoError(
        "large file, upload a smaller file not greater than 8MB"
      );
    } else {
      setLoadOtherPhoto(true);
      setOtherPhotoError("");

      if (filesss.type.startsWith("image/")) {
        let src = URL.createObjectURL(filesss);

        //convert to canvas
        const canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        let userImage = new Image();
        userImage.src = src;
        userImage.onload = () => {
          canvas.width = userImage.naturalWidth;
          canvas.height = userImage.naturalHeight;
          ctx.drawImage(userImage, 0, 0);

          canvas.toBlob(async (blob) => {
            const myImage = new File([blob], filesss.name, { type: blob.type });

            const result = await uploadSingleFileToCloudinary(
              myImage,
              myImageData
            );

            if (
              !result ||
              !result.url ||
              (result && result.path && result.path["error"])
            ) {
              setOtherPhotoError(
                "The was an error loading your image, please reload this image"
              );
              setLoadOtherPhoto(false);
              return;
            }

            setOtherPhotoPath([...otherPhotoPath, { ...result }]);
            setLoadOtherPhoto(false);
          }, "image/webp");
        };
      } else {
        const result = await uploadSingleFileToCloudinary(filesss, myImageData);
        setOtherPhotoPath([...otherPhotoPath, { ...result }]);
        setLoadOtherPhoto(false);
      }
    }
  };
  // const onChangeOtherPhoto=async (e)=>{
  //   e.preventDefault()
  //   const filesss=e.currentTarget.files[0]
  //   if(!filesss){
  //     setOtherPhotoError("file is not supposed to be empty")
  //   }else{
  //   _.forEach(e.target.files, file=>{
  //     if(file.size>2000683){
  //     return  setOtherPhotoError("large file, upload a smaller file not greater than 1.2MB")
  //     }
  //  })

  //   try {

  //     const formData = new FormData()
  //     _.forEach(e.target.files, file=>{
  //        formData.append(`files`, file )
  //     })

  //     const configs = {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     }
  //     setOtherPhotoError("")
  //     setLoadOtherPhoto(true)
  //     const { data:otherPhoto } = await axios.post(`/uploads/others`, formData, configs)
  //     setLoadOtherPhoto(false)
  //     if(otherPhoto){
  //       setOtherPhotoPath([...otherPhoto, ...otherPhotoPath])
  //     }

  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 400) {
  //       setLoadOtherPhoto(false)
  //      setOtherPhotoError(ex.response.data)
  //     }
  //   }
  // }
  // }

  //function to attach emoji to message
  const onEmojiClick = (event, emojiObject) => {
    const myEmojiMessage = messageReply + emojiObject.emoji;
    setMessageReply(myEmojiMessage);
  };

  //function to submit replied message
  const onSubmitMessage = (e) => {
    e.preventDefault();
    const myData = {
      message: messageReply,
      subject: subjectReply,
      recipient_id: message.sender_id,
      recipient_name: message.sender_name,
      images: otherPhotoPath,
      listing_id: message.listing_id,
      listing_name: message.listing_name,
      listing_address: message.listing_address,
      sender: getCurrentUser().id,
      reply: {
        subject: message.subject,
        body: message.body,
      },
    };
    setLoadSendingMsg(true);
    socket.emit("messageTenant", myData, (error) => {
      if (error) {
        setSubmitError(error);
        setLoadSendingMsg(false);
      } else {
        // alert(t('MESSAGE_SENT'))
        trackEvent(
          `${getCurrentUser() && getCurrentUser().id} tenant send a message`,
          myData.message,
          "tenat send message btn"
        );
        const myVideoToPlay = document.getElementById("myVideoPlay");
        myVideoToPlay.play();
        setLoadSendingMsg(false);
        setMessageDone(true);
        setInterval(() => {
          setMessageDone(false);
        }, 5000);
      }
    });

    if (messageReply && subjectReply) {
      setMessageReply("");
      setOtherPhotoPath([]);
      setSubmitError("");
      const myVideoToPlay = document.getElementById("myVideoPlay");
      myVideoToPlay.play();
    }
  };

  //function to download send images and files into your device
  const onDownload = async (urls) => {
    const URL = urls.slice(15, urls.length);
    trackEvent(
      `${getCurrentUser() && getCurrentUser().id} download message image`
    );
    await axios
      .get(`/messages/download/${URL}`, { responseType: "blob" })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, `${URL}`);
      })
      .catch((err) => {
        logger.log(
          "this is the error from onDownload function in ManageDetailsScreen",
          err
        );
      });
  };

  //function to enlarge a given message
  const showLargeImage = async (url) => {
    setSelectedImage(url);
  };

  //function to delete a given file you don't when to send to the landlord when replying a message
  const ondeletePicture = (pic) => {
    const images = [...otherPhotoPath];
    const myImages = images.filter((img) => img !== pic);
    setOtherPhotoPath(myImages);
  };

  const onChangeLang = (lang) => {
    const currentLanguageCode = cookies.get("i18next") || "en";

    let fromLang = currentLanguageCode;
    let toLang = lang;
    let text = `${message.subject}`;

    const API_KEY = [YOUR_API_KEY];

    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += "&q=" + encodeURI(text);
    url += `&source=${fromLang}`;
    url += `&target=${toLang}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        logger.log(response);
        //     setSubj(response)
      })
      .catch((error) => {
        logger.log(error);
      });

    setLang(toLang);

    onchangeMessageLang(toLang);
  };

  const onchangeMessageLang = (toLangs) => {
    const currentLanguageCode = cookies.get("i18next") || "en";

    let fromLang = currentLanguageCode;
    let toLang = toLangs;
    let text = `${messageBody}`;

    const API_KEY = [YOUR_API_KEY];

    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += "&q=" + encodeURI(text);
    url += `&source=${fromLang}`;
    url += `&target=${toLang}`;

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        logger.log(response);
        //     setMessageBody(response)
      })
      .catch((error) => {
        logger.log(error);
      });

    setLang(toLang);
  };

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
      </div>
    );
  return (
    <>
      <div>
        <audio
          style={{ display: "none" }}
          id="myVideoPlay"
          src="/images/sms2.mp3"
        ></audio>
      </div>

      <Meta1
        title={`${getLang()}.Nawafrica | ${message.subject}`}
        description={`Message Details for ${message.subject} In Nawafrica`}
        link={`/portal/tenants/message/${message.id}`}
      />

      {close && (
        <SidebarTenant
          onClickCloseBar={onClickCloseBar}
          path={path}
          chas={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButtonTenant
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <MessagePicModal url={selectedImage} />

      <InboxModal
        messageDone={messageDone}
        loadSendingMsg={loadSendingMsg}
        onChangeSubject={(e) => {
          const { value } = e.target;
          setSubjectReply(value);
        }}
        onChangeMessage={(e) => {
          const { value } = e.target;
          setMessageReply(value);
        }}
        onChangeImages={onChangeOtherPhoto}
        otherPhotoPath={otherPhotoPath}
        onEmojiClick={onEmojiClick}
        loadOtherPhoto={loadOtherPhoto}
        message={messageReply}
        onSubmitMessage={onSubmitMessage}
        valueSubject={subjectReply}
        submitError={submitError}
        ondeletePicture={ondeletePicture}
      />

      <div className="backBtnChatsContainerMessageDet">
        <BackButton />
        <TopDeleteReply onDelete={onDelete} />

        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      </div>

      <div className="outerContainerSubjectBodyMessageDetail">
        <div
          className={
            close
              ? "innerContainerSubjectBodyMessageDetCon"
              : "innerContainerSubjectBodyMessageDetCon1"
          }
        >
          <MessageSubject
            onChangeLang={onChangeLang}
            subject={subj}
            lang={lang}
            close={close}
          />

          <div className="sendingDateOuterConMessageDet">
            <SenderDate message={message} />
          </div>

          <div className="messageBodyOuterConMessageDet">
            <Msg message={messageBody} />
          </div>

          {message.images && message.images.length > 0 ? (
            <div className="allSentImageOuterConMessageDet">
              {message.images.map((img) => (
                <React.Fragment key={img.public_id}>
                  <MessageImages
                    img={img}
                    onDownload={onDownload}
                    showLargeImage={showLargeImage}
                  />
                </React.Fragment>
              ))}
              <ReactTooltip effect="solid" />
            </div>
          ) : (
            ""
          )}

          <div style={{ width: "100%" }}>
            <BottomDeleteReply onDelete={onDelete} />
          </div>

          <div style={{ width: "100%" }}>
            <hr
              style={{ width: "100%", background: "black", height: "0.02px" }}
            />
          </div>

          {message.reply && <Reply message={message} />}
          <ReactTooltip effect="solid" />
        </div>
      </div>

      {allSenderUnreadMsg.length > 0 && (
        <UnreadMessages
          message={message}
          senderUnreadMsg={allSenderUnreadMsg}
          onDeleteUnread={onDeleteUnread}
          close={close}
        />
      )}
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default MessageDetailsScreen;
