import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import Meta from '../Utils/Meta'
import HrContent from "../components/Authentication/HrContent";
import GoogleFbLink from "../components/Authentication/GoogleFbLink";
import Spinner from "../components/Authentication/Spinner";
import { forgotYourPassword } from "../Services/registerService";
import "../components/Authentication/auth.css";
import "../components/Authentication/auth.css";
import Meta1 from "../Utils/Meta1";
import { Card } from "@mui/material";

const LoginForgotPassword = ({ history }) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);

  //function to submit the email when forgot password
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setErrors("");
      await forgotYourPassword({ Email: email });
      setLoading(false);
      history.push("/go-to-email");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrors(ex.response.data);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Meta1
        title={t(`Nawafrica | forgot_password`)}
        description="Forgot My Nawafrica Password"
        link="/fogot_password"
      />

      <Card
        variant="outlined"
        style={{ overflow: "hidden" }}
        className="loginOuterCon"
      >
        {loading && (
          <p className="loginLoadingContainer">
            <Spinner />
          </p>
        )}
        {errors && (
          <div className="alert alert-danger loginErrorContainer">{errors}</div>
        )}
        <div>
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            <b className="title">{t("Forgot_Password")}</b>
          </p>

          <hr className="headerSeparator"></hr>
        </div>
        <div style={{ textAlign: "center" }} className="form-group">
          <form onSubmit={onSubmit}>
            <label htmlFor="exampleFormControlFile1">
              <b>{t("Email_Address")}</b>
            </label>
            <input
              placeholder={t("Email_Address_ph")}
              className="form-control"
              id="exampleFormControlFile1"
              onChange={(e) => {
                e.preventDefault();
                const { value } = e.currentTarget;
                setEmail(value.toLowerCase());
              }}
              autoComplete="off"
            />

            <p style={{ textAlign: "center" }}>
              <button
                style={{ cursor: "pointer", outline: "none" }}
                type="submit"
              >
                {t("Submit")}
              </button>
            </p>
          </form>
          <div className="loginFooter">
            <HrContent />

            <GoogleFbLink />
          </div>
          <div style={{ marginTop: "40px" }} className="loginNoAccount">
            <p style={{ flex: "1" }} className="noAccount">
              {t("You_don't_have_an_account_?")}{" "}
              <Link to="/signUp">{t("SIGN_UP")}</Link>
            </p>
          </div>
        </div>
      </Card>
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default LoginForgotPassword;
