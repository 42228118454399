import React, { useState, useEffect } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Meta from './../Utils/Meta'
import { getLang } from '../Utils/getLang'

import '../components/Authentication/auth.css'

const SignupWithFacebook1 = ({ history }) => {
  const { t } = useTranslation()
  const [landlordOrTenant, setLandlordOrTenant] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    return () => {
      setLandlordOrTenant('')
    }
  }, [])

  //function to know the role of the user when signing up with facebook and store in local storage
  const onSave = () => {
    localStorage.setItem('facebook-signup-role', landlordOrTenant)
    history.push('/facebook_signUp2')
  }

  return (
    <div
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundImage: 'url(/images/googleBackground1.jpg)',
        width: '100%',
        height: '700px',
      }}
    >
      <Meta title={`${getLang()}.${t(`Nawafrica | Role_facebook_signup`)}`} />

      <Link
        style={{ marginTop: '70px', marginLeft: '5px' }}
        to='/signUp'
        className='btn btn-light'
      >
        <FaArrowLeft style={{ marginRight: '5px' }} />
        {t('go_back')}
      </Link>

      <div className='googleAndFacebookOuterContainer1'>
        <div style={{ margin: '0 auto' }}>
          <p
            style={{
              font: 'normal normal bold 18px/28px Merriweather',
              textAlign: 'center',
            }}
          >
            {t('buy_or_rent_prop')}
          </p>
        </div>
        <div style={{ margin: '0 auto' }}>
          <select
            className='form-control'
            style={{
              cursor: 'pointer',
              padding: '5px',
              font: 'normal normal normal 16px/28px Merriweather',
            }}
            onChange={(e) => {
              e.preventDefault()
              setLandlordOrTenant(e.target.value)
            }}
          >
            <option value=''>{t('What_is_your_role_?')}</option>
            <option value='I AM A PROPERTY OWNER'>
              {' '}
              {t('I_AM_A_PROPERTY_OWNER')}
            </option>
            <option value='LOOKING TO BUY/RENT PROPERTY'>
              {t('LOOKING_TO_BUY/RENT_PROPERTY')}
            </option>
          </select>
        </div>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '20px' }}>
          <button
            onClick={onSave}
            style={{
              padding: '2px 20px',
              borderRadius: '10px',
              background: '#035aa6',
              border: '1px solid #035aa6',
              color: 'white',
              cursor: 'pointer',
              font: 'normal normal bold 16px/28px Merriweather',
            }}
          >
            {t('Save')}
          </button>
        </div>
      </div>
      <div className='letMeGiveSomeSeparationOverAccountable'></div>
    </div>
  )
}

export default SignupWithFacebook1
