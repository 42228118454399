import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUmbrella } from "@fortawesome/free-solid-svg-icons";

import "./RentAndSalePlatform.css";

const RentPlatformHeader = () => {
  return (
    <div
      style={{ backgroundImage: "url(../../images/platform.jpeg)" }}
      className="rentPlatformHeadCon"
    >
      <div className="downArrowCon">
        <div className="scroll-arrow">
          <svg width="30px" height="20px">
            <path
              stroke="#ffffff"
              fill="none"
              strokeWidth="2px"
              d="M2.000,5.000 L15.000,18.000 L28.000,5.000 "
            ></path>
          </svg>
        </div>
      </div>
      <div className="headerTitleRentPlatform">
        <FontAwesomeIcon className="RentPlatformHeadIcon" icon={faUmbrella} />
        <h4>RENTAL AND SALE PLATFORM</h4>
        <p>
          Nawafrica Offer a Platform for Property Owner To display, rent, sell
          and manage their property
        </p>
      </div>
    </div>
  );
};

export default RentPlatformHeader;
