import React from "react";

const TopHeadingImage = () => {
  return (
    <div className="aboutTopHeadImageCon">
      <div className="abouttopHeadImageinnerCon">
        <div className="aboutTopHeadImageContent">
          <h1>
            The Real Estate Platform To Search, Buy, Sell, Rent And Manage
            Properties In Africa
          </h1>
        </div>
      </div>
      <img
        style={{ width: "100%", height: "100%" }}
        src="../../images/aboutUsPic.jpeg"
      />
    </div>
  );
};

export default TopHeadingImage;
