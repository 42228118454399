import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import io from "socket.io-client";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import Chats from "../components/AddListing/Chats";
import OpenButton from "../components/AddListing/OpenButton";
import Sidebar from "../components/AddListing/Sidebar";
import WelcomeUser from "../components/AddListing/WelcomeUser";
import SearchMessage from "../components/Messages/SearchMessage";
import MessagesTable from "../components/Messages/MessagesTable";
import MessageTitle from "../components/Messages/MessageTitle";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import ComposeMessageModal from "../components/Messages/ComposeMessageModal";

import { getApplications } from "../Services/listings";
import { getCurrentUser } from "./../Services/authService";
import {
  getRecipentMessages,
  deleteGivenMessageById,
} from "./../Services/messageService";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import UnreadMsgsContext from "./../unreadmessages/context";
import { uploadSingleFileToCloudinary } from "../Utils/imageUploadToCloudinary";
import { getCloudinarySignature } from "../Services/cloudinaryService";
// import Meta from './../Utils/Meta'
import { trackEvent } from "../Utils/useGAEventTracker";
import "../components/Messages/messages.css";
import Meta1 from "../Utils/Meta1";
import { myDateToSort } from "../Utils/currentDate";
import MessagesTableMUI from "../components/Messages/MessagesTableMUI";

const MessagesScreen = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(true);
  const [path, setPath] = useState("");
  const [upSideBar, setUpSideBar] = useState(false);

  const [applications, setApplications] = useState([]);
  const [applicationConvert, setApplicationConvert] = useState([]);
  const [sortColumns, setSortColumns] = useState({
    path: "appicant_name",
    order: "asc",
  });
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [otherPhotoError, setOtherPhotoError] = useState("");
  const [loadOtherPhoto, setLoadOtherPhoto] = useState(false);

  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [selectedApplication, setSelectedApplication] = useState();
  const [otherPhotoPath, setOtherPhotoPath] = useState([]);
  const [messages, setMessages] = useState([]);
  const [submitError, setSubmitError] = useState("");

  const [socket, setSocket] = useState();
  const [loadingMessage, setLoadingMessage] = useState(true);
  const [loadingSearchMessage, setLoadingSearchMessage] = useState(false);
  const [myImageData, setMyImageData] = useState({});

  const [langType, setLangType] = useState("");

  const [loadSendingMsg, setLoadSendingMsg] = useState(false);
  const [messageDone, setMessageDone] = useState(false);

  const { unreadMsgs, setUnreadMsgs } = useContext(UnreadMsgsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("portalPath", "/portal/messages/message");
    let myPath = localStorage.getItem("portalPath");
    setPath(myPath);

    const sock = io();
    setSocket(sock);
    sock.on(
      `${getCurrentUser() && getCurrentUser().id}messagesTenant`,
      (data) => {
        const sortedListing = _.orderBy(
          data,
          [sortColumn.path],
          [sortColumn.order]
        );
        setMessages(sortedListing);
      }
    );

    getAllMyDatas();
    return () => {
      localStorage.setItem("portalPath", "");
    };
  }, []);

  //function to get all messages sent to a particular landlord
  const getAllMyDatas = async () => {
    try {
      setLangType(cookies.get("i18next") || "en");
      setLoadingMessage(true);
      const { data } = await getApplications();
      const myAppl = data.filter(
        (d) => d.list_owner_id === getCurrentUser().id
      );
      const sortedListing = _.orderBy(
        myAppl,
        [sortColumns.path],
        [sortColumns.order]
      );
      setApplications(sortedListing);

      let applConvert = [];

      for (let i = 0; i < sortedListing.length; i++) {
        let objectConvert = {
          id: sortedListing[i].id,
          data:
            getCurrentUser().post_rent_property === "I AM A PROPERTY OWNER"
              ? `${sortedListing[i].appicant_name} @ ${sortedListing[i].listing_name}`
              : `${sortedListing[i].list_owner_name} @ ${sortedListing[i].listing_name}`,
        };
        applConvert.push(objectConvert);
      }

      setApplicationConvert(applConvert);

      const { data: messages } = await getRecipentMessages(
        getCurrentUser() && getCurrentUser().id
      );
      const sortedMessages = _.orderBy(
        messages,
        [sortColumn.path],
        [sortColumn.order]
      );
      setMessages(sortedMessages);

      const { data: imagesData } = await getCloudinarySignature();
      setMyImageData(imagesData);

      setLoadingMessage(false);
    } catch (error) {
      logger.log("error in getAllMyDatas function in MessagesScreen", error);
    }
  };

  //function to open the side bar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to close the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to upload multiple photos when writing the tenant
  const onChangeOtherPhoto = async (e) => {
    e.preventDefault();
    const filesss = e.currentTarget.files[0];
    if (!filesss) return;
    if (filesss.size > 8323863) {
      setOtherPhotoError(
        "large file, upload a smaller file not greater than 8MB"
      );
    } else {
      setLoadOtherPhoto(true);
      setOtherPhotoError("");

      if (filesss.type.startsWith("image/")) {
        let src = URL.createObjectURL(filesss);

        //convert to canvas
        const canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");

        let userImage = new Image();
        userImage.src = src;
        userImage.onload = () => {
          canvas.width = userImage.naturalWidth;
          canvas.height = userImage.naturalHeight;
          ctx.drawImage(userImage, 0, 0);

          canvas.toBlob(async (blob) => {
            const myImage = new File([blob], filesss.name, { type: blob.type });

            const result = await uploadSingleFileToCloudinary(
              myImage,
              myImageData
            );

            if (
              !result ||
              !result.url ||
              (result && result.path && result.path["error"])
            ) {
              setOtherPhotoError(
                "The was an error loading your image, please reload this image"
              );
              setLoadOtherPhoto(false);
              return;
            }

            setOtherPhotoPath([...otherPhotoPath, { ...result }]);
            setLoadOtherPhoto(false);
          }, "image/webp");
        };
      } else {
        const result = await uploadSingleFileToCloudinary(filesss, myImageData);
        setOtherPhotoPath([...otherPhotoPath, { ...result }]);
        setLoadOtherPhoto(false);
      }
    }
  };
  // const onChangeOtherPhoto=async (e)=>{
  //   logger.log("all my photos here");
  //   e.preventDefault()
  //   const filesss=e.currentTarget.files[0]

  //   if(!filesss){

  //     setOtherPhotoError("file is not supposed to be empty")

  //   }else{

  //   _.forEach(e.target.files, file=>{
  //     if(file.size>2000683){
  //     return  setOtherPhotoError("large file, upload a smaller file not greater than 1.2MB")
  //     }
  //  })

  //   try {

  //     const formData = new FormData()

  //     _.forEach(e.target.files, file=>{
  //        formData.append(`files`, file )
  //     })

  //     const configs = {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     }
  //     setOtherPhotoError("")
  //     setLoadOtherPhoto(true)
  //     const { data:otherPhoto } = await axios.post(`/uploads/others`, formData, configs)
  //     setLoadOtherPhoto(false)
  //     if(otherPhoto){
  //       setOtherPhotoPath([...otherPhoto, ...otherPhotoPath])
  //     }

  //   } catch (ex) {
  //     if (ex.response && ex.response.status === 400) {
  //       setLoadOtherPhoto(false)

  //      setOtherPhotoError(ex.response.data)

  //     }

  //   }
  // }
  // }

  //function to attach emoji to message
  const onEmojiClick = (event, emojiObject) => {
    const myEmojiMessage = message + emojiObject.emoji;
    setMessage(myEmojiMessage);
  };

  //function to submit the compose message to the tenant
  const onSubmitMessage = (e) => {
    e.preventDefault();
    const myData = {
      message,
      subject,
      selectedApplication: `${selectedApplication}`,
      images: otherPhotoPath,
      sender: getCurrentUser().id,
    };
    setLoadSendingMsg(true);

    socket.emit("message", myData, (error) => {
      if (error) {
        setSubmitError(error);
        setLoadSendingMsg(false);
      } else {
        trackEvent(
          `${getCurrentUser() && getCurrentUser().first_name} send a message`,
          myData.message,
          "sent message Btn"
        );
        const myVideoToPlay = document.getElementById("myVideoPlay");
        myVideoToPlay.play();
        setLoadSendingMsg(false);
        setMessageDone(true);
        setInterval(() => {
          setMessageDone(false);
        }, 5000);
      }
    });

    if (message && selectedApplication && subject) {
      setMessage("");
      setSelectedApplication("");
      setOtherPhotoPath([]);
      setSubject("");
      setSubmitError("");
    }
  };

  //function to delete a given message
  const onDelete = async (message) => {
    try {
      if (window.confirm(t("Are_you_sure_you!!!!"))) {
        const allMessages = [...messages];
        const theRemainingMessage = allMessages.filter(
          (mess) => mess.id !== message.id
        );
        setMessages(theRemainingMessage);
        trackEvent(
          `${getCurrentUser() && getCurrentUser().id} sent message`,
          message.body,
          "delete message btn"
        );
        await deleteGivenMessageById(message.id);
      }
    } catch (error) {
      logger.log("error in onDelete function in MessagesScreen", error);
    }
  };

  //sorting the of listings
  const onSort = (e) => {
    e.preventDefault();
    const isListings = [...messages];
    const { value } = e.currentTarget;
    const column = { ...sortColumns };
    column.path = value;
    const sortedListing = _.orderBy(isListings, [column.path], [column.order]);
    setMessages(sortedListing);
  };

  //function to search a listing
  const onSearchSender = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTable(value, messages);
      setMessages(tableData);
      // setFromSearch(true)
    } else {
      try {
        setLoadingSearchMessage(true);
        const { data: messages } = await getRecipentMessages(
          getCurrentUser() && getCurrentUser().id
        );
        const sortedMessages = _.orderBy(
          messages,
          [sortColumn.path],
          [sortColumn.order]
        );
        setMessages(sortedMessages);
        setLoadingSearchMessage(false);
      } catch (error) {
        logger.log("error in onSearchSender function in MessagesScreen", error);
      }
    }
  };

  const searchTable = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].sender_name.toLowerCase();

      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //function to delete a given file you dont to send to tenant
  const ondeletePicture = (pic) => {
    const images = [...otherPhotoPath];
    const myImages = images.filter((img) => img !== pic);
    setOtherPhotoPath(myImages);
  };

  //function use to capitalise the first letters of a word
  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  //function to search a sender of the message using Contact Date
  const onSearchContactDate = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableCD(value, messages);
      setMessages(tableData);
      // setFromSearch(true)
    } else {
      try {
        setLoadingSearchMessage(true);
        const { data: messages } = await getRecipentMessages(
          getCurrentUser() && getCurrentUser().id
        );
        const sortedMessages = _.orderBy(
          messages,
          [sortColumn.path],
          [sortColumn.order]
        );
        setMessages(sortedMessages);
        setLoadingSearchMessage(false);
      } catch (error) {
        logger.log(
          "error for onSearchSender function in MessagesTenant Screen",
          error
        );
      }
    }
  };

  const searchTableCD = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].create_date.toLowerCase();
      const myTitle = myDateToSort(title);
      if (myTitle.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  if (loadingMessage)
    return (
      <div className="homeSpinnerContainer">
        {/* <ProfileSpinner /> */}
        <ProfileSpinner />
      </div>
    );
  return (
    <>
      <div>
        <audio
          style={{ display: "none" }}
          id="myVideoPlay"
          src="/images/sms2.mp3"
        ></audio>
      </div>

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      <Meta1
        title={`${langType}.${t("meta_message", {
          name: getCurrentUser().first_name,
        })}`}
        description="Lands Nawafrica Messages"
        link="/portal/messages/message"
      />

      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          path={path}
          onTakeBarUp={onTakeBarUp}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <div className="messagesScreenOuterCon">
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        {!loadingMessage && (
          <ComposeMessageModal
            applicationConvert={applicationConvert}
            open={open}
            handleClose={handleClose}
            messageDone={messageDone}
            loadSendingMsg={loadSendingMsg}
            applications={applications}
            onChangeApplicationSelect={(e, values) => {
              if (values) {
                setSelectedApplication(values.id);
              } else {
                setSelectedApplication(null);
              }
            }}
            onChangeSubject={(e) => {
              const { value } = e.target;
              setSubject(value);
            }}
            onChangeMessage={(e) => {
              const { value } = e.target;
              setMessage(value);
            }}
            onChangeImages={onChangeOtherPhoto}
            otherPhotoPath={otherPhotoPath}
            onEmojiClick={onEmojiClick}
            loadOtherPhoto={loadOtherPhoto}
            message={message}
            onSubmitMessage={onSubmitMessage}
            valueApplicant={selectedApplication}
            valueSubject={subject}
            submitError={submitError}
            ondeletePicture={ondeletePicture}
          />
        )}

        <WelcomeUser user={capitalize(getCurrentUser().first_name)} />

        <MessageTitle
          messages={
            messages.length > 999
              ? `${message.length / 1000}k`
              : messages.length
          }
        />

        <div className={close ? "messagesInnerCon" : "messagesInnerCon1"}>
          <SearchMessage
            onChangeSearch={onSearchSender}
            onChangeSort={onSort}
            onChangeContactDate={onSearchContactDate}
            handleClickOpen={handleClickOpen}
          />

          {messages.length > 0 ? (
            // <MessagesTableMUI
            //   messages={messages}
            //   onDelete={onDelete}
            //   loadingSearchMessage={loadingSearchMessage}
            //   setVisibleRowss={(data)=>setMessages(data)}
            // />
            <MessagesTable
              messages={messages}
              onDelete={onDelete}
              loadingSearchMessage={loadingSearchMessage}
              onClickMessage={(id) => {
                let msg = [...unreadMsgs];
                let remain = msg.filter((m) => m.id !== id);
                setUnreadMsgs(remain);
              }}
            />
          ) : loadingSearchMessage ? (
            <div>{t(".....loading")}</div>
          ) : (
            <div className="noListingAvailableYetCon">
              <div className="alert">{t("No_Message_Available_Yet")}</div>
            </div>
          )}
        </div>
      </div>
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default MessagesScreen;
