import React from "react";
import { useTranslation } from "react-i18next";

import "./manageTenants.css";

const SearchManagement = ({ onChangeSearch, onChangeSort }) => {
  const { t } = useTranslation();
  return (
    <div className="listingSearchCon">
      <div className="addlistCons">
        <a className="searchButtonOhh" href="/management/add_listing">
          {t("Add_Listing")}
        </a>
      </div>

      <div className="inputFieldCon">
        <input
          className="myListingSearchInput"
          onChange={onChangeSearch}
          placeholder={t("Search_your_listings")}
        />
      </div>

      <div className="listingSortDropdown">
        <p>
          <span className="sortedBySpan">{t("Sort_By")}</span>
          <span className="listingSortSelectSpan">
            <select onChange={onChangeSort}>
              <option value="dateposted">{t("Date")}</option>
              <option value="title">{t("Title")}</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  );
};

export default SearchManagement;
