import React from 'react'

const ImageText = ({ icon, title, text }) => {
  return (
    <div className='iconMessageCardCon'>
      <div className='innerCon'>
        <div className='iconContainer'>{icon}</div>
      </div>
      <div className='content'>
        <h4>{title}</h4>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default ImageText
