import React from 'react'

const OwnershipContain = () => {
  return (
    <div className='aboutUsOwnerShipContent'>
      <p>
        <span className='firstSpan'>Nawafrica.com </span>
        was founded in 2021 and is owned by Captivator technologies inc, base in
        Limbe, southwest region of Cameroon.The CEO/ founder of Captivator
        technologies inc. is Ayamba Leonard Ndifon who is a Cyber security
        expert and a software engineer base the United States of America.
      </p>
      <p>
        <span className='firstSpan'>
          <a
            style={{
              textDecoration: 'none',
              cursor: 'pointer',
              color: '#035aa6',
            }}
            href='https://www.captivator.org'
            target='_blank'
            rel='noopener noreferrer'
          >
            Captivator technologies inc.
          </a>
        </span>{' '}
        is one of the fastest growing technogy company in Africa, that is highly
        driven with the motiavtion to use technology to make life better and
        easy for Africans in various sectors. Captivator technology inc. consist
        of various sectors, some of which include Technological Sector, the Real
        Estate sector, the Educational Sector etc
      </p>
    </div>
  )
}

export default OwnershipContain
