import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import logger from "../src/Services/loggerService";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { HelmetProvider } from "react-helmet-async";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import * as serviceWorker from "./sericeWorker.js";

import App from "./App";

logger.init();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "fr", "ke", "sa"],
    fallbackLng: "en",
    detection: {
      order: ["path", "cookie", "htmlTag", "localStorage", "subdomain"],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json",
    },
  });

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<div>Loading .....</div>}>
      <GoogleOAuthProvider clientId="874024128644-vhh5lm0fdqp9gcoagv26joek19tp5ms2.apps.googleusercontent.com">
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </GoogleOAuthProvider>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.register();

// subscribeUser();
