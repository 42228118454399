import React from 'react';
import {Helmet} from "react-helmet"


const Meta = ({title="Nawafrica Real Estate",description="we connect property owners to client", keywords="Rent house, buy house, rent land, buy land"}) => {
    return (   
        <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
        <meta name="keywords" content={keywords}/>
      </Helmet>

     );
}
 
export default Meta;