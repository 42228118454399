import React from 'react'
import { Link } from 'react-router-dom'

import { trackEvent } from '../../Utils/useGAEventTracker'

import './auth.css'

const GoogleFbLink = () => {
  return (
    <div className='googleFb_Container'>
      <div className='google_container'>
        <Link
          onClick={() =>
            trackEvent('login with google', '/login/google', 'login link')
          }
          to='/login/google'
          className='btn googleLink'
        >
          <span>
            <img style={{ objectFit: 'cover' }} src='/images/google.jpg' />
          </span>
          <span className='googleLinkSpan'>Google</span>
        </Link>
      </div>

      <div className='fb_container'>
        <Link
          onClick={() =>
            trackEvent(
              'login with facebook',
              '/login/facebook',
              'login with facebook link'
            )
          }
          to='/login/facebook'
          className='btn fbLink'
        >
          <span>
            <img style={{ objectFit: 'cover' }} src='/images/fb.jpg' />
          </span>
          <span className='googleLinkSpan'>facebook</span>
        </Link>
      </div>
    </div>
  )
}

export default GoogleFbLink
