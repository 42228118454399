import React from 'react'
import { useTranslation } from 'react-i18next'
import Card from '@mui/material/Card';

// import selectedCountryInputs from '../../Utils/translateInput'
import './searchListing.css'
import SelectedItem1 from '../Home/SelectedItem1'
// import PriceInput from '../Home/PriceInput'
// import PriceInputSearch from './PriceInputSearch'
import SliderBedroom from '../Home/SliderBedroom'
import TextInputKeyword from '../Home/TextInputKeyword';

const SearchInputs = ({
  onChangeCountry,
  valueRentOrSell,
  onChangeSort,
  onChangeRentOrSale,
  valueCountry,
  countries,
  cities,
  onChangeCity,
  valueCity,
  placeholderPrice,
  onChangePrice,
  placeholderEmpty1,
  onChangeEmpty1,
  placeholderEmpty2,
  onChangeEmpty2,
  placeholderNumberOfListing,
  listingType,
  bedrooms,
  valuebedroom,
  valuePrice,
  valueListingType,
}) => {
  const { t } = useTranslation()

  return (
    <div className='searchListingInputCon'>
      <div className='sortContainer'>
        <select onChange={onChangeSort} className='form-control'>
          <option value=''>{t('Sort_By')}</option>
          <option value='title'>{t('title')}</option>
          <option value='dateposted'>{t('Date_Posted')}</option>
          <option value='price'>{t('Price')}</option>
          <option value='bedrooms'>{t('Bedrooms')}</option>
        </select>
      </div>

      <div className='inputCon1SearchList'>
        <div style={{ flex:"1", padding:"0 2px"}}>
        <SelectedItem1
        items={countries}
        onChange={onChangeCountry}
        placeholder={t('Country')}
        />
        </div>
        {/* <select
          value={valueCountry}
          onChange={onChangeCountry}
          className='form-control'
        >
          <option value=''>{t('Country')}</option>
          {countries.map((ctry) => (
            <option key={ctry} value={ctry}>
              {selectedCountryInputs(ctry)}
            </option>
          ))}
        </select> */}
             <div style={{flex:"1", padding:"0 2px"}}>
             <SelectedItem1
        items={cities}
        onChange={onChangeCity}
        placeholder={t('Choose a City')}
        />
             </div>
        {/* <select
          value={valueCity}
          onChange={onChangeCity}
          className='form-control selectCity'
        >
          <option value=''>{t('City')}</option>
          {cities.map((city) => (
            <option key={city} value={city}>
              {selectedCountryInputs(city)}
            </option>
          ))}
        </select> */}
      </div>
      <div className='inputCon2SearchList'>

        <input
          type='number'
          value={valuePrice}
          placeholder={placeholderPrice}
          onChange={onChangePrice}
          className='form-control'
        />
      </div>

      <div className='inputCon3SearchList'>
        <div className='sliderContainer'>
        <SelectedItem1
        items={bedrooms}
        onChange={onChangeEmpty1}
        placeholder={t('#_Of_Bedroom')}
        />
        </div>
      {/* <SliderBedroom
             onChange={onChangeEmpty1}
            /> */}


        {/* <select
          value={valuebedroom}
          placeholder={placeholderEmpty1}
          onChange={onChangeEmpty1}
          className='form-control'
        >
          <option value=''>{t('#_Of_Bedroom')}</option>
          {bedrooms.map((bedroom) => (
            <option key={bedroom} value={bedroom}>
              {bedroom}
            </option>
          ))}
        </select> */}

      </div>

      <div className='rentOrSellContainer'>
        <select
          value={valueRentOrSell}
          onChange={onChangeRentOrSale}
          className='form-control'
        >
          <option value=''>{t('Rent_Or_Sell_Prop.')}</option>
          <option value='Rental'>{t('Prop._For_Rent')}</option>
          <option value='Sale'>{t('Prop._For_Sale')}</option>
        </select>
      </div>

      <div className='inputCon4SearchList'>
        <select
          value={valueListingType}
          placeholder={placeholderEmpty2}
          onChange={onChangeEmpty2}
          className='form-control'
        >
          <option value=''>{t('Listing_Type')}</option>
          {listingType.map((listing) => (
            <option key={listing} value={listing}>
              {t(listing)}
            </option>
          ))}
        </select>
      </div>
      <div className='inputCon5SearchList'>
        <Card variant="outlined" className='card'>{placeholderNumberOfListing}</Card>
      </div>
    </div>
  )
}

export default SearchInputs
