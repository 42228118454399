import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";

import RentPlatformHeader from "../components/RentAndSalePlatform/RentPlatformHeader";
// import RentPlatformBody from '../components/RentAndSalePlatform/RentPlatformBody'
// import RentPlatfromSearch from '../components/RentAndSalePlatform/RentPlatfromSearch'
// import ArticleSelect from '../components/RentAndSaleArticlePost/ArticleSelect'
import Meta from "./../Utils/Meta";
// import { Link } from 'react-router-dom'
import {
  getRentPlatfrom,
  deleteRentPlatfromById,
} from "../Services/rentPlatformService";
// import LottieLoader from '../components/contactCaptivator/LottieLoader'
// import { getCurrentUser } from '../Services/authService'
import ProfileSpinner from "../components/profile/ProfileSpinner";
import RentPlatformContent from "../components/RentAndSalePlatform/RentPlatformContent";
import DownloadApp from "../components/AboutUs/DownloadApp";

const RentAndSalePlatform = () => {
  const [underConstruction, setUnderConstruction] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [loadLifter, setLoadFilter] = useState(false);
  const [loading, setLoading] = useState(false);

  const items = [
    "Resources",
    "Applications",
    "Renovation",
    "Collecting Rental",
    "Maintenance & Repairs",
    "Listing Your Rental",
    "Rent Payment",
    "Hygene",
    "Pets",
    "Taxes",
    "Legal",
    "Lease",
    "Insurance",
    "Complaints",
    "Investing",
  ];

  useEffect(() => {
    setUnderConstruction(false);
    window.scrollTo(0, 0);
    getData();
  }, []);

  const getData = async () => {
    try {
      setLoading(true);
      const { data } = await getRentPlatfrom();
      setPlatforms(data);
      setLoading(false);
    } catch (ex) {}
  };

  const onClickDelete = async (id) => {
    const remain = platforms.filter((x) => x.id !== id);
    setPlatforms(remain);
    await deleteRentPlatfromById(id);
  };

  const onChangeFilter = async (e) => {
    const selected = e.currentTarget.value;
    setLoadFilter(true);
    const { data } = await getRentPlatfrom();
    const remaining = data.filter((x) => x.subject == selected);

    setPlatforms(selected ? remaining : selected == "" ? data : []);
    setLoadFilter(false);
  };

  const searchTable = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].title.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  const onChangeArticle = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTable(value, platforms);
      setPlatforms(tableData);
      setLoadFilter(false);
    } else {
      try {
        setLoadFilter(true);
        const { data } = await getRentPlatfrom();
        setPlatforms(data);
        setLoadFilter(false);
      } catch (error) {
        // logger.log(
        //   'error from onSearchListing function in ListingScreen component',
        //   error
        // )
      }
    }
  };

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
      </div>
    );

  if (underConstruction)
    return (
      <>
        <Meta title={`Nawafrica | Rent And Sale Platform`} />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
      <Meta title={`Nawafrica | Rent And Sale Platform`} />
      <RentPlatformHeader />
      <RentPlatformContent />
      <DownloadApp />
      {/*  {getCurrentUser() && getCurrentUser().isadmin && (
        <div className='rentSaleAddLink'>
          <Link to='/rent-and-sale-platform/publish' className='btn btn-light'>
            Public An Article
          </Link>
        </div>
      )}
      <h1 className='rentSaleH1Topic'>Articles</h1>
      <RentPlatfromSearch
        onChangeArticle={onChangeArticle}
        onChangeFilter={onChangeFilter}
        items={items}
      />
      <RentPlatformBody
        loadLifter={loadLifter}
        onClickDelete={onClickDelete}
        items={platforms}
      /> */}
      <ScrollToTop smooth />
    </div>
  );
};

export default RentAndSalePlatform;
