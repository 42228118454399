import React from 'react'
import PoliciesSubHeading from './PoliciesSubHeading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFile } from '@fortawesome/free-solid-svg-icons'

const PolicieFurther = () => {
  return (
    <>
      <PoliciesSubHeading childern=' Further information about the processing of Personal Data' />
      <div style={{ width: '100%', display: 'flex', marginTop: '20px' }}>
        <div>
          <FontAwesomeIcon
            style={{
              color: 'black',
              fontSize: '35px',
              opacity: '0.8',
            }}
            icon={faFile}
          />
        </div>
        <div style={{ flex: '1', padding: '10px 20px' }}>
          <h6 style={{ fontWeight: 'bold', fontSize: '14px' }}>
            Captivator technologies inc.
          </h6>
          <p>
            Captivator technologies inc. is set by the iubenda Cookie Solution
            and stores User preferences related to Trackers in the local domain.
          </p>
        </div>
      </div>
    </>
  )
}

export default PolicieFurther
