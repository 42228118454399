import React from 'react'
import RentalAndSalePlatformSale from '../RentAndSalePlatform/RentalAndSalePlatformSale'

import './RentAndSaleDetails.css'

const RentalRelatedTopic = ({ items }) => {
  return (
    <div style={{ margin: '50px 10%', textAlign: 'center', padding: '50px 0' }}>
      <h2 style={{ marginBottom: '50px' }}>Related Articles</h2>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          //           background: 'red',
          flexDirection: 'row',
        }}
      >
        {items.map((item) => (
          <div key={`${item.id}`} style={{ width: '50%' }}>
            <RentalAndSalePlatformSale item={item} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default RentalRelatedTopic
