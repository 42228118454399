import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const ContactUsCard = ({
  title = 'Email Us',
  content,
  borderTop = '10px solid #035aa6',
}) => {
  useEffect(() => {
    AOS.init({ duration: 500 })
  }, [])
  return (
    <div
      style={{ borderTop }}
      className='contactUsCardContainer'
      data-aos='flip-left'
    >
      <h3>{title}</h3>
      {content}
    </div>
  )
}

export default ContactUsCard
