import React from "react";
import { useTranslation } from "react-i18next";

import Avatar from "../../iconComponent/Avatar";
import Management from "../../iconComponent/Management";
import Browser from "../../iconComponent/Browser";
import ContactUs from "../../iconComponent/ContactUs";
import { Link } from "react-router-dom";

import { trackEvent } from "../../Utils/useGAEventTracker";
import "./styleCompo.css";
import { Card } from "@mui/material";

import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Footer1 = () => {
  const { t } = useTranslation();
  return (
    <div className="footer1OuterCon">
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 1, md: 1 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Card
              variant="outlined"
              data-aos={"flip-left"}
              className="card footer1CardCon1"
            >
              <div style={{ flex: "1" }}>
                <div className="footer1IconCon">
                  <Avatar className="footer1IconClass" />
                </div>
                <h5>{t("Consultation_Service")}</h5>
                <p>
                  <b className="footer1Bold">{t("Consultation_Service_det")}</b>
                </p>
              </div>

              <div className="footer1LinkCon">
                <Link
                  onClick={() =>
                    trackEvent(
                      "consulting link",
                      "/consulting-service",
                      "Footer link"
                    )
                  }
                  to="/consulting-service"
                  // className='btn'
                >
                  <h5>
                    <b>{t("Consulting")}</b>
                  </h5>
                </Link>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Card
              variant="outlined"
              data-aos={"flip-left"}
              className="card footer1CardCon1"
            >
              <div style={{ flex: "1" }}>
                <div className="footer1IconCon">
                  <Management className="footer1IconClass" />
                </div>
                <h5>{t("Property_Management")}</h5>
                <p>
                  <b className="footer1Bold">{t("Property_Management_det")}</b>
                </p>
              </div>

              <div className="footer1LinkCon">
                <Link
                  onClick={() =>
                    trackEvent(
                      "Management link",
                      "/property-mangement",
                      "Footer link"
                    )
                  }
                  to="/property-management"
                  // className='btn'
                >
                  <h5>
                    <b>{t("Management")}</b>
                  </h5>
                </Link>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Card
              variant="outlined"
              data-aos={"flip-left"}
              className="card footer1CardCon1"
            >
              <div style={{ flex: "1" }}>
                <div className="footer1IconCon">
                  <Browser className="footer1IconClass" />
                </div>
                <h5>{t("Renting_And_Selling")}</h5>
                <p>
                  <b className="footer1Bold">{t("Renting_And_Selling_det")}</b>
                </p>
              </div>

              <div className="footer1LinkCon">
                <Link
                  onClick={() =>
                    trackEvent(
                      "Renting or Selling",
                      "/rent-and-sale-platform",
                      "Footer link"
                    )
                  }
                  to="/rent-and-sale-platform"
                  // className='btn'
                >
                  <h5>
                    <b>{t("Renting_or_Selling")}</b>
                  </h5>
                </Link>
              </div>
            </Card>
          </Grid>

          <Grid item xs={12} sm={4} md={3} lg={3}>
            <Card
              variant="outlined"
              data-aos={"flip-left"}
              className="card footer1CardCon1"
            >
              <div style={{ flex: "1" }}>
                <div className="footer1IconCon">
                  <ContactUs className="footer1IconClass" />
                </div>
                <h5>{t("Contact_Us")}</h5>
                <p>
                  <b className="footer1Bold">{t("Contact_Us_det")}</b>
                </p>
              </div>

              <div className="footer1LinkCon">
                <Link
                  onClick={() =>
                    trackEvent(
                      "Contact us Link",
                      "/contactUsNow",
                      "Footer Link"
                    )
                  }
                  to="/contactUsNow"
                  // className='btn'
                >
                  <h5>
                    <b>{t("Contact_Us")}</b>
                  </h5>
                </Link>
              </div>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Footer1;
