import React from 'react'

import './OurStory.css'

const Heading = ({ topic = 'OUR STORY' }) => {
  return (
    <div className='ourStoryHead'>
      <b>{topic}</b>
    </div>
  )
}

export default Heading
