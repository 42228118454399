import React from 'react'

const StateZipcode = ({
  data = 'Save Container',
  id,
  fieldLabel = '',
  labelValue = 'First Name',
  placeholder,
}) => {
  return (
    <div style={{ width: '80%' }}>
      <p
        style={{ marginBottom: '5px', marginTop: '5px' }}
        className={`passwordLabelSaveContainer ${fieldLabel}`}
        htmlFor={id}
      >
        <b>{labelValue}</b>
      </p>
      <div
        style={{
          //           border: '1px solid #035aa6',
          width: '80%',
          padding: '5px 10px 5px 10px',
          borderRadius: '5px',
          color: '#035aa6',
          letterSpacing: '0.68px',
          font: 'normal normal normal 15px/24px Merriweather',
          background: '#f5f5f5 0% 0% no-repeat padding-box',
        }}
        id={id}
      >
        {data ? data : <span style={{ color: 'gray' }}>{placeholder}</span>}
      </div>
    </div>
  )
}

export default StateZipcode
