import React from 'react'
import { Link } from 'react-router-dom'

import { trackEvent } from '../../Utils/useGAEventTracker'
import CloseBtnDeal from '../LeaseSignDetails/CloseBtnDeal'

import './addListing.css'

const ListingItems = ({
  icon,
  title,
  angle,
  chats,
  href,
  classes,
  openAngle,
  underReview,
  closeDeal,
}) => {
  return (
    <Link
      onClick={() => trackEvent(title, href, 'side bar link')}
      className='addListingListItemAtag'
      to={href}
    >
      <div className={classes}>
        <span className='iconBadgeSpan'>
          <div className='badge'>{chats}</div>
          {icon}
        </span>
        {title && <span className='titleSpanAddListing'>{title}</span>}
        {angle && <span>{angle}</span>}
        {openAngle && <span className='openAngleListItem'>{openAngle}</span>}
        {underReview && underReview.length > 0 && (
          <div className='badge sideListItemSentMsges'>
            {underReview.length > 9 ? '9+' : underReview.length}
          </div>
        )}
      </div>
    </Link>
  )
}

export default ListingItems
