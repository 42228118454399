import React from 'react'
import _ from 'lodash'

const BlogPagination = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange,
  nextPage,
  previousPage,
}) => {
  const pagesCount = Math.ceil(itemsCount / pageSize)
  if (pagesCount === 1) return null
  let pages = _.range(1, pagesCount + 1)

  return (
    <div style={{ margin: '10px 17%', width: '100%' }}>
      <nav aria-label='Page navigation example'>
        <ul className='pagination'>
          <li onClick={previousPage} className='page-item'>
            <a className='page-link' href='#'>
              Previous
            </a>
          </li>

          {pages.map((p) => (
            <li
              onClick={() => onPageChange(p)}
              style={{ marginLeft: '5px' }}
              className={p == currentPage ? 'page-item active' : 'page-item'}
            >
              <a className='page-link' href='#'>
                {p}
              </a>
            </li>
          ))}

          <li
            onClick={nextPage}
            style={{ marginLeft: '5px' }}
            className='page-item'
          >
            <a className='page-link' href='#'>
              Next
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default BlogPagination
