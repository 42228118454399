import React from 'react'
import PoliciesSubHeading from './PoliciesSubHeading'
import PolicyContactIcon from './PolicyContactIcon'

const PolicyContact = () => {
  return (
    <div>
      <PoliciesSubHeading childern='Contact information' />
      <PolicyContactIcon />
    </div>
  )
}

export default PolicyContact
