import React from 'react'
import "./listingDetails.css"



const Address = ({street_address}) => {
return (
<div className="addressOuterContainer">
<p>
<span>{street_address}</span>
</p>                                                   
</div>
)
}

export default Address
