import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLandmark,
  faPlane,
  faMoneyBillTrendUp,
  faGraduationCap,
  faBriefcase,
  faTentArrowTurnLeft,
} from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

import ImageText from './ImageText'

const WhoUse = () => {
  return (
    <div className='aboutWhoUsesCon'>
      <div>
        <ImageText
          text='Your home away from home. Nawafrica is used by landlords looking for tenants to rent or buy their property. It provides a platform where they can post their properties and allow potential buyers/renters to view them without any hassle. Sign up today.'
          title='LANDLORDS'
          icon={
            <FontAwesomeIcon
              style={{ color: '#035aa6', fontSize: '70px' }}
              icon={faLandmark}
            />
          }
        />
        <ImageText
          text={`Invest in African real estate. A platform that offers real-time property information and market trends. Find the perfect property at Nawafrica, Africa's leading investment portal. Register now.`}
          title='REAL ESTATE INVESTORS'
          icon={
            <FontAwesomeIcon
              style={{ color: '#035aa6', fontSize: '70px' }}
              icon={faMoneyBillTrendUp}
            />
          }
        />

        <ImageText
          text='Nawafrica offers safe and affordable accommodation near schools. Students looking for accommodation in the city or town where their school is located can use Nawafrica to easily view and book properties before relocating there. Start searching today!'
          title='STUDENTS'
          icon={
            <FontAwesomeIcon
              style={{ color: '#035aa6', fontSize: '70px' }}
              icon={faGraduationCap}
            />
          }
        />
      </div>
      <div style={{ paddingLeft: '20px', marginTop: '0' }}>
        <ImageText
          text={`Travel like a pro. Book accommodation before travelling so you don't have to spend hours looking for accommodation once there.Travelling is tough. Booking accommodation before you travel? Nawafrica has got your back. Check out Nawafrica today`}
          title='TRAVELLERS'
          icon={
            <FontAwesomeIcon
              style={{ color: '#035aa6', fontSize: '70px' }}
              icon={faPlane}
            />
          }
        />
        <ImageText
          text='Transferring? Nawafrica has houses waiting for you. House hunting simplified. Find a home that suits your needs at Nawafrica. Nawafrica gives you access to houses available in any city/ town across Africa. Get started today.'
          title='BEEN ON TRANSFER'
          icon={
            <FontAwesomeIcon
              style={{ color: '#035aa6', fontSize: '70px' }}
              icon={faBriefcase}
            />
          }
        />
        <ImageText
          text='if you a tenant that dislikes your present location for reasons such as conflict with landlords, long distances from the workplace, criminal neighborhood etc and wishes to relocate from your present house. Nawafrica makes it easy to look for a suitable house of your choice simply by a click of a button.'
          title='TENANT RELOCATING'
          icon={
            <FontAwesomeIcon
              style={{ color: '#035aa6', fontSize: '70px' }}
              icon={faTentArrowTurnLeft}
            />
          }
        />
      </div>
    </div>
  )
}

export default WhoUse
