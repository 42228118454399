import React from 'react'
import PoliciesCard from './PoliciesCard'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAudioDescription,
  faChartSimple,
  faEnvelopeOpen,
  faFileCircleCheck,
} from '@fortawesome/free-solid-svg-icons'

const PoliciesIcons = () => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: '1' }}>
        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faAudioDescription}
            />
          }
        />
        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faEnvelopeOpen}
            />
          }
          firstTitle='Contacting the User'
          secondTitle='Phone contact'
          description='Personal Data: phone number'
        />

        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faFileCircleCheck}
            />
          }
          firstTitle='Handling compliance'
          secondTitle='Dispute Resolution/ Independent Recourse'
          description='Mechanism'
        />

        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faFileCircleCheck}
            />
          }
          firstTitle='Managing contacts and sending messages'
          secondTitle='HubSpot Email'
          description='Personal Data: email address; Usage Data'
        />

        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faFileCircleCheck}
            />
          }
          firstTitle='Tag Management'
          secondTitle='Google Tag Manager'
          description='Personal Data: Usage Data'
        />
        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faFileCircleCheck}
            />
          }
          firstTitle='User database management'
          secondTitle='HubSpot CRM'
          description='Personal Data: email address; phone number; various types of Data as specified in the privacy policy of the service'
        />
        <PoliciesCard
          firstTitle='HubSpot Lead Management'
          secondTitle='Personal Data: various types of Data as specified'
          description='the privacy policy of the service'
        />
      </div>
      <div style={{ flex: '1' }}>
        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faChartSimple}
            />
          }
          firstTitle='Analytics'
          secondTitle='HubSpot Analytics, Google Analytics and Matomo'
          description='Personal Data: Cookies; Usage Data'
        />
        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faFileCircleCheck}
            />
          }
          firstTitle='Displaying content from external platforms'
          secondTitle='Google Fonts'
          description='Personal Data: Usage Data; various types of Data as specified in the privacy policy of the service'
        />

        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faFileCircleCheck}
            />
          }
          firstTitle='Hosting and backend infrastructure'
          secondTitle='Amazon Web Services (AWS)'
          description='Personal Data: various types of Data as specified in the privacy policy of the service'
        />

        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faFileCircleCheck}
            />
          }
          firstTitle='Remarketing and behavioral targeting'
          secondTitle='LinkedIn Website Retargeting'
          description='Personal Data: Cookies; Usage Data'
        />
        <PoliciesCard
          icon={
            <FontAwesomeIcon
              style={{
                color: 'black',
                fontSize: '35px',
                opacity: '0.8',
              }}
              icon={faFileCircleCheck}
            />
          }
          firstTitle='Traffic optimization and distribution'
          secondTitle='loudflare'
          description='Personal Data: Cookies; various types of Data as specified in the privacy policy of the service'
        />
      </div>
    </div>
  )
}

export default PoliciesIcons
