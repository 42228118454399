import React from 'react'

import Chat from '../../iconComponent/Chat'

import './manageTenants.css'

const ChatManagement = ({chats}) => {
return (
<div className="addListChatCon">
<div className="badge">{chats}</div>
<Chat
height="50px"
width="50px"
/>                                                        
</div>
)
}

export default ChatManagement

