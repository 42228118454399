import React from 'react'

const RentPlatformContent = () => {
  return (
    <div className='rentPlatformContentOuterCon'>
      <p style={{ textIndent: '50px' }}>
        NawAfrica.com, operated by Captivator Technologies, is making it easy
        for thousands of people across Africa to be closer to their next dream
        location, by reimagining real estate.
      </p>
      <p style={{ textIndent: '50px' }}>
        As a growing real estate website in Africa, NawAfrica.com and its
        affiliates offer customers an on-demand experience for selling, buying,
        renting in a hassle free and seamless end-to-end service. We’re also
        working to make it easier for buyers to have direct access to cash
        through banks and many financing houses across Africa.
      </p>
      <p style={{ textIndent: '50px' }}>
        NawAfrica.com provides homeowners and property managers tools to help
        manage their property with transparency and accountability. Tenants,
        rent payments and maintenance requests are all managed through our
        platform. With our vast network of plumbers and handy repair
        professionals, rest assured you’ll be well taken care of.
      </p>
      <h6 style={{ fontWeight: 'bold' }}>Adverts</h6>
      <p>
        Our search, content, and advertising strategies are designed to move
        thousands of people to our site. We offer a full line of advertising
        products and online marketing services designed to help real estate
        professionals connect with interested buyers and sellers. If your goals
        include connecting with quality buyers and sellers searching for their
        next home and leveraging the right tools and services to grow your
        business, you’ve come to the right place! We have tons of resources to
        help you stay informed of what’s happening in the industry, what’s
        working for successful agents, and what strategies are leading to
        success in today’s market.
      </p>
      <p style={{ margin: '30px 0' }}>
        Interested in joining and being part of our affiliates or want to
        advertise your products, we’ll appreciate talking with you
      </p>
    </div>
  )
}

export default RentPlatformContent
