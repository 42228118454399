import React from "react";
import { useTranslation } from "react-i18next";

const KeyFeatures = ({ keywords }) => {
  const { t } = useTranslation();
  if (keywords && keywords.length === 0) return;
  return (
    <div className="moreAboutListCon">
      <p>
        <span className="rentOrSellSpan">{t("Key Features")} : </span>
        {keywords &&
          keywords.map((key, index) => (
            <span key={key} className="bedroomSpan">
              {" "}
              {t(key)} {index !== keywords.length - 1 ? "|" : ""}
            </span>
          ))}
      </p>
    </div>
  );
};

export default KeyFeatures;
