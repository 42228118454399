import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const LeaseSignStripeCancel = ({ match }) => {
  useEffect(() => {
    if (localStorage && localStorage.getItem('payingWithStripe')) {
      localStorage && localStorage.removeItem('payingWithStripe')
    } else {
      window.location.assign(`/portal/tenant/leases/${match.params.id}`)
    }
  }, [])
  return (
    <div
      style={{
        width: '100%',
        height: '30vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        className='alert alert-info'
      >
        <div>
          <Link
            to={`/portal/tenant/leases/${match.params.id}`}
            className='btn btn-light'
            style={{ marginRight: '10px', color: 'grey' }}
          >
            <FontAwesomeIcon
              style={{ marginRight: '5px' }}
              className='icon'
              icon={faArrowLeftLong}
            />
            Go Back
          </Link>
        </div>
        <div style={{ flex: 1 }}>
          This Payment Through Visa Card Have Successfully Been Cancel
        </div>
      </div>
    </div>
  )
}

export default LeaseSignStripeCancel
