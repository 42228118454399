import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";
import Heading from "../components/OurStory/Heading";
import StoryParagraph from "../components/OurStory/StoryParagraph";
// import SubTitleParagraph from '../components/OurStory/SubTitleParagraph'

import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import Meta1 from "../Utils/Meta1";
import Meta from "./../Utils/Meta";
import DownloadApp from "../components/AboutUs/DownloadApp";

const OurStory = () => {
  const [underConstruction, setUnderConstruction] = useState(false);

  useEffect(() => {
    setUnderConstruction(false);
    window.scrollTo(0, 0);
  }, []);

  if (underConstruction)
    return (
      <>
        <Meta title={`Nawafrica | our story`} />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <div className="ourStoryOuterCon">
      <Meta1
        title={`Nawafrica | our story`}
        description="Nawafrica Story"
        link="/our-story"
      />
      <div style={{ width: "100%" }}>
        <div className="ourStoryInnerCon">
          <Heading />
          <StoryParagraph sentence="Captivator Technologies Inc. is a tech company that is based in Limbe, the southwest region of Cameroon. We as a Company have a vision to digitalize every sector in Africa, some of which include the real estate sector, the educational sector, the health sector, the government administration etc. We believe technology can be used to solve measured problems that are plaguing Africa as a continent such as corruption, unemployment, illiteracy etc. Our tech team is fully committed to building applications that will help to solve and ease the lives of Africans" />
          {/* <SubTitleParagraph
            subtitle='What is Captivator technologies ?'
            sentence='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vel pretium lectus quam id leo in vitae. Turpis egestas sed tempus urna. Maecenas volutpat blandit aliquam etiam. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Urna porttitor rhoncus dolor purus non enim praesent. Dignissim sodales ut eu sem integer vitae justo eget magna. Bibendum est ultricies integer quis auctor. Imperdiet proin fermentum leo vel. Egestas sed tempus urna et pharetra pharetra massa. Libero nunc consequat interdum varius sit amet mattis vulputate.'
          />
          <SubTitleParagraph sentence='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vel pretium lectus quam id leo in vitae. Turpis egestas sed tempus urna. Maecenas volutpat blandit aliquam etiam. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Urna porttitor rhoncus dolor purus non enim praesent. Dignissim sodales ut eu sem integer vitae justo eget magna. Bibendum est ultricies integer quis auctor. Imperdiet proin fermentum leo vel. Egestas sed tempus urna et pharetra pharetra massa. Libero nunc consequat interdum varius sit amet mattis vulputate.' />
          <SubTitleParagraph
            subtitle='What is our objective?'
            sentence='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vel pretium lectus quam id leo in vitae. Turpis egestas sed tempus urna. Maecenas volutpat blandit aliquam etiam. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Urna porttitor rhoncus dolor purus non enim praesent. Dignissim sodales ut eu sem integer vitae justo eget magna. Bibendum est ultricies integer quis auctor. Imperdiet proin fermentum leo vel. Egestas sed tempus urna et pharetra pharetra massa. Libero nunc consequat interdum varius sit amet mattis vulputate.'
          />
          <SubTitleParagraph
            subtitle='Who should be part of us ?'
            sentence='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vel pretium lectus quam id leo in vitae. Turpis egestas sed tempus urna. Maecenas volutpat blandit aliquam etiam. Porta non pulvinar neque laoreet suspendisse interdum consectetur libero. Urna porttitor rhoncus dolor purus non enim praesent. Dignissim sodales ut eu sem integer vitae justo eget magna. Bibendum est ultricies integer quis auctor. Imperdiet proin fermentum leo vel. Egestas sed tempus urna et pharetra pharetra massa. Libero nunc consequat interdum varius sit amet mattis vulputate.'
          /> */}
        </div>
        <DownloadApp />
      </div>
      <ScrollToTop smooth />
    </div>
  );
};

export default OurStory;
