import React from 'react'
import Progress from 'react-progressbar'

const AddStoryModal = ({ loading, progress }) => {
  return (
    <div
      style={{
        width: '80%',
        height: '100%',
        position: 'fixed',
        zIndex: '2',
        background: 'black',
        opacity: '0.5',
        marginTop: '-50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '10%',
      }}
    >
      <div
        style={{
          width: '40%',
          height: '40%',
          opacity: '1',

          borderRadius: '10px',
        }}
      >
        {loading ? (
          <div style={{ margin: '20% 0', width: '100%' }}>
            <div style={{ color: 'white' }}>{progress}</div>
            <Progress color='#035aa6' completed={progress} />
          </div>
        ) : progress == 100 ? (
          'done'
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

export default AddStoryModal
