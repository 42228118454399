export function likeListingAnimate() {
  return {
    v: '4.8.0',
    meta: { g: 'LottieFiles AE 1.0.0', a: '', k: '', d: '', tc: '' },
    fr: 29.9700012207031,
    ip: 0,
    op: 36.0000014663101,
    w: 1080,
    h: 1000,
    nm: 'Main',
    ddd: 0,
    assets: [
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'main_heart 9',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [1],
                  },
                  { t: 24.00000097754, s: [361] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [42.333, -58.667, 0],
                    ti: [-42.333, 58.667, 0],
                  },
                  { t: 24.00000097754, s: [794, 148, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'main_heart 8',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [64],
                  },
                  { t: 24.00000097754, s: [361] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [-38, -66.333, 0],
                    ti: [38, 66.333, 0],
                  },
                  { t: 24.00000097754, s: [312, 102, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 12,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'main_heart 7',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 181, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [12.333, -34, 0],
                    ti: [-12.333, 34, 0],
                  },
                  { t: 24.00000097754, s: [614, 296, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'main_heart 6',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [181],
                  },
                  { t: 24.00000097754, s: [541] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [-57.333, -39.333, 0],
                    ti: [57.333, 39.333, 0],
                  },
                  { t: 24.00000097754, s: [196, 264, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'main_heart 5',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [1],
                  },
                  { t: 24.00000097754, s: [139] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [66.333, -8.667, 0],
                    ti: [-66.333, 8.667, 0],
                  },
                  { t: 24.00000097754, s: [938, 448, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'main_heart 4',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [1],
                  },
                  { t: 24.00000097754, s: [361] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [-88.333, -12.333, 0],
                    ti: [88.333, 12.333, 0],
                  },
                  { t: 24.00000097754, s: [10, 426, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'main_heart 3',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [1],
                  },
                  { t: 24.00000097754, s: [361] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [-75.667, 14.667, 0],
                    ti: [75.667, -14.667, 0],
                  },
                  { t: 24.00000097754, s: [86, 588, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 6,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'main_heart 2',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [292],
                  },
                  { t: 24.00000097754, s: [385] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [0, 18.333, 0],
                    ti: [0, -18.333, 0],
                  },
                  { t: 24.00000097754, s: [540, 610, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'main_heart 11',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [1],
                  },
                  { t: 24.00000097754, s: [433] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [0, 53, 0],
                    ti: [0, -53, 0],
                  },
                  { t: 24.00000097754, s: [540, 818, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 10,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'main_heart 10',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [1],
                  },
                  { t: 24.00000097754, s: [361] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [41.333, 21.667, 0],
                    ti: [-41.333, -21.667, 0],
                  },
                  { t: 24.00000097754, s: [788, 630, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'main_heart',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 0,
                    s: [1],
                  },
                  { t: 24.00000097754, s: [199] },
                ],
                ix: 10,
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [540, 500, 0],
                    to: [38.667, 20.333, 0],
                    ti: [-38.667, -20.333, 0],
                  },
                  { t: 24.00000097754, s: [772, 622, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    t: 0,
                    s: [25, 25, 100],
                  },
                  { t: 24.00000097754, s: [0, 0, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [29.042, -0.225],
                          [17.08, -13.504],
                          [6.834, -9.562],
                          [9.259, 7.24],
                          [21.774, -0.097],
                          [19.64, -21.396],
                          [-0.528, -30.016],
                          [-23.537, -25.374],
                          [-33.951, -27.076],
                          [-15.463, -13.491],
                          [-8.455, 7.395],
                          [-13.151, 11.164],
                          [-29.165, 32.169],
                          [-0.871, 34.604],
                          [19.998, 22.408],
                        ],
                        o: [
                          [-19.64, -21.396],
                          [-21.773, -0.096],
                          [-9.258, 7.24],
                          [-6.833, -9.563],
                          [-17.079, -13.505],
                          [-29.043, -0.225],
                          [-19.975, 22.412],
                          [0.881, 34.6],
                          [29.166, 32.174],
                          [13.134, 11.198],
                          [8.454, 7.395],
                          [15.463, -13.592],
                          [33.952, -27.069],
                          [23.536, -25.381],
                          [0.521, -30.029],
                          [0, 0],
                        ],
                        v: [
                          [160.743, -137.462],
                          [84.281, -170.713],
                          [24.267, -150.001],
                          [0.003, -124.667],
                          [-24.26, -150.001],
                          [-84.275, -170.713],
                          [-160.737, -137.462],
                          [-190.982, -55.919],
                          [-153.091, 37.143],
                          [-58.26, 126.162],
                          [-14.762, 163.544],
                          [14.768, 163.544],
                          [58.284, 126.162],
                          [153.114, 37.161],
                          [190.988, -55.919],
                          [160.692, -137.479],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.253067944097, 0.334603672402, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [191.759, 171.189], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 36.0000014663101,
            st: 0,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 4,
        nm: 'main_heart',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 1, ix: 10 },
          p: { a: 0, k: [540, 500, 0], ix: 2 },
          a: { a: 0, k: [191.763, 170.375, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                t: 0,
                s: [0, 0, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                t: 1,
                s: [11.5, 11.5, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                t: 2,
                s: [23, 23, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                t: 3,
                s: [34.5, 34.5, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                t: 4,
                s: [46, 46, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.822, 0.822, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                t: 5,
                s: [57.5, 57.5, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.858, 0.858, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.156, 0.156, 0] },
                t: 6,
                s: [69, 69, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.877, 0.877, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.201, 0.201, 0] },
                t: 7,
                s: [82.126, 82.126, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.928, 0.928, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.259, 0.259, 0] },
                t: 8,
                s: [91.43, 91.43, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.239, 0.239, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [-0.529, -0.529, 0] },
                t: 9,
                s: [95.842, 95.842, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.783, 0.783, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.094, 0] },
                t: 10,
                s: [95.242, 95.242, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.82, 0.82, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.135, 0.135, 0] },
                t: 11,
                s: [90.354, 90.354, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.837, 0.837, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.155, 0.155, 0] },
                t: 12,
                s: [82.527, 82.527, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.851, 0.851, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.171, 0.171, 0] },
                t: 13,
                s: [73.429, 73.429, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.866, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.188, 0.188, 0] },
                t: 14,
                s: [64.732, 64.732, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.895, 0.895, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.221, 0.221, 0] },
                t: 15,
                s: [57.831, 57.831, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1.05, 1.05, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.398, 0.398, 0] },
                t: 16,
                s: [53.649, 53.649, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.729, 0.729, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.031, 0.031, 0] },
                t: 17,
                s: [52.541, 52.541, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.807, 0.807, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.12, 0.12, 0] },
                t: 18,
                s: [54.308, 54.308, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.829, 0.829, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.146, 0.146, 0] },
                t: 19,
                s: [58.296, 58.296, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.844, 0.844, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.163, 0.163, 0] },
                t: 20,
                s: [63.565, 63.565, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.858, 0.858, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.179, 0.179, 0] },
                t: 21,
                s: [69.08, 69.08, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.878, 0.878, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.201, 0.201, 0] },
                t: 22,
                s: [73.892, 73.892, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.93, 0.93, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.261, 0.261, 0] },
                t: 23,
                s: [77.29, 77.29, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.304, 0.304, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [-0.454, -0.454, 0] },
                t: 24,
                s: [78.885, 78.885, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.784, 0.784, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.095, 0.095, 0] },
                t: 25,
                s: [78.637, 78.637, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.82, 0.82, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.136, 0.136, 0] },
                t: 26,
                s: [76.819, 76.819, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.837, 0.837, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.155, 0.155, 0] },
                t: 27,
                s: [73.928, 73.928, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.851, 0.851, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.171, 0.171, 0] },
                t: 28,
                s: [70.579, 70.579, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.866, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.189, 0.189, 0] },
                t: 29,
                s: [67.386, 67.386, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.895, 0.895, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.222, 0.222, 0] },
                t: 30,
                s: [64.86, 64.86, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [1.058, 1.058, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.408, 0.408, 0] },
                t: 31,
                s: [63.338, 63.338, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.731, 0.731, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.034, 0.034, 0] },
                t: 32,
                s: [62.947, 62.947, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.807, 0.807, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.121, 0] },
                t: 33,
                s: [63.612, 63.612, 100],
              },
              {
                i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                o: { x: [0.167, 0.167, 0.167], y: [0.147, 0.147, 0] },
                t: 34,
                s: [65.089, 65.089, 100],
              },
              { t: 35.0000014255792, s: [67.031, 67.031, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [29.042, -0.225],
                      [17.08, -13.504],
                      [6.834, -9.562],
                      [9.259, 7.24],
                      [21.774, -0.097],
                      [19.64, -21.396],
                      [-0.528, -30.016],
                      [-23.537, -25.374],
                      [-33.951, -27.076],
                      [-15.463, -13.491],
                      [-8.455, 7.395],
                      [-13.151, 11.164],
                      [-29.165, 32.169],
                      [-0.871, 34.604],
                      [19.998, 22.408],
                    ],
                    o: [
                      [-19.64, -21.396],
                      [-21.773, -0.096],
                      [-9.258, 7.24],
                      [-6.833, -9.563],
                      [-17.079, -13.505],
                      [-29.043, -0.225],
                      [-19.975, 22.412],
                      [0.881, 34.6],
                      [29.166, 32.174],
                      [13.134, 11.198],
                      [8.454, 7.395],
                      [15.463, -13.592],
                      [33.952, -27.069],
                      [23.536, -25.381],
                      [0.521, -30.029],
                      [0, 0],
                    ],
                    v: [
                      [160.743, -137.462],
                      [84.281, -170.713],
                      [24.267, -150.001],
                      [0.003, -124.667],
                      [-24.26, -150.001],
                      [-84.275, -170.713],
                      [-160.737, -137.462],
                      [-190.982, -55.919],
                      [-153.091, 37.143],
                      [-58.26, 126.162],
                      [-14.762, 163.544],
                      [14.768, 163.544],
                      [58.284, 126.162],
                      [153.114, 37.161],
                      [190.988, -55.919],
                      [160.692, -137.479],
                    ],
                    c: true,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'fl',
                c: { a: 0, k: [1, 0.253067944097, 0.334603672402, 1], ix: 4 },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [191.759, 171.189], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Group 1',
            np: 2,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 0,
        op: 36.0000014663101,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 0,
        nm: 'minihearts',
        refId: 'comp_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [540, 500, 0], ix: 2 },
          a: { a: 0, k: [540, 500, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        w: 1080,
        h: 1000,
        ip: 4.00000016292334,
        op: 34.0000013848484,
        st: 4.00000016292334,
        bm: 0,
      },
    ],
    markers: [],
  }
}
