import React from 'react'
import './contact.css'

const SubmitButtonContact = ({ onClick }) => {
  return (
    <div>
      <button onClick={onClick} type='submit' className='btn contactSubmit'>
        Submit
      </button>
    </div>
  )
}

export default SubmitButtonContact
