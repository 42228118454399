import React, { useEffect } from "react";
import Lottie from "react-lottie";
import { leaseLottie } from "./leaseLottie";

const LeaseLoader = ({ height = "400px" }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: leaseLottie(),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "white",
      }}
    >
      <Lottie options={defaultOptions} height={200} width={200} />
    </div>
  );
};

export default LeaseLoader;
