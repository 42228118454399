import React from 'react'
import { FaReply} from 'react-icons/fa';
import { FaTrash} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

import './messageDetail.css'
import { useTranslation } from 'react-i18next';

const BottomDeleteReply = ({onDelete}) => {
 const {t} = useTranslation()
 return (
<>
<div className="bottomDeleteReplyOuterCon">
<div  data-toggle="modal" data-target="#exampleModalInbox" >
<FaReply data-tip={t("reply")} className="replyIconInMessageDet"/>
</div>
<FaTrash onClick={onDelete} data-tip={t("delete")} className="deleteIconInMessageDet"/>
</div>
<ReactTooltip effect="solid"/>
</>
)
}

export default BottomDeleteReply
