import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';



const SelectedItem1Message = ({placeholder, items=[], onChange }) => {
  return (
    <Autocomplete
    id="country-select-demo"
    sx={{ width: "100%", padding:"0"}}
    className={`messageInput`}
    options={items}
    autoHighlight
    getOptionLabel={(option) => option.data}
    onChange={onChange}
    renderOption={(props, option) => (
      <Box className={`form-control`} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        {option.data}
      </Box>
    )}
    renderInput={(params) => (
        <>
      <TextField
        {...params}
        label={placeholder}
        inputProps={{
            ...params.inputProps,
          autoComplete: 'off', // disable autocomplete and autofill
          style: { height:"15px", backgroundColor:"#ffffff 0% 0% no-repeat padding-box",font: "normal normal 300 18px/25px Merriweather" },
        }}
      />
        </>
    )}
  />

  )
}

export default SelectedItem1Message
