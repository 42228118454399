import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBusinessTime,
  faLaptopFile,
} from "@fortawesome/free-solid-svg-icons";

const CrewImagesContainer = ({ boss = true }) => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div className="crewImageOuterCon">
      {boss && (
        <h1 data-aos="slide-up" style={{ textAlign: "center" }}>
          LEADERSHIP
        </h1>
      )}
      <div className="crewCardCon">
        <div className="crewImageCon" data-aos="fade-right">
          <img
            style={{ width: "100%", height: "100%" }}
            src={
              boss ? "../../images/boss-leo.jpg" : "../../images/mavinIt.jpg"
            }
          />
        </div>
        <div data-aos="fade-left" className="crewImagesContent">
          {boss && (
            <FontAwesomeIcon className="crewIcon" icon={faBusinessTime} />
          )}
          {!boss && (
            <FontAwesomeIcon className="crewIcon" icon={faLaptopFile} />
          )}
          {boss && (
            <p className="nameContainer">
              <b className="names">Leonard Ayamba Ndifon</b>
            </p>
          )}

          {!boss && (
            <p className="nameContainer">
              <b className="names">MARVIN NKOM NDIFON</b>
            </p>
          )}
          {boss && (
            <h5 style={{ color: "#ffa500", textAlign: "center" }}>
              CEO/ FOUNDER OF CAPTIVATOR TECHNOLOGIES INC.
            </h5>
          )}
          {!boss && (
            <h5
              style={{
                color: "#ffa500",
                textAlign: "center",
              }}
            >
              ASSISTANT CTO/CO-FOUNDER OF CAPTIVATOR TECHNOLOGIES INC.
            </h5>
          )}
          {boss && (
            <>
              <p style={{ margin: "0 1%" }}>
                <b>
                  <i>ACADEMIC </i>:
                </b>
                <span>
                  <span style={{ marginLeft: "10px" }}>I </span>
                  have a scientist-based academic background, having my primary
                  school in G.S Lobe Estate, and my secondary school at G.B.H.S
                  Ekondo titi and a high school at B.G.S Buea, I have a BSc.
                  degree in Chemistry from the University of Buea. And a Msc. in
                  Cyber Security In USA
                </span>
              </p>
              <p style={{ margin: "5px 1%", textAlign: "left" }}>
                <b>
                  <i>SKILLS </i>:
                </b>
                <span>
                  <span style={{ marginLeft: "10px", marginRight: "5px" }}>
                    Cyber
                  </span>
                  Security expert, HTML, CSS, Javacript, Python, React, React
                  native, Node, Postgresql, Docker
                </span>
              </p>
              <p style={{ margin: "10px 1%" }}>
                <b>
                  <i>WORK EXPERIENCE </i>:
                </b>
                <span>
                  <span style={{ marginLeft: "10px" }}>Lorem</span>Aenean et
                  tortor at risus viverra adipiscing. Aliquet porttitor lacus
                  luctus accumsan tortor. Morbi tristique senectus et netus.
                  Dictumst quisque sagittis purus sit amet volutpat consequat
                  mauris nunc.
                </span>
              </p>

              <p style={{ margin: "10px 1%" }}>
                <b>
                  <i>VISION </i>:
                </b>
                <span>
                  <span style={{ marginLeft: "10px" }}>Lorem</span>Aenean et
                  tortor at risus viverra adipiscing. Aliquet porttitor lacus
                  luctus accumsan tortor. Morbi tristique senectus et netus.
                  Dictumst quisque sagittis purus sit amet volutpat consequat
                  mauris nunc.
                </span>
              </p>
            </>
          )}

          {!boss && (
            <>
              <p style={{ margin: "0 1%" }}>
                <b>
                  <i>ACADEMIC </i>:
                </b>
                <span>
                  <span style={{ marginLeft: "10px" }}>I</span>
                  have a scientist-based academic background, having my primary
                  school in G.S Lobe Estate, and my secondary school at G.B.H.S
                  Ekondo titi and a high school at B.G.S Buea.I have a BSc.
                  degree in Microbiology and a minor in medical lab. Technology
                  from the University of Buea.
                </span>
              </p>
              <p style={{ margin: "5px 1%" }}>
                <b>
                  <i>SKILLS </i>:
                </b>
                <span>
                  <span style={{ marginLeft: "10px" }}>Wordpress,</span> HTML,
                  CSS, Javascript, React
                </span>
              </p>
              <p style={{ margin: "10px 1%" }}>
                <b>
                  <i>WORK EXPERIENCE </i>:
                </b>
                <span>
                  <span style={{ marginLeft: "10px" }}>I</span> have 3 years of
                  experiment working as a frontend developer in Captivator
                  technologies inc.
                </span>
              </p>

              <p style={{ margin: "10px 1%" }}>
                <b>
                  <i>REMARK </i>:
                </b>
                <span>
                  <span style={{ marginLeft: "10px", marginRight: "5px" }}>
                    Life
                  </span>{" "}
                  is all about growth, Captivator technologies inc. have the
                  ability not only to make you grow mentally as an individual
                  but also to make us grow as nation and as a continent at
                  large.
                </span>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CrewImagesContainer;
