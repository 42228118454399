import React from 'react'

const Sentences = ({ title, sentence }) => {
  return (
    <div>
      <h1 style={{ fontWeight: 'bolder' }}>{title}</h1>
      <p style={{ lineHeight: '2', fontSize: '18px' }}>{sentence}</p>
    </div>
  )
}

export default Sentences
