import React from 'react'
import AgreementInputs from './../components/Leases/AgreementInputs';
import AgreementCheckbox from './../components/Leases/AgreementCheckbox';
import { FaHandshake } from 'react-icons/fa';

import "./styleAgreementPDF.css"
// import { cookies } from 'js-cookie';

const DownLoadHtmlPDF = ({
              leases, 
              valueDateAgreedMonth, 
              valueDateAgreedYear, 
              valueLandlordName, 
              valueEmail,
              valueLandlordCity,
              valueLandlordState,
              valueLandlordCountry,
              valueTenantName,
              valueProfession,
            
            
              valueListCountry,
              valueListState,
              valueListCity,
              valueListAddress,
              valueListType,
              valueAppartNum,
              valueBedrooms,
              valueBathroom,
              valueKitchen,
              citeName,
            
              fixedAgreement,
              renewFixedAgreement,
              continueWithMonthToMonth,
              vacatePremise,
              monthToMonthAgreement
}) => {
              return (
  <div id="thePrintedPDFAgreement" className="agreementSignOuterContainer1">
                            <div>
                            <div className="agreementInnerContainer1">
                          <div className="logoContainer"><img src="/images/logo.png" alt="_"/></div>
                           
                          <p className="agreementInnerHeader"><span>RESIDENTIAL</span> <span style={{marginLeft:"15px"}}>LEASE</span> <span style={{marginLeft:"15px"}}>AGREEMENT</span> <span  style={{marginLeft:"20px"}}>FOR</span> <span style={{marginLeft:"10px"}}>{citeName}</span></p>                    
      <p><b>I.</b> <b>THE PARTIES,</b> This Residential Lease Agreement ("Agreement") made this<div className="agreementDateMonthCon"><AgreementInputs placeholder="eg 28th March" value={valueDateAgreedMonth}/></div>,<div className="agreementDateYearsCon"><AgreementInputs placeholder="2021"value={valueDateAgreedYear}/></div> is between;</p>
                          
                          <p className="agreementLandlordInfoCon"><b >Landlord : </b><div className="inputCon1">
                            <AgreementInputs
                             placeholder="Landlords Name"
                             value={valueLandlordName}
                             />
                            </div>with a mail address of<div className="inputCon2">
                              <AgreementInputs 
                              placeholder="eg a@yahoo.com"
                              value={valueEmail}
                              />
                              </div>, 
                           City of <div className="inputCon3">
                             <AgreementInputs 
                             placeholder="landlords city"
                             value={valueLandlordCity}
                             />
                             </div>,
                           State of <div className="inputCon4">
                             <AgreementInputs 
                             placeholder="landlords State"
                             value={valueLandlordState}
                             />
                             </div>, Country<div className="inputCon5">
                               <AgreementInputs 
                               placeholder="landlords Country"
                               value={valueLandlordCountry}
                               />
                               </div>("Landlord"), <b className="centerTheAnd">AND</b>
                          </p>
                      
                      
                      
                          <p className="agreementTenantInfoCon"><b>Tenant(s): </b>
                          <div className="inputCon1">
                            <AgreementInputs
                             placeholder="Tenants Name"
                             value={valueTenantName}
                             />
                            </div>
                            , Who is 
                            <div className="inputCon2">
                              <AgreementInputs 
                              placeholder="Please Type your Profession"
                              value={valueProfession}
                      
                              /></div>
                              .</p>
                      
                          <p>Landlord and Tenant are hereby referred to as a "Party" and collectively as a "Parties"</p>
                          <p><span>NOW, THEREFORE</span><span style={{marginLeft:"15px"}}><span >, FOR </span><span style={{marginLeft:"7px"}}>AND IN CONSIDERATION</span></span><span style={{marginLeft:"10px"}}> of the mutual promises and agreements contain herein, the Tenant agrees to lease the Premises from the Landlord under the following terms and conditions : </span></p>
                      
                         <p><b>II. LEASE TYPE.</b> This agreement shall be considered a :</p>  
                        <p className="agreementFixedLease">
                          {fixedAgreement&&
                          <>
                            <div className="myFixedLeaseCons">
                          <AgreementCheckbox/>
                          </div>
                          <div className="myFixedLeaseCons">- Fixed Lease. The Tenant shall be allow to occupy the Premises starting on An Agreed Date
                           and ends on An Agreed Date</div>
                         <div className="myFixedLeaseCons">.("Lease Terms").  At the end of the Lease Term and no renewal is made, the Tenant:</div>
                         </> 
                         } 
                      
                      
                        {fixedAgreement&&renewFixedAgreement&&
                        <div className="renewMyFixedAgreement">
                        <AgreementCheckbox />
                          -Renew Your Fixed Lease Agreement.</div>}
                      
                      
                        {fixedAgreement&&continueWithMonthToMonth&&
                        <div className="inputCon5">
                          <AgreementCheckbox/> 
                          -May continue to lease the Premises under the same terms of this agreement under a month-to-month arrangement.
                          </div>
                          }
                      
                      
                          {fixedAgreement&&vacatePremise&&<div className="inputCon6">
                            <AgreementCheckbox/> -Must vacate the Premises</div>}
                          </p>
                      
                      
                        {monthToMonthAgreement&&<p className="agreementMonthToMonth">
                          <div className="inputCon1">
                          <AgreementCheckbox/></div>
                          <div>-Month-to-Month Lease. The Tenant shall be allowed to occupy the Premises on the</div>
                            month-to-month agreement starting on An Agreed Date
                               and ending upon notice  <div className="inputCon4">
                                 <AgreementInputs placeholder="eg 30"/>
                                 </div> days from either Party to the other Party("Lease terms")</p>}
                      
                      
                          <p className="agreementTheProperty">
                            <b>III. THE PROPERTY.</b>
                            <div>The Landlord agrees to lease the described Property below to the Tenant:</div>
                            (Here are the Property Informations)</p>
                          <p className="agreementCountryStateCityCon">
                            <div className="countryStateCon">
                              <b>a) Country</b>
                              <div className="agreementListCountryCon">
                                <AgreementInputs 
                                placeholder="Country"
                                value={valueListCountry}
                                />
                                </div></div>
                          <div className="stateCon">
                            <b>b) State</b>
                          <div className="agreementStateInputCon">
                            <AgreementInputs 
                            placeholder="state"
                            value={valueListState}
                            />
                            </div></div>
                          </p>
                      
                      
                      
                          <p className="agreementCityAddressCon">
                            <div className="cityCon"><b>c) City</b>
                            <div className="agreementCityCon">
                              <AgreementInputs placeholder="City"
                              value={valueListCity}
                              />
                              </div></div>

                          </p>

                          <p className="theAddressOfMyAgreementList">
                          <b style={{display:"inline-block"}}>d) Address</b>
                          <div style={{display:"inline-block"}} className="agreementAddressCon">
                            <AgreementInputs 
                            placeholder="Address"
                            value={valueListAddress?valueListAddress.slice(0,70):""}
                            />
                            </div>
                          </p>

                          <p className="agreementResidentType1">
                            <b>e) Residential Type</b>
                            <div className="residentialTypeInputCon">
                              <AgreementInputs 
                              placeholder="Listing type"
                              value={valueListType}
                              />
                              </div></p>
                          <p className="agreementAppartNum">
                            <b>f) Apartment/studio/office/condos Number</b>
                            <div className="inputCon1">
                              <AgreementInputs 
                              placeholder="Apartment Number to be filled by landlord"
                              value={valueAppartNum}
                             
                           
                              />
                              </div>
                      
                              </p>
                          <p className="agreementBedroom">
                            <b>g) Bedrooms</b><div className="inputCon1">
                              <AgreementInputs 
                              placeholder="Number Of Bedrooms"
                              value={valueBedrooms}
                              />
                              </div></p>  
                          <p className="agreementBathroom">
                            <b>h) Bathrooms</b>
                            <div className="inputCon1">
                              <AgreementInputs 
                              placeholder="Number Of Bathrooms"
                              value={valueBathroom}
                              />
                              </div></p>  
                          <p className="agreementKitchen">
                            <b>i) Kitchen</b>
                            <div className="inputCon1">
                              <AgreementInputs 
                              placeholder="Numbe Of Kitchens"
                              value={valueKitchen}
                              />
                              </div></p>  
                      

                      
                          <div style={{marginLeft:"45%"}}><FaHandshake data-tip="Lease was Signed" style={{fontSize:"50px"}}/></div>
                      
                      
                          </div>
                          
                            </div>
                          </div>
              )
}

export default DownLoadHtmlPDF
