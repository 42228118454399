import React from 'react'
import { FaCreditCard } from 'react-icons/fa'
import { FaCheck } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import { callingCode } from '../../Utils/callingCode'
import PaymentUpdateSpinner from './PaymentUpdateSpinner'

import './payment.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from '@fortawesome/free-solid-svg-icons'

const PaymentMethodModal = ({
  loadingMtnMomo,
  updatedPaymentMethod,
  onClickPaymentMethod,
  selectedPaymentMethod,
  onChangeCountryCode,
  onChangePhoneNumber,
  onhandleSubmit,
  onChangeVisaCardNumber,
  loadingVisaCard,
  onChangePaypal,
  loadingPaypal,
  continue_Bank_acc,
  onClickContinue,
  onChangeVisaCardName,
  onChangeVisaCardCountry,
  onChangeVisaCardBankName,
  valueBankAccountOwnerName,
  valueBankCountry,
  valueBankName,
  valueAccountNumber,
  underConstructionBankPayment,
  underConstructionPaypal,
  valueMomoNumber,
  error,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div
        className='modal fade'
        id='exampleModalChoosePaymentMethod'
        tabIndex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='exampleModalLabel'>
                {t('CHOOSE_A_PAYMENT_METHOD')}
              </h5>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
              >
                <span aria-hidden='true'>&times;</span>
              </button>
            </div>

            <ul className='list-group paymentMethodModalListItem'>
              <li
                onClick={() => onClickPaymentMethod('MTN Mobile Money')}
                className='list-group-item mtnListItem'
              >
                <img style={{ objectFit: 'cover' }} src='/images/mtn.png' />
                {t('MTN_Mobile_Money')}
                {loadingMtnMomo ? (
                  <PaymentUpdateSpinner />
                ) : updatedPaymentMethod.momo_number &&
                  updatedPaymentMethod.momo_number.momo_number ? (
                  <FaCheck className='selectedPaymentIcon' />
                ) : selectedPaymentMethod === 'MTN Mobile Money' ? (
                  <FaCheck className='theIconsMethods' />
                ) : (
                  ''
                )}
              </li>
              <li
                onClick={() => onClickPaymentMethod('Paypal')}
                className='list-group-item paypalListItem'
              >
                <img style={{ objectFit: 'cover' }} src='/images/paypal.jpg' />{' '}
                {t('Paypal')}
                {loadingPaypal ? (
                  <PaymentUpdateSpinner />
                ) : updatedPaymentMethod.visa_card_number &&
                  updatedPaymentMethod.paypal_account.country ? (
                  <FaCheck className='selectedPaymentIcon' />
                ) : selectedPaymentMethod === 'Paypal' ? (
                  <FaCheck className='theIconsMethods' />
                ) : (
                  ''
                )}
              </li>
              <li
                onClick={() => onClickPaymentMethod('Visa Card')}
                className='list-group-item'
              >
                <FaCreditCard className='visaCardPaymentIcon' />
                {t('Bank_Account')}
                {loadingVisaCard ? (
                  <PaymentUpdateSpinner />
                ) : updatedPaymentMethod.visa_card_number &&
                  updatedPaymentMethod.visa_card_number.account_number ? (
                  <FaCheck className='selectedPaymentIcon' />
                ) : selectedPaymentMethod === 'Visa Card' ? (
                  <FaCheck className='theIconsMethods' />
                ) : (
                  ''
                )}
              </li>
            </ul>

            <form onSubmit={onhandleSubmit}>
              {selectedPaymentMethod === 'MTN Mobile Money' && (
                <>
                  <div className='modal-body'>
                    <select
                      onChange={onChangeCountryCode}
                      className='form-control'
                    >
                      <option value=''>{t('country_code')}</option>
                      {callingCode().map((code, index) => (
                        // code: '+358 18', ctry: 'Åland Islands'
                        <option key={`${index}`} value={code.code}>
                          {code.ctry}
                        </option>
                      ))}
                    </select>
                    <input
                      onChange={onChangePhoneNumber}
                      type='number'
                      className='form-control'
                      placeholder={t('Mobile_Money_Number(No_Country_Code)')}
                      value={valueMomoNumber}
                    />
                  </div>
                  <button
                    disabled={loadingMtnMomo}
                    type='submit'
                    className='btn SaveChoosenPaymentBtn'
                  >
                    <span style={{ marginRight: '5px' }}>
                      <FontAwesomeIcon icon={faSave} />
                    </span>
                    {t('Save_Payment_Method')}
                  </button>
                </>
              )}

              {underConstructionBankPayment &&
              selectedPaymentMethod === 'Visa Card' ? (
                <div className='alert alert-info alertBankPaymentUnderConstruction'>
                  {t('Bank_Payment_is_Still_Underconstruction')}
                </div>
              ) : selectedPaymentMethod === 'Visa Card' ? (
                <>
                  <div className='modal-body'>
                    <input
                      value={valueBankAccountOwnerName}
                      onChange={onChangeVisaCardName}
                      className='form-control'
                      placeholder={t('Name')}
                    />
                    <input
                      value={valueBankCountry}
                      onChange={onChangeVisaCardCountry}
                      className='form-control bankCountry'
                      placeholder={t('Country')}
                    />
                  </div>
                  <button
                    onClick={onClickContinue}
                    className='btn continueWithOtherQuestions'
                  >
                    {t('continue')}
                  </button>
                </>
              ) : (
                ''
              )}

              {continue_Bank_acc && (
                <>
                  <div className='modal-body'>
                    <input
                      value={valueBankName}
                      onChange={onChangeVisaCardBankName}
                      className='form-control'
                      placeholder={t('Bank_Name')}
                    />
                    <input
                      value={valueAccountNumber}
                      onChange={onChangeVisaCardNumber}
                      className='form-control accountNumberInput'
                      placeholder={t('Account_Number')}
                    />
                  </div>
                  <button
                    disabled={loadingVisaCard}
                    type='submit'
                    className='btn savePaymentBankAccountBtn'
                  >
                    {t('Save_Payment_Method')}
                  </button>
                </>
              )}

              {underConstructionPaypal && selectedPaymentMethod === 'Paypal' ? (
                <div className='alert alert-info underConstructionPaypalPayment'>
                  {t('Paypal_Payment_is_Still')}
                </div>
              ) : selectedPaymentMethod === 'Paypal' ? (
                <>
                  <div className='modal-body'>
                    <input
                      onChange={onChangePaypal}
                      type='email'
                      className='form-control'
                      placeholder={t('Paypal_account_link')}
                    />
                  </div>
                  <button type='submit' className='btn savePaypalPaymentBtn'>
                    {t('Save_Payment_Method')}
                  </button>
                </>
              ) : (
                ''
              )}
            </form>

            {error && <div className='loadingErrorPaymentMenthod'>{error}</div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentMethodModal
