import { Button, CircularProgress, Divider, TextField } from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import React from "react";

const EditSpaceFormField = ({
  selectedSpace,
  listing,
  onChangeSpaceName,
  onClick,
  loadingEdit,
  editError,
  value,
}) => {
  return selectedSpace.id ? (
    <div className="editSpaceFormFieldCon">
      <p
        style={{
          font: "normal normal bold 14px/26px Merriweather",
          textAlign: "center",
        }}
      >
        {listing.listing_type} - #{selectedSpace.space_number}
      </p>
      <Divider style={{ width: "100%", background: "white" }} />
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minHeight: 150,
        }}
      >
        <TextField
          style={{ width: "100%" }}
          id="filled-basic"
          label={`Edit Space Name from ${
            selectedSpace.space_name
              ? selectedSpace.space_name
              : selectedSpace.space_number
          } to for example: 1A`}
          variant="filled"
          onChange={onChangeSpaceName}
          value={value}
        />

        <Button
          startIcon={<CreateIcon />}
          style={{
            marginTop: "10px",
            backgroundColor: "#035aa6",
            color: "white",
            borderRadius: "20px",
          }}
          variant="contained"
          onClick={onClick}
          disabled={loadingEdit}
        >
          Edit Space Name
        </Button>

        {editError && <p style={{ color: "red", fontSize: 14 }}>{editError}</p>}
        {loadingEdit && (
          <CircularProgress
            style={{ marginTop: 5 }}
            size={25}
            sx={{ color: "#035aa6" }}
          />
        )}
      </div>
    </div>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p style={{ font: "normal normal bold 22px/26px Merriweather" }}>
        No {listing.listing_type} selected Yet
      </p>
    </div>
  );
};

export default EditSpaceFormField;
