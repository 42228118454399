import React from 'react'

const PoliciesMainHeading = () => {
  return (
    <div style={{ width: '100%', opacity: '0.9' }}>
      <p style={{ fontSize: '22px', marginBottom: '5px' }}>
        Privacy Policy of{' '}
        <span style={{ fontWeight: 'bold', color: 'black', opacity: '1' }}>
          Nawafrica.com
        </span>
      </p>
      <p style={{ fontSize: '14px' }}>
        This Website collects some Personal Data from its Users.
      </p>
    </div>
  )
}

export default PoliciesMainHeading
