import React from 'react'

const FacebookIcon = (props) => {
 return (
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="42.054px" height="42.054px" viewBox="0 0 42.054 42.054"
	 xmlSpace="preserve" {...props}>
<g>
	<path d="M42.054,21.168c0,10.001-6.987,18.354-16.344,20.485V24.185h5.053v-5.833h-5.104V17.05c0-2.5,1.094-4.532,3.697-4.532
		c1.043,0,1.877,0.157,2.555,0.365l0.311-6.094c-1.094-0.312-2.445-0.522-4.217-0.522c-2.291,0-5.053,0.68-7.031,2.554
		c-2.293,2.084-3.23,5.469-3.23,8.541v0.989H14.41v5.833h3.333v17.729C7.694,40.334,0,31.661,0,21.168
		C0,9.555,9.415,0.14,21.028,0.14C32.641,0.139,42.054,9.554,42.054,21.168z"/>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
<g>
</g>
</svg>
)
}

export default FacebookIcon
