import React from 'react'
import AddStoryInput from './AddStoryInput'

const PointsInput = ({
  title = 'key point *',
  placeholder = 'Description of point',
  onChange,
  value,
  rows = '10',
  onChangeSubTitle,
  valueSubtitle,
}) => {
  return (
    <div style={{ marginTop: '15px' }}>
      <label
        style={{
          fontWeight: 'bold',
          marginBottom: 0,
          padding: 0,
        }}
        htmlFor={`${title}`}
        className='form-label'
      >
        {title}
      </label>
      <div style={{ margin: '5px 0 10px 0', padding: 0 }}>
        <input
          type='text'
          className='form-control'
          placeholder='Sub title'
          style={{ borderRadius: '10px' }}
          onChange={onChangeSubTitle}
          value={valueSubtitle}
        ></input>
      </div>
      <textarea
        className='form-control'
        id={`${title}`}
        placeholder={placeholder}
        rows={rows}
        style={{ borderRadius: '10px' }}
        onChange={onChange}
        value={value}
      ></textarea>
    </div>
  )
}

export default PointsInput
