import React from "react";
import "simplebar";
import "simplebar/dist/simplebar.css";
import { FaDownload } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaPencilAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { trackEvent } from "../../Utils/useGAEventTracker";

import "./leases.css";
import LoadingSpinner from "./../Applications/LoadingSpinner";

import "./leases.css";
import OwingAnimation from "./OwingAnimation";
import DebtFree from "./DebtFree";
import CloseBtnDeal from "../LeaseSignDetails/CloseBtnDeal";

const TableLeases = ({
  myLeasesApplications,
  loadingSearchLease,
  downloadAgreement,
  leasePDFToDownload,
  listings,
}) => {
  const { t } = useTranslation();

  // console.log('here is the lease', lease)

  const listingCurrency = (lease) => {
    const theList = listings.filter((list) => list.id == lease.listing_id)[0];
    return theList && theList.currency;
  };

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  return (
    <>
      <div data-simplebar className="leasesTableCon">
        <table className="table table-striped">
          <thead className="myListThead">
            <tr>
              <th className="myListTh" scope="col">
                {t("Leases")}
              </th>
              <th className="myListTh" scope="col">
                {t("Landlord")}
              </th>
              <th className="myListTh" scope="col">
                {t("Date_Leased")}
              </th>
              <th className="myListTh" scope="col">
                {t("Total_Amt_Paid")}
              </th>
              <th className="myListTh" scope="col">
                {t("Current_Amt_Owe")}
              </th>
              <th className="myListTh" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {myLeasesApplications.map((lease) => (
              <tr
                style={
                  lease.tenant_close_deal && lease.landlord_close_deal
                    ? { background: "#ffe5b4" }
                    : {}
                }
                key={`${lease.id}`}
                className="table-column"
              >
                <th
                  style={{
                    padding: "7px 0",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="myListTd removeTablePadding"
                >
                  {Number(lease.amount_owed) <= 0 ? (
                    <DebtFree />
                  ) : (
                    <OwingAnimation />
                  )}
                  {!lease.tenant_close_deal && lease.landlord_close_deal && (
                    <span style={{ marginLeft: "5px" }}>
                      <CloseBtnDeal />
                    </span>
                  )}

                  <Link
                    style={
                      Number(lease.amount_owed) <= 0
                        ? {
                            width: "100%",
                            padding: "0",
                            margin: "0",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            lineClamp: 1,
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                          }
                        : {
                            width: "100%",
                            padding: "0",
                            margin: "0",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            lineClamp: 1,
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                          }
                    }
                    onClick={() =>
                      trackEvent(
                        "link that goes to leasing details",
                        `/portal/tenant/leases/${lease.id}`,
                        "leasing detail link"
                      )
                    }
                    className={
                      Number(lease.amount_owed) <= 0
                        ? "goToLeaseSignDetails"
                        : "goToLeaseSignDetails1"
                    }
                    to={`/portal/tenant/leases/${lease.id}`}
                  >
                    {lease &&
                    lease.listing_name &&
                    lease.listing_name.length > 20
                      ? lease.listing_name &&
                        capitalize(lease.listing_name.slice(0, 18) + "..")
                      : lease.listing_name && capitalize(lease.listing_name)}
                  </Link>
                </th>
                <td
                  style={{ padding: "7px 0" }}
                  className="myListTd removeTablePadding"
                >
                  <Link
                    style={
                      Number(lease.amount_owed) <= 0
                        ? {
                            width: "100%",
                            padding: "0",
                            margin: "0",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            lineClamp: 1,
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                          }
                        : {
                            width: "100%",
                            padding: "0",
                            margin: "0",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            lineClamp: 1,
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                          }
                    }
                    onClick={() =>
                      trackEvent(
                        "link that goes to leasing details",
                        `/portal/tenant/leases/${lease.id}`,
                        "leasing detail link"
                      )
                    }
                    className={
                      Number(lease.amount_owed) <= 0
                        ? "goToLeaseSignDetails"
                        : "goToLeaseSignDetails1"
                    }
                    to={`/portal/tenant/leases/${lease.id}`}
                  >
                    {lease.list_owner_name && lease.list_owner_name.length > 18
                      ? lease &&
                        lease.list_owner_name &&
                        capitalize(lease.list_owner_name.slice(0, 18) + "..")
                      : lease && capitalize(lease.list_owner_name)}
                  </Link>
                </td>

                <td
                  style={{ padding: "7px 0" }}
                  className="myListTd removeTablePadding"
                >
                  <Link
                    style={
                      Number(lease.amount_owed) <= 0
                        ? {
                            width: "100%",
                            padding: "0",
                            margin: "0",
                          }
                        : {
                            width: "100%",
                            padding: "0",
                            margin: "0",
                          }
                    }
                    onClick={() =>
                      trackEvent(
                        "link that goes to leasing details",
                        `/portal/tenant/leases/${lease.id}`,
                        "leasing detail link"
                      )
                    }
                    className={
                      Number(lease.amount_owed) <= 0
                        ? "goToLeaseSignDetails"
                        : "goToLeaseSignDetails1"
                    }
                    to={`/portal/tenant/leases/${lease.id}`}
                  >
                    {lease.create_date.slice(0, 10)}
                  </Link>
                </td>
                <td
                  style={
                    Number(lease.amount_owed) <= 0
                      ? { padding: "7px 0" }
                      : { padding: "7px 0", color: "red" }
                  }
                  className={
                    Number(lease.amount_owed) <= 0
                      ? "myListTd removeTablePadding"
                      : "myListTd removeTablePadding"
                  }
                >
                  {lease.total_amount_paid
                    ? `${Math.round(lease.total_amount_paid * 100) / 100}`
                        .length > 8
                      ? commaSeparateNumber(
                          `${
                            Math.round(lease.total_amount_paid * 100) / 100
                          }`.slice(0, 7)
                        ) + "..."
                      : commaSeparateNumber(
                          Math.round(lease.total_amount_paid * 100) / 100
                        )
                    : 0}
                  {listingCurrency(lease)}
                </td>
                <td
                  style={
                    Number(lease.amount_owed) <= 0
                      ? { padding: "7px 0" }
                      : { padding: "7px 0", color: "red" }
                  }
                  className={
                    Number(lease.amount_owed) <= 0
                      ? "myListTd removeTablePadding"
                      : "myListTd removeTablePadding"
                  }
                >
                  {lease.amount_owed
                    ? `${lease.amount_owed}`.startsWith("-")
                      ? 0
                      : `${Math.round(lease.amount_owed * 100) / 100}`.length >
                        8
                      ? commaSeparateNumber(
                          `${Math.round(lease.amount_owed * 100) / 100}`.slice(
                            0,
                            8
                          )
                        ) + "..."
                      : `${lease.amount_owed}`.startsWith("-")
                      ? 0
                      : commaSeparateNumber(
                          Math.round(lease.amount_owed * 100) / 100
                        )
                    : 0}
                  {listingCurrency(lease)}
                </td>
                <td
                  style={{ padding: "7px 0" }}
                  className="myListTd removeTablePadding"
                >
                  <div className="myListDeleteBtnLease">
                    {/* <button
                      className='deleteBtn'
                      onClick={() => {
                        trackEvent(
                          'delete lease',
                          `tenant delete lease ${lease.id}`,
                          'Delete lease Btn '
                        )
                        onDelete(lease)
                      }}
                    >
                      {t('Delete')}
                    </button> */}
                    {lease.id === leasePDFToDownload ? (
                      <div className="loadingDownloadSignedLease">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <button
                        className="downloadingAgreementInLeaseSignedTable"
                        onClick={() => downloadAgreement(lease)}
                      >
                        <FaDownload className="leaseSignedAgreementIcon" />{" "}
                        {t("Agreement")}
                      </button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
            {loadingSearchLease && <div>.....loading</div>}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableLeases;
