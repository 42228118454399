import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AOS from "aos";
import "aos/dist/aos.css";
import Progress from "react-progressbar";

import FacebookIcon from "../../iconComponent/FacebookIcon";
import TweeterIcon from "../../iconComponent/TweeterIcon";
import Whatsapp from "../../iconComponent/Whatsapp";
import Youtube from "../../iconComponent/Youtube";

import { registerNewsLetter } from "../../Services/newsletter";

import { trackEvent } from "../../Utils/useGAEventTracker";

import "./styleCompo.css";
import { useState } from "react";
import AlertCustomise from "./AlertCustomise";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faEnvelopeOpenText,
} from "@fortawesome/free-solid-svg-icons";

import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const Footer2 = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgess] = useState(0);

  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  const onRegisterNews = async (e) => {
    e.preventDefault();
    try {
      await registerNewsLetter(email, (p) => setProgess(p * 100));
      setLoaded(true);
      setEmail("");
      setProgess(0);
      setInterval(() => {
        setLoaded(false);
      }, 15000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setProgess(0);
        setError(ex.response.data);
      }
    }
  };

  return (
    <>
      {loaded && (
        <div
          style={{
            position: "fixed",
            top: "100px",
            // right: '50%',
            // transform: 'translateX(-50%)',
            width: "100%",
            height: "100%",
          }}
        >
          <AlertCustomise
            data={
              "Thanks for applying for our Newsletter, You will be updated about all our recent development"
            }
          />
        </div>
      )}
      <div className="footer2OuterCon">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 1, md: 1 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={4} md={3} lg={3}>
              <div className="solutionContainer">
                <p className="title">
                  <b>{t("SOLUTION")}</b>
                </p>
                <p style={{ lineHeight: "1.5" }} className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "rent and sell platform",
                        "/rent-and-sale-platform",
                        "Footer Link"
                      )
                    }
                    className="footer2Atag"
                    to="/rent-and-sale-platform"
                  >
                    {t("Property_Rentals_And_Sales_Platform")}
                  </Link>{" "}
                </p>
                <p className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "consulting service Link",
                        "/consulting-service",
                        "Footer Link"
                      )
                    }
                    className="footer2Atag"
                    to="/consulting-service"
                  >
                    {t("Consulting_Services")}
                  </Link>
                </p>
                <p className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "property manage Link",
                        "/property-management",
                        "Footer Link"
                      )
                    }
                    className="footer2Atag"
                    to="/property-management"
                  >
                    {t("Property_Management")}
                  </Link>
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={3}>
              <div className="aboutUsContainer">
                <p className="title">
                  <b>{t("ABOUT_US")}</b>
                </p>
                <p className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent("About us Link", "/about-us", "Footer link")
                    }
                    className="footer2Atag"
                    to="/about-us"
                  >
                    {t("About_us")}
                  </Link>{" "}
                </p>
                <p className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent("our story link", "/our-story", "footer link")
                    }
                    className="footer2Atag"
                    to="/our-story"
                  >
                    {t("Our_Story")}
                  </Link>
                </p>
                <p className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent("careers link", "/careers", "Footer")
                    }
                    className="footer2Atag"
                    to="/careers"
                  >
                    {t("Careers")}
                  </Link>
                </p>
                <p className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent("blog Link", "/blog", "Footer link")
                    }
                    className="footer2Atag"
                    to="/blog"
                  >
                    {t("Blog")}
                  </Link>
                </p>
                <p className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent("newsroom link", "/newsroom", "footer link")
                    }
                    className="footer2Atag"
                    to="/newsroom"
                  >
                    {t("Newsroom")}
                  </Link>
                </p>
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={3}>
              <div className="contactUsContainer">
                <p className="title">
                  <b>{t("CONTACT_US")}</b>
                </p>
                <p className="footerParaLink">
                  <Link
                    onClick={() =>
                      trackEvent(
                        "contact us now",
                        "/contactUsNow",
                        "Footer Link"
                      )
                    }
                    className="footer2Atag"
                    to="/contactUsNow"
                  >
                    {t("Contact_us_now")}
                  </Link>{" "}
                </p>
                <p>
                  <a
                    onClick={() =>
                      trackEvent(
                        "support nawafrica",
                        "https://www.support@nawafrica.com",
                        "footer link"
                      )
                    }
                    className="theInfoNawafrica"
                    href="https://www.support@nawafrica.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    support@nawafrica.com
                  </a>
                </p>
                <p
                  style={{
                    fontSize: "16px",
                  }}
                >
                  <Link
                    onClick={() =>
                      trackEvent(
                        "contact us now",
                        "/contactUsNow",
                        "Footer Link"
                      )
                    }
                    className="footer1Atag btn faqFooter2IconBtn"
                    to="/faq"
                  >
                    <span style={{ marginRight: "5px" }}>
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </span>
                    FAQ
                  </Link>
                </p>

                <div className="contactUsLinksCon">
                  <div style={{ flex: "1" }}>
                    <a
                      onClick={() =>
                        trackEvent(
                          "facebook page Link",
                          "https://www.facebook.com/nawafrica",
                          "footer link"
                        )
                      }
                      className="twitFacebookChatLink"
                      href="https://www.facebook.com/nawafrica"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon className="facebookIconFooter2" />
                    </a>
                  </div>
                  <div style={{ flex: "1" }}>
                    <a
                      onClick={() =>
                        trackEvent(
                          "tweeter page link",
                          "https://www.tweeter.org/nawafrica",
                          "footer link"
                        )
                      }
                      className="twitFacebookChatLink"
                      href="https://www.tweeter.org/nawafrica"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <TweeterIcon className="facebookIconFooter2 " />
                    </a>
                  </div>
                  <div style={{ flex: "1" }}>
                    <a
                      onClick={() =>
                        trackEvent(
                          "whatsapp link",
                          "https://wa.me/237650432299",
                          "footer Link"
                        )
                      }
                      className="twitFacebookChatLink"
                      href="https://wa.me/237650432299"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Whatsapp className="facebookIconFooter2 " />
                    </a>
                  </div>
                  <div style={{ flex: "1" }}>
                    <a
                      className="youtubeAtagFooter2"
                      href="https://www.youtube.com/nawafrica"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() =>
                        trackEvent(
                          "youtube page link",
                          "https://www.youtube.com/nawafrica",
                          "footer link"
                        )
                      }
                    >
                      <Youtube className="facebookIconFooter2" />
                    </a>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} sm={4} md={3} lg={3}>
              <div className="newletterContainer">
                <p className="title">
                  <b>{t("SUBSCRIBE_TO_OUR_NEWSLETTER")}</b>
                </p>
                <p className="footerParaLink" style={{ lineHeight: "1.5" }}>
                  {t("SUBSCRIBE_TO_OUR_NEWSLETTER_P1")}
                </p>
                <p className="footerParaLink">
                  {t("SUBSCRIBE_TO_OUR_NEWSLETTER_P2")}
                </p>
                <form onSubmit={onRegisterNews}>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder={t("Enter your email address")}
                    value={email}
                    required
                  />

                  <button>
                    <span style={{ marginRight: "5px" }}>
                      <FontAwesomeIcon icon={faEnvelopeOpenText} />
                    </span>
                    {t("Sign_Up")}
                  </button>
                  {error && (
                    <p style={{ color: "red", fontSize: "12px" }}>{error}</p>
                  )}
                  {progress > 0 && (
                    <div className="addListingProgressBarCon">
                      <Progress color="#035aa6" completed={progress} />
                      <div>{progress}%</div>
                    </div>
                  )}
                </form>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>

      <div className="terms_copy_container">
        <div className="copyContainer">
          <h5>
            {t("Copyright")}{" "}
            <i className="fa fa-copyright" aria-hidden="true"></i> 2021&copy;
            {t("Copyright_det")}
          </h5>
        </div>
        <div className="policyContainer">
          <Link className="footer1Atag" to="/terms-of-use">
            <h5>{t("Terms of Use")}</h5>
          </Link>
          <span>|</span>
          <Link className="footer1Atag" to="/policies">
            <h5>{t("term_policy")}</h5>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Footer2;
