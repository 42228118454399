import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '90vh',
      width: 250,
    },
  },
};


const TextInputKeywordAdd = ({
        items=[], 
        placeholder="Tag",
        keyword,
        handleChange,
        label,
        id
    }) => {

    // const handleChange = (event) => {
    //   const {
    //     target: { value },
    //   } = event;
    //   setPersonName(
    //     // On autofill we get a stringified value.
    //     typeof value === 'string' ? value.split(',') : value,
    //   );
    // };

  return (
    <div className='textInputKeywordAdd'>
        <label className='addListSelectLabel50' htmlFor={id}>
        {label}
      </label>
      <FormControl sx={{width: "100%"}}>
      <InputLabel id="demo-multiple-checkbox-label">{placeholder}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={keyword}
          onChange={handleChange}
          input={<OutlinedInput 
            style={{
            background:"white", 
            height:"45px",
            borderradius: "5px",
            border: "1px solid #bcbcbc",
            border: "1px solid #bcbcbc",
            background: "#f5f5f5 0% 0% no-repeat padding-box",
            color: "#222222",
            letterSpacing: "0.9px",
            font: "normal normal normal 14px/24px Merriweather",
            cursor: "pointer",
        }} 
            label="Tag" />
        }
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {items.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={keyword.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default TextInputKeywordAdd