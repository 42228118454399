import React from 'react'
import PoliciesSubHeading from '../Policies/PoliciesSubHeading'

const ModePlace = () => {
  return (
    <div style={{ width: '100%' }}>
      <PoliciesSubHeading childern='Mode and place of processing the Data' />
      <p
        style={{
          marginTop: '20px',
          fontSize: '14px',
          fontWeight: 'bold',
          marginBottom: '0',
          opacity: '0.9',
        }}
      >
        Methods of processing
      </p>
      <div style={{ width: '100%', fontWeight: '0.9' }}>
        The Owner takes appropriate security measures to prevent unauthorized
        access, disclosure, modification, or unauthorized destruction of the
        Data.
      </div>
      <div>
        The Data processing is carried out using computers and/or IT enabled
        tools, following organizational procedures and modes strictly related to
        the purposes indicated. In addition to the Owner, in some cases, the
        Data may be accessible to certain types of persons in charge, involved
        with the operation of this Website (administration, sales, marketing,
        legal, system administration) or external parties (such as third-party
        technical service providers, mail carriers, hosting providers, IT
        companies, communications agencies) appointed, if necessary, as Data
        Processors by the Owner. The updated list of these parties may be
        requested from the Owner at any time.
      </div>
      <p
        style={{
          marginTop: '20px',
          fontSize: '14px',
          fontWeight: 'bold',
          marginBottom: '0',
          opacity: '0.9',
        }}
      >
        Legal basis of processing
      </p>
      <div style={{ opacity: '0.9' }}>
        The Owner may process Personal Data relating to Users if one of the
        following applies:
      </div>
      <ul style={{ opacity: '0.9', fontSize: '14px', opacity: '0.9' }}>
        <li>
          Users have given their consent for one or more specific purposes.
          Note: Under some legislations the Owner may be allowed to process
          Personal Data until the User objects to such processing (“opt-out”),
          without having to rely on consent or any other of the following legal
          bases. This, however, does not apply, whenever the processing of
          Personal Data is subject to European data protection law;
        </li>
        <li>
          provision of Data is necessary for the performance of an agreement
          with the User and/or for any pre-contractual obligations thereof;
        </li>
        <li>
          processing is necessary for compliance with a legal obligation to
          which the Owner is subject;
        </li>
        <li>
          processing is related to a task that is carried out in the public
          interest or in the exercise of official authority vested in the Owner;
        </li>
        <li>
          processing is necessary for the purposes of the legitimate interests
          pursued by the Owner or by a third party.
        </li>
      </ul>

      <div style={{ width: '100%', marginTop: '10px', opacity: '0.9' }}>
        In any case, the Owner will gladly help to clarify the specific legal
        basis that applies to the processing, and in particular whether the
        provision of Personal Data is a statutory or contractual requirement, or
        a requirement necessary to enter into a contract.
      </div>
      <div
        style={{
          marginTop: '20px',
          fontSize: '14px',
          fontWeight: 'bold',
          opacity: '0.9',
        }}
      >
        Place
      </div>
      <p style={{ fontSize: '14px', marginTop: '0', opacity: '0.9' }}>
        The Data is processed at the Owner's operating offices and in any other
        places where the parties involved in the processing are located.
      </p>
      <p style={{ fontSize: '14px', opacity: '0.9' }}>
        Depending on the User's location, data transfers may involve
        transferring the User's Data to a country other than their own. To find
        out more about the place of processing of such transferred Data, Users
        can check the section containing details about the processing of
        Personal Data.
      </p>
      <p style={{ fontSize: '14px', opacity: '0.9' }}>
        If broader protection standards are applicable, Users are also entitled
        to learn about the legal basis of Data transfers to a country outside
        the European Union or to any international organization governed by
        public international law or set up by two or more countries, such as the
        UN, and about the security measures taken by the Owner to safeguard
        their Data.
      </p>
      <p style={{ fontSize: '14px', opacity: '0.9' }}>
        If any such transfer takes place, Users can find out more by checking
        the relevant sections of this document or inquire with the Owner using
        the information provided in the contact section.
      </p>
      <p
        style={{
          marginTop: '20px',
          fontSize: '14px',
          fontWeight: 'bold',
          marginBottom: '0',
          opacity: '0.9',
        }}
      >
        Retention time
      </p>
      <p style={{ fontSize: '14px', opacity: '0.9' }}>
        Personal Data shall be processed and stored for as long as required by
        the purpose they have been collected for.
      </p>
      <p style={{ fontSize: '14px', opacity: '0.9' }}>Therefore:</p>
      <ul style={{ fontSize: '14px', opacity: '0.9' }}>
        <li>
          Personal Data collected for purposes related to the performance of a
          contract between the Owner and the User shall be retained until such
          contract has been fully performed.
        </li>
        <li>
          Personal Data collected for the purposes of the Owner’s legitimate
          interests shall be retained as long as needed to fulfill such
          purposes. Users may find specific information regarding the legitimate
          interests pursued by the Owner within the relevant sections of this
          document or by contacting the Owner.
        </li>
      </ul>
      <p style={{ fontSize: '14px', opacity: '0.9' }}>
        The Owner may be allowed to retain Personal Data for a longer period
        whenever the User has given consent to such processing, as long as such
        consent is not withdrawn. Furthermore, the Owner may be obliged to
        retain Personal Data for a longer period whenever required to do so for
        the performance of a legal obligation or upon order of an authority.
      </p>
      <p style={{ fontSize: '14px', opacity: '0.9' }}>
        Once the retention period expires, Personal Data shall be deleted.
        Therefore, the right of access, the right to erasure, the right to
        rectification and the right to data portability cannot be enforced after
        expiration of the retention period.
      </p>
    </div>
  )
}

export default ModePlace
