import React, { useEffect, useState } from 'react'
import LatestPress from '../components/Newsroom/LatestPress'
import NewsHead from '../components/Newsroom/NewsHead'
import { deleteNewsById, getNews } from '../Services/newsService'
import Meta from '../Utils/Meta'

import logger from '../Services/loggerService'

const NewsroomResearch = () => {
  const [news, setNews] = useState([])
  const [loading, setloading] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
  }, [])

  const getData = async () => {
    try {
      setloading(true)
      let { data } = await getNews()
      data = data.filter((d) => d.catergory === "Nawafrica's Research")
      setNews(data)
      setloading(false)
    } catch (error) {
      logger.log(error)
    }
  }

  const onClickNewsDeleted = async (id) => {
    try {
      const remainingNews = news.filter((n) => n.id !== id)
      setNews(remainingNews)
      await deleteNewsById(id)
    } catch (error) {
      logger.log(error)
    }
  }
  return (
    <div className='newsAwareCon'>
      <Meta title={`Nawafrica | newsroom`} />

      <NewsHead />
      <LatestPress
        header='Latest Research on Technology And Real Estate'
        research={true}
        news={news}
        loading={loading}
        onClickNewsDeleted={onClickNewsDeleted}
      />
    </div>
  )
}

export default NewsroomResearch
