import React from 'react'

const PropManageCard = ({
  src = '../../images/it-team.jpg',
  content = 'We provide end-to-end property management services for residential housing, apartment homes, furnished corporate housing, and mixed-use properties incorporating retail space. We focus on the need to serve our owners, employees, residents and vendors. We are composed of people with diverse educational backgrounds, work experiences and personal interests. We are all driven by a unique enthusiasm, a passion and a commitment of being part of a team and reaching a higher level of excellence.',
}) => {
  return (
    <div className='propManCardOuterCon'>
      <div className='imageCon'>
        <img
          style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          src={src}
        />
      </div>
      <div className='propManCardContentCon'>{content}</div>
    </div>
  )
}

export default PropManageCard
