import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import ListingBarChart from "./ListingBarChart";
import ListingLineChart from "./ListingLineChart";
import { barData, lineData } from "./datas";
import { Divider, IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import ListingTenants from "./ListingTenants";
import { useTranslation } from "react-i18next";

const ListingAccordion = ({
  listings = [],
  onSelectLising,
  years,
  barChartData,
  grossPayment = 0,
  tenants = [],
  lineChartData,
  selectedListing,
}) => {
  const { t } = useTranslation();
  const [toggle, setToggle] = React.useState(false);

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  return (
    <div style={{ marginTop: 20 }}>
      {listings.map((list) => (
        <Accordion
          expanded={list.id === selectedListing?.id}
          key={list.id}
          onClick={() => onSelectLising(list)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel1-header"
            style={{ backgroundColor: "#f1f1f1", fontWeight: "bold" }}
            // expanded={list.id === selectedListing?.id}
          >
            {capitalize(list.title)}
          </AccordionSummary>
          <AccordionDetails
            style={{
              minHeight: 400,
              width: "100%",
              padding: 10,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
            expanded={false}
          >
            {toggle ? (
              <ListingBarChart
                data={barData}
                years={years}
                barChartData={barChartData}
                listing={list}
              />
            ) : (
              <ListingLineChart
                data={lineData}
                lineChartData={lineChartData}
                currency={list.currency}
              />
            )}
            <IconButton
              style={{
                position: "absolute",
                zIndex: 4,
                background: "",
                top: 5,
                right: 5,
                backgroundColor: "#f1f1f1",
              }}
              onClick={() => setToggle(!toggle)}
            >
              <FontAwesomeIcon
                style={{ color: "#035aa6" }}
                className="picModalCancelIcon2"
                icon={faCopy}
              />
            </IconButton>

            <Divider style={{ width: "100%", marginTop: 10 }} />
            <div style={{ marginTop: 10, width: "100%" }}>
              <span style={{ fontWeight: "bold", color: "#035aa6" }}>
                {" "}
                {t("Total gross income since posted")} :{" "}
              </span>
              <span style={{ color: "#ffa500", fontWeight: "bold" }}>
                {commaSeparateNumber(grossPayment)} {list.currency}
              </span>
            </div>
            <div style={{ width: "100%" }}>
              <span style={{ fontWeight: "bold", color: "#035aa6" }}>
                {" "}
                {t("Total number of tenants since posted")} :{" "}
              </span>
              <span style={{ color: "#ffa500", fontWeight: "bold" }}>
                {tenants.length}
              </span>
            </div>
            <div style={{ width: "100%" }}>
              <span style={{ fontWeight: "bold", color: "#035aa6" }}>
                {" "}
                {t("Expected gross income per year")} :{" "}
              </span>
              <span style={{ color: "#ffa500", fontWeight: "bold" }}>
                {list.rent_or_sell === "Rental"
                  ? `${commaSeparateNumber(
                      list.price * list.quantity_of_space * 12
                    )} ${list.currency}`
                  : `${commaSeparateNumber(
                      list.price * list.quantity_of_space
                    )} ${list.currency}`}{" "}
              </span>
            </div>
            {/* <Divider style={{ width: "100%", marginTop: 10 }} />
            <ListingTenants tenants={tenants} listing={list} /> */}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default ListingAccordion;
