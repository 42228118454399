import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Typical from 'react-typical'

import './underConstruction.css'

const UnderContruction = () => {
  const [daysLeft, setDaysLeft] = useState(271)
  const [dateDiff, setDateDiff] = useState(0)

  useEffect(() => {
    const getDayNow = async () => {
      //  const {data}=await getConstructionDay()
      const postedDate = moment(parseInt(`1631819172315`))
      const nowDate = moment(Date.now())
      const diffInDay = nowDate.diff(postedDate, 'days')
      setDateDiff(diffInDay)
    }
    getDayNow()
  }, [])

  return (
    <div
      style={{
        backgroundImage:
          "url('/images/white-and-brown-concrete-bungalow-under-clear-blue-sky-210617.jpg')",
      }}
      className='underConstructionContainer'
    >
      <Typical
        steps={[2000, 'NawAfrica is still under construction', 3000, '.', 2000]}
        loop={Infinity}
        wrapper='h1'
        className='constructing'
      />

      <div className='topleft'>
        <img src='/images/logo.png' />
      </div>
      <div className='middle'>
        <h1>COMING SOON</h1>
        <hr />
        <p>{daysLeft - dateDiff} days left</p>
      </div>
    </div>
  )
}

export default UnderContruction
