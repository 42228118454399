export const barData = [
  {
    country: "AD",
    "hot dog": 51,
    "hot dogColor": "hsl(338, 70%, 50%)",
    burger: 3,
    burgerColor: "hsl(86, 70%, 50%)",
    sandwich: 0,
    sandwichColor: "hsl(119, 70%, 50%)",
    kebab: 184,
    kebabColor: "hsl(301, 70%, 50%)",
    fries: 122,
    friesColor: "hsl(293, 70%, 50%)",
    donut: 153,
    donutColor: "hsl(63, 70%, 50%)",
  },
  {
    country: "AE",
    "hot dog": 55,
    "hot dogColor": "hsl(103, 70%, 50%)",
    burger: 192,
    burgerColor: "hsl(298, 70%, 50%)",
    sandwich: 82,
    sandwichColor: "hsl(337, 70%, 50%)",
    kebab: 173,
    kebabColor: "hsl(346, 70%, 50%)",
    fries: 75,
    friesColor: "hsl(97, 70%, 50%)",
    donut: 2,
    donutColor: "hsl(258, 70%, 50%)",
  },
  {
    country: "AF",
    "hot dog": 25,
    "hot dogColor": "hsl(208, 70%, 50%)",
    burger: 117,
    burgerColor: "hsl(136, 70%, 50%)",
    sandwich: 41,
    sandwichColor: "hsl(126, 70%, 50%)",
    kebab: 67,
    kebabColor: "hsl(215, 70%, 50%)",
    fries: 118,
    friesColor: "hsl(259, 70%, 50%)",
    donut: 169,
    donutColor: "hsl(75, 70%, 50%)",
  },
  {
    country: "AG",
    "hot dog": 173,
    "hot dogColor": "hsl(212, 70%, 50%)",
    burger: 4,
    burgerColor: "hsl(345, 70%, 50%)",
    sandwich: 194,
    sandwichColor: "hsl(225, 70%, 50%)",
    kebab: 27,
    kebabColor: "hsl(193, 70%, 50%)",
    fries: 139,
    friesColor: "hsl(349, 70%, 50%)",
    donut: 163,
    donutColor: "hsl(233, 70%, 50%)",
  },
  {
    country: "AI",
    "hot dog": 40,
    "hot dogColor": "hsl(115, 70%, 50%)",
    burger: 160,
    burgerColor: "hsl(124, 70%, 50%)",
    sandwich: 138,
    sandwichColor: "hsl(274, 70%, 50%)",
    kebab: 56,
    kebabColor: "hsl(98, 70%, 50%)",
    fries: 100,
    friesColor: "hsl(138, 70%, 50%)",
    donut: 142,
    donutColor: "hsl(249, 70%, 50%)",
  },
  {
    country: "AL",
    "hot dog": 109,
    "hot dogColor": "hsl(359, 70%, 50%)",
    burger: 110,
    burgerColor: "hsl(56, 70%, 50%)",
    sandwich: 120,
    sandwichColor: "hsl(329, 70%, 50%)",
    kebab: 88,
    kebabColor: "hsl(308, 70%, 50%)",
    fries: 59,
    friesColor: "hsl(282, 70%, 50%)",
    donut: 134,
    donutColor: "hsl(14, 70%, 50%)",
  },
  {
    country: "AM",
    "hot dog": 127,
    "hot dogColor": "hsl(244, 70%, 50%)",
    burger: 56,
    burgerColor: "hsl(261, 70%, 50%)",
    sandwich: 68,
    sandwichColor: "hsl(290, 70%, 50%)",
    kebab: 15,
    kebabColor: "hsl(94, 70%, 50%)",
    fries: 94,
    friesColor: "hsl(113, 70%, 50%)",
    donut: 140,
    donutColor: "hsl(213, 70%, 50%)",
  },
];

export const lineData = [
  {
    id: "years",
    color: "hsl(290, 70%, 50%)",
    data: [
      {
        x: "2024",
        y: 243,
      },
      {
        x: "2023",
        y: 244,
      },
      {
        x: "2022",
        y: 173,
      },
      {
        x: "2021",
        y: 292,
      },
      {
        x: "2020",
        y: 262,
      },
      {
        x: "2019",
        y: 197,
      },
      {
        x: "2018",
        y: 217,
      },
      {
        x: "2017",
        y: 29,
      },
      {
        x: "2016",
        y: 61,
      },
      {
        x: "2015",
        y: 101,
      },
      {
        x: "2014",
        y: 61,
      },
    ],
  },
];
