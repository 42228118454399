import React from "react";

const ConsultantServiceHead = () => {
  return (
    <div
      style={{ backgroundImage: "url(/images/consulting.jpeg)" }}
      className="consultantSerHeadOuterCon"
    >
      <h1 className="consultant">Consulting Services</h1>

      <div className="consultSerArrowIcon">
        <div className="scroll-arrow">
          <svg width="30px" height="20px">
            <path
              stroke="#ffffff"
              fill="none"
              strokeWidth="2px"
              d="M2.000,5.000 L15.000,18.000 L28.000,5.000 "
            ></path>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ConsultantServiceHead;
