import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import TenantAnylyticManage from "./TenantAnylyticManage";
import BarChartIcon from "@mui/icons-material/BarChart";
import { useTranslation } from "react-i18next";

const TenantAnalytics = ({
  myTenants = [],
  myListings = [],
  availableSpaces = [],
  users = [],
  onChangeListingTitle,
  onChangeListingType,
  onChangeListingCountry,
  onChangeListingCity,
  onChangeTenantName,
}) => {
  const {t} = useTranslation()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      className="listingAnalyticsContainer1"
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      {/* <p
        style={{
          textAlign: "center",
          fontWeight: "bold",
          marginTop: 7,
          color: "#035aa6",
        }}
      >
        Rooms Tenants Analytics For Your Listings
      </p>
      <Divider style={{ width: "100%" }} /> */}
      <div style={{ width: "100%", marginTop: 20 }}>
        {/* <TenantAccordion tenants={tenants} /> */}
        <TenantAnylyticManage
          myTenants={myTenants}
          listings={myListings}
          availableSpaces={availableSpaces}
          users={users}
          onChangeListingTitle={onChangeListingTitle}
          onChangeListingType={onChangeListingType}
          onChangeListingCountry={onChangeListingCountry}
          onChangeListingCity={onChangeListingCity}
          onChangeTenantName={onChangeTenantName}
        />
      </div>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            style={{
              textTransform: "none",
              border: "1px solid #035aa6",
              borderRadius: "5px",
              padding: "5px 10px",
              backgroundColor: "#ffffff",
              color: "#035aa6",
              boxShadow: "3px 1px 3px 0.5px black",
            }}
            startIcon={<BarChartIcon />}
            onClick={toggleDrawer(anchor, true)}
          >
            {t("Tenants Analytics")}
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
};

export default TenantAnalytics;
