import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Sidebar from "../components/AddListing/Sidebar";
import OpenButton from "../components/AddListing/OpenButton";
import WelcomeUser from "../components/AddListing/WelcomeUser";
import Chats from "../components/AddListing/Chats";
import TitleListing from "../components/Listing/TitleListing";
import Search from "../components/Listing/Search";
import Table from "../components/Listing/Table";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import {
  getApplications,
  getListings,
  publishAListing,
} from "../Services/listings";
import { getCurrentUser } from "../Services/authService";
import { deleteAListingByUpdateStatus } from "./../Services/listings";
import UnreadMsgsContext from "./../unreadmessages/context";
import { getLang } from "../Utils/getLang";
import { trackEvent } from "../Utils/useGAEventTracker";
import ProfileSpinner from "./../components/profile/ProfileSpinner";
import Meta1 from "../Utils/Meta1";
import "../components/Listing/listing.css";
import { getLandlordsPayments } from "../Services/payment";
import { getTenantById } from "../Services/tenantService";

const ListingScreen = ({ history }) => {
  const { t } = useTranslation();

  const [close, setClose] = useState(true);
  const [path, setPath] = useState("");
  const [upSideBar, setUpSideBar] = useState(false);

  const [myListings, setMyListings] = useState([]);
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [applyError, setApplyError] = useState("");

  const [sortColumn, setSortColumn] = useState({
    path: "title",
    order: "desc",
  });
  const [sortColumns, setSortColumns] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [loadingSearchListings, setLoadingSearchListings] = useState(false);
  const [fromSearch, setFromSearch] = useState(false);

  const { unreadMsgs, myListings: liste } = useContext(UnreadMsgsContext);

  const [years, setYears] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [totalPayment, setTotalPayment] = useState([]);
  const [grossPayment, setGrossPayment] = useState("");
  const [grossPaymentAllListing, setGrossPaymentAllListing] = useState("");
  const [allMyPayments, setAllPayments] = useState([]);
  const [allMyTenants, setAllMyTenants] = useState([]); //all my tenants
  const [tenants, setTenants] = useState([]);
  const [tenants2, setTenants2] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [selectedListing, setSelectedListing] = useState({});

  useEffect(() => {
    if (
      getCurrentUser() &&
      getCurrentUser().post_rent_property === "LOOKING TO BUY/RENT PROPERTY"
    )
      return history.push("/");
    localStorage.setItem("portalPath", "/portal/list/listing");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    window.scrollTo(0, 0);
    getAllListings();
    return () => {
      localStorage.setItem("portalPath", "");
    };
  }, []);

  //function to get all listings posted by a given landlord
  const getAllListings = async () => {
    try {
      //get All Listing
      setLoading(true);
      const { data: allListings } = await getListings();
      const usersListings = allListings.filter(
        (listing) => listing.list_owner_id === getCurrentUser().id
      );
      const allUndeletedListings = usersListings.filter(
        (listing) => listing.status !== "deleted"
      );
      const sortedListings = _.orderBy(
        allUndeletedListings,
        [sortColumns.path],
        [sortColumns.order]
      );
      setMyListings(sortedListings);

      //get all applications
      const { data } = await getApplications();
      setApplications(data);

      /////get the last ten years from present years
      const d = new Date();
      let year = d.getFullYear();
      let start = year - 10;
      const yearss = Array.from(
        { length: year - start },
        (_, i) => `${start + 1 + i}`
      );
      setYears(yearss);

      ////get the total payment for each year
      const { data: allMyPayments } = await getLandlordsPayments();
      setAllPayments(allMyPayments);

      ///get all my tenants
      const { data: ten } = await getTenantById(getCurrentUser()?.id);
      const sortedTenants = _.orderBy(
        ten,
        [sortColumns.path],
        [sortColumns.order]
      );
      const cancelled = sortedTenants.filter(
        (s) => s.tenant_close_deal && s.landlord_close_deal
      );
      const uncancelled = sortedTenants.filter(
        (s) => !s.tenant_close_deal && !s.landlord_close_deal
      );

      const tenantCancel = sortedTenants.filter(
        (s) => s.tenant_close_deal && !s.landlord_close_deal
      );
      const landlordCancel = sortedTenants.filter(
        (s) => !s.tenant_close_deal && s.landlord_close_deal
      );

      setAllMyTenants([
        ...uncancelled,
        ...tenantCancel,
        ...landlordCancel,
        ...cancelled,
      ]);

      setLoading(false);
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        setLoading(false);
        setError(ex.response.data);
      }
    }
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to open the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to publish or unpublish a listing
  const onPublish = async (listing) => {
    try {
      let allItems = [...myListings];
      const index = allItems.indexOf(listing);
      const theListing = allItems.splice(index, 1)[0];
      if (theListing.publish === "publish") {
        // if(window.confirm("Are you sure you want to unpublish this listing ?")){
        theListing.publish = "";
        // }
      } else {
        theListing.publish = "publish";
      }
      allItems.splice(index, 0, theListing);

      setMyListings(allItems);

      await publishAListing(listing.id);
    } catch (ex) {
      setApplyError(ex.response.data);
    }
  };

  //function to delete a listing from the listing table
  const onDelete = async (id) => {
    if (window.confirm(t("Are_you_sure_you_want_to_delete"))) {
      try {
        let allItems = [...myListings];
        const all = allItems.filter((item) => item.id !== id);
        setMyListings(all);

        await deleteAListingByUpdateStatus(id);

        trackEvent(
          `${getCurrentUser() && getCurrentUser().first_name} delete listing`,
          `${id}`,
          "delete listing btn"
        );
        //   await deleteAListing(id)
      } catch (ex) {
        logger.log("error from onDelete function in ListingScreen component");
      }
    }
  };

  //function to search a listing
  const onSearchListing = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTable(value, myListings);
      setMyListings(tableData);
      setFromSearch(true);
    } else {
      try {
        setLoadingSearchListings(true);
        const { data: allListings } = await getListings();
        const usersListings = allListings.filter(
          (listing) => listing.list_owner_id === getCurrentUser().id
        );
        setMyListings(usersListings);
        setLoadingSearchListings(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTable = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].title.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //sorting the of listings
  const onSort = (e) => {
    e.preventDefault();
    const isListings = [...myListings];
    const { value } = e.currentTarget;
    const column = { ...sortColumn };
    column.path = value;
    const sortedListing = _.orderBy(isListings, [column.path], [column.order]);
    setMyListings(sortedListing);
  };

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const onSelectedListing = (list) => {
    /////get the last ten years from present years
    if (list.id === selectedListing.id) {
      setSelectedListing({});
    } else {
      setSelectedListing(list);
    }
    const d = new Date();
    let year = d.getFullYear();
    let start = year - 10;
    const yearss = Array.from(
      { length: year - start },
      (_, i) => `${start + 1 + i}`
    );
    ////get the total payment for each year

    let bar = [];
    for (let i = 0; i < yearss.length; i++) {
      const total = allMyPayments
        .filter(
          (payment) =>
            payment.create_date.slice(0, 4) === yearss[i] &&
            payment.listing_id === list.id
        )
        .reduce((acc, curr) => acc + Number(curr.amount), 0);
      let obj = {
        year: yearss[i],
      };
      obj[yearss[i]] = total;
      obj[yearss[i] + "Color"] =
        i == 9
          ? "hsl(301, 70%, 50%)"
          : i === 8
          ? "hsl(86, 70%, 50%)"
          : i === 7
          ? "hsl(338, 70%, 50%)"
          : i === 6
          ? "hsl(119, 70%, 50%)"
          : i === 5
          ? "hsl(293, 70%, 50%)"
          : i === 4
          ? "hsl(63, 70%, 50%)"
          : i === 3
          ? "hsl(103, 70%, 50%)"
          : i === 2
          ? "hsl(298, 70%, 50%)"
          : i === 1
          ? "hsl(86, 70%, 50%)"
          : "hsl(338, 70%, 50%)";
      bar.push(obj);
    }
    setBarChartData(bar);

    ///calculate the gross payment
    const sumTotal = allMyPayments
      .filter((payment) => payment.listing_id === list.id)
      .reduce((acc, curr) => acc + Number(curr.amount), 0);
    setGrossPayment(sumTotal);

    ////get all tenants for a given listing
    const tenants = allMyTenants.filter(
      (tenant) => tenant.listing_id === list.id
    );
    setTenants(tenants);
    setTenants2(tenants);

    ///////////get line chart data for a listing

    let line = [];
    let dataLine = {
      id: t("years"),
      color: "hsl(290, 70%, 50%)",
      data: [],
    };

    for (let i = 0; i < yearss.length; i++) {
      const lineData = {
        x: yearss[i],
        y: allMyPayments
          .filter(
            (payment) =>
              payment.create_date.slice(0, 4) === yearss[i] &&
              payment.listing_id === list.id
          )
          .reduce((acc, curr) => acc + Number(curr.amount), 0),
      };
      dataLine.data.push(lineData);
    }

    line.push(dataLine);
    setLineChartData(line);
  };

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
      </div>
    );
  if (error)
    return (
      <div className="homeSpinnerContainer">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  return (
    <>
      <Meta1
        title={`${getLang()}.${t("meta_list", {
          userName: getCurrentUser().first_name,
        })}`}
        description="View All My Listing Posted Into Nawafrica"
        link="/portal/list/listing"
      />

      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          onTakeBarUp={onTakeBarUp}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
          listings={liste}
        />
      )}

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <div className={"myListingOuterContainer"}>
        <Chats
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />

        <WelcomeUser
          user={capitalize(getCurrentUser() && getCurrentUser().first_name)}
        />

        <div className={close ? "myListingTableCon" : "myListingTableCon1"}>
          <TitleListing listings={myListings.length} />

          <Search
            onChangeSearch={onSearchListing}
            onChangeSort={onSort}
            listings={myListings}
            onSelectLising={onSelectedListing}
            selectedListing={selectedListing}
            years={years}
            barChartData={barChartData}
            grossPayment={grossPayment}
            tenants={tenants}
            lineChartData={lineChartData}
          />

          {myListings.length > 0 ? (
            <Table
              items={myListings}
              applications={applications}
              onPublish={onPublish}
              onDelete={onDelete}
              loadingSearchListings={loadingSearchListings}
              fromSearch={fromSearch}
            />
          ) : loadingSearchListings ? (
            <div>{t(".....loading")}</div>
          ) : (
            <div className="noListingAvailableYetCon">
              <div className="alert">
                {t("No_Listing_Posted")},{" "}
                <Link className="btn btn-light" to="/portal/add/add_listing">
                  {t("Please_Post_A_Listing")}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default ListingScreen;

// export const lineData = [
//   {
//     id: "years",
//     color: "hsl(290, 70%, 50%)",
//     data: [
//       {
//         x: "2024",
//         y: 243,
//       },
//       {
//         x: "2023",
//         y: 244,
//       },
//       {
//         x: "2022",
//         y: 173,
//       },
//       {
//         x: "2021",
//         y: 292,
//       },
//       {
//         x: "2020",
//         y: 262,
//       },
//       {
//         x: "2019",
//         y: 197,
//       },
//       {
//         x: "2018",
//         y: 217,
//       },
//       {
//         x: "2017",
//         y: 29,
//       },
//       {
//         x: "2016",
//         y: 61,
//       },
//       {
//         x: "2015",
//         y: 101,
//       },
//       {
//         x: "2014",
//         y: 61,
//       },
//     ],
//   },
// ];
