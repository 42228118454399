import React from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import style1 from './mapStyles.js'
import style2 from './mapStyles2.js'

import './listingDetails.css'
import MapLoader from './../SearchListing/MapLoader'

const mapContainerStyle = {
  height: '100%',
  width: '100%',
}

const LocationInMap = ({ geoLocation, keys, isAbout = false }) => {
  const { t } = useTranslation()
  const center = {
    lat: geoLocation.lat,
    lng: geoLocation.lng,
  }

  const options = {
    styles: isAbout ? style2 : '',
    disableDefaultUI: true,
    zoomControl: geoLocation.lat ? true : false,
    gestureHandling: 'greedy',
  }

  const { isLoaded, loadError } = useLoadScript({ ...keys })

  const mapRef = React.useRef()

  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map
  }, [])

  if (loadError)
    return (
      <div
        style={{
          padding: '5px',
          background: 'red',
          opacity: '0.8',
          color: 'white',
          width: '50%',
          margin: '5px auto',
          textAlign: 'center',
          borderRadius: '5px',
        }}
      >
        Error Loading Map
      </div>
    )
  if (!isLoaded) return <MapLoader />
  return (
    <div className='geolocationMapCon'>
      <h6>{t('Location_on_Map')}</h6>
      <div className='imageCon'>
        {geoLocation.lat ? (
          <GoogleMap
            id='map'
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={center}
            options={options}
            onLoad={onMapLoad}
          >
            <Marker
              position={{ lat: geoLocation.lat, lng: geoLocation.lng }}
              icon={{
                url: '/images/googleMarker.png',
                scaledSize: new window.google.maps.Size(70, 70),
                anchor: new window.google.maps.Point(15, 15),
              }}
            ></Marker>
          </GoogleMap>
        ) : (
          <div className='listingDetailNoMapAvailable'>
            <b>No Map Available</b>
          </div>
        )}
      </div>
    </div>
  )
}

export default LocationInMap
