import React from 'react'

const AddStoryTextarea = ({ num = '1', onChange, value }) => {
  return (
    <div style={{ marginTop: '5px' }}>
      <label
        style={{
          fontWeight: 'bold',
        }}
        htmlFor={`${num}`}
        className='form-label'
      >
        Paragraph {num}
      </label>
      <textarea
        className='form-control'
        id={`${num}`}
        placeholder={`${num} Body Paragraph`}
        rows='10'
        style={{ borderRadius: '10px' }}
        onChange={onChange}
        value={value}
      ></textarea>
    </div>
  )
}

export default AddStoryTextarea
