import React from 'react'

const MainHeadingDetails = () => {
  return (
    <div style={{ opacity: '0.9' }}>
      <div style={{ fontSize: '14px' }}>
        Users may be subject to different protection standards and broader
        standards may therefore apply to some. In order to learn more about the
        protection criteria, Users can refer to the applicability section.
      </div>
      <div style={{ fontSize: '14px' }}>
        This document contains a section dedicated to Californian consumers and
        their privacy rights.
      </div>
      <div style={{ fontSize: '14px' }}>
        This document can be printed for reference by using the print command in
        the settings of any browser.{' '}
      </div>
    </div>
  )
}

export default MainHeadingDetails
