export function closeDealAnimate() {
  return {
    nm: 'closed',
    mn: '',
    layers: [
      {
        ty: 3,
        nm: 'Controls',
        mn: '',
        sr: 1,
        st: 0,
        op: 433,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [150, 150, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [250, 250, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 0, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [
          {
            ty: 5,
            mn: 'ADBE Color Control',
            nm: 'Nail Color',
            ix: 1,
            en: 1,
            ef: [
              {
                ty: 2,
                mn: 'ADBE Color Control-0001',
                nm: 'Color',
                ix: 1,
                v: { a: 0, k: [0.9255, 0.5569, 0.0078], ix: 1 },
              },
            ],
          },
          {
            ty: 5,
            mn: 'ADBE Color Control',
            nm: 'Wires Color',
            ix: 2,
            en: 1,
            ef: [
              {
                ty: 2,
                mn: 'ADBE Color Control-0001',
                nm: 'Color',
                ix: 1,
                v: { a: 0, k: [0.3294, 0.3294, 0.3294], ix: 1 },
              },
            ],
          },
          {
            ty: 5,
            mn: 'ADBE Color Control',
            nm: 'Sign Color',
            ix: 3,
            en: 1,
            ef: [
              {
                ty: 2,
                mn: 'ADBE Color Control-0001',
                nm: 'Color',
                ix: 1,
                v: { a: 0, k: [0.9255, 0.5569, 0.0078], ix: 1 },
              },
            ],
          },
        ],
        ind: 0,
      },
      {
        ty: 4,
        nm: 'Mask',
        mn: '',
        sr: 1,
        st: 0,
        op: 433,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 1,
        ao: 0,
        ks: {
          a: { a: 0, k: [959.5, 410.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [74, 74, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 48,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [-0.5, -129.5, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { o: { x: 0.8, y: 0 }, i: { x: 0.2, y: 1 }, s: [15], t: 0 },
              { o: { x: 0.8, y: 0 }, i: { x: 0.2, y: 1 }, s: [-15], t: 72 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [15],
                t: 144,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'main',
            ix: 1,
            cix: 2,
            np: 4,
            it: [
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'line top',
                ix: 1,
                cix: 2,
                np: 1,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [79.088, 3.69],
                          [-79.088, 3.69],
                          [-79.088, -3.69],
                          [79.088, -3.69],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: {
                      a: 1,
                      k: [
                        {
                          o: { x: 0.8, y: 0 },
                          i: { x: 0.667, y: 1 },
                          s: [0, 100],
                          t: 72,
                        },
                        {
                          o: { x: 0.167, y: 0.167 },
                          i: { x: 0.833, y: 0.833 },
                          s: [100, 100],
                          t: 120,
                        },
                      ],
                      ix: 3,
                    },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [960, 488.919], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'line bot',
                ix: 2,
                cix: 2,
                np: 1,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [79.088, 3.69],
                          [-79.088, 3.69],
                          [-79.088, -3.69],
                          [79.088, -3.69],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: {
                      a: 1,
                      k: [
                        {
                          o: { x: 0.8, y: 0 },
                          i: { x: 0.667, y: 1 },
                          s: [0, 100],
                          t: 98.4,
                        },
                        {
                          o: { x: 0.167, y: 0.167 },
                          i: { x: 0.833, y: 0.833 },
                          s: [100, 100],
                          t: 146.400390625,
                        },
                      ],
                      ix: 3,
                    },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [960, 591.081], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'closed',
                ix: 3,
                cix: 2,
                np: 6,
                it: [
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'C',
                    ix: 1,
                    cix: 2,
                    np: 1,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [-0.47, 2.045],
                              [0, 0],
                              [1.949, -1.478],
                              [2.997, 0],
                              [2.387, 2.535],
                              [0, 4.393],
                              [-2.4, 2.572],
                              [-3.911, 0],
                              [-2.134, -2.019],
                              [-0.636, -2.235],
                              [0, 0],
                              [1.048, 0.838],
                              [1.498, 0],
                              [1.289, -1.486],
                              [0, -3.327],
                              [-1.269, -1.499],
                              [-2.032, 0],
                              [-1.079, 0.952],
                            ],
                            o: [
                              [0, 0],
                              [-0.838, 3.048],
                              [-1.95, 1.48],
                              [-3.708, 0],
                              [-2.387, -2.533],
                              [0, -4.648],
                              [2.401, -2.571],
                              [3.415, 0],
                              [1.269, 1.193],
                              [0, 0],
                              [-0.331, -1.448],
                              [-1.048, -0.838],
                              [-2.07, 0],
                              [-1.289, 1.485],
                              [0, 3.53],
                              [1.27, 1.498],
                              [1.498, 0],
                              [1.079, -0.952],
                            ],
                            v: [
                              [6.695, 3.695],
                              [12.161, 5.428],
                              [7.98, 12.218],
                              [0.561, 14.438],
                              [-8.581, 10.637],
                              [-12.161, 0.248],
                              [-8.563, -10.581],
                              [0.904, -14.438],
                              [9.229, -11.409],
                              [12.086, -6.266],
                              [6.505, -4.933],
                              [4.438, -8.362],
                              [0.618, -9.619],
                              [-4.419, -7.39],
                              [-6.353, -0.171],
                              [-4.448, 7.372],
                              [0.505, 9.619],
                              [4.37, 8.19],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0.171, -14.251], ix: 1 },
                        s: {
                          a: 1,
                          k: [
                            {
                              o: { x: 0.333, y: 0 },
                              i: { x: 0.667, y: 1 },
                              s: [100, 0],
                              t: 112.801,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [100, 100],
                              t: 136.80078125,
                            },
                          ],
                          ix: 3,
                        },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [892.907, 524.844], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'L',
                    ix: 2,
                    cix: 2,
                    np: 1,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-9.828, 13.847],
                              [-9.828, -13.847],
                              [-4.191, -13.847],
                              [-4.191, 9.143],
                              [9.828, 9.143],
                              [9.828, 13.847],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [-0.125, -13.4], ix: 1 },
                        s: {
                          a: 1,
                          k: [
                            {
                              o: { x: 0.333, y: 0 },
                              i: { x: 0.667, y: 1 },
                              s: [100, 0],
                              t: 100.801,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [100, 100],
                              t: 124.80078125,
                            },
                          ],
                          ix: 3,
                        },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [919.592, 525.81], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'O',
                    ix: 3,
                    cix: 2,
                    np: 3,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, -3.212],
                              [-1.46, -1.631],
                              [-2.247, 0],
                              [-1.442, 1.619],
                              [0, 3.238],
                              [1.403, 1.574],
                              [2.324, 0],
                              [1.423, -1.594],
                            ],
                            o: [
                              [0, 3.162],
                              [1.461, 1.632],
                              [2.248, 0],
                              [1.441, -1.619],
                              [0, -3.2],
                              [-1.404, -1.575],
                              [-2.323, 0],
                              [-1.422, 1.593],
                            ],
                            v: [
                              [-7.733, -0.019],
                              [-5.544, 7.171],
                              [0.018, 9.619],
                              [5.552, 7.19],
                              [7.714, -0.095],
                              [5.609, -7.257],
                              [0.018, -9.619],
                              [-5.6, -7.228],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 2',
                        ix: 2,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, 4.445],
                              [-0.851, 1.931],
                              [-1.099, 1.131],
                              [-1.308, 0.546],
                              [-2.273, 0],
                              [-2.469, -2.553],
                              [0, -4.546],
                              [2.45, -2.546],
                              [4.102, 0],
                              [2.45, 2.534],
                            ],
                            o: [
                              [0, -2.844],
                              [0.635, -1.422],
                              [1.098, -1.13],
                              [1.74, -0.736],
                              [4.115, 0],
                              [2.47, 2.552],
                              [0, 4.507],
                              [-2.451, 2.546],
                              [-4.152, 0],
                              [-2.451, -2.533],
                            ],
                            v: [
                              [-13.542, 0.171],
                              [-12.266, -6.991],
                              [-9.667, -10.819],
                              [-6.057, -13.333],
                              [-0.039, -14.438],
                              [9.838, -10.609],
                              [13.542, 0.038],
                              [9.867, 10.619],
                              [0.038, 14.438],
                              [-9.867, 10.637],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'mm',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Filter - Merge',
                        nm: 'Merge Paths 1',
                        mm: 1,
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [-0.409, -14.123], ix: 1 },
                        s: {
                          a: 1,
                          k: [
                            {
                              o: { x: 0.333, y: 0 },
                              i: { x: 0.667, y: 1 },
                              s: [100, 0],
                              t: 88.801,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [100, 100],
                              t: 112.80078125,
                            },
                          ],
                          ix: 3,
                        },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [945.554, 524.972], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'S',
                    ix: 4,
                    cix: 2,
                    np: 1,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0.368, 3.124],
                              [0, 0],
                              [-1.009, -0.863],
                              [-1.714, 0],
                              [-0.921, 0.769],
                              [0, 1.028],
                              [0.388, 0.464],
                              [0.965, 0.343],
                              [2.349, 0.584],
                              [1.219, 1.092],
                              [0, 2.21],
                              [-0.807, 1.239],
                              [-1.518, 0.648],
                              [-2.146, 0],
                              [-1.771, -1.536],
                              [-0.089, -2.565],
                              [0, 0],
                              [0.794, 0.628],
                              [1.587, 0],
                              [0.927, -0.673],
                              [0, -0.724],
                              [-0.558, -0.47],
                              [-2.742, -0.648],
                              [-1.315, -0.692],
                              [-0.742, -1.2],
                              [0, -1.765],
                              [0.889, -1.397],
                              [1.626, -0.68],
                              [2.426, 0],
                              [1.891, 1.632],
                            ],
                            o: [
                              [0, 0],
                              [0.33, 1.842],
                              [1.01, 0.864],
                              [1.815, 0],
                              [0.92, -0.769],
                              [0, -0.66],
                              [-0.386, -0.463],
                              [-0.66, -0.229],
                              [-3.022, -0.749],
                              [-1.714, -1.536],
                              [0, -1.422],
                              [0.805, -1.237],
                              [1.518, -0.648],
                              [3.505, 0],
                              [1.772, 1.537],
                              [0, 0],
                              [-0.242, -1.434],
                              [-0.793, -0.629],
                              [-1.638, 0],
                              [-0.597, 0.431],
                              [0, 0.66],
                              [0.711, 0.597],
                              [2.743, 0.647],
                              [1.314, 0.692],
                              [0.743, 1.2],
                              [0, 1.6],
                              [-0.889, 1.397],
                              [-1.625, 0.678],
                              [-3.53, 0],
                              [-1.893, -1.632],
                            ],
                            v: [
                              [-11.352, 4.866],
                              [-5.866, 4.333],
                              [-3.857, 8.39],
                              [0.229, 9.685],
                              [4.334, 8.533],
                              [5.714, 5.838],
                              [5.133, 4.152],
                              [3.105, 2.943],
                              [-1.409, 1.723],
                              [-7.771, -1.038],
                              [-10.342, -6.657],
                              [-9.132, -10.648],
                              [-5.647, -13.476],
                              [-0.152, -14.447],
                              [7.762, -12.143],
                              [10.552, -5.99],
                              [4.915, -5.743],
                              [3.362, -8.838],
                              [-0.209, -9.781],
                              [-4.057, -8.771],
                              [-4.952, -7.038],
                              [-4.114, -5.343],
                              [1.067, -3.476],
                              [7.153, -1.467],
                              [10.238, 1.371],
                              [11.352, 5.819],
                              [10.019, 10.314],
                              [6.248, 13.429],
                              [0.171, 14.447],
                              [-7.961, 12],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [-0.071, -14.188], ix: 1 },
                        s: {
                          a: 1,
                          k: [
                            {
                              o: { x: 0.333, y: 0 },
                              i: { x: 0.667, y: 1 },
                              s: [100, 0],
                              t: 88.801,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [100, 100],
                              t: 112.80078125,
                            },
                          ],
                          ix: 3,
                        },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [973.757, 524.916], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'E',
                    ix: 5,
                    cix: 2,
                    np: 1,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-10.619, 13.961],
                              [-10.619, -13.961],
                              [10.085, -13.961],
                              [10.085, -9.238],
                              [-4.981, -9.238],
                              [-4.981, -3.047],
                              [9.038, -3.047],
                              [9.038, 1.656],
                              [-4.981, 1.656],
                              [-4.981, 9.257],
                              [10.618, 9.257],
                              [10.618, 13.961],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [0.538, -13.83], ix: 1 },
                        s: {
                          a: 1,
                          k: [
                            {
                              o: { x: 0.333, y: 0 },
                              i: { x: 0.667, y: 1 },
                              s: [100, 0],
                              t: 100.801,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [100, 100],
                              t: 124.80078125,
                            },
                          ],
                          ix: 3,
                        },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [1001.081, 525.265], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'gr',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Group',
                    nm: 'D',
                    ix: 6,
                    cix: 2,
                    np: 3,
                    it: [
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 1',
                        ix: 1,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [-0.699, 0.179],
                              [-0.603, 0.546],
                              [-0.381, 1.251],
                              [0, 2.16],
                              [0.381, 1.155],
                              [0.686, 0.648],
                              [1.054, 0.229],
                              [2.298, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [1.575, 0],
                              [0.914, -0.228],
                              [0.603, -0.546],
                              [0.381, -1.251],
                              [0, -2.158],
                              [-0.381, -1.156],
                              [-0.686, -0.647],
                              [-0.788, -0.178],
                              [0, 0],
                            ],
                            v: [
                              [-6.067, -9.238],
                              [-6.067, 9.257],
                              [-1.858, 9.257],
                              [1.552, 8.99],
                              [3.828, 7.829],
                              [5.304, 5.134],
                              [5.875, 0.019],
                              [5.304, -4.952],
                              [3.704, -7.657],
                              [1.095, -8.971],
                              [-3.534, -9.238],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'sh',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Shape - Group',
                        nm: 'Path 2',
                        ix: 2,
                        d: 1,
                        ks: {
                          a: 0,
                          k: {
                            c: true,
                            i: [
                              [0, 0],
                              [0, 0],
                              [-1.218, -0.355],
                              [-1.169, -1.231],
                              [-0.609, -1.784],
                              [0, -2.616],
                              [0.571, -1.664],
                              [1.295, -1.256],
                              [1.663, -0.533],
                              [2.082, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [2.324, 0],
                              [1.639, 0.482],
                              [1.168, 1.232],
                              [0.61, 1.784],
                              [0, 2.298],
                              [-0.699, 2.031],
                              [-0.979, 0.953],
                              [-1.245, 0.394],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [-11.705, -13.962],
                              [-1.401, -13.962],
                              [3.913, -13.428],
                              [8.123, -10.857],
                              [10.789, -6.333],
                              [11.704, 0.267],
                              [10.847, 6.21],
                              [7.857, 11.142],
                              [3.895, 13.371],
                              [-1.095, 13.962],
                              [-11.705, 13.962],
                            ],
                          },
                          ix: 2,
                        },
                      },
                      {
                        ty: 'mm',
                        bm: 0,
                        cl: '',
                        ln: '',
                        hd: false,
                        mn: 'ADBE Vector Filter - Merge',
                        nm: 'Merge Paths 1',
                        mm: 1,
                      },
                      {
                        ty: 'tr',
                        a: { a: 0, k: [-0.008, -13.946], ix: 1 },
                        s: {
                          a: 1,
                          k: [
                            {
                              o: { x: 0.333, y: 0 },
                              i: { x: 0.667, y: 1 },
                              s: [100, 0],
                              t: 112.801,
                            },
                            {
                              o: { x: 0.167, y: 0.167 },
                              i: { x: 0.833, y: 0.833 },
                              s: [100, 100],
                              t: 136.80078125,
                            },
                          ],
                          ix: 3,
                        },
                        sk: { a: 0, k: 0, ix: 4 },
                        p: { a: 0, k: [1027.62, 525.149], ix: 2 },
                        r: { a: 0, k: 0, ix: 6 },
                        sa: { a: 0, k: 0, ix: 5 },
                        o: { a: 0, k: 100, ix: 7 },
                      },
                    ],
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [959.954, 539.104], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [959.954, 539.104], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [1, 1, 1], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [960, 540], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [960, 540], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
        parent: 0,
      },
      {
        ty: 4,
        nm: 'closed',
        mn: '',
        sr: 1,
        st: 0,
        op: 433,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 2,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [959.5, 410.5, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [74, 74, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100, 100, 100],
                t: 48,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [-0.5, -129.5, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: {
            a: 1,
            k: [
              { o: { x: 0.8, y: 0 }, i: { x: 0.2, y: 1 }, s: [15], t: 0 },
              { o: { x: 0.8, y: 0 }, i: { x: 0.2, y: 1 }, s: [-15], t: 72 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [15],
                t: 144,
              },
            ],
            ix: 10,
          },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'top',
            ix: 1,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 5.207],
                      [5.207, 0],
                      [0, -5.207],
                      [-5.207, 0],
                    ],
                    o: [
                      [0, -5.207],
                      [-5.207, 0],
                      [0, 5.207],
                      [5.207, 0],
                    ],
                    v: [
                      [9.428, 0],
                      [0, -9.428],
                      [-9.427, 0],
                      [0, 9.428],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.9255, 0.5569, 0.0078], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0, y: 1 },
                      s: [0, 0],
                      t: 0,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100, 100],
                      t: 24,
                    },
                  ],
                  ix: 3,
                },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [960, 411.666], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'main',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'gr',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Group',
                nm: 'main',
                ix: 1,
                cix: 2,
                np: 1,
                it: [
                  {
                    ty: 'sh',
                    bm: 0,
                    cl: '',
                    ln: '',
                    hd: false,
                    mn: 'ADBE Vector Shape - Group',
                    nm: 'Path 1',
                    ix: 1,
                    d: 1,
                    ks: {
                      a: 0,
                      k: {
                        c: true,
                        i: [
                          [0, 0],
                          [9.918, 0],
                          [0, 0],
                          [0, 9.917],
                          [0, 0],
                          [-9.917, 0],
                          [0, 0],
                          [0, -9.918],
                        ],
                        o: [
                          [0, 9.917],
                          [0, 0],
                          [-9.917, 0],
                          [0, 0],
                          [0, -9.918],
                          [0, 0],
                          [9.918, 0],
                          [0, 0],
                        ],
                        v: [
                          [99.061, 46.813],
                          [81.101, 64.771],
                          [-81.102, 64.771],
                          [-99.06, 46.813],
                          [-99.06, -46.811],
                          [-81.102, -64.77],
                          [81.101, -64.77],
                          [99.061, -46.811],
                        ],
                      },
                      ix: 2,
                    },
                  },
                  {
                    ty: 'tr',
                    a: { a: 0, k: [-0.619, -65.382], ix: 1 },
                    s: {
                      a: 1,
                      k: [
                        {
                          o: { x: 0.167, y: 0 },
                          i: { x: 0.667, y: 1 },
                          s: [100, 0],
                          t: 69.6,
                        },
                        {
                          o: { x: 0.167, y: 0.167 },
                          i: { x: 0.833, y: 0.833 },
                          s: [100, 100],
                          t: 117.599609375,
                        },
                      ],
                      ix: 3,
                    },
                    sk: { a: 0, k: 0, ix: 4 },
                    p: { a: 0, k: [959.381, 474.618], ix: 2 },
                    r: { a: 0, k: 0, ix: 6 },
                    sa: { a: 0, k: 0, ix: 5 },
                    o: { a: 0, k: 100, ix: 7 },
                  },
                ],
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [1, 0.6471, 0], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [960, 540], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [960, 540], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'right wire',
            ix: 3,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-44, -37.667],
                      [44, 37.667],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'tm',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Filter - Trim',
                nm: 'Trim Paths 1',
                ix: 2,
                e: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.8, y: 0 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 24,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100],
                      t: 72,
                    },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                s: { a: 0, k: 0, ix: 1 },
                m: 1,
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 10,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 5, ix: 5 },
                d: [],
                c: { a: 0, k: [0.9255, 0.5569, 0.0078], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [1003.764, 449.333], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'left wire',
            ix: 4,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: false,
                    i: [
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [44, -37.667],
                      [-44, 37.667],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'tm',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Filter - Trim',
                nm: 'Trim Paths 1',
                ix: 2,
                e: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.8, y: 0 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0],
                      t: 24,
                    },
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100],
                      t: 72,
                    },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                s: { a: 0, k: 0, ix: 1 },
                m: 1,
              },
              {
                ty: 'st',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Stroke',
                nm: 'Stroke 1',
                lc: 1,
                lj: 1,
                ml: 10,
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 5, ix: 5 },
                d: [],
                c: { a: 0, k: [0.9255, 0.5569, 0.0078], ix: 3 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [916, 449.333], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
        parent: 0,
      },
    ],
    ddd: 0,
    h: 500,
    w: 500,
    meta: { a: '', k: '', d: '', g: 'LottieFiles AE ', tc: '#ffffff' },
    v: '4.8.0',
    fr: 60,
    op: 433,
    ip: 0,
    assets: [],
  }
}
