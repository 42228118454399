import React from 'react'

import './leases.css'

const AgreementInputs = ({placeholder, disabled, autoFocus=false, value, onChange}) => {
return (

<input autoFocus={autoFocus} disabled={disabled} value={value} onChange={onChange} placeholder={placeholder} className="agreementInputField"/>                                      

)
}

export default AgreementInputs
