import React from 'react'
import { Link } from 'react-router-dom'

const PolicyFooter = () => {
  return (
    <div>
      <div style={{ fontSize: '13px' }}>Latest update: June 10, 2022</div>
      <div style={{ fontSize: '13px' }}>
        Captivator Technologies hosts this content and only collects the
        Personal Data strictly necessary for it to be provided.
      </div>
      {/* <div style={{ fontSize: '13px', width: '100%', marginTop: '20px' }}>
        <Link
          style={{
            textDecoration: 'underline',
            color: 'black',
            position: 'absolute',
            right: '24%',
          }}
          to='/policies/details'
        >
          Show the complete Privacy Policy
        </Link>
      </div> */}
    </div>
  )
}

export default PolicyFooter
