import React from 'react'

const AddStoryInput = ({ id, label, placeholder, onChange, value }) => {
  return (
    <div style={{ marginTop: '5px' }}>
      <label style={{ fontWeight: 'bold' }} htmlFor={id} className='form-label'>
        {label}
      </label>
      <input
        type='text'
        className='form-control'
        id={id}
        placeholder={placeholder}
        style={{ borderRadius: '10px' }}
        onChange={onChange}
        value={value}
      ></input>
    </div>
  )
}

export default AddStoryInput
