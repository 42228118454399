import React, { useEffect } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

import moment from "moment";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { setConfig, buildUrl } from "cloudinary-build-url";
import Card from "@mui/material/Card";

import { trackEvent } from "../../Utils/useGAEventTracker";

import "./styleCompo.css";
import VerifiedBadge from "./VerifiedBadge";
import CardFlag from "./CardFlag";
import { Regions } from "../../Utils/regions";
import { Chip, Tooltip } from "@mui/material";

setConfig({
  cloudName: "captivator",
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DisplayCardGrid = ({
  items = [],
  onHoverListing,
  selectedItem,
  users,
  availableSpaces,
}) => {
  const { t } = useTranslation();

  const getVerified = (item) => {
    const listOwner = users.find((u) => u.id == item.list_owner_id);
    return listOwner && listOwner.verified;
  };

  useEffect(() => {
    AOS.init({ duration: 500 });
  }, []);

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const calculateDate = (date) => {
    const postedDate = moment(parseInt(date));
    const nowDate = moment(Date.now());
    const diffInSec = nowDate.diff(postedDate, "seconds");
    const diffInMin = nowDate.diff(postedDate, "minutes");
    const diffInHour = nowDate.diff(postedDate, "hours");
    const diffInDay = nowDate.diff(postedDate, "days");
    const diffInMonth = nowDate.diff(postedDate, "months", true);
    const diffInYear = nowDate.diff(postedDate, "years");

    if (diffInSec < 60) return `${diffInSec} ${t("sec_ago")}`;
    if (diffInMin < 60) return `${diffInMin} ${t("min_ago")}`;
    if (diffInHour < 24) return `${diffInHour} ${t("hr_ago")}`;
    if (diffInDay < 30) return `${diffInDay} ${t("day_ago")}`;
    if (diffInMonth < 12)
      return `${Math.round(diffInMonth) < 1 ? 1 : Math.round(diffInMonth)} ${t(
        "mth_ago"
      )}`;

    return `${diffInYear} ${t("yr_ago")}`;
  };

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        container
        spacing={{ xs: 1, md: 1 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {items.map((item, index) => {
          const src = buildUrl(
            item.main_photo && item.main_photo.path && item.main_photo.path.url,
            {
              transformations: {
                resize: {
                  type: "scale",
                  width: 700,
                  height: 450,
                },
              },
            }
          );

          return (
            <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
              <Card
                variant="outlined"
                data-aos={
                  index / 2 === parseInt(index / 2, 10)
                    ? "fade-right"
                    : "fade-left"
                }
                key={`${item.id}`}
                className="displayListingCard7"
                onMouseEnter={() => onHoverListing(item)}
                onMouseLeave={() => onHoverListing(null)}
              >
                {Regions().filter(
                  (region) => region.country == item.country
                )[0] && (
                  <CardFlag
                    src={
                      Regions().filter(
                        (region) => region.country == item.country
                      )[0].imageUrl
                    }
                  />
                )}
                {getVerified(item) && <VerifiedBadge />}
                <Link
                  onClick={() =>
                    trackEvent(
                      "display list card click",
                      `/listing/${item.id}`,
                      "display card link"
                    )
                  }
                  to={`/listing/${item.id}`}
                  style={{
                    width: "100%",
                    height: "80%",
                    outline: "0",
                    padding: "0",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      outline: "0",
                      backgroundColor: "lightgray",
                    }}
                  >
                    <img
                      crossOrigin="anonymous"
                      className="card-img-top"
                      src={src}
                      alt="Card image cap"
                    />
                  </div>
                </Link>
                <div className="card-body cardBodyContainerForDisplayCard">
                  <div className="title_locationCon">
                    <h6 className="card-title card-title1">
                      <b
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          lineClamp: 2,
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {capitalize(item.title)}
                      </b>
                    </h6>
                    <h6
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        lineClamp: 2,
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                      }}
                      className="card-title card-title2"
                    >
                      <b>{`${commaSeparateNumber(item.price)} ${
                        item.currency
                      }`}</b>
                    </h6>
                  </div>
                  <div className="price_posted">
                    <p className="card-text">
                      {t("displayCard_bedroom_num", {
                        bedrooms_nums:
                          item.listing_type !== "Land" ? t(item.bedrooms) : "",
                        bedrooms:
                          item.listing_type !== "Land" ? t(`Bedrooms`) : "",
                        listing_type: t(item.listing_type),
                        city: item.city,
                      })}
                      <Tooltip
                        title={`${
                          item?.remainingSpace > 0 ? item?.remainingSpace : "No"
                        } available quantit${
                          Number(item?.remainingSpace) > 1 ? "ies" : "y"
                        } remaining${
                          item?.remainingSpace > 0
                            ? `, this include${
                                item?.remainingSpace > 1 ? "s" : ""
                              } ${item.listing_type} #`
                            : ""
                        } ${
                          item?.remainingSpace > 0
                            ? availableSpaces
                                .sort((a, b) => a.space_number - b.space_number)
                                ?.filter((l) => l.listing_id === item.id)
                                ?.filter((l) => l.empty_space === true)
                                .map((l) =>
                                  l.space_name ? l.space_name : l.space_number
                                )
                                .join(", ")
                            : ""
                        }`}
                        placement="top"
                      >
                        <Chip
                          style={{
                            marginLeft: "5px",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                          label={`${item?.remainingSpace}`}
                        />
                      </Tooltip>
                    </p>
                    <p className="card-text">
                      {t("display_Posted")}: {calculateDate(item.dateposted)}
                    </p>
                  </div>
                </div>
              </Card>
            </Grid>
          );
        })}

        <ReactTooltip effect="solid" />
      </Grid>
    </Box>
  );
};

export default DisplayCardGrid;
