import React from "react";

const AlertCustomise = ({ data, background = "white", color = "#035aa6" }) => {
  return (
    <div
      style={{
        width: "250px",
        position: "fixed",
        zIndex: "5",
        background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "50px auto 0 auto",
        left: "0",
        textAlign: "center",
        padding: "10px 20px",
        border: "2px solid #ffa500",
        borderRadius: "10px",
        right: "0",
        font: "normal normal normal 14px/25px Merriweather",
        color,
      }}
      className="animate__animated animate__heartBeat"
    >
      {data}
    </div>
  );
};

export default AlertCustomise;
