import React from 'react'

const Catergory = ({ items, onChange }) => {
  return (
    <div className='categoryContainer'>
      <label style={{ color: '#035aa6' }} htmlFor='c' className='form-label'>
        What Catergory best defined your issues?
        <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
      </label>
      <select
        className='form-control'
        id='c'
        placeholder='contact'
        onChange={onChange}
      >
        {items.map((x) => (
          <option style={{ cursor: 'pointer' }} key={x} value={x}>
            {x}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Catergory
