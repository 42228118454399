import React, { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./profile.css";

const ProfileSpinner = ({ size = 50, color = "#035aa6", thickness = 5 }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="profileSpinnerOuterContainer">
      <CircularProgress
        // thickness={thickness}
        size={size}
        sx={{ color: "#035aa6" }}
      />
      {/* <div aria-hidden="true" className="spinner-border profileSpinnerContainer" role="status">
  <span className="sr-only">Loading...</span>
</div> */}
    </div>
  );
};

export default ProfileSpinner;
