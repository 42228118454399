export function currencyCodes() {
  let currencyCodes = [
    { code: 'AED', ctry: 'UAE' },
    { code: 'AFN', ctry: 'AFGHANISTAN' },
    { code: 'ALL', ctry: 'ALBANIA' },
    { code: 'AMD', ctry: 'ARMENIA' },
    { code: 'ANG', ctry: 'CURAÇAO' },
    { code: 'AOA', ctry: 'ANGOLA' },
    { code: 'ARS', ctry: 'ARGENTINA' },
    { code: 'AUD', ctry: 'AUSTRALIA' },
    { code: 'AWG', ctry: 'ARUBA' },
    { code: 'AZN', ctry: 'AZERBAIJAN' },
    { code: 'BAM', ctry: 'BOSNIA' },
    { code: 'BBD', ctry: 'BARBADOS' },
    { code: 'BDT', ctry: 'BANGLADESH' },
    { code: 'BGN', ctry: 'BULGARIA' },
    { code: 'BHD', ctry: 'BAHRAIN' },
    { code: 'BIF', ctry: 'BURUNDI' },
    { code: 'BMD', ctry: 'BERMUDA' },
    { code: 'BND', ctry: 'BRUNEI DARUSSALAM' },
    { code: 'BOB', ctry: 'BOLIVIA' },
    { code: 'BOV', ctry: 'BOLIVIA' },
    { code: 'BRL', ctry: 'BRAZIL' },
    { code: 'BSD', ctry: 'BAHAMAS' },
    { code: 'BTN', ctry: 'BHUTAN' },
    { code: 'BWP', ctry: 'BOTSWANA' },
    { code: 'BYN', ctry: 'BELARUS' },
    { code: 'BZD', ctry: 'BELIZE' },
    { code: 'CAD', ctry: 'CANADA' },
    { code: 'CDF', ctry: 'D.R CONGO' },
    { code: 'CHE', ctry: 'SWITZERLAND' },
    { code: 'CHF', ctry: 'SWITZERLAND' },
    { code: 'CHW', ctry: 'SWITZERLAND' },
    { code: 'CLF', ctry: 'CHILE' },
    { code: 'CLP', ctry: 'CHILE' },
    { code: 'CNY', ctry: 'CHINA' },
    { code: 'COP', ctry: 'COLOMBIA' },
    { code: 'COU', ctry: 'COLOMBIA' },
    { code: 'CRC', ctry: 'COSTA RICA' },
    { code: 'CUC', ctry: 'CUBA' },
    { code: 'CUP', ctry: 'CUBA' },
    { code: 'CVE', ctry: 'CABO VERDE' },
    { code: 'CZK', ctry: 'CZECH REPUBLIC' },
    { code: 'DJF', ctry: 'DJIBOUTI' },
    { code: 'DKK', ctry: 'DENMARK' },
    { code: 'DOP', ctry: 'DOMINICAN REPUBLIC' },
    { code: 'DZD', ctry: 'ALGERIA' },
    { code: 'EGP', ctry: 'EGYPT' },
    { code: 'ERN', ctry: 'ERITREA' },
    { code: 'ETB', ctry: 'ETHIOPIA' },
    { code: 'ETB', ctry: 'ETHIOPIA' },
    { code: 'EUR', ctry: 'EUROPEAN UNION' },
    { code: 'FJD', ctry: 'FIJI' },
    { code: 'FKP', ctry: 'FALKLAND ISLANDS' },
    { code: 'GBP', ctry: 'UNITED KINGDOM' },
    { code: 'GEL', ctry: 'GEORGIA' },
    { code: 'GHS', ctry: 'GHANA' },
    { code: 'GIP', ctry: 'GIBRALTAR' },
    { code: 'GMD', ctry: 'GAMBIA' },
    { code: 'GNF', ctry: 'GUINEA' },
    { code: 'GTQ', ctry: 'GUATEMALA' },
    { code: 'GYD', ctry: 'GUYANA' },
    { code: 'HKD', ctry: 'HONG KONG' },
    { code: 'HNL', ctry: 'HONDURAS' },
    { code: 'HRK', ctry: 'CROATIA' },
    { code: 'HTG', ctry: 'HAITI' },
    { code: 'HUF', ctry: 'HUNGARY' },
    { code: 'IDR', ctry: 'INDONESIA' },
    { code: 'ILS', ctry: 'ISRAEL' },
    { code: 'INR', ctry: 'INDIA' },
    { code: 'IQD', ctry: 'IRAQ' },
    { code: 'IRR', ctry: 'IRAN' },
    { code: 'ISK', ctry: 'ICELAND' },
    { code: 'JMD', ctry: 'JAMAICA' },
    { code: 'JOD', ctry: 'JORDAN' },
    { code: 'JPY', ctry: 'JAPAN' },
    { code: 'KES', ctry: 'KENYA' },
    { code: 'KGS', ctry: 'KYRGYZSTAN' },
    { code: 'KHR', ctry: 'CAMBODIA' },
    { code: 'KMF', ctry: 'COMOROS' },
    { code: 'KPW', ctry: 'KOREA' },
    { code: 'KWD', ctry: 'KUWAIT' },
    { code: 'KYD', ctry: 'CAYMAN ISLANDS' },
    { code: 'KZT', ctry: 'KAZAKHSTAN' },
    { code: 'LAK', ctry: 'D.R LAO PEOPLE’S' },
    { code: 'LBP', ctry: 'LEBANON' },
    { code: 'LKR', ctry: 'SRI LANKA' },
    { code: 'LRD', ctry: 'LIBERIA' },
    { code: 'LSL', ctry: 'LESOTHO' },
    { code: 'LYD', ctry: 'LIBYA' },
    { code: 'MAD', ctry: 'MOROCCO' },
    { code: 'MDL', ctry: 'MOLDOVA' },
    { code: 'MGA', ctry: 'MADAGASCAR' },
    { code: 'MKD', ctry: 'REPUBLIC OF NORTH MACEDONIA' },
    { code: 'MMK', ctry: 'MYANMAR' },
    { code: 'MNT', ctry: 'MONGOLIA' },
    { code: 'MOP', ctry: 'MACAO' },
    { code: 'MRU', ctry: 'MAURITANIA' },
    { code: 'MUR', ctry: 'MAURITIUS' },
    { code: 'MVR', ctry: 'MALDIVES' },
    { code: 'MWK', ctry: 'MALAWI' },
    { code: 'MXN', ctry: 'MEXICO' },
    { code: 'MXV', ctry: 'MEXICO' },
    { code: 'MYR', ctry: 'MALAYSIA' },
    { code: 'MZN', ctry: 'MOZAMBIQUE' },
    { code: 'NAD', ctry: 'NAMIBIA' },
    { code: 'NGN', ctry: 'NIGERIA' },
    { code: 'NIO', ctry: 'NICARAGUA' },
    { code: 'NOK', ctry: 'NORWAY' },
    { code: 'NPR', ctry: 'NEPAL' },
    { code: 'NZD', ctry: 'NEW ZEALAND' },
    { code: 'OMR', ctry: 'OMAN' },
    { code: 'PAB', ctry: 'PANAMA' },
    { code: 'PEN', ctry: 'PERU' },
    { code: 'PGK', ctry: 'PAPUA NEW GUINEA' },
    { code: 'PHP', ctry: 'PHILIPPINES' },
    { code: 'PKR', ctry: 'PAKISTAN' },
    { code: 'PLN', ctry: 'POLAND' },
    { code: 'PYG', ctry: 'PARAGUAY' },
    { code: 'QAR', ctry: 'QATAR' },
    { code: 'RON', ctry: 'ROMANIA' },
    { code: 'RSD', ctry: 'SERBIA' },
    { code: 'RUB', ctry: 'RUSSIAN FEDERATION' },
    { code: 'RWF', ctry: 'RWANDA' },
    { code: 'SAR', ctry: 'SAUDI ARABIA' },
    { code: 'SBD', ctry: 'SOLOMON ISLANDS' },
    { code: 'SCR', ctry: 'SEYCHELLES' },
    { code: 'SDG', ctry: 'SUDAN' },
    { code: 'SEK', ctry: 'SWEDEN' },
    { code: 'SGD', ctry: 'SINGAPORE' },
    { code: 'SHP', ctry: 'SAINT HELENA' },
    { code: 'SLL', ctry: 'SIERRA LEONE' },
    { code: 'SOS', ctry: 'SOMALIA' },
    { code: 'SRD', ctry: 'SURINAME' },
    { code: 'SSP', ctry: 'SOUTH SUDAN' },
    { code: 'STN', ctry: 'SAO TOME AND PRINCIPE' },
    { code: 'SVC', ctry: 'EL SALVADOR' },
    { code: 'SYP', ctry: 'SYRIAN ARAB REPUBLIC' },
    { code: 'SZL', ctry: 'SWAZILAND' },
    { code: 'THB', ctry: 'THAILAND' },
    { code: 'TJS', ctry: 'TAJIKISTAN' },
    { code: 'TMT', ctry: 'TURKMENISTAN' },
    { code: 'TND', ctry: 'TUNISIA' },
    { code: 'TOP', ctry: 'TONGA' },
    { code: 'TRY', ctry: 'TURKEY' },
    { code: 'TTD', ctry: 'TRINIDAD AND TOBAGO' },
    { code: 'TWD', ctry: 'TAIWAN' },
    { code: 'TZS', ctry: 'TANZANIA' },
    { code: 'UAH', ctry: 'UKRAINE' },
    { code: 'UGX', ctry: 'UGANDA' },
    { code: 'USD', ctry: 'UNITED STATES OF AMERICA' },
    { code: 'UYI', ctry: 'URUGUAY' },
    { code: 'UYU', ctry: 'URUGUAY' },
    { code: 'UZS', ctry: 'UZBEKISTAN' },
    { code: 'VED', ctry: 'VENEZUELA' },
    { code: 'VEF', ctry: 'VENEZUELA' },
    { code: 'VND', ctry: 'VIET NAM' },
    { code: 'VUV', ctry: 'VANUATU' },
    { code: 'WST', ctry: 'SAMOA' },
    { code: 'XAF', ctry: 'CAMEROON' },
    { code: 'XCD', ctry: 'DOMINICA' },
    { code: 'XDR', ctry: 'IMF' },
    { code: 'XOF', ctry: `CÔTE D'IVOIRE` },
    { code: 'XPF', ctry: 'NEW CALEDONIA' },
    { code: 'YER', ctry: 'YEMEN' },
    { code: 'ZAR', ctry: 'SOUTH AFRICA' },
    { code: 'ZMW', ctry: 'ZAMBIA' },
    { code: 'ZWL', ctry: 'ZIMBABWE' },
  ]

  return currencyCodes
}

export function currencyCodes2() {
  let callingCodes = [
    { code: 'EUR', ctry: 'Åland Islands', currency: 'Euro' },
    { code: 'AFN', ctry: 'Afghanistan', currency: 'Afghani' },
    { code: 'ALL', ctry: 'Albania', currency: 'Lek' },
    { code: 'DZD', ctry: 'Algeria', currency: 'Algerian Dinar' },
    { code: 'USD', ctry: 'American Samoa', currency: 'US Dollar' },
    { code: 'EUR', ctry: 'Andorra', currency: 'Euro' },
    { code: 'AOA', ctry: 'Angola', currency: 'Kwanza' },
    { code: 'XCD', ctry: 'Anguilla', currency: 'East Caribbean Dollar' },
    {
      code: 'XCD',
      ctry: 'Antigua and Barbuda',
      currency: 'East Caribbean Dollar',
    },
    { code: 'ARS', ctry: 'Argentina', currency: 'Argentine Peso' },
    { code: 'AMD', ctry: 'Armenia', currency: 'Armenian Dram' },
    { code: 'AWG', ctry: 'Aruba', currency: 'Aruban Florin' },
    { code: 'SHP', ctry: 'Ascension Island', currency: 'Saint Helena pound' },
    { code: 'AUD', ctry: 'Australia', currency: 'Australian Dollar' },
    {
      code: 'AUD',
      ctry: 'Australian Antarctic Territory',
      currency: 'Australian Dollar',
    },
    { code: 'EUR', ctry: 'Austria', currency: 'Euro' },
    { code: 'AZN', ctry: 'Azerbaijan', currency: 'Azerbaijanian Manat' },
    { code: 'BSD', ctry: 'Bahamas', currency: 'Bahamian Dollar' },
    { code: 'BHD', ctry: 'Bahrain', currency: 'Bahraini Dinar' },
    { code: 'BDT', ctry: 'Bangladesh', currency: 'Taka' },
    { code: 'BBD', ctry: 'Barbados', currency: 'Barbados Dollar' },
    { code: 'BYN', ctry: 'Belarus', currency: 'Belarussian Ruble' },
    { code: 'EUR', ctry: 'Belgium', currency: 'Euro' },
    { code: 'BZD', ctry: 'Belize', currency: 'Belize Dollar' },
    { code: 'XOF', ctry: 'Benin', currency: '	CFA Franc' },
    { code: 'BMD', ctry: 'Bermuda', currency: 'Bermudian Dollar' },
    { code: 'BTN', ctry: 'Bhutan', currency: 'Ngultrum' },
    { code: 'INR', ctry: 'Bhutan', currency: 'Indian Rupee' },
    { code: 'BOB', ctry: 'Bolivia', currency: 'Boliviano' },
    // { code: 'BOV', ctry: 'Bolivia', currency: 'Mvdol' },
    {
      code: 'BAM',
      ctry: 'Bosnia and Herzegovina',
      currency: 'Convertible Mark',
    },
    { code: 'BWP', ctry: 'Botswana', currency: 'Pula' },
    { code: 'NOK', ctry: 'Bouvet Island', currency: 'Norwegian Krone' },
    { code: 'BRL', ctry: 'Brazil', currency: 'Brazilian Real' },
    {
      code: 'USD',
      ctry: 'British Indian Ocean Territory',
      currency: 'US Dollar',
    },
    { code: 'USD', ctry: 'British Virgin Islands', currency: 'US Dollar' },
    { code: 'BND', ctry: 'Brunei', currency: 'Brunei Dollar' },
    { code: 'BGN', ctry: 'Bulgaria', currency: 'Bulgarian Lev' },
    { code: 'XOF', ctry: 'Burkina Faso', currency: 'CFA Franc BCEAO' },
    { code: 'BIF', ctry: 'Burundi', currency: 'Burundi Franc' },
    { code: 'KHR', ctry: 'Cambodia', currency: 'Riel' },
    { code: 'XAF', ctry: 'Cameroon', currency: 'CFA Franc BEAC' },
    { code: 'CAD', ctry: 'Canada', currency: 'Canadian Dollar' },
    { code: 'CVE', ctry: 'Cape Verde', currency: 'Cabo Verde Escudo' },
    { code: 'USD', ctry: 'Caribbean Netherlands', currency: 'U.S. dollar' },
    { code: 'KYD', ctry: 'Cayman Islands', currency: 'Cayman Islands Dollar' },
    {
      code: 'XAF',
      ctry: 'Central African Republic',
      currency: 'CFA Franc BEAC',
    },
    { code: 'XAF', ctry: 'Chad', currency: 'CFA Franc BEAC' },
    { code: 'CLF', ctry: 'Chile', currency: 'Unidad de Fomento' },
    { code: 'CLP', ctry: 'Chile', currency: 'Chilean Peso' },
    { code: '	CNY', ctry: 'China', currency: 'Yuan Renminbi' },
    { code: 'AUD', ctry: 'Christmas Island', currency: 'Australian dollar' },
    {
      code: 'AUD',
      ctry: 'Cocos (Keeling) Islands',
      currency: 'Australian dollar',
    },
    { code: 'COP', ctry: 'Colombia', currency: 'Colombian Peso' },
    { code: 'COU', ctry: 'Colombia', currency: 'Unidad de Valor Real' },
    { code: 'KMF', ctry: 'Comoros', currency: 'Comoro Franc' },
    { code: 'NZD', ctry: 'Cook Islands', currency: 'New Zealand Dollar' },
    { code: 'CRC', ctry: 'Costa Rica', currency: 'Costa Rican Colon' },
    { code: 'HRK', ctry: 'Croatia', currency: 'Kuna' },
    { code: 'CUC', ctry: 'Cuba', currency: 'Peso Convertible' },
    { code: 'CUP', ctry: 'Cuba', currency: 'Cuban Peso' },
    { code: 'ANG', ctry: 'Curaçao', currency: 'Netherlands Antillean Guilder' },
    { code: 'EUR', ctry: 'Cyprus', currency: 'Euro' },
    { code: 'CZK', ctry: 'Czech Republic', currency: 'Czech Koruna' },
    {
      code: 'CDF',
      ctry: 'Democratic Republic of the Congo',
      currency: 'Congolese Franc',
    },
    { code: 'DKK', ctry: 'Denmark', currency: 'Danish Krone' },
    { code: 'DJF', ctry: 'Djibouti', currency: 'Djibouti Franc' },
    { code: 'XCD', ctry: 'Dominica', currency: 'East Caribbean Dollar' },
    { code: 'DOP', ctry: 'Dominican Republic', currency: 'Dominican Peso' },
    { code: 'USD', ctry: 'East Timor', currency: 'US Dollar' },
    { code: 'USD', ctry: 'Ecuador', currency: 'US Dollar' },
    { code: 'EGP', ctry: 'Egypt', currency: 'Egyptian Pound' },
    { code: 'SVC', ctry: 'El Salvador', currency: 'El Salvador Colon' },
    { code: 'XAF', ctry: 'Equatorial Guinea', currency: 'CFA Franc BEAC' },
    { code: 'ERN', ctry: 'Eritrea', currency: 'Nakfa' },
    { code: 'EUR', ctry: 'Estonia', currency: 'Euro' },
    { code: 'SZL', ctry: 'Eswatini', currency: 'Swaziland lilangeni' },
    { code: 'ETB', ctry: 'Ethiopia', currency: 'Ethiopian Birr' },
    {
      code: 'FKP',
      ctry: 'Falkland Islands',
      currency: 'Falkland Islands Pound',
    },
    { code: 'DKK', ctry: 'Faroe Islands', currency: 'Danish Krone' },
    { code: 'FJD', ctry: 'Fiji', currency: 'Fiji Dollar' },
    { code: 'EUR', ctry: 'Finland', currency: 'Euro' },
    { code: 'EUR', ctry: 'France', currency: 'Euro' },
    { code: 'EUR', ctry: 'French Guiana', currency: 'Euro' },
    { code: 'XPF', ctry: 'French Polynesia', currency: 'CFP Franc' },
    {
      code: 'EUR',
      ctry: 'French Southern and Antarctic Lands',
      currency: 'Euro',
    },
    { code: 'XAF', ctry: 'Gabon', currency: 'CFA Franc BEAC' },
    { code: 'GMD', ctry: 'Gambia', currency: 'Dalasi' },
    { code: 'GEL', ctry: 'Georgia', currency: 'Lari' },
    { code: 'EUR', ctry: 'Germany', currency: 'Euro' },
    { code: 'GHS', ctry: 'Ghana', currency: 'Ghana Cedi' },
    { code: 'GIP', ctry: 'Gibraltar', currency: 'Gibraltar Pound' },
    { code: 'EUR', ctry: 'Greece', currency: 'Euro' },
    { code: 'DKK', ctry: 'Greenland', currency: 'Danish Krone' },
    { code: 'XCD', ctry: 'Grenada', currency: 'East Caribbean Dollar' },
    { code: 'EUR', ctry: 'Guadeloupe', currency: 'Euro' },
    { code: 'USD', ctry: 'Guam', currency: 'US Dollar' },
    { code: 'GTQ', ctry: 'Guatemala', currency: 'Quetzal' },
    { code: 'GBP', ctry: 'Guernsey', currency: 'Pound Sterling' },
    { code: 'GNF', ctry: 'Guinea', currency: 'Guinea Franc' },
    { code: 'XOF', ctry: 'Guinea-Bissau', currency: 'CFA Franc BCEAO' },
    { code: 'GYD', ctry: 'Guyana', currency: 'Guyana Dollar' },
    { code: 'HTG', ctry: 'Haiti', currency: 'Gourde' },
    {
      code: 'AUD',
      ctry: 'Heard Island and McDonald Islands',
      currency: 'Australian Dollar',
    },
    { code: 'HNL', ctry: 'Honduras', currency: 'Lempira' },
    { code: 'HKD', ctry: 'Hong Kong', currency: '	Hong Kong Dollar' },
    { code: 'HUF', ctry: 'Hungary', currency: 'Forint' },
    { code: '	ISK', ctry: 'Iceland', currency: 'Iceland Krona' },
    { code: 'INR', ctry: 'India', currency: 'Indian Rupee' },
    { code: 'IDR', ctry: 'Indonesia', currency: 'Rupiah' },
    { code: 'IRR', ctry: 'Iran', currency: 'Iranian Rial' },
    { code: 'IQD', ctry: 'Iraq', currency: 'Iraqi Dinar' },
    { code: 'EUR', ctry: 'Ireland', currency: 'Euro' },
    { code: 'GBP', ctry: 'Isle of Man', currency: 'Pound' },
    { code: 'ILS', ctry: 'Israel', currency: 'New Israeli Sheqel' },
    { code: 'EUR', ctry: 'Italy', currency: 'Euro' },
    { code: 'XOF', ctry: 'Ivory Coast', currency: '	CFA Franc BCEAO' },
    { code: 'JMD', ctry: 'Jamaica', currency: 'Jamaican Dollar' },
    { code: 'JPY', ctry: 'Japan', currency: 'Yen' },
    { code: 'GBP', ctry: 'Jersey', currency: 'Pound Sterling' },
    { code: 'JOD', ctry: 'Jordan', currency: 'Jordanian Dinar' },
    { code: 'KZT', ctry: 'Kazakhstan', currency: 'Tenge' },
    { code: 'KES', ctry: 'Kenya', currency: 'Kenyan Shilling' },
    { code: 'AUD', ctry: 'Kiribati', currency: 'Australian Dollar' },
    { code: 'EUR', ctry: 'Kosovo', currency: 'Euro' },
    { code: 'KWD', ctry: 'Kuwait', currency: 'Kuwaiti Dinar' },
    { code: 'KGS', ctry: 'Kyrgyzstan', currency: 'Som' },
    { code: 'LAK', ctry: 'Laos', currency: 'Kip' },
    { code: 'EUR', ctry: 'Latvia', currency: 'Euro' },
    { code: 'LBP', ctry: 'Lebanon', currency: 'Lebanese Pound' },
    { code: 'LSL', ctry: 'Lesotho', currency: 'Loti' },
    { code: 'ZAR', ctry: 'Lesotho', currency: 'Rand' },
    { code: 'LRD', ctry: 'Liberia', currency: 'Liberian Dollar' },
    { code: 'LYD', ctry: 'Libya', currency: 'Libyan Dinar' },
    { code: 'CHF', ctry: 'Liechtenstein', currency: 'Swiss Franc' },
    { code: 'EUR', ctry: 'Lithuania', currency: 'Euro' },
    { code: 'EUR', ctry: 'Luxembourg', currency: 'Euro' },
    { code: 'MOP', ctry: 'Macao', currency: 'Pataca' },
    { code: 'MGA', ctry: 'Madagascar', currency: 'Malagasy Ariary' },
    { code: 'MWK', ctry: 'Malawi', currency: 'Kwacha' },
    { code: 'MYR', ctry: 'Malaysia', currency: 'Malaysian Ringgit' },
    { code: 'MVR', ctry: 'Maldives', currency: 'Rufiyaa' },
    { code: 'XOF', ctry: 'Mali', currency: 'CFA Franc BCEAO' },
    { code: 'EUR', ctry: 'Malta', currency: 'Euro' },
    { code: 'USD', ctry: 'Marshall Islands', currency: 'US Dollar' },
    { code: 'EUR', ctry: 'Martinique', currency: 'Euro' },
    { code: 'MRU', ctry: 'Mauritania', currency: 'Ouguiya' },
    { code: 'MUR', ctry: 'Mauritius', currency: 'Mauritius Rupee' },
    { code: 'EUR', ctry: 'Mayotte', currency: 'Euro' },
    { code: 'MXN', ctry: 'Mexico', currency: 'Mexican Peso' },
    { code: 'MXV', ctry: 'Mexico', currency: 'Mexican Unidad de Inversion' },
    { code: 'USD', ctry: 'Micronesia', currency: 'US Dollar' },
    { code: 'MDL', ctry: 'Moldova', currency: 'Moldovan Leu' },
    { code: 'EUR', ctry: 'Monaco', currency: 'Euro' },
    { code: 'MNT', ctry: 'Mongolia', currency: 'Tugrik' },
    { code: 'EUR', ctry: 'Montenegro', currency: 'Euro' },
    { code: 'XCD', ctry: 'Montserrat', currency: 'East Caribbean Dollar' },
    { code: 'MAD', ctry: 'Morocco', currency: 'Moroccan Dirham' },
    { code: 'MZN', ctry: 'Mozambique', currency: 'Mozambique Metical' },
    { code: 'MMK', ctry: 'Myanmar', currency: 'Kyat' },
    { code: 'NAD', ctry: 'Namibia', currency: 'Namibia Dollar' },
    { code: 'ZAR', ctry: 'Namibia', currency: '	Rand' },
    { code: 'AUD', ctry: 'Nauru', currency: 'Australian Dollar' },
    { code: 'NPR', ctry: 'Nepal', currency: 'Nepalese Rupee' },
    { code: 'EUR', ctry: 'Netherlands', currency: 'Euro' },
    { code: 'EUR', ctry: 'Netherlands Antilles', currency: 'Euro' },
    { code: 'XPF', ctry: 'New Caledonia', currency: 'CFP Franc' },
    { code: 'NZD', ctry: 'New Zealand', currency: 'New Zealand Dollar' },

    { code: 'NIO', ctry: 'Nicaragua', currency: 'Cordoba Oro' },
    { code: 'XOF', ctry: 'Niger', currency: 'CFA Franc BCEAO' },
    { code: 'NGN', ctry: 'Nigeria', currency: 'Naira' },
    { code: 'NZD', ctry: 'Niue', currency: 'New Zealand Dollar' },
    { code: 'AUD', ctry: 'Norfolk Island', currency: 'Australian Dollar' },
    { code: 'KPW', ctry: 'North Korea', currency: '₩' },
    { code: 'MKD', ctry: 'North Macedonia', currency: 'Macedonian Denar' },
    { code: 'EUR', ctry: 'Northern Ireland', currency: 'Euro' },
    { code: 'USD', ctry: 'Northern Mariana Islands', currency: 'US Dollar' },
    { code: 'NOK', ctry: 'Norway', currency: 'Norwegian Krone' },
    { code: 'OMR', ctry: 'Oman', currency: 'Rial Omani' },
    { code: 'PKR', ctry: 'Pakistan', currency: 'Pakistan Rupee' },
    { code: 'USD', ctry: 'Palau', currency: '	US Dollar' },
    { code: 'USD', ctry: 'Palestine', currency: '	US Dollar' },
    { code: 'PAB', ctry: 'Panama', currency: 'Balboa' },
    { code: 'PGK', ctry: 'Papua New Guinea', currency: 'Kina' },
    { code: '	PYG', ctry: 'Paraguay', currency: 'Guarani' },
    { code: 'PEN', ctry: 'Peru', currency: 'Nuevo Sol' },
    { code: 'PHP', ctry: 'Philippines', currency: 'Philippine Peso' },
    { code: 'NZD', ctry: 'Pitcairn', currency: 'New Zealand Dollar' },
    { code: 'PLN', ctry: 'Poland', currency: 'Zloty' },
    { code: 'EUR', ctry: 'Portugal', currency: 'Euro' },
    { code: 'USD', ctry: 'Puerto Rico', currency: 'US Dollar' },
    { code: 'QAR', ctry: 'Qatar', currency: 'Qatari Rial' },
    { code: 'EUR', ctry: 'Réunion', currency: 'Euro' },
    { code: 'XAF', ctry: 'Republic of the Congo', currency: 'CFA Franc BEAC' },
    { code: 'RON', ctry: 'Romania', currency: 'Romanian Leu' },
    { code: 'RUB', ctry: 'Russia', currency: 'Russian Ruble' },
    { code: 'RWF', ctry: 'Rwanda', currency: 'Rwanda Franc' },
    { code: 'EUR', ctry: 'Saint Barthélemy', currency: 'Euro' },
    { code: 'SHP', ctry: 'Saint Helena', currency: 'Saint Helena Pound' },
    {
      code: 'XCD',
      ctry: 'Saint Kitts and Nevis',
      currency: 'East Caribbean Dollar',
    },
    { code: 'GBP', ctry: 'British Indian Ocean Territory', currency: 'Pound' },
    { code: 'XCD', ctry: 'Saint Lucia', currency: 'East Caribbean Dollar' },
    { code: 'EUR', ctry: 'Saint Martin', currency: 'Euro' },
    { code: 'EUR', ctry: 'Saint Pierre and Miquelon', currency: 'Euro' },
    {
      code: 'XCD',
      ctry: 'Saint Vincent and the Grenadines',
      currency: 'East Caribbean Dollar',
    },
    { code: 'WST', ctry: 'Samoa', currency: 'Tala' },
    { code: 'EUR', ctry: 'San Marino', currency: 'Euro' },
    { code: 'STN', ctry: 'Sao Tome and Principe', currency: 'Dobra' },
    { code: 'SAR', ctry: 'Saudi Arabia', currency: 'Saudi Riyal' },
    { code: 'XOF', ctry: 'Senegal', currency: 'CFA Franc BCEAO' },
    { code: 'RSD', ctry: 'Serbia', currency: 'Serbian Dinar' },
    { code: '	SCR', ctry: 'Seychelles', currency: 'Seychelles Rupee' },
    { code: 'SLL', ctry: 'Sierra Leone', currency: 'SLL' },
    { code: 'SGD', ctry: 'Singapore', currency: 'Singapore Dollar' },
    { code: 'EUR', ctry: 'Slovakia', currency: 'Euro' },
    { code: 'EUR', ctry: 'Slovenia', currency: 'Euro' },
    {
      code: 'SBD',
      ctry: 'Solomon Islands',
      currency: 'Solomon Islands Dollar',
    },
    { code: 'SOS', ctry: 'Somalia', currency: 'Somali Shilling' },
    { code: 'ZAR', ctry: 'South Africa', currency: 'Rand' },
    {
      code: 'GBP',
      ctry: 'South Georgia and the South Sandwich Islands',
      currency: 'Pound',
    },
    { code: 'KRW', ctry: 'South Korea', currency: '₩' },
    { code: 'SDG', ctry: 'South Sudan', currency: 'Sudanese Pound' },
    { code: 'EUR', ctry: 'Spain', currency: 'Euro' },
    { code: 'LKR', ctry: 'Sri Lanka', currency: 'Sri Lanka Rupee' },
    { code: 'SDG', ctry: 'Sudan', currency: 'Sudanese Pound' },
    { code: 'SRD', ctry: 'Suriname', currency: 'Surinam Dollar' },
    {
      code: 'NOK',
      ctry: 'Svalbard and Jan Mayen',
      currency: 'Norwegian Krone',
    },
    { code: 'SEK', ctry: 'Sweden', currency: 'Swedish Krona' },
    { code: 'CHE', ctry: 'Switzerland', currency: 'CHE' },
    { code: 'SYP', ctry: 'Syria', currency: 'Syrian Pound' },
    { code: 'TWD', ctry: 'Taiwan', currency: 'New Taiwan Dollar' },
    { code: 'TJS', ctry: 'Tajikistan', currency: 'Somoni' },
    { code: 'TZS', ctry: 'Tanzania', currency: 'Tanzanian Shilling' },
    { code: 'THB', ctry: 'Thailand', currency: 'Baht' },
    { code: 'XOF', ctry: 'Togo', currency: 'CFA Franc BCEAO' },
    { code: 'NZD', ctry: 'Tokelau', currency: 'New Zealand Dollar' },
    { code: 'TOP', ctry: 'Tonga', currency: 'Pa’anga' },
    {
      code: 'TTD',
      ctry: 'Trinidad and Tobago',
      currency: 'Trinidad and Tobago Dollar',
    },
    { code: 'TND', ctry: 'Tunisia', currency: 'Tunisian Dinar' },
    { code: 'TRY', ctry: 'Turkey', currency: 'Turkish Lira' },
    { code: 'TMT', ctry: 'Turkmenistana', currency: 'Turkmenistan New Manat' },
    { code: 'USD', ctry: 'Turks and Caicos Islands', currency: 'US Dollar' },
    { code: 'AUD', ctry: 'Tuvalu', currency: 'Australian Dollar' },
    { code: 'UGX', ctry: 'Uganda', currency: 'Uganda Shilling' },
    { code: 'UAH', ctry: 'Ukraine', currency: 'Hryvnia' },
    { code: 'AED', ctry: 'United Arab Emirates', currency: 'UAE Dirham' },
    { code: 'GBP', ctry: 'United Kingdom', currency: 'Pound Sterling' },
    { code: 'USD', ctry: 'United States', currency: 'US Dollar' },
    {
      code: 'USD',
      ctry: 'United States Minor Outlying Islands',
      currency: 'US Dollar',
    },
    {
      code: 'UYI',
      ctry: 'Uruguay',
      currency: 'Uruguay Peso en Unidades Indexadas',
    },
    { code: 'UZS', ctry: 'Uzbekistan', currency: 'Uzbekistan Sum' },
    { code: 'VUV', ctry: 'Vanuatu', currency: 'Vatu' },
    { code: 'EUR', ctry: 'Vatican City', currency: 'Euro' },
    { code: 'VEF', ctry: 'Venezuela', currency: 'Bolivar' },
    { code: 'VND', ctry: 'Vietnam', currency: 'Dong' },
    { code: 'USD', ctry: 'Virgin Islands, U.S.', currency: 'US Dollar' },
    { code: 'XPF', ctry: 'Wallis and Futuna', currency: 'CFP Franc' },
    { code: 'MAD', ctry: 'Western Sahara', currency: 'Moroccan Dirham' },
    { code: 'YER', ctry: 'Yemen', currency: 'YER' },
    { code: 'ZMW', ctry: 'Zambia', currency: 'Zambian Kwacha' },
    { code: 'ZWL', ctry: 'Zimbabwe', currency: 'Zimbabwe Dollar' },
  ]

  return callingCodes
}

// 'BENIN',
//   'Botswana',
//   'Burkina_Faso',
//   'Burundi',
//   'Cameroon',
//   'Cabo_Verde',
//   'Central_Africa_Republic',
//   'Chad',
//   'Comoros',
//   'Congo',
//   'Democratic_Republic_Of_The_Congo',
//   'Djibouti',
//   'Egypt',
//   'Equatorial_Guinea',
//   'Eritrea',
//   'Ethiopia',
//   'French_Southern_territory',
//   'Gabon',
//   'Gambia',
//   'Ghana',
//   'Guinea',
//   'Guinea_Bissau',
//   'Cote_DIvoire',
//   'Kenya',
//   'Lesotho',
//   'Liberia',
//   'Libya',
//   'Madagascar',
//   'Malawi',
//   'Mali',
//   'Mauritania',
//   'Mauritius',
//   'Mayotte',
//   'Morocco',
//   'Mozambique',
//   'Namibia',
//   'Niger',
//   'Nigeria',
//   'Reunion',
//   'Rwanda',
//   'Saint_Helana',
//   'Sao_Tome_And_Principe',
//   'Senegal',
//   'SeyChelles',
//   'Sierra_leon',
//   'Somalia',
//   'South_Africa',
//   'South_Sudan',
//   'Sudan',
//   'Swaziland',
//   'Tanzania',
//   'Togo',
//   'Tunisia',
//   'Uganda',
//   'Western_Sahara',
//   'Zambia',
//   'Zimbabwe'
