export function signLeaseAnimation() {
  return {
    nm: 'Loading Files',
    mn: '',
    layers: [
      {
        ty: 4,
        nm: 'Search',
        mn: '',
        sr: 1,
        st: 0,
        op: 510,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [352.535, 96.918, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.69, y: 0 },
                i: { x: 0.97, y: 1 },
                s: [351.535, 97.918, 0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [351.535, 99.418, 0],
                t: 30,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Subtraction 48',
            ix: 1,
            cix: 2,
            np: 3,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [16.521, -0.045],
                      [-0.013, -34.356],
                      [-34.356, 0.012],
                      [0.011, 34.356],
                      [11.651, 11.659],
                    ],
                    o: [
                      [-34.356, 0.012],
                      [0.011, 34.356],
                      [34.356, -0.013],
                      [-0.006, -16.483],
                      [-11.651, -11.715],
                    ],
                    v: [
                      [-0.089, -62.489],
                      [-62.274, -0.26],
                      [-0.046, 61.927],
                      [62.142, -0.304],
                      [43.936, -44.254],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 2',
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [9.639, 0.013],
                      [-0.054, 41.136],
                      [-41.136, -0.054],
                      [0.054, -41.137],
                      [28.059, -11.391],
                    ],
                    o: [
                      [-41.136, -0.054],
                      [0.054, -41.137],
                      [41.136, 0.055],
                      [-0.039, 30.282],
                      [-8.929, 3.626],
                    ],
                    v: [
                      [-0.098, 74.485],
                      [-74.484, -0.097],
                      [0.098, -74.485],
                      [74.484, 0.098],
                      [28.019, 69.014],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.7922, 0.8078, 0.8353], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [383.401, 89.476], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Rectangle 4757',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.999, -2.999],
                      [0, 0],
                      [2.999, 2.999],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.999, 2.999],
                      [0, 0],
                      [-2.999, 2.999],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-25.254, -8.902],
                      [-8.902, -25.254],
                      [-8.902, -25.254],
                      [23.005, 6.653],
                      [23.005, 17.514],
                      [17.514, 23.005],
                      [6.653, 23.005],
                      [-25.254, -8.902],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.7137, 0.7333, 0.7686], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [446.9, 153.591], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 0,
      },
      {
        ty: 4,
        nm: 'Files 5',
        mn: '',
        sr: 1,
        st: 0,
        op: 510,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [723.194, 96.918, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [165, 165, 100],
                t: 30,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [723.194, 100.918, 0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [571.694, 97.418, 0],
                t: 30,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              { o: { x: 0.167, y: 0.172 }, i: { x: 0.24, y: 1 }, s: [0], t: 0 },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [50],
                t: 30,
              },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2951',
            ix: 1,
            cix: 2,
            np: 6,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.019, -0.326],
                      [0, 0],
                      [0.326, -0.019],
                      [0, 0],
                      [0.019, 0.326],
                      [0, 0],
                      [-0.326, 0.019],
                    ],
                    o: [
                      [0, 0],
                      [0.326, 0.019],
                      [0, 0],
                      [-0.019, 0.326],
                      [0, 0],
                      [-0.326, -0.019],
                      [0, 0],
                      [0.019, -0.326],
                      [0, 0],
                    ],
                    v: [
                      [-18.935, -8.56],
                      [-10.273, -8.56],
                      [-9.669, -7.956],
                      [-9.669, -5.943],
                      [-10.273, -5.338],
                      [-18.935, -5.338],
                      [-19.54, -5.943],
                      [-19.54, -7.957],
                      [-18.935, -8.562],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 2',
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.019, -0.326],
                      [0, 0],
                      [0.326, -0.019],
                      [0, 0],
                      [0.019, 0.326],
                      [0, 0],
                      [-0.326, 0.019],
                    ],
                    o: [
                      [0, 0],
                      [0.326, 0.019],
                      [0, 0],
                      [-0.019, 0.326],
                      [0, 0],
                      [-0.326, -0.019],
                      [0, 0],
                      [0.019, -0.326],
                      [0, 0],
                    ],
                    v: [
                      [-18.935, -0.906],
                      [18.935, -0.906],
                      [19.54, -0.301],
                      [19.54, 1.713],
                      [18.935, 2.318],
                      [-18.935, 2.318],
                      [-19.54, 1.713],
                      [-19.54, -0.302],
                      [-18.935, -0.907],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 3',
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.325, 0.019],
                      [0, 0],
                      [-0.019, -0.326],
                      [0, 0],
                      [0.326, -0.019],
                      [0, 0],
                      [0.019, 0.326],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.326, 0.019],
                      [0, 0],
                      [-0.019, 0.326],
                      [0, 0],
                      [-0.326, -0.019],
                      [0, 0],
                      [0.019, -0.325],
                    ],
                    v: [
                      [-18.935, 5.943],
                      [18.935, 5.943],
                      [19.54, 6.547],
                      [19.54, 8.562],
                      [18.935, 9.166],
                      [-18.935, 9.166],
                      [-19.54, 8.562],
                      [-19.54, 6.546],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 4',
                ix: 4,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.326, 0.019],
                      [0, 0],
                      [-0.019, -0.326],
                      [0, 0],
                      [0.326, -0.019],
                      [0, 0],
                      [0.019, 0.326],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.326, 0.019],
                      [0, 0],
                      [-0.019, 0.326],
                      [0, 0],
                      [-0.326, -0.019],
                      [0, 0],
                      [0.019, -0.326],
                    ],
                    v: [
                      [-18.935, 12.791],
                      [-0.604, 12.791],
                      [0.001, 13.396],
                      [0.001, 15.41],
                      [-0.604, 16.014],
                      [-18.935, 16.014],
                      [-19.54, 15.41],
                      [-19.54, 13.396],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 5',
                ix: 5,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.326, 0.019],
                      [0, 0],
                      [-0.019, -0.326],
                      [0, 0],
                      [0.326, -0.019],
                      [0, 0],
                      [0.019, 0.326],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.326, 0.019],
                      [0, 0],
                      [-0.019, 0.326],
                      [0, 0],
                      [-0.326, -0.019],
                      [0, 0],
                      [0.019, -0.326],
                    ],
                    v: [
                      [-18.935, -16.014],
                      [-6.446, -16.014],
                      [-5.842, -15.41],
                      [-5.842, -13.396],
                      [-6.446, -12.791],
                      [-18.935, -12.791],
                      [-19.54, -13.396],
                      [-19.54, -15.41],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.698, 0.7569, 0.8196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [723.194, 96.817], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2950',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-9.468, -9.468],
                      [9.468, 9.468],
                      [-9.468, 9.468],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.698, 0.7569, 0.8196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [738.705, 74.961], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2949',
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.025, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [2.872, -0.021],
                      [0.025, 0],
                      [0, 0],
                      [0.022, 2.872],
                      [0, 0.026],
                      [0, 0],
                      [-2.872, 0.021],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.021, 2.872],
                      [-0.025, 0],
                      [0, 0],
                      [-2.872, 0.022],
                      [0, -0.026],
                      [0, 0],
                      [-0.021, -2.872],
                      [0.025, 0],
                    ],
                    v: [
                      [-19.64, -31.424],
                      [5.942, -31.424],
                      [24.878, -12.489],
                      [24.878, 26.187],
                      [19.715, 31.424],
                      [19.64, 31.424],
                      [-19.638, 31.424],
                      [-24.878, 26.264],
                      [-24.878, 26.187],
                      [-24.878, -26.187],
                      [-19.715, -31.424],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.851, 0.8902, 0.9216], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [723.094, 96.918], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'Files 4',
        mn: '',
        sr: 1,
        st: 0,
        op: 510,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [570.918, 96.918, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [126, 126, 100],
                t: 30,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [570.918, 96.918, 0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [390.918, 96.918, 0],
                t: 30,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [50],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [100],
                t: 30,
              },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2951',
            ix: 1,
            cix: 2,
            np: 6,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                      [-0.549, 0.032],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.032, -0.549],
                      [0, 0],
                    ],
                    v: [
                      [-31.928, -14.435],
                      [-17.322, -14.435],
                      [-16.303, -13.415],
                      [-16.303, -10.021],
                      [-17.322, -9.001],
                      [-31.928, -9.001],
                      [-32.948, -10.021],
                      [-32.948, -13.417],
                      [-31.928, -14.436],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 2',
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                      [-0.549, 0.032],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.032, -0.549],
                      [0, 0],
                    ],
                    v: [
                      [-31.928, -1.527],
                      [31.928, -1.527],
                      [32.948, -0.508],
                      [32.948, 2.889],
                      [31.928, 3.908],
                      [-31.928, 3.908],
                      [-32.948, 2.889],
                      [-32.948, -0.51],
                      [-31.928, -1.529],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 3',
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.549, 0.032],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.033, -0.548],
                    ],
                    v: [
                      [-31.928, 10.021],
                      [31.928, 10.021],
                      [32.948, 11.04],
                      [32.948, 14.436],
                      [31.928, 15.456],
                      [-31.928, 15.456],
                      [-32.948, 14.436],
                      [-32.948, 11.038],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 4',
                ix: 4,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.549, 0.032],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.032, -0.549],
                    ],
                    v: [
                      [-31.928, 21.568],
                      [-1.018, 21.568],
                      [0.001, 22.588],
                      [0.001, 25.984],
                      [-1.018, 27.004],
                      [-31.928, 27.004],
                      [-32.948, 25.984],
                      [-32.948, 22.588],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 5',
                ix: 5,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.549, 0.032],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.032, -0.549],
                    ],
                    v: [
                      [-31.928, -27.004],
                      [-10.87, -27.004],
                      [-9.85, -25.984],
                      [-9.85, -22.588],
                      [-10.87, -21.568],
                      [-31.928, -21.568],
                      [-32.948, -22.588],
                      [-32.948, -25.984],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.698, 0.7569, 0.8196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [570.918, 96.747], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2950',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-15.965, -15.965],
                      [15.965, 15.965],
                      [-15.965, 15.965],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.698, 0.7569, 0.8196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [597.073, 59.895], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2949',
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.042, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.843, -0.035],
                      [0.042, 0],
                      [0, 0],
                      [0.037, 4.843],
                      [0, 0.043],
                      [0, 0],
                      [-4.843, 0.035],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.035, 4.843],
                      [-0.042, 0],
                      [0, 0],
                      [-4.843, 0.037],
                      [0, -0.043],
                      [0, 0],
                      [-0.035, -4.843],
                      [0.042, 0],
                    ],
                    v: [
                      [-33.117, -52.988],
                      [10.02, -52.988],
                      [41.949, -21.059],
                      [41.949, 44.156],
                      [33.243, 52.988],
                      [33.117, 52.988],
                      [-33.113, 52.988],
                      [-41.949, 44.286],
                      [-41.949, 44.156],
                      [-41.949, -44.156],
                      [-33.243, -52.988],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.851, 0.8902, 0.9216], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [570.748, 96.918], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 2,
      },
      {
        ty: 4,
        nm: 'Files 3',
        mn: '',
        sr: 1,
        st: 0,
        op: 510,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [390.535, 96.918, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [78, 78, 100],
                t: 30,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [390.535, 96.918, 0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [210.535, 96.918, 0],
                t: 30,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [50],
                t: 30,
              },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2951',
            ix: 1,
            cix: 2,
            np: 6,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.04, -0.692],
                      [0, 0],
                      [0.692, -0.04],
                      [0, 0],
                      [0.04, 0.692],
                      [0, 0],
                      [-0.692, 0.04],
                    ],
                    o: [
                      [0, 0],
                      [0.692, 0.04],
                      [0, 0],
                      [-0.04, 0.692],
                      [0, 0],
                      [-0.692, -0.04],
                      [0, 0],
                      [0.04, -0.692],
                      [0, 0],
                    ],
                    v: [
                      [-40.242, -18.193],
                      [-21.833, -18.193],
                      [-20.548, -16.908],
                      [-20.548, -12.63],
                      [-21.833, -11.345],
                      [-40.242, -11.345],
                      [-41.526, -12.63],
                      [-41.526, -16.911],
                      [-40.242, -18.195],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 2',
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.04, -0.692],
                      [0, 0],
                      [0.692, -0.04],
                      [0, 0],
                      [0.04, 0.692],
                      [0, 0],
                      [-0.692, 0.04],
                    ],
                    o: [
                      [0, 0],
                      [0.692, 0.04],
                      [0, 0],
                      [-0.04, 0.692],
                      [0, 0],
                      [-0.692, -0.04],
                      [0, 0],
                      [0.04, -0.692],
                      [0, 0],
                    ],
                    v: [
                      [-40.242, -1.925],
                      [40.242, -1.925],
                      [41.526, -0.64],
                      [41.526, 3.641],
                      [40.242, 4.926],
                      [-40.242, 4.926],
                      [-41.526, 3.641],
                      [-41.526, -0.642],
                      [-40.242, -1.927],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 3',
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.691, 0.04],
                      [0, 0],
                      [-0.04, -0.692],
                      [0, 0],
                      [0.692, -0.04],
                      [0, 0],
                      [0.04, 0.692],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.692, 0.04],
                      [0, 0],
                      [-0.04, 0.692],
                      [0, 0],
                      [-0.692, -0.04],
                      [0, 0],
                      [0.041, -0.691],
                    ],
                    v: [
                      [-40.242, 12.63],
                      [40.242, 12.63],
                      [41.526, 13.914],
                      [41.526, 18.195],
                      [40.242, 19.48],
                      [-40.242, 19.48],
                      [-41.526, 18.195],
                      [-41.526, 13.912],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 4',
                ix: 4,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.692, 0.04],
                      [0, 0],
                      [-0.04, -0.692],
                      [0, 0],
                      [0.692, -0.04],
                      [0, 0],
                      [0.04, 0.692],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.692, 0.04],
                      [0, 0],
                      [-0.04, 0.692],
                      [0, 0],
                      [-0.692, -0.04],
                      [0, 0],
                      [0.04, -0.692],
                    ],
                    v: [
                      [-40.242, 27.184],
                      [-1.284, 27.184],
                      [0.001, 28.469],
                      [0.001, 32.75],
                      [-1.284, 34.035],
                      [-40.242, 34.035],
                      [-41.526, 32.75],
                      [-41.526, 28.469],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 5',
                ix: 5,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.692, 0.04],
                      [0, 0],
                      [-0.04, -0.692],
                      [0, 0],
                      [0.692, -0.04],
                      [0, 0],
                      [0.04, 0.692],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.692, 0.04],
                      [0, 0],
                      [-0.04, 0.692],
                      [0, 0],
                      [-0.692, -0.04],
                      [0, 0],
                      [0.04, -0.692],
                    ],
                    v: [
                      [-40.242, -34.035],
                      [-13.7, -34.035],
                      [-12.415, -32.75],
                      [-12.415, -28.469],
                      [-13.7, -27.184],
                      [-40.242, -27.184],
                      [-41.526, -28.469],
                      [-41.526, -32.75],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.698, 0.7569, 0.8196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [390.534, 96.702], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2950',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-20.121, -20.121],
                      [20.121, 20.121],
                      [-20.121, 20.121],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.698, 0.7569, 0.8196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [423.5, 50.255], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2949',
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.053, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [6.104, -0.044],
                      [0.053, 0],
                      [0, 0],
                      [0.047, 6.104],
                      [0, 0.055],
                      [0, 0],
                      [-6.104, 0.044],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.044, 6.104],
                      [-0.053, 0],
                      [0, 0],
                      [-6.104, 0.047],
                      [0, -0.055],
                      [0, 0],
                      [-0.044, -6.104],
                      [0.053, 0],
                    ],
                    v: [
                      [-41.74, -66.785],
                      [12.628, -66.785],
                      [52.871, -26.542],
                      [52.871, 55.653],
                      [41.899, 66.785],
                      [41.74, 66.785],
                      [-41.735, 66.785],
                      [-52.871, 55.817],
                      [-52.871, 55.653],
                      [-52.871, -55.653],
                      [-41.899, -66.785],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.851, 0.8902, 0.9216], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [390.321, 96.918], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 3,
      },
      {
        ty: 4,
        nm: 'Files 2',
        mn: '',
        sr: 1,
        st: 0,
        op: 510,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [210.152, 96.918, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [100, 100, 100],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [60, 60, 100],
                t: 30,
              },
            ],
            ix: 6,
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [210.152, 96.918, 0],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [78.151, 96.918, 0],
                t: 30,
              },
            ],
            ix: 2,
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.167, y: 0.172 },
                i: { x: 0.24, y: 1 },
                s: [50],
                t: 0,
              },
              {
                o: { x: 0.167, y: 0.167 },
                i: { x: 0.833, y: 0.833 },
                s: [0],
                t: 30,
              },
            ],
            ix: 11,
          },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        shapes: [
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2951',
            ix: 1,
            cix: 2,
            np: 6,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                      [-0.549, 0.032],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.032, -0.549],
                      [0, 0],
                    ],
                    v: [
                      [-31.928, -14.435],
                      [-17.322, -14.435],
                      [-16.303, -13.415],
                      [-16.303, -10.021],
                      [-17.322, -9.001],
                      [-31.928, -9.001],
                      [-32.948, -10.021],
                      [-32.948, -13.417],
                      [-31.928, -14.436],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 2',
                ix: 2,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                      [-0.549, 0.032],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.032, -0.549],
                      [0, 0],
                    ],
                    v: [
                      [-31.928, -1.527],
                      [31.928, -1.527],
                      [32.948, -0.508],
                      [32.948, 2.889],
                      [31.928, 3.908],
                      [-31.928, 3.908],
                      [-32.948, 2.889],
                      [-32.948, -0.51],
                      [-31.928, -1.529],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 3',
                ix: 3,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.549, 0.032],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.033, -0.548],
                    ],
                    v: [
                      [-31.928, 10.021],
                      [31.928, 10.021],
                      [32.948, 11.04],
                      [32.948, 14.436],
                      [31.928, 15.456],
                      [-31.928, 15.456],
                      [-32.948, 14.436],
                      [-32.948, 11.038],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 4',
                ix: 4,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.549, 0.032],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.032, -0.549],
                    ],
                    v: [
                      [-31.928, 21.568],
                      [-1.018, 21.568],
                      [0.001, 22.588],
                      [0.001, 25.984],
                      [-1.018, 27.004],
                      [-31.928, 27.004],
                      [-32.948, 25.984],
                      [-32.948, 22.588],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 5',
                ix: 5,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.549, 0.032],
                      [0, 0],
                      [-0.032, -0.549],
                      [0, 0],
                      [0.549, -0.032],
                      [0, 0],
                      [0.032, 0.549],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0.549, 0.032],
                      [0, 0],
                      [-0.032, 0.549],
                      [0, 0],
                      [-0.549, -0.032],
                      [0, 0],
                      [0.032, -0.549],
                    ],
                    v: [
                      [-31.928, -27.004],
                      [-10.87, -27.004],
                      [-9.85, -25.984],
                      [-9.85, -22.588],
                      [-10.87, -21.568],
                      [-31.928, -21.568],
                      [-32.948, -22.588],
                      [-32.948, -25.984],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.698, 0.7569, 0.8196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [210.151, 96.747], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2950',
            ix: 2,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-15.965, -15.965],
                      [15.965, 15.965],
                      [-15.965, 15.965],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.698, 0.7569, 0.8196], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [236.306, 59.895], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
          {
            ty: 'gr',
            bm: 0,
            cl: '',
            ln: '',
            hd: false,
            mn: 'ADBE Vector Group',
            nm: 'Path 2949',
            ix: 3,
            cix: 2,
            np: 2,
            it: [
              {
                ty: 'sh',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Shape - Group',
                nm: 'Path 1',
                ix: 1,
                d: 1,
                ks: {
                  a: 0,
                  k: {
                    c: true,
                    i: [
                      [-0.042, 0],
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [4.843, -0.035],
                      [0.042, 0],
                      [0, 0],
                      [0.037, 4.843],
                      [0, 0.043],
                      [0, 0],
                      [-4.843, 0.035],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                      [0.035, 4.843],
                      [-0.042, 0],
                      [0, 0],
                      [-4.843, 0.037],
                      [0, -0.043],
                      [0, 0],
                      [-0.035, -4.843],
                      [0.042, 0],
                    ],
                    v: [
                      [-33.117, -52.988],
                      [10.02, -52.988],
                      [41.949, -21.059],
                      [41.949, 44.156],
                      [33.243, 52.988],
                      [33.117, 52.988],
                      [-33.113, 52.988],
                      [-41.949, 44.286],
                      [-41.949, 44.156],
                      [-41.949, -44.156],
                      [-33.243, -52.988],
                    ],
                  },
                  ix: 2,
                },
              },
              {
                ty: 'fl',
                bm: 0,
                cl: '',
                ln: '',
                hd: false,
                mn: 'ADBE Vector Graphic - Fill',
                nm: 'Fill 1',
                c: { a: 0, k: [0.851, 0.8902, 0.9216], ix: 4 },
                r: 1,
                o: { a: 0, k: 100, ix: 5 },
              },
              {
                ty: 'tr',
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                sk: { a: 0, k: 0, ix: 4 },
                p: { a: 0, k: [209.981, 96.918], ix: 2 },
                r: { a: 0, k: 0, ix: 6 },
                sa: { a: 0, k: 0, ix: 5 },
                o: { a: 0, k: 100, ix: 7 },
              },
            ],
          },
        ],
        ind: 4,
      },
      {
        ty: 1,
        nm: 'BG',
        mn: '',
        sr: 1,
        st: 0,
        op: 510,
        ip: 0,
        hd: false,
        cl: '',
        ln: '',
        ddd: 0,
        bm: 0,
        tt: 0,
        hasMask: false,
        td: 0,
        ao: 0,
        ks: {
          a: { a: 0, k: [400, 100, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
          sk: { a: 0, k: 0 },
          p: { a: 0, k: [400, 100, 0], ix: 2 },
          sa: { a: 0, k: 0 },
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
        },
        ef: [],
        sc: '#ffffff',
        sh: 200,
        sw: 800,
        ind: 5,
      },
    ],
    ddd: 0,
    h: 200,
    w: 800,
    meta: { a: '', k: '', d: '', g: 'LottieFiles AE ', tc: '#ffffff' },
    v: '4.8.0',
    fr: 30,
    op: 31,
    ip: 0,
    assets: [],
  }
}
