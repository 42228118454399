export function success_sign() {
  return {
    v: '4.8.0',
    meta: { g: 'LottieFiles AE 1.0.0', a: '', k: '', d: '', tc: '' },
    fr: 60,
    ip: 0,
    op: 240,
    w: 720,
    h: 720,
    nm: 'success',
    ddd: 0,
    assets: [
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'check',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-82.5, 4.5],
                          [-31, 55],
                          [73, -52.5],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: { a: 0, k: 0, ix: 1 },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.667], y: [1] },
                          o: { x: [1], y: [0.076] },
                          t: 60,
                          s: [0],
                        },
                        { t: 85, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 30, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 300,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Shape Layer 2',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [255, 257, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0.833], y: [0.98, 0.98, 1] },
                    o: { x: [0.656, 0.656, 0.167], y: [0.866, 0.866, 0] },
                    t: 20,
                    s: [0, 0, 100],
                  },
                  { t: 60, s: [151, 151, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [236, 236], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.35294117647058826, 0.403921568627451,
                        0.9647058823529412, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, -3], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 300,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Shape Layer 4',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.99], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 99,
                    s: [100],
                  },
                  {
                    i: { x: [0.626], y: [0.613] },
                    o: { x: [0.912], y: [0.104] },
                    t: 125,
                    s: [70],
                  },
                  { t: 168, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [255, 257, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0.667], y: [0.999, 0.999, 1] },
                    o: { x: [0.477, 0.477, 0.333], y: [0.587, 0.587, 0] },
                    t: 59,
                    s: [0, 0, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    t: 99,
                    s: [150, 150, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    t: 125,
                    s: [150, 150, 100],
                  },
                  { t: 172, s: [210, 210, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [236, 236], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.47843137254901963, 0.5215686274509804, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, -3], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 300,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Shape Layer 3',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.99], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 70,
                    s: [100],
                  },
                  {
                    i: { x: [0.626], y: [0.613] },
                    o: { x: [0.912], y: [0.104] },
                    t: 96,
                    s: [70],
                  },
                  { t: 139, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [255, 257, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0.667], y: [0.999, 0.999, 1] },
                    o: { x: [0.477, 0.477, 0.333], y: [0.587, 0.587, 0] },
                    t: 30,
                    s: [0, 0, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    t: 70,
                    s: [150, 150, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    t: 96,
                    s: [150, 150, 100],
                  },
                  { t: 143, s: [210, 210, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [236, 236], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [0.4, 0.4470588235294118, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, -3], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 300,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Shape Layer 1',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.99], y: [1] },
                    o: { x: [0.167], y: [0] },
                    t: 50,
                    s: [100],
                  },
                  {
                    i: { x: [0.626], y: [0.613] },
                    o: { x: [0.912], y: [0.104] },
                    t: 76,
                    s: [70],
                  },
                  { t: 119, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [255, 257, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0.667], y: [0.999, 0.999, 1] },
                    o: { x: [0.477, 0.477, 0.333], y: [0.587, 0.587, 0] },
                    t: 10,
                    s: [0, 0, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    t: 50,
                    s: [150, 150, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    t: 76,
                    s: [150, 150, 100],
                  },
                  { t: 123, s: [210, 210, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [236, 236], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.611764705882353, 0.6470588235294118, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, -3], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 3,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 300,
            st: 0,
            bm: 0,
          },
        ],
      },
      {
        id: 'comp_1',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 0,
            nm: 'brillante',
            refId: 'comp_2',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 180, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [256, 256, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            w: 512,
            h: 512,
            ip: 22,
            op: 202,
            st: 22,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 0,
            nm: 'claro',
            refId: 'comp_3',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [256, 256, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            w: 512,
            h: 512,
            ip: 0,
            op: 180,
            st: 0,
            bm: 0,
          },
        ],
      },
      {
        id: 'comp_2',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Shape Layer 75',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 28,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 38,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 82,
                    s: [100],
                  },
                  { t: 114, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 682, ix: 10 },
              p: { a: 0, k: [136, 154, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [41, 41, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-19.362, 34.498],
                          [7.899, -25.433],
                          [37.607, 53.867],
                          [11.905, 108.592],
                        ],
                        o: [
                          [0, 0],
                          [42.457, -75.648],
                          [-9.025, 29.061],
                          [-47.221, -67.637],
                          [-3.807, -34.72],
                        ],
                        v: [
                          [1, 2],
                          [-48.536, -23.607],
                          [-76.476, -100.021],
                          [27.098, -130.112],
                          [-120.809, -222.715],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 23,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 34,
                      s: [0],
                    },
                    { t: 108, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 28,
                      s: [0],
                    },
                    { t: 98, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 28,
            op: 222,
            st: 28,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Shape Layer 73',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 18,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 28,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 72,
                    s: [100],
                  },
                  { t: 104, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 487, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [41, 41, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-19.362, 34.498],
                          [7.899, -25.433],
                          [37.607, 53.867],
                          [11.905, 108.592],
                        ],
                        o: [
                          [0, 0],
                          [42.457, -75.648],
                          [-9.025, 29.061],
                          [-47.221, -67.637],
                          [-3.807, -34.72],
                        ],
                        v: [
                          [1, 2],
                          [-48.536, -23.607],
                          [-76.476, -100.021],
                          [27.098, -130.112],
                          [-120.809, -222.715],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 28,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 24,
                      s: [0],
                    },
                    { t: 98, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 18,
                      s: [0],
                    },
                    { t: 88, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 18,
            op: 222,
            st: 18,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Shape Layer 72',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 39,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 49,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 93,
                    s: [100],
                  },
                  { t: 125, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 353, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-14, 37],
                          [8.506, -58.529],
                          [28.328, 67.272],
                          [11.905, 108.592],
                        ],
                        o: [
                          [0, 0],
                          [9.966, -26.338],
                          [-12.365, 85.083],
                          [-32.013, -76.024],
                          [-3.807, -34.72],
                        ],
                        v: [
                          [1, 2],
                          [-48.536, -23.607],
                          [-76.476, -100.021],
                          [27.098, -130.112],
                          [-120.809, -222.715],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [1, 0.8352941176470589, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 26,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 45,
                      s: [0],
                    },
                    { t: 119, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 39,
                      s: [0],
                    },
                    { t: 109, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 39,
            op: 245,
            st: 39,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Shape Layer 71',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 68,
                    s: [100],
                  },
                  { t: 100, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 187, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-14, 37],
                          [8.254, -31.239],
                          [20.685, 70.001],
                          [17.795, 30.055],
                        ],
                        o: [
                          [0, 0],
                          [9.966, -26.338],
                          [-7.439, 28.154],
                          [-10.769, -36.442],
                          [-17.795, -30.055],
                        ],
                        v: [
                          [1, 2],
                          [2.692, -79.782],
                          [-49.416, -90.653],
                          [-14.589, -135.23],
                          [-105.31, -168.421],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 30,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 20,
                      s: [0],
                    },
                    { t: 94, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 14,
                      s: [0],
                    },
                    { t: 84, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 14,
            op: 222,
            st: 14,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Shape Layer 70',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 29,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 39,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 83,
                    s: [100],
                  },
                  { t: 115, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-14, 37],
                          [-1, 38],
                          [35.009, -27.828],
                          [-11, 31],
                        ],
                        o: [
                          [0, 0],
                          [9.966, -26.338],
                          [1, -38],
                          [-39, 31],
                          [11, -31],
                        ],
                        v: [
                          [1, 2],
                          [48, -39],
                          [29, -112],
                          [136, -107],
                          [103, -176],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: { a: 0, k: [1, 0.7294117647058823, 0, 1], ix: 3 },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 12,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 35,
                      s: [0],
                    },
                    { t: 109, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 29,
                      s: [0],
                    },
                    { t: 99, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 29,
            op: 241,
            st: 29,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Shape Layer 69',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 12,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 22,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 66,
                    s: [100],
                  },
                  { t: 98, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 12,
                    s: [269.95, 307.113, 0],
                    to: [-0.323, -0.36, 0],
                    ti: [1.346, 1.541, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [268.011, 304.951, 0],
                    to: [-1.346, -1.541, 0],
                    ti: [2.46, 3.542, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [261.875, 297.864, 0],
                    to: [-2.46, -3.542, 0],
                    ti: [2.232, 5.312, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [253.252, 283.701, 0],
                    to: [-2.232, -5.312, 0],
                    ti: [0.637, 5.627, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 20,
                    s: [248.481, 265.992, 0],
                    to: [-0.637, -5.627, 0],
                    ti: [-0.921, 4.788, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.982 },
                    o: { x: 0.167, y: 0.021 },
                    t: 22,
                    s: [249.431, 249.939, 0],
                    to: [0.921, -4.788, 0],
                    ti: [-1.796, 3.705, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.954 },
                    o: { x: 0.167, y: 0.054 },
                    t: 24,
                    s: [254.007, 237.262, 0],
                    to: [1.796, -3.705, 0],
                    ti: [-2.147, 2.784, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.928 },
                    o: { x: 0.167, y: 0.084 },
                    t: 26,
                    s: [260.206, 227.707, 0],
                    to: [2.147, -2.784, 0],
                    ti: [-2.218, 2.088, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.905 },
                    o: { x: 0.167, y: 0.108 },
                    t: 28,
                    s: [266.89, 220.559, 0],
                    to: [2.218, -2.088, 0],
                    ti: [-2.161, 1.578, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.888 },
                    o: { x: 0.167, y: 0.126 },
                    t: 30,
                    s: [273.513, 215.179, 0],
                    to: [2.161, -1.578, 0],
                    ti: [-2.053, 1.203, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.875 },
                    o: { x: 0.167, y: 0.139 },
                    t: 32,
                    s: [279.855, 211.089, 0],
                    to: [2.053, -1.203, 0],
                    ti: [-1.93, 0.918, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.866 },
                    o: { x: 0.167, y: 0.149 },
                    t: 34,
                    s: [285.832, 207.964, 0],
                    to: [1.93, -0.918, 0],
                    ti: [-1.801, 0.7, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.858 },
                    o: { x: 0.167, y: 0.156 },
                    t: 36,
                    s: [291.433, 205.582, 0],
                    to: [1.801, -0.7, 0],
                    ti: [-1.666, 0.538, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.161 },
                    t: 38,
                    s: [296.641, 203.764, 0],
                    to: [1.666, -0.538, 0],
                    ti: [-1.53, 0.417, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.851 },
                    o: { x: 0.167, y: 0.165 },
                    t: 40,
                    s: [301.428, 202.354, 0],
                    to: [1.53, -0.417, 0],
                    ti: [-1.402, 0.32, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.167 },
                    t: 42,
                    s: [305.818, 201.265, 0],
                    to: [1.402, -0.32, 0],
                    ti: [-1.283, 0.243, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.171 },
                    t: 44,
                    s: [309.838, 200.433, 0],
                    to: [1.283, -0.243, 0],
                    ti: [-1.172, 0.18, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.171 },
                    t: 46,
                    s: [313.514, 199.809, 0],
                    to: [1.172, -0.18, 0],
                    ti: [-1.068, 0.128, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.173 },
                    t: 48,
                    s: [316.868, 199.355, 0],
                    to: [1.068, -0.128, 0],
                    ti: [-0.97, 0.084, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 50,
                    s: [319.92, 199.043, 0],
                    to: [0.97, -0.084, 0],
                    ti: [-0.879, 0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.176 },
                    t: 52,
                    s: [322.69, 198.85, 0],
                    to: [0.879, -0.048, 0],
                    ti: [-0.793, 0.017, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.176 },
                    t: 54,
                    s: [325.194, 198.756, 0],
                    to: [0.793, -0.017, 0],
                    ti: [-0.712, -0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.176 },
                    t: 56,
                    s: [327.449, 198.747, 0],
                    to: [0.712, 0.009, 0],
                    ti: [-0.636, -0.03, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.177 },
                    t: 58,
                    s: [329.469, 198.808, 0],
                    to: [0.636, 0.03, 0],
                    ti: [-0.564, -0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.175 },
                    t: 60,
                    s: [331.266, 198.928, 0],
                    to: [0.564, 0.048, 0],
                    ti: [-0.497, -0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.174 },
                    t: 62,
                    s: [332.854, 199.098, 0],
                    to: [0.497, 0.063, 0],
                    ti: [-0.434, -0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.171 },
                    t: 64,
                    s: [334.247, 199.309, 0],
                    to: [0.434, 0.076, 0],
                    ti: [-0.375, -0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.168 },
                    t: 66,
                    s: [335.457, 199.552, 0],
                    to: [0.375, 0.085, 0],
                    ti: [-0.32, -0.092, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.861 },
                    o: { x: 0.167, y: 0.163 },
                    t: 68,
                    s: [336.496, 199.819, 0],
                    to: [0.32, 0.092, 0],
                    ti: [-0.269, -0.097, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.867 },
                    o: { x: 0.167, y: 0.156 },
                    t: 70,
                    s: [337.376, 200.105, 0],
                    to: [0.269, 0.097, 0],
                    ti: [-0.221, -0.099, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.878 },
                    o: { x: 0.167, y: 0.145 },
                    t: 72,
                    s: [338.108, 200.401, 0],
                    to: [0.221, 0.099, 0],
                    ti: [-0.178, -0.1, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.131 },
                    t: 74,
                    s: [338.704, 200.702, 0],
                    to: [0.178, 0.1, 0],
                    ti: [-0.138, -0.098, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.909 },
                    o: { x: 0.167, y: 0.111 },
                    t: 76,
                    s: [339.176, 200.999, 0],
                    to: [0.138, 0.098, 0],
                    ti: [-0.103, -0.094, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.93 },
                    o: { x: 0.167, y: 0.087 },
                    t: 78,
                    s: [339.534, 201.288, 0],
                    to: [0.103, 0.094, 0],
                    ti: [-0.071, -0.087, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.954 },
                    o: { x: 0.167, y: 0.057 },
                    t: 80,
                    s: [339.791, 201.562, 0],
                    to: [0.071, 0.087, 0],
                    ti: [-0.043, -0.079, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.027 },
                    t: 82,
                    s: [339.958, 201.813, 0],
                    to: [0.043, 0.079, 0],
                    ti: [-0.02, -0.068, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.004 },
                    t: 84,
                    s: [340.049, 202.035, 0],
                    to: [0.02, 0.068, 0],
                    ti: [0, -0.055, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [340.075, 202.222, 0],
                    to: [0, 0.055, 0],
                    ti: [0.014, -0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [340.051, 202.365, 0],
                    to: [-0.014, 0.04, 0],
                    ti: [0.025, -0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [339.989, 202.459, 0],
                    to: [-0.025, 0.022, 0],
                    ti: [0.024, -0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [339.903, 202.496, 0],
                    to: [-0.024, 0.002, 0],
                    ti: [0.008, 0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.995 },
                    o: { x: 0.167, y: 0.003 },
                    t: 94,
                    s: [339.844, 202.469, 0],
                    to: [-0.008, -0.02, 0],
                    ti: [-0.011, 0.042, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.983 },
                    o: { x: 0.167, y: 0.013 },
                    t: 96,
                    s: [339.858, 202.373, 0],
                    to: [0.011, -0.042, 0],
                    ti: [-0.012, 0.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 98,
                    s: [339.908, 202.219, 0],
                    to: [0.012, -0.057, 0],
                    ti: [-0.001, 0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [339.927, 202.033, 0],
                    to: [0.001, -0.066, 0],
                    ti: [0.008, 0.072, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [339.916, 201.823, 0],
                    to: [-0.008, -0.072, 0],
                    ti: [0.017, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [339.877, 201.599, 0],
                    to: [-0.017, -0.076, 0],
                    ti: [0.026, 0.078, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [339.812, 201.365, 0],
                    to: [-0.026, -0.078, 0],
                    ti: [0.033, 0.078, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [339.723, 201.128, 0],
                    to: [-0.033, -0.078, 0],
                    ti: [0.04, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [339.613, 200.895, 0],
                    to: [-0.04, -0.076, 0],
                    ti: [0.046, 0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [339.484, 200.67, 0],
                    to: [-0.046, -0.073, 0],
                    ti: [0.051, 0.068, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [339.339, 200.457, 0],
                    to: [-0.051, -0.068, 0],
                    ti: [0.055, 0.062, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [339.179, 200.262, 0],
                    to: [-0.055, -0.062, 0],
                    ti: [0.029, 0.029, 0],
                  },
                  { t: 118, s: [339.007, 200.087, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.27, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 12,
                    s: [140.028, 87.797, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.945, 4.379, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.064, 0.567, 0] },
                    t: 14,
                    s: [149.724, 81.426, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.242, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.158, 0.081, 0] },
                    t: 16,
                    s: [108.654, 80.328, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 1.096, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, -0.235, 0] },
                    t: 18,
                    s: [122.85, 125.952, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.849, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.361, 0.045, 0] },
                    t: 20,
                    s: [67.435, 113.999, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.46, 0.786, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.186, -0.016, 0] },
                    t: 22,
                    s: [77.828, 139.764, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.921, 0.918, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.099, 0.136, 0] },
                    t: 24,
                    s: [86.275, 118.232, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.039, -1.479, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.604, -4.077, 0] },
                    t: 26,
                    s: [132.517, 84.447, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.895, 1.074, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.091, 0.086, 0] },
                    t: 28,
                    s: [130.233, 85.124, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.337, 1.045, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.399, 0.039, 0] },
                    t: 30,
                    s: [104.061, 104.576, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 0.992, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, 0.029, 0] },
                    t: 32,
                    s: [97.155, 67.886, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.652, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.607, -0.009, 0] },
                    t: 34,
                    s: [131.988, 124.186, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.86, 0.858, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, -0.045, 0] },
                    t: 36,
                    s: [137.528, 73.357, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.036, 1.045, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.207, 0.201, 0] },
                    t: 38,
                    s: [88.621, 106.456, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 0.915, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, 0.029, 0] },
                    t: 40,
                    s: [55.617, 129.849, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.262, 4.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.634, 4.996, 0] },
                    t: 42,
                    s: [102.914, 93.756, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.946, 0.946, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.082, 0] },
                    t: 44,
                    s: [97.417, 93.144, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.703, 0.499, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.154, -0.157, 0] },
                    t: 46,
                    s: [54.264, 122.898, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.998, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.116, 0.1, 0] },
                    t: 48,
                    s: [69.385, 112.598, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.949, 1.263, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.002, 0.458, 0] },
                    t: 50,
                    s: [108.191, 60.937, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 0.891, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.135, 0.063, 0] },
                    t: 52,
                    s: [70.4, 49.44, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.585, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.486, 0.354, 0] },
                    t: 54,
                    s: [84.854, 97.16, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.234, 0.72, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.104, -0.001, 0] },
                    t: 56,
                    s: [85.713, 111.857, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.975, 0.919, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, 0.119, 0] },
                    t: 58,
                    s: [89.136, 97.385, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.375, 0.152, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.036, -3.19, 0] },
                    t: 60,
                    s: [76.083, 63.267, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 0.8, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.068, 0.092, 0] },
                    t: 62,
                    s: [85.219, 64.136, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.877, 1.035, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, 0.143, 0] },
                    t: 64,
                    s: [34.985, 72.111, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.984, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.257, 0.025, 0] },
                    t: 66,
                    s: [97.846, 83.275, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.901, 2.92, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.02, 2.259, 0] },
                    t: 68,
                    s: [127.941, 67.428, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.553, 0.981, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.536, 0.08, 0] },
                    t: 70,
                    s: [103.695, 66.821, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.782, 0.701, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.102, -0.025, 0] },
                    t: 72,
                    s: [99.227, 81.408, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.859, 0.804, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.135, 0.116, 0] },
                    t: 74,
                    s: [79.727, 70.137, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.953, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.203, 0.145, 0] },
                    t: 76,
                    s: [48.153, 40.939, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.831, 0.706, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.106, -0.113, 0] },
                    t: 78,
                    s: [26.225, 1.63, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.052, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.164, 0.116, 0] },
                    t: 80,
                    s: [35.878, 18.283, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.639, 0.832, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.032, -0.069, 0] },
                    t: 82,
                    s: [45.795, 60.473, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.99, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.108, 0.165, 0] },
                    t: 84,
                    s: [29.734, 37.417, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.965, -0.589, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.011, -0.907, 0] },
                    t: 86,
                    s: [-23.828, 13.866, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.893, 0.871, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.06, 0.088, 0] },
                    t: 88,
                    s: [23.282, 15.847, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.57, 1.036, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.379, 0.235, 0] },
                    t: 90,
                    s: [-4.081, 51.66, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.965, 0.796, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.025, 0] },
                    t: 92,
                    s: [-11.795, 71.385, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 0.979, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.061, 0.141, 0] },
                    t: 94,
                    s: [48.641, 43.137, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.129, 0.825, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, -0.028, 0] },
                    t: 96,
                    s: [13.642, 2.267, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.051, 0.159, 0] },
                    t: 98,
                    s: [-18.389, 32.951, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.864, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.028, 0.017, 0] },
                    t: 100,
                    s: [63.189, 66.722, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.844, 1.094, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.146, 0.216, 0] },
                    t: 102,
                    s: [2.261, 24.31, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.977, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.179, 0.044, 0] },
                    t: 104,
                    s: [24.372, -2.358, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.521, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.314, -0.032, 0] },
                    t: 106,
                    s: [43.66, 54.3, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 0.99, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.101, -0.001, 0] },
                    t: 108,
                    s: [39.61, 13.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.265, 0.893, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.359, -0.012, 0] },
                    t: 110,
                    s: [20.359, 53.589, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.823, 0.847, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.371, 0] },
                    t: 112,
                    s: [23.988, 18.274, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.068, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.157, 0.183, 0] },
                    t: 114,
                    s: [52.384, 8.05, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.034, 0] },
                    t: 116,
                    s: [84.408, -0.484, 100],
                  },
                  { t: 118, s: [26.091, 14.017, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 12,
            op: 222,
            st: 12,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Shape Layer 68',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 22,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 32,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 76,
                    s: [100],
                  },
                  { t: 108, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [266.709, 299.63, 0],
                    to: [-0.015, 0.376, 0],
                    ti: [0.204, -1.854, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [266.621, 301.887, 0],
                    to: [-0.204, 1.854, 0],
                    ti: [0.96, -4.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [265.488, 310.754, 0],
                    to: [-0.96, 4.091, 0],
                    ti: [2.202, -5.284, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [260.859, 326.432, 0],
                    to: [-2.202, 5.284, 0],
                    ti: [3.145, -4.688, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [252.276, 342.456, 0],
                    to: [-3.145, 4.688, 0],
                    ti: [3.441, -3.397, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [241.99, 354.561, 0],
                    to: [-3.441, 3.397, 0],
                    ti: [3.35, -2.293, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [231.629, 362.84, 0],
                    to: [-3.35, 2.293, 0],
                    ti: [3.107, -1.507, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [221.889, 368.32, 0],
                    to: [-3.107, 1.507, 0],
                    ti: [2.823, -0.973, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [212.988, 371.885, 0],
                    to: [-2.823, 0.973, 0],
                    ti: [2.545, -0.61, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [204.948, 374.155, 0],
                    to: [-2.545, 0.61, 0],
                    ti: [2.287, -0.361, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [197.719, 375.542, 0],
                    to: [-2.287, 0.361, 0],
                    ti: [2.057, -0.189, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [191.223, 376.321, 0],
                    to: [-2.057, 0.189, 0],
                    ti: [1.855, -0.069, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [185.374, 376.678, 0],
                    to: [-1.855, 0.069, 0],
                    ti: [1.678, 0.016, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [180.091, 376.736, 0],
                    to: [-1.678, -0.016, 0],
                    ti: [1.52, 0.078, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [175.308, 376.58, 0],
                    to: [-1.52, -0.078, 0],
                    ti: [1.379, 0.122, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [170.97, 376.27, 0],
                    to: [-1.379, -0.122, 0],
                    ti: [1.252, 0.155, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [167.033, 375.845, 0],
                    to: [-1.252, -0.155, 0],
                    ti: [1.137, 0.18, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [163.458, 375.337, 0],
                    to: [-1.137, -0.18, 0],
                    ti: [1.031, 0.198, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [160.214, 374.766, 0],
                    to: [-1.031, -0.198, 0],
                    ti: [0.933, 0.212, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [157.274, 374.147, 0],
                    to: [-0.933, -0.212, 0],
                    ti: [0.843, 0.223, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [154.615, 373.492, 0],
                    to: [-0.843, -0.223, 0],
                    ti: [0.758, 0.232, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [152.218, 372.807, 0],
                    to: [-0.758, -0.232, 0],
                    ti: [0.683, 0.239, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [150.067, 372.098, 0],
                    to: [-0.683, -0.239, 0],
                    ti: [0.619, 0.242, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [148.12, 371.372, 0],
                    to: [-0.619, -0.242, 0],
                    ti: [0.56, 0.239, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [146.355, 370.647, 0],
                    to: [-0.56, -0.239, 0],
                    ti: [0.506, 0.232, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [144.758, 369.937, 0],
                    to: [-0.506, -0.232, 0],
                    ti: [0.454, 0.219, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [143.32, 369.258, 0],
                    to: [-0.454, -0.219, 0],
                    ti: [0.406, 0.203, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [142.032, 368.621, 0],
                    to: [-0.406, -0.203, 0],
                    ti: [0.36, 0.184, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [140.885, 368.038, 0],
                    to: [-0.36, -0.184, 0],
                    ti: [0.317, 0.162, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [139.871, 367.517, 0],
                    to: [-0.317, -0.162, 0],
                    ti: [0.277, 0.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [138.982, 367.066, 0],
                    to: [-0.277, -0.138, 0],
                    ti: [0.238, 0.111, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [138.211, 366.692, 0],
                    to: [-0.238, -0.111, 0],
                    ti: [0.201, 0.083, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [137.554, 366.399, 0],
                    to: [-0.201, -0.083, 0],
                    ti: [0.166, 0.055, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [137.005, 366.191, 0],
                    to: [-0.166, -0.055, 0],
                    ti: [0.132, 0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [136.559, 366.071, 0],
                    to: [-0.132, -0.025, 0],
                    ti: [0.1, -0.005, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [136.211, 366.04, 0],
                    to: [-0.1, 0.005, 0],
                    ti: [0.069, -0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [135.959, 366.098, 0],
                    to: [-0.069, 0.034, 0],
                    ti: [0.039, -0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [135.797, 366.244, 0],
                    to: [-0.039, 0.063, 0],
                    ti: [0.011, -0.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [135.723, 366.476, 0],
                    to: [-0.011, 0.091, 0],
                    ti: [-0.016, -0.118, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.007 },
                    t: 100,
                    s: [135.732, 366.79, 0],
                    to: [0.016, 0.118, 0],
                    ti: [-0.036, -0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.012 },
                    t: 102,
                    s: [135.82, 367.181, 0],
                    to: [0.036, 0.141, 0],
                    ti: [-0.044, -0.159, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.011 },
                    t: 104,
                    s: [135.95, 367.634, 0],
                    to: [0.044, 0.159, 0],
                    ti: [-0.046, -0.173, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.01 },
                    t: 106,
                    s: [136.085, 368.134, 0],
                    to: [0.046, 0.173, 0],
                    ti: [-0.047, -0.184, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.009 },
                    t: 108,
                    s: [136.223, 368.671, 0],
                    to: [0.047, 0.184, 0],
                    ti: [-0.048, -0.193, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.009 },
                    t: 110,
                    s: [136.365, 369.239, 0],
                    to: [0.048, 0.193, 0],
                    ti: [-0.048, -0.197, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.009 },
                    t: 112,
                    s: [136.509, 369.827, 0],
                    to: [0.048, 0.197, 0],
                    ti: [-0.048, -0.198, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.009 },
                    t: 114,
                    s: [136.653, 370.423, 0],
                    to: [0.048, 0.198, 0],
                    ti: [-0.047, -0.195, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.009 },
                    t: 116,
                    s: [136.796, 371.017, 0],
                    to: [0.047, 0.195, 0],
                    ti: [-0.023, -0.096, 0],
                  },
                  { t: 118, s: [136.937, 371.594, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.877, 0.953, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 22,
                    s: [59.343, 96.124, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.969, 0.832, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.259, -0.107, 0] },
                    t: 24,
                    s: [89.744, 43.042, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.861, 1.087, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.05, 0.166, 0] },
                    t: 26,
                    s: [104.203, 66.307, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.424, 0.974, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.208, 0.043, 0] },
                    t: 28,
                    s: [95.197, 89.821, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.819, 0.904, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, -0.039, 0] },
                    t: 30,
                    s: [89.153, 41.816, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.993, 0.548, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.154, 0.617, 0] },
                    t: 32,
                    s: [125.946, 74.556, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.815, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.008, 0.102, 0] },
                    t: 34,
                    s: [169.121, 79.673, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.937, 1.513, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.152, -0.669, 0] },
                    t: 36,
                    s: [129.751, 102.297, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.747, 1.071, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.266, 0.072, 0] },
                    t: 38,
                    s: [81.726, 99.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.055, 0.899, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.124, 0.038, 0] },
                    t: 40,
                    s: [93.197, 117.733, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 1.324, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.033, 0.474, 0] },
                    t: 42,
                    s: [116.588, 84.536, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.473, 1.053, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.179, 0.066, 0] },
                    t: 44,
                    s: [77.774, 77.458, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.099, 0.033, 0] },
                    t: 46,
                    s: [80.336, 112.065, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.026, 1.108, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.364, 0.57, 0] },
                    t: 48,
                    s: [93.979, 55.33, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.832, 0.728, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.02, 0.047, 0] },
                    t: 50,
                    s: [91.437, 45.624, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.069, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.076, 0.12, 0] },
                    t: 52,
                    s: [94.777, 67.955, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, -0.116, 0] },
                    t: 54,
                    s: [58.109, 118.474, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 0.352, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.102, 0.493, 0] },
                    t: 56,
                    s: [125.074, 97.355, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.408, 0.998, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.104, 0.096, 0] },
                    t: 58,
                    s: [95.015, 93.056, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.982, 0.922, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, -0.002, 0] },
                    t: 60,
                    s: [108.364, 63.932, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.838, -0.683, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.022, -1.218, 0] },
                    t: 62,
                    s: [29.719, 92.263, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 0.975, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.172, 0.088, 0] },
                    t: 64,
                    s: [91.786, 90.449, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 0.834, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.177, -0.036, 0] },
                    t: 66,
                    s: [150.239, 55.635, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.842, 0.888, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, 0.167, 0] },
                    t: 68,
                    s: [131.528, 79.867, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 1.053, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, 0.326, 0] },
                    t: 70,
                    s: [114.424, 104, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.464, 0.726, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.141, 0.032, 0] },
                    t: 72,
                    s: [99.134, 112.3, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 0.762, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, 0.12, 0] },
                    t: 74,
                    s: [104.825, 98.77, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.398, 1.006, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.54, 0.128, 0] },
                    t: 76,
                    s: [67.48, 67.824, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, 0.877, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.006, 0] },
                    t: 78,
                    s: [65.344, 10.519, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-3.208, 1.093, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.911, 0.258, 0] },
                    t: 80,
                    s: [77.671, 71.977, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.93, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, 0.044, 0] },
                    t: 82,
                    s: [76.639, 101.368, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.96, 1.238, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.443, 0.68, 0] },
                    t: 84,
                    s: [25.532, 39.221, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.134, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.077, 0.062, 0] },
                    t: 86,
                    s: [33.627, 30.543, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.683, 0.714, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.051, -0.194, 0] },
                    t: 88,
                    s: [29.417, 64.046, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.008, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, 0.118, 0] },
                    t: 90,
                    s: [40.374, 53.993, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.787, 1.36, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.007, -0.137, 0] },
                    t: 92,
                    s: [71.134, 29.6, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.078, 1.002, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.137, 0.068, 0] },
                    t: 94,
                    s: [37.38, 38.828, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.04, 0.002, 0] },
                    t: 96,
                    s: [-15.322, -10.291, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 0.695, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.139, -0.254, 0] },
                    t: 98,
                    s: [86.713, 39.937, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.314, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.669, 0.115, 0] },
                    t: 100,
                    s: [48.493, 27.546, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.73, 0.854, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.012, 0] },
                    t: 102,
                    s: [46.484, -5.44, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.035, 0.884, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.12, 0.194, 0] },
                    t: 104,
                    s: [56.051, 33.097, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 1.27, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, 0.299, 0] },
                    t: 106,
                    s: [77.513, 62.169, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.851, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.324, 0.064, 0] },
                    t: 108,
                    s: [46.909, 73.396, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.037, 0.912, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.222, 0.188, 0] },
                    t: 110,
                    s: [53.173, 25.847, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.024, 1.423, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.091, 1.71, 0] },
                    t: 112,
                    s: [56.946, -11.873, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.891, 0.665, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.019, 0.07, 0] },
                    t: 114,
                    s: [96.787, -13.805, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.356, 0.111, 0] },
                    t: 116,
                    s: [45.548, -2.059, 100],
                  },
                  { t: 118, s: [29.917, 33.378, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.6666666666666666,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 22,
            op: 222,
            st: 22,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Shape Layer 67',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 4,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 58,
                    s: [100],
                  },
                  { t: 90, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.643 },
                    o: { x: 0.167, y: 0.146 },
                    t: 4,
                    s: [262.436, 299.331, 0],
                    to: [0.489, -0.187, 0],
                    ti: [-2.348, 1.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.806 },
                    o: { x: 0.167, y: 0.086 },
                    t: 6,
                    s: [265.373, 298.21, 0],
                    to: [2.348, -1.046, 0],
                    ti: [-5.031, 2.854, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.874 },
                    o: { x: 0.167, y: 0.092 },
                    t: 8,
                    s: [276.523, 293.055, 0],
                    to: [5.031, -2.854, 0],
                    ti: [-6.175, 4.882, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.919 },
                    o: { x: 0.167, y: 0.082 },
                    t: 10,
                    s: [295.56, 281.084, 0],
                    to: [6.175, -4.882, 0],
                    ti: [-4.919, 5.974, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.957 },
                    o: { x: 0.167, y: 0.049 },
                    t: 12,
                    s: [313.573, 263.763, 0],
                    to: [4.919, -5.974, 0],
                    ti: [-2.793, 5.995, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.015 },
                    t: 14,
                    s: [325.076, 245.24, 0],
                    to: [2.793, -5.995, 0],
                    ti: [-0.958, 5.467, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [330.333, 227.794, 0],
                    to: [0.958, -5.467, 0],
                    ti: [0.382, 4.708, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [330.823, 212.44, 0],
                    to: [-0.382, -4.708, 0],
                    ti: [1.261, 3.892, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [328.039, 199.545, 0],
                    to: [-1.261, -3.892, 0],
                    ti: [1.765, 3.129, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [323.257, 189.086, 0],
                    to: [-1.765, -3.129, 0],
                    ti: [2.001, 2.482, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [317.447, 180.769, 0],
                    to: [-2.001, -2.482, 0],
                    ti: [2.064, 1.961, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [311.25, 174.195, 0],
                    to: [-2.064, -1.961, 0],
                    ti: [2.024, 1.552, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [305.064, 169.001, 0],
                    to: [-2.024, -1.552, 0],
                    ti: [1.932, 1.236, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [299.106, 164.881, 0],
                    to: [-1.932, -1.236, 0],
                    ti: [1.816, 0.99, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [293.474, 161.588, 0],
                    to: [-1.816, -0.99, 0],
                    ti: [1.69, 0.797, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [288.212, 158.939, 0],
                    to: [-1.69, -0.797, 0],
                    ti: [1.563, 0.644, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [283.333, 156.803, 0],
                    to: [-1.563, -0.644, 0],
                    ti: [1.437, 0.519, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [278.836, 155.078, 0],
                    to: [-1.437, -0.519, 0],
                    ti: [1.316, 0.416, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [274.712, 153.691, 0],
                    to: [-1.316, -0.416, 0],
                    ti: [1.201, 0.33, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [270.942, 152.585, 0],
                    to: [-1.201, -0.33, 0],
                    ti: [1.094, 0.257, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [267.505, 151.714, 0],
                    to: [-1.094, -0.257, 0],
                    ti: [0.992, 0.194, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [264.381, 151.045, 0],
                    to: [-0.992, -0.194, 0],
                    ti: [0.897, 0.139, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [261.551, 150.551, 0],
                    to: [-0.897, -0.139, 0],
                    ti: [0.808, 0.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [258.997, 150.211, 0],
                    to: [-0.808, -0.091, 0],
                    ti: [0.724, 0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [256.702, 150.007, 0],
                    to: [-0.724, -0.048, 0],
                    ti: [0.646, 0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [254.651, 149.925, 0],
                    to: [-0.646, -0.009, 0],
                    ti: [0.572, -0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [252.828, 149.951, 0],
                    to: [-0.572, 0.025, 0],
                    ti: [0.502, -0.055, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [251.221, 150.073, 0],
                    to: [-0.502, 0.055, 0],
                    ti: [0.437, -0.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [249.815, 150.281, 0],
                    to: [-0.437, 0.082, 0],
                    ti: [0.377, -0.105, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [248.597, 150.563, 0],
                    to: [-0.377, 0.105, 0],
                    ti: [0.321, -0.125, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [247.552, 150.91, 0],
                    to: [-0.321, 0.125, 0],
                    ti: [0.269, -0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [246.671, 151.311, 0],
                    to: [-0.269, 0.141, 0],
                    ti: [0.22, -0.154, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [245.941, 151.756, 0],
                    to: [-0.22, 0.154, 0],
                    ti: [0.174, -0.163, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [245.353, 152.233, 0],
                    to: [-0.174, 0.163, 0],
                    ti: [0.132, -0.168, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [244.897, 152.732, 0],
                    to: [-0.132, 0.168, 0],
                    ti: [0.093, -0.169, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [244.562, 153.241, 0],
                    to: [-0.093, 0.169, 0],
                    ti: [0.057, -0.166, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [244.34, 153.746, 0],
                    to: [-0.057, 0.166, 0],
                    ti: [0.024, -0.158, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [244.221, 154.236, 0],
                    to: [-0.024, 0.158, 0],
                    ti: [-0.006, -0.145, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.004 },
                    t: 80,
                    s: [244.197, 154.694, 0],
                    to: [0.006, 0.145, 0],
                    ti: [-0.033, -0.128, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.023 },
                    t: 82,
                    s: [244.257, 155.108, 0],
                    to: [0.033, 0.128, 0],
                    ti: [-0.049, -0.109, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.963 },
                    o: { x: 0.167, y: 0.037 },
                    t: 84,
                    s: [244.394, 155.461, 0],
                    to: [0.049, 0.109, 0],
                    ti: [-0.051, -0.108, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.973 },
                    o: { x: 0.167, y: 0.025 },
                    t: 86,
                    s: [244.552, 155.765, 0],
                    to: [0.051, 0.108, 0],
                    ti: [-0.047, -0.12, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.98 },
                    o: { x: 0.167, y: 0.018 },
                    t: 88,
                    s: [244.699, 156.107, 0],
                    to: [0.047, 0.12, 0],
                    ti: [-0.044, -0.131, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.985 },
                    o: { x: 0.167, y: 0.015 },
                    t: 90,
                    s: [244.836, 156.487, 0],
                    to: [0.044, 0.131, 0],
                    ti: [-0.041, -0.137, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.013 },
                    t: 92,
                    s: [244.964, 156.891, 0],
                    to: [0.041, 0.137, 0],
                    ti: [-0.039, -0.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.011 },
                    t: 94,
                    s: [245.085, 157.309, 0],
                    to: [0.039, 0.14, 0],
                    ti: [-0.037, -0.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.01 },
                    t: 96,
                    s: [245.198, 157.733, 0],
                    to: [0.037, 0.14, 0],
                    ti: [-0.035, -0.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.01 },
                    t: 98,
                    s: [245.305, 158.152, 0],
                    to: [0.035, 0.138, 0],
                    ti: [-0.033, -0.133, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.01 },
                    t: 100,
                    s: [245.406, 158.559, 0],
                    to: [0.033, 0.133, 0],
                    ti: [-0.031, -0.125, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.01 },
                    t: 102,
                    s: [245.502, 158.947, 0],
                    to: [0.031, 0.125, 0],
                    ti: [-0.03, -0.116, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.012 },
                    t: 104,
                    s: [245.594, 159.31, 0],
                    to: [0.03, 0.116, 0],
                    ti: [-0.029, -0.104, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.014 },
                    t: 106,
                    s: [245.683, 159.641, 0],
                    to: [0.029, 0.104, 0],
                    ti: [-0.014, -0.049, 0],
                  },
                  { t: 108, s: [245.769, 159.936, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.684, 1.023, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 4,
                    s: [100.63, 69.657, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.351, -1.07, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, 0.018, 0] },
                    t: 6,
                    s: [104.514, 69.052, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.905, 1.153, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, 0.087, 0] },
                    t: 8,
                    s: [115.346, 69.821, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.398, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.695, 0.054, 0] },
                    t: 10,
                    s: [58.871, 88.161, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.965, 0.738, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, -0.137, 0] },
                    t: 12,
                    s: [51.174, 36.165, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.972, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.06, 0.122, 0] },
                    t: 14,
                    s: [95.636, 55.822, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.882, 0.78, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.042, -0.141, 0] },
                    t: 16,
                    s: [69.725, 97.853, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.246, 1.072, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.285, 0.134, 0] },
                    t: 18,
                    s: [86.959, 82.233, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.96, 0.883, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.039, 0] },
                    t: 20,
                    s: [94.096, 56.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 1.134, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.077, 0.29, 0] },
                    t: 22,
                    s: [151.503, 104.404, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.845, 0.96, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.166, 0.051, 0] },
                    t: 24,
                    s: [121.653, 123.646, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.987, 1.023, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.181, -0.079, 0] },
                    t: 26,
                    s: [91.668, 73.435, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.872, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.015, 0.018, 0] },
                    t: 28,
                    s: [66.045, 99.285, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 1.2, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.223, 0.238, 0] },
                    t: 30,
                    s: [87.746, 66.291, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.943, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.113, 0.059, 0] },
                    t: 32,
                    s: [100.66, 48.526, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.102, 0.778, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.477, -0.182, 0] },
                    t: 34,
                    s: [95.172, 108.893, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.575, 0.936, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.046, 0.133, 0] },
                    t: 36,
                    s: [95.987, 89.94, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.108, 0.256, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.079, -0.27, 0] },
                    t: 38,
                    s: [94.176, 58.401, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.975, 0.976, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, 0.094, 0] },
                    t: 40,
                    s: [130.21, 65.835, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 0.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.036, -0.034, 0] },
                    t: 42,
                    s: [47.499, 124.757, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.822, 1.024, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.43, -0.057, 0] },
                    t: 44,
                    s: [105.068, 83.037, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.17, 0.979, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.157, 0.019, 0] },
                    t: 46,
                    s: [118.917, 107.79, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.86, 0.93, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.056, -0.028, 0] },
                    t: 48,
                    s: [134.616, 75.912, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.094, 0.206, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.205, -0.452, 0] },
                    t: 50,
                    s: [86.877, 99.716, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 0.852, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, 0.093, 0] },
                    t: 52,
                    s: [54.265, 96.009, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.9, 1.036, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.139, 0.19, 0] },
                    t: 54,
                    s: [123.839, 64.381, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.39, 0.839, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.496, 0.025, 0] },
                    t: 56,
                    s: [97.726, 39.711, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.984, 0.871, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.097, 0.173, 0] },
                    t: 58,
                    s: [92.458, 75.119, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.931, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.019, 0.235, 0] },
                    t: 60,
                    s: [59.151, 108.162, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.407, 0.742, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.405, 0.034, 0] },
                    t: 62,
                    s: [86.256, 126.307, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.957, 0.931, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.097, 0.123, 0] },
                    t: 64,
                    s: [81.627, 95.551, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.83, 0.652, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.089, -0.391, 0] },
                    t: 66,
                    s: [53.31, 31.043, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.098, 1.066, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.164, 0.11, 0] },
                    t: 68,
                    s: [67.002, 42.37, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.972, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.045, 0.037, 0] },
                    t: 70,
                    s: [81.207, 78.404, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.908, 0.922, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.224, -0.041, 0] },
                    t: 72,
                    s: [50.269, 13.881, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.43, 0.675, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.933, -1.114, 0] },
                    t: 74,
                    s: [31.981, 57.039, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.97, 0.375, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, 0.112, 0] },
                    t: 76,
                    s: [30.188, 54.035, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.919, 0.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.046, 0.096, 0] },
                    t: 78,
                    s: [41.246, 45.317, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [17.435, 1.051, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-2.661, -0.148, 0] },
                    t: 80,
                    s: [34.148, -11.396, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.997, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.083, 0.032, 0] },
                    t: 82,
                    s: [34.363, 9.033, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.893, 0.829, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.004, -0.247, 0] },
                    t: 84,
                    s: [-8.365, -24.009, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.892, 0.989, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.375, 0.163, 0] },
                    t: 86,
                    s: [32.621, -15.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.847, 1.17, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.364, -0.012, 0] },
                    t: 88,
                    s: [44.332, -6.94, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.476, 0.712, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.183, 0.056, 0] },
                    t: 90,
                    s: [47.804, -14.568, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.098, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.099, 0.117, 0] },
                    t: 92,
                    s: [50.704, 8.589, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 1.348, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.045, 0.46, 0] },
                    t: 94,
                    s: [66.039, 65.528, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.638, 0.848, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.665, 0.067, 0] },
                    t: 96,
                    s: [32.735, 78.112, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.718, 0.935, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.185, 0] },
                    t: 98,
                    s: [30.98, 12.995, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.692, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, -0.305, 0] },
                    t: 100,
                    s: [46.164, -40.385, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.25, 0.862, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.313, 0.114, 0] },
                    t: 102,
                    s: [82.432, -28.944, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 0.97, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.211, 0] },
                    t: 104,
                    s: [74.813, 1.96, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.112, -0.047, 0] },
                    t: 106,
                    s: [13.817, 22.06, 100],
                  },
                  { t: 108, s: [39.779, 9.173, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 4,
            op: 212,
            st: 4,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Shape Layer 66',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 18,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 28,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 72,
                    s: [100],
                  },
                  { t: 104, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [264.075, 300.144, 0],
                    to: [-0.069, 0.468, 0],
                    ti: [0.355, -2.342, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [263.663, 302.955, 0],
                    to: [-0.355, 2.342, 0],
                    ti: [0.559, -5.353, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [261.945, 314.199, 0],
                    to: [-0.559, 5.353, 0],
                    ti: [0.004, -7.357, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 24,
                    s: [260.312, 335.072, 0],
                    to: [-0.004, 7.357, 0],
                    ti: [-1.215, -7.142, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.014 },
                    t: 26,
                    s: [261.922, 358.34, 0],
                    to: [1.215, 7.142, 0],
                    ti: [-2.396, -5.718, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.96 },
                    o: { x: 0.167, y: 0.047 },
                    t: 28,
                    s: [267.601, 377.926, 0],
                    to: [2.396, 5.718, 0],
                    ti: [-3.152, -4.167, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.923 },
                    o: { x: 0.167, y: 0.09 },
                    t: 30,
                    s: [276.299, 392.647, 0],
                    to: [3.152, 4.167, 0],
                    ti: [-3.444, -2.85, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.125 },
                    t: 32,
                    s: [286.512, 402.925, 0],
                    to: [3.444, 2.85, 0],
                    ti: [-3.405, -1.874, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.869 },
                    o: { x: 0.167, y: 0.15 },
                    t: 34,
                    s: [296.962, 409.748, 0],
                    to: [3.405, 1.874, 0],
                    ti: [-3.2, -1.208, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.162 },
                    t: 36,
                    s: [306.94, 414.169, 0],
                    to: [3.2, 1.208, 0],
                    ti: [-2.937, -0.769, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.169 },
                    t: 38,
                    s: [316.159, 416.998, 0],
                    to: [2.937, 0.769, 0],
                    ti: [-2.672, -0.481, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.172 },
                    t: 40,
                    s: [324.559, 418.783, 0],
                    to: [2.672, 0.481, 0],
                    ti: [-2.428, -0.291, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.174 },
                    t: 42,
                    s: [332.191, 419.883, 0],
                    to: [2.428, 0.291, 0],
                    ti: [-2.206, -0.163, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 44,
                    s: [339.125, 420.527, 0],
                    to: [2.206, 0.163, 0],
                    ti: [-2.007, -0.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 46,
                    s: [345.429, 420.863, 0],
                    to: [2.007, 0.077, 0],
                    ti: [-1.827, -0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 48,
                    s: [351.165, 420.991, 0],
                    to: [1.827, 0.019, 0],
                    ti: [-1.665, 0.021, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 50,
                    s: [356.391, 420.977, 0],
                    to: [1.665, -0.021, 0],
                    ti: [-1.519, 0.049, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 52,
                    s: [361.156, 420.865, 0],
                    to: [1.519, -0.049, 0],
                    ti: [-1.386, 0.069, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 54,
                    s: [365.504, 420.683, 0],
                    to: [1.386, -0.069, 0],
                    ti: [-1.264, 0.084, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 56,
                    s: [369.472, 420.452, 0],
                    to: [1.264, -0.084, 0],
                    ti: [-1.151, 0.096, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.173 },
                    t: 58,
                    s: [373.088, 420.182, 0],
                    to: [1.151, -0.096, 0],
                    ti: [-1.046, 0.107, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.173 },
                    t: 60,
                    s: [376.379, 419.878, 0],
                    to: [1.046, -0.107, 0],
                    ti: [-0.947, 0.118, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.171 },
                    t: 62,
                    s: [379.365, 419.542, 0],
                    to: [0.947, -0.118, 0],
                    ti: [-0.856, 0.124, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.171 },
                    t: 64,
                    s: [382.063, 419.172, 0],
                    to: [0.856, -0.124, 0],
                    ti: [-0.773, 0.118, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.172 },
                    t: 66,
                    s: [384.499, 418.801, 0],
                    to: [0.773, -0.118, 0],
                    ti: [-0.697, 0.107, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.172 },
                    t: 68,
                    s: [386.7, 418.464, 0],
                    to: [0.697, -0.107, 0],
                    ti: [-0.626, 0.097, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.172 },
                    t: 70,
                    s: [388.681, 418.159, 0],
                    to: [0.626, -0.097, 0],
                    ti: [-0.561, 0.088, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.173 },
                    t: 72,
                    s: [390.459, 417.883, 0],
                    to: [0.561, -0.088, 0],
                    ti: [-0.5, 0.079, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.173 },
                    t: 74,
                    s: [392.047, 417.633, 0],
                    to: [0.5, -0.079, 0],
                    ti: [-0.443, 0.072, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.174 },
                    t: 76,
                    s: [393.458, 417.406, 0],
                    to: [0.443, -0.072, 0],
                    ti: [-0.39, 0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.172 },
                    t: 78,
                    s: [394.704, 417.199, 0],
                    to: [0.39, -0.066, 0],
                    ti: [-0.34, 0.061, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.851 },
                    o: { x: 0.167, y: 0.173 },
                    t: 80,
                    s: [395.795, 417.009, 0],
                    to: [0.34, -0.061, 0],
                    ti: [-0.293, 0.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.852 },
                    o: { x: 0.167, y: 0.172 },
                    t: 82,
                    s: [396.741, 416.833, 0],
                    to: [0.293, -0.057, 0],
                    ti: [-0.249, 0.053, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.173 },
                    t: 84,
                    s: [397.551, 416.669, 0],
                    to: [0.249, -0.053, 0],
                    ti: [-0.207, 0.05, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.859 },
                    o: { x: 0.167, y: 0.172 },
                    t: 86,
                    s: [398.233, 416.514, 0],
                    to: [0.207, -0.05, 0],
                    ti: [-0.168, 0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.865 },
                    o: { x: 0.167, y: 0.169 },
                    t: 88,
                    s: [398.794, 416.367, 0],
                    to: [0.168, -0.048, 0],
                    ti: [-0.132, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.875 },
                    o: { x: 0.167, y: 0.163 },
                    t: 90,
                    s: [399.242, 416.225, 0],
                    to: [0.132, -0.046, 0],
                    ti: [-0.097, 0.045, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.148 },
                    t: 92,
                    s: [399.584, 416.088, 0],
                    to: [0.097, -0.045, 0],
                    ti: [-0.064, 0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.921 },
                    o: { x: 0.167, y: 0.111 },
                    t: 94,
                    s: [399.824, 415.954, 0],
                    to: [0.064, -0.044, 0],
                    ti: [-0.034, 0.043, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.974 },
                    o: { x: 0.167, y: 0.032 },
                    t: 96,
                    s: [399.97, 415.824, 0],
                    to: [0.034, -0.043, 0],
                    ti: [-0.012, 0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 98,
                    s: [400.026, 415.696, 0],
                    to: [0.012, -0.044, 0],
                    ti: [-0.006, 0.049, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 100,
                    s: [400.041, 415.559, 0],
                    to: [0.006, -0.049, 0],
                    ti: [-0.008, 0.056, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.004 },
                    t: 102,
                    s: [400.063, 415.401, 0],
                    to: [0.008, -0.056, 0],
                    ti: [-0.011, 0.062, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.006 },
                    t: 104,
                    s: [400.091, 415.224, 0],
                    to: [0.011, -0.062, 0],
                    ti: [-0.014, 0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.992 },
                    o: { x: 0.167, y: 0.008 },
                    t: 106,
                    s: [400.129, 415.031, 0],
                    to: [0.014, -0.066, 0],
                    ti: [-0.017, 0.07, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.011 },
                    t: 108,
                    s: [400.175, 414.825, 0],
                    to: [0.017, -0.07, 0],
                    ti: [-0.021, 0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.985 },
                    o: { x: 0.167, y: 0.015 },
                    t: 110,
                    s: [400.232, 414.61, 0],
                    to: [0.021, -0.073, 0],
                    ti: [-0.025, 0.074, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.98 },
                    o: { x: 0.167, y: 0.02 },
                    t: 112,
                    s: [400.301, 414.389, 0],
                    to: [0.025, -0.074, 0],
                    ti: [-0.029, 0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.974 },
                    o: { x: 0.167, y: 0.026 },
                    t: 114,
                    s: [400.382, 414.168, 0],
                    to: [0.029, -0.073, 0],
                    ti: [-0.034, 0.071, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.964 },
                    o: { x: 0.167, y: 0.036 },
                    t: 116,
                    s: [400.476, 413.95, 0],
                    to: [0.034, -0.071, 0],
                    ti: [-0.018, 0.035, 0],
                  },
                  { t: 118, s: [400.585, 413.741, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.237, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 18,
                    s: [60.433, 69.807, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 0.354, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.078, 0.012, 0] },
                    t: 20,
                    s: [57.035, 63.909, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.869, 0.914, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.136, 0.096, 0] },
                    t: 22,
                    s: [110.865, 70.792, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.993, 2.539, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.23, 2.807, 0] },
                    t: 24,
                    s: [90.384, 117.236, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.615, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.008, 0.079, 0] },
                    t: 26,
                    s: [78.755, 118.657, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.957, 0.878, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.106, 0.017, 0] },
                    t: 28,
                    s: [89.349, 90.995, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.759, 1.314, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.09, 0.263, 0] },
                    t: 30,
                    s: [127.76, 125.63, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.85, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.128, 0.066, 0] },
                    t: 32,
                    s: [109.298, 141.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.93, 1.01, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.187, -0.134, 0] },
                    t: 34,
                    s: [74.466, 65.123, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.468, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.45, 0.009, 0] },
                    t: 36,
                    s: [46.407, 94.474, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.94, 2.673, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.099, 2.042, 0] },
                    t: 38,
                    s: [50.79, 61.594, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.179, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.209, 0.079, 0] },
                    t: 40,
                    s: [74.379, 60.195, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 1.053, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.057, 0.166, 0] },
                    t: 42,
                    s: [67.656, 89.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.318, 0.963, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.577, 0.032, 0] },
                    t: 44,
                    s: [88.817, 119.34, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.032, 0.863, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.089, -0.068, 0] },
                    t: 46,
                    s: [92.391, 70.889, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 1.002, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.023, 0.212, 0] },
                    t: 48,
                    s: [145.365, 97.56, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.702, 0.895, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.509, 0.002, 0] },
                    t: 50,
                    s: [71.948, 114.853, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.886, 1.201, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.407, 0] },
                    t: 52,
                    s: [67.657, 97.077, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.837, 0.928, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.312, 0.059, 0] },
                    t: 54,
                    s: [108.077, 92.496, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.302, 1.255, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.171, -0.517, 0] },
                    t: 56,
                    s: [122.812, 108.142, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.944, 1.236, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.065, 0.063, 0] },
                    t: 58,
                    s: [136.892, 105.97, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.917, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.168, 0.062, 0] },
                    t: 60,
                    s: [71.752, 114.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [4.698, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-10.728, 0.167, 0] },
                    t: 62,
                    s: [93.325, 80.942, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.281, 2.111, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, 0.997, 0] },
                    t: 64,
                    s: [93.159, 47.153, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, 0.796, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.064, 0.078, 0] },
                    t: 66,
                    s: [100.704, 44.07, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-6.847, 0.936, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-7.898, 0.141, 0] },
                    t: 68,
                    s: [67.68, 88.243, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.94, 0.681, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.084, -0.275, 0] },
                    t: 70,
                    s: [68.025, 152.101, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.562, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.209, 0.113, 0] },
                    t: 72,
                    s: [100.152, 137.254, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.103, 0.504, 0] },
                    t: 74,
                    s: [91.001, 95.342, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, -2.094, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, 0.554, 0] },
                    t: 76,
                    s: [52.026, 87.036, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-2.601, 0.998, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-3.839, 0.086, 0] },
                    t: 78,
                    s: [90.362, 85.565, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 0.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, -0.003, 0] },
                    t: 80,
                    s: [89.547, 32.423, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.446, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.669, 1.085, 0] },
                    t: 82,
                    s: [55.168, 83.983, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, 0.034, 0] },
                    t: 84,
                    s: [58.978, 88.274, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.109, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.325, 0.091, 0] },
                    t: 86,
                    s: [34.79, 80.994, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 1.213, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, 1.844, 0] },
                    t: 88,
                    s: [39.732, 2.714, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [4.228, 1.121, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.292, 0.06, 0] },
                    t: 90,
                    s: [28.352, -0.992, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.984, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, 0.049, 0] },
                    t: 92,
                    s: [29.041, 12.182, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.923, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.02, -0.04, 0] },
                    t: 94,
                    s: [1.635, -20.19, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.207, 3.708, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.991, -1.388, 0] },
                    t: 96,
                    s: [23.803, 1.627, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.933, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.078, 0.081, 0] },
                    t: 98,
                    s: [22.083, 0.392, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.444, 0.353, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.338, 0.943, 0] },
                    t: 100,
                    s: [48.704, 41.779, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.843, 1.011, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, 0.096, 0] },
                    t: 102,
                    s: [43.437, 45.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.042, 0.741, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.178, 0.01, 0] },
                    t: 104,
                    s: [13.575, 72.931, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.846, 0.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.028, 0.123, 0] },
                    t: 106,
                    s: [-12.851, 42.13, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.907, 1.471, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.181, 1.058, 0] },
                    t: 108,
                    s: [26.868, -22.827, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.014, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.832, 0.071, 0] },
                    t: 110,
                    s: [60.696, -28.382, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.956, 6.012, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.077, 11.039, 0] },
                    t: 112,
                    s: [64.461, 8.569, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.945, 1.005, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.093, 0.082, 0] },
                    t: 114,
                    s: [14.897, 8.85, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.162, 0.005, 0] },
                    t: 116,
                    s: [38.326, -8.337, 100],
                  },
                  { t: 118, s: [30.376, 9.931, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.6901960784313725,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 18,
            op: 222,
            st: 18,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Shape Layer 65',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 39,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 49,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 93,
                    s: [100],
                  },
                  { t: 125, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.748 },
                    o: { x: 0.167, y: 0.106 },
                    t: 39,
                    s: [268.501, 303.894, 0],
                    to: [0.373, -0.267, 0],
                    ti: [-1.332, 1.601, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.933 },
                    o: { x: 0.167, y: 0.032 },
                    t: 41,
                    s: [270.738, 302.29, 0],
                    to: [1.332, -1.601, 0],
                    ti: [1.952, 1.768, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 43,
                    s: [276.491, 294.286, 0],
                    to: [-1.952, -1.768, 0],
                    ti: [6.31, 0.166, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 45,
                    s: [259.026, 291.685, 0],
                    to: [-6.31, -0.166, 0],
                    ti: [6.364, -0.502, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 47,
                    s: [238.628, 293.288, 0],
                    to: [-6.364, 0.502, 0],
                    ti: [5.435, -0.368, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 49,
                    s: [220.844, 294.694, 0],
                    to: [-5.435, 0.368, 0],
                    ti: [4.549, -0.177, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 51,
                    s: [206.017, 295.497, 0],
                    to: [-4.549, 0.177, 0],
                    ti: [3.853, -0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 53,
                    s: [193.549, 295.758, 0],
                    to: [-3.853, 0.014, 0],
                    ti: [3.314, 0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 55,
                    s: [182.897, 295.583, 0],
                    to: [-3.314, -0.117, 0],
                    ti: [2.886, 0.223, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 57,
                    s: [173.668, 295.056, 0],
                    to: [-2.886, -0.223, 0],
                    ti: [2.537, 0.31, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 59,
                    s: [165.584, 294.243, 0],
                    to: [-2.537, -0.31, 0],
                    ti: [2.246, 0.383, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 61,
                    s: [158.446, 293.194, 0],
                    to: [-2.246, -0.383, 0],
                    ti: [2.001, 0.441, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 63,
                    s: [152.105, 291.947, 0],
                    to: [-2.001, -0.441, 0],
                    ti: [1.789, 0.485, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 65,
                    s: [146.441, 290.551, 0],
                    to: [-1.789, -0.485, 0],
                    ti: [1.601, 0.519, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 67,
                    s: [141.373, 289.038, 0],
                    to: [-1.601, -0.519, 0],
                    ti: [1.435, 0.544, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 69,
                    s: [136.833, 287.437, 0],
                    to: [-1.435, -0.544, 0],
                    ti: [1.285, 0.562, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 71,
                    s: [132.765, 285.771, 0],
                    to: [-1.285, -0.562, 0],
                    ti: [1.15, 0.573, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 73,
                    s: [129.121, 284.063, 0],
                    to: [-1.15, -0.573, 0],
                    ti: [1.027, 0.578, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 75,
                    s: [125.863, 282.331, 0],
                    to: [-1.027, -0.578, 0],
                    ti: [0.915, 0.576, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 77,
                    s: [122.957, 280.597, 0],
                    to: [-0.915, -0.576, 0],
                    ti: [0.814, 0.569, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 79,
                    s: [120.371, 278.877, 0],
                    to: [-0.814, -0.569, 0],
                    ti: [0.721, 0.558, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 81,
                    s: [118.075, 277.184, 0],
                    to: [-0.721, -0.558, 0],
                    ti: [0.637, 0.542, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 83,
                    s: [116.044, 275.532, 0],
                    to: [-0.637, -0.542, 0],
                    ti: [0.56, 0.523, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 85,
                    s: [114.254, 273.931, 0],
                    to: [-0.56, -0.523, 0],
                    ti: [0.49, 0.502, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 87,
                    s: [112.684, 272.392, 0],
                    to: [-0.49, -0.502, 0],
                    ti: [0.427, 0.478, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 89,
                    s: [111.312, 270.921, 0],
                    to: [-0.427, -0.478, 0],
                    ti: [0.369, 0.453, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 91,
                    s: [110.122, 269.523, 0],
                    to: [-0.369, -0.453, 0],
                    ti: [0.317, 0.426, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 93,
                    s: [109.095, 268.204, 0],
                    to: [-0.317, -0.426, 0],
                    ti: [0.27, 0.399, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 95,
                    s: [108.219, 266.966, 0],
                    to: [-0.27, -0.399, 0],
                    ti: [0.226, 0.37, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 97,
                    s: [107.478, 265.812, 0],
                    to: [-0.226, -0.37, 0],
                    ti: [0.186, 0.342, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 99,
                    s: [106.862, 264.743, 0],
                    to: [-0.186, -0.342, 0],
                    ti: [0.15, 0.313, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 101,
                    s: [106.36, 263.762, 0],
                    to: [-0.15, -0.313, 0],
                    ti: [0.117, 0.283, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 103,
                    s: [105.962, 262.868, 0],
                    to: [-0.117, -0.283, 0],
                    ti: [0.086, 0.254, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 105,
                    s: [105.66, 262.062, 0],
                    to: [-0.086, -0.254, 0],
                    ti: [0.058, 0.224, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 107,
                    s: [105.445, 261.346, 0],
                    to: [-0.058, -0.224, 0],
                    ti: [0.032, 0.194, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 109,
                    s: [105.312, 260.718, 0],
                    to: [-0.032, -0.194, 0],
                    ti: [0.008, 0.164, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 111,
                    s: [105.253, 260.18, 0],
                    to: [-0.008, -0.164, 0],
                    ti: [-0.014, 0.135, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.007 },
                    t: 113,
                    s: [105.263, 259.732, 0],
                    to: [0.014, -0.135, 0],
                    ti: [-0.034, 0.104, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.97 },
                    o: { x: 0.167, y: 0.035 },
                    t: 115,
                    s: [105.336, 259.373, 0],
                    to: [0.034, -0.104, 0],
                    ti: [-0.052, 0.074, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.917 },
                    o: { x: 0.167, y: 0.092 },
                    t: 117,
                    s: [105.467, 259.105, 0],
                    to: [0.052, -0.074, 0],
                    ti: [-0.065, 0.05, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.879 },
                    o: { x: 0.167, y: 0.129 },
                    t: 119,
                    s: [105.65, 258.927, 0],
                    to: [0.065, -0.05, 0],
                    ti: [-0.069, 0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.876 },
                    o: { x: 0.167, y: 0.142 },
                    t: 121,
                    s: [105.86, 258.807, 0],
                    to: [0.069, -0.036, 0],
                    ti: [-0.062, 0.029, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.883 },
                    o: { x: 0.167, y: 0.151 },
                    t: 123,
                    s: [106.065, 258.712, 0],
                    to: [0.062, -0.029, 0],
                    ti: [-0.047, 0.024, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.896 },
                    o: { x: 0.167, y: 0.15 },
                    t: 125,
                    s: [106.232, 258.632, 0],
                    to: [0.047, -0.024, 0],
                    ti: [-0.031, 0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.92 },
                    o: { x: 0.167, y: 0.138 },
                    t: 127,
                    s: [106.349, 258.566, 0],
                    to: [0.031, -0.02, 0],
                    ti: [-0.015, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.961 },
                    o: { x: 0.167, y: 0.065 },
                    t: 129,
                    s: [106.418, 258.515, 0],
                    to: [0.015, -0.014, 0],
                    ti: [0.001, 0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 131,
                    s: [106.44, 258.479, 0],
                    to: [-0.001, -0.009, 0],
                    ti: [0.016, 0.003, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 133,
                    s: [106.414, 258.46, 0],
                    to: [-0.016, -0.003, 0],
                    ti: [0.031, -0.003, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 135,
                    s: [106.343, 258.459, 0],
                    to: [-0.031, 0.003, 0],
                    ti: [0.045, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 137,
                    s: [106.228, 258.475, 0],
                    to: [-0.045, 0.008, 0],
                    ti: [0.059, -0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 139,
                    s: [106.07, 258.51, 0],
                    to: [-0.059, 0.014, 0],
                    ti: [0.072, -0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 141,
                    s: [105.873, 258.562, 0],
                    to: [-0.072, 0.02, 0],
                    ti: [0.084, -0.026, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 143,
                    s: [105.639, 258.632, 0],
                    to: [-0.084, 0.026, 0],
                    ti: [0.095, -0.032, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 145,
                    s: [105.369, 258.719, 0],
                    to: [-0.095, 0.032, 0],
                    ti: [0.05, -0.017, 0],
                  },
                  { t: 147, s: [105.069, 258.823, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, -0.931, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 39,
                    s: [111.822, 109.374, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.087, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.414, 0.087, 0] },
                    t: 41,
                    s: [100.055, 106.422, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.812, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, -0.097, 0] },
                    t: 43,
                    s: [97.093, 40.951, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.773, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.117, 0.15, 0] },
                    t: 45,
                    s: [67.613, 71.247, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.893, 1.157, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.132, -0.129, 0] },
                    t: 47,
                    s: [79.872, 109.348, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.215, 0.987, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.381, 0.054, 0] },
                    t: 49,
                    s: [101.054, 94.428, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.06, -0.015, 0] },
                    t: 51,
                    s: [106.979, 137.485, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.974, 0.793, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.477, -0.164, 0] },
                    t: 53,
                    s: [85.798, 101.174, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.674, 1.013, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.039, 0.139, 0] },
                    t: 55,
                    s: [88.947, 113.411, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, 0.677, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.011, 0] },
                    t: 57,
                    s: [86.793, 131.596, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.583, 0.977, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.914, 0.112, 0] },
                    t: 59,
                    s: [106.377, 110.603, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.868, 0.899, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.088, -0.033, 0] },
                    t: 61,
                    s: [104.74, 50.192, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.873, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.225, 0.471, 0] },
                    t: 63,
                    s: [75.287, 93.627, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.914, 0.963, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.244, 0.557, 0] },
                    t: 65,
                    s: [57.971, 102.957, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [6.07, 1.272, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [2.53, -0.068, 0] },
                    t: 67,
                    s: [48.983, 104.599, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.801, 1.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.082, 0.064, 0] },
                    t: 69,
                    s: [48.677, 103.694, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.786, 1.017, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.143, 0.076, 0] },
                    t: 71,
                    s: [67.607, 107.546, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.136, 0.014, 0] },
                    t: 73,
                    s: [93.956, 61.633, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.898, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, -0.051, 0] },
                    t: 75,
                    s: [135.372, 117.12, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.936, 1.36, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.448, -0.923, 0] },
                    t: 77,
                    s: [94.654, 82.761, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.146, 1.103, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.276, 0.068, 0] },
                    t: 79,
                    s: [85.351, 85.606, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.778, 0.911, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, 0.046, 0] },
                    t: 81,
                    s: [87.506, 70.455, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 0.043, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.134, 1.425, 0] },
                    t: 83,
                    s: [107.432, 104.297, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.792, 1.155, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, 0.091, 0] },
                    t: 85,
                    s: [140.473, 106.399, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.967, 0.932, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.139, 0.054, 0] },
                    t: 87,
                    s: [118.147, 128.441, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.974, 1.121, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.054, -0.359, 0] },
                    t: 89,
                    s: [84.776, 65.43, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.673, 0.886, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.038, 0.049, 0] },
                    t: 91,
                    s: [105.029, 77.29, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.925, 1.408, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.112, 0.311, 0] },
                    t: 93,
                    s: [91.068, 48.266, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.647, 0.929, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.747, 0.069, 0] },
                    t: 95,
                    s: [50.228, 37.635, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.827, 0.939, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.109, -0.474, 0] },
                    t: 97,
                    s: [54.329, 100.336, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.244, 1.036, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.161, -0.223, 0] },
                    t: 99,
                    s: [67.613, 90.964, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 0.595, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, 0.025, 0] },
                    t: 101,
                    s: [81.902, 93.51, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.785, 0.829, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.101, 0.105, 0] },
                    t: 103,
                    s: [25.699, 89.858, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.976, 0.897, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.136, 0.163, 0] },
                    t: 105,
                    s: [51.156, 75.74, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.939, 1.149, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.034, 0.43, 0] },
                    t: 107,
                    s: [91.35, 60.958, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.534, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.231, 0.053, 0] },
                    t: 109,
                    s: [62.734, 57.409, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.526, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, -0.014, 0] },
                    t: 111,
                    s: [70.32, 67.319, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, 0.923, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.24, 0.101, 0] },
                    t: 113,
                    s: [14.17, 58.838, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.32, 0.689, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.419, -0.944, 0] },
                    t: 115,
                    s: [28.636, 19.113, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.869, 1.114, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.114, 0] },
                    t: 117,
                    s: [32.23, 22.336, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.092, 1.006, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.228, 0.048, 0] },
                    t: 119,
                    s: [14.851, 31.15, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.899, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, 0.005, 0] },
                    t: 121,
                    s: [4.812, 10.283, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.075, 1.195, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.472, 0.001, 0] },
                    t: 123,
                    s: [25.925, 32.587, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.799, 0.984, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.04, 0.058, 0] },
                    t: 125,
                    s: [30.453, 9.9, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.842, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.142, -0.02, 0] },
                    t: 127,
                    s: [21.826, 85.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.674, 0.929, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, -0.039, 0] },
                    t: 129,
                    s: [9.608, 24.619, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.022, 0.324, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.112, -0.462, 0] },
                    t: 131,
                    s: [-1.289, 66.096, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.018, 0.095, 0] },
                    t: 133,
                    s: [-33.054, 59.758, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 1.101, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.173, 1.876, 0] },
                    t: 135,
                    s: [7.239, 14.644, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.273, 1.364, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.683, 0.046, 0] },
                    t: 137,
                    s: [44.549, 12.547, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.913, 0.998, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.068, 0] },
                    t: 139,
                    s: [40.492, 17.184, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.637, 1.065, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.953, -0.003, 0] },
                    t: 141,
                    s: [9.161, -7.703, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.935, 0.991, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.079, 0.037, 0] },
                    t: 143,
                    s: [7.764, 16.458, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.3, -0.01, 0] },
                    t: 145,
                    s: [36.594, -26.534, 100],
                  },
                  { t: 147, s: [30.332, 11.798, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.7843137254901961,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 39,
            op: 251,
            st: 39,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Shape Layer 64',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 2,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 12,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 56,
                    s: [100],
                  },
                  { t: 88, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.796 },
                    o: { x: 0.167, y: 0.094 },
                    t: 2,
                    s: [270.415, 303.961, 0],
                    to: [0.303, -0.268, 0],
                    ti: [-1.389, 1.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.872 },
                    o: { x: 0.167, y: 0.068 },
                    t: 4,
                    s: [272.233, 302.354, 0],
                    to: [1.389, -1.063, 0],
                    ti: [-2.915, 0.504, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.826 },
                    o: { x: 0.167, y: 0.125 },
                    t: 6,
                    s: [278.751, 297.585, 0],
                    to: [2.915, -0.504, 0],
                    ti: [-1.552, -2.903, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 8,
                    s: [289.724, 299.332, 0],
                    to: [1.552, 2.903, 0],
                    ti: [0.807, -4.867, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 10,
                    s: [288.065, 315.004, 0],
                    to: [-0.807, 4.867, 0],
                    ti: [1.017, -4.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 12,
                    s: [284.884, 328.537, 0],
                    to: [-1.017, 4.14, 0],
                    ti: [0.905, -3.485, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [281.965, 339.842, 0],
                    to: [-0.905, 3.485, 0],
                    ti: [0.776, -2.987, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [279.455, 349.449, 0],
                    to: [-0.776, 2.987, 0],
                    ti: [0.667, -2.607, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [277.308, 357.764, 0],
                    to: [-0.667, 2.607, 0],
                    ti: [0.578, -2.312, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [275.455, 365.094, 0],
                    to: [-0.578, 2.312, 0],
                    ti: [0.505, -2.074, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [273.842, 371.638, 0],
                    to: [-0.505, 2.074, 0],
                    ti: [0.444, -1.876, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [272.427, 377.536, 0],
                    to: [-0.444, 1.876, 0],
                    ti: [0.393, -1.708, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [271.178, 382.891, 0],
                    to: [-0.393, 1.708, 0],
                    ti: [0.349, -1.562, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [270.071, 387.781, 0],
                    to: [-0.349, 1.562, 0],
                    ti: [0.31, -1.434, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [269.086, 392.264, 0],
                    to: [-0.31, 1.434, 0],
                    ti: [0.275, -1.32, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [268.211, 396.386, 0],
                    to: [-0.275, 1.32, 0],
                    ti: [0.243, -1.215, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [267.436, 400.181, 0],
                    to: [-0.243, 1.215, 0],
                    ti: [0.213, -1.118, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [266.752, 403.676, 0],
                    to: [-0.213, 1.118, 0],
                    ti: [0.185, -1.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [266.156, 406.891, 0],
                    to: [-0.185, 1.028, 0],
                    ti: [0.158, -0.941, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [265.642, 409.842, 0],
                    to: [-0.158, 0.941, 0],
                    ti: [0.131, -0.859, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [265.209, 412.54, 0],
                    to: [-0.131, 0.859, 0],
                    ti: [0.105, -0.779, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [264.855, 414.996, 0],
                    to: [-0.105, 0.779, 0],
                    ti: [0.078, -0.702, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [264.581, 417.216, 0],
                    to: [-0.078, 0.702, 0],
                    ti: [0.051, -0.625, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [264.386, 419.205, 0],
                    to: [-0.051, 0.625, 0],
                    ti: [0.024, -0.55, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [264.272, 420.968, 0],
                    to: [-0.024, 0.55, 0],
                    ti: [-0.004, -0.475, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [264.241, 422.506, 0],
                    to: [0.004, 0.475, 0],
                    ti: [-0.032, -0.401, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.003 },
                    t: 54,
                    s: [264.295, 423.82, 0],
                    to: [0.032, 0.401, 0],
                    ti: [-0.062, -0.326, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.012 },
                    t: 56,
                    s: [264.435, 424.912, 0],
                    to: [0.062, 0.326, 0],
                    ti: [-0.092, -0.251, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.969 },
                    o: { x: 0.167, y: 0.038 },
                    t: 58,
                    s: [264.666, 425.779, 0],
                    to: [0.092, 0.251, 0],
                    ti: [-0.123, -0.178, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.923 },
                    o: { x: 0.167, y: 0.088 },
                    t: 60,
                    s: [264.99, 426.419, 0],
                    to: [0.123, 0.178, 0],
                    ti: [-0.144, -0.125, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.115 },
                    t: 62,
                    s: [265.403, 426.848, 0],
                    to: [0.144, 0.125, 0],
                    ti: [-0.154, -0.094, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.871 },
                    o: { x: 0.167, y: 0.135 },
                    t: 64,
                    s: [265.856, 427.168, 0],
                    to: [0.154, 0.094, 0],
                    ti: [-0.158, -0.068, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.151 },
                    t: 66,
                    s: [266.328, 427.409, 0],
                    to: [0.158, 0.068, 0],
                    ti: [-0.158, -0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.163 },
                    t: 68,
                    s: [266.806, 427.579, 0],
                    to: [0.158, 0.046, 0],
                    ti: [-0.155, -0.026, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.169 },
                    t: 70,
                    s: [267.278, 427.686, 0],
                    to: [0.155, 0.026, 0],
                    ti: [-0.148, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.839 },
                    o: { x: 0.167, y: 0.172 },
                    t: 72,
                    s: [267.736, 427.736, 0],
                    to: [0.148, 0.008, 0],
                    ti: [-0.139, 0.007, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.171 },
                    t: 74,
                    s: [268.169, 427.736, 0],
                    to: [0.139, -0.007, 0],
                    ti: [-0.127, 0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.164 },
                    t: 76,
                    s: [268.571, 427.692, 0],
                    to: [0.127, -0.022, 0],
                    ti: [-0.114, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.862 },
                    o: { x: 0.167, y: 0.151 },
                    t: 78,
                    s: [268.934, 427.607, 0],
                    to: [0.114, -0.034, 0],
                    ti: [-0.098, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.883 },
                    o: { x: 0.167, y: 0.132 },
                    t: 80,
                    s: [269.252, 427.486, 0],
                    to: [0.098, -0.046, 0],
                    ti: [-0.081, 0.051, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.901 },
                    o: { x: 0.167, y: 0.12 },
                    t: 82,
                    s: [269.522, 427.333, 0],
                    to: [0.081, -0.051, 0],
                    ti: [-0.064, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.907 },
                    o: { x: 0.167, y: 0.126 },
                    t: 84,
                    s: [269.74, 427.18, 0],
                    to: [0.064, -0.046, 0],
                    ti: [-0.047, 0.035, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.918 },
                    o: { x: 0.167, y: 0.125 },
                    t: 86,
                    s: [269.907, 427.059, 0],
                    to: [0.047, -0.035, 0],
                    ti: [-0.029, 0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.941 },
                    o: { x: 0.167, y: 0.113 },
                    t: 88,
                    s: [270.02, 426.97, 0],
                    to: [0.029, -0.025, 0],
                    ti: [-0.011, 0.015, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.004 },
                    t: 90,
                    s: [270.079, 426.911, 0],
                    to: [0.011, -0.015, 0],
                    ti: [0.007, 0.006, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [270.083, 426.879, 0],
                    to: [-0.007, -0.006, 0],
                    ti: [0.025, -0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [270.035, 426.873, 0],
                    to: [-0.025, 0.002, 0],
                    ti: [0.041, -0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [269.935, 426.889, 0],
                    to: [-0.041, 0.009, 0],
                    ti: [0.056, -0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [269.788, 426.925, 0],
                    to: [-0.056, 0.014, 0],
                    ti: [0.07, -0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [269.597, 426.976, 0],
                    to: [-0.07, 0.019, 0],
                    ti: [0.082, -0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [269.367, 427.038, 0],
                    to: [-0.082, 0.022, 0],
                    ti: [0.092, -0.023, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [269.104, 427.106, 0],
                    to: [-0.092, 0.023, 0],
                    ti: [0.099, -0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [268.815, 427.175, 0],
                    to: [-0.099, 0.022, 0],
                    ti: [0.104, -0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [268.508, 427.24, 0],
                    to: [-0.104, 0.02, 0],
                    ti: [0.105, -0.015, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [268.192, 427.293, 0],
                    to: [-0.105, 0.015, 0],
                    ti: [0.103, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [267.876, 427.329, 0],
                    to: [-0.103, 0.008, 0],
                    ti: [0.098, 0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [267.571, 427.341, 0],
                    to: [-0.098, -0.002, 0],
                    ti: [0.088, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [267.289, 427.32, 0],
                    to: [-0.088, -0.014, 0],
                    ti: [0.041, 0.01, 0],
                  },
                  { t: 118, s: [267.042, 427.258, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.161, 0.992, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 2,
                    s: [88.706, 125.309, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.987, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, -0.009, 0] },
                    t: 4,
                    s: [101.05, 65.647, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.822, 0.862, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.015, -0.05, 0] },
                    t: 6,
                    s: [64.822, 119.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.03, 1.194, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.156, 0.21, 0] },
                    t: 8,
                    s: [95.37, 85.974, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.939, 0.943, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.022, 0.058, 0] },
                    t: 10,
                    s: [130.225, 63.782, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.793, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.229, -0.185, 0] },
                    t: 12,
                    s: [82.767, 137.754, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.05, 0.961, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.219, 0.139, 0] },
                    t: 14,
                    s: [95.438, 114.769, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.544, 0.867, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.031, -0.072, 0] },
                    t: 16,
                    s: [103.2, 80.591, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.032, 0.895, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.102, 0.224, 0] },
                    t: 18,
                    s: [90.818, 98.938, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.967, 0.336, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.023, 0.402, 0] },
                    t: 20,
                    s: [35.459, 109.792, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.842, 1.105, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.056, 0.095, 0] },
                    t: 22,
                    s: [111.828, 112.627, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.085, 0.874, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.176, 0.046, 0] },
                    t: 24,
                    s: [66.113, 132.396, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.042, 0.248, 0] },
                    t: 26,
                    s: [25.062, 87.733, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.884, 1.494, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.328, -0.831, 0] },
                    t: 28,
                    s: [107.997, 65.085, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.34, 1.282, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.298, 0.071, 0] },
                    t: 30,
                    s: [91.212, 67.15, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, 0.064, 0] },
                    t: 32,
                    s: [84.699, 52.844, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.04, 0.928, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-2.065, -0.147, 0] },
                    t: 34,
                    s: [117.811, 115.589, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.695, 1.984, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.027, -0.553, 0] },
                    t: 36,
                    s: [116.527, 92.87, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.902, 0.923, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.079, 0.077, 0] },
                    t: 38,
                    s: [118.433, 95.846, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.407, 0.784, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.556, -0.959, 0] },
                    t: 40,
                    s: [77.764, 57.746, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.863, 0.619, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.136, 0] },
                    t: 42,
                    s: [70.59, 60.792, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.073, 1.124, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.213, 0.107, 0] },
                    t: 44,
                    s: [112.827, 65.643, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.871, 1.062, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.039, 0.05, 0] },
                    t: 46,
                    s: [139.959, 82.972, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.858, 0.887, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.235, 0.036, 0] },
                    t: 48,
                    s: [89.044, 39.953, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.03, 1.025, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.202, 0.321, 0] },
                    t: 50,
                    s: [61.106, 115.024, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 0.861, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.022, 0.019, 0] },
                    t: 52,
                    s: [41.52, 141.346, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.086, 0.818, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.674, 0.209, 0] },
                    t: 54,
                    s: [68.262, 107.246, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 0.94, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.077, 0.154, 0] },
                    t: 56,
                    s: [65.317, 84.613, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.919, 1.127, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, -0.216, 0] },
                    t: 58,
                    s: [106.651, 57.805, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-3.288, 0.05, 0] },
                    t: 60,
                    s: [66.005, 65.265, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-1.723, 0.574, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.143, -0.241, 0] },
                    t: 62,
                    s: [67.009, 46.429, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 1.067, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.086, 0.104, 0] },
                    t: 64,
                    s: [66.639, 51.274, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.778, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, 0.037, 0] },
                    t: 66,
                    s: [54.923, 71.216, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.741, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.133, -1.444, 0] },
                    t: 68,
                    s: [65.427, 35.22, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.878, 1.091, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.123, 0] },
                    t: 70,
                    s: [82.893, 37.183, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.906, 0.828, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.264, 0.043, 0] },
                    t: 72,
                    s: [50.525, 41.333, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.063, 1.344, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.747, 0.162, 0] },
                    t: 74,
                    s: [35.563, 32.66, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.889, 1.002, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.077, 0.067, 0] },
                    t: 76,
                    s: [33.685, 23.422, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.055, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.331, 0.002, 0] },
                    t: 78,
                    s: [59.517, 70.825, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.967, 1.608, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.033, -0.9, 0] },
                    t: 80,
                    s: [68.226, 22.181, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.215, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.055, 0.073, 0] },
                    t: 82,
                    s: [53.746, 26.302, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.832, 0.514, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.06, -0.163, 0] },
                    t: 84,
                    s: [62.499, -7.882, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.925, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.166, 0.101, 0] },
                    t: 86,
                    s: [31.128, 3.701, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.261, 0.975, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.72, -0.052, 0] },
                    t: 88,
                    s: [-0.586, 59.702, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.131, 1.044, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.063, -0.035, 0] },
                    t: 90,
                    s: [2.703, 25.328, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.936, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.051, 0.029, 0] },
                    t: 92,
                    s: [-10.892, 49.534, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.851, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.242, -0.278, 0] },
                    t: 94,
                    s: [24.109, 12.637, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.541, 1.12, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.189, -0.068, 0] },
                    t: 96,
                    s: [15.151, 21.155, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 1.225, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, 0.049, 0] },
                    t: 98,
                    s: [8.06, 16.479, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.881, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, 0.061, 0] },
                    t: 100,
                    s: [61.168, 27.875, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.037, 0.929, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.278, -0.07, 0] },
                    t: 102,
                    s: [25.413, -14.304, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.927, 1.673, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.026, -0.462, 0] },
                    t: 104,
                    s: [10.106, 8.552, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.809, 0.934, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.597, 0.074, 0] },
                    t: 106,
                    s: [32.24, 5.058, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, 0.766, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.148, -0.321, 0] },
                    t: 108,
                    s: [29.528, 36.767, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.876, 1.171, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.422, 0.129, 0] },
                    t: 110,
                    s: [26.015, 30.226, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.716, 0.961, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.252, 0.056, 0] },
                    t: 112,
                    s: [25.151, 18.362, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.719, 0.812, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, -0.074, 0] },
                    t: 114,
                    s: [24.725, 54.585, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.075, 0.15, 0] },
                    t: 116,
                    s: [23.696, 35.402, 100],
                  },
                  { t: 118, s: [33.602, 11.392, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.7607843137254902,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 2,
            op: 222,
            st: 2,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 12,
            ty: 4,
            nm: 'Shape Layer 63',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 8,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 18,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 62,
                    s: [100],
                  },
                  { t: 94, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 8,
                    s: [267.904, 308.221, 0],
                    to: [-0.062, -0.384, 0],
                    ti: [-0.406, 2.013, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.982 },
                    o: { x: 0.167, y: 0.008 },
                    t: 10,
                    s: [267.529, 305.917, 0],
                    to: [0.406, -2.013, 0],
                    ti: [-2.229, 4.277, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.946 },
                    o: { x: 0.167, y: 0.039 },
                    t: 12,
                    s: [270.337, 296.143, 0],
                    to: [2.229, -4.277, 0],
                    ti: [-4.056, 5.365, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.935 },
                    o: { x: 0.167, y: 0.066 },
                    t: 14,
                    s: [280.902, 280.252, 0],
                    to: [4.056, -5.365, 0],
                    ti: [-4.394, 5.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.931 },
                    o: { x: 0.167, y: 0.081 },
                    t: 16,
                    s: [294.676, 263.955, 0],
                    to: [4.394, -5.019, 0],
                    ti: [-3.868, 4.214, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.929 },
                    o: { x: 0.167, y: 0.084 },
                    t: 18,
                    s: [307.264, 250.141, 0],
                    to: [3.868, -4.214, 0],
                    ti: [-3.257, 3.538, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.929 },
                    o: { x: 0.167, y: 0.083 },
                    t: 20,
                    s: [317.883, 238.67, 0],
                    to: [3.257, -3.538, 0],
                    ti: [-2.752, 3.047, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.931 },
                    o: { x: 0.167, y: 0.08 },
                    t: 22,
                    s: [326.809, 228.915, 0],
                    to: [2.752, -3.047, 0],
                    ti: [-2.349, 2.69, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.933 },
                    o: { x: 0.167, y: 0.075 },
                    t: 24,
                    s: [334.394, 220.39, 0],
                    to: [2.349, -2.69, 0],
                    ti: [-2.023, 2.427, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.937 },
                    o: { x: 0.167, y: 0.07 },
                    t: 26,
                    s: [340.902, 212.773, 0],
                    to: [2.023, -2.427, 0],
                    ti: [-1.753, 2.23, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.942 },
                    o: { x: 0.167, y: 0.064 },
                    t: 28,
                    s: [346.531, 205.826, 0],
                    to: [1.753, -2.23, 0],
                    ti: [-1.519, 2.072, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.947 },
                    o: { x: 0.167, y: 0.058 },
                    t: 30,
                    s: [351.417, 199.393, 0],
                    to: [1.519, -2.072, 0],
                    ti: [-1.306, 1.928, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.953 },
                    o: { x: 0.167, y: 0.052 },
                    t: 32,
                    s: [355.644, 193.397, 0],
                    to: [1.306, -1.928, 0],
                    ti: [-1.106, 1.797, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.96 },
                    o: { x: 0.167, y: 0.044 },
                    t: 34,
                    s: [359.255, 187.824, 0],
                    to: [1.106, -1.797, 0],
                    ti: [-0.913, 1.682, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.968 },
                    o: { x: 0.167, y: 0.035 },
                    t: 36,
                    s: [362.28, 182.616, 0],
                    to: [0.913, -1.682, 0],
                    ti: [-0.72, 1.579, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.977 },
                    o: { x: 0.167, y: 0.025 },
                    t: 38,
                    s: [364.731, 177.73, 0],
                    to: [0.72, -1.579, 0],
                    ti: [-0.524, 1.479, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.014 },
                    t: 40,
                    s: [366.601, 173.143, 0],
                    to: [0.524, -1.479, 0],
                    ti: [-0.321, 1.378, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.005 },
                    t: 42,
                    s: [367.872, 168.855, 0],
                    to: [0.321, -1.378, 0],
                    ti: [-0.115, 1.264, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [368.526, 164.876, 0],
                    to: [0.115, -1.264, 0],
                    ti: [0.083, 1.13, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [368.561, 161.269, 0],
                    to: [-0.083, -1.13, 0],
                    ti: [0.254, 0.978, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [368.028, 158.096, 0],
                    to: [-0.254, -0.978, 0],
                    ti: [0.382, 0.82, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [367.038, 155.4, 0],
                    to: [-0.382, -0.82, 0],
                    ti: [0.464, 0.67, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [365.735, 153.176, 0],
                    to: [-0.464, -0.67, 0],
                    ti: [0.506, 0.539, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [364.254, 151.378, 0],
                    to: [-0.506, -0.539, 0],
                    ti: [0.52, 0.43, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [362.7, 149.94, 0],
                    to: [-0.52, -0.43, 0],
                    ti: [0.517, 0.343, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [361.136, 148.795, 0],
                    to: [-0.517, -0.343, 0],
                    ti: [0.505, 0.273, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [359.597, 147.883, 0],
                    to: [-0.505, -0.273, 0],
                    ti: [0.487, 0.216, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [358.105, 147.16, 0],
                    to: [-0.487, -0.216, 0],
                    ti: [0.465, 0.169, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [356.676, 146.589, 0],
                    to: [-0.465, -0.169, 0],
                    ti: [0.441, 0.132, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [355.317, 146.143, 0],
                    to: [-0.441, -0.132, 0],
                    ti: [0.415, 0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [354.033, 145.799, 0],
                    to: [-0.415, -0.101, 0],
                    ti: [0.388, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [352.827, 145.538, 0],
                    to: [-0.388, -0.076, 0],
                    ti: [0.36, 0.056, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [351.704, 145.343, 0],
                    to: [-0.36, -0.056, 0],
                    ti: [0.33, 0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [350.668, 145.203, 0],
                    to: [-0.33, -0.04, 0],
                    ti: [0.298, 0.027, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [349.724, 145.104, 0],
                    to: [-0.298, -0.027, 0],
                    ti: [0.263, 0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [348.882, 145.038, 0],
                    to: [-0.263, -0.019, 0],
                    ti: [0.227, 0.013, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [348.144, 144.993, 0],
                    to: [-0.227, -0.013, 0],
                    ti: [0.189, 0.011, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [347.517, 144.959, 0],
                    to: [-0.189, -0.011, 0],
                    ti: [0.146, 0.011, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [347.011, 144.929, 0],
                    to: [-0.146, -0.011, 0],
                    ti: [0.1, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [346.639, 144.893, 0],
                    to: [-0.1, -0.014, 0],
                    ti: [0.056, 0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [346.413, 144.843, 0],
                    to: [-0.056, -0.022, 0],
                    ti: [0.03, 0.033, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [346.304, 144.761, 0],
                    to: [-0.03, -0.033, 0],
                    ti: [0.031, 0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [346.234, 144.648, 0],
                    to: [-0.031, -0.038, 0],
                    ti: [0.045, 0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [346.12, 144.534, 0],
                    to: [-0.045, -0.038, 0],
                    ti: [0.059, 0.037, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [345.962, 144.422, 0],
                    to: [-0.059, -0.037, 0],
                    ti: [0.072, 0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [345.764, 144.313, 0],
                    to: [-0.072, -0.036, 0],
                    ti: [0.083, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [345.53, 144.207, 0],
                    to: [-0.083, -0.034, 0],
                    ti: [0.093, 0.033, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [345.264, 144.107, 0],
                    to: [-0.093, -0.033, 0],
                    ti: [0.102, 0.031, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [344.97, 144.011, 0],
                    to: [-0.102, -0.031, 0],
                    ti: [0.11, 0.029, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [344.65, 143.922, 0],
                    to: [-0.11, -0.029, 0],
                    ti: [0.117, 0.026, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [344.308, 143.839, 0],
                    to: [-0.117, -0.026, 0],
                    ti: [0.123, 0.024, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [343.947, 143.764, 0],
                    to: [-0.123, -0.024, 0],
                    ti: [0.127, 0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [343.572, 143.695, 0],
                    to: [-0.127, -0.022, 0],
                    ti: [0.13, 0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [343.185, 143.634, 0],
                    to: [-0.13, -0.019, 0],
                    ti: [0.133, 0.017, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [342.79, 143.581, 0],
                    to: [-0.133, -0.017, 0],
                    ti: [0.067, 0.008, 0],
                  },
                  { t: 118, s: [342.389, 143.535, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.916, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 8,
                    s: [146.545, 56.981, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.984, 6.136, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [7.109, 6.416, 0] },
                    t: 10,
                    s: [102.654, 113.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.082, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.082, 0] },
                    t: 12,
                    s: [102.133, 114.538, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.769, 0.88, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, -0.024, 0] },
                    t: 14,
                    s: [115.051, 67.711, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.13, 0.272, 0] },
                    t: 16,
                    s: [89.461, 104.174, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.843, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.029, -0.825, 0] },
                    t: 18,
                    s: [44.049, 120.312, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.068, 5.668, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, -0.052, 0] },
                    t: 20,
                    s: [77.823, 118.832, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.009, 0.967, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.037, 0.082, 0] },
                    t: 22,
                    s: [107.747, 119.743, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.009, -0.054, 0] },
                    t: 24,
                    s: [53.479, 67.782, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-1.061, 0.515, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-2.064, -0.16, 0] },
                    t: 26,
                    s: [113.921, 99.393, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.981, 1.019, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.087, 0.101, 0] },
                    t: 28,
                    s: [111.576, 88.565, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.95, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.025, 0.015, 0] },
                    t: 30,
                    s: [55.899, 36.375, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.094, 1.148, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.128, 0.737, 0] },
                    t: 32,
                    s: [98.768, 100.4, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.97, 0.816, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, 0.053, 0] },
                    t: 34,
                    s: [81.873, 108.568, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.853, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.047, 0.153, 0] },
                    t: 36,
                    s: [117.879, 85.877, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.034, 0.862, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.192, -0.017, 0] },
                    t: 38,
                    s: [94.849, 58.578, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.781, 0.836, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.024, 0.211, 0] },
                    t: 40,
                    s: [77.175, 81.139, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.98, 1.271, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.135, 0.169, 0] },
                    t: 42,
                    s: [102.134, 95.906, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.835, 0.976, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.026, 0.064, 0] },
                    t: 44,
                    s: [142.654, 110.218, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.797, 0.841, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, -0.034, 0] },
                    t: 46,
                    s: [111.887, 49.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.009, 1.01, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.141, 0.175, 0] },
                    t: 48,
                    s: [81.638, 92.763, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.894, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.008, 0.009, 0] },
                    t: 50,
                    s: [38.028, 132.048, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.012, 0.82, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.143, 0.392, 0] },
                    t: 52,
                    s: [86.128, 88.194, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.088, 0.901, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.011, 0.155, 0] },
                    t: 54,
                    s: [68.431, 76.37, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.042, 1.263, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.043, 0.518, 0] },
                    t: 56,
                    s: [88.699, 62.632, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.935, 1.037, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.028, 0.063, 0] },
                    t: 58,
                    s: [46.98, 59.998, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.851, 1.103, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.298, 0.026, 0] },
                    t: 60,
                    s: [109.874, 70.958, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.881, 1.047, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.189, 0.046, 0] },
                    t: 62,
                    s: [96.114, 55.137, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.231, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.278, 0.03, 0] },
                    t: 64,
                    s: [85.214, 90.54, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.189, 0.931, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, -0.071, 0] },
                    t: 66,
                    s: [80.545, 35.132, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.94, 0.796, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, -0.412, 0] },
                    t: 68,
                    s: [98.178, 65.031, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.966, 0.606, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.209, 0.141, 0] },
                    t: 70,
                    s: [40.533, 60.003, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.059, 0.106, 0] },
                    t: 72,
                    s: [56.966, 52.732, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.747, 1.016, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.244, 0.016, 0] },
                    t: 74,
                    s: [47.335, 25.638, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.818, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.124, 0.014, 0] },
                    t: 76,
                    s: [49.783, 59.415, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.061, 0.813, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.154, 0.718, 0] },
                    t: 78,
                    s: [54.761, 18.968, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.894, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.035, 0.15, 0] },
                    t: 80,
                    s: [60.644, 13.657, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.945, 0.618, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.384, 0.034, 0] },
                    t: 82,
                    s: [50.471, 7.064, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.087, 0.107, 0] },
                    t: 84,
                    s: [47.647, 18.253, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.927, 0.88, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, -0.023, 0] },
                    t: 86,
                    s: [-15.426, 58.31, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.52, 0.892, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.604, 0.275, 0] },
                    t: 88,
                    s: [27.159, 27.049, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 0.901, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, 0.364, 0] },
                    t: 90,
                    s: [21.997, 13.425, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.588, 1.135, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.54, 0.54, 0] },
                    t: 92,
                    s: [59.347, 9.373, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.055, 1.741, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.104, 0.051, 0] },
                    t: 94,
                    s: [54.352, 8.634, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 0.829, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.033, 0.075, 0] },
                    t: 96,
                    s: [34.643, 10.569, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.881, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, 0.163, 0] },
                    t: 98,
                    s: [67.47, -8.566, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.842, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.276, -0.04, 0] },
                    t: 100,
                    s: [34.991, -28.664, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [3.321, 0.639, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.801, 0.176, 0] },
                    t: 102,
                    s: [20.908, -15.133, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.876, 0.894, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.108, 0] },
                    t: 104,
                    s: [21.531, -2.998, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.331, 0.787, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.256, 0.39, 0] },
                    t: 106,
                    s: [3.556, 37.39, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, 0.137, 0] },
                    t: 108,
                    s: [-5.112, 48.357, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.837, 0.83, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.482, -0.165, 0] },
                    t: 110,
                    s: [37.958, 65.386, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.163, 0.146, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.171, 0.163, 0] },
                    t: 112,
                    s: [40.525, 59.682, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.082, 0.927, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 0.092, 0] },
                    t: 114,
                    s: [42.972, 53.735, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, -0.57, 0] },
                    t: 116,
                    s: [65.106, -1.291, 100],
                  },
                  { t: 118, s: [21.097, 5.723, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 8,
            op: 222,
            st: 8,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: 'Shape Layer 62',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 34,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 78,
                    s: [100],
                  },
                  { t: 110, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [274.616, 306.243, 0],
                    to: [-0.115, -0.59, 0],
                    ti: [0.824, 2.77, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [273.925, 302.703, 0],
                    to: [-0.824, -2.77, 0],
                    ti: [2.181, 6.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [269.674, 289.624, 0],
                    to: [-2.181, -6.093, 0],
                    ti: [3.267, 8.196, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [260.838, 266.142, 0],
                    to: [-3.267, -8.196, 0],
                    ti: [3.485, 7.964, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [250.074, 240.449, 0],
                    to: [-3.485, -7.964, 0],
                    ti: [3.198, 6.707, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [239.929, 218.357, 0],
                    to: [-3.198, -6.707, 0],
                    ti: [2.853, 5.529, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [230.887, 200.209, 0],
                    to: [-2.853, -5.529, 0],
                    ti: [2.563, 4.612, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [222.813, 185.182, 0],
                    to: [-2.563, -4.612, 0],
                    ti: [2.332, 3.906, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [215.51, 172.535, 0],
                    to: [-2.332, -3.906, 0],
                    ti: [2.146, 3.349, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [208.822, 161.746, 0],
                    to: [-2.146, -3.349, 0],
                    ti: [1.992, 2.898, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [202.636, 152.442, 0],
                    to: [-1.992, -2.898, 0],
                    ti: [1.863, 2.525, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [196.868, 144.357, 0],
                    to: [-1.863, -2.525, 0],
                    ti: [1.751, 2.21, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [191.458, 137.291, 0],
                    to: [-1.751, -2.21, 0],
                    ti: [1.653, 1.941, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [186.36, 131.094, 0],
                    to: [-1.653, -1.941, 0],
                    ti: [1.563, 1.706, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [181.542, 125.648, 0],
                    to: [-1.563, -1.706, 0],
                    ti: [1.481, 1.501, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [176.98, 120.856, 0],
                    to: [-1.481, -1.501, 0],
                    ti: [1.403, 1.32, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [172.656, 116.64, 0],
                    to: [-1.403, -1.32, 0],
                    ti: [1.328, 1.158, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [168.561, 112.936, 0],
                    to: [-1.328, -1.158, 0],
                    ti: [1.255, 1.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [164.687, 109.689, 0],
                    to: [-1.255, -1.014, 0],
                    ti: [1.183, 0.886, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [161.03, 106.849, 0],
                    to: [-1.183, -0.886, 0],
                    ti: [1.111, 0.771, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [157.59, 104.374, 0],
                    to: [-1.111, -0.771, 0],
                    ti: [1.038, 0.668, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [154.367, 102.223, 0],
                    to: [-1.038, -0.668, 0],
                    ti: [0.964, 0.577, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [151.363, 100.364, 0],
                    to: [-0.964, -0.577, 0],
                    ti: [0.888, 0.495, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [148.583, 98.763, 0],
                    to: [-0.888, -0.495, 0],
                    ti: [0.81, 0.422, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [146.035, 97.394, 0],
                    to: [-0.81, -0.422, 0],
                    ti: [0.741, 0.358, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [143.723, 96.23, 0],
                    to: [-0.741, -0.358, 0],
                    ti: [0.687, 0.303, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [141.59, 95.244, 0],
                    to: [-0.687, -0.303, 0],
                    ti: [0.639, 0.255, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [139.604, 94.413, 0],
                    to: [-0.639, -0.255, 0],
                    ti: [0.592, 0.214, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [137.759, 93.715, 0],
                    to: [-0.592, -0.214, 0],
                    ti: [0.547, 0.179, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [136.052, 93.13, 0],
                    to: [-0.547, -0.179, 0],
                    ti: [0.502, 0.149, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [134.48, 92.643, 0],
                    to: [-0.502, -0.149, 0],
                    ti: [0.46, 0.124, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [133.038, 92.238, 0],
                    to: [-0.46, -0.124, 0],
                    ti: [0.418, 0.103, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [131.723, 91.901, 0],
                    to: [-0.418, -0.103, 0],
                    ti: [0.378, 0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [130.53, 91.622, 0],
                    to: [-0.378, -0.085, 0],
                    ti: [0.339, 0.071, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [129.457, 91.39, 0],
                    to: [-0.339, -0.071, 0],
                    ti: [0.301, 0.059, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [128.499, 91.197, 0],
                    to: [-0.301, -0.059, 0],
                    ti: [0.264, 0.049, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [127.654, 91.037, 0],
                    to: [-0.264, -0.049, 0],
                    ti: [0.228, 0.041, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [126.917, 90.903, 0],
                    to: [-0.228, -0.041, 0],
                    ti: [0.193, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [126.287, 90.791, 0],
                    to: [-0.193, -0.034, 0],
                    ti: [0.158, 0.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [125.761, 90.698, 0],
                    to: [-0.158, -0.028, 0],
                    ti: [0.134, 0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [125.337, 90.622, 0],
                    to: [-0.134, -0.025, 0],
                    ti: [0.126, 0.027, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [124.96, 90.547, 0],
                    to: [-0.126, -0.027, 0],
                    ti: [0.127, 0.03, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [124.579, 90.461, 0],
                    to: [-0.127, -0.03, 0],
                    ti: [0.127, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [124.196, 90.364, 0],
                    to: [-0.127, -0.034, 0],
                    ti: [0.126, 0.037, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [123.816, 90.257, 0],
                    to: [-0.126, -0.037, 0],
                    ti: [0.124, 0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [123.44, 90.141, 0],
                    to: [-0.124, -0.04, 0],
                    ti: [0.121, 0.042, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [123.073, 90.017, 0],
                    to: [-0.121, -0.042, 0],
                    ti: [0.116, 0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 118,
                    s: [122.717, 89.887, 0],
                    to: [-0.116, -0.044, 0],
                    ti: [0.11, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 120,
                    s: [122.376, 89.751, 0],
                    to: [-0.11, -0.046, 0],
                    ti: [0.103, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 122,
                    s: [122.055, 89.613, 0],
                    to: [-0.103, -0.046, 0],
                    ti: [0.095, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 124,
                    s: [121.755, 89.474, 0],
                    to: [-0.095, -0.046, 0],
                    ti: [0.086, 0.045, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 126,
                    s: [121.483, 89.337, 0],
                    to: [-0.086, -0.045, 0],
                    ti: [0.075, 0.043, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 128,
                    s: [121.241, 89.204, 0],
                    to: [-0.075, -0.043, 0],
                    ti: [0.062, 0.041, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 130,
                    s: [121.035, 89.077, 0],
                    to: [-0.062, -0.041, 0],
                    ti: [0.028, 0.019, 0],
                  },
                  { t: 132, s: [120.867, 88.96, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.272, 0.954, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 24,
                    s: [88.213, 98.042, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.933, 0.787, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.064, -0.101, 0] },
                    t: 26,
                    s: [98.671, 49.922, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 0.883, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.333, 0.137, 0] },
                    t: 28,
                    s: [54.098, 71.655, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.856, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.111, 0.287, 0] },
                    t: 30,
                    s: [63.017, 105.433, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.962, 0.719, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.076, 0.001, 0] },
                    t: 32,
                    s: [59.185, 119.254, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 1.027, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.071, 0.118, 0] },
                    t: 34,
                    s: [102.36, 105.209, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, 0.02, 0] },
                    t: 36,
                    s: [79.066, 71.811, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.893, -1.794, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.579, -1.652, 0] },
                    t: 38,
                    s: [101.985, 115.908, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.972, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.076, 0.086, 0] },
                    t: 40,
                    s: [105.84, 113.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.033, 0.698, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.042, -0.255, 0] },
                    t: 42,
                    s: [60.688, 44.915, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.04, 0.92, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.024, 0.115, 0] },
                    t: 44,
                    s: [90.735, 61.894, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, 0.271, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.027, -2.022, 0] },
                    t: 46,
                    s: [48.636, 106.533, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.029, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.867, 0.094, 0] },
                    t: 48,
                    s: [110.804, 104.766, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.309, 0.797, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.022, -0.018, 0] },
                    t: 50,
                    s: [105.35, 91.067, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.026, 0.865, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.141, 0] },
                    t: 52,
                    s: [112.726, 102.388, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 1.297, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.02, 0.217, 0] },
                    t: 54,
                    s: [78.021, 118.647, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.73, 0.94, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.493, 0.065, 0] },
                    t: 56,
                    s: [123.499, 128.748, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.684, 0.681, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.12, -0.217, 0] },
                    t: 58,
                    s: [116.924, 82.593, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 1.037, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, 0.113, 0] },
                    t: 60,
                    s: [102.179, 95.418, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.886, 0.872, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, 0.026, 0] },
                    t: 62,
                    s: [61.093, 131.691, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.995, 1.018, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.309, 0.239, 0] },
                    t: 64,
                    s: [97.755, 79.275, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.561, 0.959, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.006, 0.015, 0] },
                    t: 66,
                    s: [111.269, 51.174, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.977, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.103, -0.081, 0] },
                    t: 68,
                    s: [98.597, 85.374, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, 0.259, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.031, 0.937, 0] },
                    t: 70,
                    s: [44.555, 68.006, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.03, 1.073, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.425, 0.094, 0] },
                    t: 72,
                    s: [83.792, 66.311, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.079, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.022, 0.039, 0] },
                    t: 74,
                    s: [93.366, 52.935, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.872, 0.97, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, 0.017, 0] },
                    t: 76,
                    s: [80.351, 77.978, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.459, 0.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.24, -0.047, 0] },
                    t: 78,
                    s: [105.736, 46.617, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, 1.164, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, 1.147, 0] },
                    t: 80,
                    s: [119.231, 66.597, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.338, 0.408, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-4.839, 0.055, 0] },
                    t: 82,
                    s: [31.333, 68.163, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.923, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.089, 0.097, 0] },
                    t: 84,
                    s: [32.821, 63.516, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.316, 1.245, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.049, -0.192, 0] },
                    t: 86,
                    s: [55.229, 35.157, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.082, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.095, 0.062, 0] },
                    t: 88,
                    s: [53.579, 43.75, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.008, 0.863, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, -0.1, 0] },
                    t: 90,
                    s: [41.681, 9.938, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 1.024, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.008, 0.213, 0] },
                    t: 92,
                    s: [65.338, 25.314, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.135, 1.097, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, 0.019, 0] },
                    t: 94,
                    s: [39.293, 35.228, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.942, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.052, 0.045, 0] },
                    t: 96,
                    s: [71.909, 22.453, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 1.746, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.192, 0.562, 0] },
                    t: 98,
                    s: [-13.722, 50.113, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.78, 0.885, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, 0.075, 0] },
                    t: 100,
                    s: [12.165, 54.928, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 1.049, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.134, 0.304, 0] },
                    t: 102,
                    s: [36.047, 7.012, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.981, 0.875, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.369, 0.031, 0] },
                    t: 104,
                    s: [75.273, -11.082, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.976, 1.192, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.024, 0.248, 0] },
                    t: 106,
                    s: [68.05, 17.747, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.142, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.033, 0.058, 0] },
                    t: 108,
                    s: [73.648, 32.314, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.915, 0.932, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, 0.553, 0] },
                    t: 110,
                    s: [69.641, -15.771, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.919, -0.285, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [4.378, -0.361, 0] },
                    t: 112,
                    s: [32.387, -24.312, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [216.664, 0.822, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-3.24, 0.089, 0] },
                    t: 114,
                    s: [31.664, -22.708, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.876, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.083, 0.156, 0] },
                    t: 116,
                    s: [31.682, 0.419, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.616, 0.585, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.235, 0.252, 0] },
                    t: 118,
                    s: [-15.245, 26.807, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.106, 0.104, 0] },
                    t: 120,
                    s: [-2.957, 39.841, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.946, 0.871, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.581, -0.016, 0] },
                    t: 122,
                    s: [41.396, 91.664, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.754, 0.858, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.15, 0.236, 0] },
                    t: 124,
                    s: [48.828, 48.327, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.928, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.075, 0.203, 0] },
                    t: 126,
                    s: [46.178, 24.721, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.871, 0.408, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, -0.524, 0] },
                    t: 128,
                    s: [72.828, 8.231, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.235, 0.097, 0] },
                    t: 130,
                    s: [23.424, 10.493, 100],
                  },
                  { t: 132, s: [-3.769, 24.296, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 24,
            op: 236,
            st: 24,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 14,
            ty: 4,
            nm: 'Shape Layer 61',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 8,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 18,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 62,
                    s: [100],
                  },
                  { t: 94, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 92, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.727 },
                    o: { x: 0.167, y: 0.111 },
                    t: 8,
                    s: [269.491, 306.01, 0],
                    to: [0.367, 0.261, 0],
                    ti: [-1.762, -1.355, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.065 },
                    t: 10,
                    s: [271.692, 307.579, 0],
                    to: [1.762, 1.355, 0],
                    ti: [-4.055, -2.961, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.882 },
                    o: { x: 0.167, y: 0.087 },
                    t: 12,
                    s: [280.065, 314.139, 0],
                    to: [4.055, 2.961, 0],
                    ti: [-5.763, -3.712, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.885 },
                    o: { x: 0.167, y: 0.117 },
                    t: 14,
                    s: [296.024, 325.342, 0],
                    to: [5.763, 3.712, 0],
                    ti: [-5.909, -3.26, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.878 },
                    o: { x: 0.167, y: 0.143 },
                    t: 16,
                    s: [314.641, 336.411, 0],
                    to: [5.909, 3.26, 0],
                    ti: [-5.199, -2.461, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.872 },
                    o: { x: 0.167, y: 0.155 },
                    t: 18,
                    s: [331.476, 344.903, 0],
                    to: [5.199, 2.461, 0],
                    ti: [-4.435, -1.834, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.866 },
                    o: { x: 0.167, y: 0.16 },
                    t: 20,
                    s: [345.833, 351.179, 0],
                    to: [4.435, 1.834, 0],
                    ti: [-3.802, -1.398, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.862 },
                    o: { x: 0.167, y: 0.162 },
                    t: 22,
                    s: [358.083, 355.908, 0],
                    to: [3.802, 1.398, 0],
                    ti: [-3.292, -1.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.858 },
                    o: { x: 0.167, y: 0.164 },
                    t: 24,
                    s: [368.642, 359.567, 0],
                    to: [3.292, 1.093, 0],
                    ti: [-2.886, -0.867, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.165 },
                    t: 26,
                    s: [377.836, 362.467, 0],
                    to: [2.886, 0.867, 0],
                    ti: [-2.566, -0.687, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.852 },
                    o: { x: 0.167, y: 0.167 },
                    t: 28,
                    s: [385.959, 364.771, 0],
                    to: [2.566, 0.687, 0],
                    ti: [-2.306, -0.543, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.849 },
                    o: { x: 0.167, y: 0.168 },
                    t: 30,
                    s: [393.233, 366.589, 0],
                    to: [2.306, 0.543, 0],
                    ti: [-2.085, -0.431, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.17 },
                    t: 32,
                    s: [399.792, 368.03, 0],
                    to: [2.085, 0.431, 0],
                    ti: [-1.895, -0.342, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.171 },
                    t: 34,
                    s: [405.741, 369.174, 0],
                    to: [1.895, 0.342, 0],
                    ti: [-1.729, -0.272, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.844 },
                    o: { x: 0.167, y: 0.171 },
                    t: 36,
                    s: [411.16, 370.082, 0],
                    to: [1.729, 0.272, 0],
                    ti: [-1.583, -0.217, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.172 },
                    t: 38,
                    s: [416.115, 370.805, 0],
                    to: [1.583, 0.217, 0],
                    ti: [-1.452, -0.173, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.172 },
                    t: 40,
                    s: [420.657, 371.382, 0],
                    to: [1.452, 0.173, 0],
                    ti: [-1.335, -0.139, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.172 },
                    t: 42,
                    s: [424.829, 371.844, 0],
                    to: [1.335, 0.139, 0],
                    ti: [-1.229, -0.113, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.172 },
                    t: 44,
                    s: [428.668, 372.218, 0],
                    to: [1.229, 0.113, 0],
                    ti: [-1.131, -0.094, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.172 },
                    t: 46,
                    s: [432.201, 372.525, 0],
                    to: [1.131, 0.094, 0],
                    ti: [-1.041, -0.08, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.174 },
                    t: 48,
                    s: [435.454, 372.783, 0],
                    to: [1.041, 0.08, 0],
                    ti: [-0.958, -0.071, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.174 },
                    t: 50,
                    s: [438.448, 373.008, 0],
                    to: [0.958, 0.071, 0],
                    ti: [-0.88, -0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.173 },
                    t: 52,
                    s: [441.201, 373.211, 0],
                    to: [0.88, 0.066, 0],
                    ti: [-0.807, -0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.172 },
                    t: 54,
                    s: [443.727, 373.403, 0],
                    to: [0.807, 0.063, 0],
                    ti: [-0.738, -0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.172 },
                    t: 56,
                    s: [446.042, 373.591, 0],
                    to: [0.738, 0.063, 0],
                    ti: [-0.673, -0.065, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.173 },
                    t: 58,
                    s: [448.156, 373.783, 0],
                    to: [0.673, 0.065, 0],
                    ti: [-0.611, -0.069, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.173 },
                    t: 60,
                    s: [450.079, 373.983, 0],
                    to: [0.611, 0.069, 0],
                    ti: [-0.552, -0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.172 },
                    t: 62,
                    s: [451.822, 374.195, 0],
                    to: [0.552, 0.073, 0],
                    ti: [-0.495, -0.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.171 },
                    t: 64,
                    s: [453.39, 374.419, 0],
                    to: [0.495, 0.077, 0],
                    ti: [-0.44, -0.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.849 },
                    o: { x: 0.167, y: 0.17 },
                    t: 66,
                    s: [454.792, 374.659, 0],
                    to: [0.44, 0.082, 0],
                    ti: [-0.387, -0.086, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.852 },
                    o: { x: 0.167, y: 0.168 },
                    t: 68,
                    s: [456.031, 374.911, 0],
                    to: [0.387, 0.086, 0],
                    ti: [-0.336, -0.09, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.857 },
                    o: { x: 0.167, y: 0.165 },
                    t: 70,
                    s: [457.115, 375.176, 0],
                    to: [0.336, 0.09, 0],
                    ti: [-0.286, -0.092, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.864 },
                    o: { x: 0.167, y: 0.16 },
                    t: 72,
                    s: [458.047, 375.45, 0],
                    to: [0.286, 0.092, 0],
                    ti: [-0.237, -0.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.874 },
                    o: { x: 0.167, y: 0.154 },
                    t: 74,
                    s: [458.831, 375.73, 0],
                    to: [0.237, 0.093, 0],
                    ti: [-0.19, -0.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.886 },
                    o: { x: 0.167, y: 0.143 },
                    t: 76,
                    s: [459.47, 376.01, 0],
                    to: [0.19, 0.093, 0],
                    ti: [-0.143, -0.09, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.906 },
                    o: { x: 0.167, y: 0.123 },
                    t: 78,
                    s: [459.968, 376.286, 0],
                    to: [0.143, 0.09, 0],
                    ti: [-0.097, -0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.936 },
                    o: { x: 0.167, y: 0.088 },
                    t: 80,
                    s: [460.328, 376.55, 0],
                    to: [0.097, 0.085, 0],
                    ti: [-0.052, -0.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.028 },
                    t: 82,
                    s: [460.55, 376.795, 0],
                    to: [0.052, 0.077, 0],
                    ti: [-0.007, -0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [460.639, 377.012, 0],
                    to: [0.007, 0.066, 0],
                    ti: [0.032, -0.061, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [460.595, 377.193, 0],
                    to: [-0.032, 0.061, 0],
                    ti: [0.056, -0.069, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [460.445, 377.377, 0],
                    to: [-0.056, 0.069, 0],
                    ti: [0.06, -0.083, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [460.259, 377.605, 0],
                    to: [-0.06, 0.083, 0],
                    ti: [0.055, -0.097, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [460.084, 377.877, 0],
                    to: [-0.055, 0.097, 0],
                    ti: [0.049, -0.11, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [459.926, 378.189, 0],
                    to: [-0.049, 0.11, 0],
                    ti: [0.042, -0.121, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [459.788, 378.537, 0],
                    to: [-0.042, 0.121, 0],
                    ti: [0.034, -0.13, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [459.673, 378.915, 0],
                    to: [-0.034, 0.13, 0],
                    ti: [0.025, -0.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [459.585, 379.319, 0],
                    to: [-0.025, 0.138, 0],
                    ti: [0.015, -0.145, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [459.525, 379.745, 0],
                    to: [-0.015, 0.145, 0],
                    ti: [0.004, -0.15, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [459.496, 380.189, 0],
                    to: [-0.004, 0.15, 0],
                    ti: [-0.006, -0.154, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 106,
                    s: [459.499, 380.647, 0],
                    to: [0.006, 0.154, 0],
                    ti: [-0.017, -0.157, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 108,
                    s: [459.535, 381.115, 0],
                    to: [0.017, 0.157, 0],
                    ti: [-0.028, -0.158, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.993 },
                    o: { x: 0.167, y: 0.007 },
                    t: 110,
                    s: [459.603, 381.589, 0],
                    to: [0.028, 0.158, 0],
                    ti: [-0.039, -0.159, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.012 },
                    t: 112,
                    s: [459.705, 382.066, 0],
                    to: [0.039, 0.159, 0],
                    ti: [-0.05, -0.158, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.982 },
                    o: { x: 0.167, y: 0.018 },
                    t: 114,
                    s: [459.838, 382.542, 0],
                    to: [0.05, 0.158, 0],
                    ti: [-0.059, -0.156, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.975 },
                    o: { x: 0.167, y: 0.025 },
                    t: 116,
                    s: [460.002, 383.013, 0],
                    to: [0.059, 0.156, 0],
                    ti: [-0.032, -0.077, 0],
                  },
                  { t: 118, s: [460.195, 383.477, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 8,
                    s: [97.409, 66.055, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.753, 1.579, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.148, -0.189, 0] },
                    t: 10,
                    s: [44.567, 76.147, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.004, 1.01, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.126, 0.073, 0] },
                    t: 12,
                    s: [63.613, 73.054, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.971, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.004, 0.009, 0] },
                    t: 14,
                    s: [100.946, 97.634, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.792, 1.619, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.045, 0.494, 0] },
                    t: 16,
                    s: [61.805, 70.187, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.93, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.139, 0.073, 0] },
                    t: 18,
                    s: [87.307, 64.612, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.68, 0.923, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.12, -0.436, 0] },
                    t: 20,
                    s: [125.487, 111.62, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.883, -3.054, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, -0.938, 0] },
                    t: 22,
                    s: [109.816, 104.073, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.123, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.289, 0.085, 0] },
                    t: 24,
                    s: [65.323, 104.689, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.05, -0.013, 0] },
                    t: 26,
                    s: [47.252, 134.038, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.754, 0.996, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.445, 0.017, 0] },
                    t: 28,
                    s: [91.917, 108.765, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.965, 0.782, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.126, -0.004, 0] },
                    t: 30,
                    s: [102.196, 140.322, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.598, 0.943, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.059, 0.135, 0] },
                    t: 32,
                    s: [122.314, 110.374, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 0.983, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.105, -0.179, 0] },
                    t: 34,
                    s: [110.56, 62.157, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.992, 0.811, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.102, -0.022, 0] },
                    t: 36,
                    s: [65.661, 77.47, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.964, 1.174, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.009, 0.149, 0] },
                    t: 38,
                    s: [85.871, 65.321, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.389, 0.889, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.062, 0.056, 0] },
                    t: 40,
                    s: [67.714, 49.917, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.983, 1.088, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.096, 0.335, 0] },
                    t: 42,
                    s: [78.115, 97.527, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.976, 1.012, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.021, 0.043, 0] },
                    t: 44,
                    s: [143.974, 113.298, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.034, 0.01, 0] },
                    t: 46,
                    s: [91.205, 80.878, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.004, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, 0.012, 0] },
                    t: 48,
                    s: [128.534, 117.961, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.878, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.004, -0.04, 0] },
                    t: 50,
                    s: [81.833, 74.831, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.701, 1.076, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.245, 0.261, 0] },
                    t: 52,
                    s: [130.604, 103.951, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.848, 0.884, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.115, 0.04, 0] },
                    t: 54,
                    s: [118.24, 117.585, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, 1.085, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.185, 0.297, 0] },
                    t: 56,
                    s: [86.185, 91.54, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.239, 1.079, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.857, 0.042, 0] },
                    t: 58,
                    s: [59.806, 81.372, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.06, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.078, 0.041, 0] },
                    t: 60,
                    s: [62.144, 101.879, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.904, 1.073, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.035, -1.454, 0] },
                    t: 62,
                    s: [25.053, 61.948, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.113, 0.614, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.64, 0.039, 0] },
                    t: 64,
                    s: [88.839, 64.113, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.878, 0.934, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.048, 0.106, 0] },
                    t: 66,
                    s: [98.389, 60.046, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 1.366, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.265, -0.316, 0] },
                    t: 68,
                    s: [75.918, 45.28, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.487, 0.892, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, 0.068, 0] },
                    t: 70,
                    s: [65.601, 48.361, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.02, 1.735, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.1, 0.366, 0] },
                    t: 72,
                    s: [72.571, 31.73, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.839, 1.002, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.016, 0.075, 0] },
                    t: 74,
                    s: [108.482, 26.833, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.939, 0.884, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.173, 0.002, 0] },
                    t: 76,
                    s: [64.065, 74.946, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.807, 1.122, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.226, 0.296, 0] },
                    t: 78,
                    s: [22.794, 25.686, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.039, 1.029, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.147, 0.05, 0] },
                    t: 80,
                    s: [33.918, 6.407, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.027, 0.021, 0] },
                    t: 82,
                    s: [48.539, 53.893, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.843, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.114, -0.046, 0] },
                    t: 84,
                    s: [27.022, -9.839, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.415, 0.929, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, 0.001, 0] },
                    t: 86,
                    s: [36.118, 31.361, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.042, 0.504, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, -0.476, 0] },
                    t: 88,
                    s: [44.181, -10.557, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 0.874, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.028, 0.1, 0] },
                    t: 90,
                    s: [-4.037, -4.307, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.945, 0.884, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.027, 0.248, 0] },
                    t: 92,
                    s: [68.255, 26.661, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.863, 0.961, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.164, 0.298, 0] },
                    t: 94,
                    s: [13.766, 42.343, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.171, -1.3, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.213, -0.072, 0] },
                    t: 96,
                    s: [32.121, 48.441, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.056, 0.086, 0] },
                    t: 98,
                    s: [43.941, 45.171, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.907, 0.82, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.238, -0.116, 0] },
                    t: 100,
                    s: [7.866, -41.801, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.765, 0.908, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.767, 0.155, 0] },
                    t: 102,
                    s: [17.231, -5.411, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.963, 0.772, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.087, 0.843, 0] },
                    t: 104,
                    s: [18.373, 36.907, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.047, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.066, 0.131, 0] },
                    t: 106,
                    s: [41.417, 41.547, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.142, 1.256, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.03, -0.64, 0] },
                    t: 108,
                    s: [28.526, 49.618, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.933, 1.594, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.053, 0.063, 0] },
                    t: 110,
                    s: [48.627, 48.688, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.831, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.344, 0.073, 0] },
                    t: 112,
                    s: [-5.742, 52.477, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.959, 1.011, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.164, -0.017, 0] },
                    t: 114,
                    s: [4.853, 21.683, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.082, 0.01, 0] },
                    t: 116,
                    s: [15.758, 47.142, 100],
                  },
                  { t: 118, s: [10.26, 18.203, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [10, 10], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.6431372549019608, 0.13725490196078433, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 8,
            op: 222,
            st: 8,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 15,
            ty: 4,
            nm: 'Shape Layer 60',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 68,
                    s: [100],
                  },
                  { t: 100, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.528 },
                    o: { x: 0.167, y: 0.161 },
                    t: 14,
                    s: [273.717, 253.598, 0],
                    to: [0.28, -0.028, 0],
                    ti: [-1.664, -0.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.761 },
                    o: { x: 0.167, y: 0.1 },
                    t: 16,
                    s: [275.395, 253.428, 0],
                    to: [1.664, 0.082, 0],
                    ti: [-3.922, -0.767, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.835 },
                    o: { x: 0.167, y: 0.119 },
                    t: 18,
                    s: [283.7, 254.091, 0],
                    to: [3.922, 0.767, 0],
                    ti: [-5.158, -1.975, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.876 },
                    o: { x: 0.167, y: 0.126 },
                    t: 20,
                    s: [298.929, 258.033, 0],
                    to: [5.158, 1.975, 0],
                    ti: [-4.6, -2.946, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.909 },
                    o: { x: 0.167, y: 0.106 },
                    t: 22,
                    s: [314.649, 265.939, 0],
                    to: [4.6, 2.946, 0],
                    ti: [-3.328, -3.296, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.939 },
                    o: { x: 0.167, y: 0.072 },
                    t: 24,
                    s: [326.529, 275.709, 0],
                    to: [3.328, 3.296, 0],
                    ti: [-2.233, -3.252, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.963 },
                    o: { x: 0.167, y: 0.043 },
                    t: 26,
                    s: [334.618, 285.714, 0],
                    to: [2.233, 3.252, 0],
                    ti: [-1.455, -3.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.024 },
                    t: 28,
                    s: [339.929, 295.223, 0],
                    to: [1.455, 3.046, 0],
                    ti: [-0.931, -2.79, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.012 },
                    t: 30,
                    s: [343.35, 303.988, 0],
                    to: [0.931, 2.79, 0],
                    ti: [-0.582, -2.533, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.995 },
                    o: { x: 0.167, y: 0.006 },
                    t: 32,
                    s: [345.513, 311.964, 0],
                    to: [0.582, 2.533, 0],
                    ti: [-0.353, -2.293, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.003 },
                    t: 34,
                    s: [346.843, 319.188, 0],
                    to: [0.353, 2.293, 0],
                    ti: [-0.205, -2.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 36,
                    s: [347.63, 325.723, 0],
                    to: [0.205, 2.077, 0],
                    ti: [-0.115, -1.887, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [348.075, 331.651, 0],
                    to: [0.115, 1.887, 0],
                    ti: [-0.064, -1.719, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [348.319, 337.046, 0],
                    to: [0.064, 1.719, 0],
                    ti: [-0.041, -1.573, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [348.461, 341.968, 0],
                    to: [0.041, 1.573, 0],
                    ti: [-0.028, -1.452, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [348.564, 346.482, 0],
                    to: [0.028, 1.452, 0],
                    ti: [-0.019, -1.351, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [348.628, 350.68, 0],
                    to: [0.019, 1.351, 0],
                    ti: [-0.016, -1.256, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [348.676, 354.586, 0],
                    to: [0.016, 1.256, 0],
                    ti: [-0.018, -1.167, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [348.724, 358.218, 0],
                    to: [0.018, 1.167, 0],
                    ti: [-0.022, -1.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [348.783, 361.589, 0],
                    to: [0.022, 1.082, 0],
                    ti: [-0.029, -1, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [348.858, 364.71, 0],
                    to: [0.029, 1, 0],
                    ti: [-0.036, -0.921, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [348.955, 367.59, 0],
                    to: [0.036, 0.921, 0],
                    ti: [-0.043, -0.844, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 58,
                    s: [349.073, 370.236, 0],
                    to: [0.043, 0.844, 0],
                    ti: [-0.05, -0.768, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 60,
                    s: [349.212, 372.652, 0],
                    to: [0.05, 0.768, 0],
                    ti: [-0.056, -0.694, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 62,
                    s: [349.372, 374.845, 0],
                    to: [0.056, 0.694, 0],
                    ti: [-0.062, -0.622, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 64,
                    s: [349.55, 376.819, 0],
                    to: [0.062, 0.622, 0],
                    ti: [-0.067, -0.551, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 66,
                    s: [349.743, 378.577, 0],
                    to: [0.067, 0.551, 0],
                    ti: [-0.07, -0.481, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.004 },
                    t: 68,
                    s: [349.95, 380.124, 0],
                    to: [0.07, 0.481, 0],
                    ti: [-0.073, -0.413, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.007 },
                    t: 70,
                    s: [350.166, 381.464, 0],
                    to: [0.073, 0.413, 0],
                    ti: [-0.075, -0.348, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.992 },
                    o: { x: 0.167, y: 0.01 },
                    t: 72,
                    s: [350.389, 382.603, 0],
                    to: [0.075, 0.348, 0],
                    ti: [-0.075, -0.284, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.015 },
                    t: 74,
                    s: [350.614, 383.549, 0],
                    to: [0.075, 0.284, 0],
                    ti: [-0.075, -0.222, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.981 },
                    o: { x: 0.167, y: 0.024 },
                    t: 76,
                    s: [350.84, 384.306, 0],
                    to: [0.075, 0.222, 0],
                    ti: [-0.073, -0.163, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.968 },
                    o: { x: 0.167, y: 0.044 },
                    t: 78,
                    s: [351.062, 384.882, 0],
                    to: [0.073, 0.163, 0],
                    ti: [-0.071, -0.106, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.939 },
                    o: { x: 0.167, y: 0.088 },
                    t: 80,
                    s: [351.279, 385.285, 0],
                    to: [0.071, 0.106, 0],
                    ti: [-0.068, -0.053, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.867 },
                    o: { x: 0.167, y: 0.17 },
                    t: 82,
                    s: [351.487, 385.521, 0],
                    to: [0.068, 0.053, 0],
                    ti: [-0.064, -0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.835 },
                    o: { x: 0.167, y: 0.148 },
                    t: 84,
                    s: [351.685, 385.601, 0],
                    to: [0.064, 0.002, 0],
                    ti: [-0.059, 0.045, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.919 },
                    o: { x: 0.167, y: 0.06 },
                    t: 86,
                    s: [351.87, 385.535, 0],
                    to: [0.059, -0.045, 0],
                    ti: [-0.054, 0.088, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.965 },
                    o: { x: 0.167, y: 0.027 },
                    t: 88,
                    s: [352.04, 385.333, 0],
                    to: [0.054, -0.088, 0],
                    ti: [-0.049, 0.127, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.984 },
                    o: { x: 0.167, y: 0.013 },
                    t: 90,
                    s: [352.195, 385.007, 0],
                    to: [0.049, -0.127, 0],
                    ti: [-0.043, 0.161, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.992 },
                    o: { x: 0.167, y: 0.007 },
                    t: 92,
                    s: [352.331, 384.571, 0],
                    to: [0.043, -0.161, 0],
                    ti: [-0.035, 0.185, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.004 },
                    t: 94,
                    s: [352.45, 384.039, 0],
                    to: [0.035, -0.185, 0],
                    ti: [-0.024, 0.192, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 96,
                    s: [352.541, 383.461, 0],
                    to: [0.024, -0.192, 0],
                    ti: [-0.013, 0.188, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [352.596, 382.887, 0],
                    to: [0.013, -0.188, 0],
                    ti: [-0.001, 0.179, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [352.617, 382.333, 0],
                    to: [0.001, -0.179, 0],
                    ti: [0.007, 0.165, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [352.603, 381.814, 0],
                    to: [-0.007, -0.165, 0],
                    ti: [0.003, 0.149, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [352.574, 381.345, 0],
                    to: [-0.003, -0.149, 0],
                    ti: [-0.011, 0.133, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 106,
                    s: [352.587, 380.922, 0],
                    to: [0.011, -0.133, 0],
                    ti: [-0.023, 0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.011 },
                    t: 108,
                    s: [352.639, 380.546, 0],
                    to: [0.023, -0.117, 0],
                    ti: [-0.014, 0.055, 0],
                  },
                  { t: 110, s: [352.724, 380.217, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.45, -1.622, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 14,
                    s: [64.676, 29.575, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.851, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.079, 0.086, 0] },
                    t: 16,
                    s: [63.619, 31.654, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.096, 0.849, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.189, -0.115, 0] },
                    t: 18,
                    s: [83.082, 94.984, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.044, 1.052, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.045, 0.186, 0] },
                    t: 20,
                    s: [98.428, 68.346, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.791, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.029, 0.032, 0] },
                    t: 22,
                    s: [65.326, 46.692, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.846, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.117, 0.139, 0] },
                    t: 24,
                    s: [115.976, 81.786, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.191, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.181, -0.001, 0] },
                    t: 26,
                    s: [94.867, 134.538, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 1.096, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, 0.505, 0] },
                    t: 28,
                    s: [76.907, 82.463, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.592, 0.774, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.576, 0.045, 0] },
                    t: 30,
                    s: [136.087, 72.163, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.95, 0.874, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.132, 0] },
                    t: 32,
                    s: [146.093, 94.38, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 1.06, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.126, 0.245, 0] },
                    t: 34,
                    s: [64.998, 132.461, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.163, 0.877, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, 0.035, 0] },
                    t: 36,
                    s: [97.241, 152.104, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.936, 0.874, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, 0.259, 0] },
                    t: 38,
                    s: [126.81, 118.253, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.87, 0.449, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.273, 0.246, 0] },
                    t: 40,
                    s: [39.419, 102.155, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.013, 0.912, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.231, 0.098, 0] },
                    t: 42,
                    s: [59.845, 93.89, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.068, 2.263, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.011, 1.504, 0] },
                    t: 44,
                    s: [71.34, 47.538, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.87, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.037, 0.078, 0] },
                    t: 46,
                    s: [58.098, 44.818, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.848, 0.781, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.234, -0.119, 0] },
                    t: 48,
                    s: [82.135, 88.74, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.37, 1.069, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.185, 0.135, 0] },
                    t: 50,
                    s: [95.452, 70.689, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 0.946, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.068, 0.038, 0] },
                    t: 52,
                    s: [106.399, 41.339, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.931, 0.784, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, -0.156, 0] },
                    t: 54,
                    s: [46.837, 95.066, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.989, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.417, 0.136, 0] },
                    t: 56,
                    s: [100.241, 76.371, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.202, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.224, -0.013, 0] },
                    t: 58,
                    s: [91.348, 46.624, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.962, 0.96, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.059, -0.017, 0] },
                    t: 60,
                    s: [86.082, 72.273, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.193, 0.684, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.069, -0.076, 0] },
                    t: 62,
                    s: [104.113, 51.031, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.964, 0.99, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, 0.113, 0] },
                    t: 64,
                    s: [94.281, 62.125, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.717, 0.85, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.063, -0.012, 0] },
                    t: 66,
                    s: [126.942, 93.089, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.99, 0.934, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, 0.187, 0] },
                    t: 68,
                    s: [108.331, 65.944, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.969, 0.494, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.012, -0.318, 0] },
                    t: 70,
                    s: [63.723, 44.138, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.903, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.049, 0.1, 0] },
                    t: 72,
                    s: [102.921, 48.671, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.464, 0.113, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.147, 0.604, 0] },
                    t: 74,
                    s: [78.268, 71.671, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.902, 1.068, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, 0.092, 0] },
                    t: 76,
                    s: [87.176, 75.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.234, 0.908, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.554, 0.037, 0] },
                    t: 78,
                    s: [28.705, 110.854, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.845, 1.441, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, 0.901, 0] },
                    t: 80,
                    s: [18.357, 46.477, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.091, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.181, 0.07, 0] },
                    t: 82,
                    s: [57.758, 39.913, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 0.801, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, -0.023, 0] },
                    t: 84,
                    s: [91.489, 81.222, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.953, 0.932, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.137, 0.143, 0] },
                    t: 86,
                    s: [20.878, 48.853, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.976, 0.994, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.108, -0.379, 0] },
                    t: 88,
                    s: [47.568, 3.824, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.831, 1.042, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.035, -0.006, 0] },
                    t: 90,
                    s: [35.966, 11.947, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.412, 0.592, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.165, 0.028, 0] },
                    t: 92,
                    s: [44.168, 4.41, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.937, 0.978, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.105, 0] },
                    t: 94,
                    s: [52.575, 15.778, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.454, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.254, -0.03, 0] },
                    t: 96,
                    s: [2.557, 60.137, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.959, 1.407, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, -0.624, 0] },
                    t: 98,
                    s: [14.899, 27.604, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.849, 1.066, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.082, 0.069, 0] },
                    t: 100,
                    s: [83.445, 31.436, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.046, 0.934, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.186, 0.037, 0] },
                    t: 102,
                    s: [48.803, 8.908, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.963, 0.694, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.03, -0.323, 0] },
                    t: 104,
                    s: [20.805, 49.183, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.959, 0.832, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.065, 0.115, 0] },
                    t: 106,
                    s: [64.315, 40.919, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.079, 0.165, 0] },
                    t: 108,
                    s: [39.92, 18.864, 100],
                  },
                  { t: 110, s: [52.411, -3.555, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.7058823529411765, 0.13725490196078433, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 14,
            op: 214,
            st: 14,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 16,
            ty: 4,
            nm: 'Shape Layer 59',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 34,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 78,
                    s: [100],
                  },
                  { t: 110, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [267.865, 255.269, 0],
                    to: [-0.161, -0.402, 0],
                    ti: [1.026, 2.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [266.901, 252.858, 0],
                    to: [-1.026, -2.138, 0],
                    ti: [2.899, 4.739, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [261.706, 242.443, 0],
                    to: [-2.899, -4.739, 0],
                    ti: [4.94, 5.799, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [249.506, 224.427, 0],
                    to: [-4.94, -5.799, 0],
                    ti: [5.963, 4.5, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [232.069, 207.647, 0],
                    to: [-5.963, -4.5, 0],
                    ti: [5.888, 2.374, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [213.727, 197.425, 0],
                    to: [-5.888, -2.374, 0],
                    ti: [5.276, 0.563, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [196.743, 193.401, 0],
                    to: [-5.276, -0.563, 0],
                    ti: [4.452, -0.741, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [182.074, 194.046, 0],
                    to: [-4.452, 0.741, 0],
                    ti: [3.589, -1.577, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [170.029, 197.845, 0],
                    to: [-3.589, 1.577, 0],
                    ti: [2.794, -2.037, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [160.537, 203.508, 0],
                    to: [-2.794, 2.037, 0],
                    ti: [2.125, -2.229, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [153.265, 210.066, 0],
                    to: [-2.125, 2.229, 0],
                    ti: [1.595, -2.253, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [147.784, 216.885, 0],
                    to: [-1.595, 2.253, 0],
                    ti: [1.188, -2.18, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [143.692, 223.586, 0],
                    to: [-1.188, 2.18, 0],
                    ti: [0.88, -2.056, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [140.657, 229.963, 0],
                    to: [-0.88, 2.056, 0],
                    ti: [0.65, -1.912, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [138.413, 235.924, 0],
                    to: [-0.65, 1.912, 0],
                    ti: [0.477, -1.76, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [136.76, 241.434, 0],
                    to: [-0.477, 1.76, 0],
                    ti: [0.347, -1.607, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [135.553, 246.481, 0],
                    to: [-0.347, 1.607, 0],
                    ti: [0.25, -1.458, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [134.678, 251.075, 0],
                    to: [-0.25, 1.458, 0],
                    ti: [0.178, -1.316, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [134.053, 255.23, 0],
                    to: [-0.178, 1.316, 0],
                    ti: [0.125, -1.182, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [133.611, 258.969, 0],
                    to: [-0.125, 1.182, 0],
                    ti: [0.088, -1.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [133.302, 262.321, 0],
                    to: [-0.088, 1.057, 0],
                    ti: [0.062, -0.942, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [133.085, 265.313, 0],
                    to: [-0.062, 0.942, 0],
                    ti: [0.045, -0.835, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [132.931, 267.971, 0],
                    to: [-0.045, 0.835, 0],
                    ti: [0.035, -0.737, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [132.816, 270.322, 0],
                    to: [-0.035, 0.737, 0],
                    ti: [0.031, -0.648, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [132.72, 272.392, 0],
                    to: [-0.031, 0.648, 0],
                    ti: [0.031, -0.567, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [132.63, 274.209, 0],
                    to: [-0.031, 0.567, 0],
                    ti: [0.035, -0.496, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [132.533, 275.797, 0],
                    to: [-0.035, 0.496, 0],
                    ti: [0.04, -0.432, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [132.422, 277.182, 0],
                    to: [-0.04, 0.432, 0],
                    ti: [0.043, -0.377, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [132.291, 278.392, 0],
                    to: [-0.043, 0.377, 0],
                    ti: [0.036, -0.328, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [132.166, 279.447, 0],
                    to: [-0.036, 0.328, 0],
                    ti: [0.026, -0.282, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [132.075, 280.359, 0],
                    to: [-0.026, 0.282, 0],
                    ti: [0.017, -0.24, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [132.011, 281.139, 0],
                    to: [-0.017, 0.24, 0],
                    ti: [0.01, -0.201, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [131.972, 281.799, 0],
                    to: [-0.01, 0.201, 0],
                    ti: [0.003, -0.166, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [131.954, 282.347, 0],
                    to: [-0.003, 0.166, 0],
                    ti: [-0.002, -0.133, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [131.953, 282.792, 0],
                    to: [0.002, 0.133, 0],
                    ti: [-0.007, -0.102, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 94,
                    s: [131.967, 283.142, 0],
                    to: [0.007, 0.102, 0],
                    ti: [-0.011, -0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.009 },
                    t: 96,
                    s: [131.994, 283.403, 0],
                    to: [0.011, 0.073, 0],
                    ti: [-0.014, -0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.975 },
                    o: { x: 0.167, y: 0.042 },
                    t: 98,
                    s: [132.031, 283.58, 0],
                    to: [0.014, 0.046, 0],
                    ti: [-0.017, -0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.175 },
                    t: 100,
                    s: [132.078, 283.677, 0],
                    to: [0.017, 0.02, 0],
                    ti: [-0.019, 0.004, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.89 },
                    o: { x: 0.167, y: 0.089 },
                    t: 102,
                    s: [132.132, 283.7, 0],
                    to: [0.019, -0.004, 0],
                    ti: [-0.021, 0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.925 },
                    o: { x: 0.167, y: 0.074 },
                    t: 104,
                    s: [132.193, 283.651, 0],
                    to: [0.021, -0.02, 0],
                    ti: [-0.023, 0.018, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.876 },
                    o: { x: 0.167, y: 0.137 },
                    t: 106,
                    s: [132.259, 283.583, 0],
                    to: [0.023, -0.018, 0],
                    ti: [-0.025, 0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.834 },
                    o: { x: 0.167, y: 0.168 },
                    t: 108,
                    s: [132.331, 283.544, 0],
                    to: [0.025, -0.008, 0],
                    ti: [-0.026, 0, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.832 },
                    o: { x: 0.167, y: 0.16 },
                    t: 110,
                    s: [132.408, 283.533, 0],
                    to: [0.026, 0, 0],
                    ti: [-0.027, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.135 },
                    t: 112,
                    s: [132.487, 283.545, 0],
                    to: [0.027, 0.008, 0],
                    ti: [-0.027, -0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.877 },
                    o: { x: 0.167, y: 0.114 },
                    t: 114,
                    s: [132.568, 283.579, 0],
                    to: [0.027, 0.014, 0],
                    ti: [-0.027, -0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.9 },
                    o: { x: 0.167, y: 0.096 },
                    t: 116,
                    s: [132.649, 283.63, 0],
                    to: [0.027, 0.019, 0],
                    ti: [-0.013, -0.011, 0],
                  },
                  { t: 118, s: [132.728, 283.695, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.085, 2.343, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 24,
                    s: [95.923, 97.698, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.959, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.042, 0.078, 0] },
                    t: 26,
                    s: [119.853, 100.809, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.846, 0.754, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.082, -0.083, 0] },
                    t: 28,
                    s: [71.61, 47.548, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.108, 0.987, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.182, 0.126, 0] },
                    t: 30,
                    s: [95.909, 74.187, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.971, 0.88, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, -0.015, 0] },
                    t: 32,
                    s: [116.529, 126.213, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.765, 1.081, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.046, 0.272, 0] },
                    t: 34,
                    s: [69.282, 82.296, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.014, 0.826, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.129, 0.041, 0] },
                    t: 36,
                    s: [99.804, 62.944, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.925, 0.917, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.012, 0.16, 0] },
                    t: 38,
                    s: [155.455, 101.204, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.797, -29.815, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.787, 623.527, 0] },
                    t: 40,
                    s: [90.335, 142.892, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.16, 1.74, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.141, 0.084, 0] },
                    t: 42,
                    s: [96.569, 142.898, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.834, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, 0.075, 0] },
                    t: 44,
                    s: [105.516, 144.953, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.994, 0.309, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, 0.547, 0] },
                    t: 46,
                    s: [79.375, 124.659, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.849, 0.77, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.007, 0.095, 0] },
                    t: 48,
                    s: [53.599, 121.011, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.006, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.187, 0.131, 0] },
                    t: 50,
                    s: [77.44, 94.418, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 0.812, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.006, -0.044, 0] },
                    t: 52,
                    s: [96.658, 47.588, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.869, 1.081, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, 0.15, 0] },
                    t: 54,
                    s: [75.964, 78.236, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.159, 0.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.23, 0.041, 0] },
                    t: 56,
                    s: [94.431, 116.596, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, 0.82, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, -0.058, 0] },
                    t: 58,
                    s: [104.908, 41.132, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-2.162, 1.012, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-4.52, 0.155, 0] },
                    t: 60,
                    s: [74.497, 85.65, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.955, 0.956, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.086, 0.011, 0] },
                    t: 62,
                    s: [75.047, 137.348, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 1.056, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.097, -0.092, 0] },
                    t: 64,
                    s: [95.389, 78.111, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [3.564, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.248, 0.034, 0] },
                    t: 66,
                    s: [85.987, 106.338, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.138, 0.755, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, -0.133, 0] },
                    t: 68,
                    s: [86.576, 59.064, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.052, 0.126, 0] },
                    t: 70,
                    s: [67.887, 77.283, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.048, 1.699, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.951, 0.69, 0] },
                    t: 72,
                    s: [117.559, 112.652, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.968, 0.892, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.091, 0.074, 0] },
                    t: 74,
                    s: [115.524, 117.508, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.868, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.052, 0.361, 0] },
                    t: 76,
                    s: [94.322, 71.923, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.911, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 0.226, 0] },
                    t: 78,
                    s: [107.41, 58.262, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-1.313, 0.689, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.225, -0.022, 0] },
                    t: 80,
                    s: [89.918, 50.26, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.885, 0.965, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.086, 0.114, 0] },
                    t: 82,
                    s: [88.641, 56.58, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.486, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.303, -0.061, 0] },
                    t: 84,
                    s: [54.485, 73.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.806, 1.029, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 0.099, 0] },
                    t: 86,
                    s: [41.537, 63.86, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.052, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.146, 0.021, 0] },
                    t: 88,
                    s: [58.89, 12.33, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 1.279, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.032, 0.455, 0] },
                    t: 90,
                    s: [81.867, 81.726, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.858, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.543, 0.064, 0] },
                    t: 92,
                    s: [44.5, 97.298, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.018, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.202, 0.656, 0] },
                    t: 94,
                    s: [49.475, 29.648, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.415, -65.878, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.015, 34.698, 0] },
                    t: 96,
                    s: [52.978, 19.812, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.126, 1.189, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.083, 0] },
                    t: 98,
                    s: [48.737, 19.789, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.939, 0.939, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.05, 0.058, 0] },
                    t: 100,
                    s: [74.095, 0.809, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.788, 1.031, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.229, -0.233, 0] },
                    t: 102,
                    s: [10.268, 62.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.913, 1.141, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.137, 0.023, 0] },
                    t: 104,
                    s: [27.318, 46.475, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [3.588, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [2.212, 0.052, 0] },
                    t: 106,
                    s: [53.611, 68.98, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.93, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, -0.069, 0] },
                    t: 108,
                    s: [54.64, 8.452, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.974, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.428, -0.05, 0] },
                    t: 110,
                    s: [21.647, 41.626, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.031, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.037, -0.251, 0] },
                    t: 112,
                    s: [27.025, 20.873, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.011, 0.234, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.023, 0.727, 0] },
                    t: 114,
                    s: [23.296, 26.051, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.01, 0.093, 0] },
                    t: 116,
                    s: [28.415, 26.722, 100],
                  },
                  { t: 118, s: [22.608, 32.219, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 24,
            op: 222,
            st: 24,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 17,
            ty: 4,
            nm: 'Shape Layer 57',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 68,
                    s: [100],
                  },
                  { t: 100, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [276.873, 250.83, 0],
                    to: [-0.189, -0.223, 0],
                    ti: [1.057, 0.986, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [275.739, 249.492, 0],
                    to: [-1.057, -0.986, 0],
                    ti: [2.736, 1.782, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [270.529, 244.915, 0],
                    to: [-2.736, -1.782, 0],
                    ti: [4.156, 1.618, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [259.324, 238.803, 0],
                    to: [-4.156, -1.618, 0],
                    ti: [4.345, 0.759, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [245.595, 235.21, 0],
                    to: [-4.345, -0.759, 0],
                    ti: [3.769, 0.09, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [233.251, 234.251, 0],
                    to: [-3.769, -0.09, 0],
                    ti: [3.138, -0.25, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [222.982, 234.672, 0],
                    to: [-3.138, 0.25, 0],
                    ti: [2.634, -0.412, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [214.421, 235.749, 0],
                    to: [-2.634, 0.412, 0],
                    ti: [2.24, -0.491, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [207.179, 237.146, 0],
                    to: [-2.24, 0.491, 0],
                    ti: [1.931, -0.529, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [200.979, 238.697, 0],
                    to: [-1.931, 0.529, 0],
                    ti: [1.683, -0.547, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [195.596, 240.321, 0],
                    to: [-1.683, 0.547, 0],
                    ti: [1.48, -0.555, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [190.881, 241.977, 0],
                    to: [-1.48, 0.555, 0],
                    ti: [1.309, -0.559, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [186.716, 243.649, 0],
                    to: [-1.309, 0.559, 0],
                    ti: [1.16, -0.566, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [183.025, 245.334, 0],
                    to: [-1.16, 0.566, 0],
                    ti: [1.025, -0.581, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [179.755, 247.045, 0],
                    to: [-1.025, 0.581, 0],
                    ti: [0.884, -0.616, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [176.877, 248.822, 0],
                    to: [-0.884, 0.616, 0],
                    ti: [0.678, -0.697, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [174.448, 250.742, 0],
                    to: [-0.678, 0.697, 0],
                    ti: [0.181, -0.747, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.01 },
                    t: 48,
                    s: [172.806, 253.001, 0],
                    to: [-0.181, 0.747, 0],
                    ti: [-0.395, -0.538, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.883 },
                    o: { x: 0.167, y: 0.132 },
                    t: 50,
                    s: [173.36, 255.224, 0],
                    to: [0.395, 0.538, 0],
                    ti: [-0.6, -0.241, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.168 },
                    t: 52,
                    s: [175.177, 256.227, 0],
                    to: [0.6, 0.241, 0],
                    ti: [-0.564, -0.106, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.176 },
                    t: 54,
                    s: [176.961, 256.669, 0],
                    to: [0.564, 0.106, 0],
                    ti: [-0.502, -0.043, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.844 },
                    o: { x: 0.167, y: 0.179 },
                    t: 56,
                    s: [178.564, 256.865, 0],
                    to: [0.502, 0.043, 0],
                    ti: [-0.439, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.179 },
                    t: 58,
                    s: [179.975, 256.928, 0],
                    to: [0.439, 0.008, 0],
                    ti: [-0.38, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.18 },
                    t: 60,
                    s: [181.2, 256.911, 0],
                    to: [0.38, -0.014, 0],
                    ti: [-0.325, 0.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.179 },
                    t: 62,
                    s: [182.253, 256.843, 0],
                    to: [0.325, -0.028, 0],
                    ti: [-0.275, 0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.851 },
                    o: { x: 0.167, y: 0.178 },
                    t: 64,
                    s: [183.148, 256.743, 0],
                    to: [0.275, -0.036, 0],
                    ti: [-0.23, 0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.176 },
                    t: 66,
                    s: [183.9, 256.626, 0],
                    to: [0.23, -0.04, 0],
                    ti: [-0.19, 0.041, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.174 },
                    t: 68,
                    s: [184.526, 256.502, 0],
                    to: [0.19, -0.041, 0],
                    ti: [-0.155, 0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.861 },
                    o: { x: 0.167, y: 0.171 },
                    t: 70,
                    s: [185.04, 256.378, 0],
                    to: [0.155, -0.04, 0],
                    ti: [-0.125, 0.037, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.863 },
                    o: { x: 0.167, y: 0.171 },
                    t: 72,
                    s: [185.458, 256.261, 0],
                    to: [0.125, -0.037, 0],
                    ti: [-0.1, 0.031, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.861 },
                    o: { x: 0.167, y: 0.171 },
                    t: 74,
                    s: [185.791, 256.158, 0],
                    to: [0.1, -0.031, 0],
                    ti: [-0.082, 0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.175 },
                    t: 76,
                    s: [186.06, 256.075, 0],
                    to: [0.082, -0.022, 0],
                    ti: [-0.073, 0.001, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.832 },
                    o: { x: 0.167, y: 0.162 },
                    t: 78,
                    s: [186.284, 256.027, 0],
                    to: [0.073, -0.001, 0],
                    ti: [-0.069, -0.03, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.874 },
                    o: { x: 0.167, y: 0.108 },
                    t: 80,
                    s: [186.495, 256.07, 0],
                    to: [0.069, 0.03, 0],
                    ti: [-0.067, -0.059, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.918 },
                    o: { x: 0.167, y: 0.069 },
                    t: 82,
                    s: [186.699, 256.205, 0],
                    to: [0.067, 0.059, 0],
                    ti: [-0.065, -0.084, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.944 },
                    o: { x: 0.167, y: 0.048 },
                    t: 84,
                    s: [186.897, 256.422, 0],
                    to: [0.065, 0.084, 0],
                    ti: [-0.063, -0.106, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.961 },
                    o: { x: 0.167, y: 0.035 },
                    t: 86,
                    s: [187.09, 256.71, 0],
                    to: [0.063, 0.106, 0],
                    ti: [-0.062, -0.125, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.97 },
                    o: { x: 0.167, y: 0.027 },
                    t: 88,
                    s: [187.278, 257.059, 0],
                    to: [0.062, 0.125, 0],
                    ti: [-0.059, -0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.977 },
                    o: { x: 0.167, y: 0.022 },
                    t: 90,
                    s: [187.46, 257.46, 0],
                    to: [0.059, 0.141, 0],
                    ti: [-0.057, -0.153, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.981 },
                    o: { x: 0.167, y: 0.018 },
                    t: 92,
                    s: [187.635, 257.904, 0],
                    to: [0.057, 0.153, 0],
                    ti: [-0.059, -0.163, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.02 },
                    t: 94,
                    s: [187.802, 258.381, 0],
                    to: [0.059, 0.163, 0],
                    ti: [-0.07, -0.168, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.971 },
                    o: { x: 0.167, y: 0.028 },
                    t: 96,
                    s: [187.988, 258.88, 0],
                    to: [0.07, 0.168, 0],
                    ti: [-0.084, -0.171, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.963 },
                    o: { x: 0.167, y: 0.035 },
                    t: 98,
                    s: [188.22, 259.391, 0],
                    to: [0.084, 0.171, 0],
                    ti: [-0.097, -0.171, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.956 },
                    o: { x: 0.167, y: 0.044 },
                    t: 100,
                    s: [188.492, 259.907, 0],
                    to: [0.097, 0.171, 0],
                    ti: [-0.108, -0.169, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.948 },
                    o: { x: 0.167, y: 0.051 },
                    t: 102,
                    s: [188.799, 260.419, 0],
                    to: [0.108, 0.169, 0],
                    ti: [-0.117, -0.165, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.941 },
                    o: { x: 0.167, y: 0.059 },
                    t: 104,
                    s: [189.138, 260.923, 0],
                    to: [0.117, 0.165, 0],
                    ti: [-0.125, -0.159, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.933 },
                    o: { x: 0.167, y: 0.067 },
                    t: 106,
                    s: [189.502, 261.41, 0],
                    to: [0.125, 0.159, 0],
                    ti: [-0.13, -0.151, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.926 },
                    o: { x: 0.167, y: 0.076 },
                    t: 108,
                    s: [189.886, 261.876, 0],
                    to: [0.13, 0.151, 0],
                    ti: [-0.134, -0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.919 },
                    o: { x: 0.167, y: 0.084 },
                    t: 110,
                    s: [190.284, 262.315, 0],
                    to: [0.134, 0.141, 0],
                    ti: [-0.135, -0.13, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.911 },
                    o: { x: 0.167, y: 0.093 },
                    t: 112,
                    s: [190.69, 262.722, 0],
                    to: [0.135, 0.13, 0],
                    ti: [-0.135, -0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.904 },
                    o: { x: 0.167, y: 0.103 },
                    t: 114,
                    s: [191.097, 263.093, 0],
                    to: [0.135, 0.117, 0],
                    ti: [-0.132, -0.103, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.112 },
                    t: 116,
                    s: [191.499, 263.423, 0],
                    to: [0.132, 0.103, 0],
                    ti: [-0.065, -0.048, 0],
                  },
                  { t: 118, s: [191.888, 263.709, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 0.541, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 14,
                    s: [98.652, 70.754, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.848, 0.518, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, 0.102, 0] },
                    t: 16,
                    s: [52.407, 72.577, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.779, 0.997, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.184, 0.101, 0] },
                    t: 18,
                    s: [83.757, 80.783, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 0.867, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.134, -0.003, 0] },
                    t: 20,
                    s: [109.776, 120.093, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.877, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.028, 0.222, 0] },
                    t: 22,
                    s: [152.905, 82.158, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.679, 0.626, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.257, -0.12, 0] },
                    t: 24,
                    s: [120.66, 59.419, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.91, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, 0.107, 0] },
                    t: 26,
                    s: [105.207, 68.721, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.545, 0.804, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.14, -0.083, 0] },
                    t: 28,
                    s: [61.164, 101.207, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.937, 1.003, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, 0.145, 0] },
                    t: 30,
                    s: [57.69, 84.913, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.643, 0.859, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.256, 0.003, 0] },
                    t: 32,
                    s: [83.888, 62.79, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.821, 0.798, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.109, 0.204, 0] },
                    t: 34,
                    s: [77.451, 85.785, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.087, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.156, 0.142, 0] },
                    t: 36,
                    s: [56.288, 101.636, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 1.927, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.042, 0.454, 0] },
                    t: 38,
                    s: [32.111, 124.298, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.091, 0.877, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.178, 0.076, 0] },
                    t: 40,
                    s: [81.428, 129.386, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.009, 0.967, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.043, 0.259, 0] },
                    t: 42,
                    s: [65.685, 67.723, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.955, 0.799, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.008, -0.054, 0] },
                    t: 44,
                    s: [98.593, 38.482, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.732, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.096, 0.142, 0] },
                    t: 46,
                    s: [62.324, 56.222, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.936, 0.271, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.546, 0] },
                    t: 48,
                    s: [79.166, 81.278, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.276, 0.094, 0] },
                    t: 50,
                    s: [116.554, 85.792, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.592, 0.744, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.377, -0.236, 0] },
                    t: 52,
                    s: [107.89, 120.793, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.083, 0.766, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.124, 0] },
                    t: 54,
                    s: [109.46, 111.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.998, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.042, 0.129, 0] },
                    t: 56,
                    s: [96.752, 92.8, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.958, 0.687, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.002, -0.162, 0] },
                    t: 58,
                    s: [122.109, 58.72, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.192, 0.866, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.084, 0.114, 0] },
                    t: 60,
                    s: [97.302, 70.284, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.964, 1.117, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, 0.219, 0] },
                    t: 62,
                    s: [109.621, 102.162, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.063, 0.049, 0] },
                    t: 64,
                    s: [68.943, 121.676, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.514, 0.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.428, -0.071, 0] },
                    t: 66,
                    s: [92.184, 74.87, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.944, 0.654, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, -0.144, 0] },
                    t: 68,
                    s: [97.803, 100.145, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.734, 0.804, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.175, 0.11, 0] },
                    t: 70,
                    s: [57.518, 90.885, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.006, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.145, 0] },
                    t: 72,
                    s: [70.533, 61.657, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.907, 0.638, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.006, -0.187, 0] },
                    t: 74,
                    s: [99.011, 22.279, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.045, 0.875, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.773, 0.108, 0] },
                    t: 76,
                    s: [68.391, 34.404, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.907, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.029, 0.249, 0] },
                    t: 78,
                    s: [64.692, 74.939, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [8.194, 0.523, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.82, -0.051, 0] },
                    t: 80,
                    s: [70.385, 95.308, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.944, 0.92, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.082, 0.101, 0] },
                    t: 82,
                    s: [71.029, 82.647, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.654, 0.166, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.168, -2.039, 0] },
                    t: 84,
                    s: [14.793, 22.846, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.026, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.11, 0.093, 0] },
                    t: 86,
                    s: [33.445, 25.195, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.908, 1.479, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.02, 0.754, 0] },
                    t: 88,
                    s: [92.281, 46.336, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.981, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.884, 0.071, 0] },
                    t: 90,
                    s: [15.049, 48.962, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.761, 1.318, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.024, -0.118, 0] },
                    t: 92,
                    s: [7.007, 31.245, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.054, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.128, 0.066, 0] },
                    t: 94,
                    s: [13.248, 38.566, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.957, 1.045, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.033, -0.241, 0] },
                    t: 96,
                    s: [24.888, 3.264, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.6, 1.009, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.09, 0.029, 0] },
                    t: 98,
                    s: [5.738, 12.341, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.956, 0.936, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.105, 0.008, 0] },
                    t: 100,
                    s: [14.963, -1.634, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.639, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.091, -0.275, 0] },
                    t: 102,
                    s: [50.015, 13.874, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.185, 1.318, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.46, 0.108, 0] },
                    t: 104,
                    s: [33.27, 10.27, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.869, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.078, 0.066, 0] },
                    t: 106,
                    s: [35.84, -1.726, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.164, 0.842, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.228, -0.049, 0] },
                    t: 108,
                    s: [-3.278, 56.039, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 1.064, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, 0.177, 0] },
                    t: 110,
                    s: [-25.859, 19.601, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.139, 0.036, 0] },
                    t: 112,
                    s: [41.265, -12.971, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.325, 0.731, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.118, -0.247, 0] },
                    t: 114,
                    s: [16.064, 44.564, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.121, 0] },
                    t: 116,
                    s: [26.47, 30.06, 100],
                  },
                  { t: 118, s: [-24.557, -2.189, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 14,
            op: 222,
            st: 14,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 18,
            ty: 4,
            nm: 'Shape Layer 56',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 4,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 58,
                    s: [100],
                  },
                  { t: 90, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 4,
                    s: [278.346, 255.953, 0],
                    to: [-0.206, -0.234, 0],
                    ti: [0.991, 1.248, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 6,
                    s: [277.109, 254.547, 0],
                    to: [-0.991, -1.248, 0],
                    ti: [0.533, 2.822, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.002 },
                    t: 8,
                    s: [272.4, 248.463, 0],
                    to: [-0.533, -2.822, 0],
                    ti: [-2.874, 1.607, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.849 },
                    o: { x: 0.167, y: 0.139 },
                    t: 10,
                    s: [273.909, 237.613, 0],
                    to: [2.874, -1.607, 0],
                    ti: [-4.877, -0.657, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.172 },
                    t: 12,
                    s: [289.644, 238.823, 0],
                    to: [4.877, 0.657, 0],
                    ti: [-4.118, -0.868, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.176 },
                    t: 14,
                    s: [303.171, 241.554, 0],
                    to: [4.118, 0.868, 0],
                    ti: [-3.424, -0.757, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.175 },
                    t: 16,
                    s: [314.351, 244.031, 0],
                    to: [3.424, 0.757, 0],
                    ti: [-2.889, -0.625, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.851 },
                    o: { x: 0.167, y: 0.173 },
                    t: 18,
                    s: [323.717, 246.095, 0],
                    to: [2.889, 0.625, 0],
                    ti: [-2.477, -0.51, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.174 },
                    t: 20,
                    s: [331.687, 247.782, 0],
                    to: [2.477, 0.51, 0],
                    ti: [-2.154, -0.414, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.849 },
                    o: { x: 0.167, y: 0.173 },
                    t: 22,
                    s: [338.58, 249.155, 0],
                    to: [2.154, 0.414, 0],
                    ti: [-1.892, -0.333, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.174 },
                    t: 24,
                    s: [344.611, 250.265, 0],
                    to: [1.892, 0.333, 0],
                    ti: [-1.675, -0.265, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.173 },
                    t: 26,
                    s: [349.933, 251.155, 0],
                    to: [1.675, 0.265, 0],
                    ti: [-1.493, -0.207, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.175 },
                    t: 28,
                    s: [354.663, 251.856, 0],
                    to: [1.493, 0.207, 0],
                    ti: [-1.344, -0.162, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.174 },
                    t: 30,
                    s: [358.894, 252.395, 0],
                    to: [1.344, 0.162, 0],
                    ti: [-1.227, -0.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.172 },
                    t: 32,
                    s: [362.724, 252.827, 0],
                    to: [1.227, 0.138, 0],
                    ti: [-1.133, -0.127, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 34,
                    s: [366.255, 253.222, 0],
                    to: [1.133, 0.127, 0],
                    ti: [-1.051, -0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 36,
                    s: [369.523, 253.587, 0],
                    to: [1.051, 0.117, 0],
                    ti: [-0.978, -0.109, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 38,
                    s: [372.561, 253.926, 0],
                    to: [0.978, 0.109, 0],
                    ti: [-0.912, -0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 40,
                    s: [375.391, 254.24, 0],
                    to: [0.912, 0.101, 0],
                    ti: [-0.852, -0.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 42,
                    s: [378.033, 254.529, 0],
                    to: [0.852, 0.093, 0],
                    ti: [-0.796, -0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.17 },
                    t: 44,
                    s: [380.502, 254.795, 0],
                    to: [0.796, 0.085, 0],
                    ti: [-0.745, -0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.17 },
                    t: 46,
                    s: [382.812, 255.037, 0],
                    to: [0.745, 0.076, 0],
                    ti: [-0.697, -0.068, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.17 },
                    t: 48,
                    s: [384.972, 255.253, 0],
                    to: [0.697, 0.068, 0],
                    ti: [-0.651, -0.059, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 50,
                    s: [386.993, 255.443, 0],
                    to: [0.651, 0.059, 0],
                    ti: [-0.608, -0.05, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.839 },
                    o: { x: 0.167, y: 0.171 },
                    t: 52,
                    s: [388.881, 255.606, 0],
                    to: [0.608, 0.05, 0],
                    ti: [-0.567, -0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.839 },
                    o: { x: 0.167, y: 0.173 },
                    t: 54,
                    s: [390.642, 255.741, 0],
                    to: [0.567, 0.04, 0],
                    ti: [-0.527, -0.03, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.173 },
                    t: 56,
                    s: [392.281, 255.845, 0],
                    to: [0.527, 0.03, 0],
                    ti: [-0.488, -0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.174 },
                    t: 58,
                    s: [393.801, 255.919, 0],
                    to: [0.488, 0.019, 0],
                    ti: [-0.45, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.174 },
                    t: 60,
                    s: [395.207, 255.96, 0],
                    to: [0.45, 0.008, 0],
                    ti: [-0.412, 0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 62,
                    s: [396.499, 255.969, 0],
                    to: [0.412, -0.002, 0],
                    ti: [-0.375, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.175 },
                    t: 64,
                    s: [397.679, 255.945, 0],
                    to: [0.375, -0.014, 0],
                    ti: [-0.338, 0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.174 },
                    t: 66,
                    s: [398.748, 255.888, 0],
                    to: [0.338, -0.025, 0],
                    ti: [-0.301, 0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.173 },
                    t: 68,
                    s: [399.706, 255.797, 0],
                    to: [0.301, -0.036, 0],
                    ti: [-0.264, 0.047, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.17 },
                    t: 70,
                    s: [400.554, 255.673, 0],
                    to: [0.264, -0.047, 0],
                    ti: [-0.227, 0.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.859 },
                    o: { x: 0.167, y: 0.165 },
                    t: 72,
                    s: [401.291, 255.517, 0],
                    to: [0.227, -0.057, 0],
                    ti: [-0.189, 0.067, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.87 },
                    o: { x: 0.167, y: 0.153 },
                    t: 74,
                    s: [401.915, 255.33, 0],
                    to: [0.189, -0.067, 0],
                    ti: [-0.151, 0.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.89 },
                    o: { x: 0.167, y: 0.133 },
                    t: 76,
                    s: [402.427, 255.112, 0],
                    to: [0.151, -0.077, 0],
                    ti: [-0.113, 0.086, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.92 },
                    o: { x: 0.167, y: 0.094 },
                    t: 78,
                    s: [402.824, 254.867, 0],
                    to: [0.113, -0.086, 0],
                    ti: [-0.074, 0.094, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.963 },
                    o: { x: 0.167, y: 0.041 },
                    t: 80,
                    s: [403.104, 254.596, 0],
                    to: [0.074, -0.094, 0],
                    ti: [-0.034, 0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 82,
                    s: [403.266, 254.302, 0],
                    to: [0.034, -0.101, 0],
                    ti: [0.002, 0.108, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [403.307, 253.989, 0],
                    to: [-0.002, -0.108, 0],
                    ti: [0.028, 0.114, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [403.256, 253.656, 0],
                    to: [-0.028, -0.114, 0],
                    ti: [0.05, 0.119, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [403.14, 253.307, 0],
                    to: [-0.05, -0.119, 0],
                    ti: [0.071, 0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [402.953, 252.945, 0],
                    to: [-0.071, -0.117, 0],
                    ti: [0.081, 0.103, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [402.716, 252.603, 0],
                    to: [-0.081, -0.103, 0],
                    ti: [0.084, 0.081, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [402.468, 252.328, 0],
                    to: [-0.084, -0.081, 0],
                    ti: [0.087, 0.059, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [402.212, 252.118, 0],
                    to: [-0.087, -0.059, 0],
                    ti: [0.089, 0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [401.947, 251.973, 0],
                    to: [-0.089, -0.038, 0],
                    ti: [0.091, 0.018, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [401.676, 251.89, 0],
                    to: [-0.091, -0.018, 0],
                    ti: [0.046, 0.004, 0],
                  },
                  { t: 102, s: [401.399, 251.868, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.884, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 4,
                    s: [150.023, 84.302, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.718, 0.778, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.295, -0.001, 0] },
                    t: 6,
                    s: [115.536, 94.772, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.873, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, 0.133, 0] },
                    t: 8,
                    s: [101.947, 84.391, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.035, 1.291, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.243, 0.465, 0] },
                    t: 10,
                    s: [69.48, 67.085, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.989, 0.813, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, 0.065, 0] },
                    t: 12,
                    s: [52.556, 63.303, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.856, 0.84, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.012, 0.15, 0] },
                    t: 14,
                    s: [76.674, 80.31, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.805, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.197, 0.174, 0] },
                    t: 16,
                    s: [55.698, 101.414, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.152, 1.045, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.145, -0.098, 0] },
                    t: 18,
                    s: [40.385, 120.878, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 1.219, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.054, 0.029, 0] },
                    t: 20,
                    s: [19.82, 111.938, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.016, 0.871, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.102, 0.06, 0] },
                    t: 22,
                    s: [78.004, 125.754, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.949, 0.912, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.013, 0.234, 0] },
                    t: 24,
                    s: [51.803, 75.606, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.062, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.135, 1.443, 0] },
                    t: 26,
                    s: [82.948, 47.853, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.885, -0.218, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.035, -0.097, 0] },
                    t: 28,
                    s: [71.038, 46.152, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.784, 0.414, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.302, 0.089, 0] },
                    t: 30,
                    s: [91.761, 46.937, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 0.919, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.136, 0.097, 0] },
                    t: 32,
                    s: [99.655, 57.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.883, 0.788, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.133, -3.004, 0] },
                    t: 34,
                    s: [112.222, 122.076, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [4.25, 0.248, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.289, 0.137, 0] },
                    t: 36,
                    s: [111.361, 120.336, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.223, 0.834, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, 0.094, 0] },
                    t: 38,
                    s: [111.012, 117.65, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 1.003, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, 0.167, 0] },
                    t: 40,
                    s: [124.959, 96.101, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.488, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.648, 0.003, 0] },
                    t: 42,
                    s: [73.624, 74.745, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.933, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, -0.053, 0] },
                    t: 44,
                    s: [79.474, 96.927, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.44, 0.823, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.337, -0.086, 0] },
                    t: 46,
                    s: [39.363, 83.33, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.955, 0.354, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, 0.158, 0] },
                    t: 48,
                    s: [47.32, 90.031, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.968, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.099, 0.096, 0] },
                    t: 50,
                    s: [92.847, 97.529, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.719, 0.771, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.051, -0.044, 0] },
                    t: 52,
                    s: [72.017, 148.186, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.036, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, 0.131, 0] },
                    t: 54,
                    s: [84.935, 114.918, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.917, 1.042, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, -0.658, 0] },
                    t: 56,
                    s: [115.633, 56.769, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-3.33, 1.406, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-14.6, 0.028, 0] },
                    t: 58,
                    s: [71.733, 63.301, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.842, 0.897, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, 0.069, 0] },
                    t: 60,
                    s: [71.982, 53.5, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.296, 1.262, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, 0.435, 0] },
                    t: 62,
                    s: [84.68, 111.004, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.873, 0.865, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.065, 0.063, 0] },
                    t: 64,
                    s: [96.026, 124.642, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.082, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.241, 0.218, 0] },
                    t: 66,
                    s: [44.359, 68.117, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 1.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, 1.94, 0] },
                    t: 68,
                    s: [16.99, 33.186, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.848, 0.777, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.028, 0.077, 0] },
                    t: 70,
                    s: [71.31, 31.618, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.963, 1.037, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.185, 0.133, 0] },
                    t: 72,
                    s: [30.563, 51.009, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.958, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.066, 0.026, 0] },
                    t: 74,
                    s: [-2.843, 83.398, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.188, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.084, 0.448, 0] },
                    t: 76,
                    s: [15.771, 36.624, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, -0.025, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, -0.252, 0] },
                    t: 78,
                    s: [6.523, 25.933, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.308, 1.025, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.823, 0.091, 0] },
                    t: 80,
                    s: [36.606, 28.591, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.857, 0.867, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.019, 0] },
                    t: 82,
                    s: [33.84, 58.624, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.022, 0.96, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.199, 0.224, 0] },
                    t: 84,
                    s: [46.823, 19.638, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.667, 0.763, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.018, -0.078, 0] },
                    t: 86,
                    s: [56.147, -3.469, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.868, 0.839, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.111, 0.129, 0] },
                    t: 88,
                    s: [44.338, 8.441, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 1.087, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.227, 0.172, 0] },
                    t: 90,
                    s: [8.892, 30.372, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.016, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.043, 0] },
                    t: 92,
                    s: [-11.629, 50.878, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.012, 0.823, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.013, -0.049, 0] },
                    t: 94,
                    s: [26.385, 8.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 1.146, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.011, 0.158, 0] },
                    t: 96,
                    s: [-18.969, 35.342, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.128, 0.932, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.244, 0.053, 0] },
                    t: 98,
                    s: [33.051, 65.059, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.051, -0.359, 0] },
                    t: 100,
                    s: [19.792, -16.798, 100],
                  },
                  { t: 102, s: [53.472, -1.378, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.611764705882353, 0.13725490196078433, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 4,
            op: 213,
            st: 4,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 19,
            ty: 4,
            nm: 'Shape Layer 54',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 64,
                    s: [100],
                  },
                  { t: 96, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 10,
                    s: [280.895, 263.305, 0],
                    to: [-0.567, 0.175, 0],
                    ti: [2.713, -0.992, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 12,
                    s: [277.49, 264.357, 0],
                    to: [-2.713, 0.992, 0],
                    ti: [6.007, -2.449, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [264.618, 269.257, 0],
                    to: [-6.007, 2.449, 0],
                    ti: [8.091, -3.591, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [241.447, 279.054, 0],
                    to: [-8.091, 3.591, 0],
                    ti: [7.873, -3.782, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [216.07, 290.801, 0],
                    to: [-7.873, 3.782, 0],
                    ti: [6.648, -3.432, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [194.207, 301.746, 0],
                    to: [-6.648, 3.432, 0],
                    ti: [5.503, -3.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [176.185, 311.391, 0],
                    to: [-5.503, 3.025, 0],
                    ti: [4.615, -2.683, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [161.19, 319.898, 0],
                    to: [-4.615, 2.683, 0],
                    ti: [3.935, -2.407, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [148.493, 327.489, 0],
                    to: [-3.935, 2.407, 0],
                    ti: [3.399, -2.181, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [137.583, 334.338, 0],
                    to: [-3.399, 2.181, 0],
                    ti: [2.966, -1.993, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [128.098, 340.575, 0],
                    to: [-2.966, 1.993, 0],
                    ti: [2.607, -1.832, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [119.785, 346.294, 0],
                    to: [-2.607, 1.832, 0],
                    ti: [2.302, -1.693, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [112.455, 351.568, 0],
                    to: [-2.302, 1.693, 0],
                    ti: [2.037, -1.57, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [105.973, 356.452, 0],
                    to: [-2.037, 1.57, 0],
                    ti: [1.803, -1.461, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [100.233, 360.99, 0],
                    to: [-1.803, 1.461, 0],
                    ti: [1.593, -1.362, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [95.155, 365.216, 0],
                    to: [-1.593, 1.362, 0],
                    ti: [1.401, -1.271, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [90.677, 369.16, 0],
                    to: [-1.401, 1.271, 0],
                    ti: [1.224, -1.188, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [86.752, 372.844, 0],
                    to: [-1.224, 1.188, 0],
                    ti: [1.07, -1.108, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [83.332, 376.285, 0],
                    to: [-1.07, 1.108, 0],
                    ti: [0.937, -1.031, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [80.332, 379.49, 0],
                    to: [-0.937, 1.031, 0],
                    ti: [0.818, -0.958, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [77.708, 382.471, 0],
                    to: [-0.818, 0.958, 0],
                    ti: [0.71, -0.889, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [75.425, 385.239, 0],
                    to: [-0.71, 0.889, 0],
                    ti: [0.612, -0.822, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [73.45, 387.804, 0],
                    to: [-0.612, 0.822, 0],
                    ti: [0.523, -0.758, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [71.754, 390.173, 0],
                    to: [-0.523, 0.758, 0],
                    ti: [0.442, -0.696, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [70.313, 392.354, 0],
                    to: [-0.442, 0.696, 0],
                    ti: [0.369, -0.637, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [69.102, 394.352, 0],
                    to: [-0.369, 0.637, 0],
                    ti: [0.304, -0.579, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [68.096, 396.175, 0],
                    to: [-0.304, 0.579, 0],
                    ti: [0.245, -0.524, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [67.277, 397.829, 0],
                    to: [-0.245, 0.524, 0],
                    ti: [0.193, -0.471, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [66.624, 399.32, 0],
                    to: [-0.193, 0.471, 0],
                    ti: [0.147, -0.42, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [66.118, 400.656, 0],
                    to: [-0.147, 0.42, 0],
                    ti: [0.105, -0.37, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [65.744, 401.84, 0],
                    to: [-0.105, 0.37, 0],
                    ti: [0.069, -0.322, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [65.487, 402.877, 0],
                    to: [-0.069, 0.322, 0],
                    ti: [0.037, -0.276, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [65.332, 403.773, 0],
                    to: [-0.037, 0.276, 0],
                    ti: [0.009, -0.231, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [65.265, 404.532, 0],
                    to: [-0.009, 0.231, 0],
                    ti: [-0.014, -0.188, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.004 },
                    t: 78,
                    s: [65.276, 405.16, 0],
                    to: [0.014, 0.188, 0],
                    ti: [-0.034, -0.146, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.984 },
                    o: { x: 0.167, y: 0.02 },
                    t: 80,
                    s: [65.351, 405.66, 0],
                    to: [0.034, 0.146, 0],
                    ti: [-0.051, -0.106, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.952 },
                    o: { x: 0.167, y: 0.059 },
                    t: 82,
                    s: [65.481, 406.037, 0],
                    to: [0.051, 0.106, 0],
                    ti: [-0.064, -0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.89 },
                    o: { x: 0.167, y: 0.126 },
                    t: 84,
                    s: [65.655, 406.293, 0],
                    to: [0.064, 0.066, 0],
                    ti: [-0.074, -0.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.83 },
                    o: { x: 0.167, y: 0.166 },
                    t: 86,
                    s: [65.864, 406.433, 0],
                    to: [0.074, 0.028, 0],
                    ti: [-0.081, 0.01, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.14 },
                    t: 88,
                    s: [66.098, 406.459, 0],
                    to: [0.081, -0.01, 0],
                    ti: [-0.083, 0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.88 },
                    o: { x: 0.167, y: 0.12 },
                    t: 90,
                    s: [66.349, 406.373, 0],
                    to: [0.083, -0.038, 0],
                    ti: [-0.078, 0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.889 },
                    o: { x: 0.167, y: 0.119 },
                    t: 92,
                    s: [66.596, 406.231, 0],
                    to: [0.078, -0.048, 0],
                    ti: [-0.069, 0.051, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.905 },
                    o: { x: 0.167, y: 0.105 },
                    t: 94,
                    s: [66.819, 406.083, 0],
                    to: [0.069, -0.051, 0],
                    ti: [-0.057, 0.054, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.929 },
                    o: { x: 0.167, y: 0.078 },
                    t: 96,
                    s: [67.01, 405.928, 0],
                    to: [0.057, -0.054, 0],
                    ti: [-0.042, 0.058, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.962 },
                    o: { x: 0.167, y: 0.04 },
                    t: 98,
                    s: [67.16, 405.761, 0],
                    to: [0.042, -0.058, 0],
                    ti: [-0.023, 0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.006 },
                    t: 100,
                    s: [67.259, 405.58, 0],
                    to: [0.023, -0.063, 0],
                    ti: [-0.001, 0.07, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [67.298, 405.38, 0],
                    to: [0.001, -0.07, 0],
                    ti: [0.023, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [67.266, 405.158, 0],
                    to: [-0.023, -0.076, 0],
                    ti: [0.046, 0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [67.158, 404.922, 0],
                    to: [-0.046, -0.073, 0],
                    ti: [0.062, 0.06, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [66.993, 404.72, 0],
                    to: [-0.062, -0.06, 0],
                    ti: [0.076, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [66.783, 404.561, 0],
                    to: [-0.076, -0.046, 0],
                    ti: [0.086, 0.031, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [66.539, 404.446, 0],
                    to: [-0.086, -0.031, 0],
                    ti: [0.093, 0.016, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [66.269, 404.376, 0],
                    to: [-0.093, -0.016, 0],
                    ti: [0.097, 0, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [65.982, 404.352, 0],
                    to: [-0.097, 0, 0],
                    ti: [0.049, -0.004, 0],
                  },
                  { t: 118, s: [65.686, 404.375, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 10,
                    s: [49.45, 148.28, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.038, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.246, 0.447, 0] },
                    t: 12,
                    s: [96.233, 59.296, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.894, 0.934, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.026, 0.012, 0] },
                    t: 14,
                    s: [84.39, 38.927, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.66, 0.535, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.391, -0.32, 0] },
                    t: 16,
                    s: [101.568, 62.764, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.102, 0] },
                    t: 18,
                    s: [106.222, 57.834, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.162, 0.853, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.472, -0.001, 0] },
                    t: 20,
                    s: [64.691, 35.274, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.955, 0.795, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 0.193, 0] },
                    t: 22,
                    s: [70.923, 57.653, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.827, 1.022, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.098, 0.141, 0] },
                    t: 24,
                    s: [127.324, 74.727, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.941, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.161, 0.018, 0] },
                    t: 26,
                    s: [101.458, 99.601, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.386, 1.222, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.314, -0.197, 0] },
                    t: 28,
                    s: [73.693, 68.044, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.064, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.061, 0] },
                    t: 30,
                    s: [79.522, 77.427, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 1.413, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.036, 0.681, 0] },
                    t: 32,
                    s: [46.671, 43.016, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.358, 0.941, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.543, 0.069, 0] },
                    t: 34,
                    s: [104.84, 38.219, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.006, 1.25, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.096, -0.2, 0] },
                    t: 36,
                    s: [97.095, 66.774, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.896, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.006, 0.063, 0] },
                    t: 38,
                    s: [45.158, 58.378, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 1.295, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.142, 0.42, 0] },
                    t: 40,
                    s: [100.992, 91.978, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.782, 0.93, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.105, 0.065, 0] },
                    t: 42,
                    s: [80.364, 100.301, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.813, 0.641, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.135, -0.451, 0] },
                    t: 44,
                    s: [89.494, 62.471, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.187, 0.991, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.15, 0.109, 0] },
                    t: 46,
                    s: [104.25, 68.373, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 0.748, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, -0.01, 0] },
                    t: 48,
                    s: [122.573, 87.899, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.546, 1.004, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.303, 0.124, 0] },
                    t: 50,
                    s: [63.056, 70.386, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.939, 0.876, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.088, 0.004, 0] },
                    t: 52,
                    s: [66.633, 34.869, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.782, 0.805, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.233, 0.254, 0] },
                    t: 54,
                    s: [129.403, 72.011, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.806, 1.026, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.135, 0.145, 0] },
                    t: 56,
                    s: [112.863, 90.111, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 0.817, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.146, 0.02, 0] },
                    t: 58,
                    s: [86.228, 114.396, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.514, 0.153, 0] },
                    t: 60,
                    s: [50.891, 82.427, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.248, 0.388, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, -0.251, 0] },
                    t: 62,
                    s: [55.82, 44.045, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.895, 0.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, 0.096, 0] },
                    t: 64,
                    s: [49.632, 53.61, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.992, 0.911, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.404, -0.058, 0] },
                    t: 66,
                    s: [74.255, 114.253, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.123, 0.931, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.009, 1.243, 0] },
                    t: 68,
                    s: [80.651, 78.395, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 0.169, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, -0.398, 0] },
                    t: 70,
                    s: [74.861, 75.817, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.802, 1.723, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.176, 0.093, 0] },
                    t: 72,
                    s: [19.702, 76.263, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 1.043, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.144, 0.075, 0] },
                    t: 74,
                    s: [37.393, 80.265, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.507, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.243, 0.028, 0] },
                    t: 76,
                    s: [61.705, 41.546, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.85, 0.881, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.1, -0.133, 0] },
                    t: 78,
                    s: [55.488, 100.178, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.039, 0.614, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.187, 0.279, 0] },
                    t: 80,
                    s: [24.961, 77.623, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.03, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.026, 0.106, 0] },
                    t: 82,
                    s: [0.375, 68.025, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.01, 1.132, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.022, -0.04, 0] },
                    t: 84,
                    s: [36.365, 33.155, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.865, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.009, 0.051, 0] },
                    t: 86,
                    s: [-12.521, 56.708, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.219, -0.051, 0] },
                    t: 88,
                    s: [42.47, -4.293, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.953, 1.38, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.941, 0] },
                    t: 90,
                    s: [76.363, 33.422, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.108, 0.068, 0] },
                    t: 92,
                    s: [13.419, 37.087, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 1.072, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.028, -0.014, 0] },
                    t: 94,
                    s: [40.835, 16.71, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.911, 1.007, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.176, 0.039, 0] },
                    t: 96,
                    s: [20.376, 34.131, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [3.32, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.367, 0.007, 0] },
                    t: 98,
                    s: [26.948, 1.687, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.176, 0.438, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.755, 0] },
                    t: 100,
                    s: [27.375, 36.914, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.984, 0.868, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.057, 0.098, 0] },
                    t: 102,
                    s: [15.071, 41.282, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.862, 1.428, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.019, 0.226, 0] },
                    t: 104,
                    s: [53.354, 66.388, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.873, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.21, 0.07, 0] },
                    t: 106,
                    s: [22.228, 81.077, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.145, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.242, -0.12, 0] },
                    t: 108,
                    s: [1.727, -9.051, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.874, 0.919, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.053, -0.044, 0] },
                    t: 110,
                    s: [-9.032, 27.824, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.791, -3.351, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.245, -3.045, 0] },
                    t: 112,
                    s: [20.502, 3.731, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 0.93, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.139, 0.085, 0] },
                    t: 114,
                    s: [35.73, 4.373, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, -0.444, 0] },
                    t: 116,
                    s: [58.616, 37.243, 100],
                  },
                  { t: 118, s: [30.021, 32.047, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.611764705882353, 0.13725490196078433, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 10,
            op: 222,
            st: 10,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 20,
            ty: 4,
            nm: 'Shape Layer 53',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 30,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 40,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 84,
                    s: [100],
                  },
                  { t: 116, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [272.114, 255.071, 0],
                    to: [-0.266, 0.053, 0],
                    ti: [1.216, -0.382, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [270.517, 255.387, 0],
                    to: [-1.216, 0.382, 0],
                    ti: [2.644, -1.017, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [264.819, 257.361, 0],
                    to: [-2.644, 1.017, 0],
                    ti: [3.502, -1.605, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [254.654, 261.49, 0],
                    to: [-3.502, 1.605, 0],
                    ti: [3.128, -2.062, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [243.809, 266.989, 0],
                    to: [-3.128, 2.062, 0],
                    ti: [0.929, -2.4, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.016 },
                    t: 40,
                    s: [235.889, 273.86, 0],
                    to: [-0.929, 2.4, 0],
                    ti: [-1.493, -1.834, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.881 },
                    o: { x: 0.167, y: 0.133 },
                    t: 42,
                    s: [238.235, 281.389, 0],
                    to: [1.493, 1.834, 0],
                    ti: [-2.114, -0.944, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.862 },
                    o: { x: 0.167, y: 0.16 },
                    t: 44,
                    s: [244.846, 284.864, 0],
                    to: [2.114, 0.944, 0],
                    ti: [-1.909, -0.642, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.857 },
                    o: { x: 0.167, y: 0.163 },
                    t: 46,
                    s: [250.917, 287.051, 0],
                    to: [1.909, 0.642, 0],
                    ti: [-1.697, -0.51, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.163 },
                    t: 48,
                    s: [256.301, 288.717, 0],
                    to: [1.697, 0.51, 0],
                    ti: [-1.516, -0.438, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.162 },
                    t: 50,
                    s: [261.1, 290.112, 0],
                    to: [1.516, 0.438, 0],
                    ti: [-1.362, -0.392, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.162 },
                    t: 52,
                    s: [265.397, 291.343, 0],
                    to: [1.362, 0.392, 0],
                    ti: [-1.229, -0.362, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.161 },
                    t: 54,
                    s: [269.269, 292.467, 0],
                    to: [1.229, 0.362, 0],
                    ti: [-1.114, -0.341, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.159 },
                    t: 56,
                    s: [272.774, 293.517, 0],
                    to: [1.114, 0.341, 0],
                    ti: [-1.012, -0.326, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.158 },
                    t: 58,
                    s: [275.955, 294.515, 0],
                    to: [1.012, 0.326, 0],
                    ti: [-0.921, -0.314, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.858 },
                    o: { x: 0.167, y: 0.155 },
                    t: 60,
                    s: [278.847, 295.472, 0],
                    to: [0.921, 0.314, 0],
                    ti: [-0.839, -0.305, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.86 },
                    o: { x: 0.167, y: 0.153 },
                    t: 62,
                    s: [281.482, 296.4, 0],
                    to: [0.839, 0.305, 0],
                    ti: [-0.762, -0.298, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.863 },
                    o: { x: 0.167, y: 0.149 },
                    t: 64,
                    s: [283.879, 297.304, 0],
                    to: [0.762, 0.298, 0],
                    ti: [-0.69, -0.293, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.867 },
                    o: { x: 0.167, y: 0.145 },
                    t: 66,
                    s: [286.053, 298.19, 0],
                    to: [0.69, 0.293, 0],
                    ti: [-0.621, -0.288, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.871 },
                    o: { x: 0.167, y: 0.141 },
                    t: 68,
                    s: [288.016, 299.06, 0],
                    to: [0.621, 0.288, 0],
                    ti: [-0.554, -0.284, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.878 },
                    o: { x: 0.167, y: 0.135 },
                    t: 70,
                    s: [289.778, 299.918, 0],
                    to: [0.554, 0.284, 0],
                    ti: [-0.486, -0.279, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.886 },
                    o: { x: 0.167, y: 0.128 },
                    t: 72,
                    s: [291.338, 300.763, 0],
                    to: [0.486, 0.279, 0],
                    ti: [-0.419, -0.273, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.895 },
                    o: { x: 0.167, y: 0.119 },
                    t: 74,
                    s: [292.695, 301.592, 0],
                    to: [0.419, 0.273, 0],
                    ti: [-0.351, -0.266, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.908 },
                    o: { x: 0.167, y: 0.106 },
                    t: 76,
                    s: [293.849, 302.402, 0],
                    to: [0.351, 0.266, 0],
                    ti: [-0.283, -0.258, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.925 },
                    o: { x: 0.167, y: 0.088 },
                    t: 78,
                    s: [294.801, 303.189, 0],
                    to: [0.283, 0.258, 0],
                    ti: [-0.215, -0.245, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.945 },
                    o: { x: 0.167, y: 0.067 },
                    t: 80,
                    s: [295.549, 303.949, 0],
                    to: [0.215, 0.245, 0],
                    ti: [-0.147, -0.225, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.967 },
                    o: { x: 0.167, y: 0.041 },
                    t: 82,
                    s: [296.09, 304.662, 0],
                    to: [0.147, 0.225, 0],
                    ti: [-0.082, -0.198, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.014 },
                    t: 84,
                    s: [296.429, 305.301, 0],
                    to: [0.082, 0.198, 0],
                    ti: [-0.024, -0.164, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [296.583, 305.847, 0],
                    to: [0.024, 0.164, 0],
                    ti: [0.026, -0.131, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [296.574, 306.285, 0],
                    to: [-0.026, 0.131, 0],
                    ti: [0.067, -0.108, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [296.425, 306.636, 0],
                    to: [-0.067, 0.108, 0],
                    ti: [0.097, -0.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [296.17, 306.934, 0],
                    to: [-0.097, 0.091, 0],
                    ti: [0.117, -0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [295.842, 307.183, 0],
                    to: [-0.117, 0.076, 0],
                    ti: [0.129, -0.065, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [295.468, 307.393, 0],
                    to: [-0.129, 0.065, 0],
                    ti: [0.134, -0.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [295.071, 307.574, 0],
                    to: [-0.134, 0.057, 0],
                    ti: [0.133, -0.051, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [294.667, 307.733, 0],
                    to: [-0.133, 0.051, 0],
                    ti: [0.128, -0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [294.272, 307.878, 0],
                    to: [-0.128, 0.046, 0],
                    ti: [0.12, -0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [293.898, 308.012, 0],
                    to: [-0.12, 0.044, 0],
                    ti: [0.108, -0.042, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [293.554, 308.14, 0],
                    to: [-0.108, 0.042, 0],
                    ti: [0.094, -0.041, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [293.249, 308.264, 0],
                    to: [-0.094, 0.041, 0],
                    ti: [0.08, -0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [292.988, 308.385, 0],
                    to: [-0.08, 0.044, 0],
                    ti: [0.068, -0.055, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [292.767, 308.528, 0],
                    to: [-0.068, 0.055, 0],
                    ti: [0.056, -0.069, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [292.579, 308.714, 0],
                    to: [-0.056, 0.069, 0],
                    ti: [0.044, -0.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [292.428, 308.941, 0],
                    to: [-0.044, 0.082, 0],
                    ti: [0.03, -0.094, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 118,
                    s: [292.318, 309.205, 0],
                    to: [-0.03, 0.094, 0],
                    ti: [0.016, -0.105, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 120,
                    s: [292.249, 309.504, 0],
                    to: [-0.016, 0.105, 0],
                    ti: [0.001, -0.114, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 122,
                    s: [292.223, 309.833, 0],
                    to: [-0.001, 0.114, 0],
                    ti: [-0.013, -0.122, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.995 },
                    o: { x: 0.167, y: 0.004 },
                    t: 124,
                    s: [292.242, 310.189, 0],
                    to: [0.013, 0.122, 0],
                    ti: [-0.028, -0.128, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.011 },
                    t: 126,
                    s: [292.304, 310.564, 0],
                    to: [0.028, 0.128, 0],
                    ti: [-0.042, -0.131, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.98 },
                    o: { x: 0.167, y: 0.019 },
                    t: 128,
                    s: [292.408, 310.955, 0],
                    to: [0.042, 0.131, 0],
                    ti: [-0.055, -0.133, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.971 },
                    o: { x: 0.167, y: 0.029 },
                    t: 130,
                    s: [292.554, 311.353, 0],
                    to: [0.055, 0.133, 0],
                    ti: [-0.031, -0.067, 0],
                  },
                  { t: 132, s: [292.738, 311.753, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 30,
                    s: [127.438, 90.591, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.119, 0.935, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.22, -0.014, 0] },
                    t: 32,
                    s: [90.096, 28.016, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 1.126, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.049, -0.3, 0] },
                    t: 34,
                    s: [67.312, 81.384, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.746, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.141, 0.05, 0] },
                    t: 36,
                    s: [122.538, 69.77, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.94, 1.165, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.124, -0.191, 0] },
                    t: 38,
                    s: [102.02, 98.906, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.968, 1.084, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.21, 0.055, 0] },
                    t: 40,
                    s: [60.035, 90.043, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.429, 0.887, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.051, 0.042, 0] },
                    t: 42,
                    s: [71.968, 116.451, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.886, 0.984, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, 0.318, 0] },
                    t: 44,
                    s: [64.586, 63.443, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.12, 0.842, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.307, -0.02, 0] },
                    t: 46,
                    s: [109.991, 44.631, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.887, 0.933, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.049, 0.176, 0] },
                    t: 48,
                    s: [126.937, 59.824, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.971, 1.735, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.316, -0.347, 0] },
                    t: 50,
                    s: [85.604, 73.442, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.831, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.045, 0.075, 0] },
                    t: 52,
                    s: [70.771, 70.802, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.913, 0.639, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.164, -0.162, 0] },
                    t: 54,
                    s: [80.389, 96.73, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.179, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.79, 0.108, 0] },
                    t: 56,
                    s: [90.289, 87.923, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.589, 1.199, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 0.673, 0] },
                    t: 58,
                    s: [90.772, 58.569, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.878, 1.108, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.059, 0] },
                    t: 60,
                    s: [95.049, 54.421, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.33, 0.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.262, 0.047, 0] },
                    t: 62,
                    s: [60.54, 68.48, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.901, 0.927, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, -0.148, 0] },
                    t: 64,
                    s: [44.437, 36.222, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 4.165, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.525, -0.607, 0] },
                    t: 66,
                    s: [124.279, 47.844, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-1.352, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.486, 0.081, 0] },
                    t: 68,
                    s: [139.353, 46.441, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.883, 2.129, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.086, 1.924, 0] },
                    t: 70,
                    s: [137.147, 101.148, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.914, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.29, 0.078, 0] },
                    t: 72,
                    s: [77.097, 103.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.16, 2.38, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.222, 2.472, 0] },
                    t: 74,
                    s: [52.92, 67.602, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.876, 0.839, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, 0.079, 0] },
                    t: 76,
                    s: [38.391, 66.346, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.058, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.255, 0.173, 0] },
                    t: 78,
                    s: [80.849, 88.417, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 0.752, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.034, -0.04, 0] },
                    t: 80,
                    s: [101.514, 109.026, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.242, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.446, 0.126, 0] },
                    t: 82,
                    s: [66.389, 95.048, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.892, 1.025, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, 0.034, 0] },
                    t: 84,
                    s: [58.31, 67.448, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.044, 0.974, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.364, 0.019, 0] },
                    t: 86,
                    s: [89.867, 114.12, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.587, 0.887, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.029, -0.038, 0] },
                    t: 88,
                    s: [99.232, 53.341, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 1.104, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.104, 0.319, 0] },
                    t: 90,
                    s: [84.926, 94.953, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.439, 0.867, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.495, 0.046, 0] },
                    t: 92,
                    s: [28.265, 109.679, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.035, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, 0.223, 0] },
                    t: 94,
                    s: [24.92, 76.564, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 1.618, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, -0.113, 0] },
                    t: 96,
                    s: [45.873, 56.751, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.603, 0.985, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.138, 0.073, 0] },
                    t: 98,
                    s: [16.102, 65.143, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.911, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.105, -0.019, 0] },
                    t: 100,
                    s: [27.313, -5.503, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.923, 1.561, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 1.347, 0] },
                    t: 102,
                    s: [69.528, 52.086, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.395, 0.972, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.056, 0.073, 0] },
                    t: 104,
                    s: [13.347, 55.885, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 0.96, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, -0.041, 0] },
                    t: 106,
                    s: [17.457, 26.517, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.906, 0.989, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, -0.077, 0] },
                    t: 108,
                    s: [-6.114, 46.16, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.079, 1.13, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.74, -0.013, 0] },
                    t: 110,
                    s: [14.941, 35.961, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.225, 0.927, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, 0.051, 0] },
                    t: 112,
                    s: [17.614, 44.753, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.151, -0.369, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, -0.61, 0] },
                    t: 114,
                    s: [12.402, 22.217, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.054, 0.089, 0] },
                    t: 116,
                    s: [31.658, 24.926, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.879, 0.864, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.115, -0.014, 0] },
                    t: 118,
                    s: [-22.38, 66.727, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.835, 0.983, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.269, 0.215, 0] },
                    t: 120,
                    s: [0.27, 30.796, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.829, 1.068, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, -0.021, 0] },
                    t: 122,
                    s: [10.423, 7.981, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.817, 0.983, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.162, 0.038, 0] },
                    t: 124,
                    s: [20.367, 26.148, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.012, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.153, -0.021, 0] },
                    t: 126,
                    s: [30.864, -6.905, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.986, 1.056, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.011, 0.499, 0] },
                    t: 128,
                    s: [43.453, 19.525, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.017, 0.034, 0] },
                    t: 130,
                    s: [29.038, 24.824, 100],
                  },
                  { t: 132, s: [41.021, 15.933, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 30,
            op: 236,
            st: 30,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 21,
            ty: 4,
            nm: 'Shape Layer 52',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 44,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 54,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 98,
                    s: [100],
                  },
                  { t: 130, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [279.864, 255.271, 0],
                    to: [-0.343, 0.177, 0],
                    ti: [1.691, -0.679, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [277.804, 256.336, 0],
                    to: [-1.691, 0.679, 0],
                    ti: [3.887, -1.09, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [269.719, 259.347, 0],
                    to: [-3.887, 1.09, 0],
                    ti: [3.906, 1.153, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [254.482, 262.875, 0],
                    to: [-3.906, -1.153, 0],
                    ti: [0.302, 3.979, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.973 },
                    o: { x: 0.167, y: 0.029 },
                    t: 52,
                    s: [246.284, 252.432, 0],
                    to: [-0.302, -3.979, 0],
                    ti: [-2.124, 4.016, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.96 },
                    o: { x: 0.167, y: 0.048 },
                    t: 54,
                    s: [252.67, 238.999, 0],
                    to: [2.124, -4.016, 0],
                    ti: [-1.967, 3.264, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.958 },
                    o: { x: 0.167, y: 0.049 },
                    t: 56,
                    s: [259.026, 228.335, 0],
                    to: [1.967, -3.264, 0],
                    ti: [-1.669, 2.774, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.96 },
                    o: { x: 0.167, y: 0.047 },
                    t: 58,
                    s: [264.471, 219.412, 0],
                    to: [1.669, -2.774, 0],
                    ti: [-1.398, 2.426, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.962 },
                    o: { x: 0.167, y: 0.042 },
                    t: 60,
                    s: [269.041, 211.694, 0],
                    to: [1.398, -2.426, 0],
                    ti: [-1.168, 2.169, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.967 },
                    o: { x: 0.167, y: 0.037 },
                    t: 62,
                    s: [272.862, 204.857, 0],
                    to: [1.168, -2.169, 0],
                    ti: [-0.974, 1.973, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.972 },
                    o: { x: 0.167, y: 0.032 },
                    t: 64,
                    s: [276.051, 198.681, 0],
                    to: [0.974, -1.973, 0],
                    ti: [-0.806, 1.82, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.977 },
                    o: { x: 0.167, y: 0.026 },
                    t: 66,
                    s: [278.705, 193.017, 0],
                    to: [0.806, -1.82, 0],
                    ti: [-0.644, 1.685, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.983 },
                    o: { x: 0.167, y: 0.019 },
                    t: 68,
                    s: [280.888, 187.764, 0],
                    to: [0.644, -1.685, 0],
                    ti: [-0.482, 1.558, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.012 },
                    t: 70,
                    s: [282.57, 182.907, 0],
                    to: [0.482, -1.558, 0],
                    ti: [-0.333, 1.443, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.006 },
                    t: 72,
                    s: [283.781, 178.414, 0],
                    to: [0.333, -1.443, 0],
                    ti: [-0.197, 1.336, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 74,
                    s: [284.566, 174.251, 0],
                    to: [0.197, -1.336, 0],
                    ti: [-0.074, 1.237, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [284.965, 170.396, 0],
                    to: [0.074, -1.237, 0],
                    ti: [0.038, 1.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [285.012, 166.831, 0],
                    to: [-0.038, -1.14, 0],
                    ti: [0.139, 1.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [284.739, 163.555, 0],
                    to: [-0.139, -1.044, 0],
                    ti: [0.228, 0.947, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [284.178, 160.569, 0],
                    to: [-0.228, -0.947, 0],
                    ti: [0.304, 0.848, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [283.368, 157.874, 0],
                    to: [-0.304, -0.848, 0],
                    ti: [0.363, 0.748, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [282.355, 155.48, 0],
                    to: [-0.363, -0.748, 0],
                    ti: [0.405, 0.649, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [281.189, 153.386, 0],
                    to: [-0.405, -0.649, 0],
                    ti: [0.428, 0.554, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [279.927, 151.584, 0],
                    to: [-0.428, -0.554, 0],
                    ti: [0.433, 0.464, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [278.623, 150.062, 0],
                    to: [-0.433, -0.464, 0],
                    ti: [0.424, 0.383, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [277.326, 148.798, 0],
                    to: [-0.424, -0.383, 0],
                    ti: [0.403, 0.31, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [276.078, 147.765, 0],
                    to: [-0.403, -0.31, 0],
                    ti: [0.372, 0.245, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [274.911, 146.939, 0],
                    to: [-0.372, -0.245, 0],
                    ti: [0.335, 0.19, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [273.847, 146.293, 0],
                    to: [-0.335, -0.19, 0],
                    ti: [0.294, 0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [272.9, 145.802, 0],
                    to: [-0.294, -0.141, 0],
                    ti: [0.25, 0.1, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [272.082, 145.445, 0],
                    to: [-0.25, -0.1, 0],
                    ti: [0.205, 0.064, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [271.398, 145.204, 0],
                    to: [-0.205, -0.064, 0],
                    ti: [0.16, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [270.85, 145.06, 0],
                    to: [-0.16, -0.034, 0],
                    ti: [0.115, 0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [270.438, 144.999, 0],
                    to: [-0.115, -0.009, 0],
                    ti: [0.071, -0.011, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [270.159, 145.005, 0],
                    to: [-0.071, 0.011, 0],
                    ti: [0.029, -0.027, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [270.01, 145.067, 0],
                    to: [-0.029, 0.027, 0],
                    ti: [-0.01, -0.039, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.936 },
                    o: { x: 0.167, y: 0.043 },
                    t: 116,
                    s: [269.983, 145.17, 0],
                    to: [0.01, 0.039, 0],
                    ti: [-0.046, -0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.877 },
                    o: { x: 0.167, y: 0.086 },
                    t: 118,
                    s: [270.07, 145.3, 0],
                    to: [0.046, 0.046, 0],
                    ti: [-0.08, -0.05, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.113 },
                    t: 120,
                    s: [270.262, 145.447, 0],
                    to: [0.08, 0.05, 0],
                    ti: [-0.111, -0.049, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.131 },
                    t: 122,
                    s: [270.551, 145.599, 0],
                    to: [0.111, 0.049, 0],
                    ti: [-0.133, -0.043, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.151 },
                    t: 124,
                    s: [270.927, 145.744, 0],
                    to: [0.133, 0.043, 0],
                    ti: [-0.141, -0.026, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.835 },
                    o: { x: 0.167, y: 0.166 },
                    t: 126,
                    s: [271.347, 145.854, 0],
                    to: [0.141, 0.026, 0],
                    ti: [-0.142, -0.004, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.164 },
                    t: 128,
                    s: [271.77, 145.903, 0],
                    to: [0.142, 0.004, 0],
                    ti: [-0.143, 0.021, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.157 },
                    t: 130,
                    s: [272.2, 145.876, 0],
                    to: [0.143, -0.021, 0],
                    ti: [-0.142, 0.042, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.144 },
                    t: 132,
                    s: [272.629, 145.78, 0],
                    to: [0.142, -0.042, 0],
                    ti: [-0.137, 0.061, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.869 },
                    o: { x: 0.167, y: 0.13 },
                    t: 134,
                    s: [273.049, 145.622, 0],
                    to: [0.137, -0.061, 0],
                    ti: [-0.13, 0.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.887 },
                    o: { x: 0.167, y: 0.113 },
                    t: 136,
                    s: [273.452, 145.412, 0],
                    to: [0.13, -0.077, 0],
                    ti: [-0.12, 0.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.904 },
                    o: { x: 0.167, y: 0.098 },
                    t: 138,
                    s: [273.829, 145.157, 0],
                    to: [0.12, -0.091, 0],
                    ti: [-0.109, 0.102, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.92 },
                    o: { x: 0.167, y: 0.08 },
                    t: 140,
                    s: [274.175, 144.866, 0],
                    to: [0.109, -0.102, 0],
                    ti: [-0.051, 0.054, 0],
                  },
                  { t: 142, s: [274.483, 144.545, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.838, 1.875, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 44,
                    s: [109.864, 70.089, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.856, 0.69, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.172, 0.076, 0] },
                    t: 46,
                    s: [86.733, 68.252, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.993, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.199, 0.114, 0] },
                    t: 48,
                    s: [64.924, 89.385, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.797, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, -0.008, 0] },
                    t: 50,
                    s: [49.171, 146.99, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.755, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.141, 0.509, 0] },
                    t: 52,
                    s: [70.147, 94.506, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.944, 1.08, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.941, 0.126, 0] },
                    t: 54,
                    s: [100.345, 84.223, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.003, 0.981, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.041, 0] },
                    t: 56,
                    s: [99.102, 64.322, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.843, 0.917, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.002, -0.024, 0] },
                    t: 58,
                    s: [129.334, 103.257, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.977, -0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, -21.256, 0] },
                    t: 60,
                    s: [98.181, 73.031, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.969, 0.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.032, 0.087, 0] },
                    t: 62,
                    s: [70.521, 73.149, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.819, 0.396, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.048, -0.057, 0] },
                    t: 64,
                    s: [90.481, 75.792, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.879, 0.99, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.155, 0.097, 0] },
                    t: 66,
                    s: [77.84, 74.224, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.857, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.269, -0.012, 0] },
                    t: 68,
                    s: [63.067, 64.426, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.698, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.2, 0] },
                    t: 70,
                    s: [56.422, 72.999, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.807, 0.455, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.115, -0.052, 0] },
                    t: 72,
                    s: [68.741, 79.126, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.911, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.147, 0.098, 0] },
                    t: 74,
                    s: [101.082, 75.355, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.698, 0.664, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.265, -0.1, 0] },
                    t: 76,
                    s: [143.609, 54.475, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.73, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.111, 0] },
                    t: 78,
                    s: [146.608, 63.972, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.888, 1.027, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.012, 0] },
                    t: 80,
                    s: [118.472, 92.79, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.05, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.324, 0.02, 0] },
                    t: 82,
                    s: [55.394, 59.194, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.94, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.031, -0.051, 0] },
                    t: 84,
                    s: [33.53, 103.661, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.632, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.22, -0.21, 0] },
                    t: 86,
                    s: [68.551, 76.112, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.9, 1.084, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 0.108, 0] },
                    t: 88,
                    s: [89.966, 83.951, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.007, 1.005, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.497, 0.042, 0] },
                    t: 90,
                    s: [61.396, 110.752, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.966, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.077, 0.004, 0] },
                    t: 92,
                    s: [55.64, 56.936, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.958, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.058, -0.045, 0] },
                    t: 94,
                    s: [130.926, 113.733, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.106, 0.944, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.086, -0.085, 0] },
                    t: 96,
                    s: [86.439, 76.969, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.598, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, -0.169, 0] },
                    t: 98,
                    s: [108.294, 95.156, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.04, 0.836, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.221, 0.105, 0] },
                    t: 100,
                    s: [58.545, 89.141, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.959, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.027, 0.17, 0] },
                    t: 102,
                    s: [28.376, 66.139, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.89, 1.228, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.148, -0.079, 0] },
                    t: 104,
                    s: [72.983, 43.989, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.128, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.339, 0.061, 0] },
                    t: 106,
                    s: [56.935, 55.362, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.199, 0.907, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.05, 0.017, 0] },
                    t: 108,
                    s: [51.706, 12.909, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 1.369, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.059, 0.774, 0] },
                    t: 110,
                    s: [64.946, 65.932, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.252, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.238, 0.068, 0] },
                    t: 112,
                    s: [20.13, 72.328, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.97, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.063, 0.001, 0] },
                    t: 114,
                    s: [31.738, 37.588, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.893, 1.019, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.046, -0.129, 0] },
                    t: 116,
                    s: [-14.966, 72.869, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.07, 1.207, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.373, 0.016, 0] },
                    t: 118,
                    s: [15.041, 59.042, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.075, 0.894, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.059, 0] },
                    t: 120,
                    s: [23.68, 76.032, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.039, 0.396, 0] },
                    t: 122,
                    s: [7.79, 16.858, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.941, 0.564, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, -0.041, 0] },
                    t: 124,
                    s: [37.924, 1.088, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.813, 0.957, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.205, 0.103, 0] },
                    t: 126,
                    s: [-17.733, 11.662, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.814, 1.008, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.15, -0.09, 0] },
                    t: 128,
                    s: [-1.626, 56.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 1.062, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.151, 0.008, 0] },
                    t: 130,
                    s: [18.416, 34.888, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.817, 0.918, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.441, 0.035, 0] },
                    t: 132,
                    s: [43.027, 58.529, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.877, 3.279, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.153, -7.35, 0] },
                    t: 134,
                    s: [48.754, 17.354, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.206, 0.776, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.259, 0.08, 0] },
                    t: 136,
                    s: [55.61, 17.816, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.986, 1.061, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.059, 0.133, 0] },
                    t: 138,
                    s: [58.864, 4.732, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.017, 0.035, 0] },
                    t: 140,
                    s: [47.582, -17.422, 100],
                  },
                  { t: 142, s: [56.939, 20.914, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.6431372549019608, 0.13725490196078433, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 44,
            op: 246,
            st: 44,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 22,
            ty: 4,
            nm: 'Shape Layer 51',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 68,
                    s: [100],
                  },
                  { t: 100, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [272.377, 258.087, 0],
                    to: [-0.394, 0.137, 0],
                    ti: [2.139, -0.681, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [270.01, 258.906, 0],
                    to: [-2.139, 0.681, 0],
                    ti: [5.098, -1.104, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [259.541, 262.172, 0],
                    to: [-5.098, 1.104, 0],
                    ti: [7.13, -0.303, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [239.424, 265.531, 0],
                    to: [-7.13, 0.303, 0],
                    ti: [6.918, 1.295, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [216.76, 263.988, 0],
                    to: [-6.918, -1.295, 0],
                    ti: [5.553, 2.383, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [197.916, 257.762, 0],
                    to: [-5.553, -2.383, 0],
                    ti: [4.294, 2.71, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [183.44, 249.693, 0],
                    to: [-4.294, -2.71, 0],
                    ti: [3.412, 2.643, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [172.149, 241.499, 0],
                    to: [-3.412, -2.643, 0],
                    ti: [2.824, 2.427, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [162.967, 233.838, 0],
                    to: [-2.824, -2.427, 0],
                    ti: [2.43, 2.167, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [155.204, 226.937, 0],
                    to: [-2.43, -2.167, 0],
                    ti: [2.166, 1.903, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [148.386, 220.833, 0],
                    to: [-2.166, -1.903, 0],
                    ti: [1.988, 1.643, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [142.207, 215.519, 0],
                    to: [-1.988, -1.643, 0],
                    ti: [1.866, 1.384, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [136.458, 210.978, 0],
                    to: [-1.866, -1.384, 0],
                    ti: [1.768, 1.113, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [131.01, 207.214, 0],
                    to: [-1.768, -1.113, 0],
                    ti: [1.677, 0.828, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [125.848, 204.301, 0],
                    to: [-1.677, -0.828, 0],
                    ti: [1.589, 0.546, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [120.949, 202.245, 0],
                    to: [-1.589, -0.546, 0],
                    ti: [1.493, 0.277, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [116.315, 201.024, 0],
                    to: [-1.493, -0.277, 0],
                    ti: [1.38, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [111.993, 200.584, 0],
                    to: [-1.38, -0.034, 0],
                    ti: [1.254, -0.168, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [108.033, 200.82, 0],
                    to: [-1.254, 0.168, 0],
                    ti: [1.122, -0.322, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [104.469, 201.591, 0],
                    to: [-1.122, 0.322, 0],
                    ti: [0.991, -0.429, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [101.304, 202.75, 0],
                    to: [-0.991, 0.429, 0],
                    ti: [0.869, -0.496, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [98.52, 204.163, 0],
                    to: [-0.869, 0.496, 0],
                    ti: [0.759, -0.533, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [96.088, 205.725, 0],
                    to: [-0.759, 0.533, 0],
                    ti: [0.662, -0.548, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [93.965, 207.359, 0],
                    to: [-0.662, 0.548, 0],
                    ti: [0.577, -0.547, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [92.114, 209.011, 0],
                    to: [-0.577, 0.547, 0],
                    ti: [0.502, -0.535, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [90.501, 210.642, 0],
                    to: [-0.502, 0.535, 0],
                    ti: [0.436, -0.515, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [89.1, 212.221, 0],
                    to: [-0.436, 0.515, 0],
                    ti: [0.376, -0.488, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [87.887, 213.729, 0],
                    to: [-0.376, 0.488, 0],
                    ti: [0.324, -0.458, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [86.842, 215.15, 0],
                    to: [-0.324, 0.458, 0],
                    ti: [0.277, -0.424, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [85.945, 216.475, 0],
                    to: [-0.277, 0.424, 0],
                    ti: [0.234, -0.388, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [85.182, 217.694, 0],
                    to: [-0.234, 0.388, 0],
                    ti: [0.195, -0.349, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [84.541, 218.8, 0],
                    to: [-0.195, 0.349, 0],
                    ti: [0.16, -0.31, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [84.009, 219.79, 0],
                    to: [-0.16, 0.31, 0],
                    ti: [0.129, -0.269, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [83.578, 220.658, 0],
                    to: [-0.129, 0.269, 0],
                    ti: [0.099, -0.226, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [83.238, 221.401, 0],
                    to: [-0.099, 0.226, 0],
                    ti: [0.072, -0.183, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [82.984, 222.016, 0],
                    to: [-0.072, 0.183, 0],
                    ti: [0.048, -0.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [82.806, 222.502, 0],
                    to: [-0.048, 0.14, 0],
                    ti: [0.026, -0.097, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [82.697, 222.858, 0],
                    to: [-0.026, 0.097, 0],
                    ti: [0.005, -0.052, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.007 },
                    t: 90,
                    s: [82.651, 223.082, 0],
                    to: [-0.005, 0.052, 0],
                    ti: [-0.014, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.111 },
                    t: 92,
                    s: [82.666, 223.173, 0],
                    to: [0.014, 0.008, 0],
                    ti: [-0.027, 0.032, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.948 },
                    o: { x: 0.167, y: 0.033 },
                    t: 94,
                    s: [82.736, 223.128, 0],
                    to: [0.027, -0.032, 0],
                    ti: [-0.027, 0.062, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.981 },
                    o: { x: 0.167, y: 0.015 },
                    t: 96,
                    s: [82.826, 222.979, 0],
                    to: [0.027, -0.062, 0],
                    ti: [-0.023, 0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.992 },
                    o: { x: 0.167, y: 0.007 },
                    t: 98,
                    s: [82.9, 222.757, 0],
                    to: [0.023, -0.085, 0],
                    ti: [-0.025, 0.099, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.012 },
                    t: 100,
                    s: [82.964, 222.469, 0],
                    to: [0.025, -0.099, 0],
                    ti: [-0.032, 0.102, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.983 },
                    o: { x: 0.167, y: 0.017 },
                    t: 102,
                    s: [83.05, 222.164, 0],
                    to: [0.032, -0.102, 0],
                    ti: [-0.036, 0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.021 },
                    t: 104,
                    s: [83.155, 221.857, 0],
                    to: [0.036, -0.101, 0],
                    ti: [-0.038, 0.098, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.978 },
                    o: { x: 0.167, y: 0.024 },
                    t: 106,
                    s: [83.269, 221.556, 0],
                    to: [0.038, -0.098, 0],
                    ti: [-0.038, 0.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.976 },
                    o: { x: 0.167, y: 0.025 },
                    t: 108,
                    s: [83.385, 221.268, 0],
                    to: [0.038, -0.093, 0],
                    ti: [-0.035, 0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.977 },
                    o: { x: 0.167, y: 0.026 },
                    t: 110,
                    s: [83.497, 221, 0],
                    to: [0.035, -0.085, 0],
                    ti: [-0.031, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.024 },
                    t: 112,
                    s: [83.598, 220.758, 0],
                    to: [0.031, -0.076, 0],
                    ti: [-0.024, 0.065, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.984 },
                    o: { x: 0.167, y: 0.02 },
                    t: 114,
                    s: [83.682, 220.545, 0],
                    to: [0.024, -0.065, 0],
                    ti: [-0.017, 0.053, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.012 },
                    t: 116,
                    s: [83.745, 220.367, 0],
                    to: [0.017, -0.053, 0],
                    ti: [-0.006, 0.023, 0],
                  },
                  { t: 118, s: [83.781, 220.227, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.852, 3.785, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 14,
                    s: [121.49, 88.737, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.91, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.19, 0.081, 0] },
                    t: 16,
                    s: [91.076, 88.159, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.429, -3.486, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.162, -1.415, 0] },
                    t: 18,
                    s: [67.412, 108.062, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.033, 0.953, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, 0.085, 0] },
                    t: 20,
                    s: [65.583, 106.955, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.841, 0.807, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.024, -0.106, 0] },
                    t: 22,
                    s: [54.874, 48.475, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.733, 0.874, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.176, 0.146, 0] },
                    t: 24,
                    s: [69.879, 74.186, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.032, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.246, 0] },
                    t: 26,
                    s: [83.434, 108.14, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.978, 0.868, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.023, 0.001, 0] },
                    t: 28,
                    s: [113.236, 125.507, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.937, 0.727, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.03, 0.227, 0] },
                    t: 30,
                    s: [72.085, 108.002, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.63, 0.848, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.261, 0.12, 0] },
                    t: 32,
                    s: [102.383, 97.845, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 0.819, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.108, 0.184, 0] },
                    t: 34,
                    s: [95.054, 74.753, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.758, 1.104, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.116, 0.155, 0] },
                    t: 36,
                    s: [69.833, 55.593, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.253, 0.933, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.127, 0.046, 0] },
                    t: 38,
                    s: [71.585, 33.206, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.962, 0.845, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.089, -0.336, 0] },
                    t: 40,
                    s: [74.924, 83.413, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.895, 1.179, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.069, 0.18, 0] },
                    t: 42,
                    s: [121.778, 73.433, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.903, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.41, 0.057, 0] },
                    t: 44,
                    s: [96.063, 64.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-2.344, 1.503, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.493, 0.61, 0] },
                    t: 46,
                    s: [89.506, 91.864, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1.002, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, 0.071, 0] },
                    t: 48,
                    s: [90.453, 96.143, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.913, 0.848, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0.002, 0] },
                    t: 50,
                    s: [127.532, 66.021, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.203, 1.019, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.831, 0.185, 0] },
                    t: 52,
                    s: [90.276, 96.965, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.094, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.059, 0.015, 0] },
                    t: 54,
                    s: [88.5, 122.317, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.722, 1.067, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, -0.049, 0] },
                    t: 56,
                    s: [94.599, 91.269, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.905, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.119, 0.037, 0] },
                    t: 58,
                    s: [81.585, 110.87, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.81, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.689, -0.085, 0] },
                    t: 60,
                    s: [51.164, 75.568, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.817, 1.401, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.148, -0.141, 0] },
                    t: 62,
                    s: [46.977, 93.082, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.272, 0.996, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.153, 0.069, 0] },
                    t: 64,
                    s: [41.615, 86.585, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 0.774, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.064, -0.004, 0] },
                    t: 66,
                    s: [35.186, 124.337, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.499, 0.946, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.532, 0.132, 0] },
                    t: 68,
                    s: [62.591, 88.269, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, -0.154, 0] },
                    t: 70,
                    s: [58.881, 26.321, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.072, 1.831, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.121, -1.508, 0] },
                    t: 72,
                    s: [84.818, 48.109, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.946, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.076, 0] },
                    t: 74,
                    s: [74.254, 46.968, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.594, 35.497, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.15, 12.359, 0] },
                    t: 76,
                    s: [93.883, 59.486, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.105, 0.083, 0] },
                    t: 78,
                    s: [86.872, 59.571, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.076, 0.594, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.224, -0.643, 0] },
                    t: 80,
                    s: [59.763, 24.304, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.02, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.04, 0.105, 0] },
                    t: 82,
                    s: [43.653, 28.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 1.059, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.016, -0.049, 0] },
                    t: 84,
                    s: [74.399, 44.017, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.401, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.523, 0.034, 0] },
                    t: 86,
                    s: [36.198, 34.152, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 0.234, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.557, 0] },
                    t: 88,
                    s: [41.448, 50.974, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.109, 1.139, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.183, 0.094, 0] },
                    t: 90,
                    s: [10.923, 53.931, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.042, 0.95, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, 0.052, 0] },
                    t: 92,
                    s: [20.467, 78.157, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.003, 0.801, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.028, -0.123, 0] },
                    t: 94,
                    s: [-1.575, 13.482, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.927, 0.974, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.003, 0.144, 0] },
                    t: 96,
                    s: [31.467, 39.632, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.201, 0.662, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.568, -0.037, 0] },
                    t: 98,
                    s: [-2.832, 75.796, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.885, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 0.111, 0] },
                    t: 100,
                    s: [1.556, 50.773, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.966, 0.83, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.302, -0.136, 0] },
                    t: 102,
                    s: [39.258, -25.561, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.108, 1.032, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.057, 0.164, 0] },
                    t: 104,
                    s: [53.602, 3.457, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.962, 1.046, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, 0.023, 0] },
                    t: 106,
                    s: [45.062, 33.613, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.964, 0.941, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.07, 0.03, 0] },
                    t: 108,
                    s: [-37.848, -8.287, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.116, 0.998, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.063, -0.206, 0] },
                    t: 110,
                    s: [7.213, 56.765, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, 1.241, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.049, -0.003, 0] },
                    t: 112,
                    s: [-18.526, 38.06, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.835, 0.935, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.416, 0.062, 0] },
                    t: 114,
                    s: [43.172, 56.215, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, -0.296, 0] },
                    t: 116,
                    s: [58.626, -14.531, 100],
                  },
                  { t: 118, s: [73.773, 0.993, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [10, 10], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.6745098039215687, 0.13725490196078433, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 14,
            op: 222,
            st: 14,
            bm: 0,
          },
        ],
      },
      {
        id: 'comp_3',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Shape Layer 50',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 28,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 38,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 82,
                    s: [100],
                  },
                  { t: 114, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 682, ix: 10 },
              p: { a: 0, k: [136, 154, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [41, 41, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-19.362, 34.498],
                          [7.899, -25.433],
                          [37.607, 53.867],
                          [11.905, 108.592],
                        ],
                        o: [
                          [0, 0],
                          [42.457, -75.648],
                          [-9.025, 29.061],
                          [-47.221, -67.637],
                          [-3.807, -34.72],
                        ],
                        v: [
                          [1, 2],
                          [-48.536, -23.607],
                          [-76.476, -100.021],
                          [27.098, -130.112],
                          [-120.809, -222.715],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [1, 0.6823529411764706, 0.23137254901960785, 1],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 17,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 34,
                      s: [0],
                    },
                    { t: 108, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 28,
                      s: [0],
                    },
                    { t: 98, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 28,
            op: 180,
            st: 28,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Shape Layer 46',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 30,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 74,
                    s: [100],
                  },
                  { t: 106, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 187, ix: 10 },
              p: { a: 0, k: [179, 312, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [62, 62, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-14, 37],
                          [-1, 38],
                          [35.009, -27.828],
                          [-11, 31],
                        ],
                        o: [
                          [0, 0],
                          [9.966, -26.338],
                          [1, -38],
                          [-39, 31],
                          [11, -31],
                        ],
                        v: [
                          [1, 2],
                          [48, -39],
                          [29, -112],
                          [136, -107],
                          [103, -176],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.7137254901960784,
                        0.3215686274509804, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 20,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 26,
                      s: [0],
                    },
                    { t: 100, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 20,
                      s: [0],
                    },
                    { t: 90, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 20,
            op: 180,
            st: 20,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Shape Layer 45',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 18,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 28,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 72,
                    s: [100],
                  },
                  { t: 104, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 487, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [41, 41, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-19.362, 34.498],
                          [7.899, -25.433],
                          [37.607, 53.867],
                          [11.905, 108.592],
                        ],
                        o: [
                          [0, 0],
                          [42.457, -75.648],
                          [-9.025, 29.061],
                          [-47.221, -67.637],
                          [-3.807, -34.72],
                        ],
                        v: [
                          [1, 2],
                          [-48.536, -23.607],
                          [-76.476, -100.021],
                          [27.098, -130.112],
                          [-120.809, -222.715],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [1, 0.6823529411764706, 0.23137254901960785, 1],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 5,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 24,
                      s: [0],
                    },
                    { t: 98, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 18,
                      s: [0],
                    },
                    { t: 88, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 18,
            op: 180,
            st: 18,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Shape Layer 43',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 16,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 26,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 70,
                    s: [100],
                  },
                  { t: 102, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 353, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-14, 37],
                          [8.506, -58.529],
                          [28.328, 67.272],
                          [11.905, 108.592],
                        ],
                        o: [
                          [0, 0],
                          [9.966, -26.338],
                          [-12.365, 85.083],
                          [-32.013, -76.024],
                          [-3.807, -34.72],
                        ],
                        v: [
                          [1, 2],
                          [-48.536, -23.607],
                          [-76.476, -100.021],
                          [27.098, -130.112],
                          [-120.809, -222.715],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.6431372549019608,
                        0.3215686274509804, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 15,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 22,
                      s: [0],
                    },
                    { t: 96, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 16,
                      s: [0],
                    },
                    { t: 86, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 16,
            op: 180,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Shape Layer 42',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 68,
                    s: [100],
                  },
                  { t: 100, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 187, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-14, 37],
                          [8.254, -31.239],
                          [20.685, 70.001],
                          [17.795, 30.055],
                        ],
                        o: [
                          [0, 0],
                          [9.966, -26.338],
                          [-7.439, 28.154],
                          [-10.769, -36.442],
                          [-17.795, -30.055],
                        ],
                        v: [
                          [1, 2],
                          [2.692, -79.782],
                          [-49.416, -90.653],
                          [-14.589, -135.23],
                          [-105.31, -168.421],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 25,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 20,
                      s: [0],
                    },
                    { t: 94, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 14,
                      s: [0],
                    },
                    { t: 84, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 14,
            op: 180,
            st: 14,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Shape Layer 41',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 64,
                    s: [100],
                  },
                  { t: 96, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [256, 256, 0], ix: 2 },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [-14, 37],
                          [-1, 38],
                          [35.009, -27.828],
                          [-11, 31],
                        ],
                        o: [
                          [0, 0],
                          [9.966, -26.338],
                          [1, -38],
                          [-39, 31],
                          [11, -31],
                        ],
                        v: [
                          [1, 2],
                          [48, -39],
                          [29, -112],
                          [136, -107],
                          [103, -176],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.8313725490196079,
                        0.3215686274509804, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: {
                      a: 0,
                      k: 17,
                      ix: 5,
                      x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = wiggle(5, 3);',
                    },
                    lc: 1,
                    lj: 1,
                    ml: 4,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 16,
                      s: [0],
                    },
                    { t: 90, s: [100] },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.139], y: [0] },
                      t: 10,
                      s: [0],
                    },
                    { t: 80, s: [100] },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 10,
            op: 180,
            st: 10,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Shape Layer 40',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 12,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 22,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 66,
                    s: [100],
                  },
                  { t: 98, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 12,
                    s: [269.95, 307.113, 0],
                    to: [-0.323, -0.36, 0],
                    ti: [1.346, 1.541, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [268.011, 304.951, 0],
                    to: [-1.346, -1.541, 0],
                    ti: [2.46, 3.542, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [261.875, 297.864, 0],
                    to: [-2.46, -3.542, 0],
                    ti: [2.232, 5.312, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [253.252, 283.701, 0],
                    to: [-2.232, -5.312, 0],
                    ti: [0.637, 5.627, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 20,
                    s: [248.481, 265.992, 0],
                    to: [-0.637, -5.627, 0],
                    ti: [-0.921, 4.788, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.982 },
                    o: { x: 0.167, y: 0.021 },
                    t: 22,
                    s: [249.431, 249.939, 0],
                    to: [0.921, -4.788, 0],
                    ti: [-1.796, 3.705, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.954 },
                    o: { x: 0.167, y: 0.054 },
                    t: 24,
                    s: [254.007, 237.262, 0],
                    to: [1.796, -3.705, 0],
                    ti: [-2.147, 2.784, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.928 },
                    o: { x: 0.167, y: 0.084 },
                    t: 26,
                    s: [260.206, 227.707, 0],
                    to: [2.147, -2.784, 0],
                    ti: [-2.218, 2.088, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.905 },
                    o: { x: 0.167, y: 0.108 },
                    t: 28,
                    s: [266.89, 220.559, 0],
                    to: [2.218, -2.088, 0],
                    ti: [-2.161, 1.578, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.888 },
                    o: { x: 0.167, y: 0.126 },
                    t: 30,
                    s: [273.513, 215.179, 0],
                    to: [2.161, -1.578, 0],
                    ti: [-2.053, 1.203, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.875 },
                    o: { x: 0.167, y: 0.139 },
                    t: 32,
                    s: [279.855, 211.089, 0],
                    to: [2.053, -1.203, 0],
                    ti: [-1.93, 0.918, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.866 },
                    o: { x: 0.167, y: 0.149 },
                    t: 34,
                    s: [285.832, 207.964, 0],
                    to: [1.93, -0.918, 0],
                    ti: [-1.801, 0.7, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.858 },
                    o: { x: 0.167, y: 0.156 },
                    t: 36,
                    s: [291.433, 205.582, 0],
                    to: [1.801, -0.7, 0],
                    ti: [-1.666, 0.538, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.161 },
                    t: 38,
                    s: [296.641, 203.764, 0],
                    to: [1.666, -0.538, 0],
                    ti: [-1.53, 0.417, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.851 },
                    o: { x: 0.167, y: 0.165 },
                    t: 40,
                    s: [301.428, 202.354, 0],
                    to: [1.53, -0.417, 0],
                    ti: [-1.402, 0.32, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.167 },
                    t: 42,
                    s: [305.818, 201.265, 0],
                    to: [1.402, -0.32, 0],
                    ti: [-1.283, 0.243, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.171 },
                    t: 44,
                    s: [309.838, 200.433, 0],
                    to: [1.283, -0.243, 0],
                    ti: [-1.172, 0.18, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.171 },
                    t: 46,
                    s: [313.514, 199.809, 0],
                    to: [1.172, -0.18, 0],
                    ti: [-1.068, 0.128, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.173 },
                    t: 48,
                    s: [316.868, 199.355, 0],
                    to: [1.068, -0.128, 0],
                    ti: [-0.97, 0.084, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 50,
                    s: [319.92, 199.043, 0],
                    to: [0.97, -0.084, 0],
                    ti: [-0.879, 0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.176 },
                    t: 52,
                    s: [322.69, 198.85, 0],
                    to: [0.879, -0.048, 0],
                    ti: [-0.793, 0.017, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.176 },
                    t: 54,
                    s: [325.194, 198.756, 0],
                    to: [0.793, -0.017, 0],
                    ti: [-0.712, -0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.176 },
                    t: 56,
                    s: [327.449, 198.747, 0],
                    to: [0.712, 0.009, 0],
                    ti: [-0.636, -0.03, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.177 },
                    t: 58,
                    s: [329.469, 198.808, 0],
                    to: [0.636, 0.03, 0],
                    ti: [-0.564, -0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.175 },
                    t: 60,
                    s: [331.266, 198.928, 0],
                    to: [0.564, 0.048, 0],
                    ti: [-0.497, -0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.174 },
                    t: 62,
                    s: [332.854, 199.098, 0],
                    to: [0.497, 0.063, 0],
                    ti: [-0.434, -0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.171 },
                    t: 64,
                    s: [334.247, 199.309, 0],
                    to: [0.434, 0.076, 0],
                    ti: [-0.375, -0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.168 },
                    t: 66,
                    s: [335.457, 199.552, 0],
                    to: [0.375, 0.085, 0],
                    ti: [-0.32, -0.092, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.861 },
                    o: { x: 0.167, y: 0.163 },
                    t: 68,
                    s: [336.496, 199.819, 0],
                    to: [0.32, 0.092, 0],
                    ti: [-0.269, -0.097, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.867 },
                    o: { x: 0.167, y: 0.156 },
                    t: 70,
                    s: [337.376, 200.105, 0],
                    to: [0.269, 0.097, 0],
                    ti: [-0.221, -0.099, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.878 },
                    o: { x: 0.167, y: 0.145 },
                    t: 72,
                    s: [338.108, 200.401, 0],
                    to: [0.221, 0.099, 0],
                    ti: [-0.178, -0.1, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.131 },
                    t: 74,
                    s: [338.704, 200.702, 0],
                    to: [0.178, 0.1, 0],
                    ti: [-0.138, -0.098, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.909 },
                    o: { x: 0.167, y: 0.111 },
                    t: 76,
                    s: [339.176, 200.999, 0],
                    to: [0.138, 0.098, 0],
                    ti: [-0.103, -0.094, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.93 },
                    o: { x: 0.167, y: 0.087 },
                    t: 78,
                    s: [339.534, 201.288, 0],
                    to: [0.103, 0.094, 0],
                    ti: [-0.071, -0.087, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.954 },
                    o: { x: 0.167, y: 0.057 },
                    t: 80,
                    s: [339.791, 201.562, 0],
                    to: [0.071, 0.087, 0],
                    ti: [-0.043, -0.079, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.027 },
                    t: 82,
                    s: [339.958, 201.813, 0],
                    to: [0.043, 0.079, 0],
                    ti: [-0.02, -0.068, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.004 },
                    t: 84,
                    s: [340.049, 202.035, 0],
                    to: [0.02, 0.068, 0],
                    ti: [0, -0.055, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [340.075, 202.222, 0],
                    to: [0, 0.055, 0],
                    ti: [0.014, -0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [340.051, 202.365, 0],
                    to: [-0.014, 0.04, 0],
                    ti: [0.025, -0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [339.989, 202.459, 0],
                    to: [-0.025, 0.022, 0],
                    ti: [0.024, -0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [339.903, 202.496, 0],
                    to: [-0.024, 0.002, 0],
                    ti: [0.008, 0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.995 },
                    o: { x: 0.167, y: 0.003 },
                    t: 94,
                    s: [339.844, 202.469, 0],
                    to: [-0.008, -0.02, 0],
                    ti: [-0.011, 0.042, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.983 },
                    o: { x: 0.167, y: 0.013 },
                    t: 96,
                    s: [339.858, 202.373, 0],
                    to: [0.011, -0.042, 0],
                    ti: [-0.012, 0.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 98,
                    s: [339.908, 202.219, 0],
                    to: [0.012, -0.057, 0],
                    ti: [-0.001, 0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [339.927, 202.033, 0],
                    to: [0.001, -0.066, 0],
                    ti: [0.008, 0.072, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [339.916, 201.823, 0],
                    to: [-0.008, -0.072, 0],
                    ti: [0.017, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [339.877, 201.599, 0],
                    to: [-0.017, -0.076, 0],
                    ti: [0.026, 0.078, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [339.812, 201.365, 0],
                    to: [-0.026, -0.078, 0],
                    ti: [0.033, 0.078, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [339.723, 201.128, 0],
                    to: [-0.033, -0.078, 0],
                    ti: [0.04, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [339.613, 200.895, 0],
                    to: [-0.04, -0.076, 0],
                    ti: [0.046, 0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [339.484, 200.67, 0],
                    to: [-0.046, -0.073, 0],
                    ti: [0.051, 0.068, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [339.339, 200.457, 0],
                    to: [-0.051, -0.068, 0],
                    ti: [0.055, 0.062, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [339.179, 200.262, 0],
                    to: [-0.055, -0.062, 0],
                    ti: [0.029, 0.029, 0],
                  },
                  { t: 118, s: [339.007, 200.087, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.27, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 12,
                    s: [140.028, 87.797, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.945, 4.379, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.064, 0.567, 0] },
                    t: 14,
                    s: [149.724, 81.426, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.242, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.158, 0.081, 0] },
                    t: 16,
                    s: [108.654, 80.328, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 1.096, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, -0.235, 0] },
                    t: 18,
                    s: [122.85, 125.952, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.849, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.361, 0.045, 0] },
                    t: 20,
                    s: [67.435, 113.999, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.46, 0.786, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.186, -0.016, 0] },
                    t: 22,
                    s: [77.828, 139.764, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.921, 0.918, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.099, 0.136, 0] },
                    t: 24,
                    s: [86.275, 118.232, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.039, -1.479, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.604, -4.077, 0] },
                    t: 26,
                    s: [132.517, 84.447, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.895, 1.074, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.091, 0.086, 0] },
                    t: 28,
                    s: [130.233, 85.124, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.337, 1.045, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.399, 0.039, 0] },
                    t: 30,
                    s: [104.061, 104.576, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 0.992, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, 0.029, 0] },
                    t: 32,
                    s: [97.155, 67.886, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.652, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.607, -0.009, 0] },
                    t: 34,
                    s: [131.988, 124.186, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.86, 0.858, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, -0.045, 0] },
                    t: 36,
                    s: [137.528, 73.357, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.036, 1.045, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.207, 0.201, 0] },
                    t: 38,
                    s: [88.621, 106.456, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 0.915, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, 0.029, 0] },
                    t: 40,
                    s: [55.617, 129.849, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.262, 4.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.634, 4.996, 0] },
                    t: 42,
                    s: [102.914, 93.756, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.946, 0.946, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.082, 0] },
                    t: 44,
                    s: [97.417, 93.144, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.703, 0.499, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.154, -0.157, 0] },
                    t: 46,
                    s: [54.264, 122.898, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.998, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.116, 0.1, 0] },
                    t: 48,
                    s: [69.385, 112.598, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.949, 1.263, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.002, 0.458, 0] },
                    t: 50,
                    s: [108.191, 60.937, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 0.891, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.135, 0.063, 0] },
                    t: 52,
                    s: [70.4, 49.44, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.585, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.486, 0.354, 0] },
                    t: 54,
                    s: [84.854, 97.16, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.234, 0.72, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.104, -0.001, 0] },
                    t: 56,
                    s: [85.713, 111.857, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.975, 0.919, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, 0.119, 0] },
                    t: 58,
                    s: [89.136, 97.385, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.375, 0.152, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.036, -3.19, 0] },
                    t: 60,
                    s: [76.083, 63.267, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 0.8, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.068, 0.092, 0] },
                    t: 62,
                    s: [85.219, 64.136, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.877, 1.035, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, 0.143, 0] },
                    t: 64,
                    s: [34.985, 72.111, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.984, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.257, 0.025, 0] },
                    t: 66,
                    s: [97.846, 83.275, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.901, 2.92, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.02, 2.259, 0] },
                    t: 68,
                    s: [127.941, 67.428, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.553, 0.981, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.536, 0.08, 0] },
                    t: 70,
                    s: [103.695, 66.821, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.782, 0.701, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.102, -0.025, 0] },
                    t: 72,
                    s: [99.227, 81.408, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.859, 0.804, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.135, 0.116, 0] },
                    t: 74,
                    s: [79.727, 70.137, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.953, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.203, 0.145, 0] },
                    t: 76,
                    s: [48.153, 40.939, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.831, 0.706, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.106, -0.113, 0] },
                    t: 78,
                    s: [26.225, 1.63, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.052, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.164, 0.116, 0] },
                    t: 80,
                    s: [35.878, 18.283, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.639, 0.832, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.032, -0.069, 0] },
                    t: 82,
                    s: [45.795, 60.473, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.99, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.108, 0.165, 0] },
                    t: 84,
                    s: [29.734, 37.417, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.965, -0.589, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.011, -0.907, 0] },
                    t: 86,
                    s: [-23.828, 13.866, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.893, 0.871, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.06, 0.088, 0] },
                    t: 88,
                    s: [23.282, 15.847, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.57, 1.036, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.379, 0.235, 0] },
                    t: 90,
                    s: [-4.081, 51.66, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.965, 0.796, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.025, 0] },
                    t: 92,
                    s: [-11.795, 71.385, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 0.979, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.061, 0.141, 0] },
                    t: 94,
                    s: [48.641, 43.137, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.129, 0.825, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, -0.028, 0] },
                    t: 96,
                    s: [13.642, 2.267, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.051, 0.159, 0] },
                    t: 98,
                    s: [-18.389, 32.951, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.864, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.028, 0.017, 0] },
                    t: 100,
                    s: [63.189, 66.722, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.844, 1.094, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.146, 0.216, 0] },
                    t: 102,
                    s: [2.261, 24.31, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.977, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.179, 0.044, 0] },
                    t: 104,
                    s: [24.372, -2.358, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.521, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.314, -0.032, 0] },
                    t: 106,
                    s: [43.66, 54.3, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 0.99, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.101, -0.001, 0] },
                    t: 108,
                    s: [39.61, 13.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.265, 0.893, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.359, -0.012, 0] },
                    t: 110,
                    s: [20.359, 53.589, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.823, 0.847, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.371, 0] },
                    t: 112,
                    s: [23.988, 18.274, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.068, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.157, 0.183, 0] },
                    t: 114,
                    s: [52.384, 8.05, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.034, 0] },
                    t: 116,
                    s: [84.408, -0.484, 100],
                  },
                  { t: 118, s: [26.091, 14.017, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.7137254901960784,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 12,
            op: 180,
            st: 12,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Shape Layer 39',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 22,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 32,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 76,
                    s: [100],
                  },
                  { t: 108, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [266.709, 299.63, 0],
                    to: [-0.015, 0.376, 0],
                    ti: [0.204, -1.854, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [266.621, 301.887, 0],
                    to: [-0.204, 1.854, 0],
                    ti: [0.96, -4.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [265.488, 310.754, 0],
                    to: [-0.96, 4.091, 0],
                    ti: [2.202, -5.284, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [260.859, 326.432, 0],
                    to: [-2.202, 5.284, 0],
                    ti: [3.145, -4.688, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [252.276, 342.456, 0],
                    to: [-3.145, 4.688, 0],
                    ti: [3.441, -3.397, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [241.99, 354.561, 0],
                    to: [-3.441, 3.397, 0],
                    ti: [3.35, -2.293, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [231.629, 362.84, 0],
                    to: [-3.35, 2.293, 0],
                    ti: [3.107, -1.507, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [221.889, 368.32, 0],
                    to: [-3.107, 1.507, 0],
                    ti: [2.823, -0.973, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [212.988, 371.885, 0],
                    to: [-2.823, 0.973, 0],
                    ti: [2.545, -0.61, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [204.948, 374.155, 0],
                    to: [-2.545, 0.61, 0],
                    ti: [2.287, -0.361, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [197.719, 375.542, 0],
                    to: [-2.287, 0.361, 0],
                    ti: [2.057, -0.189, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [191.223, 376.321, 0],
                    to: [-2.057, 0.189, 0],
                    ti: [1.855, -0.069, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [185.374, 376.678, 0],
                    to: [-1.855, 0.069, 0],
                    ti: [1.678, 0.016, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [180.091, 376.736, 0],
                    to: [-1.678, -0.016, 0],
                    ti: [1.52, 0.078, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [175.308, 376.58, 0],
                    to: [-1.52, -0.078, 0],
                    ti: [1.379, 0.122, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [170.97, 376.27, 0],
                    to: [-1.379, -0.122, 0],
                    ti: [1.252, 0.155, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [167.033, 375.845, 0],
                    to: [-1.252, -0.155, 0],
                    ti: [1.137, 0.18, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [163.458, 375.337, 0],
                    to: [-1.137, -0.18, 0],
                    ti: [1.031, 0.198, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [160.214, 374.766, 0],
                    to: [-1.031, -0.198, 0],
                    ti: [0.933, 0.212, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [157.274, 374.147, 0],
                    to: [-0.933, -0.212, 0],
                    ti: [0.843, 0.223, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [154.615, 373.492, 0],
                    to: [-0.843, -0.223, 0],
                    ti: [0.758, 0.232, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [152.218, 372.807, 0],
                    to: [-0.758, -0.232, 0],
                    ti: [0.683, 0.239, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [150.067, 372.098, 0],
                    to: [-0.683, -0.239, 0],
                    ti: [0.619, 0.242, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [148.12, 371.372, 0],
                    to: [-0.619, -0.242, 0],
                    ti: [0.56, 0.239, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [146.355, 370.647, 0],
                    to: [-0.56, -0.239, 0],
                    ti: [0.506, 0.232, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [144.758, 369.937, 0],
                    to: [-0.506, -0.232, 0],
                    ti: [0.454, 0.219, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [143.32, 369.258, 0],
                    to: [-0.454, -0.219, 0],
                    ti: [0.406, 0.203, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [142.032, 368.621, 0],
                    to: [-0.406, -0.203, 0],
                    ti: [0.36, 0.184, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [140.885, 368.038, 0],
                    to: [-0.36, -0.184, 0],
                    ti: [0.317, 0.162, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [139.871, 367.517, 0],
                    to: [-0.317, -0.162, 0],
                    ti: [0.277, 0.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [138.982, 367.066, 0],
                    to: [-0.277, -0.138, 0],
                    ti: [0.238, 0.111, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [138.211, 366.692, 0],
                    to: [-0.238, -0.111, 0],
                    ti: [0.201, 0.083, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [137.554, 366.399, 0],
                    to: [-0.201, -0.083, 0],
                    ti: [0.166, 0.055, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [137.005, 366.191, 0],
                    to: [-0.166, -0.055, 0],
                    ti: [0.132, 0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [136.559, 366.071, 0],
                    to: [-0.132, -0.025, 0],
                    ti: [0.1, -0.005, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [136.211, 366.04, 0],
                    to: [-0.1, 0.005, 0],
                    ti: [0.069, -0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [135.959, 366.098, 0],
                    to: [-0.069, 0.034, 0],
                    ti: [0.039, -0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [135.797, 366.244, 0],
                    to: [-0.039, 0.063, 0],
                    ti: [0.011, -0.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [135.723, 366.476, 0],
                    to: [-0.011, 0.091, 0],
                    ti: [-0.016, -0.118, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.007 },
                    t: 100,
                    s: [135.732, 366.79, 0],
                    to: [0.016, 0.118, 0],
                    ti: [-0.036, -0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.012 },
                    t: 102,
                    s: [135.82, 367.181, 0],
                    to: [0.036, 0.141, 0],
                    ti: [-0.044, -0.159, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.011 },
                    t: 104,
                    s: [135.95, 367.634, 0],
                    to: [0.044, 0.159, 0],
                    ti: [-0.046, -0.173, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.01 },
                    t: 106,
                    s: [136.085, 368.134, 0],
                    to: [0.046, 0.173, 0],
                    ti: [-0.047, -0.184, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.009 },
                    t: 108,
                    s: [136.223, 368.671, 0],
                    to: [0.047, 0.184, 0],
                    ti: [-0.048, -0.193, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.009 },
                    t: 110,
                    s: [136.365, 369.239, 0],
                    to: [0.048, 0.193, 0],
                    ti: [-0.048, -0.197, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.009 },
                    t: 112,
                    s: [136.509, 369.827, 0],
                    to: [0.048, 0.197, 0],
                    ti: [-0.048, -0.198, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.009 },
                    t: 114,
                    s: [136.653, 370.423, 0],
                    to: [0.048, 0.198, 0],
                    ti: [-0.047, -0.195, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.009 },
                    t: 116,
                    s: [136.796, 371.017, 0],
                    to: [0.047, 0.195, 0],
                    ti: [-0.023, -0.096, 0],
                  },
                  { t: 118, s: [136.937, 371.594, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.877, 0.953, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 22,
                    s: [59.343, 96.124, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.969, 0.832, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.259, -0.107, 0] },
                    t: 24,
                    s: [89.744, 43.042, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.861, 1.087, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.05, 0.166, 0] },
                    t: 26,
                    s: [104.203, 66.307, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.424, 0.974, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.208, 0.043, 0] },
                    t: 28,
                    s: [95.197, 89.821, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.819, 0.904, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, -0.039, 0] },
                    t: 30,
                    s: [89.153, 41.816, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.993, 0.548, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.154, 0.617, 0] },
                    t: 32,
                    s: [125.946, 74.556, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.815, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.008, 0.102, 0] },
                    t: 34,
                    s: [169.121, 79.673, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.937, 1.513, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.152, -0.669, 0] },
                    t: 36,
                    s: [129.751, 102.297, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.747, 1.071, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.266, 0.072, 0] },
                    t: 38,
                    s: [81.726, 99.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.055, 0.899, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.124, 0.038, 0] },
                    t: 40,
                    s: [93.197, 117.733, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 1.324, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.033, 0.474, 0] },
                    t: 42,
                    s: [116.588, 84.536, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.473, 1.053, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.179, 0.066, 0] },
                    t: 44,
                    s: [77.774, 77.458, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.099, 0.033, 0] },
                    t: 46,
                    s: [80.336, 112.065, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.026, 1.108, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.364, 0.57, 0] },
                    t: 48,
                    s: [93.979, 55.33, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.832, 0.728, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.02, 0.047, 0] },
                    t: 50,
                    s: [91.437, 45.624, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.069, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.076, 0.12, 0] },
                    t: 52,
                    s: [94.777, 67.955, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, -0.116, 0] },
                    t: 54,
                    s: [58.109, 118.474, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 0.352, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.102, 0.493, 0] },
                    t: 56,
                    s: [125.074, 97.355, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.408, 0.998, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.104, 0.096, 0] },
                    t: 58,
                    s: [95.015, 93.056, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.982, 0.922, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, -0.002, 0] },
                    t: 60,
                    s: [108.364, 63.932, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.838, -0.683, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.022, -1.218, 0] },
                    t: 62,
                    s: [29.719, 92.263, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 0.975, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.172, 0.088, 0] },
                    t: 64,
                    s: [91.786, 90.449, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 0.834, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.177, -0.036, 0] },
                    t: 66,
                    s: [150.239, 55.635, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.842, 0.888, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, 0.167, 0] },
                    t: 68,
                    s: [131.528, 79.867, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 1.053, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, 0.326, 0] },
                    t: 70,
                    s: [114.424, 104, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.464, 0.726, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.141, 0.032, 0] },
                    t: 72,
                    s: [99.134, 112.3, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 0.762, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, 0.12, 0] },
                    t: 74,
                    s: [104.825, 98.77, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.398, 1.006, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.54, 0.128, 0] },
                    t: 76,
                    s: [67.48, 67.824, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, 0.877, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.006, 0] },
                    t: 78,
                    s: [65.344, 10.519, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-3.208, 1.093, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.911, 0.258, 0] },
                    t: 80,
                    s: [77.671, 71.977, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.93, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, 0.044, 0] },
                    t: 82,
                    s: [76.639, 101.368, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.96, 1.238, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.443, 0.68, 0] },
                    t: 84,
                    s: [25.532, 39.221, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.134, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.077, 0.062, 0] },
                    t: 86,
                    s: [33.627, 30.543, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.683, 0.714, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.051, -0.194, 0] },
                    t: 88,
                    s: [29.417, 64.046, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.008, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, 0.118, 0] },
                    t: 90,
                    s: [40.374, 53.993, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.787, 1.36, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.007, -0.137, 0] },
                    t: 92,
                    s: [71.134, 29.6, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.078, 1.002, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.137, 0.068, 0] },
                    t: 94,
                    s: [37.38, 38.828, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.04, 0.002, 0] },
                    t: 96,
                    s: [-15.322, -10.291, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 0.695, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.139, -0.254, 0] },
                    t: 98,
                    s: [86.713, 39.937, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.314, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.669, 0.115, 0] },
                    t: 100,
                    s: [48.493, 27.546, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.73, 0.854, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.012, 0] },
                    t: 102,
                    s: [46.484, -5.44, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.035, 0.884, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.12, 0.194, 0] },
                    t: 104,
                    s: [56.051, 33.097, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 1.27, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, 0.299, 0] },
                    t: 106,
                    s: [77.513, 62.169, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.851, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.324, 0.064, 0] },
                    t: 108,
                    s: [46.909, 73.396, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.037, 0.912, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.222, 0.188, 0] },
                    t: 110,
                    s: [53.173, 25.847, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.024, 1.423, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.091, 1.71, 0] },
                    t: 112,
                    s: [56.946, -11.873, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.891, 0.665, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.019, 0.07, 0] },
                    t: 114,
                    s: [96.787, -13.805, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.356, 0.111, 0] },
                    t: 116,
                    s: [45.548, -2.059, 100],
                  },
                  { t: 118, s: [29.917, 33.378, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.7137254901960784,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 22,
            op: 180,
            st: 22,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Shape Layer 38',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 68,
                    s: [100],
                  },
                  { t: 100, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.643 },
                    o: { x: 0.167, y: 0.146 },
                    t: 14,
                    s: [262.436, 299.331, 0],
                    to: [0.489, -0.187, 0],
                    ti: [-2.348, 1.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.806 },
                    o: { x: 0.167, y: 0.086 },
                    t: 16,
                    s: [265.373, 298.21, 0],
                    to: [2.348, -1.046, 0],
                    ti: [-5.031, 2.854, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.874 },
                    o: { x: 0.167, y: 0.092 },
                    t: 18,
                    s: [276.523, 293.055, 0],
                    to: [5.031, -2.854, 0],
                    ti: [-6.175, 4.882, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.919 },
                    o: { x: 0.167, y: 0.082 },
                    t: 20,
                    s: [295.56, 281.084, 0],
                    to: [6.175, -4.882, 0],
                    ti: [-4.919, 5.974, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.957 },
                    o: { x: 0.167, y: 0.049 },
                    t: 22,
                    s: [313.573, 263.763, 0],
                    to: [4.919, -5.974, 0],
                    ti: [-2.793, 5.995, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.015 },
                    t: 24,
                    s: [325.076, 245.24, 0],
                    to: [2.793, -5.995, 0],
                    ti: [-0.958, 5.467, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [330.333, 227.794, 0],
                    to: [0.958, -5.467, 0],
                    ti: [0.382, 4.708, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [330.823, 212.44, 0],
                    to: [-0.382, -4.708, 0],
                    ti: [1.261, 3.892, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [328.039, 199.545, 0],
                    to: [-1.261, -3.892, 0],
                    ti: [1.765, 3.129, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [323.257, 189.086, 0],
                    to: [-1.765, -3.129, 0],
                    ti: [2.001, 2.482, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [317.447, 180.769, 0],
                    to: [-2.001, -2.482, 0],
                    ti: [2.064, 1.961, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [311.25, 174.195, 0],
                    to: [-2.064, -1.961, 0],
                    ti: [2.024, 1.552, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [305.064, 169.001, 0],
                    to: [-2.024, -1.552, 0],
                    ti: [1.932, 1.236, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [299.106, 164.881, 0],
                    to: [-1.932, -1.236, 0],
                    ti: [1.816, 0.99, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [293.474, 161.588, 0],
                    to: [-1.816, -0.99, 0],
                    ti: [1.69, 0.797, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [288.212, 158.939, 0],
                    to: [-1.69, -0.797, 0],
                    ti: [1.563, 0.644, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [283.333, 156.803, 0],
                    to: [-1.563, -0.644, 0],
                    ti: [1.437, 0.519, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [278.836, 155.078, 0],
                    to: [-1.437, -0.519, 0],
                    ti: [1.316, 0.416, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [274.712, 153.691, 0],
                    to: [-1.316, -0.416, 0],
                    ti: [1.201, 0.33, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [270.942, 152.585, 0],
                    to: [-1.201, -0.33, 0],
                    ti: [1.094, 0.257, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [267.505, 151.714, 0],
                    to: [-1.094, -0.257, 0],
                    ti: [0.992, 0.194, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [264.381, 151.045, 0],
                    to: [-0.992, -0.194, 0],
                    ti: [0.897, 0.139, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [261.551, 150.551, 0],
                    to: [-0.897, -0.139, 0],
                    ti: [0.808, 0.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [258.997, 150.211, 0],
                    to: [-0.808, -0.091, 0],
                    ti: [0.724, 0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [256.702, 150.007, 0],
                    to: [-0.724, -0.048, 0],
                    ti: [0.646, 0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [254.651, 149.925, 0],
                    to: [-0.646, -0.009, 0],
                    ti: [0.572, -0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [252.828, 149.951, 0],
                    to: [-0.572, 0.025, 0],
                    ti: [0.502, -0.055, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [251.221, 150.073, 0],
                    to: [-0.502, 0.055, 0],
                    ti: [0.437, -0.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [249.815, 150.281, 0],
                    to: [-0.437, 0.082, 0],
                    ti: [0.377, -0.105, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [248.597, 150.563, 0],
                    to: [-0.377, 0.105, 0],
                    ti: [0.321, -0.125, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [247.552, 150.91, 0],
                    to: [-0.321, 0.125, 0],
                    ti: [0.269, -0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [246.671, 151.311, 0],
                    to: [-0.269, 0.141, 0],
                    ti: [0.22, -0.154, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [245.941, 151.756, 0],
                    to: [-0.22, 0.154, 0],
                    ti: [0.174, -0.163, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [245.353, 152.233, 0],
                    to: [-0.174, 0.163, 0],
                    ti: [0.132, -0.168, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [244.897, 152.732, 0],
                    to: [-0.132, 0.168, 0],
                    ti: [0.093, -0.169, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [244.562, 153.241, 0],
                    to: [-0.093, 0.169, 0],
                    ti: [0.057, -0.166, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [244.34, 153.746, 0],
                    to: [-0.057, 0.166, 0],
                    ti: [0.024, -0.158, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [244.221, 154.236, 0],
                    to: [-0.024, 0.158, 0],
                    ti: [-0.006, -0.145, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.004 },
                    t: 90,
                    s: [244.197, 154.694, 0],
                    to: [0.006, 0.145, 0],
                    ti: [-0.033, -0.128, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.023 },
                    t: 92,
                    s: [244.257, 155.108, 0],
                    to: [0.033, 0.128, 0],
                    ti: [-0.049, -0.109, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.963 },
                    o: { x: 0.167, y: 0.037 },
                    t: 94,
                    s: [244.394, 155.461, 0],
                    to: [0.049, 0.109, 0],
                    ti: [-0.051, -0.108, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.973 },
                    o: { x: 0.167, y: 0.025 },
                    t: 96,
                    s: [244.552, 155.765, 0],
                    to: [0.051, 0.108, 0],
                    ti: [-0.047, -0.12, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.98 },
                    o: { x: 0.167, y: 0.018 },
                    t: 98,
                    s: [244.699, 156.107, 0],
                    to: [0.047, 0.12, 0],
                    ti: [-0.044, -0.131, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.985 },
                    o: { x: 0.167, y: 0.015 },
                    t: 100,
                    s: [244.836, 156.487, 0],
                    to: [0.044, 0.131, 0],
                    ti: [-0.041, -0.137, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.013 },
                    t: 102,
                    s: [244.964, 156.891, 0],
                    to: [0.041, 0.137, 0],
                    ti: [-0.039, -0.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.011 },
                    t: 104,
                    s: [245.085, 157.309, 0],
                    to: [0.039, 0.14, 0],
                    ti: [-0.037, -0.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.01 },
                    t: 106,
                    s: [245.198, 157.733, 0],
                    to: [0.037, 0.14, 0],
                    ti: [-0.035, -0.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.01 },
                    t: 108,
                    s: [245.305, 158.152, 0],
                    to: [0.035, 0.138, 0],
                    ti: [-0.033, -0.133, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.01 },
                    t: 110,
                    s: [245.406, 158.559, 0],
                    to: [0.033, 0.133, 0],
                    ti: [-0.031, -0.125, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.01 },
                    t: 112,
                    s: [245.502, 158.947, 0],
                    to: [0.031, 0.125, 0],
                    ti: [-0.03, -0.116, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.012 },
                    t: 114,
                    s: [245.594, 159.31, 0],
                    to: [0.03, 0.116, 0],
                    ti: [-0.029, -0.104, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.014 },
                    t: 116,
                    s: [245.683, 159.641, 0],
                    to: [0.029, 0.104, 0],
                    ti: [-0.014, -0.049, 0],
                  },
                  { t: 118, s: [245.769, 159.936, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.684, 1.023, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 14,
                    s: [100.63, 69.657, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.351, -1.07, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, 0.018, 0] },
                    t: 16,
                    s: [104.514, 69.052, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.905, 1.153, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, 0.087, 0] },
                    t: 18,
                    s: [115.346, 69.821, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.398, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.695, 0.054, 0] },
                    t: 20,
                    s: [58.871, 88.161, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.965, 0.738, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, -0.137, 0] },
                    t: 22,
                    s: [51.174, 36.165, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.972, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.06, 0.122, 0] },
                    t: 24,
                    s: [95.636, 55.822, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.882, 0.78, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.042, -0.141, 0] },
                    t: 26,
                    s: [69.725, 97.853, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.246, 1.072, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.285, 0.134, 0] },
                    t: 28,
                    s: [86.959, 82.233, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.96, 0.883, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.039, 0] },
                    t: 30,
                    s: [94.096, 56.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 1.134, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.077, 0.29, 0] },
                    t: 32,
                    s: [151.503, 104.404, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.845, 0.96, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.166, 0.051, 0] },
                    t: 34,
                    s: [121.653, 123.646, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.987, 1.023, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.181, -0.079, 0] },
                    t: 36,
                    s: [91.668, 73.435, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.872, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.015, 0.018, 0] },
                    t: 38,
                    s: [66.045, 99.285, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 1.2, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.223, 0.238, 0] },
                    t: 40,
                    s: [87.746, 66.291, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.943, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.113, 0.059, 0] },
                    t: 42,
                    s: [100.66, 48.526, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.102, 0.778, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.477, -0.182, 0] },
                    t: 44,
                    s: [95.172, 108.893, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.575, 0.936, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.046, 0.133, 0] },
                    t: 46,
                    s: [95.987, 89.94, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.108, 0.256, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.079, -0.27, 0] },
                    t: 48,
                    s: [94.176, 58.401, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.975, 0.976, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, 0.094, 0] },
                    t: 50,
                    s: [130.21, 65.835, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 0.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.036, -0.034, 0] },
                    t: 52,
                    s: [47.499, 124.757, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.822, 1.024, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.43, -0.057, 0] },
                    t: 54,
                    s: [105.068, 83.037, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.17, 0.979, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.157, 0.019, 0] },
                    t: 56,
                    s: [118.917, 107.79, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.86, 0.93, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.056, -0.028, 0] },
                    t: 58,
                    s: [134.616, 75.912, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.094, 0.206, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.205, -0.452, 0] },
                    t: 60,
                    s: [86.877, 99.716, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 0.852, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, 0.093, 0] },
                    t: 62,
                    s: [54.265, 96.009, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.9, 1.036, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.139, 0.19, 0] },
                    t: 64,
                    s: [123.839, 64.381, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.39, 0.839, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.496, 0.025, 0] },
                    t: 66,
                    s: [97.726, 39.711, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.984, 0.871, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.097, 0.173, 0] },
                    t: 68,
                    s: [92.458, 75.119, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.931, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.019, 0.235, 0] },
                    t: 70,
                    s: [59.151, 108.162, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.407, 0.742, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.405, 0.034, 0] },
                    t: 72,
                    s: [86.256, 126.307, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.957, 0.931, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.097, 0.123, 0] },
                    t: 74,
                    s: [81.627, 95.551, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.83, 0.652, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.089, -0.391, 0] },
                    t: 76,
                    s: [53.31, 31.043, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.098, 1.066, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.164, 0.11, 0] },
                    t: 78,
                    s: [67.002, 42.37, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.972, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.045, 0.037, 0] },
                    t: 80,
                    s: [81.207, 78.404, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.908, 0.922, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.224, -0.041, 0] },
                    t: 82,
                    s: [50.269, 13.881, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.43, 0.675, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.933, -1.114, 0] },
                    t: 84,
                    s: [31.981, 57.039, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.97, 0.375, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, 0.112, 0] },
                    t: 86,
                    s: [30.188, 54.035, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.919, 0.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.046, 0.096, 0] },
                    t: 88,
                    s: [41.246, 45.317, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [17.435, 1.051, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-2.661, -0.148, 0] },
                    t: 90,
                    s: [34.148, -11.396, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.997, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.083, 0.032, 0] },
                    t: 92,
                    s: [34.363, 9.033, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.893, 0.829, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.004, -0.247, 0] },
                    t: 94,
                    s: [-8.365, -24.009, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.892, 0.989, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.375, 0.163, 0] },
                    t: 96,
                    s: [32.621, -15.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.847, 1.17, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.364, -0.012, 0] },
                    t: 98,
                    s: [44.332, -6.94, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.476, 0.712, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.183, 0.056, 0] },
                    t: 100,
                    s: [47.804, -14.568, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.098, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.099, 0.117, 0] },
                    t: 102,
                    s: [50.704, 8.589, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 1.348, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.045, 0.46, 0] },
                    t: 104,
                    s: [66.039, 65.528, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.638, 0.848, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.665, 0.067, 0] },
                    t: 106,
                    s: [32.735, 78.112, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.718, 0.935, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.185, 0] },
                    t: 108,
                    s: [30.98, 12.995, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.692, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, -0.305, 0] },
                    t: 110,
                    s: [46.164, -40.385, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.25, 0.862, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.313, 0.114, 0] },
                    t: 112,
                    s: [82.432, -28.944, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 0.97, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.211, 0] },
                    t: 114,
                    s: [74.813, 1.96, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.112, -0.047, 0] },
                    t: 116,
                    s: [13.817, 22.06, 100],
                  },
                  { t: 118, s: [39.779, 9.173, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.807843137254902,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 14,
            op: 180,
            st: 14,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Shape Layer 37',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 18,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 28,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 72,
                    s: [100],
                  },
                  { t: 104, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [264.075, 300.144, 0],
                    to: [-0.069, 0.468, 0],
                    ti: [0.355, -2.342, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [263.663, 302.955, 0],
                    to: [-0.355, 2.342, 0],
                    ti: [0.559, -5.353, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [261.945, 314.199, 0],
                    to: [-0.559, 5.353, 0],
                    ti: [0.004, -7.357, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 24,
                    s: [260.312, 335.072, 0],
                    to: [-0.004, 7.357, 0],
                    ti: [-1.215, -7.142, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.014 },
                    t: 26,
                    s: [261.922, 358.34, 0],
                    to: [1.215, 7.142, 0],
                    ti: [-2.396, -5.718, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.96 },
                    o: { x: 0.167, y: 0.047 },
                    t: 28,
                    s: [267.601, 377.926, 0],
                    to: [2.396, 5.718, 0],
                    ti: [-3.152, -4.167, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.923 },
                    o: { x: 0.167, y: 0.09 },
                    t: 30,
                    s: [276.299, 392.647, 0],
                    to: [3.152, 4.167, 0],
                    ti: [-3.444, -2.85, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.125 },
                    t: 32,
                    s: [286.512, 402.925, 0],
                    to: [3.444, 2.85, 0],
                    ti: [-3.405, -1.874, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.869 },
                    o: { x: 0.167, y: 0.15 },
                    t: 34,
                    s: [296.962, 409.748, 0],
                    to: [3.405, 1.874, 0],
                    ti: [-3.2, -1.208, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.162 },
                    t: 36,
                    s: [306.94, 414.169, 0],
                    to: [3.2, 1.208, 0],
                    ti: [-2.937, -0.769, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.169 },
                    t: 38,
                    s: [316.159, 416.998, 0],
                    to: [2.937, 0.769, 0],
                    ti: [-2.672, -0.481, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.172 },
                    t: 40,
                    s: [324.559, 418.783, 0],
                    to: [2.672, 0.481, 0],
                    ti: [-2.428, -0.291, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.174 },
                    t: 42,
                    s: [332.191, 419.883, 0],
                    to: [2.428, 0.291, 0],
                    ti: [-2.206, -0.163, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 44,
                    s: [339.125, 420.527, 0],
                    to: [2.206, 0.163, 0],
                    ti: [-2.007, -0.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 46,
                    s: [345.429, 420.863, 0],
                    to: [2.007, 0.077, 0],
                    ti: [-1.827, -0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 48,
                    s: [351.165, 420.991, 0],
                    to: [1.827, 0.019, 0],
                    ti: [-1.665, 0.021, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 50,
                    s: [356.391, 420.977, 0],
                    to: [1.665, -0.021, 0],
                    ti: [-1.519, 0.049, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 52,
                    s: [361.156, 420.865, 0],
                    to: [1.519, -0.049, 0],
                    ti: [-1.386, 0.069, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 54,
                    s: [365.504, 420.683, 0],
                    to: [1.386, -0.069, 0],
                    ti: [-1.264, 0.084, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 56,
                    s: [369.472, 420.452, 0],
                    to: [1.264, -0.084, 0],
                    ti: [-1.151, 0.096, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.173 },
                    t: 58,
                    s: [373.088, 420.182, 0],
                    to: [1.151, -0.096, 0],
                    ti: [-1.046, 0.107, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.173 },
                    t: 60,
                    s: [376.379, 419.878, 0],
                    to: [1.046, -0.107, 0],
                    ti: [-0.947, 0.118, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.171 },
                    t: 62,
                    s: [379.365, 419.542, 0],
                    to: [0.947, -0.118, 0],
                    ti: [-0.856, 0.124, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.171 },
                    t: 64,
                    s: [382.063, 419.172, 0],
                    to: [0.856, -0.124, 0],
                    ti: [-0.773, 0.118, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.172 },
                    t: 66,
                    s: [384.499, 418.801, 0],
                    to: [0.773, -0.118, 0],
                    ti: [-0.697, 0.107, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.172 },
                    t: 68,
                    s: [386.7, 418.464, 0],
                    to: [0.697, -0.107, 0],
                    ti: [-0.626, 0.097, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.172 },
                    t: 70,
                    s: [388.681, 418.159, 0],
                    to: [0.626, -0.097, 0],
                    ti: [-0.561, 0.088, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.173 },
                    t: 72,
                    s: [390.459, 417.883, 0],
                    to: [0.561, -0.088, 0],
                    ti: [-0.5, 0.079, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.173 },
                    t: 74,
                    s: [392.047, 417.633, 0],
                    to: [0.5, -0.079, 0],
                    ti: [-0.443, 0.072, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.174 },
                    t: 76,
                    s: [393.458, 417.406, 0],
                    to: [0.443, -0.072, 0],
                    ti: [-0.39, 0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.172 },
                    t: 78,
                    s: [394.704, 417.199, 0],
                    to: [0.39, -0.066, 0],
                    ti: [-0.34, 0.061, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.851 },
                    o: { x: 0.167, y: 0.173 },
                    t: 80,
                    s: [395.795, 417.009, 0],
                    to: [0.34, -0.061, 0],
                    ti: [-0.293, 0.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.852 },
                    o: { x: 0.167, y: 0.172 },
                    t: 82,
                    s: [396.741, 416.833, 0],
                    to: [0.293, -0.057, 0],
                    ti: [-0.249, 0.053, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.173 },
                    t: 84,
                    s: [397.551, 416.669, 0],
                    to: [0.249, -0.053, 0],
                    ti: [-0.207, 0.05, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.859 },
                    o: { x: 0.167, y: 0.172 },
                    t: 86,
                    s: [398.233, 416.514, 0],
                    to: [0.207, -0.05, 0],
                    ti: [-0.168, 0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.865 },
                    o: { x: 0.167, y: 0.169 },
                    t: 88,
                    s: [398.794, 416.367, 0],
                    to: [0.168, -0.048, 0],
                    ti: [-0.132, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.875 },
                    o: { x: 0.167, y: 0.163 },
                    t: 90,
                    s: [399.242, 416.225, 0],
                    to: [0.132, -0.046, 0],
                    ti: [-0.097, 0.045, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.148 },
                    t: 92,
                    s: [399.584, 416.088, 0],
                    to: [0.097, -0.045, 0],
                    ti: [-0.064, 0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.921 },
                    o: { x: 0.167, y: 0.111 },
                    t: 94,
                    s: [399.824, 415.954, 0],
                    to: [0.064, -0.044, 0],
                    ti: [-0.034, 0.043, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.974 },
                    o: { x: 0.167, y: 0.032 },
                    t: 96,
                    s: [399.97, 415.824, 0],
                    to: [0.034, -0.043, 0],
                    ti: [-0.012, 0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 98,
                    s: [400.026, 415.696, 0],
                    to: [0.012, -0.044, 0],
                    ti: [-0.006, 0.049, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 100,
                    s: [400.041, 415.559, 0],
                    to: [0.006, -0.049, 0],
                    ti: [-0.008, 0.056, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.004 },
                    t: 102,
                    s: [400.063, 415.401, 0],
                    to: [0.008, -0.056, 0],
                    ti: [-0.011, 0.062, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.006 },
                    t: 104,
                    s: [400.091, 415.224, 0],
                    to: [0.011, -0.062, 0],
                    ti: [-0.014, 0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.992 },
                    o: { x: 0.167, y: 0.008 },
                    t: 106,
                    s: [400.129, 415.031, 0],
                    to: [0.014, -0.066, 0],
                    ti: [-0.017, 0.07, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.011 },
                    t: 108,
                    s: [400.175, 414.825, 0],
                    to: [0.017, -0.07, 0],
                    ti: [-0.021, 0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.985 },
                    o: { x: 0.167, y: 0.015 },
                    t: 110,
                    s: [400.232, 414.61, 0],
                    to: [0.021, -0.073, 0],
                    ti: [-0.025, 0.074, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.98 },
                    o: { x: 0.167, y: 0.02 },
                    t: 112,
                    s: [400.301, 414.389, 0],
                    to: [0.025, -0.074, 0],
                    ti: [-0.029, 0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.974 },
                    o: { x: 0.167, y: 0.026 },
                    t: 114,
                    s: [400.382, 414.168, 0],
                    to: [0.029, -0.073, 0],
                    ti: [-0.034, 0.071, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.964 },
                    o: { x: 0.167, y: 0.036 },
                    t: 116,
                    s: [400.476, 413.95, 0],
                    to: [0.034, -0.071, 0],
                    ti: [-0.018, 0.035, 0],
                  },
                  { t: 118, s: [400.585, 413.741, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.237, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 18,
                    s: [60.433, 69.807, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 0.354, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.078, 0.012, 0] },
                    t: 20,
                    s: [57.035, 63.909, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.869, 0.914, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.136, 0.096, 0] },
                    t: 22,
                    s: [110.865, 70.792, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.993, 2.539, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.23, 2.807, 0] },
                    t: 24,
                    s: [90.384, 117.236, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.615, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.008, 0.079, 0] },
                    t: 26,
                    s: [78.755, 118.657, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.957, 0.878, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.106, 0.017, 0] },
                    t: 28,
                    s: [89.349, 90.995, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.759, 1.314, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.09, 0.263, 0] },
                    t: 30,
                    s: [127.76, 125.63, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.85, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.128, 0.066, 0] },
                    t: 32,
                    s: [109.298, 141.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.93, 1.01, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.187, -0.134, 0] },
                    t: 34,
                    s: [74.466, 65.123, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.468, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.45, 0.009, 0] },
                    t: 36,
                    s: [46.407, 94.474, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.94, 2.673, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.099, 2.042, 0] },
                    t: 38,
                    s: [50.79, 61.594, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.179, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.209, 0.079, 0] },
                    t: 40,
                    s: [74.379, 60.195, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 1.053, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.057, 0.166, 0] },
                    t: 42,
                    s: [67.656, 89.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.318, 0.963, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.577, 0.032, 0] },
                    t: 44,
                    s: [88.817, 119.34, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.032, 0.863, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.089, -0.068, 0] },
                    t: 46,
                    s: [92.391, 70.889, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 1.002, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.023, 0.212, 0] },
                    t: 48,
                    s: [145.365, 97.56, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.702, 0.895, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.509, 0.002, 0] },
                    t: 50,
                    s: [71.948, 114.853, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.886, 1.201, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.407, 0] },
                    t: 52,
                    s: [67.657, 97.077, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.837, 0.928, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.312, 0.059, 0] },
                    t: 54,
                    s: [108.077, 92.496, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.302, 1.255, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.171, -0.517, 0] },
                    t: 56,
                    s: [122.812, 108.142, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.944, 1.236, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.065, 0.063, 0] },
                    t: 58,
                    s: [136.892, 105.97, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.917, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.168, 0.062, 0] },
                    t: 60,
                    s: [71.752, 114.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [4.698, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-10.728, 0.167, 0] },
                    t: 62,
                    s: [93.325, 80.942, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.281, 2.111, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, 0.997, 0] },
                    t: 64,
                    s: [93.159, 47.153, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, 0.796, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.064, 0.078, 0] },
                    t: 66,
                    s: [100.704, 44.07, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-6.847, 0.936, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-7.898, 0.141, 0] },
                    t: 68,
                    s: [67.68, 88.243, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.94, 0.681, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.084, -0.275, 0] },
                    t: 70,
                    s: [68.025, 152.101, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.562, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.209, 0.113, 0] },
                    t: 72,
                    s: [100.152, 137.254, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.103, 0.504, 0] },
                    t: 74,
                    s: [91.001, 95.342, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, -2.094, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, 0.554, 0] },
                    t: 76,
                    s: [52.026, 87.036, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-2.601, 0.998, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-3.839, 0.086, 0] },
                    t: 78,
                    s: [90.362, 85.565, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 0.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, -0.003, 0] },
                    t: 80,
                    s: [89.547, 32.423, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.446, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.669, 1.085, 0] },
                    t: 82,
                    s: [55.168, 83.983, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, 0.034, 0] },
                    t: 84,
                    s: [58.978, 88.274, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.109, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.325, 0.091, 0] },
                    t: 86,
                    s: [34.79, 80.994, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 1.213, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, 1.844, 0] },
                    t: 88,
                    s: [39.732, 2.714, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [4.228, 1.121, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.292, 0.06, 0] },
                    t: 90,
                    s: [28.352, -0.992, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.984, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, 0.049, 0] },
                    t: 92,
                    s: [29.041, 12.182, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.923, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.02, -0.04, 0] },
                    t: 94,
                    s: [1.635, -20.19, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.207, 3.708, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.991, -1.388, 0] },
                    t: 96,
                    s: [23.803, 1.627, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.933, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.078, 0.081, 0] },
                    t: 98,
                    s: [22.083, 0.392, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.444, 0.353, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.338, 0.943, 0] },
                    t: 100,
                    s: [48.704, 41.779, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.843, 1.011, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, 0.096, 0] },
                    t: 102,
                    s: [43.437, 45.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.042, 0.741, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.178, 0.01, 0] },
                    t: 104,
                    s: [13.575, 72.931, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.846, 0.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.028, 0.123, 0] },
                    t: 106,
                    s: [-12.851, 42.13, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.907, 1.471, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.181, 1.058, 0] },
                    t: 108,
                    s: [26.868, -22.827, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.014, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.832, 0.071, 0] },
                    t: 110,
                    s: [60.696, -28.382, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.956, 6.012, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.077, 11.039, 0] },
                    t: 112,
                    s: [64.461, 8.569, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.945, 1.005, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.093, 0.082, 0] },
                    t: 114,
                    s: [14.897, 8.85, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.162, 0.005, 0] },
                    t: 116,
                    s: [38.326, -8.337, 100],
                  },
                  { t: 118, s: [30.376, 9.931, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.807843137254902,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 18,
            op: 180,
            st: 18,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Shape Layer 36',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 64,
                    s: [100],
                  },
                  { t: 96, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.748 },
                    o: { x: 0.167, y: 0.106 },
                    t: 10,
                    s: [268.501, 303.894, 0],
                    to: [0.373, -0.267, 0],
                    ti: [-1.332, 1.601, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.933 },
                    o: { x: 0.167, y: 0.032 },
                    t: 12,
                    s: [270.738, 302.29, 0],
                    to: [1.332, -1.601, 0],
                    ti: [1.952, 1.768, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [276.491, 294.286, 0],
                    to: [-1.952, -1.768, 0],
                    ti: [6.31, 0.166, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [259.026, 291.685, 0],
                    to: [-6.31, -0.166, 0],
                    ti: [6.364, -0.502, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [238.628, 293.288, 0],
                    to: [-6.364, 0.502, 0],
                    ti: [5.435, -0.368, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [220.844, 294.694, 0],
                    to: [-5.435, 0.368, 0],
                    ti: [4.549, -0.177, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [206.017, 295.497, 0],
                    to: [-4.549, 0.177, 0],
                    ti: [3.853, -0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [193.549, 295.758, 0],
                    to: [-3.853, 0.014, 0],
                    ti: [3.314, 0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [182.897, 295.583, 0],
                    to: [-3.314, -0.117, 0],
                    ti: [2.886, 0.223, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [173.668, 295.056, 0],
                    to: [-2.886, -0.223, 0],
                    ti: [2.537, 0.31, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [165.584, 294.243, 0],
                    to: [-2.537, -0.31, 0],
                    ti: [2.246, 0.383, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [158.446, 293.194, 0],
                    to: [-2.246, -0.383, 0],
                    ti: [2.001, 0.441, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [152.105, 291.947, 0],
                    to: [-2.001, -0.441, 0],
                    ti: [1.789, 0.485, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [146.441, 290.551, 0],
                    to: [-1.789, -0.485, 0],
                    ti: [1.601, 0.519, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [141.373, 289.038, 0],
                    to: [-1.601, -0.519, 0],
                    ti: [1.435, 0.544, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [136.833, 287.437, 0],
                    to: [-1.435, -0.544, 0],
                    ti: [1.285, 0.562, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [132.765, 285.771, 0],
                    to: [-1.285, -0.562, 0],
                    ti: [1.15, 0.573, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [129.121, 284.063, 0],
                    to: [-1.15, -0.573, 0],
                    ti: [1.027, 0.578, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [125.863, 282.331, 0],
                    to: [-1.027, -0.578, 0],
                    ti: [0.915, 0.576, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [122.957, 280.597, 0],
                    to: [-0.915, -0.576, 0],
                    ti: [0.814, 0.569, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [120.371, 278.877, 0],
                    to: [-0.814, -0.569, 0],
                    ti: [0.721, 0.558, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [118.075, 277.184, 0],
                    to: [-0.721, -0.558, 0],
                    ti: [0.637, 0.542, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [116.044, 275.532, 0],
                    to: [-0.637, -0.542, 0],
                    ti: [0.56, 0.523, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [114.254, 273.931, 0],
                    to: [-0.56, -0.523, 0],
                    ti: [0.49, 0.502, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [112.684, 272.392, 0],
                    to: [-0.49, -0.502, 0],
                    ti: [0.427, 0.478, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [111.312, 270.921, 0],
                    to: [-0.427, -0.478, 0],
                    ti: [0.369, 0.453, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [110.122, 269.523, 0],
                    to: [-0.369, -0.453, 0],
                    ti: [0.317, 0.426, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [109.095, 268.204, 0],
                    to: [-0.317, -0.426, 0],
                    ti: [0.27, 0.399, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [108.219, 266.966, 0],
                    to: [-0.27, -0.399, 0],
                    ti: [0.226, 0.37, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [107.478, 265.812, 0],
                    to: [-0.226, -0.37, 0],
                    ti: [0.186, 0.342, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [106.862, 264.743, 0],
                    to: [-0.186, -0.342, 0],
                    ti: [0.15, 0.313, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [106.36, 263.762, 0],
                    to: [-0.15, -0.313, 0],
                    ti: [0.117, 0.283, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [105.962, 262.868, 0],
                    to: [-0.117, -0.283, 0],
                    ti: [0.086, 0.254, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [105.66, 262.062, 0],
                    to: [-0.086, -0.254, 0],
                    ti: [0.058, 0.224, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [105.445, 261.346, 0],
                    to: [-0.058, -0.224, 0],
                    ti: [0.032, 0.194, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [105.312, 260.718, 0],
                    to: [-0.032, -0.194, 0],
                    ti: [0.008, 0.164, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [105.253, 260.18, 0],
                    to: [-0.008, -0.164, 0],
                    ti: [-0.014, 0.135, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.007 },
                    t: 84,
                    s: [105.263, 259.732, 0],
                    to: [0.014, -0.135, 0],
                    ti: [-0.034, 0.104, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.97 },
                    o: { x: 0.167, y: 0.035 },
                    t: 86,
                    s: [105.336, 259.373, 0],
                    to: [0.034, -0.104, 0],
                    ti: [-0.052, 0.074, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.917 },
                    o: { x: 0.167, y: 0.092 },
                    t: 88,
                    s: [105.467, 259.105, 0],
                    to: [0.052, -0.074, 0],
                    ti: [-0.065, 0.05, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.879 },
                    o: { x: 0.167, y: 0.129 },
                    t: 90,
                    s: [105.65, 258.927, 0],
                    to: [0.065, -0.05, 0],
                    ti: [-0.069, 0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.876 },
                    o: { x: 0.167, y: 0.142 },
                    t: 92,
                    s: [105.86, 258.807, 0],
                    to: [0.069, -0.036, 0],
                    ti: [-0.062, 0.029, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.883 },
                    o: { x: 0.167, y: 0.151 },
                    t: 94,
                    s: [106.065, 258.712, 0],
                    to: [0.062, -0.029, 0],
                    ti: [-0.047, 0.024, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.896 },
                    o: { x: 0.167, y: 0.15 },
                    t: 96,
                    s: [106.232, 258.632, 0],
                    to: [0.047, -0.024, 0],
                    ti: [-0.031, 0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.92 },
                    o: { x: 0.167, y: 0.138 },
                    t: 98,
                    s: [106.349, 258.566, 0],
                    to: [0.031, -0.02, 0],
                    ti: [-0.015, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.961 },
                    o: { x: 0.167, y: 0.065 },
                    t: 100,
                    s: [106.418, 258.515, 0],
                    to: [0.015, -0.014, 0],
                    ti: [0.001, 0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [106.44, 258.479, 0],
                    to: [-0.001, -0.009, 0],
                    ti: [0.016, 0.003, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [106.414, 258.46, 0],
                    to: [-0.016, -0.003, 0],
                    ti: [0.031, -0.003, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [106.343, 258.459, 0],
                    to: [-0.031, 0.003, 0],
                    ti: [0.045, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [106.228, 258.475, 0],
                    to: [-0.045, 0.008, 0],
                    ti: [0.059, -0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [106.07, 258.51, 0],
                    to: [-0.059, 0.014, 0],
                    ti: [0.072, -0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [105.873, 258.562, 0],
                    to: [-0.072, 0.02, 0],
                    ti: [0.084, -0.026, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [105.639, 258.632, 0],
                    to: [-0.084, 0.026, 0],
                    ti: [0.095, -0.032, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [105.369, 258.719, 0],
                    to: [-0.095, 0.032, 0],
                    ti: [0.05, -0.017, 0],
                  },
                  { t: 118, s: [105.069, 258.823, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, -0.931, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 10,
                    s: [111.822, 109.374, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.087, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.414, 0.087, 0] },
                    t: 12,
                    s: [100.055, 106.422, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.812, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, -0.097, 0] },
                    t: 14,
                    s: [97.093, 40.951, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.773, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.117, 0.15, 0] },
                    t: 16,
                    s: [67.613, 71.247, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.893, 1.157, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.132, -0.129, 0] },
                    t: 18,
                    s: [79.872, 109.348, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.215, 0.987, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.381, 0.054, 0] },
                    t: 20,
                    s: [101.054, 94.428, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.06, -0.015, 0] },
                    t: 22,
                    s: [106.979, 137.485, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.974, 0.793, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.477, -0.164, 0] },
                    t: 24,
                    s: [85.798, 101.174, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.674, 1.013, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.039, 0.139, 0] },
                    t: 26,
                    s: [88.947, 113.411, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, 0.677, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.011, 0] },
                    t: 28,
                    s: [86.793, 131.596, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.583, 0.977, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.914, 0.112, 0] },
                    t: 30,
                    s: [106.377, 110.603, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.868, 0.899, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.088, -0.033, 0] },
                    t: 32,
                    s: [104.74, 50.192, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.873, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.225, 0.471, 0] },
                    t: 34,
                    s: [75.287, 93.627, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.914, 0.963, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.244, 0.557, 0] },
                    t: 36,
                    s: [57.971, 102.957, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [6.07, 1.272, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [2.53, -0.068, 0] },
                    t: 38,
                    s: [48.983, 104.599, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.801, 1.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.082, 0.064, 0] },
                    t: 40,
                    s: [48.677, 103.694, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.786, 1.017, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.143, 0.076, 0] },
                    t: 42,
                    s: [67.607, 107.546, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.136, 0.014, 0] },
                    t: 44,
                    s: [93.956, 61.633, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.898, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, -0.051, 0] },
                    t: 46,
                    s: [135.372, 117.12, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.936, 1.36, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.448, -0.923, 0] },
                    t: 48,
                    s: [94.654, 82.761, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.146, 1.103, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.276, 0.068, 0] },
                    t: 50,
                    s: [85.351, 85.606, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.778, 0.911, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, 0.046, 0] },
                    t: 52,
                    s: [87.506, 70.455, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 0.043, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.134, 1.425, 0] },
                    t: 54,
                    s: [107.432, 104.297, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.792, 1.155, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, 0.091, 0] },
                    t: 56,
                    s: [140.473, 106.399, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.967, 0.932, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.139, 0.054, 0] },
                    t: 58,
                    s: [118.147, 128.441, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.974, 1.121, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.054, -0.359, 0] },
                    t: 60,
                    s: [84.776, 65.43, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.673, 0.886, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.038, 0.049, 0] },
                    t: 62,
                    s: [105.029, 77.29, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.925, 1.408, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.112, 0.311, 0] },
                    t: 64,
                    s: [91.068, 48.266, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.647, 0.929, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.747, 0.069, 0] },
                    t: 66,
                    s: [50.228, 37.635, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.827, 0.939, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.109, -0.474, 0] },
                    t: 68,
                    s: [54.329, 100.336, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.244, 1.036, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.161, -0.223, 0] },
                    t: 70,
                    s: [67.613, 90.964, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 0.595, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, 0.025, 0] },
                    t: 72,
                    s: [81.902, 93.51, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.785, 0.829, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.101, 0.105, 0] },
                    t: 74,
                    s: [25.699, 89.858, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.976, 0.897, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.136, 0.163, 0] },
                    t: 76,
                    s: [51.156, 75.74, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.939, 1.149, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.034, 0.43, 0] },
                    t: 78,
                    s: [91.35, 60.958, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.534, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.231, 0.053, 0] },
                    t: 80,
                    s: [62.734, 57.409, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.526, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, -0.014, 0] },
                    t: 82,
                    s: [70.32, 67.319, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, 0.923, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.24, 0.101, 0] },
                    t: 84,
                    s: [14.17, 58.838, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.32, 0.689, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.419, -0.944, 0] },
                    t: 86,
                    s: [28.636, 19.113, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.869, 1.114, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.114, 0] },
                    t: 88,
                    s: [32.23, 22.336, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.092, 1.006, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.228, 0.048, 0] },
                    t: 90,
                    s: [14.851, 31.15, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.899, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, 0.005, 0] },
                    t: 92,
                    s: [4.812, 10.283, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.075, 1.195, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.472, 0.001, 0] },
                    t: 94,
                    s: [25.925, 32.587, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.799, 0.984, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.04, 0.058, 0] },
                    t: 96,
                    s: [30.453, 9.9, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.842, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.142, -0.02, 0] },
                    t: 98,
                    s: [21.826, 85.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.674, 0.929, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, -0.039, 0] },
                    t: 100,
                    s: [9.608, 24.619, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.022, 0.324, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.112, -0.462, 0] },
                    t: 102,
                    s: [-1.289, 66.096, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.018, 0.095, 0] },
                    t: 104,
                    s: [-33.054, 59.758, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 1.101, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.173, 1.876, 0] },
                    t: 106,
                    s: [7.239, 14.644, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.273, 1.364, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.683, 0.046, 0] },
                    t: 108,
                    s: [44.549, 12.547, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.913, 0.998, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 0.068, 0] },
                    t: 110,
                    s: [40.492, 17.184, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.637, 1.065, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.953, -0.003, 0] },
                    t: 112,
                    s: [9.161, -7.703, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.935, 0.991, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.079, 0.037, 0] },
                    t: 114,
                    s: [7.764, 16.458, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.3, -0.01, 0] },
                    t: 116,
                    s: [36.594, -26.534, 100],
                  },
                  { t: 118, s: [30.332, 11.798, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.9882352941176471, 0.7843137254901961,
                        0.3215686274509804, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 10,
            op: 180,
            st: 10,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 12,
            ty: 4,
            nm: 'Shape Layer 32',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 8,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 18,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 62,
                    s: [100],
                  },
                  { t: 94, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 8,
                    s: [267.904, 308.221, 0],
                    to: [-0.062, -0.384, 0],
                    ti: [-0.406, 2.013, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.982 },
                    o: { x: 0.167, y: 0.008 },
                    t: 10,
                    s: [267.529, 305.917, 0],
                    to: [0.406, -2.013, 0],
                    ti: [-2.229, 4.277, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.946 },
                    o: { x: 0.167, y: 0.039 },
                    t: 12,
                    s: [270.337, 296.143, 0],
                    to: [2.229, -4.277, 0],
                    ti: [-4.056, 5.365, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.935 },
                    o: { x: 0.167, y: 0.066 },
                    t: 14,
                    s: [280.902, 280.252, 0],
                    to: [4.056, -5.365, 0],
                    ti: [-4.394, 5.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.931 },
                    o: { x: 0.167, y: 0.081 },
                    t: 16,
                    s: [294.676, 263.955, 0],
                    to: [4.394, -5.019, 0],
                    ti: [-3.868, 4.214, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.929 },
                    o: { x: 0.167, y: 0.084 },
                    t: 18,
                    s: [307.264, 250.141, 0],
                    to: [3.868, -4.214, 0],
                    ti: [-3.257, 3.538, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.929 },
                    o: { x: 0.167, y: 0.083 },
                    t: 20,
                    s: [317.883, 238.67, 0],
                    to: [3.257, -3.538, 0],
                    ti: [-2.752, 3.047, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.931 },
                    o: { x: 0.167, y: 0.08 },
                    t: 22,
                    s: [326.809, 228.915, 0],
                    to: [2.752, -3.047, 0],
                    ti: [-2.349, 2.69, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.933 },
                    o: { x: 0.167, y: 0.075 },
                    t: 24,
                    s: [334.394, 220.39, 0],
                    to: [2.349, -2.69, 0],
                    ti: [-2.023, 2.427, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.937 },
                    o: { x: 0.167, y: 0.07 },
                    t: 26,
                    s: [340.902, 212.773, 0],
                    to: [2.023, -2.427, 0],
                    ti: [-1.753, 2.23, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.942 },
                    o: { x: 0.167, y: 0.064 },
                    t: 28,
                    s: [346.531, 205.826, 0],
                    to: [1.753, -2.23, 0],
                    ti: [-1.519, 2.072, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.947 },
                    o: { x: 0.167, y: 0.058 },
                    t: 30,
                    s: [351.417, 199.393, 0],
                    to: [1.519, -2.072, 0],
                    ti: [-1.306, 1.928, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.953 },
                    o: { x: 0.167, y: 0.052 },
                    t: 32,
                    s: [355.644, 193.397, 0],
                    to: [1.306, -1.928, 0],
                    ti: [-1.106, 1.797, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.96 },
                    o: { x: 0.167, y: 0.044 },
                    t: 34,
                    s: [359.255, 187.824, 0],
                    to: [1.106, -1.797, 0],
                    ti: [-0.913, 1.682, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.968 },
                    o: { x: 0.167, y: 0.035 },
                    t: 36,
                    s: [362.28, 182.616, 0],
                    to: [0.913, -1.682, 0],
                    ti: [-0.72, 1.579, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.977 },
                    o: { x: 0.167, y: 0.025 },
                    t: 38,
                    s: [364.731, 177.73, 0],
                    to: [0.72, -1.579, 0],
                    ti: [-0.524, 1.479, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.014 },
                    t: 40,
                    s: [366.601, 173.143, 0],
                    to: [0.524, -1.479, 0],
                    ti: [-0.321, 1.378, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.005 },
                    t: 42,
                    s: [367.872, 168.855, 0],
                    to: [0.321, -1.378, 0],
                    ti: [-0.115, 1.264, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [368.526, 164.876, 0],
                    to: [0.115, -1.264, 0],
                    ti: [0.083, 1.13, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [368.561, 161.269, 0],
                    to: [-0.083, -1.13, 0],
                    ti: [0.254, 0.978, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [368.028, 158.096, 0],
                    to: [-0.254, -0.978, 0],
                    ti: [0.382, 0.82, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [367.038, 155.4, 0],
                    to: [-0.382, -0.82, 0],
                    ti: [0.464, 0.67, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [365.735, 153.176, 0],
                    to: [-0.464, -0.67, 0],
                    ti: [0.506, 0.539, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [364.254, 151.378, 0],
                    to: [-0.506, -0.539, 0],
                    ti: [0.52, 0.43, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [362.7, 149.94, 0],
                    to: [-0.52, -0.43, 0],
                    ti: [0.517, 0.343, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [361.136, 148.795, 0],
                    to: [-0.517, -0.343, 0],
                    ti: [0.505, 0.273, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [359.597, 147.883, 0],
                    to: [-0.505, -0.273, 0],
                    ti: [0.487, 0.216, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [358.105, 147.16, 0],
                    to: [-0.487, -0.216, 0],
                    ti: [0.465, 0.169, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [356.676, 146.589, 0],
                    to: [-0.465, -0.169, 0],
                    ti: [0.441, 0.132, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [355.317, 146.143, 0],
                    to: [-0.441, -0.132, 0],
                    ti: [0.415, 0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [354.033, 145.799, 0],
                    to: [-0.415, -0.101, 0],
                    ti: [0.388, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [352.827, 145.538, 0],
                    to: [-0.388, -0.076, 0],
                    ti: [0.36, 0.056, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [351.704, 145.343, 0],
                    to: [-0.36, -0.056, 0],
                    ti: [0.33, 0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [350.668, 145.203, 0],
                    to: [-0.33, -0.04, 0],
                    ti: [0.298, 0.027, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [349.724, 145.104, 0],
                    to: [-0.298, -0.027, 0],
                    ti: [0.263, 0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [348.882, 145.038, 0],
                    to: [-0.263, -0.019, 0],
                    ti: [0.227, 0.013, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [348.144, 144.993, 0],
                    to: [-0.227, -0.013, 0],
                    ti: [0.189, 0.011, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [347.517, 144.959, 0],
                    to: [-0.189, -0.011, 0],
                    ti: [0.146, 0.011, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [347.011, 144.929, 0],
                    to: [-0.146, -0.011, 0],
                    ti: [0.1, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [346.639, 144.893, 0],
                    to: [-0.1, -0.014, 0],
                    ti: [0.056, 0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [346.413, 144.843, 0],
                    to: [-0.056, -0.022, 0],
                    ti: [0.03, 0.033, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [346.304, 144.761, 0],
                    to: [-0.03, -0.033, 0],
                    ti: [0.031, 0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [346.234, 144.648, 0],
                    to: [-0.031, -0.038, 0],
                    ti: [0.045, 0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [346.12, 144.534, 0],
                    to: [-0.045, -0.038, 0],
                    ti: [0.059, 0.037, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [345.962, 144.422, 0],
                    to: [-0.059, -0.037, 0],
                    ti: [0.072, 0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [345.764, 144.313, 0],
                    to: [-0.072, -0.036, 0],
                    ti: [0.083, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [345.53, 144.207, 0],
                    to: [-0.083, -0.034, 0],
                    ti: [0.093, 0.033, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [345.264, 144.107, 0],
                    to: [-0.093, -0.033, 0],
                    ti: [0.102, 0.031, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [344.97, 144.011, 0],
                    to: [-0.102, -0.031, 0],
                    ti: [0.11, 0.029, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [344.65, 143.922, 0],
                    to: [-0.11, -0.029, 0],
                    ti: [0.117, 0.026, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [344.308, 143.839, 0],
                    to: [-0.117, -0.026, 0],
                    ti: [0.123, 0.024, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [343.947, 143.764, 0],
                    to: [-0.123, -0.024, 0],
                    ti: [0.127, 0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [343.572, 143.695, 0],
                    to: [-0.127, -0.022, 0],
                    ti: [0.13, 0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [343.185, 143.634, 0],
                    to: [-0.13, -0.019, 0],
                    ti: [0.133, 0.017, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [342.79, 143.581, 0],
                    to: [-0.133, -0.017, 0],
                    ti: [0.067, 0.008, 0],
                  },
                  { t: 118, s: [342.389, 143.535, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.916, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 8,
                    s: [146.545, 56.981, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.984, 6.136, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [7.109, 6.416, 0] },
                    t: 10,
                    s: [102.654, 113.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.082, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.082, 0] },
                    t: 12,
                    s: [102.133, 114.538, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.769, 0.88, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, -0.024, 0] },
                    t: 14,
                    s: [115.051, 67.711, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.13, 0.272, 0] },
                    t: 16,
                    s: [89.461, 104.174, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.843, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.029, -0.825, 0] },
                    t: 18,
                    s: [44.049, 120.312, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.068, 5.668, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, -0.052, 0] },
                    t: 20,
                    s: [77.823, 118.832, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.009, 0.967, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.037, 0.082, 0] },
                    t: 22,
                    s: [107.747, 119.743, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.009, -0.054, 0] },
                    t: 24,
                    s: [53.479, 67.782, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-1.061, 0.515, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-2.064, -0.16, 0] },
                    t: 26,
                    s: [113.921, 99.393, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.981, 1.019, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.087, 0.101, 0] },
                    t: 28,
                    s: [111.576, 88.565, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.95, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.025, 0.015, 0] },
                    t: 30,
                    s: [55.899, 36.375, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.094, 1.148, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.128, 0.737, 0] },
                    t: 32,
                    s: [98.768, 100.4, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.97, 0.816, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, 0.053, 0] },
                    t: 34,
                    s: [81.873, 108.568, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.853, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.047, 0.153, 0] },
                    t: 36,
                    s: [117.879, 85.877, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.034, 0.862, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.192, -0.017, 0] },
                    t: 38,
                    s: [94.849, 58.578, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.781, 0.836, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.024, 0.211, 0] },
                    t: 40,
                    s: [77.175, 81.139, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.98, 1.271, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.135, 0.169, 0] },
                    t: 42,
                    s: [102.134, 95.906, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.835, 0.976, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.026, 0.064, 0] },
                    t: 44,
                    s: [142.654, 110.218, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.797, 0.841, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, -0.034, 0] },
                    t: 46,
                    s: [111.887, 49.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.009, 1.01, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.141, 0.175, 0] },
                    t: 48,
                    s: [81.638, 92.763, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.894, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.008, 0.009, 0] },
                    t: 50,
                    s: [38.028, 132.048, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.012, 0.82, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.143, 0.392, 0] },
                    t: 52,
                    s: [86.128, 88.194, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.088, 0.901, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.011, 0.155, 0] },
                    t: 54,
                    s: [68.431, 76.37, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.042, 1.263, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.043, 0.518, 0] },
                    t: 56,
                    s: [88.699, 62.632, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.935, 1.037, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.028, 0.063, 0] },
                    t: 58,
                    s: [46.98, 59.998, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.851, 1.103, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.298, 0.026, 0] },
                    t: 60,
                    s: [109.874, 70.958, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.881, 1.047, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.189, 0.046, 0] },
                    t: 62,
                    s: [96.114, 55.137, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.231, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.278, 0.03, 0] },
                    t: 64,
                    s: [85.214, 90.54, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.189, 0.931, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, -0.071, 0] },
                    t: 66,
                    s: [80.545, 35.132, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.94, 0.796, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, -0.412, 0] },
                    t: 68,
                    s: [98.178, 65.031, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.966, 0.606, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.209, 0.141, 0] },
                    t: 70,
                    s: [40.533, 60.003, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.059, 0.106, 0] },
                    t: 72,
                    s: [56.966, 52.732, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.747, 1.016, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.244, 0.016, 0] },
                    t: 74,
                    s: [47.335, 25.638, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.818, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.124, 0.014, 0] },
                    t: 76,
                    s: [49.783, 59.415, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.061, 0.813, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.154, 0.718, 0] },
                    t: 78,
                    s: [54.761, 18.968, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.894, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.035, 0.15, 0] },
                    t: 80,
                    s: [60.644, 13.657, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.945, 0.618, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.384, 0.034, 0] },
                    t: 82,
                    s: [50.471, 7.064, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.087, 0.107, 0] },
                    t: 84,
                    s: [47.647, 18.253, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.927, 0.88, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, -0.023, 0] },
                    t: 86,
                    s: [-15.426, 58.31, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.52, 0.892, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.604, 0.275, 0] },
                    t: 88,
                    s: [27.159, 27.049, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 0.901, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, 0.364, 0] },
                    t: 90,
                    s: [21.997, 13.425, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.588, 1.135, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.54, 0.54, 0] },
                    t: 92,
                    s: [59.347, 9.373, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.055, 1.741, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.104, 0.051, 0] },
                    t: 94,
                    s: [54.352, 8.634, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 0.829, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.033, 0.075, 0] },
                    t: 96,
                    s: [34.643, 10.569, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.881, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, 0.163, 0] },
                    t: 98,
                    s: [67.47, -8.566, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.842, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.276, -0.04, 0] },
                    t: 100,
                    s: [34.991, -28.664, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [3.321, 0.639, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.801, 0.176, 0] },
                    t: 102,
                    s: [20.908, -15.133, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.876, 0.894, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.108, 0] },
                    t: 104,
                    s: [21.531, -2.998, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.331, 0.787, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.256, 0.39, 0] },
                    t: 106,
                    s: [3.556, 37.39, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, 0.137, 0] },
                    t: 108,
                    s: [-5.112, 48.357, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.837, 0.83, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.482, -0.165, 0] },
                    t: 110,
                    s: [37.958, 65.386, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.163, 0.146, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.171, 0.163, 0] },
                    t: 112,
                    s: [40.525, 59.682, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.082, 0.927, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 0.092, 0] },
                    t: 114,
                    s: [42.972, 53.735, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, -0.57, 0] },
                    t: 116,
                    s: [65.106, -1.291, 100],
                  },
                  { t: 118, s: [21.097, 5.723, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.792156862745098, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 8,
            op: 180,
            st: 8,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 13,
            ty: 4,
            nm: 'Shape Layer 31',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 64,
                    s: [100],
                  },
                  { t: 96, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 92,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 10,
                    s: [274.616, 306.243, 0],
                    to: [-0.115, -0.59, 0],
                    ti: [0.824, 2.77, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 12,
                    s: [273.925, 302.703, 0],
                    to: [-0.824, -2.77, 0],
                    ti: [2.181, 6.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [269.674, 289.624, 0],
                    to: [-2.181, -6.093, 0],
                    ti: [3.267, 8.196, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [260.838, 266.142, 0],
                    to: [-3.267, -8.196, 0],
                    ti: [3.485, 7.964, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [250.074, 240.449, 0],
                    to: [-3.485, -7.964, 0],
                    ti: [3.198, 6.707, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [239.929, 218.357, 0],
                    to: [-3.198, -6.707, 0],
                    ti: [2.853, 5.529, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [230.887, 200.209, 0],
                    to: [-2.853, -5.529, 0],
                    ti: [2.563, 4.612, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [222.813, 185.182, 0],
                    to: [-2.563, -4.612, 0],
                    ti: [2.332, 3.906, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [215.51, 172.535, 0],
                    to: [-2.332, -3.906, 0],
                    ti: [2.146, 3.349, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [208.822, 161.746, 0],
                    to: [-2.146, -3.349, 0],
                    ti: [1.992, 2.898, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [202.636, 152.442, 0],
                    to: [-1.992, -2.898, 0],
                    ti: [1.863, 2.525, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [196.868, 144.357, 0],
                    to: [-1.863, -2.525, 0],
                    ti: [1.751, 2.21, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [191.458, 137.291, 0],
                    to: [-1.751, -2.21, 0],
                    ti: [1.653, 1.941, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [186.36, 131.094, 0],
                    to: [-1.653, -1.941, 0],
                    ti: [1.563, 1.706, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [181.542, 125.648, 0],
                    to: [-1.563, -1.706, 0],
                    ti: [1.481, 1.501, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [176.98, 120.856, 0],
                    to: [-1.481, -1.501, 0],
                    ti: [1.403, 1.32, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [172.656, 116.64, 0],
                    to: [-1.403, -1.32, 0],
                    ti: [1.328, 1.158, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [168.561, 112.936, 0],
                    to: [-1.328, -1.158, 0],
                    ti: [1.255, 1.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [164.687, 109.689, 0],
                    to: [-1.255, -1.014, 0],
                    ti: [1.183, 0.886, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [161.03, 106.849, 0],
                    to: [-1.183, -0.886, 0],
                    ti: [1.111, 0.771, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [157.59, 104.374, 0],
                    to: [-1.111, -0.771, 0],
                    ti: [1.038, 0.668, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [154.367, 102.223, 0],
                    to: [-1.038, -0.668, 0],
                    ti: [0.964, 0.577, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [151.363, 100.364, 0],
                    to: [-0.964, -0.577, 0],
                    ti: [0.888, 0.495, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [148.583, 98.763, 0],
                    to: [-0.888, -0.495, 0],
                    ti: [0.81, 0.422, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [146.035, 97.394, 0],
                    to: [-0.81, -0.422, 0],
                    ti: [0.741, 0.358, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [143.723, 96.23, 0],
                    to: [-0.741, -0.358, 0],
                    ti: [0.687, 0.303, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [141.59, 95.244, 0],
                    to: [-0.687, -0.303, 0],
                    ti: [0.639, 0.255, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [139.604, 94.413, 0],
                    to: [-0.639, -0.255, 0],
                    ti: [0.592, 0.214, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [137.759, 93.715, 0],
                    to: [-0.592, -0.214, 0],
                    ti: [0.547, 0.179, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [136.052, 93.13, 0],
                    to: [-0.547, -0.179, 0],
                    ti: [0.502, 0.149, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [134.48, 92.643, 0],
                    to: [-0.502, -0.149, 0],
                    ti: [0.46, 0.124, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [133.038, 92.238, 0],
                    to: [-0.46, -0.124, 0],
                    ti: [0.418, 0.103, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [131.723, 91.901, 0],
                    to: [-0.418, -0.103, 0],
                    ti: [0.378, 0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [130.53, 91.622, 0],
                    to: [-0.378, -0.085, 0],
                    ti: [0.339, 0.071, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [129.457, 91.39, 0],
                    to: [-0.339, -0.071, 0],
                    ti: [0.301, 0.059, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [128.499, 91.197, 0],
                    to: [-0.301, -0.059, 0],
                    ti: [0.264, 0.049, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [127.654, 91.037, 0],
                    to: [-0.264, -0.049, 0],
                    ti: [0.228, 0.041, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [126.917, 90.903, 0],
                    to: [-0.228, -0.041, 0],
                    ti: [0.193, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [126.287, 90.791, 0],
                    to: [-0.193, -0.034, 0],
                    ti: [0.158, 0.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [125.761, 90.698, 0],
                    to: [-0.158, -0.028, 0],
                    ti: [0.134, 0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [125.337, 90.622, 0],
                    to: [-0.134, -0.025, 0],
                    ti: [0.126, 0.027, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [124.96, 90.547, 0],
                    to: [-0.126, -0.027, 0],
                    ti: [0.127, 0.03, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [124.579, 90.461, 0],
                    to: [-0.127, -0.03, 0],
                    ti: [0.127, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [124.196, 90.364, 0],
                    to: [-0.127, -0.034, 0],
                    ti: [0.126, 0.037, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [123.816, 90.257, 0],
                    to: [-0.126, -0.037, 0],
                    ti: [0.124, 0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [123.44, 90.141, 0],
                    to: [-0.124, -0.04, 0],
                    ti: [0.121, 0.042, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [123.073, 90.017, 0],
                    to: [-0.121, -0.042, 0],
                    ti: [0.116, 0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [122.717, 89.887, 0],
                    to: [-0.116, -0.044, 0],
                    ti: [0.11, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [122.376, 89.751, 0],
                    to: [-0.11, -0.046, 0],
                    ti: [0.103, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [122.055, 89.613, 0],
                    to: [-0.103, -0.046, 0],
                    ti: [0.095, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [121.755, 89.474, 0],
                    to: [-0.095, -0.046, 0],
                    ti: [0.086, 0.045, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [121.483, 89.337, 0],
                    to: [-0.086, -0.045, 0],
                    ti: [0.075, 0.043, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [121.241, 89.204, 0],
                    to: [-0.075, -0.043, 0],
                    ti: [0.062, 0.041, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [121.035, 89.077, 0],
                    to: [-0.062, -0.041, 0],
                    ti: [0.028, 0.019, 0],
                  },
                  { t: 118, s: [120.867, 88.96, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.272, 0.954, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 10,
                    s: [88.213, 98.042, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.933, 0.787, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.064, -0.101, 0] },
                    t: 12,
                    s: [98.671, 49.922, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 0.883, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.333, 0.137, 0] },
                    t: 14,
                    s: [54.098, 71.655, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.856, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.111, 0.287, 0] },
                    t: 16,
                    s: [63.017, 105.433, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.962, 0.719, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.076, 0.001, 0] },
                    t: 18,
                    s: [59.185, 119.254, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.999, 1.027, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.071, 0.118, 0] },
                    t: 20,
                    s: [102.36, 105.209, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.001, 0.02, 0] },
                    t: 22,
                    s: [79.066, 71.811, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.893, -1.794, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.579, -1.652, 0] },
                    t: 24,
                    s: [101.985, 115.908, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.972, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.076, 0.086, 0] },
                    t: 26,
                    s: [105.84, 113.791, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.033, 0.698, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.042, -0.255, 0] },
                    t: 28,
                    s: [60.688, 44.915, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.04, 0.92, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.024, 0.115, 0] },
                    t: 30,
                    s: [90.735, 61.894, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, 0.271, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.027, -2.022, 0] },
                    t: 32,
                    s: [48.636, 106.533, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.029, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.867, 0.094, 0] },
                    t: 34,
                    s: [110.804, 104.766, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.309, 0.797, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.022, -0.018, 0] },
                    t: 36,
                    s: [105.35, 91.067, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.026, 0.865, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.141, 0] },
                    t: 38,
                    s: [112.726, 102.388, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 1.297, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.02, 0.217, 0] },
                    t: 40,
                    s: [78.021, 118.647, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.73, 0.94, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.493, 0.065, 0] },
                    t: 42,
                    s: [123.499, 128.748, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.684, 0.681, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.12, -0.217, 0] },
                    t: 44,
                    s: [116.924, 82.593, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 1.037, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, 0.113, 0] },
                    t: 46,
                    s: [102.179, 95.418, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.886, 0.872, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, 0.026, 0] },
                    t: 48,
                    s: [61.093, 131.691, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.995, 1.018, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.309, 0.239, 0] },
                    t: 50,
                    s: [97.755, 79.275, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.561, 0.959, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.006, 0.015, 0] },
                    t: 52,
                    s: [111.269, 51.174, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.977, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.103, -0.081, 0] },
                    t: 54,
                    s: [98.597, 85.374, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, 0.259, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.031, 0.937, 0] },
                    t: 56,
                    s: [44.555, 68.006, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.03, 1.073, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.425, 0.094, 0] },
                    t: 58,
                    s: [83.792, 66.311, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.079, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.022, 0.039, 0] },
                    t: 60,
                    s: [93.366, 52.935, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.872, 0.97, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, 0.017, 0] },
                    t: 62,
                    s: [80.351, 77.978, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.459, 0.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.24, -0.047, 0] },
                    t: 64,
                    s: [105.736, 46.617, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, 1.164, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, 1.147, 0] },
                    t: 66,
                    s: [119.231, 66.597, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.338, 0.408, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-4.839, 0.055, 0] },
                    t: 68,
                    s: [31.333, 68.163, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.923, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.089, 0.097, 0] },
                    t: 70,
                    s: [32.821, 63.516, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.316, 1.245, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.049, -0.192, 0] },
                    t: 72,
                    s: [55.229, 35.157, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.082, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.095, 0.062, 0] },
                    t: 74,
                    s: [53.579, 43.75, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.008, 0.863, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, -0.1, 0] },
                    t: 76,
                    s: [41.681, 9.938, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 1.024, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.008, 0.213, 0] },
                    t: 78,
                    s: [65.338, 25.314, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.135, 1.097, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, 0.019, 0] },
                    t: 80,
                    s: [39.293, 35.228, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.942, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.052, 0.045, 0] },
                    t: 82,
                    s: [71.909, 22.453, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 1.746, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.192, 0.562, 0] },
                    t: 84,
                    s: [-13.722, 50.113, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.78, 0.885, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, 0.075, 0] },
                    t: 86,
                    s: [12.165, 54.928, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 1.049, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.134, 0.304, 0] },
                    t: 88,
                    s: [36.047, 7.012, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.981, 0.875, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.369, 0.031, 0] },
                    t: 90,
                    s: [75.273, -11.082, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.976, 1.192, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.024, 0.248, 0] },
                    t: 92,
                    s: [68.05, 17.747, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.142, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.033, 0.058, 0] },
                    t: 94,
                    s: [73.648, 32.314, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.915, 0.932, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, 0.553, 0] },
                    t: 96,
                    s: [69.641, -15.771, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.919, -0.285, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [4.378, -0.361, 0] },
                    t: 98,
                    s: [32.387, -24.312, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [216.664, 0.822, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-3.24, 0.089, 0] },
                    t: 100,
                    s: [31.664, -22.708, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.876, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.083, 0.156, 0] },
                    t: 102,
                    s: [31.682, 0.419, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.616, 0.585, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.235, 0.252, 0] },
                    t: 104,
                    s: [-15.245, 26.807, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.106, 0.104, 0] },
                    t: 106,
                    s: [-2.957, 39.841, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.946, 0.871, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.581, -0.016, 0] },
                    t: 108,
                    s: [41.396, 91.664, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.754, 0.858, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.15, 0.236, 0] },
                    t: 110,
                    s: [48.828, 48.327, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.928, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.075, 0.203, 0] },
                    t: 112,
                    s: [46.178, 24.721, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.871, 0.408, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, -0.524, 0] },
                    t: 114,
                    s: [72.828, 8.231, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.235, 0.097, 0] },
                    t: 116,
                    s: [23.424, 10.493, 100],
                  },
                  { t: 118, s: [-3.769, 24.296, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 10,
            op: 180,
            st: 10,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 14,
            ty: 4,
            nm: 'Shape Layer 19',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 22,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 32,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 76,
                    s: [100],
                  },
                  { t: 108, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.528 },
                    o: { x: 0.167, y: 0.161 },
                    t: 22,
                    s: [273.717, 253.598, 0],
                    to: [0.28, -0.028, 0],
                    ti: [-1.664, -0.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.761 },
                    o: { x: 0.167, y: 0.1 },
                    t: 24,
                    s: [275.395, 253.428, 0],
                    to: [1.664, 0.082, 0],
                    ti: [-3.922, -0.767, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.835 },
                    o: { x: 0.167, y: 0.119 },
                    t: 26,
                    s: [283.7, 254.091, 0],
                    to: [3.922, 0.767, 0],
                    ti: [-5.158, -1.975, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.876 },
                    o: { x: 0.167, y: 0.126 },
                    t: 28,
                    s: [298.929, 258.033, 0],
                    to: [5.158, 1.975, 0],
                    ti: [-4.6, -2.946, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.909 },
                    o: { x: 0.167, y: 0.106 },
                    t: 30,
                    s: [314.649, 265.939, 0],
                    to: [4.6, 2.946, 0],
                    ti: [-3.328, -3.296, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.939 },
                    o: { x: 0.167, y: 0.072 },
                    t: 32,
                    s: [326.529, 275.709, 0],
                    to: [3.328, 3.296, 0],
                    ti: [-2.233, -3.252, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.963 },
                    o: { x: 0.167, y: 0.043 },
                    t: 34,
                    s: [334.618, 285.714, 0],
                    to: [2.233, 3.252, 0],
                    ti: [-1.455, -3.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.024 },
                    t: 36,
                    s: [339.929, 295.223, 0],
                    to: [1.455, 3.046, 0],
                    ti: [-0.931, -2.79, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.012 },
                    t: 38,
                    s: [343.35, 303.988, 0],
                    to: [0.931, 2.79, 0],
                    ti: [-0.582, -2.533, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.995 },
                    o: { x: 0.167, y: 0.006 },
                    t: 40,
                    s: [345.513, 311.964, 0],
                    to: [0.582, 2.533, 0],
                    ti: [-0.353, -2.293, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.003 },
                    t: 42,
                    s: [346.843, 319.188, 0],
                    to: [0.353, 2.293, 0],
                    ti: [-0.205, -2.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 44,
                    s: [347.63, 325.723, 0],
                    to: [0.205, 2.077, 0],
                    ti: [-0.115, -1.887, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [348.075, 331.651, 0],
                    to: [0.115, 1.887, 0],
                    ti: [-0.064, -1.719, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [348.319, 337.046, 0],
                    to: [0.064, 1.719, 0],
                    ti: [-0.041, -1.573, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [348.461, 341.968, 0],
                    to: [0.041, 1.573, 0],
                    ti: [-0.028, -1.452, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [348.564, 346.482, 0],
                    to: [0.028, 1.452, 0],
                    ti: [-0.019, -1.351, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [348.628, 350.68, 0],
                    to: [0.019, 1.351, 0],
                    ti: [-0.016, -1.256, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [348.676, 354.586, 0],
                    to: [0.016, 1.256, 0],
                    ti: [-0.018, -1.167, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [348.724, 358.218, 0],
                    to: [0.018, 1.167, 0],
                    ti: [-0.022, -1.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [348.783, 361.589, 0],
                    to: [0.022, 1.082, 0],
                    ti: [-0.029, -1, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [348.858, 364.71, 0],
                    to: [0.029, 1, 0],
                    ti: [-0.036, -0.921, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [348.955, 367.59, 0],
                    to: [0.036, 0.921, 0],
                    ti: [-0.043, -0.844, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 66,
                    s: [349.073, 370.236, 0],
                    to: [0.043, 0.844, 0],
                    ti: [-0.05, -0.768, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 68,
                    s: [349.212, 372.652, 0],
                    to: [0.05, 0.768, 0],
                    ti: [-0.056, -0.694, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 70,
                    s: [349.372, 374.845, 0],
                    to: [0.056, 0.694, 0],
                    ti: [-0.062, -0.622, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 72,
                    s: [349.55, 376.819, 0],
                    to: [0.062, 0.622, 0],
                    ti: [-0.067, -0.551, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 74,
                    s: [349.743, 378.577, 0],
                    to: [0.067, 0.551, 0],
                    ti: [-0.07, -0.481, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.004 },
                    t: 76,
                    s: [349.95, 380.124, 0],
                    to: [0.07, 0.481, 0],
                    ti: [-0.073, -0.413, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.007 },
                    t: 78,
                    s: [350.166, 381.464, 0],
                    to: [0.073, 0.413, 0],
                    ti: [-0.075, -0.348, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.992 },
                    o: { x: 0.167, y: 0.01 },
                    t: 80,
                    s: [350.389, 382.603, 0],
                    to: [0.075, 0.348, 0],
                    ti: [-0.075, -0.284, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.015 },
                    t: 82,
                    s: [350.614, 383.549, 0],
                    to: [0.075, 0.284, 0],
                    ti: [-0.075, -0.222, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.981 },
                    o: { x: 0.167, y: 0.024 },
                    t: 84,
                    s: [350.84, 384.306, 0],
                    to: [0.075, 0.222, 0],
                    ti: [-0.073, -0.163, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.968 },
                    o: { x: 0.167, y: 0.044 },
                    t: 86,
                    s: [351.062, 384.882, 0],
                    to: [0.073, 0.163, 0],
                    ti: [-0.071, -0.106, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.939 },
                    o: { x: 0.167, y: 0.088 },
                    t: 88,
                    s: [351.279, 385.285, 0],
                    to: [0.071, 0.106, 0],
                    ti: [-0.068, -0.053, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.867 },
                    o: { x: 0.167, y: 0.17 },
                    t: 90,
                    s: [351.487, 385.521, 0],
                    to: [0.068, 0.053, 0],
                    ti: [-0.064, -0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.835 },
                    o: { x: 0.167, y: 0.148 },
                    t: 92,
                    s: [351.685, 385.601, 0],
                    to: [0.064, 0.002, 0],
                    ti: [-0.059, 0.045, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.919 },
                    o: { x: 0.167, y: 0.06 },
                    t: 94,
                    s: [351.87, 385.535, 0],
                    to: [0.059, -0.045, 0],
                    ti: [-0.054, 0.088, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.965 },
                    o: { x: 0.167, y: 0.027 },
                    t: 96,
                    s: [352.04, 385.333, 0],
                    to: [0.054, -0.088, 0],
                    ti: [-0.049, 0.127, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.984 },
                    o: { x: 0.167, y: 0.013 },
                    t: 98,
                    s: [352.195, 385.007, 0],
                    to: [0.049, -0.127, 0],
                    ti: [-0.043, 0.161, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.992 },
                    o: { x: 0.167, y: 0.007 },
                    t: 100,
                    s: [352.331, 384.571, 0],
                    to: [0.043, -0.161, 0],
                    ti: [-0.035, 0.185, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.004 },
                    t: 102,
                    s: [352.45, 384.039, 0],
                    to: [0.035, -0.185, 0],
                    ti: [-0.024, 0.192, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 104,
                    s: [352.541, 383.461, 0],
                    to: [0.024, -0.192, 0],
                    ti: [-0.013, 0.188, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [352.596, 382.887, 0],
                    to: [0.013, -0.188, 0],
                    ti: [-0.001, 0.179, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [352.617, 382.333, 0],
                    to: [0.001, -0.179, 0],
                    ti: [0.007, 0.165, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [352.603, 381.814, 0],
                    to: [-0.007, -0.165, 0],
                    ti: [0.003, 0.149, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [352.574, 381.345, 0],
                    to: [-0.003, -0.149, 0],
                    ti: [-0.011, 0.133, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 114,
                    s: [352.587, 380.922, 0],
                    to: [0.011, -0.133, 0],
                    ti: [-0.023, 0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.011 },
                    t: 116,
                    s: [352.639, 380.546, 0],
                    to: [0.023, -0.117, 0],
                    ti: [-0.014, 0.055, 0],
                  },
                  { t: 118, s: [352.724, 380.217, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.45, -1.622, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 22,
                    s: [64.676, 29.575, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.851, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.079, 0.086, 0] },
                    t: 24,
                    s: [63.619, 31.654, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.096, 0.849, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.189, -0.115, 0] },
                    t: 26,
                    s: [83.082, 94.984, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.044, 1.052, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.045, 0.186, 0] },
                    t: 28,
                    s: [98.428, 68.346, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.791, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.029, 0.032, 0] },
                    t: 30,
                    s: [65.326, 46.692, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.846, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.117, 0.139, 0] },
                    t: 32,
                    s: [115.976, 81.786, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.191, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.181, -0.001, 0] },
                    t: 34,
                    s: [94.867, 134.538, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 1.096, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, 0.505, 0] },
                    t: 36,
                    s: [76.907, 82.463, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.592, 0.774, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.576, 0.045, 0] },
                    t: 38,
                    s: [136.087, 72.163, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.95, 0.874, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.132, 0] },
                    t: 40,
                    s: [146.093, 94.38, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 1.06, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.126, 0.245, 0] },
                    t: 42,
                    s: [64.998, 132.461, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.163, 0.877, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, 0.035, 0] },
                    t: 44,
                    s: [97.241, 152.104, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.936, 0.874, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, 0.259, 0] },
                    t: 46,
                    s: [126.81, 118.253, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.87, 0.449, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.273, 0.246, 0] },
                    t: 48,
                    s: [39.419, 102.155, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.013, 0.912, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.231, 0.098, 0] },
                    t: 50,
                    s: [59.845, 93.89, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.068, 2.263, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.011, 1.504, 0] },
                    t: 52,
                    s: [71.34, 47.538, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.87, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.037, 0.078, 0] },
                    t: 54,
                    s: [58.098, 44.818, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.848, 0.781, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.234, -0.119, 0] },
                    t: 56,
                    s: [82.135, 88.74, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.37, 1.069, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.185, 0.135, 0] },
                    t: 58,
                    s: [95.452, 70.689, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 0.946, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.068, 0.038, 0] },
                    t: 60,
                    s: [106.399, 41.339, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.931, 0.784, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, -0.156, 0] },
                    t: 62,
                    s: [46.837, 95.066, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.989, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.417, 0.136, 0] },
                    t: 64,
                    s: [100.241, 76.371, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.202, 0.986, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.224, -0.013, 0] },
                    t: 66,
                    s: [91.348, 46.624, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.962, 0.96, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.059, -0.017, 0] },
                    t: 68,
                    s: [86.082, 72.273, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.193, 0.684, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.069, -0.076, 0] },
                    t: 70,
                    s: [104.113, 51.031, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.964, 0.99, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, 0.113, 0] },
                    t: 72,
                    s: [94.281, 62.125, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.717, 0.85, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.063, -0.012, 0] },
                    t: 74,
                    s: [126.942, 93.089, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.99, 0.934, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, 0.187, 0] },
                    t: 76,
                    s: [108.331, 65.944, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.969, 0.494, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.012, -0.318, 0] },
                    t: 78,
                    s: [63.723, 44.138, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.903, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.049, 0.1, 0] },
                    t: 80,
                    s: [102.921, 48.671, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.464, 0.113, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.147, 0.604, 0] },
                    t: 82,
                    s: [78.268, 71.671, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.902, 1.068, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, 0.092, 0] },
                    t: 84,
                    s: [87.176, 75.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.234, 0.908, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.554, 0.037, 0] },
                    t: 86,
                    s: [28.705, 110.854, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.845, 1.441, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, 0.901, 0] },
                    t: 88,
                    s: [18.357, 46.477, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.091, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.181, 0.07, 0] },
                    t: 90,
                    s: [57.758, 39.913, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 0.801, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, -0.023, 0] },
                    t: 92,
                    s: [91.489, 81.222, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.953, 0.932, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.137, 0.143, 0] },
                    t: 94,
                    s: [20.878, 48.853, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.976, 0.994, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.108, -0.379, 0] },
                    t: 96,
                    s: [47.568, 3.824, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.831, 1.042, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.035, -0.006, 0] },
                    t: 98,
                    s: [35.966, 11.947, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.412, 0.592, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.165, 0.028, 0] },
                    t: 100,
                    s: [44.168, 4.41, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.937, 0.978, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.105, 0] },
                    t: 102,
                    s: [52.575, 15.778, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.454, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.254, -0.03, 0] },
                    t: 104,
                    s: [2.557, 60.137, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.959, 1.407, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, -0.624, 0] },
                    t: 106,
                    s: [14.899, 27.604, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.849, 1.066, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.082, 0.069, 0] },
                    t: 108,
                    s: [83.445, 31.436, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.046, 0.934, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.186, 0.037, 0] },
                    t: 110,
                    s: [48.803, 8.908, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.963, 0.694, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.03, -0.323, 0] },
                    t: 112,
                    s: [20.805, 49.183, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.959, 0.832, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.065, 0.115, 0] },
                    t: 114,
                    s: [64.315, 40.919, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.079, 0.165, 0] },
                    t: 116,
                    s: [39.92, 18.864, 100],
                  },
                  { t: 118, s: [52.411, -3.555, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.43529411764705883, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 22,
            op: 180,
            st: 22,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 15,
            ty: 4,
            nm: 'Shape Layer 18',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 34,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 78,
                    s: [100],
                  },
                  { t: 110, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [267.865, 255.269, 0],
                    to: [-0.161, -0.402, 0],
                    ti: [1.026, 2.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [266.901, 252.858, 0],
                    to: [-1.026, -2.138, 0],
                    ti: [2.899, 4.739, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [261.706, 242.443, 0],
                    to: [-2.899, -4.739, 0],
                    ti: [4.94, 5.799, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [249.506, 224.427, 0],
                    to: [-4.94, -5.799, 0],
                    ti: [5.963, 4.5, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [232.069, 207.647, 0],
                    to: [-5.963, -4.5, 0],
                    ti: [5.888, 2.374, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [213.727, 197.425, 0],
                    to: [-5.888, -2.374, 0],
                    ti: [5.276, 0.563, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [196.743, 193.401, 0],
                    to: [-5.276, -0.563, 0],
                    ti: [4.452, -0.741, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [182.074, 194.046, 0],
                    to: [-4.452, 0.741, 0],
                    ti: [3.589, -1.577, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [170.029, 197.845, 0],
                    to: [-3.589, 1.577, 0],
                    ti: [2.794, -2.037, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [160.537, 203.508, 0],
                    to: [-2.794, 2.037, 0],
                    ti: [2.125, -2.229, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [153.265, 210.066, 0],
                    to: [-2.125, 2.229, 0],
                    ti: [1.595, -2.253, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [147.784, 216.885, 0],
                    to: [-1.595, 2.253, 0],
                    ti: [1.188, -2.18, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [143.692, 223.586, 0],
                    to: [-1.188, 2.18, 0],
                    ti: [0.88, -2.056, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [140.657, 229.963, 0],
                    to: [-0.88, 2.056, 0],
                    ti: [0.65, -1.912, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [138.413, 235.924, 0],
                    to: [-0.65, 1.912, 0],
                    ti: [0.477, -1.76, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [136.76, 241.434, 0],
                    to: [-0.477, 1.76, 0],
                    ti: [0.347, -1.607, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [135.553, 246.481, 0],
                    to: [-0.347, 1.607, 0],
                    ti: [0.25, -1.458, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [134.678, 251.075, 0],
                    to: [-0.25, 1.458, 0],
                    ti: [0.178, -1.316, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [134.053, 255.23, 0],
                    to: [-0.178, 1.316, 0],
                    ti: [0.125, -1.182, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [133.611, 258.969, 0],
                    to: [-0.125, 1.182, 0],
                    ti: [0.088, -1.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [133.302, 262.321, 0],
                    to: [-0.088, 1.057, 0],
                    ti: [0.062, -0.942, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [133.085, 265.313, 0],
                    to: [-0.062, 0.942, 0],
                    ti: [0.045, -0.835, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [132.931, 267.971, 0],
                    to: [-0.045, 0.835, 0],
                    ti: [0.035, -0.737, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [132.816, 270.322, 0],
                    to: [-0.035, 0.737, 0],
                    ti: [0.031, -0.648, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [132.72, 272.392, 0],
                    to: [-0.031, 0.648, 0],
                    ti: [0.031, -0.567, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [132.63, 274.209, 0],
                    to: [-0.031, 0.567, 0],
                    ti: [0.035, -0.496, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [132.533, 275.797, 0],
                    to: [-0.035, 0.496, 0],
                    ti: [0.04, -0.432, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [132.422, 277.182, 0],
                    to: [-0.04, 0.432, 0],
                    ti: [0.043, -0.377, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [132.291, 278.392, 0],
                    to: [-0.043, 0.377, 0],
                    ti: [0.036, -0.328, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [132.166, 279.447, 0],
                    to: [-0.036, 0.328, 0],
                    ti: [0.026, -0.282, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [132.075, 280.359, 0],
                    to: [-0.026, 0.282, 0],
                    ti: [0.017, -0.24, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [132.011, 281.139, 0],
                    to: [-0.017, 0.24, 0],
                    ti: [0.01, -0.201, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [131.972, 281.799, 0],
                    to: [-0.01, 0.201, 0],
                    ti: [0.003, -0.166, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [131.954, 282.347, 0],
                    to: [-0.003, 0.166, 0],
                    ti: [-0.002, -0.133, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [131.953, 282.792, 0],
                    to: [0.002, 0.133, 0],
                    ti: [-0.007, -0.102, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 94,
                    s: [131.967, 283.142, 0],
                    to: [0.007, 0.102, 0],
                    ti: [-0.011, -0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.009 },
                    t: 96,
                    s: [131.994, 283.403, 0],
                    to: [0.011, 0.073, 0],
                    ti: [-0.014, -0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.975 },
                    o: { x: 0.167, y: 0.042 },
                    t: 98,
                    s: [132.031, 283.58, 0],
                    to: [0.014, 0.046, 0],
                    ti: [-0.017, -0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.175 },
                    t: 100,
                    s: [132.078, 283.677, 0],
                    to: [0.017, 0.02, 0],
                    ti: [-0.019, 0.004, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.89 },
                    o: { x: 0.167, y: 0.089 },
                    t: 102,
                    s: [132.132, 283.7, 0],
                    to: [0.019, -0.004, 0],
                    ti: [-0.021, 0.02, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.925 },
                    o: { x: 0.167, y: 0.074 },
                    t: 104,
                    s: [132.193, 283.651, 0],
                    to: [0.021, -0.02, 0],
                    ti: [-0.023, 0.018, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.876 },
                    o: { x: 0.167, y: 0.137 },
                    t: 106,
                    s: [132.259, 283.583, 0],
                    to: [0.023, -0.018, 0],
                    ti: [-0.025, 0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.834 },
                    o: { x: 0.167, y: 0.168 },
                    t: 108,
                    s: [132.331, 283.544, 0],
                    to: [0.025, -0.008, 0],
                    ti: [-0.026, 0, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.832 },
                    o: { x: 0.167, y: 0.16 },
                    t: 110,
                    s: [132.408, 283.533, 0],
                    to: [0.026, 0, 0],
                    ti: [-0.027, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.135 },
                    t: 112,
                    s: [132.487, 283.545, 0],
                    to: [0.027, 0.008, 0],
                    ti: [-0.027, -0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.877 },
                    o: { x: 0.167, y: 0.114 },
                    t: 114,
                    s: [132.568, 283.579, 0],
                    to: [0.027, 0.014, 0],
                    ti: [-0.027, -0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.9 },
                    o: { x: 0.167, y: 0.096 },
                    t: 116,
                    s: [132.649, 283.63, 0],
                    to: [0.027, 0.019, 0],
                    ti: [-0.013, -0.011, 0],
                  },
                  { t: 118, s: [132.728, 283.695, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.085, 2.343, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 24,
                    s: [95.923, 97.698, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.959, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.042, 0.078, 0] },
                    t: 26,
                    s: [119.853, 100.809, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.846, 0.754, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.082, -0.083, 0] },
                    t: 28,
                    s: [71.61, 47.548, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.108, 0.987, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.182, 0.126, 0] },
                    t: 30,
                    s: [95.909, 74.187, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.971, 0.88, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, -0.015, 0] },
                    t: 32,
                    s: [116.529, 126.213, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.765, 1.081, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.046, 0.272, 0] },
                    t: 34,
                    s: [69.282, 82.296, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.014, 0.826, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.129, 0.041, 0] },
                    t: 36,
                    s: [99.804, 62.944, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.925, 0.917, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.012, 0.16, 0] },
                    t: 38,
                    s: [155.455, 101.204, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.797, -29.815, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.787, 623.527, 0] },
                    t: 40,
                    s: [90.335, 142.892, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.16, 1.74, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.141, 0.084, 0] },
                    t: 42,
                    s: [96.569, 142.898, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.834, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, 0.075, 0] },
                    t: 44,
                    s: [105.516, 144.953, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.994, 0.309, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, 0.547, 0] },
                    t: 46,
                    s: [79.375, 124.659, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.849, 0.77, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.007, 0.095, 0] },
                    t: 48,
                    s: [53.599, 121.011, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.006, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.187, 0.131, 0] },
                    t: 50,
                    s: [77.44, 94.418, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 0.812, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.006, -0.044, 0] },
                    t: 52,
                    s: [96.658, 47.588, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.869, 1.081, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, 0.15, 0] },
                    t: 54,
                    s: [75.964, 78.236, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.159, 0.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.23, 0.041, 0] },
                    t: 56,
                    s: [94.431, 116.596, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, 0.82, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, -0.058, 0] },
                    t: 58,
                    s: [104.908, 41.132, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-2.162, 1.012, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-4.52, 0.155, 0] },
                    t: 60,
                    s: [74.497, 85.65, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.955, 0.956, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.086, 0.011, 0] },
                    t: 62,
                    s: [75.047, 137.348, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 1.056, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.097, -0.092, 0] },
                    t: 64,
                    s: [95.389, 78.111, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [3.564, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.248, 0.034, 0] },
                    t: 66,
                    s: [85.987, 106.338, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.138, 0.755, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, -0.133, 0] },
                    t: 68,
                    s: [86.576, 59.064, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.052, 0.126, 0] },
                    t: 70,
                    s: [67.887, 77.283, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.048, 1.699, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.951, 0.69, 0] },
                    t: 72,
                    s: [117.559, 112.652, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.968, 0.892, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.091, 0.074, 0] },
                    t: 74,
                    s: [115.524, 117.508, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.868, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.052, 0.361, 0] },
                    t: 76,
                    s: [94.322, 71.923, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.911, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 0.226, 0] },
                    t: 78,
                    s: [107.41, 58.262, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-1.313, 0.689, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.225, -0.022, 0] },
                    t: 80,
                    s: [89.918, 50.26, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.885, 0.965, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.086, 0.114, 0] },
                    t: 82,
                    s: [88.641, 56.58, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.486, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.303, -0.061, 0] },
                    t: 84,
                    s: [54.485, 73.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.806, 1.029, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 0.099, 0] },
                    t: 86,
                    s: [41.537, 63.86, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.052, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.146, 0.021, 0] },
                    t: 88,
                    s: [58.89, 12.33, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 1.279, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.032, 0.455, 0] },
                    t: 90,
                    s: [81.867, 81.726, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.858, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.543, 0.064, 0] },
                    t: 92,
                    s: [44.5, 97.298, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.018, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.202, 0.656, 0] },
                    t: 94,
                    s: [49.475, 29.648, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.415, -65.878, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.015, 34.698, 0] },
                    t: 96,
                    s: [52.978, 19.812, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.126, 1.189, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.083, 0] },
                    t: 98,
                    s: [48.737, 19.789, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.939, 0.939, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.05, 0.058, 0] },
                    t: 100,
                    s: [74.095, 0.809, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.788, 1.031, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.229, -0.233, 0] },
                    t: 102,
                    s: [10.268, 62.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.913, 1.141, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.137, 0.023, 0] },
                    t: 104,
                    s: [27.318, 46.475, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [3.588, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [2.212, 0.052, 0] },
                    t: 106,
                    s: [53.611, 68.98, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.93, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, -0.069, 0] },
                    t: 108,
                    s: [54.64, 8.452, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.974, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.428, -0.05, 0] },
                    t: 110,
                    s: [21.647, 41.626, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.031, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.037, -0.251, 0] },
                    t: 112,
                    s: [27.025, 20.873, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.011, 0.234, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.023, 0.727, 0] },
                    t: 114,
                    s: [23.296, 26.051, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.01, 0.093, 0] },
                    t: 116,
                    s: [28.415, 26.722, 100],
                  },
                  { t: 118, s: [22.608, 32.219, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.3803921568627451, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 24,
            op: 180,
            st: 24,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 16,
            ty: 4,
            nm: 'Shape Layer 17',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 16,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 26,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 70,
                    s: [100],
                  },
                  { t: 102, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 0.659 },
                    o: { x: 0.167, y: 0.153 },
                    t: 16,
                    s: [275.449, 259.557, 0],
                    to: [0.546, 0.159, 0],
                    ti: [-2.494, -0.483, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.773 },
                    o: { x: 0.167, y: 0.105 },
                    t: 18,
                    s: [278.724, 260.512, 0],
                    to: [2.494, 0.483, 0],
                    ti: [-5.49, -0.572, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.827 },
                    o: { x: 0.167, y: 0.13 },
                    t: 20,
                    s: [290.414, 262.453, 0],
                    to: [5.49, 0.572, 0],
                    ti: [-7.452, 0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.157 },
                    t: 22,
                    s: [311.663, 263.943, 0],
                    to: [7.452, -0.063, 0],
                    ti: [-7.172, 1.285, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.86 },
                    o: { x: 0.167, y: 0.164 },
                    t: 24,
                    s: [335.126, 262.075, 0],
                    to: [7.172, -1.285, 0],
                    ti: [-5.684, 2.429, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.888 },
                    o: { x: 0.167, y: 0.134 },
                    t: 26,
                    s: [354.697, 256.231, 0],
                    to: [5.684, -2.429, 0],
                    ti: [-4.084, 3.144, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.924 },
                    o: { x: 0.167, y: 0.09 },
                    t: 28,
                    s: [369.23, 247.5, 0],
                    to: [4.084, -3.144, 0],
                    ti: [-2.734, 3.404, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.956 },
                    o: { x: 0.167, y: 0.051 },
                    t: 30,
                    s: [379.199, 237.368, 0],
                    to: [2.734, -3.404, 0],
                    ti: [-1.736, 3.345, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.978 },
                    o: { x: 0.167, y: 0.025 },
                    t: 32,
                    s: [385.634, 227.077, 0],
                    to: [1.736, -3.345, 0],
                    ti: [-1.056, 3.132, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.011 },
                    t: 34,
                    s: [389.616, 217.298, 0],
                    to: [1.056, -3.132, 0],
                    ti: [-0.604, 2.868, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.004 },
                    t: 36,
                    s: [391.967, 208.287, 0],
                    to: [0.604, -2.868, 0],
                    ti: [-0.305, 2.608, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.999 },
                    o: { x: 0.167, y: 0.001 },
                    t: 38,
                    s: [393.24, 200.09, 0],
                    to: [0.305, -2.608, 0],
                    ti: [-0.105, 2.371, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [393.797, 192.641, 0],
                    to: [0.105, -2.371, 0],
                    ti: [0.031, 2.158, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [393.871, 185.865, 0],
                    to: [-0.031, -2.158, 0],
                    ti: [0.123, 1.968, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [393.614, 179.691, 0],
                    to: [-0.123, -1.968, 0],
                    ti: [0.186, 1.797, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [393.131, 174.057, 0],
                    to: [-0.186, -1.797, 0],
                    ti: [0.228, 1.644, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [392.497, 168.908, 0],
                    to: [-0.228, -1.644, 0],
                    ti: [0.251, 1.504, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [391.765, 164.196, 0],
                    to: [-0.251, -1.504, 0],
                    ti: [0.261, 1.377, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [390.988, 159.882, 0],
                    to: [-0.261, -1.377, 0],
                    ti: [0.262, 1.261, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [390.199, 155.932, 0],
                    to: [-0.262, -1.261, 0],
                    ti: [0.256, 1.155, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [389.418, 152.313, 0],
                    to: [-0.256, -1.155, 0],
                    ti: [0.246, 1.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [388.662, 149, 0],
                    to: [-0.246, -1.057, 0],
                    ti: [0.233, 0.967, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [387.941, 145.969, 0],
                    to: [-0.233, -0.967, 0],
                    ti: [0.217, 0.882, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [387.264, 143.201, 0],
                    to: [-0.217, -0.882, 0],
                    ti: [0.199, 0.803, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [386.64, 140.677, 0],
                    to: [-0.199, -0.803, 0],
                    ti: [0.18, 0.729, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [386.071, 138.382, 0],
                    to: [-0.18, -0.729, 0],
                    ti: [0.159, 0.659, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [385.563, 136.303, 0],
                    to: [-0.159, -0.659, 0],
                    ti: [0.138, 0.594, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [385.116, 134.426, 0],
                    to: [-0.138, -0.594, 0],
                    ti: [0.117, 0.531, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [384.732, 132.741, 0],
                    to: [-0.117, -0.531, 0],
                    ti: [0.096, 0.473, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [384.411, 131.237, 0],
                    to: [-0.096, -0.473, 0],
                    ti: [0.076, 0.417, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [384.153, 129.905, 0],
                    to: [-0.076, -0.417, 0],
                    ti: [0.055, 0.364, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [383.957, 128.736, 0],
                    to: [-0.055, -0.364, 0],
                    ti: [0.036, 0.314, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [383.821, 127.721, 0],
                    to: [-0.036, -0.314, 0],
                    ti: [0.017, 0.266, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [383.742, 126.852, 0],
                    to: [-0.017, -0.266, 0],
                    ti: [0, 0.222, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [383.718, 126.122, 0],
                    to: [0, -0.222, 0],
                    ti: [-0.017, 0.179, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.004 },
                    t: 86,
                    s: [383.745, 125.523, 0],
                    to: [0.017, -0.179, 0],
                    ti: [-0.032, 0.139, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.986 },
                    o: { x: 0.167, y: 0.018 },
                    t: 88,
                    s: [383.819, 125.048, 0],
                    to: [0.032, -0.139, 0],
                    ti: [-0.046, 0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.958 },
                    o: { x: 0.167, y: 0.054 },
                    t: 90,
                    s: [383.937, 124.69, 0],
                    to: [0.046, -0.101, 0],
                    ti: [-0.058, 0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.9 },
                    o: { x: 0.167, y: 0.115 },
                    t: 92,
                    s: [384.093, 124.442, 0],
                    to: [0.058, -0.066, 0],
                    ti: [-0.068, 0.032, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.163 },
                    t: 94,
                    s: [384.283, 124.297, 0],
                    to: [0.068, -0.032, 0],
                    ti: [-0.074, 0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.838 },
                    o: { x: 0.167, y: 0.166 },
                    t: 96,
                    s: [384.5, 124.247, 0],
                    to: [0.074, -0.009, 0],
                    ti: [-0.073, 0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.172 },
                    t: 98,
                    s: [384.725, 124.244, 0],
                    to: [0.073, -0.002, 0],
                    ti: [-0.068, 0.003, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.174 },
                    t: 100,
                    s: [384.937, 124.237, 0],
                    to: [0.068, -0.003, 0],
                    ti: [-0.061, 0.006, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.174 },
                    t: 102,
                    s: [385.131, 124.223, 0],
                    to: [0.061, -0.006, 0],
                    ti: [-0.052, 0.011, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.86 },
                    o: { x: 0.167, y: 0.168 },
                    t: 104,
                    s: [385.302, 124.198, 0],
                    to: [0.052, -0.011, 0],
                    ti: [-0.041, 0.017, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.885 },
                    o: { x: 0.167, y: 0.136 },
                    t: 106,
                    s: [385.442, 124.158, 0],
                    to: [0.041, -0.017, 0],
                    ti: [-0.028, 0.024, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.936 },
                    o: { x: 0.167, y: 0.071 },
                    t: 108,
                    s: [385.546, 124.097, 0],
                    to: [0.028, -0.024, 0],
                    ti: [-0.026, 0.021, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.137 },
                    t: 110,
                    s: [385.612, 124.016, 0],
                    to: [0.026, -0.021, 0],
                    ti: [-0.034, 0.006, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.817 },
                    o: { x: 0.167, y: 0.151 },
                    t: 112,
                    s: [385.701, 123.974, 0],
                    to: [0.034, -0.006, 0],
                    ti: [-0.043, -0.01, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.129 },
                    t: 114,
                    s: [385.818, 123.981, 0],
                    to: [0.043, 0.01, 0],
                    ti: [-0.048, -0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.881 },
                    o: { x: 0.167, y: 0.109 },
                    t: 116,
                    s: [385.957, 124.035, 0],
                    to: [0.048, 0.025, 0],
                    ti: [-0.025, -0.016, 0],
                  },
                  { t: 118, s: [386.107, 124.13, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.944, 0.679, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 16,
                    s: [49.219, 103.811, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.111, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.166, 0.113, 0] },
                    t: 18,
                    s: [96.918, 92.8, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.903, 29.641, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.048, 19.517, 0] },
                    t: 20,
                    s: [80.991, 61.448, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.606, 1.004, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.607, 0.083, 0] },
                    t: 22,
                    s: [118.185, 61.313, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.989, 0.964, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.004, 0] },
                    t: 24,
                    s: [124.101, 107.653, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.917, 0.896, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.013, -0.062, 0] },
                    t: 26,
                    s: [75.181, 59.182, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-66.49, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-62.917, 0.413, 0] },
                    t: 28,
                    s: [117.68, 86.992, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.946, 0.5, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.083, -0.053, 0] },
                    t: 30,
                    s: [117.624, 94.022, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.649, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.154, 0.1, 0] },
                    t: 32,
                    s: [72.153, 89.729, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.994, 0.616, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.109, -0.07, 0] },
                    t: 34,
                    s: [88.106, 68.252, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.006, 0.106, 0] },
                    t: 36,
                    s: [139.441, 79.894, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.246, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.681, -0.189, 0] },
                    t: 38,
                    s: [91.59, 121.922, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.031, 1.095, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, -0.001, 0] },
                    t: 40,
                    s: [96.806, 109.051, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.909, 0.978, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.023, 0.044, 0] },
                    t: 42,
                    s: [76.174, 121.699, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.265, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.035, -0.029, 0] },
                    t: 44,
                    s: [104.516, 94.655, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.009, 0.92, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.078, -0.118, 0] },
                    t: 46,
                    s: [106.997, 114.689, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, 7.262, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.008, -1.803, 0] },
                    t: 48,
                    s: [66.837, 106.412, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-2.408, 1.035, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-4.987, 0.082, 0] },
                    t: 50,
                    s: [111.464, 106.778, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.975, 1.046, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, 0.025, 0] },
                    t: 52,
                    s: [110.731, 78.932, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.884, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.036, 0.03, 0] },
                    t: 54,
                    s: [81.464, 118.575, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.215, 0.686, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.293, -0.158, 0] },
                    t: 56,
                    s: [101.82, 56.948, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.963, 0.967, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.06, 0.113, 0] },
                    t: 58,
                    s: [109.895, 78.255, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.916, 0.979, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.066, -0.056, 0] },
                    t: 60,
                    s: [80.942, 137.249, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-6.827, 1.076, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [12.816, -0.028, 0] },
                    t: 62,
                    s: [97.117, 101.933, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.453, 0.923, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.084, 0.04, 0] },
                    t: 64,
                    s: [97.223, 128.384, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.989, 0.619, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, -0.935, 0] },
                    t: 66,
                    s: [107.061, 77.679, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.924, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.013, 0.107, 0] },
                    t: 68,
                    s: [43.764, 81.828, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, -1.177, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.143, -0.844, 0] },
                    t: 70,
                    s: [98.569, 96.663, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.435, 0.919, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-2.31, 0.087, 0] },
                    t: 72,
                    s: [78.363, 95.331, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.931, 2.861, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.079, -3.479, 0] },
                    t: 74,
                    s: [79.067, 61.85, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.227, 0.912, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.407, 0.08, 0] },
                    t: 76,
                    s: [66.248, 62.633, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.092, -2.126, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 1.726, 0] },
                    t: 78,
                    s: [68.428, 44.364, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.901, 1.019, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, 0.086, 0] },
                    t: 80,
                    s: [86.481, 43.437, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.779, 1.036, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.541, 0.015, 0] },
                    t: 82,
                    s: [48.571, 9.591, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.776, 1.009, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.134, 0.025, 0] },
                    t: 84,
                    s: [41.665, 51.149, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.092, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.133, 0.008, 0] },
                    t: 86,
                    s: [30.243, -8.194, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.935, 9.657, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, 20.432, 0] },
                    t: 88,
                    s: [10.976, 57.43, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.606, 0.758, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.287, 0.083, 0] },
                    t: 90,
                    s: [51.537, 57.699, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.918, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.106, 0.127, 0] },
                    t: 92,
                    s: [42.412, 29.51, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-8.496, 0.903, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-5.253, -0.014, 0] },
                    t: 94,
                    s: [8.401, -24.232, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.961, 0.669, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.084, 0.582, 0] },
                    t: 96,
                    s: [8.932, 21.756, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.956, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.072, 0.111, 0] },
                    t: 98,
                    s: [68.92, 29.447, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.049, 0.445, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.094, 1.927, 0] },
                    t: 100,
                    s: [36.715, 52.274, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.899, 1.631, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.031, 0.098, 0] },
                    t: 102,
                    s: [51.861, 53.306, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.136, 0.908, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.481, 0.074, 0] },
                    t: 104,
                    s: [27.738, 59.151, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.062, 0.322, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.052, 0.928, 0] },
                    t: 106,
                    s: [22.685, 9.056, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.882, 0.979, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.035, 0.095, 0] },
                    t: 108,
                    s: [35.966, 4.115, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.812, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.285, -0.029, 0] },
                    t: 110,
                    s: [12.831, -31.117, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.846, 0.873, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 0.15, 0] },
                    t: 112,
                    s: [3.287, -4.957, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.815, 0.964, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.182, 0.241, 0] },
                    t: 114,
                    s: [16.066, 27.774, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.152, -0.063, 0] },
                    t: 116,
                    s: [26.832, 45.045, 100],
                  },
                  { t: 118, s: [39.927, 35.232, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'sr',
                    sy: 2,
                    d: 1,
                    pt: { a: 0, k: 3, ix: 3 },
                    p: { a: 0, k: [6, 32], ix: 4 },
                    r: { a: 0, k: 0, ix: 5 },
                    or: { a: 0, k: 6.599, ix: 7 },
                    os: { a: 0, k: 0, ix: 9 },
                    ix: 1,
                    nm: 'Polystar Path 1',
                    mn: 'ADBE Vector Shape - Star',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.43529411764705883, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-7.382, -25.677], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Polystar 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 16,
            op: 180,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 17,
            ty: 4,
            nm: 'Shape Layer 14',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 68,
                    s: [100],
                  },
                  { t: 100, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [276.873, 250.83, 0],
                    to: [-0.189, -0.223, 0],
                    ti: [1.057, 0.986, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [275.739, 249.492, 0],
                    to: [-1.057, -0.986, 0],
                    ti: [2.736, 1.782, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [270.529, 244.915, 0],
                    to: [-2.736, -1.782, 0],
                    ti: [4.156, 1.618, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [259.324, 238.803, 0],
                    to: [-4.156, -1.618, 0],
                    ti: [4.345, 0.759, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [245.595, 235.21, 0],
                    to: [-4.345, -0.759, 0],
                    ti: [3.769, 0.09, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [233.251, 234.251, 0],
                    to: [-3.769, -0.09, 0],
                    ti: [3.138, -0.25, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [222.982, 234.672, 0],
                    to: [-3.138, 0.25, 0],
                    ti: [2.634, -0.412, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [214.421, 235.749, 0],
                    to: [-2.634, 0.412, 0],
                    ti: [2.24, -0.491, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [207.179, 237.146, 0],
                    to: [-2.24, 0.491, 0],
                    ti: [1.931, -0.529, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [200.979, 238.697, 0],
                    to: [-1.931, 0.529, 0],
                    ti: [1.683, -0.547, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [195.596, 240.321, 0],
                    to: [-1.683, 0.547, 0],
                    ti: [1.48, -0.555, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [190.881, 241.977, 0],
                    to: [-1.48, 0.555, 0],
                    ti: [1.309, -0.559, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [186.716, 243.649, 0],
                    to: [-1.309, 0.559, 0],
                    ti: [1.16, -0.566, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [183.025, 245.334, 0],
                    to: [-1.16, 0.566, 0],
                    ti: [1.025, -0.581, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [179.755, 247.045, 0],
                    to: [-1.025, 0.581, 0],
                    ti: [0.884, -0.616, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [176.877, 248.822, 0],
                    to: [-0.884, 0.616, 0],
                    ti: [0.678, -0.697, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [174.448, 250.742, 0],
                    to: [-0.678, 0.697, 0],
                    ti: [0.181, -0.747, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.991 },
                    o: { x: 0.167, y: 0.01 },
                    t: 48,
                    s: [172.806, 253.001, 0],
                    to: [-0.181, 0.747, 0],
                    ti: [-0.395, -0.538, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.883 },
                    o: { x: 0.167, y: 0.132 },
                    t: 50,
                    s: [173.36, 255.224, 0],
                    to: [0.395, 0.538, 0],
                    ti: [-0.6, -0.241, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.168 },
                    t: 52,
                    s: [175.177, 256.227, 0],
                    to: [0.6, 0.241, 0],
                    ti: [-0.564, -0.106, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.176 },
                    t: 54,
                    s: [176.961, 256.669, 0],
                    to: [0.564, 0.106, 0],
                    ti: [-0.502, -0.043, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.844 },
                    o: { x: 0.167, y: 0.179 },
                    t: 56,
                    s: [178.564, 256.865, 0],
                    to: [0.502, 0.043, 0],
                    ti: [-0.439, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.179 },
                    t: 58,
                    s: [179.975, 256.928, 0],
                    to: [0.439, 0.008, 0],
                    ti: [-0.38, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.18 },
                    t: 60,
                    s: [181.2, 256.911, 0],
                    to: [0.38, -0.014, 0],
                    ti: [-0.325, 0.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.848 },
                    o: { x: 0.167, y: 0.179 },
                    t: 62,
                    s: [182.253, 256.843, 0],
                    to: [0.325, -0.028, 0],
                    ti: [-0.275, 0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.851 },
                    o: { x: 0.167, y: 0.178 },
                    t: 64,
                    s: [183.148, 256.743, 0],
                    to: [0.275, -0.036, 0],
                    ti: [-0.23, 0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.176 },
                    t: 66,
                    s: [183.9, 256.626, 0],
                    to: [0.23, -0.04, 0],
                    ti: [-0.19, 0.041, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.174 },
                    t: 68,
                    s: [184.526, 256.502, 0],
                    to: [0.19, -0.041, 0],
                    ti: [-0.155, 0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.861 },
                    o: { x: 0.167, y: 0.171 },
                    t: 70,
                    s: [185.04, 256.378, 0],
                    to: [0.155, -0.04, 0],
                    ti: [-0.125, 0.037, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.863 },
                    o: { x: 0.167, y: 0.171 },
                    t: 72,
                    s: [185.458, 256.261, 0],
                    to: [0.125, -0.037, 0],
                    ti: [-0.1, 0.031, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.861 },
                    o: { x: 0.167, y: 0.171 },
                    t: 74,
                    s: [185.791, 256.158, 0],
                    to: [0.1, -0.031, 0],
                    ti: [-0.082, 0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.175 },
                    t: 76,
                    s: [186.06, 256.075, 0],
                    to: [0.082, -0.022, 0],
                    ti: [-0.073, 0.001, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.832 },
                    o: { x: 0.167, y: 0.162 },
                    t: 78,
                    s: [186.284, 256.027, 0],
                    to: [0.073, -0.001, 0],
                    ti: [-0.069, -0.03, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.874 },
                    o: { x: 0.167, y: 0.108 },
                    t: 80,
                    s: [186.495, 256.07, 0],
                    to: [0.069, 0.03, 0],
                    ti: [-0.067, -0.059, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.918 },
                    o: { x: 0.167, y: 0.069 },
                    t: 82,
                    s: [186.699, 256.205, 0],
                    to: [0.067, 0.059, 0],
                    ti: [-0.065, -0.084, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.944 },
                    o: { x: 0.167, y: 0.048 },
                    t: 84,
                    s: [186.897, 256.422, 0],
                    to: [0.065, 0.084, 0],
                    ti: [-0.063, -0.106, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.961 },
                    o: { x: 0.167, y: 0.035 },
                    t: 86,
                    s: [187.09, 256.71, 0],
                    to: [0.063, 0.106, 0],
                    ti: [-0.062, -0.125, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.97 },
                    o: { x: 0.167, y: 0.027 },
                    t: 88,
                    s: [187.278, 257.059, 0],
                    to: [0.062, 0.125, 0],
                    ti: [-0.059, -0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.977 },
                    o: { x: 0.167, y: 0.022 },
                    t: 90,
                    s: [187.46, 257.46, 0],
                    to: [0.059, 0.141, 0],
                    ti: [-0.057, -0.153, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.981 },
                    o: { x: 0.167, y: 0.018 },
                    t: 92,
                    s: [187.635, 257.904, 0],
                    to: [0.057, 0.153, 0],
                    ti: [-0.059, -0.163, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.02 },
                    t: 94,
                    s: [187.802, 258.381, 0],
                    to: [0.059, 0.163, 0],
                    ti: [-0.07, -0.168, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.971 },
                    o: { x: 0.167, y: 0.028 },
                    t: 96,
                    s: [187.988, 258.88, 0],
                    to: [0.07, 0.168, 0],
                    ti: [-0.084, -0.171, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.963 },
                    o: { x: 0.167, y: 0.035 },
                    t: 98,
                    s: [188.22, 259.391, 0],
                    to: [0.084, 0.171, 0],
                    ti: [-0.097, -0.171, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.956 },
                    o: { x: 0.167, y: 0.044 },
                    t: 100,
                    s: [188.492, 259.907, 0],
                    to: [0.097, 0.171, 0],
                    ti: [-0.108, -0.169, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.948 },
                    o: { x: 0.167, y: 0.051 },
                    t: 102,
                    s: [188.799, 260.419, 0],
                    to: [0.108, 0.169, 0],
                    ti: [-0.117, -0.165, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.941 },
                    o: { x: 0.167, y: 0.059 },
                    t: 104,
                    s: [189.138, 260.923, 0],
                    to: [0.117, 0.165, 0],
                    ti: [-0.125, -0.159, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.933 },
                    o: { x: 0.167, y: 0.067 },
                    t: 106,
                    s: [189.502, 261.41, 0],
                    to: [0.125, 0.159, 0],
                    ti: [-0.13, -0.151, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.926 },
                    o: { x: 0.167, y: 0.076 },
                    t: 108,
                    s: [189.886, 261.876, 0],
                    to: [0.13, 0.151, 0],
                    ti: [-0.134, -0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.919 },
                    o: { x: 0.167, y: 0.084 },
                    t: 110,
                    s: [190.284, 262.315, 0],
                    to: [0.134, 0.141, 0],
                    ti: [-0.135, -0.13, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.911 },
                    o: { x: 0.167, y: 0.093 },
                    t: 112,
                    s: [190.69, 262.722, 0],
                    to: [0.135, 0.13, 0],
                    ti: [-0.135, -0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.904 },
                    o: { x: 0.167, y: 0.103 },
                    t: 114,
                    s: [191.097, 263.093, 0],
                    to: [0.135, 0.117, 0],
                    ti: [-0.132, -0.103, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.891 },
                    o: { x: 0.167, y: 0.112 },
                    t: 116,
                    s: [191.499, 263.423, 0],
                    to: [0.132, 0.103, 0],
                    ti: [-0.065, -0.048, 0],
                  },
                  { t: 118, s: [191.888, 263.709, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 0.541, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 14,
                    s: [98.652, 70.754, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.848, 0.518, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, 0.102, 0] },
                    t: 16,
                    s: [52.407, 72.577, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.779, 0.997, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.184, 0.101, 0] },
                    t: 18,
                    s: [83.757, 80.783, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 0.867, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.134, -0.003, 0] },
                    t: 20,
                    s: [109.776, 120.093, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.877, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.028, 0.222, 0] },
                    t: 22,
                    s: [152.905, 82.158, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.679, 0.626, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.257, -0.12, 0] },
                    t: 24,
                    s: [120.66, 59.419, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.91, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.113, 0.107, 0] },
                    t: 26,
                    s: [105.207, 68.721, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.545, 0.804, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.14, -0.083, 0] },
                    t: 28,
                    s: [61.164, 101.207, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.937, 1.003, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, 0.145, 0] },
                    t: 30,
                    s: [57.69, 84.913, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.643, 0.859, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.256, 0.003, 0] },
                    t: 32,
                    s: [83.888, 62.79, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.821, 0.798, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.109, 0.204, 0] },
                    t: 34,
                    s: [77.451, 85.785, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.087, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.156, 0.142, 0] },
                    t: 36,
                    s: [56.288, 101.636, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 1.927, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.042, 0.454, 0] },
                    t: 38,
                    s: [32.111, 124.298, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.091, 0.877, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.178, 0.076, 0] },
                    t: 40,
                    s: [81.428, 129.386, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.009, 0.967, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.043, 0.259, 0] },
                    t: 42,
                    s: [65.685, 67.723, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.955, 0.799, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.008, -0.054, 0] },
                    t: 44,
                    s: [98.593, 38.482, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.732, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.096, 0.142, 0] },
                    t: 46,
                    s: [62.324, 56.222, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.936, 0.271, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.546, 0] },
                    t: 48,
                    s: [79.166, 81.278, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.932, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.276, 0.094, 0] },
                    t: 50,
                    s: [116.554, 85.792, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.592, 0.744, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.377, -0.236, 0] },
                    t: 52,
                    s: [107.89, 120.793, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.083, 0.766, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.124, 0] },
                    t: 54,
                    s: [109.46, 111.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.998, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.042, 0.129, 0] },
                    t: 56,
                    s: [96.752, 92.8, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.958, 0.687, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.002, -0.162, 0] },
                    t: 58,
                    s: [122.109, 58.72, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.192, 0.866, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.084, 0.114, 0] },
                    t: 60,
                    s: [97.302, 70.284, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.964, 1.117, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, 0.219, 0] },
                    t: 62,
                    s: [109.621, 102.162, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 0.962, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.063, 0.049, 0] },
                    t: 64,
                    s: [68.943, 121.676, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.514, 0.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.428, -0.071, 0] },
                    t: 66,
                    s: [92.184, 74.87, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.944, 0.654, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, -0.144, 0] },
                    t: 68,
                    s: [97.803, 100.145, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.734, 0.804, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.175, 0.11, 0] },
                    t: 70,
                    s: [57.518, 90.885, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.006, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.145, 0] },
                    t: 72,
                    s: [70.533, 61.657, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.907, 0.638, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.006, -0.187, 0] },
                    t: 74,
                    s: [99.011, 22.279, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.045, 0.875, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.773, 0.108, 0] },
                    t: 76,
                    s: [68.391, 34.404, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.907, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.029, 0.249, 0] },
                    t: 78,
                    s: [64.692, 74.939, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [8.194, 0.523, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.82, -0.051, 0] },
                    t: 80,
                    s: [70.385, 95.308, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.944, 0.92, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.082, 0.101, 0] },
                    t: 82,
                    s: [71.029, 82.647, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.654, 0.166, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.168, -2.039, 0] },
                    t: 84,
                    s: [14.793, 22.846, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.026, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.11, 0.093, 0] },
                    t: 86,
                    s: [33.445, 25.195, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.908, 1.479, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.02, 0.754, 0] },
                    t: 88,
                    s: [92.281, 46.336, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.981, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.884, 0.071, 0] },
                    t: 90,
                    s: [15.049, 48.962, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.761, 1.318, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.024, -0.118, 0] },
                    t: 92,
                    s: [7.007, 31.245, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.054, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.128, 0.066, 0] },
                    t: 94,
                    s: [13.248, 38.566, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.957, 1.045, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.033, -0.241, 0] },
                    t: 96,
                    s: [24.888, 3.264, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.6, 1.009, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.09, 0.029, 0] },
                    t: 98,
                    s: [5.738, 12.341, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.956, 0.936, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.105, 0.008, 0] },
                    t: 100,
                    s: [14.963, -1.634, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.639, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.091, -0.275, 0] },
                    t: 102,
                    s: [50.015, 13.874, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.185, 1.318, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.46, 0.108, 0] },
                    t: 104,
                    s: [33.27, 10.27, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.869, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.078, 0.066, 0] },
                    t: 106,
                    s: [35.84, -1.726, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.164, 0.842, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.228, -0.049, 0] },
                    t: 108,
                    s: [-3.278, 56.039, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 1.064, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, 0.177, 0] },
                    t: 110,
                    s: [-25.859, 19.601, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.938, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.139, 0.036, 0] },
                    t: 112,
                    s: [41.265, -12.971, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.325, 0.731, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.118, -0.247, 0] },
                    t: 114,
                    s: [16.064, 44.564, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.121, 0] },
                    t: 116,
                    s: [26.47, 30.06, 100],
                  },
                  { t: 118, s: [-24.557, -2.189, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.3254901960784314, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 14,
            op: 180,
            st: 14,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 18,
            ty: 4,
            nm: 'Shape Layer 13',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 30,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 74,
                    s: [100],
                  },
                  { t: 106, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [278.346, 255.953, 0],
                    to: [-0.206, -0.234, 0],
                    ti: [0.991, 1.248, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [277.109, 254.547, 0],
                    to: [-0.991, -1.248, 0],
                    ti: [0.533, 2.822, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.002 },
                    t: 24,
                    s: [272.4, 248.463, 0],
                    to: [-0.533, -2.822, 0],
                    ti: [-2.874, 1.607, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.849 },
                    o: { x: 0.167, y: 0.139 },
                    t: 26,
                    s: [273.909, 237.613, 0],
                    to: [2.874, -1.607, 0],
                    ti: [-4.877, -0.657, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.172 },
                    t: 28,
                    s: [289.644, 238.823, 0],
                    to: [4.877, 0.657, 0],
                    ti: [-4.118, -0.868, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.176 },
                    t: 30,
                    s: [303.171, 241.554, 0],
                    to: [4.118, 0.868, 0],
                    ti: [-3.424, -0.757, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.175 },
                    t: 32,
                    s: [314.351, 244.031, 0],
                    to: [3.424, 0.757, 0],
                    ti: [-2.889, -0.625, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.851 },
                    o: { x: 0.167, y: 0.173 },
                    t: 34,
                    s: [323.717, 246.095, 0],
                    to: [2.889, 0.625, 0],
                    ti: [-2.477, -0.51, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.174 },
                    t: 36,
                    s: [331.687, 247.782, 0],
                    to: [2.477, 0.51, 0],
                    ti: [-2.154, -0.414, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.849 },
                    o: { x: 0.167, y: 0.173 },
                    t: 38,
                    s: [338.58, 249.155, 0],
                    to: [2.154, 0.414, 0],
                    ti: [-1.892, -0.333, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.174 },
                    t: 40,
                    s: [344.611, 250.265, 0],
                    to: [1.892, 0.333, 0],
                    ti: [-1.675, -0.265, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.173 },
                    t: 42,
                    s: [349.933, 251.155, 0],
                    to: [1.675, 0.265, 0],
                    ti: [-1.493, -0.207, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.843 },
                    o: { x: 0.167, y: 0.175 },
                    t: 44,
                    s: [354.663, 251.856, 0],
                    to: [1.493, 0.207, 0],
                    ti: [-1.344, -0.162, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.174 },
                    t: 46,
                    s: [358.894, 252.395, 0],
                    to: [1.344, 0.162, 0],
                    ti: [-1.227, -0.138, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.172 },
                    t: 48,
                    s: [362.724, 252.827, 0],
                    to: [1.227, 0.138, 0],
                    ti: [-1.133, -0.127, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 50,
                    s: [366.255, 253.222, 0],
                    to: [1.133, 0.127, 0],
                    ti: [-1.051, -0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 52,
                    s: [369.523, 253.587, 0],
                    to: [1.051, 0.117, 0],
                    ti: [-0.978, -0.109, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 54,
                    s: [372.561, 253.926, 0],
                    to: [0.978, 0.109, 0],
                    ti: [-0.912, -0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 56,
                    s: [375.391, 254.24, 0],
                    to: [0.912, 0.101, 0],
                    ti: [-0.852, -0.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 58,
                    s: [378.033, 254.529, 0],
                    to: [0.852, 0.093, 0],
                    ti: [-0.796, -0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.17 },
                    t: 60,
                    s: [380.502, 254.795, 0],
                    to: [0.796, 0.085, 0],
                    ti: [-0.745, -0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.17 },
                    t: 62,
                    s: [382.812, 255.037, 0],
                    to: [0.745, 0.076, 0],
                    ti: [-0.697, -0.068, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.17 },
                    t: 64,
                    s: [384.972, 255.253, 0],
                    to: [0.697, 0.068, 0],
                    ti: [-0.651, -0.059, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.171 },
                    t: 66,
                    s: [386.993, 255.443, 0],
                    to: [0.651, 0.059, 0],
                    ti: [-0.608, -0.05, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.839 },
                    o: { x: 0.167, y: 0.171 },
                    t: 68,
                    s: [388.881, 255.606, 0],
                    to: [0.608, 0.05, 0],
                    ti: [-0.567, -0.04, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.839 },
                    o: { x: 0.167, y: 0.173 },
                    t: 70,
                    s: [390.642, 255.741, 0],
                    to: [0.567, 0.04, 0],
                    ti: [-0.527, -0.03, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.173 },
                    t: 72,
                    s: [392.281, 255.845, 0],
                    to: [0.527, 0.03, 0],
                    ti: [-0.488, -0.019, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.174 },
                    t: 74,
                    s: [393.801, 255.919, 0],
                    to: [0.488, 0.019, 0],
                    ti: [-0.45, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.174 },
                    t: 76,
                    s: [395.207, 255.96, 0],
                    to: [0.45, 0.008, 0],
                    ti: [-0.412, 0.002, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.175 },
                    t: 78,
                    s: [396.499, 255.969, 0],
                    to: [0.412, -0.002, 0],
                    ti: [-0.375, 0.014, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.842 },
                    o: { x: 0.167, y: 0.175 },
                    t: 80,
                    s: [397.679, 255.945, 0],
                    to: [0.375, -0.014, 0],
                    ti: [-0.338, 0.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.174 },
                    t: 82,
                    s: [398.748, 255.888, 0],
                    to: [0.338, -0.025, 0],
                    ti: [-0.301, 0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.847 },
                    o: { x: 0.167, y: 0.173 },
                    t: 84,
                    s: [399.706, 255.797, 0],
                    to: [0.301, -0.036, 0],
                    ti: [-0.264, 0.047, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.17 },
                    t: 86,
                    s: [400.554, 255.673, 0],
                    to: [0.264, -0.047, 0],
                    ti: [-0.227, 0.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.859 },
                    o: { x: 0.167, y: 0.165 },
                    t: 88,
                    s: [401.291, 255.517, 0],
                    to: [0.227, -0.057, 0],
                    ti: [-0.189, 0.067, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.87 },
                    o: { x: 0.167, y: 0.153 },
                    t: 90,
                    s: [401.915, 255.33, 0],
                    to: [0.189, -0.067, 0],
                    ti: [-0.151, 0.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.89 },
                    o: { x: 0.167, y: 0.133 },
                    t: 92,
                    s: [402.427, 255.112, 0],
                    to: [0.151, -0.077, 0],
                    ti: [-0.113, 0.086, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.92 },
                    o: { x: 0.167, y: 0.094 },
                    t: 94,
                    s: [402.824, 254.867, 0],
                    to: [0.113, -0.086, 0],
                    ti: [-0.074, 0.094, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.963 },
                    o: { x: 0.167, y: 0.041 },
                    t: 96,
                    s: [403.104, 254.596, 0],
                    to: [0.074, -0.094, 0],
                    ti: [-0.034, 0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.003 },
                    t: 98,
                    s: [403.266, 254.302, 0],
                    to: [0.034, -0.101, 0],
                    ti: [0.002, 0.108, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [403.307, 253.989, 0],
                    to: [-0.002, -0.108, 0],
                    ti: [0.028, 0.114, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [403.256, 253.656, 0],
                    to: [-0.028, -0.114, 0],
                    ti: [0.05, 0.119, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [403.14, 253.307, 0],
                    to: [-0.05, -0.119, 0],
                    ti: [0.071, 0.117, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [402.953, 252.945, 0],
                    to: [-0.071, -0.117, 0],
                    ti: [0.081, 0.103, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [402.716, 252.603, 0],
                    to: [-0.081, -0.103, 0],
                    ti: [0.084, 0.081, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [402.468, 252.328, 0],
                    to: [-0.084, -0.081, 0],
                    ti: [0.087, 0.059, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [402.212, 252.118, 0],
                    to: [-0.087, -0.059, 0],
                    ti: [0.089, 0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [401.947, 251.973, 0],
                    to: [-0.089, -0.038, 0],
                    ti: [0.091, 0.018, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [401.676, 251.89, 0],
                    to: [-0.091, -0.018, 0],
                    ti: [0.046, 0.004, 0],
                  },
                  { t: 118, s: [401.399, 251.868, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.884, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 20,
                    s: [150.023, 84.302, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.718, 0.778, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.295, -0.001, 0] },
                    t: 22,
                    s: [115.536, 94.772, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.873, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, 0.133, 0] },
                    t: 24,
                    s: [101.947, 84.391, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.035, 1.291, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.243, 0.465, 0] },
                    t: 26,
                    s: [69.48, 67.085, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.989, 0.813, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, 0.065, 0] },
                    t: 28,
                    s: [52.556, 63.303, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.856, 0.84, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.012, 0.15, 0] },
                    t: 30,
                    s: [76.674, 80.31, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.805, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.197, 0.174, 0] },
                    t: 32,
                    s: [55.698, 101.414, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.152, 1.045, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.145, -0.098, 0] },
                    t: 34,
                    s: [40.385, 120.878, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 1.219, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.054, 0.029, 0] },
                    t: 36,
                    s: [19.82, 111.938, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.016, 0.871, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.102, 0.06, 0] },
                    t: 38,
                    s: [78.004, 125.754, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.949, 0.912, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.013, 0.234, 0] },
                    t: 40,
                    s: [51.803, 75.606, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.062, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.135, 1.443, 0] },
                    t: 42,
                    s: [82.948, 47.853, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.885, -0.218, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.035, -0.097, 0] },
                    t: 44,
                    s: [71.038, 46.152, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.784, 0.414, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.302, 0.089, 0] },
                    t: 46,
                    s: [91.761, 46.937, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 0.919, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.136, 0.097, 0] },
                    t: 48,
                    s: [99.655, 57.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.883, 0.788, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.133, -3.004, 0] },
                    t: 50,
                    s: [112.222, 122.076, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [4.25, 0.248, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.289, 0.137, 0] },
                    t: 52,
                    s: [111.361, 120.336, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.223, 0.834, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.081, 0.094, 0] },
                    t: 54,
                    s: [111.012, 117.65, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.926, 1.003, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, 0.167, 0] },
                    t: 56,
                    s: [124.959, 96.101, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.488, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.648, 0.003, 0] },
                    t: 58,
                    s: [73.624, 74.745, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.933, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, -0.053, 0] },
                    t: 60,
                    s: [79.474, 96.927, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.44, 0.823, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.337, -0.086, 0] },
                    t: 62,
                    s: [39.363, 83.33, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.955, 0.354, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, 0.158, 0] },
                    t: 64,
                    s: [47.32, 90.031, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.968, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.099, 0.096, 0] },
                    t: 66,
                    s: [92.847, 97.529, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.719, 0.771, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.051, -0.044, 0] },
                    t: 68,
                    s: [72.017, 148.186, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.036, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.118, 0.131, 0] },
                    t: 70,
                    s: [84.935, 114.918, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.917, 1.042, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, -0.658, 0] },
                    t: 72,
                    s: [115.633, 56.769, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-3.33, 1.406, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-14.6, 0.028, 0] },
                    t: 74,
                    s: [71.733, 63.301, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.842, 0.897, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, 0.069, 0] },
                    t: 76,
                    s: [71.982, 53.5, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.296, 1.262, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, 0.435, 0] },
                    t: 78,
                    s: [84.68, 111.004, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.873, 0.865, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.065, 0.063, 0] },
                    t: 80,
                    s: [96.026, 124.642, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.082, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.241, 0.218, 0] },
                    t: 82,
                    s: [44.359, 68.117, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 1.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, 1.94, 0] },
                    t: 84,
                    s: [16.99, 33.186, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.848, 0.777, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.028, 0.077, 0] },
                    t: 86,
                    s: [71.31, 31.618, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.963, 1.037, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.185, 0.133, 0] },
                    t: 88,
                    s: [30.563, 51.009, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.958, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.066, 0.026, 0] },
                    t: 90,
                    s: [-2.843, 83.398, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.188, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.084, 0.448, 0] },
                    t: 92,
                    s: [15.771, 36.624, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, -0.025, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, -0.252, 0] },
                    t: 94,
                    s: [6.523, 25.933, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.308, 1.025, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.823, 0.091, 0] },
                    t: 96,
                    s: [36.606, 28.591, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.857, 0.867, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.066, 0.019, 0] },
                    t: 98,
                    s: [33.84, 58.624, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.022, 0.96, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.199, 0.224, 0] },
                    t: 100,
                    s: [46.823, 19.638, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.667, 0.763, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.018, -0.078, 0] },
                    t: 102,
                    s: [56.147, -3.469, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.868, 0.839, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.111, 0.129, 0] },
                    t: 104,
                    s: [44.338, 8.441, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 1.087, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.227, 0.172, 0] },
                    t: 106,
                    s: [8.892, 30.372, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.016, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.043, 0] },
                    t: 108,
                    s: [-11.629, 50.878, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.012, 0.823, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.013, -0.049, 0] },
                    t: 110,
                    s: [26.385, 8.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 1.146, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.011, 0.158, 0] },
                    t: 112,
                    s: [-18.969, 35.342, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.128, 0.932, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.244, 0.053, 0] },
                    t: 114,
                    s: [33.051, 65.059, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.051, -0.359, 0] },
                    t: 116,
                    s: [19.792, -16.798, 100],
                  },
                  { t: 118, s: [53.472, -1.378, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.792156862745098, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 20,
            op: 180,
            st: 20,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 19,
            ty: 4,
            nm: 'Shape Layer 12',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 22,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 32,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 76,
                    s: [100],
                  },
                  { t: 108, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [278.886, 263.108, 0],
                    to: [-0.396, 0.093, 0],
                    ti: [2.046, 0.295, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [276.51, 263.668, 0],
                    to: [-2.046, -0.295, 0],
                    ti: [4.357, 2.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [266.607, 261.339, 0],
                    to: [-4.357, -2.028, 0],
                    ti: [5.491, 3.808, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [250.369, 251.499, 0],
                    to: [-5.491, -3.808, 0],
                    ti: [5.141, 4.15, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [233.659, 238.49, 0],
                    to: [-5.141, -4.15, 0],
                    ti: [4.303, 3.645, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [219.52, 226.601, 0],
                    to: [-4.303, -3.645, 0],
                    ti: [3.589, 3.053, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [207.84, 216.618, 0],
                    to: [-3.589, -3.053, 0],
                    ti: [3.064, 2.559, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [197.984, 208.283, 0],
                    to: [-3.064, -2.559, 0],
                    ti: [2.675, 2.164, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [189.458, 201.262, 0],
                    to: [-2.675, -2.164, 0],
                    ti: [2.381, 1.843, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [181.933, 195.3, 0],
                    to: [-2.381, -1.843, 0],
                    ti: [2.153, 1.575, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [175.17, 190.206, 0],
                    to: [-2.153, -1.575, 0],
                    ti: [1.971, 1.344, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [169.014, 185.852, 0],
                    to: [-1.971, -1.344, 0],
                    ti: [1.824, 1.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [163.346, 182.143, 0],
                    to: [-1.824, -1.14, 0],
                    ti: [1.703, 0.954, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [158.073, 179.012, 0],
                    to: [-1.703, -0.954, 0],
                    ti: [1.601, 0.778, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [153.127, 176.419, 0],
                    to: [-1.601, -0.778, 0],
                    ti: [1.512, 0.605, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [148.464, 174.344, 0],
                    to: [-1.512, -0.605, 0],
                    ti: [1.427, 0.431, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [144.054, 172.787, 0],
                    to: [-1.427, -0.431, 0],
                    ti: [1.341, 0.252, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [139.899, 171.759, 0],
                    to: [-1.341, -0.252, 0],
                    ti: [1.243, 0.072, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [136.007, 171.274, 0],
                    to: [-1.243, -0.072, 0],
                    ti: [1.123, -0.099, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [132.443, 171.328, 0],
                    to: [-1.123, 0.099, 0],
                    ti: [0.987, -0.242, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [129.266, 171.868, 0],
                    to: [-0.987, 0.242, 0],
                    ti: [0.844, -0.342, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [126.522, 172.779, 0],
                    to: [-0.844, 0.342, 0],
                    ti: [0.71, -0.397, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [124.202, 173.921, 0],
                    to: [-0.71, 0.397, 0],
                    ti: [0.594, -0.415, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [122.26, 175.163, 0],
                    to: [-0.594, 0.415, 0],
                    ti: [0.499, -0.407, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [120.635, 176.409, 0],
                    to: [-0.499, 0.407, 0],
                    ti: [0.424, -0.386, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [119.263, 177.604, 0],
                    to: [-0.424, 0.386, 0],
                    ti: [0.362, -0.359, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [118.093, 178.724, 0],
                    to: [-0.362, 0.359, 0],
                    ti: [0.312, -0.329, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [117.089, 179.757, 0],
                    to: [-0.312, 0.329, 0],
                    ti: [0.271, -0.298, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [116.22, 180.697, 0],
                    to: [-0.271, 0.298, 0],
                    ti: [0.237, -0.267, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [115.461, 181.543, 0],
                    to: [-0.237, 0.267, 0],
                    ti: [0.208, -0.237, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [114.796, 182.298, 0],
                    to: [-0.208, 0.237, 0],
                    ti: [0.183, -0.208, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [114.21, 182.964, 0],
                    to: [-0.183, 0.208, 0],
                    ti: [0.161, -0.181, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [113.696, 183.547, 0],
                    to: [-0.161, 0.181, 0],
                    ti: [0.141, -0.155, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [113.244, 184.051, 0],
                    to: [-0.141, 0.155, 0],
                    ti: [0.122, -0.131, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [112.85, 184.48, 0],
                    to: [-0.122, 0.131, 0],
                    ti: [0.105, -0.107, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [112.51, 184.835, 0],
                    to: [-0.105, 0.107, 0],
                    ti: [0.089, -0.086, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [112.219, 185.124, 0],
                    to: [-0.089, 0.086, 0],
                    ti: [0.074, -0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [111.973, 185.351, 0],
                    to: [-0.074, 0.066, 0],
                    ti: [0.06, -0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [111.772, 185.518, 0],
                    to: [-0.06, 0.046, 0],
                    ti: [0.046, -0.026, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [111.612, 185.626, 0],
                    to: [-0.046, 0.026, 0],
                    ti: [0.035, -0.013, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [111.493, 185.674, 0],
                    to: [-0.035, 0.013, 0],
                    ti: [0.027, -0.015, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [111.404, 185.706, 0],
                    to: [-0.027, 0.015, 0],
                    ti: [0.021, -0.022, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [111.333, 185.761, 0],
                    to: [-0.021, 0.022, 0],
                    ti: [0.015, -0.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [111.28, 185.837, 0],
                    to: [-0.015, 0.028, 0],
                    ti: [0.01, -0.033, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [111.243, 185.929, 0],
                    to: [-0.01, 0.033, 0],
                    ti: [0.006, -0.036, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [111.219, 186.034, 0],
                    to: [-0.006, 0.036, 0],
                    ti: [0.003, -0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [111.207, 186.148, 0],
                    to: [-0.003, 0.038, 0],
                    ti: [0, -0.039, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [111.203, 186.265, 0],
                    to: [0, 0.039, 0],
                    ti: [0, -0.019, 0],
                  },
                  { t: 118, s: [111.205, 186.381, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.894, 0.956, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 22,
                    s: [70.371, 73.099, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.011, 0.757, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.387, -0.094, 0] },
                    t: 24,
                    s: [81.451, 49.764, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.535, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.009, 0.127, 0] },
                    t: 26,
                    s: [84.489, 60.747, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.814, 1.157, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.102, -0.112, 0] },
                    t: 28,
                    s: [81.065, 81.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.818, 1.017, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.151, 0.054, 0] },
                    t: 30,
                    s: [65.388, 72.828, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.16, 0.963, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.154, 0.014, 0] },
                    t: 32,
                    s: [46.083, 98.818, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.958, 0.997, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, -0.066, 0] },
                    t: 34,
                    s: [23.236, 67.65, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.924, 0.911, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.086, -0.003, 0] },
                    t: 36,
                    s: [90.073, 84.988, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.171, 1.805, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.806, 1.29, 0] },
                    t: 38,
                    s: [57.21, 68.22, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.965, 1.029, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.09, 0.076, 0] },
                    t: 40,
                    s: [60.29, 67.062, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.989, 0.774, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.06, 0.022, 0] },
                    t: 42,
                    s: [100.479, 79.398, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.87, 1.114, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.013, 0.132, 0] },
                    t: 44,
                    s: [77.165, 62.735, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.823, 0.904, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.233, 0.048, 0] },
                    t: 46,
                    s: [97.376, 34.245, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.123, 1.029, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.158, 0.644, 0] },
                    t: 48,
                    s: [108.611, 101.546, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.977, 0.834, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.05, 0.021, 0] },
                    t: 50,
                    s: [121.197, 111.543, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.983, 1.031, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.031, 0.167, 0] },
                    t: 52,
                    s: [90.035, 98.098, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.835, 0.878, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.021, 0.023, 0] },
                    t: 54,
                    s: [112.765, 84.756, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.751, 1.24, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, 0.262, 0] },
                    t: 56,
                    s: [94.608, 103.076, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.906, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.125, 0.062, 0] },
                    t: 58,
                    s: [76.78, 111.632, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.547, -0.27, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.74, 2.198, 0] },
                    t: 60,
                    s: [41.278, 78.465, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.914, 1.188, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.072, 0.089, 0] },
                    t: 62,
                    s: [36.77, 77.159, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.441, 0.893, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [2.649, 0.058, 0] },
                    t: 64,
                    s: [70.888, 58.554, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.52, 1.21, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, 0.377, 0] },
                    t: 66,
                    s: [71.996, 119.193, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.977, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.101, 0.06, 0] },
                    t: 68,
                    s: [78.321, 136.39, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.766, 1.004, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.031, -0.115, 0] },
                    t: 70,
                    s: [108.467, 75.868, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.959, 0.964, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.129, 0.004, 0] },
                    t: 72,
                    s: [86.503, 101.319, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.84, 0.825, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.079, -0.062, 0] },
                    t: 74,
                    s: [46.776, 74.608, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.98, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.174, 0.159, 0] },
                    t: 76,
                    s: [67.187, 89.94, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.699, 3.529, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.027, 1.019, 0] },
                    t: 78,
                    s: [85.904, 106.857, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.914, 0.895, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.115, 0.081, 0] },
                    t: 80,
                    s: [71.727, 108.364, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.929, 0.834, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [2.984, 0.401, 0] },
                    t: 82,
                    s: [34.691, 61.105, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.084, 1.299, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.168, 0] },
                    t: 84,
                    s: [33.627, 48.718, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.001, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.042, 0.065, 0] },
                    t: 86,
                    s: [59.325, 36.458, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.944, 0.928, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.001, -0.001, 0] },
                    t: 88,
                    s: [7.81, 92.753, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.889, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.168, -0.538, 0] },
                    t: 90,
                    s: [59.941, 37.215, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.33, 1.334, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.335, -0.083, 0] },
                    t: 92,
                    s: [42.632, 44.663, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.973, 0.992, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, 0.067, 0] },
                    t: 94,
                    s: [36.89, 40.928, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.915, 0.649, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.04, -0.009, 0] },
                    t: 96,
                    s: [65.36, 59.633, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.366, 0.982, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [3.347, 0.109, 0] },
                    t: 98,
                    s: [46.071, 42.784, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.284, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.096, -0.023, 0] },
                    t: 100,
                    s: [45.578, -11.282, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.976, -5.807, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.094, 7.457, 0] },
                    t: 102,
                    s: [42.321, 31.087, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.91, 0.98, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.033, 0.084, 0] },
                    t: 104,
                    s: [17.582, 31.566, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [4.784, 0.838, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.171, -0.026, 0] },
                    t: 106,
                    s: [35.243, 70.197, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.95, 1.095, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.082, 0.171, 0] },
                    t: 108,
                    s: [36.597, 40.776, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.762, 1.02, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.123, 0.044, 0] },
                    t: 110,
                    s: [-26.212, 12.89, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.889, 0.94, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.128, 0.016, 0] },
                    t: 112,
                    s: [-0.792, 72.669, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.804, 0.91, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.335, -0.211, 0] },
                    t: 114,
                    s: [46.469, -1.813, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.145, 1.163, 0] },
                    t: 116,
                    s: [62.111, 19.267, 100],
                  },
                  { t: 118, s: [83.28, 20.895, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.6823529411764706, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 22,
            op: 180,
            st: 22,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 20,
            ty: 4,
            nm: 'Shape Layer 11',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 10,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 64,
                    s: [100],
                  },
                  { t: 96, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 10,
                    s: [280.895, 263.305, 0],
                    to: [-0.567, 0.175, 0],
                    ti: [2.713, -0.992, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 12,
                    s: [277.49, 264.357, 0],
                    to: [-2.713, 0.992, 0],
                    ti: [6.007, -2.449, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [264.618, 269.257, 0],
                    to: [-6.007, 2.449, 0],
                    ti: [8.091, -3.591, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [241.447, 279.054, 0],
                    to: [-8.091, 3.591, 0],
                    ti: [7.873, -3.782, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [216.07, 290.801, 0],
                    to: [-7.873, 3.782, 0],
                    ti: [6.648, -3.432, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [194.207, 301.746, 0],
                    to: [-6.648, 3.432, 0],
                    ti: [5.503, -3.025, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [176.185, 311.391, 0],
                    to: [-5.503, 3.025, 0],
                    ti: [4.615, -2.683, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [161.19, 319.898, 0],
                    to: [-4.615, 2.683, 0],
                    ti: [3.935, -2.407, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [148.493, 327.489, 0],
                    to: [-3.935, 2.407, 0],
                    ti: [3.399, -2.181, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [137.583, 334.338, 0],
                    to: [-3.399, 2.181, 0],
                    ti: [2.966, -1.993, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [128.098, 340.575, 0],
                    to: [-2.966, 1.993, 0],
                    ti: [2.607, -1.832, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [119.785, 346.294, 0],
                    to: [-2.607, 1.832, 0],
                    ti: [2.302, -1.693, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [112.455, 351.568, 0],
                    to: [-2.302, 1.693, 0],
                    ti: [2.037, -1.57, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [105.973, 356.452, 0],
                    to: [-2.037, 1.57, 0],
                    ti: [1.803, -1.461, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [100.233, 360.99, 0],
                    to: [-1.803, 1.461, 0],
                    ti: [1.593, -1.362, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [95.155, 365.216, 0],
                    to: [-1.593, 1.362, 0],
                    ti: [1.401, -1.271, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [90.677, 369.16, 0],
                    to: [-1.401, 1.271, 0],
                    ti: [1.224, -1.188, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [86.752, 372.844, 0],
                    to: [-1.224, 1.188, 0],
                    ti: [1.07, -1.108, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [83.332, 376.285, 0],
                    to: [-1.07, 1.108, 0],
                    ti: [0.937, -1.031, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [80.332, 379.49, 0],
                    to: [-0.937, 1.031, 0],
                    ti: [0.818, -0.958, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [77.708, 382.471, 0],
                    to: [-0.818, 0.958, 0],
                    ti: [0.71, -0.889, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [75.425, 385.239, 0],
                    to: [-0.71, 0.889, 0],
                    ti: [0.612, -0.822, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [73.45, 387.804, 0],
                    to: [-0.612, 0.822, 0],
                    ti: [0.523, -0.758, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [71.754, 390.173, 0],
                    to: [-0.523, 0.758, 0],
                    ti: [0.442, -0.696, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [70.313, 392.354, 0],
                    to: [-0.442, 0.696, 0],
                    ti: [0.369, -0.637, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [69.102, 394.352, 0],
                    to: [-0.369, 0.637, 0],
                    ti: [0.304, -0.579, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [68.096, 396.175, 0],
                    to: [-0.304, 0.579, 0],
                    ti: [0.245, -0.524, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [67.277, 397.829, 0],
                    to: [-0.245, 0.524, 0],
                    ti: [0.193, -0.471, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [66.624, 399.32, 0],
                    to: [-0.193, 0.471, 0],
                    ti: [0.147, -0.42, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [66.118, 400.656, 0],
                    to: [-0.147, 0.42, 0],
                    ti: [0.105, -0.37, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [65.744, 401.84, 0],
                    to: [-0.105, 0.37, 0],
                    ti: [0.069, -0.322, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [65.487, 402.877, 0],
                    to: [-0.069, 0.322, 0],
                    ti: [0.037, -0.276, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [65.332, 403.773, 0],
                    to: [-0.037, 0.276, 0],
                    ti: [0.009, -0.231, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [65.265, 404.532, 0],
                    to: [-0.009, 0.231, 0],
                    ti: [-0.014, -0.188, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.997 },
                    o: { x: 0.167, y: 0.004 },
                    t: 78,
                    s: [65.276, 405.16, 0],
                    to: [0.014, 0.188, 0],
                    ti: [-0.034, -0.146, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.984 },
                    o: { x: 0.167, y: 0.02 },
                    t: 80,
                    s: [65.351, 405.66, 0],
                    to: [0.034, 0.146, 0],
                    ti: [-0.051, -0.106, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.952 },
                    o: { x: 0.167, y: 0.059 },
                    t: 82,
                    s: [65.481, 406.037, 0],
                    to: [0.051, 0.106, 0],
                    ti: [-0.064, -0.066, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.89 },
                    o: { x: 0.167, y: 0.126 },
                    t: 84,
                    s: [65.655, 406.293, 0],
                    to: [0.064, 0.066, 0],
                    ti: [-0.074, -0.028, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.83 },
                    o: { x: 0.167, y: 0.166 },
                    t: 86,
                    s: [65.864, 406.433, 0],
                    to: [0.074, 0.028, 0],
                    ti: [-0.081, 0.01, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.846 },
                    o: { x: 0.167, y: 0.14 },
                    t: 88,
                    s: [66.098, 406.459, 0],
                    to: [0.081, -0.01, 0],
                    ti: [-0.083, 0.038, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.88 },
                    o: { x: 0.167, y: 0.12 },
                    t: 90,
                    s: [66.349, 406.373, 0],
                    to: [0.083, -0.038, 0],
                    ti: [-0.078, 0.048, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.889 },
                    o: { x: 0.167, y: 0.119 },
                    t: 92,
                    s: [66.596, 406.231, 0],
                    to: [0.078, -0.048, 0],
                    ti: [-0.069, 0.051, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.905 },
                    o: { x: 0.167, y: 0.105 },
                    t: 94,
                    s: [66.819, 406.083, 0],
                    to: [0.069, -0.051, 0],
                    ti: [-0.057, 0.054, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.929 },
                    o: { x: 0.167, y: 0.078 },
                    t: 96,
                    s: [67.01, 405.928, 0],
                    to: [0.057, -0.054, 0],
                    ti: [-0.042, 0.058, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.962 },
                    o: { x: 0.167, y: 0.04 },
                    t: 98,
                    s: [67.16, 405.761, 0],
                    to: [0.042, -0.058, 0],
                    ti: [-0.023, 0.063, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.006 },
                    t: 100,
                    s: [67.259, 405.58, 0],
                    to: [0.023, -0.063, 0],
                    ti: [-0.001, 0.07, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [67.298, 405.38, 0],
                    to: [0.001, -0.07, 0],
                    ti: [0.023, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [67.266, 405.158, 0],
                    to: [-0.023, -0.076, 0],
                    ti: [0.046, 0.073, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [67.158, 404.922, 0],
                    to: [-0.046, -0.073, 0],
                    ti: [0.062, 0.06, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [66.993, 404.72, 0],
                    to: [-0.062, -0.06, 0],
                    ti: [0.076, 0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 110,
                    s: [66.783, 404.561, 0],
                    to: [-0.076, -0.046, 0],
                    ti: [0.086, 0.031, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 112,
                    s: [66.539, 404.446, 0],
                    to: [-0.086, -0.031, 0],
                    ti: [0.093, 0.016, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 114,
                    s: [66.269, 404.376, 0],
                    to: [-0.093, -0.016, 0],
                    ti: [0.097, 0, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 116,
                    s: [65.982, 404.352, 0],
                    to: [-0.097, 0, 0],
                    ti: [0.049, -0.004, 0],
                  },
                  { t: 118, s: [65.686, 404.375, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 0.898, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 10,
                    s: [49.45, 148.28, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.038, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.246, 0.447, 0] },
                    t: 12,
                    s: [96.233, 59.296, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.894, 0.934, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.026, 0.012, 0] },
                    t: 14,
                    s: [84.39, 38.927, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.66, 0.535, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.391, -0.32, 0] },
                    t: 16,
                    s: [101.568, 62.764, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.999, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.102, 0] },
                    t: 18,
                    s: [106.222, 57.834, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.162, 0.853, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.472, -0.001, 0] },
                    t: 20,
                    s: [64.691, 35.274, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.955, 0.795, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 0.193, 0] },
                    t: 22,
                    s: [70.923, 57.653, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.827, 1.022, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.098, 0.141, 0] },
                    t: 24,
                    s: [127.324, 74.727, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.934, 0.941, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.161, 0.018, 0] },
                    t: 26,
                    s: [101.458, 99.601, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.386, 1.222, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.314, -0.197, 0] },
                    t: 28,
                    s: [73.693, 68.044, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.064, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.061, 0] },
                    t: 30,
                    s: [79.522, 77.427, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 1.413, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.036, 0.681, 0] },
                    t: 32,
                    s: [46.671, 43.016, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.358, 0.941, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.543, 0.069, 0] },
                    t: 34,
                    s: [104.84, 38.219, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.006, 1.25, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.096, -0.2, 0] },
                    t: 36,
                    s: [97.095, 66.774, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.896, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.006, 0.063, 0] },
                    t: 38,
                    s: [45.158, 58.378, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.954, 1.295, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.142, 0.42, 0] },
                    t: 40,
                    s: [100.992, 91.978, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.782, 0.93, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.105, 0.065, 0] },
                    t: 42,
                    s: [80.364, 100.301, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.813, 0.641, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.135, -0.451, 0] },
                    t: 44,
                    s: [89.494, 62.471, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.187, 0.991, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.15, 0.109, 0] },
                    t: 46,
                    s: [104.25, 68.373, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 0.748, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.058, -0.01, 0] },
                    t: 48,
                    s: [122.573, 87.899, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.546, 1.004, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.303, 0.124, 0] },
                    t: 50,
                    s: [63.056, 70.386, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.939, 0.876, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.088, 0.004, 0] },
                    t: 52,
                    s: [66.633, 34.869, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.782, 0.805, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.233, 0.254, 0] },
                    t: 54,
                    s: [129.403, 72.011, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.806, 1.026, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.135, 0.145, 0] },
                    t: 56,
                    s: [112.863, 90.111, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 0.817, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.146, 0.02, 0] },
                    t: 58,
                    s: [86.228, 114.396, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 0.937, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.514, 0.153, 0] },
                    t: 60,
                    s: [50.891, 82.427, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.248, 0.388, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, -0.251, 0] },
                    t: 62,
                    s: [55.82, 44.045, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.895, 0.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, 0.096, 0] },
                    t: 64,
                    s: [49.632, 53.61, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.992, 0.911, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.404, -0.058, 0] },
                    t: 66,
                    s: [74.255, 114.253, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.123, 0.931, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.009, 1.243, 0] },
                    t: 68,
                    s: [80.651, 78.395, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 0.169, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, -0.398, 0] },
                    t: 70,
                    s: [74.861, 75.817, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.802, 1.723, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.176, 0.093, 0] },
                    t: 72,
                    s: [19.702, 76.263, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 1.043, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.144, 0.075, 0] },
                    t: 74,
                    s: [37.393, 80.265, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.507, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.243, 0.028, 0] },
                    t: 76,
                    s: [61.705, 41.546, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.85, 0.881, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.1, -0.133, 0] },
                    t: 78,
                    s: [55.488, 100.178, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.039, 0.614, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.187, 0.279, 0] },
                    t: 80,
                    s: [24.961, 77.623, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.03, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.026, 0.106, 0] },
                    t: 82,
                    s: [0.375, 68.025, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.01, 1.132, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.022, -0.04, 0] },
                    t: 84,
                    s: [36.365, 33.155, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.865, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.009, 0.051, 0] },
                    t: 86,
                    s: [-12.521, 56.708, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.909, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.219, -0.051, 0] },
                    t: 88,
                    s: [42.47, -4.293, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.953, 1.38, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.941, 0] },
                    t: 90,
                    s: [76.363, 33.422, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.979, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.108, 0.068, 0] },
                    t: 92,
                    s: [13.419, 37.087, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 1.072, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.028, -0.014, 0] },
                    t: 94,
                    s: [40.835, 16.71, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.911, 1.007, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.176, 0.039, 0] },
                    t: 96,
                    s: [20.376, 34.131, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [3.32, 0.906, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.367, 0.007, 0] },
                    t: 98,
                    s: [26.948, 1.687, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.176, 0.438, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.755, 0] },
                    t: 100,
                    s: [27.375, 36.914, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.984, 0.868, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.057, 0.098, 0] },
                    t: 102,
                    s: [15.071, 41.282, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.862, 1.428, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.019, 0.226, 0] },
                    t: 104,
                    s: [53.354, 66.388, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.873, 0.951, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.21, 0.07, 0] },
                    t: 106,
                    s: [22.228, 81.077, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.145, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.242, -0.12, 0] },
                    t: 108,
                    s: [1.727, -9.051, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.874, 0.919, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.053, -0.044, 0] },
                    t: 110,
                    s: [-9.032, 27.824, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.791, -3.351, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.245, -3.045, 0] },
                    t: 112,
                    s: [20.502, 3.731, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.021, 0.93, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.139, 0.085, 0] },
                    t: 114,
                    s: [35.73, 4.373, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.017, -0.444, 0] },
                    t: 116,
                    s: [58.616, 37.243, 100],
                  },
                  { t: 118, s: [30.021, 32.047, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.7647058823529411, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 10,
            op: 180,
            st: 10,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 21,
            ty: 4,
            nm: 'Shape Layer 10',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 16,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 26,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 70,
                    s: [100],
                  },
                  { t: 102, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [272.114, 255.071, 0],
                    to: [-0.266, 0.053, 0],
                    ti: [1.216, -0.382, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [270.517, 255.387, 0],
                    to: [-1.216, 0.382, 0],
                    ti: [2.644, -1.017, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [264.819, 257.361, 0],
                    to: [-2.644, 1.017, 0],
                    ti: [3.502, -1.605, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [254.654, 261.49, 0],
                    to: [-3.502, 1.605, 0],
                    ti: [3.128, -2.062, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [243.809, 266.989, 0],
                    to: [-3.128, 2.062, 0],
                    ti: [0.929, -2.4, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.987 },
                    o: { x: 0.167, y: 0.016 },
                    t: 26,
                    s: [235.889, 273.86, 0],
                    to: [-0.929, 2.4, 0],
                    ti: [-1.493, -1.834, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.881 },
                    o: { x: 0.167, y: 0.133 },
                    t: 28,
                    s: [238.235, 281.389, 0],
                    to: [1.493, 1.834, 0],
                    ti: [-2.114, -0.944, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.862 },
                    o: { x: 0.167, y: 0.16 },
                    t: 30,
                    s: [244.846, 284.864, 0],
                    to: [2.114, 0.944, 0],
                    ti: [-1.909, -0.642, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.857 },
                    o: { x: 0.167, y: 0.163 },
                    t: 32,
                    s: [250.917, 287.051, 0],
                    to: [1.909, 0.642, 0],
                    ti: [-1.697, -0.51, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.163 },
                    t: 34,
                    s: [256.301, 288.717, 0],
                    to: [1.697, 0.51, 0],
                    ti: [-1.516, -0.438, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.162 },
                    t: 36,
                    s: [261.1, 290.112, 0],
                    to: [1.516, 0.438, 0],
                    ti: [-1.362, -0.392, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.162 },
                    t: 38,
                    s: [265.397, 291.343, 0],
                    to: [1.362, 0.392, 0],
                    ti: [-1.229, -0.362, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.854 },
                    o: { x: 0.167, y: 0.161 },
                    t: 40,
                    s: [269.269, 292.467, 0],
                    to: [1.229, 0.362, 0],
                    ti: [-1.114, -0.341, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.159 },
                    t: 42,
                    s: [272.774, 293.517, 0],
                    to: [1.114, 0.341, 0],
                    ti: [-1.012, -0.326, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.856 },
                    o: { x: 0.167, y: 0.158 },
                    t: 44,
                    s: [275.955, 294.515, 0],
                    to: [1.012, 0.326, 0],
                    ti: [-0.921, -0.314, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.858 },
                    o: { x: 0.167, y: 0.155 },
                    t: 46,
                    s: [278.847, 295.472, 0],
                    to: [0.921, 0.314, 0],
                    ti: [-0.839, -0.305, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.86 },
                    o: { x: 0.167, y: 0.153 },
                    t: 48,
                    s: [281.482, 296.4, 0],
                    to: [0.839, 0.305, 0],
                    ti: [-0.762, -0.298, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.863 },
                    o: { x: 0.167, y: 0.149 },
                    t: 50,
                    s: [283.879, 297.304, 0],
                    to: [0.762, 0.298, 0],
                    ti: [-0.69, -0.293, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.867 },
                    o: { x: 0.167, y: 0.145 },
                    t: 52,
                    s: [286.053, 298.19, 0],
                    to: [0.69, 0.293, 0],
                    ti: [-0.621, -0.288, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.871 },
                    o: { x: 0.167, y: 0.141 },
                    t: 54,
                    s: [288.016, 299.06, 0],
                    to: [0.621, 0.288, 0],
                    ti: [-0.554, -0.284, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.878 },
                    o: { x: 0.167, y: 0.135 },
                    t: 56,
                    s: [289.778, 299.918, 0],
                    to: [0.554, 0.284, 0],
                    ti: [-0.486, -0.279, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.886 },
                    o: { x: 0.167, y: 0.128 },
                    t: 58,
                    s: [291.338, 300.763, 0],
                    to: [0.486, 0.279, 0],
                    ti: [-0.419, -0.273, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.895 },
                    o: { x: 0.167, y: 0.119 },
                    t: 60,
                    s: [292.695, 301.592, 0],
                    to: [0.419, 0.273, 0],
                    ti: [-0.351, -0.266, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.908 },
                    o: { x: 0.167, y: 0.106 },
                    t: 62,
                    s: [293.849, 302.402, 0],
                    to: [0.351, 0.266, 0],
                    ti: [-0.283, -0.258, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.925 },
                    o: { x: 0.167, y: 0.088 },
                    t: 64,
                    s: [294.801, 303.189, 0],
                    to: [0.283, 0.258, 0],
                    ti: [-0.215, -0.245, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.945 },
                    o: { x: 0.167, y: 0.067 },
                    t: 66,
                    s: [295.549, 303.949, 0],
                    to: [0.215, 0.245, 0],
                    ti: [-0.147, -0.225, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.967 },
                    o: { x: 0.167, y: 0.041 },
                    t: 68,
                    s: [296.09, 304.662, 0],
                    to: [0.147, 0.225, 0],
                    ti: [-0.082, -0.198, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.014 },
                    t: 70,
                    s: [296.429, 305.301, 0],
                    to: [0.082, 0.198, 0],
                    ti: [-0.024, -0.164, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [296.583, 305.847, 0],
                    to: [0.024, 0.164, 0],
                    ti: [0.026, -0.131, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [296.574, 306.285, 0],
                    to: [-0.026, 0.131, 0],
                    ti: [0.067, -0.108, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [296.425, 306.636, 0],
                    to: [-0.067, 0.108, 0],
                    ti: [0.097, -0.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [296.17, 306.934, 0],
                    to: [-0.097, 0.091, 0],
                    ti: [0.117, -0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [295.842, 307.183, 0],
                    to: [-0.117, 0.076, 0],
                    ti: [0.129, -0.065, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [295.468, 307.393, 0],
                    to: [-0.129, 0.065, 0],
                    ti: [0.134, -0.057, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [295.071, 307.574, 0],
                    to: [-0.134, 0.057, 0],
                    ti: [0.133, -0.051, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [294.667, 307.733, 0],
                    to: [-0.133, 0.051, 0],
                    ti: [0.128, -0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [294.272, 307.878, 0],
                    to: [-0.128, 0.046, 0],
                    ti: [0.12, -0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [293.898, 308.012, 0],
                    to: [-0.12, 0.044, 0],
                    ti: [0.108, -0.042, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 92,
                    s: [293.554, 308.14, 0],
                    to: [-0.108, 0.042, 0],
                    ti: [0.094, -0.041, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 94,
                    s: [293.249, 308.264, 0],
                    to: [-0.094, 0.041, 0],
                    ti: [0.08, -0.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 96,
                    s: [292.988, 308.385, 0],
                    to: [-0.08, 0.044, 0],
                    ti: [0.068, -0.055, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 98,
                    s: [292.767, 308.528, 0],
                    to: [-0.068, 0.055, 0],
                    ti: [0.056, -0.069, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 100,
                    s: [292.579, 308.714, 0],
                    to: [-0.056, 0.069, 0],
                    ti: [0.044, -0.082, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 102,
                    s: [292.428, 308.941, 0],
                    to: [-0.044, 0.082, 0],
                    ti: [0.03, -0.094, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 104,
                    s: [292.318, 309.205, 0],
                    to: [-0.03, 0.094, 0],
                    ti: [0.016, -0.105, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 106,
                    s: [292.249, 309.504, 0],
                    to: [-0.016, 0.105, 0],
                    ti: [0.001, -0.114, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 108,
                    s: [292.223, 309.833, 0],
                    to: [-0.001, 0.114, 0],
                    ti: [-0.013, -0.122, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.995 },
                    o: { x: 0.167, y: 0.004 },
                    t: 110,
                    s: [292.242, 310.189, 0],
                    to: [0.013, 0.122, 0],
                    ti: [-0.028, -0.128, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.011 },
                    t: 112,
                    s: [292.304, 310.564, 0],
                    to: [0.028, 0.128, 0],
                    ti: [-0.042, -0.131, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.98 },
                    o: { x: 0.167, y: 0.019 },
                    t: 114,
                    s: [292.408, 310.955, 0],
                    to: [0.042, 0.131, 0],
                    ti: [-0.055, -0.133, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.971 },
                    o: { x: 0.167, y: 0.029 },
                    t: 116,
                    s: [292.554, 311.353, 0],
                    to: [0.055, 0.133, 0],
                    ti: [-0.031, -0.067, 0],
                  },
                  { t: 118, s: [292.738, 311.753, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 16,
                    s: [127.438, 90.591, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.119, 0.935, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.22, -0.014, 0] },
                    t: 18,
                    s: [90.096, 28.016, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 1.126, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.049, -0.3, 0] },
                    t: 20,
                    s: [67.312, 81.384, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.746, 0.942, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.141, 0.05, 0] },
                    t: 22,
                    s: [122.538, 69.77, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.94, 1.165, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.124, -0.191, 0] },
                    t: 24,
                    s: [102.02, 98.906, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.968, 1.084, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.21, 0.055, 0] },
                    t: 26,
                    s: [60.035, 90.043, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.429, 0.887, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.051, 0.042, 0] },
                    t: 28,
                    s: [71.968, 116.451, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.886, 0.984, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, 0.318, 0] },
                    t: 30,
                    s: [64.586, 63.443, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.12, 0.842, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.307, -0.02, 0] },
                    t: 32,
                    s: [109.991, 44.631, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.887, 0.933, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.049, 0.176, 0] },
                    t: 34,
                    s: [126.937, 59.824, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.971, 1.735, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.316, -0.347, 0] },
                    t: 36,
                    s: [85.604, 73.442, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.831, 0.945, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.045, 0.075, 0] },
                    t: 38,
                    s: [70.771, 70.802, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.913, 0.639, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.164, -0.162, 0] },
                    t: 40,
                    s: [80.389, 96.73, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.179, 0.905, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.79, 0.108, 0] },
                    t: 42,
                    s: [90.289, 87.923, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.589, 1.199, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 0.673, 0] },
                    t: 44,
                    s: [90.772, 58.569, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.878, 1.108, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.073, 0.059, 0] },
                    t: 46,
                    s: [95.049, 54.421, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.33, 0.947, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.262, 0.047, 0] },
                    t: 48,
                    s: [60.54, 68.48, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.901, 0.927, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.067, -0.148, 0] },
                    t: 50,
                    s: [44.437, 36.222, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 4.165, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.525, -0.607, 0] },
                    t: 52,
                    s: [124.279, 47.844, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-1.352, 0.913, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.486, 0.081, 0] },
                    t: 54,
                    s: [139.353, 46.441, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.883, 2.129, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.086, 1.924, 0] },
                    t: 56,
                    s: [137.147, 101.148, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.914, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.29, 0.078, 0] },
                    t: 58,
                    s: [77.097, 103.625, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.16, 2.38, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.222, 2.472, 0] },
                    t: 60,
                    s: [52.92, 67.602, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.876, 0.839, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.055, 0.079, 0] },
                    t: 62,
                    s: [38.391, 66.346, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.058, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.255, 0.173, 0] },
                    t: 64,
                    s: [80.849, 88.417, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 0.752, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.034, -0.04, 0] },
                    t: 66,
                    s: [101.514, 109.026, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.242, 1.058, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.446, 0.126, 0] },
                    t: 68,
                    s: [66.389, 95.048, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.892, 1.025, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.062, 0.034, 0] },
                    t: 70,
                    s: [58.31, 67.448, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.044, 0.974, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.364, 0.019, 0] },
                    t: 72,
                    s: [89.867, 114.12, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.587, 0.887, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.029, -0.038, 0] },
                    t: 74,
                    s: [99.232, 53.341, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.912, 1.104, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.104, 0.319, 0] },
                    t: 76,
                    s: [84.926, 94.953, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.439, 0.867, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.495, 0.046, 0] },
                    t: 78,
                    s: [28.265, 109.679, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.035, 0.952, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.07, 0.223, 0] },
                    t: 80,
                    s: [24.92, 76.564, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.948, 1.618, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.025, -0.113, 0] },
                    t: 82,
                    s: [45.873, 56.751, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.603, 0.985, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.138, 0.073, 0] },
                    t: 84,
                    s: [16.102, 65.143, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.911, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.105, -0.019, 0] },
                    t: 86,
                    s: [27.313, -5.503, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.923, 1.561, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 1.347, 0] },
                    t: 88,
                    s: [69.528, 52.086, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.395, 0.972, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.056, 0.073, 0] },
                    t: 90,
                    s: [13.347, 55.885, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.991, 0.96, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, -0.041, 0] },
                    t: 92,
                    s: [17.457, 26.517, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.906, 0.989, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.01, -0.077, 0] },
                    t: 94,
                    s: [-6.114, 46.16, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.079, 1.13, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.74, -0.013, 0] },
                    t: 96,
                    s: [14.941, 35.961, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.225, 0.927, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.041, 0.051, 0] },
                    t: 98,
                    s: [17.614, 44.753, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.151, -0.369, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.061, -0.61, 0] },
                    t: 100,
                    s: [12.402, 22.217, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.952, 0.988, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.054, 0.089, 0] },
                    t: 102,
                    s: [31.658, 24.926, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.879, 0.864, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.115, -0.014, 0] },
                    t: 104,
                    s: [-22.38, 66.727, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.835, 0.983, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.269, 0.215, 0] },
                    t: 106,
                    s: [0.27, 30.796, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.829, 1.068, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, -0.021, 0] },
                    t: 108,
                    s: [10.423, 7.981, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.817, 0.983, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.162, 0.038, 0] },
                    t: 110,
                    s: [20.367, 26.148, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.012, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.153, -0.021, 0] },
                    t: 112,
                    s: [30.864, -6.905, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.986, 1.056, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.011, 0.499, 0] },
                    t: 114,
                    s: [43.453, 19.525, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.017, 0.034, 0] },
                    t: 116,
                    s: [29.038, 24.824, 100],
                  },
                  { t: 118, s: [41.021, 15.933, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [
                        0.44313725490196076, 0.8823529411764706,
                        0.5137254901960784, 1,
                      ],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 16,
            op: 180,
            st: 16,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 22,
            ty: 4,
            nm: 'Shape Layer 9',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 20,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 30,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 74,
                    s: [100],
                  },
                  { t: 106, s: [0] },
                ],
                ix: 11,
              },
              r: {
                a: 0,
                k: 0,
                ix: 10,
                x: 'var $bm_rt;\nvar $bm_rt;\nvar $bm_rt;\n$bm_rt = $bm_rt = $bm_rt = mul(time, 300);',
              },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [279.864, 255.271, 0],
                    to: [-0.343, 0.177, 0],
                    ti: [1.691, -0.679, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [277.804, 256.336, 0],
                    to: [-1.691, 0.679, 0],
                    ti: [3.887, -1.09, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [269.719, 259.347, 0],
                    to: [-3.887, 1.09, 0],
                    ti: [3.906, 1.153, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [254.482, 262.875, 0],
                    to: [-3.906, -1.153, 0],
                    ti: [0.302, 3.979, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.973 },
                    o: { x: 0.167, y: 0.029 },
                    t: 28,
                    s: [246.284, 252.432, 0],
                    to: [-0.302, -3.979, 0],
                    ti: [-2.124, 4.016, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.96 },
                    o: { x: 0.167, y: 0.048 },
                    t: 30,
                    s: [252.67, 238.999, 0],
                    to: [2.124, -4.016, 0],
                    ti: [-1.967, 3.264, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.958 },
                    o: { x: 0.167, y: 0.049 },
                    t: 32,
                    s: [259.026, 228.335, 0],
                    to: [1.967, -3.264, 0],
                    ti: [-1.669, 2.774, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.96 },
                    o: { x: 0.167, y: 0.047 },
                    t: 34,
                    s: [264.471, 219.412, 0],
                    to: [1.669, -2.774, 0],
                    ti: [-1.398, 2.426, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.962 },
                    o: { x: 0.167, y: 0.042 },
                    t: 36,
                    s: [269.041, 211.694, 0],
                    to: [1.398, -2.426, 0],
                    ti: [-1.168, 2.169, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.967 },
                    o: { x: 0.167, y: 0.037 },
                    t: 38,
                    s: [272.862, 204.857, 0],
                    to: [1.168, -2.169, 0],
                    ti: [-0.974, 1.973, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.972 },
                    o: { x: 0.167, y: 0.032 },
                    t: 40,
                    s: [276.051, 198.681, 0],
                    to: [0.974, -1.973, 0],
                    ti: [-0.806, 1.82, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.977 },
                    o: { x: 0.167, y: 0.026 },
                    t: 42,
                    s: [278.705, 193.017, 0],
                    to: [0.806, -1.82, 0],
                    ti: [-0.644, 1.685, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.983 },
                    o: { x: 0.167, y: 0.019 },
                    t: 44,
                    s: [280.888, 187.764, 0],
                    to: [0.644, -1.685, 0],
                    ti: [-0.482, 1.558, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.989 },
                    o: { x: 0.167, y: 0.012 },
                    t: 46,
                    s: [282.57, 182.907, 0],
                    to: [0.482, -1.558, 0],
                    ti: [-0.333, 1.443, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.994 },
                    o: { x: 0.167, y: 0.006 },
                    t: 48,
                    s: [283.781, 178.414, 0],
                    to: [0.333, -1.443, 0],
                    ti: [-0.197, 1.336, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.998 },
                    o: { x: 0.167, y: 0.002 },
                    t: 50,
                    s: [284.566, 174.251, 0],
                    to: [0.197, -1.336, 0],
                    ti: [-0.074, 1.237, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [284.965, 170.396, 0],
                    to: [0.074, -1.237, 0],
                    ti: [0.038, 1.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [285.012, 166.831, 0],
                    to: [-0.038, -1.14, 0],
                    ti: [0.139, 1.044, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [284.739, 163.555, 0],
                    to: [-0.139, -1.044, 0],
                    ti: [0.228, 0.947, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [284.178, 160.569, 0],
                    to: [-0.228, -0.947, 0],
                    ti: [0.304, 0.848, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [283.368, 157.874, 0],
                    to: [-0.304, -0.848, 0],
                    ti: [0.363, 0.748, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [282.355, 155.48, 0],
                    to: [-0.363, -0.748, 0],
                    ti: [0.405, 0.649, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [281.189, 153.386, 0],
                    to: [-0.405, -0.649, 0],
                    ti: [0.428, 0.554, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [279.927, 151.584, 0],
                    to: [-0.428, -0.554, 0],
                    ti: [0.433, 0.464, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [278.623, 150.062, 0],
                    to: [-0.433, -0.464, 0],
                    ti: [0.424, 0.383, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [277.326, 148.798, 0],
                    to: [-0.424, -0.383, 0],
                    ti: [0.403, 0.31, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [276.078, 147.765, 0],
                    to: [-0.403, -0.31, 0],
                    ti: [0.372, 0.245, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [274.911, 146.939, 0],
                    to: [-0.372, -0.245, 0],
                    ti: [0.335, 0.19, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [273.847, 146.293, 0],
                    to: [-0.335, -0.19, 0],
                    ti: [0.294, 0.141, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [272.9, 145.802, 0],
                    to: [-0.294, -0.141, 0],
                    ti: [0.25, 0.1, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [272.082, 145.445, 0],
                    to: [-0.25, -0.1, 0],
                    ti: [0.205, 0.064, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [271.398, 145.204, 0],
                    to: [-0.205, -0.064, 0],
                    ti: [0.16, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [270.85, 145.06, 0],
                    to: [-0.16, -0.034, 0],
                    ti: [0.115, 0.009, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [270.438, 144.999, 0],
                    to: [-0.115, -0.009, 0],
                    ti: [0.071, -0.011, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [270.159, 145.005, 0],
                    to: [-0.071, 0.011, 0],
                    ti: [0.029, -0.027, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 90,
                    s: [270.01, 145.067, 0],
                    to: [-0.029, 0.027, 0],
                    ti: [-0.01, -0.039, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.936 },
                    o: { x: 0.167, y: 0.043 },
                    t: 92,
                    s: [269.983, 145.17, 0],
                    to: [0.01, 0.039, 0],
                    ti: [-0.046, -0.046, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.877 },
                    o: { x: 0.167, y: 0.086 },
                    t: 94,
                    s: [270.07, 145.3, 0],
                    to: [0.046, 0.046, 0],
                    ti: [-0.08, -0.05, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.855 },
                    o: { x: 0.167, y: 0.113 },
                    t: 96,
                    s: [270.262, 145.447, 0],
                    to: [0.08, 0.05, 0],
                    ti: [-0.111, -0.049, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.85 },
                    o: { x: 0.167, y: 0.131 },
                    t: 98,
                    s: [270.551, 145.599, 0],
                    to: [0.111, 0.049, 0],
                    ti: [-0.133, -0.043, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.845 },
                    o: { x: 0.167, y: 0.151 },
                    t: 100,
                    s: [270.927, 145.744, 0],
                    to: [0.133, 0.043, 0],
                    ti: [-0.141, -0.026, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.835 },
                    o: { x: 0.167, y: 0.166 },
                    t: 102,
                    s: [271.347, 145.854, 0],
                    to: [0.141, 0.026, 0],
                    ti: [-0.142, -0.004, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.833 },
                    o: { x: 0.167, y: 0.164 },
                    t: 104,
                    s: [271.77, 145.903, 0],
                    to: [0.142, 0.004, 0],
                    ti: [-0.143, 0.021, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.84 },
                    o: { x: 0.167, y: 0.157 },
                    t: 106,
                    s: [272.2, 145.876, 0],
                    to: [0.143, -0.021, 0],
                    ti: [-0.142, 0.042, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.853 },
                    o: { x: 0.167, y: 0.144 },
                    t: 108,
                    s: [272.629, 145.78, 0],
                    to: [0.142, -0.042, 0],
                    ti: [-0.137, 0.061, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.869 },
                    o: { x: 0.167, y: 0.13 },
                    t: 110,
                    s: [273.049, 145.622, 0],
                    to: [0.137, -0.061, 0],
                    ti: [-0.13, 0.077, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.887 },
                    o: { x: 0.167, y: 0.113 },
                    t: 112,
                    s: [273.452, 145.412, 0],
                    to: [0.13, -0.077, 0],
                    ti: [-0.12, 0.091, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.904 },
                    o: { x: 0.167, y: 0.098 },
                    t: 114,
                    s: [273.829, 145.157, 0],
                    to: [0.12, -0.091, 0],
                    ti: [-0.109, 0.102, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.92 },
                    o: { x: 0.167, y: 0.08 },
                    t: 116,
                    s: [274.175, 144.866, 0],
                    to: [0.109, -0.102, 0],
                    ti: [-0.051, 0.054, 0],
                  },
                  { t: 118, s: [274.483, 144.545, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.838, 1.875, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 20,
                    s: [109.864, 70.089, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.856, 0.69, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.172, 0.076, 0] },
                    t: 22,
                    s: [86.733, 68.252, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.993, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.199, 0.114, 0] },
                    t: 24,
                    s: [64.924, 89.385, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.797, 0.9, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, -0.008, 0] },
                    t: 26,
                    s: [49.171, 146.99, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.92, 0.755, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.141, 0.509, 0] },
                    t: 28,
                    s: [70.147, 94.506, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.944, 1.08, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.941, 0.126, 0] },
                    t: 30,
                    s: [100.345, 84.223, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.003, 0.981, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.08, 0.041, 0] },
                    t: 32,
                    s: [99.102, 64.322, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.843, 0.917, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.002, -0.024, 0] },
                    t: 34,
                    s: [129.334, 103.257, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.977, -0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.177, -21.256, 0] },
                    t: 36,
                    s: [98.181, 73.031, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.969, 0.966, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.032, 0.087, 0] },
                    t: 38,
                    s: [70.521, 73.149, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.819, 0.396, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.048, -0.057, 0] },
                    t: 40,
                    s: [90.481, 75.792, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.879, 0.99, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.155, 0.097, 0] },
                    t: 42,
                    s: [77.84, 74.224, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.857, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.269, -0.012, 0] },
                    t: 44,
                    s: [63.067, 64.426, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.698, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.2, 0] },
                    t: 46,
                    s: [56.422, 72.999, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.807, 0.455, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.115, -0.052, 0] },
                    t: 48,
                    s: [68.741, 79.126, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.911, 0.955, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.147, 0.098, 0] },
                    t: 50,
                    s: [101.082, 75.355, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.698, 0.664, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.265, -0.1, 0] },
                    t: 52,
                    s: [143.609, 54.475, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.73, 1.014, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.074, 0.111, 0] },
                    t: 54,
                    s: [146.608, 63.972, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.888, 1.027, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.012, 0] },
                    t: 56,
                    s: [118.472, 92.79, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.05, 0.968, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.324, 0.02, 0] },
                    t: 58,
                    s: [55.394, 59.194, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.94, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.031, -0.051, 0] },
                    t: 60,
                    s: [33.53, 103.661, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.028, 0.632, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.22, -0.21, 0] },
                    t: 62,
                    s: [68.551, 76.112, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.9, 1.084, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.021, 0.108, 0] },
                    t: 64,
                    s: [89.966, 83.951, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [2.007, 1.005, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.497, 0.042, 0] },
                    t: 66,
                    s: [61.396, 110.752, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.966, 0.971, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.077, 0.004, 0] },
                    t: 68,
                    s: [55.64, 56.936, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.958, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.058, -0.045, 0] },
                    t: 70,
                    s: [130.926, 113.733, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.106, 0.944, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.086, -0.085, 0] },
                    t: 72,
                    s: [86.439, 76.969, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.866, 0.598, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, -0.169, 0] },
                    t: 74,
                    s: [108.294, 95.156, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.04, 0.836, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.221, 0.105, 0] },
                    t: 76,
                    s: [58.545, 89.141, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.947, 0.959, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.027, 0.17, 0] },
                    t: 78,
                    s: [28.376, 66.139, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.89, 1.228, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.148, -0.079, 0] },
                    t: 80,
                    s: [72.983, 43.989, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.128, 1.021, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.339, 0.061, 0] },
                    t: 82,
                    s: [56.935, 55.362, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.199, 0.907, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.05, 0.017, 0] },
                    t: 84,
                    s: [51.706, 12.909, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.938, 1.369, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.059, 0.774, 0] },
                    t: 86,
                    s: [64.946, 65.932, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.252, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.238, 0.068, 0] },
                    t: 88,
                    s: [20.13, 72.328, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.97, 0.949, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.063, 0.001, 0] },
                    t: 90,
                    s: [31.738, 37.588, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.893, 1.019, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.046, -0.129, 0] },
                    t: 92,
                    s: [-14.966, 72.869, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.07, 1.207, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.373, 0.016, 0] },
                    t: 94,
                    s: [15.041, 59.042, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.075, 0.894, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.059, 0] },
                    t: 96,
                    s: [23.68, 76.032, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.071, 0.973, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.039, 0.396, 0] },
                    t: 98,
                    s: [7.79, 16.858, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.941, 0.564, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, -0.041, 0] },
                    t: 100,
                    s: [37.924, 1.088, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.813, 0.957, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.205, 0.103, 0] },
                    t: 102,
                    s: [-17.733, 11.662, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.814, 1.008, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.15, -0.09, 0] },
                    t: 104,
                    s: [-1.626, 56.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.897, 1.062, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.151, 0.008, 0] },
                    t: 106,
                    s: [18.416, 34.888, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.817, 0.918, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.441, 0.035, 0] },
                    t: 108,
                    s: [43.027, 58.529, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.877, 3.279, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.153, -7.35, 0] },
                    t: 110,
                    s: [48.754, 17.354, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.206, 0.776, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.259, 0.08, 0] },
                    t: 112,
                    s: [55.61, 17.816, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.986, 1.061, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.059, 0.133, 0] },
                    t: 114,
                    s: [58.864, 4.732, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.017, 0.035, 0] },
                    t: 116,
                    s: [47.582, -17.422, 100],
                  },
                  { t: 118, s: [56.939, 20.914, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [0.012, 10.621],
                          [-5.695, 5.34],
                          [-2.557, -7.377],
                          [3.15, -2.096],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.7098039215686275, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [-3.421, 4.043], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Rectangle 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 20,
            op: 180,
            st: 20,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 23,
            ty: 4,
            nm: 'Shape Layer 3',
            sr: 1,
            ks: {
              o: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 14,
                    s: [0],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 24,
                    s: [100],
                  },
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    t: 68,
                    s: [100],
                  },
                  { t: 100, s: [0] },
                ],
                ix: 11,
              },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 14,
                    s: [272.377, 258.087, 0],
                    to: [-0.394, 0.137, 0],
                    ti: [2.139, -0.681, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 16,
                    s: [270.01, 258.906, 0],
                    to: [-2.139, 0.681, 0],
                    ti: [5.098, -1.104, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 18,
                    s: [259.541, 262.172, 0],
                    to: [-5.098, 1.104, 0],
                    ti: [7.13, -0.303, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 20,
                    s: [239.424, 265.531, 0],
                    to: [-7.13, 0.303, 0],
                    ti: [6.918, 1.295, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 22,
                    s: [216.76, 263.988, 0],
                    to: [-6.918, -1.295, 0],
                    ti: [5.553, 2.383, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 24,
                    s: [197.916, 257.762, 0],
                    to: [-5.553, -2.383, 0],
                    ti: [4.294, 2.71, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 26,
                    s: [183.44, 249.693, 0],
                    to: [-4.294, -2.71, 0],
                    ti: [3.412, 2.643, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 28,
                    s: [172.149, 241.499, 0],
                    to: [-3.412, -2.643, 0],
                    ti: [2.824, 2.427, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 30,
                    s: [162.967, 233.838, 0],
                    to: [-2.824, -2.427, 0],
                    ti: [2.43, 2.167, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 32,
                    s: [155.204, 226.937, 0],
                    to: [-2.43, -2.167, 0],
                    ti: [2.166, 1.903, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 34,
                    s: [148.386, 220.833, 0],
                    to: [-2.166, -1.903, 0],
                    ti: [1.988, 1.643, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 36,
                    s: [142.207, 215.519, 0],
                    to: [-1.988, -1.643, 0],
                    ti: [1.866, 1.384, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 38,
                    s: [136.458, 210.978, 0],
                    to: [-1.866, -1.384, 0],
                    ti: [1.768, 1.113, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 40,
                    s: [131.01, 207.214, 0],
                    to: [-1.768, -1.113, 0],
                    ti: [1.677, 0.828, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 42,
                    s: [125.848, 204.301, 0],
                    to: [-1.677, -0.828, 0],
                    ti: [1.589, 0.546, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 44,
                    s: [120.949, 202.245, 0],
                    to: [-1.589, -0.546, 0],
                    ti: [1.493, 0.277, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 46,
                    s: [116.315, 201.024, 0],
                    to: [-1.493, -0.277, 0],
                    ti: [1.38, 0.034, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 48,
                    s: [111.993, 200.584, 0],
                    to: [-1.38, -0.034, 0],
                    ti: [1.254, -0.168, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 50,
                    s: [108.033, 200.82, 0],
                    to: [-1.254, 0.168, 0],
                    ti: [1.122, -0.322, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 52,
                    s: [104.469, 201.591, 0],
                    to: [-1.122, 0.322, 0],
                    ti: [0.991, -0.429, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 54,
                    s: [101.304, 202.75, 0],
                    to: [-0.991, 0.429, 0],
                    ti: [0.869, -0.496, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 56,
                    s: [98.52, 204.163, 0],
                    to: [-0.869, 0.496, 0],
                    ti: [0.759, -0.533, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 58,
                    s: [96.088, 205.725, 0],
                    to: [-0.759, 0.533, 0],
                    ti: [0.662, -0.548, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 60,
                    s: [93.965, 207.359, 0],
                    to: [-0.662, 0.548, 0],
                    ti: [0.577, -0.547, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 62,
                    s: [92.114, 209.011, 0],
                    to: [-0.577, 0.547, 0],
                    ti: [0.502, -0.535, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 64,
                    s: [90.501, 210.642, 0],
                    to: [-0.502, 0.535, 0],
                    ti: [0.436, -0.515, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 66,
                    s: [89.1, 212.221, 0],
                    to: [-0.436, 0.515, 0],
                    ti: [0.376, -0.488, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 68,
                    s: [87.887, 213.729, 0],
                    to: [-0.376, 0.488, 0],
                    ti: [0.324, -0.458, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 70,
                    s: [86.842, 215.15, 0],
                    to: [-0.324, 0.458, 0],
                    ti: [0.277, -0.424, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 72,
                    s: [85.945, 216.475, 0],
                    to: [-0.277, 0.424, 0],
                    ti: [0.234, -0.388, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 74,
                    s: [85.182, 217.694, 0],
                    to: [-0.234, 0.388, 0],
                    ti: [0.195, -0.349, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 76,
                    s: [84.541, 218.8, 0],
                    to: [-0.195, 0.349, 0],
                    ti: [0.16, -0.31, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 78,
                    s: [84.009, 219.79, 0],
                    to: [-0.16, 0.31, 0],
                    ti: [0.129, -0.269, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 80,
                    s: [83.578, 220.658, 0],
                    to: [-0.129, 0.269, 0],
                    ti: [0.099, -0.226, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 82,
                    s: [83.238, 221.401, 0],
                    to: [-0.099, 0.226, 0],
                    ti: [0.072, -0.183, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 84,
                    s: [82.984, 222.016, 0],
                    to: [-0.072, 0.183, 0],
                    ti: [0.048, -0.14, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 86,
                    s: [82.806, 222.502, 0],
                    to: [-0.048, 0.14, 0],
                    ti: [0.026, -0.097, 0],
                  },
                  {
                    i: { x: 0.833, y: 1 },
                    o: { x: 0.167, y: 0 },
                    t: 88,
                    s: [82.697, 222.858, 0],
                    to: [-0.026, 0.097, 0],
                    ti: [0.005, -0.052, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.996 },
                    o: { x: 0.167, y: 0.007 },
                    t: 90,
                    s: [82.651, 223.082, 0],
                    to: [-0.005, 0.052, 0],
                    ti: [-0.014, -0.008, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.841 },
                    o: { x: 0.167, y: 0.111 },
                    t: 92,
                    s: [82.666, 223.173, 0],
                    to: [0.014, 0.008, 0],
                    ti: [-0.027, 0.032, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.948 },
                    o: { x: 0.167, y: 0.033 },
                    t: 94,
                    s: [82.736, 223.128, 0],
                    to: [0.027, -0.032, 0],
                    ti: [-0.027, 0.062, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.981 },
                    o: { x: 0.167, y: 0.015 },
                    t: 96,
                    s: [82.826, 222.979, 0],
                    to: [0.027, -0.062, 0],
                    ti: [-0.023, 0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.992 },
                    o: { x: 0.167, y: 0.007 },
                    t: 98,
                    s: [82.9, 222.757, 0],
                    to: [0.023, -0.085, 0],
                    ti: [-0.025, 0.099, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.988 },
                    o: { x: 0.167, y: 0.012 },
                    t: 100,
                    s: [82.964, 222.469, 0],
                    to: [0.025, -0.099, 0],
                    ti: [-0.032, 0.102, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.983 },
                    o: { x: 0.167, y: 0.017 },
                    t: 102,
                    s: [83.05, 222.164, 0],
                    to: [0.032, -0.102, 0],
                    ti: [-0.036, 0.101, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.021 },
                    t: 104,
                    s: [83.155, 221.857, 0],
                    to: [0.036, -0.101, 0],
                    ti: [-0.038, 0.098, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.978 },
                    o: { x: 0.167, y: 0.024 },
                    t: 106,
                    s: [83.269, 221.556, 0],
                    to: [0.038, -0.098, 0],
                    ti: [-0.038, 0.093, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.976 },
                    o: { x: 0.167, y: 0.025 },
                    t: 108,
                    s: [83.385, 221.268, 0],
                    to: [0.038, -0.093, 0],
                    ti: [-0.035, 0.085, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.977 },
                    o: { x: 0.167, y: 0.026 },
                    t: 110,
                    s: [83.497, 221, 0],
                    to: [0.035, -0.085, 0],
                    ti: [-0.031, 0.076, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.979 },
                    o: { x: 0.167, y: 0.024 },
                    t: 112,
                    s: [83.598, 220.758, 0],
                    to: [0.031, -0.076, 0],
                    ti: [-0.024, 0.065, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.984 },
                    o: { x: 0.167, y: 0.02 },
                    t: 114,
                    s: [83.682, 220.545, 0],
                    to: [0.024, -0.065, 0],
                    ti: [-0.017, 0.053, 0],
                  },
                  {
                    i: { x: 0.833, y: 0.99 },
                    o: { x: 0.167, y: 0.012 },
                    t: 116,
                    s: [83.745, 220.367, 0],
                    to: [0.017, -0.053, 0],
                    ti: [-0.006, 0.023, 0],
                  },
                  { t: 118, s: [83.781, 220.227, 0] },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.852, 3.785, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0] },
                    t: 14,
                    s: [121.49, 88.737, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.91, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.19, 0.081, 0] },
                    t: 16,
                    s: [91.076, 88.159, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.429, -3.486, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.162, -1.415, 0] },
                    t: 18,
                    s: [67.412, 108.062, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.033, 0.953, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.098, 0.085, 0] },
                    t: 20,
                    s: [65.583, 106.955, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.841, 0.807, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.024, -0.106, 0] },
                    t: 22,
                    s: [54.874, 48.475, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.733, 0.874, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.176, 0.146, 0] },
                    t: 24,
                    s: [69.879, 74.186, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.032, 1.001, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.121, 0.246, 0] },
                    t: 26,
                    s: [83.434, 108.14, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.978, 0.868, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.023, 0.001, 0] },
                    t: 28,
                    s: [113.236, 125.507, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.937, 0.727, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.03, 0.227, 0] },
                    t: 30,
                    s: [72.085, 108.002, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.63, 0.848, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.261, 0.12, 0] },
                    t: 32,
                    s: [102.383, 97.845, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.922, 0.819, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.108, 0.184, 0] },
                    t: 34,
                    s: [95.054, 74.753, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.758, 1.104, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-1.116, 0.155, 0] },
                    t: 36,
                    s: [69.833, 55.593, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-0.253, 0.933, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.127, 0.046, 0] },
                    t: 38,
                    s: [71.585, 33.206, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.962, 0.845, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.089, -0.336, 0] },
                    t: 40,
                    s: [74.924, 83.413, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.895, 1.179, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.069, 0.18, 0] },
                    t: 42,
                    s: [121.778, 73.433, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.929, 0.903, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.41, 0.057, 0] },
                    t: 44,
                    s: [96.063, 64.842, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [-2.344, 1.503, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.493, 0.61, 0] },
                    t: 46,
                    s: [89.506, 91.864, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1.002, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.085, 0.071, 0] },
                    t: 48,
                    s: [90.453, 96.143, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.913, 0.848, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0.002, 0] },
                    t: 50,
                    s: [127.532, 66.021, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.203, 1.019, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [1.831, 0.185, 0] },
                    t: 52,
                    s: [90.276, 96.965, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.094, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.059, 0.015, 0] },
                    t: 54,
                    s: [88.5, 122.317, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.722, 1.067, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.044, -0.049, 0] },
                    t: 56,
                    s: [94.599, 91.269, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.905, 0.958, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.119, 0.037, 0] },
                    t: 58,
                    s: [81.585, 110.87, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.81, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.689, -0.085, 0] },
                    t: 60,
                    s: [51.164, 75.568, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.817, 1.401, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.148, -0.141, 0] },
                    t: 62,
                    s: [46.977, 93.082, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.272, 0.996, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.153, 0.069, 0] },
                    t: 64,
                    s: [41.615, 86.585, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 0.774, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.064, -0.004, 0] },
                    t: 66,
                    s: [35.186, 124.337, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.499, 0.946, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.532, 0.132, 0] },
                    t: 68,
                    s: [62.591, 88.269, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.951, 0.921, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.071, -0.154, 0] },
                    t: 70,
                    s: [58.881, 26.321, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.072, 1.831, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.121, -1.508, 0] },
                    t: 72,
                    s: [84.818, 48.109, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.946, 0.916, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.038, 0.076, 0] },
                    t: 74,
                    s: [74.254, 46.968, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.594, 35.497, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.15, 12.359, 0] },
                    t: 76,
                    s: [93.883, 59.486, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.867, 0.926, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.105, 0.083, 0] },
                    t: 78,
                    s: [86.872, 59.571, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.076, 0.594, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.224, -0.643, 0] },
                    t: 80,
                    s: [59.763, 24.304, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.02, 0.969, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.04, 0.105, 0] },
                    t: 82,
                    s: [43.653, 28.352, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.928, 1.059, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.016, -0.049, 0] },
                    t: 84,
                    s: [74.399, 44.017, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.401, 0.902, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.523, 0.034, 0] },
                    t: 86,
                    s: [36.198, 34.152, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.943, 0.234, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.069, 0.557, 0] },
                    t: 88,
                    s: [41.448, 50.974, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.109, 1.139, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.183, 0.094, 0] },
                    t: 90,
                    s: [10.923, 53.931, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.042, 0.95, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.047, 0.052, 0] },
                    t: 92,
                    s: [20.467, 78.157, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.003, 0.801, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.028, -0.123, 0] },
                    t: 94,
                    s: [-1.575, 13.482, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.927, 0.974, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.003, 0.144, 0] },
                    t: 96,
                    s: [31.467, 39.632, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.201, 0.662, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.568, -0.037, 0] },
                    t: 98,
                    s: [-2.832, 75.796, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.885, 0.948, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.093, 0.111, 0] },
                    t: 100,
                    s: [1.556, 50.773, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.966, 0.83, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.302, -0.136, 0] },
                    t: 102,
                    s: [39.258, -25.561, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.108, 1.032, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.057, 0.164, 0] },
                    t: 104,
                    s: [53.602, 3.457, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.962, 1.046, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.092, 0.023, 0] },
                    t: 106,
                    s: [45.062, 33.613, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.964, 0.941, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.07, 0.03, 0] },
                    t: 108,
                    s: [-37.848, -8.287, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1.116, 0.998, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [-0.063, -0.206, 0] },
                    t: 110,
                    s: [7.213, 56.765, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.896, 1.241, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.049, -0.003, 0] },
                    t: 112,
                    s: [-18.526, 38.06, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.835, 0.935, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.416, 0.062, 0] },
                    t: 114,
                    s: [43.172, 56.215, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0.168, -0.296, 0] },
                    t: 116,
                    s: [58.626, -14.531, 100],
                  },
                  { t: 118, s: [73.773, 0.993, 100] },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    d: 1,
                    ty: 'el',
                    s: { a: 0, k: [10, 10], ix: 2 },
                    p: { a: 0, k: [0, 0], ix: 3 },
                    nm: 'Ellipse Path 1',
                    mn: 'ADBE Vector Shape - Ellipse',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.8196078431372549, 0.23137254901960785, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    bm: 0,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Ellipse 1',
                np: 2,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 14,
            op: 180,
            st: 14,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 0,
        nm: 'check',
        refId: 'comp_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [360, 360, 0], ix: 2 },
          a: { a: 0, k: [256, 256, 0], ix: 1 },
          s: { a: 0, k: [65, 65, 100], ix: 6 },
        },
        ao: 0,
        w: 512,
        h: 512,
        ip: 0,
        op: 241,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 0,
        nm: 'confeti',
        refId: 'comp_1',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [360, 360, 0], ix: 2 },
          a: { a: 0, k: [256, 256, 0], ix: 1 },
          s: { a: 0, k: [150, 150, 100], ix: 6 },
        },
        ao: 0,
        w: 512,
        h: 512,
        ip: 72,
        op: 272,
        st: 72,
        bm: 0,
      },
    ],
    markers: [],
  }
}
