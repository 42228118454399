import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { callingCode } from '../../Utils/callingCode'

import './profile.css'

const ContactInput = ({
  value,
  countryCode,
  placeholder,
  onChangeSelect,
  onChange,
}) => {
  const { t } = useTranslation()

  const [allCallCodes, setAllCallCode] = useState([])

  useEffect(() => {
    setAllCallCode(callingCode())
  }, [])

  return (
    <>
      <div className='profileInputOuterCon'>
        <label className={`passwordLabel`} htmlFor='contactUser'>
          <b>{t('Contact')}</b>
        </label>
        <div className='contactInputContactInnerContainer'>
          <div>
            <select onChange={onChangeSelect} id='contactUser'>
              <option value=''>{t('Country_Code')}</option>
              {allCallCodes.map((code) => (
                <option key={code.ctry} value={code.code}>
                  {code.ctry}
                </option>
              ))}
            </select>
          </div>
          <div className='contactInputInputContainer'>
            <input
              disabled={!countryCode}
              value={value}
              onChange={onChange}
              className={`form-control`}
              autoComplete='off'
              placeholder={t('without_the_country_code')}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactInput
