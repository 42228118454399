export function done() {
  return {
    v: '5.5.7',
    meta: { g: 'LottieFiles AE 0.1.20', a: '', k: '', d: '', tc: '' },
    fr: 29.9700012207031,
    ip: 0,
    op: 91.000003706506,
    w: 1080,
    h: 1080,
    nm: 'CHECK',
    ddd: 0,
    assets: [
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Shape Layer 8',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 315, ix: 10 },
              p: { a: 0, k: [552, 540, 0], ix: 2 },
              a: { a: 0, k: [-396, -26.286, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-236, -32],
                          [94.286, -32],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.432], y: [1] },
                          o: { x: [0.622], y: [0] },
                          t: 4.2,
                          s: [0],
                        },
                        { t: 14.0000005702317, s: [100] },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.442], y: [1] },
                          o: { x: [0.609], y: [0] },
                          t: 0,
                          s: [0],
                        },
                        { t: 10.2675004182038, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 65, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 94.0000038286985,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 4,
            nm: 'Shape Layer 7',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 270, ix: 10 },
              p: { a: 0, k: [552, 540, 0], ix: 2 },
              a: { a: 0, k: [-396, -26.286, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-236, -32],
                          [94.286, -32],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.432], y: [1] },
                          o: { x: [0.622], y: [0] },
                          t: 4.2,
                          s: [0],
                        },
                        { t: 14.0000005702317, s: [100] },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.442], y: [1] },
                          o: { x: [0.609], y: [0] },
                          t: 0,
                          s: [0],
                        },
                        { t: 10.2675004182038, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 65, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 94.0000038286985,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Shape Layer 6',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 225, ix: 10 },
              p: { a: 0, k: [552, 540, 0], ix: 2 },
              a: { a: 0, k: [-396, -26.286, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-236, -32],
                          [94.286, -32],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.432], y: [1] },
                          o: { x: [0.622], y: [0] },
                          t: 4.2,
                          s: [0],
                        },
                        { t: 14.0000005702317, s: [100] },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.442], y: [1] },
                          o: { x: [0.609], y: [0] },
                          t: 0,
                          s: [0],
                        },
                        { t: 10.2675004182038, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 65, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 94.0000038286985,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Shape Layer 5',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 180, ix: 10 },
              p: { a: 0, k: [552, 540, 0], ix: 2 },
              a: { a: 0, k: [-396, -26.286, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-236, -32],
                          [94.286, -32],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.432], y: [1] },
                          o: { x: [0.622], y: [0] },
                          t: 4.2,
                          s: [0],
                        },
                        { t: 14.0000005702317, s: [100] },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.442], y: [1] },
                          o: { x: [0.609], y: [0] },
                          t: 0,
                          s: [0],
                        },
                        { t: 10.2675004182038, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 65, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 94.0000038286985,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Shape Layer 4',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 135, ix: 10 },
              p: { a: 0, k: [552, 540, 0], ix: 2 },
              a: { a: 0, k: [-396, -26.286, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-236, -32],
                          [94.286, -32],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.432], y: [1] },
                          o: { x: [0.622], y: [0] },
                          t: 4.2,
                          s: [0],
                        },
                        { t: 14.0000005702317, s: [100] },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.442], y: [1] },
                          o: { x: [0.609], y: [0] },
                          t: 0,
                          s: [0],
                        },
                        { t: 10.2675004182038, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 65, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 94.0000038286985,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Shape Layer 3',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 90, ix: 10 },
              p: { a: 0, k: [552, 540, 0], ix: 2 },
              a: { a: 0, k: [-396, -26.286, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-236, -32],
                          [94.286, -32],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.432], y: [1] },
                          o: { x: [0.622], y: [0] },
                          t: 4.2,
                          s: [0],
                        },
                        { t: 14.0000005702317, s: [100] },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.442], y: [1] },
                          o: { x: [0.609], y: [0] },
                          t: 0,
                          s: [0],
                        },
                        { t: 10.2675004182038, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 65, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 94.0000038286985,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Shape Layer 2',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 45, ix: 10 },
              p: { a: 0, k: [552, 540, 0], ix: 2 },
              a: { a: 0, k: [-396, -26.286, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-236, -32],
                          [94.286, -32],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.432], y: [1] },
                          o: { x: [0.622], y: [0] },
                          t: 4.2,
                          s: [0],
                        },
                        { t: 14.0000005702317, s: [100] },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.442], y: [1] },
                          o: { x: [0.609], y: [0] },
                          t: 0,
                          s: [0],
                        },
                        { t: 10.2675004182038, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 65, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 94.0000038286985,
            st: 0,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Shape Layer 1',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [552, 540, 0], ix: 2 },
              a: { a: 0, k: [-396, -26.286, 0], ix: 1 },
              s: { a: 0, k: [70, 70, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-236, -32],
                          [94.286, -32],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'tm',
                    s: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.432], y: [1] },
                          o: { x: [0.622], y: [0] },
                          t: 4.2,
                          s: [0],
                        },
                        { t: 14.0000005702317, s: [100] },
                      ],
                      ix: 1,
                    },
                    e: {
                      a: 1,
                      k: [
                        {
                          i: { x: [0.442], y: [1] },
                          o: { x: [0.609], y: [0] },
                          t: 0,
                          s: [0],
                        },
                        { t: 10.2675004182038, s: [100] },
                      ],
                      ix: 2,
                    },
                    o: { a: 0, k: 0, ix: 3 },
                    m: 1,
                    ix: 2,
                    nm: 'Trim Paths 1',
                    mn: 'ADBE Vector Filter - Trim',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [
                        0.011764705882352941, 0.35294117647058826,
                        0.6509803921568628, 1,
                      ],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 65, ix: 5 },
                    lc: 2,
                    lj: 2,
                    bm: 0,
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Shape 1',
                np: 4,
                cix: 2,
                bm: 0,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 0,
            op: 94.0000038286985,
            st: 0,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 0,
        nm: 'Comp 2',
        refId: 'comp_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [496, 220, 0], ix: 2 },
          a: { a: 0, k: [540, 540, 0], ix: 1 },
          s: { a: 0, k: [31.111, 31.111, 100], ix: 6 },
        },
        ao: 0,
        w: 1080,
        h: 1080,
        ip: 14.0000005702317,
        op: 108.00000439893,
        st: 14.0000005702317,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 2,
        ty: 4,
        nm: 'Shape Layer 2',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [524, 552, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [100, 100, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                ind: 0,
                ty: 'sh',
                ix: 1,
                ks: {
                  a: 0,
                  k: {
                    i: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    o: [
                      [0, 0],
                      [0, 0],
                      [0, 0],
                    ],
                    v: [
                      [-156, 36],
                      [-32, 160],
                      [220, -140],
                    ],
                    c: false,
                  },
                  ix: 2,
                },
                nm: 'Path 1',
                mn: 'ADBE Vector Shape - Group',
                hd: false,
              },
              {
                ty: 'st',
                c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 59, ix: 5 },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Shape 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: { a: 0, k: 0, ix: 1 },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.221], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 28,
                  s: [0],
                },
                {
                  i: { x: [0.191], y: [1] },
                  o: { x: [0.333], y: [0] },
                  t: 44,
                  s: [100],
                },
                { t: 59.0000024031193, s: [87] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 28.0000011404634,
        op: 122.000004969162,
        st: 28.0000011404634,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 3,
        ty: 4,
        nm: 'Shape Layer 1',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [540, 540, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: { a: 0, k: [60, 60, 100], ix: 6 },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [1080, 1080], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [
                    0.011764705882352941, 0.35294117647058826,
                    0.6509803921568628, 1,
                  ],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 65, ix: 5 },
                lc: 2,
                lj: 2,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
          {
            ty: 'tm',
            s: { a: 0, k: 0, ix: 1 },
            e: {
              a: 1,
              k: [
                {
                  i: { x: [0.113], y: [1] },
                  o: { x: [0.647], y: [0] },
                  t: 0,
                  s: [0],
                },
                { t: 22.0000008960784, s: [100] },
              ],
              ix: 2,
            },
            o: { a: 0, k: 0, ix: 3 },
            m: 1,
            ix: 2,
            nm: 'Trim Paths 1',
            mn: 'ADBE Vector Filter - Trim',
            hd: false,
          },
        ],
        ip: 0,
        op: 94.0000038286985,
        st: 0,
        bm: 0,
      },
      {
        ddd: 0,
        ind: 4,
        ty: 4,
        nm: 'Shape Layer 5',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [540, 540, 0], ix: 2 },
          a: { a: 0, k: [0, 0, 0], ix: 1 },
          s: {
            a: 1,
            k: [
              {
                i: { x: [0.321, 0.321, 0.667], y: [1, 1, 1] },
                o: { x: [0.725, 0.725, 0.333], y: [0, 0, 0] },
                t: 17,
                s: [0, 0, 100],
              },
              { t: 40.0000016292334, s: [60, 60, 100] },
            ],
            ix: 6,
          },
        },
        ao: 0,
        shapes: [
          {
            ty: 'gr',
            it: [
              {
                d: 1,
                ty: 'el',
                s: { a: 0, k: [1080, 1080], ix: 2 },
                p: { a: 0, k: [0, 0], ix: 3 },
                nm: 'Ellipse Path 1',
                mn: 'ADBE Vector Shape - Ellipse',
                hd: false,
              },
              {
                ty: 'st',
                c: {
                  a: 0,
                  k: [0.956862984452, 0.145097994337, 0.203922002456, 1],
                  ix: 3,
                },
                o: { a: 0, k: 100, ix: 4 },
                w: { a: 0, k: 0, ix: 5 },
                lc: 1,
                lj: 1,
                ml: 4,
                bm: 0,
                nm: 'Stroke 1',
                mn: 'ADBE Vector Graphic - Stroke',
                hd: false,
              },
              {
                ty: 'fl',
                c: {
                  a: 0,
                  k: [
                    0.011764705882352941, 0.35294117647058826,
                    0.6509803921568628, 1,
                  ],
                  ix: 4,
                },
                o: { a: 0, k: 100, ix: 5 },
                r: 1,
                bm: 0,
                nm: 'Fill 1',
                mn: 'ADBE Vector Graphic - Fill',
                hd: false,
              },
              {
                ty: 'tr',
                p: { a: 0, k: [0, 0], ix: 2 },
                a: { a: 0, k: [0, 0], ix: 1 },
                s: { a: 0, k: [100, 100], ix: 3 },
                r: { a: 0, k: 0, ix: 6 },
                o: { a: 0, k: 100, ix: 7 },
                sk: { a: 0, k: 0, ix: 4 },
                sa: { a: 0, k: 0, ix: 5 },
                nm: 'Transform',
              },
            ],
            nm: 'Ellipse 1',
            np: 3,
            cix: 2,
            bm: 0,
            ix: 1,
            mn: 'ADBE Vector Group',
            hd: false,
          },
        ],
        ip: 17.0000006924242,
        op: 111.000004521123,
        st: 17.0000006924242,
        bm: 0,
      },
    ],
    markers: [],
  }
}
