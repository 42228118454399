import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { useEffect, useState } from "react";
import io from "socket.io-client";
import logger from "./Services/loggerService";
import ReactGA from "react-ga";
import addNotification from "react-push-notification";
import { Notifications } from "react-push-notification";
import _ from "lodash";
import Normalize from "react-normalize";

import ProtectedRoute from "./Utils/ProtectedRoute";
import Navbar from "./components/Home/Navbar";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import Footer2 from "./components/Home/Footer2";
import SignUpScreen from "./screens/SignUpScreen";
import ProfileScreen from "./screens/ProfileScreen";
import ListingDetails from "./screens/ListingDetails";
import AddListing from "./screens/AddListing";
import ListingScreen from "./screens/ListingScreen";
import Applications from "./screens/Applications";
import NotFoundScreen from "./screens/NotFoundScreen";
import LogoutScreen from "./screens/LogoutScreen";
import MessagesScreen from "./screens/MessagesScreen";
import SearchListing from "./screens/SearchListing";
import HousesForRent from "./screens/HousesForRent";
import HouseForSell from "./screens/HouseForSell";
import LandForRent from "./screens/LandForRent";
import LandForSell from "./screens/LandForSell";
import ApplicationsTenant from "./screens/ApplicationsTenant";
import LeasesScreen from "./screens/LeasesScreen";
import LeaseSignDetails from "./screens/LeaseSignDetails";
import ManageTenants from "./screens/ManageTenants";
import ManageTenantDetail from "./screens/ManageTenantDetail";

import AddListingManagement from "./screens/AddListingManagement";
import ListingScreenManagement from "./screens/ListingScreenManagement";
import SignUpWithGoogle from "./screens/SignUpWithGoogle";
import { getCurrentUser } from "./Services/authService";
import {
  getApprovedStatusByTenantId,
  // getApprovedStatusByTenantId,
  getApprovedStatusByTenantId1,
  getStatus,
  getStatusByApplicantsId,
  putStatus,
} from "./Services/applicationStatus";
import UnderContruction from "./components/UnderConstruction/UnderContruction";
import MessagesTenant from "./screens/MessagesTenant";
import MessageDetailsScreen from "./screens/MessageDetailsScreen";
import MessageDetLandlord from "./screens/MessageDetLandlord";
import ManageMessages from "./screens/ManageMessages";
import ManagementMessageDet from "./screens/ManageMessageDet";
import { getRecipientUnreadMsgById } from "./Services/messageService";
import SignUpWithGoogle1 from "./screens/SignUpWithGoogle1";
import AboutUsCrew from "./screens/AboutUsCrew";
import FAQ from "./screens/FAQ";

import UnreadMsgsContext from "./unreadmessages/context";
import langContext from "./unreadmessages/langContext";

import LoginWithGoogleScreen from "./screens/LoginWithGoogleScreen";
import SignupWithFacebook from "./screens/SignupWithFacebook";
import SignupWithFacebook1 from "./screens/SignupWithFacebook1";
import LoginWithFacebook from "./screens/LoginWithFacebook";
import LoginForgotPassword from "./screens/LoginForgotPassword";
import LoginForgotPassMsg from "./screens/LoginForgotPassMsg";
import LoginNewPassword from "./screens/LoginNewPassword";
import LoginSuccessChange from "./screens/LoginSuccessChange";

import RentAndSalePlatform from "./screens/RentAndSalePlatform";
import ConsultingService from "./screens/ConsultingService";
import PropertyManagement from "./screens/PropertyManagement";
import AboutUs from "./screens/AboutUs";
import OurStory from "./screens/OurStory";
import Careers from "./screens/Careers";
import Blog from "./screens/Blog";
import Newsroom from "./screens/Newsroom";
import ContactCaptivator from "./screens/ContactCaptivator";
import Maintanance from "./screens/Maintanance";
import PaymentScreen from "./screens/PaymentScreen";
import { getTrackingCode } from "./Services/googleTrackingCode";
import AboutUsOwnership from "./screens/AboutUsOwnership";
import BlogDetailsScreen from "./screens/BlogDetailsScreen";
import BlogAddStory from "./screens/BlogAddStory";
import NewsroomAddRelease from "./screens/NewsroomAddRelease";
import NewsroomPressRelease from "./screens/NewsroomPressRelease";
import NewsroomCompanyInfo from "./screens/NewsroomCompanyInfo";
import NewsroomAware from "./screens/NewsroomAware";
import NewsroomResearch from "./screens/NewsroomResearch";
import NewsroomOurProduct from "./screens/NewsroomOurProduct";
import NewsroomDetails from "./screens/NewsroomDetails";
import RentAndSaleDetails from "./screens/RentAndSaleDetails";
import Policies from "./screens/Policies";
import TermUse from "./screens/TermUse";
import PolicyDetails from "./screens/PolicyDetails";
import RentAndSaleArticlePost from "./screens/RentAndSaleArticlePost";
import PropertyManagementDet from "./screens/PropertyManagementDet";
import ManageTenantDetailNonMem from "./screens/ManageTenantDetailNonMem";
import NoWifi from "./components/Home/NoWifi";
import LeaseSignStripeSuccess from "./screens/LeaseSignStripeSuccess";
import LeaseSignStripeCancel from "./screens/LeaseSignStripeCancel";
import PaypalSuccess from "./screens/PaypalSuccess";
import PaypalCancel from "./screens/PaypalCancel";
import { subscribeUser } from "./Utils/subscription";
import { getApplications, getListings } from "./Services/listings";
import { getTenantById, updateTenant } from "./Services/tenantService";
import { getLandlordsPayments } from "./Services/payment";
import EditAvailableSpace from "./screens/EditAvailableSpace";

function App() {
  const [unreadMsgs, setUnreadMsgs] = useState([]);
  const [myListings, setMyListings] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [allPayments, setAllPayments] = useState([]);
  const [applications, setApplications] = useState([]);
  const [myLeaseSigned, setMyLeaseSigned] = useState([]);
  const [tenantApplications, setTenantApplications] = useState([]);
  const [myLeasesApplicationsOwed, setMyLeasesApplicationsOwed] = useState([]);

  const [lan, setLan] = useState("");
  const [underConstruction, setUnderConstruction] = useState(false);
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [cancelAgreement, setCancelAgreement] = useState(false);
  const [cancelData, setCancelData] = useState();
  const [online, setOnline] = useState();

  useEffect(() => {
    setLocalStorage();
    setOnline(navigator.onLine);
    setUnderConstruction(false);

    const socket = io("/");
    //socket to get instant under review data for a user
    socket.on(`${getCurrentUser() && getCurrentUser().id}myReview`, (data) => {
      if (data) {
        localStorage.setItem(
          `${getCurrentUser() && getCurrentUser().id}myReviews`,
          JSON.stringify(data)
        );
      }
    });

    //funtion to get the lasted message send to the use
    socket.on(`${getCurrentUser() && getCurrentUser().id}latestMsg`, (data) => {
      const myVideoToPlay = document.getElementById("myVideoPlay");
      myVideoToPlay && myVideoToPlay.play();
      setUnreadMsgs(data);
      const sortedListing = _.orderBy(
        data,
        [sortColumn.path],
        [sortColumn.order]
      );
      if (window.navigator.userAgentData.mobile) {
        window.navigator.vibrate(300);
      }
    });

    socket.on(
      `${getCurrentUser() && getCurrentUser().id}tenant_cancelled_agreement`,
      (data) => {
        setCancelData(data);
        setCancelAgreement(true);
        setInterval(() => {
          setCancelAgreement(false);
        }, 10000);
      }
    );

    const gooogleTrackingCode = async () => {
      ////track user with google analytics
      const { data: tracker } = await getTrackingCode();
      tracker && ReactGA.initialize(tracker);
      tracker &&
        ReactGA.pageview(window.location.pathname + window.location.search);
    };
    gooogleTrackingCode();

    return async () => {
      //funtion to get the lasted message send to the use
      socket.on(
        `${getCurrentUser() && getCurrentUser().id}latestMsg`,
        (data) => {
          const myVideoToPlay = document.getElementById("myVideoPlay");
          myVideoToPlay && myVideoToPlay.play();
          const sortedListing = _.orderBy(
            data,
            [sortColumn.path],
            [sortColumn.order]
          );
          if (window.navigator.userAgentData.mobile) {
            window.navigator.vibrate(300);
          }
        }
      );
    };
  }, []);

  const setLocalStorage = async () => {
    try {
      if (getCurrentUser()) {
        //funtion to get all unread messages for a given user
        const { data: unreadMsg } = await getRecipientUnreadMsgById(
          getCurrentUser().id
        );
        setUnreadMsgs(unreadMsg);
        subscribeUser();
      }

      //funtion to get all listings for a given user
      if (getCurrentUser()?.post_rent_property === "I AM A PROPERTY OWNER") {
        await updateTenant();
        const { data: allListings } = await getListings();
        const usersListings = allListings.filter(
          (listing) => listing.list_owner_id === getCurrentUser().id
        );
        const allUndeletedListings = usersListings.filter(
          (listing) =>
            listing.status !== "deleted" && listing.publish !== "publish"
        );
        setMyListings(allUndeletedListings);

        /////get all non signed lease applications by landlord but signed by tenant that are approved
        const { data: allStatus } = await getStatus();
        const myStatus = allStatus.filter(
          (d) =>
            d.list_owner_id === getCurrentUser()?.id &&
            !d.deleted &&
            d.approved == "approved" &&
            d.tenant_sign_lease == true &&
            d.landlord_sign_lease !== true
        );
        setApplications(myStatus);

        ///////get all tenants owing money
        const { data: ten } = await getTenantById(getCurrentUser().id);
        setTenants(ten.filter((t) => t.amount_owed > 0));

        const dateNow = new Date().toISOString();
        const { data: allMyPayments } = await getLandlordsPayments();
        setAllPayments(
          allMyPayments.filter(
            (p) => p.create_date.slice(0, 10) === dateNow.slice(0, 10)
          )
        );
      } else {
        await putStatus();

        ///////get all applications that are approved by landlord but not signed by tenant
        const { data: myReviews } = await getApprovedStatusByTenantId();
        const myUnSignedLeased = myReviews?.filter(
          (d) =>
            d.tenant_sign_lease !== true &&
            d.landlord_sign_lease !== true &&
            d.deleted !== true
        );
        setMyLeaseSigned(myUnSignedLeased);

        const { data } = await getApplications();
        const myAppl = data.filter(
          (d) =>
            d.applicant_id === getCurrentUser()?.id && d.tenant_delete !== true
        );
        setTenantApplications(myAppl);

        const { data: myLeasesAppl } = await getStatusByApplicantsId(
          getCurrentUser().id
        );

        const sortedReviewLease = _.orderBy(
          myLeasesAppl,
          [sortColumn.path],
          [sortColumn.order]
        );

        ////tenant get all leases applications that are owed
        const leaseOwing = sortedReviewLease.filter(
          (p) => Number(p.amount_owed) > 0
        );
        setMyLeasesApplicationsOwed(leaseOwing);
      }
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        console.log("error from app.js", ex.response.data);
      }
    }
  };

  function isOnline() {
    return navigator.onLine;
  }

  return (
    <>
      <Normalize />
      <div>
        <audio
          style={{ display: "none" }}
          id="myVideoPlay"
          src="/images/sms2.mp3"
        ></audio>
      </div>

      <UnreadMsgsContext.Provider
        value={{
          unreadMsgs,
          setUnreadMsgs,
          myListings,
          setMyListings,
          applications,
          setApplications,
          tenants,
          setTenants,
          allPayments,
          setAllPayments,
          myLeaseSigned,
          setMyLeaseSigned,
          tenantApplications,
          setTenantApplications,
          myLeasesApplicationsOwed,
          setMyLeasesApplicationsOwed,
        }}
      >
        <langContext.Provider value={{ lan, setLan }}>
          <Navbar />
          {!isOnline && (
            <div className="offlineSignal">
              <NoWifi />
              <div>Your Device is Offline, Refresh</div>
            </div>
          )}

          <Notifications />

          {underConstruction ? (
            <UnderContruction />
          ) : (
            <Switch>
              <Route exact path="/" component={HomeScreen} />
              <Route path="/login/google" component={LoginWithGoogleScreen} />
              <Route path="/login/facebook" component={LoginWithFacebook} />
              <Route path="/login" component={LoginScreen} />
              <Route path="/signUp" component={SignUpScreen} />
              <Route path="/rent-a-house" component={HousesForRent} />
              <Route path="/buy-a-house" component={HouseForSell} />
              <Route path="/rent-a-land" component={LandForRent} />
              <Route path="/buy-a-land" component={LandForSell} />
              <Route path="/google_signUp1" component={SignUpWithGoogle} />
              <Route path="/google_signUp2" component={SignUpWithGoogle1} />
              <Route path="/facebook_signUp1" component={SignupWithFacebook1} />
              <Route path="/facebook_signUp2" component={SignupWithFacebook} />
              <Route path="/fogot_password" component={LoginForgotPassword} />
              <Route path="/go-to-email" component={LoginForgotPassMsg} />
              <Route path="/newPassword/:id" component={LoginNewPassword} />
              <Route
                path="/changed-successfully"
                component={LoginSuccessChange}
              />

              <ProtectedRoute
                path="/portal/add/edit_available_space/:id"
                component={EditAvailableSpace}
              />

              <ProtectedRoute
                path="/rent-and-sale-platform/publish/:id"
                component={RentAndSaleArticlePost}
              />
              <ProtectedRoute
                path="/stripe/cancel/:id"
                component={LeaseSignStripeCancel}
              />

              <ProtectedRoute
                path="/stripe/success/:id/:amount/:card_num/:eurExchange/:amountUSD"
                component={LeaseSignStripeSuccess}
              />

              <ProtectedRoute
                path="/payment/paypal/success/:id/:amount/:paypal_account/:eurExchange/:amountUSD/:listingExchange"
                component={PaypalSuccess}
              />
              {/* payment/paypal/success/:lease_signed_id/:amount/:paypal_account/:currentEuroExchangeRate/:amountToUSD/:listingExchange */}
              <ProtectedRoute
                path="/payment/paypal/cancel/:id"
                component={PaypalCancel}
              />

              <ProtectedRoute
                path="/rent-and-sale-platform/publish"
                component={RentAndSaleArticlePost}
              />
              <Route
                path="/rent-and-sale-platform/:id"
                component={RentAndSaleDetails}
              />
              <Route
                path="/rent-and-sale-platform"
                component={RentAndSalePlatform}
              />
              <Route path="/consulting-service" component={ConsultingService} />
              <Route
                path="/property-management/id"
                component={PropertyManagementDet}
              />
              <Route
                path="/property-management"
                component={PropertyManagement}
              />
              <Route path="/about-us" component={AboutUs} />
              <Route path="/ownership" component={AboutUsOwnership} />
              <Route path="/crew" component={AboutUsCrew} />
              <Route path="/our-story" component={OurStory} />
              <Route path="/careers" component={Careers} />
              <Route path="/blog/:id" component={BlogDetailsScreen} />
              <Route path="/blog" component={Blog} />
              <Route path="/addBlog/:id" component={BlogAddStory} />
              <Route path="/addBlog" component={BlogAddStory} />
              <Route path="/faq" component={FAQ} />
              <Route path="/newsroom/add/:id" component={NewsroomAddRelease} />
              <Route path="/newsroom/add" component={NewsroomAddRelease} />
              <Route
                path="/newsroom/company-info"
                component={NewsroomCompanyInfo}
              />
              <Route path="/policies/details" component={PolicyDetails} />
              <Route path="/policies" component={Policies} />
              <Route path="/terms-of-use" component={TermUse} />
              <Route path="/newsroom/research" component={NewsroomResearch} />
              <Route path="/newsroom/awards" component={NewsroomAware} />
              <Route path="/newsroom/products" component={NewsroomOurProduct} />
              <Route
                path="/newsroom/press-release"
                component={NewsroomPressRelease}
              />
              <Route path="/newsroom/:id" component={NewsroomDetails} />
              <Route path="/newsroom" component={Newsroom} />
              <Route path="/contactUsNow" exact component={ContactCaptivator} />

              <Route path="/listing/:id" component={ListingDetails} />
              <ProtectedRoute path="/profile" component={ProfileScreen} />

              <ProtectedRoute
                path="/portal/list/:page"
                component={ListingScreen}
              />
              <ProtectedRoute path="/portal/add/:page" component={AddListing} />
              <ProtectedRoute path="/edit_listing/:id" component={AddListing} />
              <ProtectedRoute
                path="/portal/application/:page"
                component={Applications}
              />
              <ProtectedRoute
                path="/portal/messages/message/:id"
                component={MessageDetLandlord}
              />
              <ProtectedRoute
                path="/portal/messages/:page"
                component={MessagesScreen}
              />
              <ProtectedRoute
                path="/portal/payment"
                component={PaymentScreen}
              />

              <ProtectedRoute
                path="/portal/tenant/applications"
                component={ApplicationsTenant}
              />
              <ProtectedRoute
                path="/portal/tenant/leases/:id"
                component={LeaseSignDetails}
              />
              <ProtectedRoute
                path="/portal/tenant/leases"
                component={LeasesScreen}
              />
              <ProtectedRoute
                path="/portal/tenants/message/:id"
                component={MessageDetailsScreen}
              />
              <ProtectedRoute
                path="/portal/tenants/message"
                component={MessagesTenant}
              />

              <ProtectedRoute
                path="/manage/edit_listing/:id"
                component={AddListingManagement}
              />
              <ProtectedRoute
                path="/portal/tenants/:id"
                component={ManageTenantDetail}
              />
              <ProtectedRoute
                path="/portal/tenants_nonmember/:id"
                component={ManageTenantDetailNonMem}
              />

              <ProtectedRoute
                path="/portal/tenants"
                component={ManageTenants}
              />
              <ProtectedRoute
                path="/management/add_listing"
                component={AddListingManagement}
              />
              <ProtectedRoute
                path="/management/message/:id"
                component={ManagementMessageDet}
              />
              <ProtectedRoute
                path="/management/message"
                component={ManageMessages}
              />
              <ProtectedRoute
                path="/management/edit_listing/:id"
                component={AddListingManagement}
              />
              <ProtectedRoute
                path="/management/listing"
                component={ListingScreenManagement}
              />
              <ProtectedRoute
                path="/portal/maintenance"
                component={Maintanance}
              />

              <Route path="/search_listing" component={SearchListing} />
              <Route
                path="/logout"
                render={(props) => <LogoutScreen {...props} />}
              />
              <Route path="/notFound" component={NotFoundScreen} />
              <Redirect to="/notFound" />
              {/* <Redirect from="/logout" to="/" /> */}
            </Switch>
          )}

          <Footer2 />
        </langContext.Provider>
      </UnreadMsgsContext.Provider>
    </>
  );
}

export default withRouter(App);
