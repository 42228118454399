import React from 'react'

import "./messageDetail.css"

const Msg = ({message}) => {
return (
<>
<p className="displayTheMessageHere">{message}</p>                                         
</>
)
}

export default Msg
