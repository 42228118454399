import React from "react";

import "./addListing.css";
import { useTranslation } from "react-i18next";

const Checkbox = ({ onChange, value, onClickCheckBox }) => {
  const { t } = useTranslation();
  return (
    <div onClick={onClickCheckBox} className="addListCheckboxCon">
      <div className="inputCon">
        <input checked={value} type="checkbox" onChange={onChange} />
      </div>
      <div className="paraCon">
        <p>{t("Click Checkbox to Publish")}</p>
      </div>
    </div>
  );
};

export default Checkbox;
