import React from "react";

import PropBodyTitle from "./PropBodyTitle";
import PropJoinAgent from "./PropJoinAgent";
import PropManageCard from "./PropManageCard";
import PropManageCard1 from "./PropManageCard1";
import PropertyListItem from "./PropertyListItem";
import DownloadApp from "../AboutUs/DownloadApp";

const PropBody = ({ agents }) => {
  return (
    <div className="propManBodyCon">
      <DownloadApp />
      <PropBodyTitle />
      {/* <PropCarousel agents={agents} /> */}
      <hr style={{ margin: "50px auto", width: "25%" }} />
      <PropManageCard />
      <hr style={{ margin: "50px auto", width: "25%" }} />
      <PropManageCard1 />
      <hr style={{ margin: "50px auto", width: "25%" }} />
      <PropManageCard
        src="../../images/realtor.jpg"
        content="Our focus is on the local market. Every community is staffed by the very
      best real estate professionals; each team member is specially trained to
      think like an owner and take pride in resident satisfaction."
      />
      <hr style={{ margin: "50px auto", width: "25%" }} />
      <PropManageCard1
        src="../../images/rent_house.jpg"
        content="Prospective residents can easily search for the perfect rental through NawAfrica.com which offers up-to-date real estate listings and detailed information about properties that are managed both by us and third parties."
      />
      <hr style={{ margin: "50px auto", width: "25%" }} />
      <PropJoinAgent />
      {/* <hr style={{ margin: '25px auto' }} /> */}
      {/* <PropertyListItem /> */}
      <DownloadApp />
    </div>
  );
};

export default PropBody;
