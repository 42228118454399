import React from "react";

const CustomAlert = ({ data }) => {
  return (
    <div
      style={{
        width: "20%",
        padding: "10px",
        position: "absolute",
        top: "110px",
        left: "40%",
        borderRadius: "10px",
        textAlign: "center",
        fontSize: "14px",
        font: "normal normal normal 14px/32px Merriweather",
      }}
      className="alert alert-success"
    >
      {data}
    </div>
  );
};

export default CustomAlert;
