import React from 'react'

const ConsultContent = () => {
  return (
    <div className='consultantSerContentOuterCon'>
      <h2
        style={{
          textAlign: 'center',
          fontSize: '28px',
          lineHeight: '1.7',
          fontFamily: "'DM Serif Display', serif",
          marginBottom: '20px',
          opacity: '0.8',
        }}
      >
        Enabling clients to cultivate greater economic and societal returns in
        the built environment
      </h2>
      <div style={{ opacity: '0.8', width: '100%' }}>
        <p style={{ fontSize: '17px', lineHeight: '1.8' }}>
          Real estate is fundamental to the human experience. It’s where the
          world spends 90 percent of its time living, working, shopping, and
          playing. But society’s needs, expectations, and wants of real estate
          have permanently evolved, driven by the COVID-19 pandemic, climate
          change, and increasing economic uncertainty.
        </p>
        <p style={{ fontSize: '17px', lineHeight: '1.8' }}>
          McKinsey's Real Estate Practice works with investors, property owners,
          real estate managers, and the world's leading organizations who are
          the primary occupiers and users of space to lean into these
          disruptions and generate sustainable growth opportunities that benefit
          the businesses, environments, and communities they serve.
        </p>
        <p style={{ fontSize: '17px', lineHeight: '1.8' }}>
          With proprietary technology, global scale, and local expertise, we are
          uniquely equipped to embolden clients to embrace disruption, pioneer
          innovation, and shape a new future for themselves and for the
          industry.
        </p>
        <p style={{ marginTop: '50px' }}>We have extensive experience in:</p>
        <div>
          <ul>
            <li>Tenant representation</li>
            <li>Landlord representation</li>
            <li>Property acquisition and disposition</li>
            <li>Real estate development</li>
            <li>Project/construction management</li>
            <li>Finance</li>
            <li>Strategic consulting</li>
            <li>Property management</li>
            <li>Asset management</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ConsultContent
