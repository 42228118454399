import React from 'react'

const ArticleSelect = ({
  title,
  id,
  placeholder,
  onChange,
  items = [],
  value,
}) => {
  return (
    <div className='rentSaleAddSelect'>
      <label style={{ opacity: '1' }} htmlFor={id}>
        {title}
      </label>
      <select
        style={{ cursor: 'pointer' }}
        className='form-control'
        id={id}
        onChange={onChange}
        value={value}
      >
        {items.map((item) => (
          <option key={item}>{item}</option>
        ))}
      </select>
    </div>
  )
}

export default ArticleSelect
