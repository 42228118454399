import React from 'react'

const AddStorySelect = ({
  onChange,
  value,
  width = '50%',
  catergories = [],
  label = 'Story Catergory',
}) => {
  return (
    <div style={{ marginTop: '5px', width }}>
      <label
        style={{ fontWeight: 'bold' }}
        htmlFor={'Catergory'}
        className='form-label'
      >
        {label}
      </label>
      <select
        value={value}
        onChange={onChange}
        type='text'
        className='form-control'
        id='Catergory'
        style={{ borderRadius: '10px', cursor: 'pointer' }}
      >
        <option value=''>Story Catergory</option>
        {catergories.map((c) => (
          <option key={c} value={c}>
            {c}
          </option>
        ))}
      </select>
    </div>
  )
}

export default AddStorySelect
