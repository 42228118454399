import React, { useEffect, useState } from "react";

import ConsultantServiceHead from "../components/contactCaptivator/ConsultantServiceHead";
import HelpInfo from "../components/contactCaptivator/HelpInfo";
import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import ConsultServiceCards from "../components/ConsultingService/ConsultServiceCards";
import ConsultContent from "../components/ConsultingService/ConsultContent";
import "../components/ConsultingService/ConsultingService.css";
import Meta from "./../Utils/Meta";
import Meta1 from "../Utils/Meta1";
import DownloadApp from "../components/AboutUs/DownloadApp";

const ConsultingService = () => {
  const [underConstruction, setUnderConstruction] = useState(false);

  useEffect(() => {
    setUnderConstruction(false);

    window.scrollTo(0, 0);
  }, []);

  if (underConstruction)
    return (
      <>
        <Meta title="Nawafrica|consulting services" />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <>
      <Meta1
        title="Nawafrica | consulting services"
        description="Nawafrica consulting Service"
        keywords="Nawafrica Consulting Service"
        link="/consulting-service"
      />
      <div className="consultingServicesOuterCon">
        <ConsultantServiceHead />
        <ConsultContent />
        <HelpInfo />
        <ConsultServiceCards />
        <DownloadApp />
      </div>
    </>
  );
};

export default ConsultingService;
