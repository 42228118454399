import React, { useState } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { FaAngleLeft } from 'react-icons/fa'
import _ from 'lodash'

import './styleCompo.css'

const Pagination = ({
  itemsCount,
  pageSize,
  currentPage,
  onPageChange,
  nextPage,
  previousPage,
}) => {
  const [pages1To3, setPages1To3] = useState(true)
  const [pages1To3Prev, setPages1To3Prev] = useState(false)

  const [pages3To6, setPages3To6] = useState(false)
  const [pages3To6Prev, setPages3To6Prev] = useState(false)

  const [pages6To9, setPages6To9] = useState(false)
  const [pages6To9Prev, setPages6To9Prev] = useState(false)

  const [pages9To12, setPages9To12] = useState(false)
  const [pages9To12Prev, setPages9To12Prev] = useState(false)

  const [pages12To15, setPages12To15] = useState(false)
  const [pages12To15Prev, setPages12To15Prev] = useState(false)

  const [pages15To18, setPages15To18] = useState(false)
  const [pages15To18Prev, setPages15To18Prev] = useState(false)

  const [pages18To21, setPages18To21] = useState(false)
  const [pages18To21Prev, setPages18To21Prev] = useState(false)

  const [pages21To24, setPages21To24] = useState(false)
  const [pages21To24Prev, setPages21To24Prev] = useState(false)

  const [pages24To26, setPages24To26] = useState(false)
  const [pagesToView, setPagesToView] = useState([])

  const pagesCount = Math.ceil(itemsCount / pageSize)
  if (pagesCount === 1) return null
  let pages = _.range(1, pagesCount + 1)
  const initialPages = pages.slice(0, 3)

  const page1To3 = () => {
    setPages1To3(true)
    setPages3To6(false)
    setPages6To9(false)
    setPages9To12(false)
    setPages12To15(false)
    setPages15To18(false)
    setPages18To21(false)
    setPages21To24(false)
    setPages24To26(false)

    setPages1To3Prev(false)
    setPages3To6Prev(false)
    setPages6To9Prev(false)
    setPages9To12Prev(false)
    setPages12To15Prev(false)
    setPages15To18Prev(false)
    setPages18To21Prev(false)
    setPages21To24Prev(false)

    const pagesTo = pages.slice(0, 3)
    setPagesToView(pagesTo)
  }

  const page3To6 = () => {
    setPages1To3(false)

    setPages3To6(true)
    setPages6To9(false)
    setPages9To12(false)
    setPages12To15(false)
    setPages15To18(false)
    setPages18To21(false)
    setPages21To24(false)
    setPages24To26(false)

    setPages1To3Prev(true)
    setPages3To6Prev(false)
    setPages6To9Prev(false)
    setPages9To12Prev(false)
    setPages12To15Prev(false)
    setPages15To18Prev(false)
    setPages18To21Prev(false)
    setPages21To24Prev(false)

    const pagesTo = pages.slice(3, 6)
    setPagesToView(pagesTo)
  }

  const page6To9 = () => {
    setPages1To3(false)
    setPages3To6(false)
    setPages6To9(true)

    setPages9To12(false)
    setPages12To15(false)
    setPages15To18(false)
    setPages18To21(false)
    setPages21To24(false)
    setPages24To26(false)

    setPages3To6Prev(true)
    setPages1To3Prev(false)
    setPages6To9Prev(false)
    setPages9To12Prev(false)
    setPages12To15Prev(false)
    setPages15To18Prev(false)
    setPages18To21Prev(false)
    setPages21To24Prev(false)

    const pagesTo = pages.slice(6, 9)
    setPagesToView(pagesTo)
  }

  const page9To12 = () => {
    setPages1To3(false)
    setPages3To6(false)
    setPages6To9(false)
    setPages9To12(true)
    setPages12To15(false)
    setPages15To18(false)
    setPages18To21(false)
    setPages21To24(false)
    setPages24To26(false)

    setPages6To9Prev(true)
    setPages3To6Prev(false)
    setPages1To3Prev(false)
    setPages9To12Prev(false)
    setPages12To15Prev(false)
    setPages15To18Prev(false)
    setPages18To21Prev(false)
    setPages21To24Prev(false)

    const pagesTo = pages.slice(9, 12)
    setPagesToView(pagesTo)
  }

  const page12To15 = () => {
    setPages1To3(false)
    setPages3To6(false)
    setPages6To9(false)
    setPages9To12(false)
    setPages12To15(true)
    setPages15To18(false)
    setPages18To21(false)
    setPages21To24(false)
    setPages24To26(false)

    setPages6To9Prev(false)
    setPages3To6Prev(false)
    setPages1To3Prev(false)
    setPages9To12Prev(true)
    setPages12To15Prev(false)
    setPages15To18Prev(false)
    setPages18To21Prev(false)
    setPages21To24Prev(false)

    const pagesTo = pages.slice(12, 15)
    setPagesToView(pagesTo)
  }

  const page15To18 = () => {
    setPages1To3(false)
    setPages3To6(false)
    setPages6To9(false)
    setPages9To12(false)
    setPages12To15(false)
    setPages15To18(true)
    setPages18To21(false)
    setPages21To24(false)
    setPages24To26(false)

    setPages6To9Prev(false)
    setPages3To6Prev(false)
    setPages1To3Prev(false)
    setPages9To12Prev(false)
    setPages12To15Prev(true)
    setPages15To18Prev(false)
    setPages18To21Prev(false)
    setPages21To24Prev(false)

    const pagesTo = pages.slice(15, 18)
    setPagesToView(pagesTo)
  }

  const page18To21 = () => {
    setPages1To3(false)
    setPages3To6(false)
    setPages6To9(false)
    setPages9To12(false)
    setPages12To15(false)
    setPages15To18(false)
    setPages18To21(true)
    setPages21To24(false)
    setPages24To26(false)

    setPages6To9Prev(false)
    setPages3To6Prev(false)
    setPages1To3Prev(false)
    setPages9To12Prev(false)
    setPages12To15Prev(false)
    setPages15To18Prev(true)
    setPages18To21Prev(false)
    setPages21To24Prev(false)

    const pagesTo = pages.slice(18, 21)
    setPagesToView(pagesTo)
  }

  const page21To24 = () => {
    setPages1To3(false)
    setPages3To6(false)
    setPages6To9(false)
    setPages9To12(false)
    setPages12To15(false)
    setPages15To18(false)
    setPages18To21(false)
    setPages21To24(true)
    setPages24To26(false)

    setPages6To9Prev(false)
    setPages3To6Prev(false)
    setPages1To3Prev(false)
    setPages9To12Prev(false)
    setPages12To15Prev(false)
    setPages15To18Prev(false)
    setPages18To21Prev(true)
    setPages21To24Prev(false)

    const pagesTo = pages.slice(21, 24)
    setPagesToView(pagesTo)
  }

  const page24To26 = () => {
    setPages1To3(true)
    setPages3To6(false)
    setPages6To9(false)
    setPages9To12(false)
    setPages12To15(false)
    setPages15To18(false)
    setPages18To21(false)
    setPages21To24(false)
    setPages24To26(false)

    setPages6To9Prev(false)
    setPages3To6Prev(false)
    setPages1To3Prev(false)
    setPages9To12Prev(false)
    setPages12To15Prev(false)
    setPages15To18Prev(false)
    setPages18To21Prev(false)
    setPages21To24Prev(true)

    const pagesTo = pages.slice(24, 26)
    setPagesToView(pagesTo)
  }

  const lastPage = () => {
    return pages.slice(0, 26).slice(pages.length - 1, pages.length)
  }

  return (
    <div
      style={{
        // padding: '5px 5px 5px 5px',
        // boxShadow: '2px 5px 7px black',
        // borderRadius: '5px',
        // width: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
      className='paginationOuterCon'
    >
      {pages.length > 0 && (
        <nav aria-label='...'>
          <ul style={{ marginBottom: '0' }} className='pagination'>
            {currentPage !== 1 && (
              <li className='page-item'>
                <a
                  onClick={previousPage}
                  className='page-link previousBtn'
                  tabIndex='-1'
                  style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                >
                  <FaAngleLeft />
                </a>
              </li>
            )}

            {pages.length <= 3 && (
              <>
                {pages.map((page) => (
                  <li key={`${page}`} className='page-item'>
                    <a
                      style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                      href='#listingNum'
                      className={
                        page === currentPage ? 'page-link1' : 'page-link'
                      }
                      onClick={() => onPageChange(page)}
                    >
                      {page}
                    </a>
                  </li>
                ))}
              </>
            )}

            {pages.length > 3 && (
              <>
                {pages1To3Prev && (
                  <button onClick={page1To3}>
                    <p>
                      <b>....</b>
                    </p>
                  </button>
                )}
                {pages3To6Prev && (
                  <button onClick={page3To6}>
                    <p>
                      <b>....</b>
                    </p>
                  </button>
                )}
                {pages6To9Prev && (
                  <button onClick={page6To9}>
                    <p>
                      <b>....</b>
                    </p>
                  </button>
                )}
                {pages9To12Prev && (
                  <button onClick={page9To12}>
                    <p>
                      <b>....</b>
                    </p>
                  </button>
                )}
                {pages12To15Prev && (
                  <button onClick={page12To15}>
                    <p>
                      <b>....</b>
                    </p>
                  </button>
                )}
                {pages15To18Prev && (
                  <button onClick={page15To18}>
                    <p>
                      <b>....</b>
                    </p>
                  </button>
                )}
                {pages18To21Prev && (
                  <button onClick={page18To21}>
                    <p>
                      <b>....</b>
                    </p>
                  </button>
                )}
                {pages21To24Prev && (
                  <button onClick={page21To24}>
                    <p>
                      <b>....</b>
                    </p>
                  </button>
                )}

                {pages1To3 &&
                  initialPages.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        href='#listingNum'
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages3To6 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        href='#listingNum'
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages6To9 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages9To12 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages12To15 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages15To18 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages18To21 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages21To24 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages21To24 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages24To26 &&
                  pagesToView.map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}

                {pages1To3 &&
                  lastPage()[0] !== pagesToView[pagesToView.length - 1] && (
                    <button onClick={page3To6}>
                      <p>
                        <b>....</b>
                      </p>
                    </button>
                  )}
                {pages3To6 &&
                  lastPage()[0] !== pagesToView[pagesToView.length - 1] && (
                    <button onClick={page6To9}>
                      <p>
                        <b>....</b>
                      </p>
                    </button>
                  )}
                {pages6To9 &&
                  lastPage()[0] !== pagesToView[pagesToView.length - 1] && (
                    <button onClick={page9To12}>
                      <p>
                        <b>....</b>
                      </p>
                    </button>
                  )}
                {pages9To12 &&
                  lastPage()[0] !== pagesToView[pagesToView.length - 1] && (
                    <button onClick={page12To15}>
                      <p>
                        <b>....</b>
                      </p>
                    </button>
                  )}
                {pages12To15 &&
                  lastPage()[0] !== pagesToView[pagesToView.length - 1] && (
                    <button onClick={page15To18}>
                      <p>
                        <b>....</b>
                      </p>
                    </button>
                  )}
                {pages15To18 &&
                  lastPage()[0] !== pagesToView[pagesToView.length - 1] && (
                    <button onClick={page18To21}>
                      <p>
                        <b>....</b>
                      </p>
                    </button>
                  )}
                {pages18To21 &&
                  lastPage()[0] !== pagesToView[pagesToView.length - 1] && (
                    <button onClick={page21To24}>
                      <p>
                        <b>....</b>
                      </p>
                    </button>
                  )}
                {pages21To24 &&
                  lastPage()[0] !== pagesToView[pagesToView.length - 1] && (
                    <button onClick={page24To26}>
                      <p>
                        <b>....</b>
                      </p>
                    </button>
                  )}

                {lastPage()[0] !== pagesToView[pagesToView.length - 1] &&
                  lastPage().map((page) => (
                    <li key={`${page}`} className='page-item'>
                      <a
                        style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                        href='#listingNum'
                        className={
                          page === currentPage ? 'page-link1' : 'page-link'
                        }
                        onClick={() => onPageChange(page)}
                      >
                        {page}
                      </a>
                    </li>
                  ))}
              </>
            )}

            {currentPage !== pagesCount && (
              <li className='page-item'>
                <a
                  style={{ boxShadow: '3px 1px 3px 0.5px #9696' }}
                  onClick={nextPage}
                  className='page-link'
                >
                  <FaAngleRight />
                </a>
              </li>
            )}
          </ul>
        </nav>
      )}
    </div>
  )
}

export default Pagination
