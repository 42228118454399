export function countries(){
    return  [
        { code: 'DZ', label: 'Algeria', phone: '213' },
        { code: 'AO', label: 'Angola', phone: '244' },
        { code: 'BJ', label: 'Benin', phone: '229' },
        { code: 'BW', label: 'Botswana', phone: '267' },
        { code: 'BF', label: 'Burkina Faso', phone: '226' },
        { code: 'BI', label: 'Burundi', phone: '257' },
        { code: 'CM', label: 'Cameroon', phone: '237' },
        { code: 'CV', label: 'Cape Verde', phone: '238' },
        {
            code: 'CF',
            label: 'Central African Republic',
            phone: '236',
          },
          { code: 'TD', label: 'Chad', phone: '235' },
          { code: 'KM', label: 'Comoros', phone: '269' },
          {
            code: 'CG',
            label: 'Congo',
            phone: '242',
          },
          {
            code: 'CD',
            label: 'Democratic Republic Of The Congo',
            phone: '243',
    
          },
          { code: 'DJ', label: 'Djibouti', phone: '253' },
          { code: 'EG', label: 'Egypt', phone: '20' },
          { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
          { code: 'ER', label: 'Eritrea', phone: '291' },
          { code: 'ET', label: 'Ethiopia', phone: '251' },
          {
            code: 'TF',
            label: 'French Southern Territories',
            phone: '262',
          },
          { code: 'GA', label: 'Gabon', phone: '241' },
          { code: 'GM', label: 'Gambia', phone: '220' },
          { code: 'GH', label: 'Ghana', phone: '233' },
          { code: 'GN', label: 'Guinea', phone: '224' },
          { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
          { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
          { code: 'KE', label: 'Kenya', phone: '254' },
          { code: 'LS', label: 'Lesotho', phone: '266' },
          { code: 'LR', label: 'Liberia', phone: '231' },
          { code: 'LY', label: 'Libya', phone: '218' },
          { code: 'MG', label: 'Madagascar', phone: '261' },
          { code: 'MW', label: 'Malawi', phone: '265' },
          { code: 'ML', label: 'Mali', phone: '223' },
          { code: 'MR', label: 'Mauritania', phone: '222' },
          { code: 'MU', label: 'Mauritius', phone: '230' },
          { code: 'YT', label: 'Mayotte', phone: '262' },
          { code: 'MA', label: 'Morocco', phone: '212' },
          { code: 'MZ', label: 'Mozambique', phone: '258' },
          { code: 'NA', label: 'Namibia', phone: '264' },
          { code: 'NE', label: 'Niger', phone: '227' },
          { code: 'NG', label: 'Nigeria', phone: '234' },
          { code: 'RE', label: 'Reunion', phone: '262' },
          { code: 'RW', label: 'Rwanda', phone: '250' },
          { code: 'SH', label: 'Saint Helana', phone: '290' },
          {
            code: 'ST',
            label: 'Sao Tome And Principe',
            phone: '239',
          },
          { code: 'SN', label: 'Senegal', phone: '221' },
          { code: 'SC', label: 'Seychelles', phone: '248' },
          { code: 'SL', label: 'Sierra Leone', phone: '232' },
          { code: 'SO', label: 'Somalia', phone: '252' },
          { code: 'ZA', label: 'South Africa', phone: '27' },
          { code: 'SS', label: 'South Sudan', phone: '211' },
          { code: 'SD', label: 'Sudan', phone: '249' },
          { code: 'SZ', label: 'Swaziland', phone: '268' },
          {
            code: 'TZ',
            label: 'United Republic of Tanzania',
            phone: '255',
          },
          { code: 'TG', label: 'Togo', phone: '228' },
          { code: 'TN', label: 'Tunisia', phone: '216' },
          { code: 'UG', label: 'Uganda', phone: '256' },
          { code: 'EH', label: 'Western Sahara', phone: '212' },
          { code: 'ZM', label: 'Zambia', phone: '260' },
          { code: 'ZW', label: 'Zimbabwe', phone: '263' },
      ]
}