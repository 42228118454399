import React, { useEffect, useState } from "react";
import ScrollToTop from "react-scroll-to-top";

import ComponentUnderConstruction from "../components/UnderConstruction/ComponentUnderConstruction";
import CareerHead from "../components/Career/CareerHead";
import CareerBodyIntro from "../components/Career/CareerBodyIntro";
import Recruitment from "../components/Career/Recruitment";
import WorkingEnviron from "../components/Career/WorkingEnviron";
import Internship from "../components/Career/Internship";
import Meta from "./../Utils/Meta";
import Meta1 from "../Utils/Meta1";
import DownloadApp from "../components/AboutUs/DownloadApp";

const Careers = () => {
  const [underConstruction, setUnderConstruction] = useState(false);

  useEffect(() => {
    setUnderConstruction(false);
    window.scrollTo(0, 0);
  }, []);

  if (underConstruction)
    return (
      <>
        <Meta title="Nawafrica | careers" />
        <ComponentUnderConstruction />
      </>
    );
  return (
    <>
      <Meta1
        title={`Nawafrica | Career`}
        description={`Nawafrica Careers and Opportunities in Nawafrica`}
        link="/careers"
      />
      <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
        <CareerHead />
        <DownloadApp />
        <CareerBodyIntro />
        <Recruitment />
        <WorkingEnviron />
        <Internship />
        <DownloadApp />
        <ScrollToTop smooth />
      </div>
    </>
  );
};

export default Careers;
