import React from 'react'
import { useTranslation } from 'react-i18next'

import './manageTenants.css'

const IdTypeInput = ({
  onChange,
  onChangeIdType,
  value = { value },
  title,
  id,
  type = 'text',
}) => {
  const { t } = useTranslation()
  return (
    <>
      <label className='IDTypelabel' htmlFor={id}>
        {title}
      </label>
      <div className='IDTypeOuterCon'>
        <div className='IDTypeSelectCon'>
          <select id={id} onChange={onChangeIdType} className='form-control'>
            <option value=''>{t('Select_One')}</option>
            <option value='ID card'>{t('ID_card')}</option>
            <option value='Passport'>{t("Passport")}</option>
          </select>
        </div>
        <div className='IDTypeInputCon'>
          <input
            value={value}
            placeholder={t('identification_card#')}
            type={type}
            id={id}
            onChange={onChange}
            className='form-control'
          />
        </div>
      </div>
    </>
  )
}

export default IdTypeInput
