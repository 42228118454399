import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

const WorkEnvCarousel = () => {
  return (
    <div className='careerWorkCar'>
      <Carousel interval={10000} autoPlay infiniteLoop>
        <div className='careerInnerCon'>
          <div className='careerInnerContentCon'>
            <h5>FAST AND QUALITY INTERNET</h5>
            <p>
              fast and quality internet is one of the conducive environment
              provided by Captivator Technologies, Inc. to all its workers. We
              believe that for tech experts, good and quality internet is a
              great necessity that can be used for research and hence enhance
              productivity.
            </p>
          </div>
          <div className='careerCarImageCon'>
            <img
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              src='../../images/it-team.jpg'
            />
          </div>
        </div>
        <div className='careerInnerCon'>
          <div className='careerCarImageCon'>
            <img
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              src='../../images/lap.jpg'
            />
          </div>
          <div className='careerInnerContentCon'>
            <h5>LAPTOPS AND MONITOR</h5>
            <p>
              Captivator Technologies inc. provides quality laptops and desktops
              to their tech team so that they can be equipped with the right
              tools to be productive in the workplace. We provide tools such as
              laptop, keyboard, extra large monitor, mouse etc to our workers so
              they are fully equipped to function properly in the work place.
            </p>
          </div>
        </div>
        <div className='careerInnerCon'>
          <div className='careerInnerContentCon'>
            <h5>EXPERTISE OF EXPERIENCE TEAMMATE</h5>
            <p>
              Captivator Technologies Inc. tech team is made up of tech experts
              in various domain in the tech space who are extremely experienced
              in their field of specialization. Some of which include Cyber
              security experts, web developers and mobile app developers. This
              team of experts provides help to other team members who are new to
              the tech space and are still trying to make exploits.
            </p>
          </div>
          <div className='careerCarImageCon'>
            <img
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              src='../../images/team.jpg'
            />
          </div>
        </div>
        <div className='careerInnerCon'>
          <div className='careerCarImageCon'>
            <img
              style={{ height: '100%', width: '100%', objectFit: 'cover' }}
              src='../../images/office.jpg'
            />
          </div>
          <div className='careerInnerContentCon'>
            <h5>QUALITY WORKSPACE</h5>
            <p>
              Our workspace here at Captivator Technologies, Inc. is of high
              quality with facilities such as air conditioning, clean office
              space, quality computer desk and chairs etc. We at Captivator
              Technologies believe providing these amenities will enhance the
              productivity of our workers.
            </p>
          </div>
        </div>
      </Carousel>
    </div>
  )
}

export default WorkEnvCarousel
