import React from 'react'
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CloseBtnDeal from './CloseBtnDeal'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const CloseDealButton = ({
  onClickCloseDeal,
  requestToClose,
  onClickRejectCancellation,
}) => {
  const {t}=useTranslation()
  return (
    <>
      {!requestToClose && (
        <Button
        sx={{
          background: "rgba(255, 0, 0, 0.8)",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius:"20px",
          font: "normal normal normal 14px/25px Merriweather"
        }}
          onClick={onClickCloseDeal}
          className={
            requestToClose
              ? 'btn closeDealButtonCon1'
              : 'btn closeDealButtonCon'
          }
        >
          <span style={{ marginRight: '5px' }}>
            <CloseBtnDeal />
          </span>
          {t("Close Lease Agreement")}
        </Button>
      )}
      {requestToClose && (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: '1', padding: '5px' }}>
            <Button
            onClick={onClickCloseDeal}
            className='btn btn-danger'
            sx={{
              background: "red",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius:"20px",
              font: "normal normal normal 14px/25px Merriweather",
              border:"1px solid red",
              "&:hover": {
                color: "red",
              }
            }}
            >
              <span style={{ marginRight: '5px' }}>
                <FontAwesomeIcon icon={faThumbsUp} />
              </span>
              {t("Accept Cancellation")}
            </Button>
          </div>
          <div style={{ flex: '1', padding: '5px' }}>
            <Button
              // style={{ background: '#035aa6', color: 'white' }}
              className='btn'
              onClick={onClickRejectCancellation}
              sx={{
                background: "#035aa6",
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius:"20px",
                font: "normal normal normal 14px/25px Merriweather",
                border:"1px solid #035aa6",
                "&:hover": {
                  color: "#035aa6",
                  background: "white",
                }
              }}
            >
              <span style={{ marginRight: '5px' }}>
                <FontAwesomeIcon icon={faThumbsDown} />
              </span>
              {t("Reject Cancellation")}
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default CloseDealButton
