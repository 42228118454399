import React from 'react'

const CrewIntroStatement = () => {
  return (
    <div className='aboutStatement'>
      <a
        href='https://www.captivator.org'
        target='_blank'
        rel='noopener noreferrer'
      >
        Captivator technologies inc.
      </a>{' '}
      is a software company whose success is achieve only through team work and
      excellent leadership. We have a team of hardworking experts on various
      technologically sectors such as Web develpers, mobile developer,
      cybersecurity experts who work tireless to ensure that every project is
      build with high level of expertise and also highly secure from cyber
      criminals. Our Team inorder of leader is represented as follows :
    </div>
  )
}

export default CrewIntroStatement
