import React from 'react'

const PolicyHr = () => {
  return (
    <div style={{ margin: '20px 0 30px 0', fontSize: '14px', opacity: '0.4' }}>
      ............................................................................................................................................................................................................................................................................
    </div>
  )
}

export default PolicyHr
