import React from 'react'

import NewsItems from './NewsItems'

const NewsListings = ({
  header = 'Latest Press Releases',
  news,
  loading,
  onClickNewsDeleted,
}) => {
  return (
    <div className='newsListingCon'>
      <h3>{header}</h3>
      {loading && <div>....loading</div>}
      {news.length === 0 && !loading && (
        <div style={{ textAlign: 'center' }} className='alert alert-info'>
          No News Available
        </div>
      )}
      {!loading &&
        news.map((p) => (
          <div key={`${p.id}`}>
            <NewsItems onClickNewsDeleted={onClickNewsDeleted} p={p} />
          </div>
        ))}
    </div>
  )
}

export default NewsListings
