import React from 'react'

import './auth.css'

const Input = ({label, type="text", placeholder, onChange, id, fieldLabel}) => {
return (
<div className="authenticationInputContainer">
<label className={`passwordLabel ${fieldLabel}`} htmlFor={id}><b>{label}</b></label>
<input type={type} placeholder={placeholder} onChange={onChange} className="form-control" id={id} autoComplete="off"/>                                                          
</div>
)
}

export default Input
