import {
  faEnvelopeCircleCheck,
  faEnvelopeOpen,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'

import './listingDetails.css'
import { Button } from '@mui/material'

const ApplyButton = ({ onClick, appliedAlready }) => {
  const { t } = useTranslation()

  return (
    <>
      {/* <div
        className={appliedAlready ? 'applyBtnOuterCon1' : 'applyBtnOuterCon'}
      >
        <button style={{ cursor: 'pointer' }} onClick={onClick}>
          {appliedAlready ? t('UNAPPLY_NOW') : t('APPLY_NOW')}
        </button>
      </div> */}
      {appliedAlready && (
        <div className={'applyBtnOuterCon1'}>
          <Button variant='outlined' style={{ cursor: 'pointer' }} onClick={onClick}>
            {/* <span style={{ marginRight: '5px' }}>
              <FontAwesomeIcon icon={faEnvelopeOpen} />
            </span> */}
            {t('UNAPPLY_NOW')}
          </Button>
        </div>
      )}
      {!appliedAlready && (
        <div className={'applyBtnOuterCon'}>
          <Button variant='contained' style={{ cursor: 'pointer' }} onClick={onClick}>
            {/* <span style={{ marginRight: '5px' }}>
              <FontAwesomeIcon icon={faEnvelopeCircleCheck} />
            </span> */}
            {t('APPLY_NOW')}
          </Button>
        </div>
      )}
    </>
  )
}

export default ApplyButton
