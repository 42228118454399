import React, { useContext } from "react";
import { FaAngleLeft, FaUsers, FaCreditCard, FaAngleUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import ListingItems from "./ListingItems";
import Grid from "../../iconComponent/Grid";
import SidebarList from "./../../iconComponent/SidebarList";
import PlusSymbol from "../../iconComponent/PlusSymbol";
import Feature from "../../iconComponent/Feature";
import Chat from "../../iconComponent/Chat";
import Gear from "../../iconComponent/Gear";
import User from "../../iconComponent/User";

import "./addListing.css";
import UnreadMsgsContext from "../../unreadmessages/context";

const Sidebar = ({ onClickCloseBar, path, chats, onTakeBarUp }) => {
  const { t } = useTranslation();

  const listingPath = "/portal/list/listing";
  const listingAnalyticsPath = "listing-analytics";
  const addListingPath = "/portal/add/add_listing";
  const applicationPath = "/portal/application/applicants";
  const messagesPath = "/portal/messages/message";
  const paymentPath = "/portal/payment";

  const tenantPath = "/portal/tenants";

  const maintanancePath = "/portal/maintenance";

  const {
    myListings: liste,
    applications,
    tenants,
    allPayments,
  } = useContext(UnreadMsgsContext);

  return (
    <div className="sidebarContainer">
      <ListingItems
        href={"#"}
        icon={<Grid className="sidebarIconsClassMenu" />}
        title={<span className="sidebarMenuSpan">{t("Menu")}</span>}
        angle={
          <div className="angleIconContainer" onClick={onClickCloseBar}>
            <FaAngleLeft className="sidebarMenuLeftAngle" />
          </div>
        }
        path={path}
        classes={"ListingItemConAddList"}
      />

      <hr className="sidebarHr" />

      <div className="sideBarLinks">
        <ListingItems
          icon={
            <SidebarList
              className={
                path === listingPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          title={
            <span
              className={
                path === listingPath ? "activeSidebarTitle" : "sidebarLinkTitle"
              }
            >
              {t("Listings")}
            </span>
          }
          href="/portal/list/listing"
          path={path}
          chats={
            liste?.length === 0
              ? null
              : liste.length > 99
              ? "99+"
              : liste.length
          }
          classes={
            path === listingPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <PlusSymbol
              className={
                path === addListingPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          title={
            <span
              className={
                path === addListingPath
                  ? "activeSidebarTitle"
                  : "sidebarLinkTitle"
              }
            >
              {t("Add_Listing")}
            </span>
          }
          href={"/portal/add/add_listing"}
          path={path}
          classes={
            path === addListingPath
              ? "activePortalLink"
              : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <Feature
              className={
                path === applicationPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          title={
            <span
              className={
                path === applicationPath
                  ? "activeSidebarTitle"
                  : "sidebarLinkTitle"
              }
            >
              {t("Applications")}
            </span>
          }
          href={"/portal/application/applicants"}
          path={path}
          chats={
            applications.length === 0
              ? null
              : applications.length > 99
              ? "99+"
              : applications.length
          }
          classes={
            path === applicationPath
              ? "activePortalLink"
              : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <Chat
              className={
                path === messagesPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          title={
            <span
              className={
                path === messagesPath
                  ? "activeSidebarTitle"
                  : "sidebarLinkTitle"
              }
            >
              {t("Messages")}
            </span>
          }
          chats={chats === 0 ? null : chats}
          path={path}
          href={"/portal/messages/message"}
          classes={
            path === messagesPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <FaUsers
              className={
                path === tenantPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          title={
            <span
              className={
                path === tenantPath ? "activeSidebarTitle" : "sidebarLinkTitle"
              }
            >
              {t("Tenants")}
            </span>
          }
          chats={
            tenants.length === 0
              ? null
              : tenants.length > 99
              ? "99+"
              : tenants.length
          }
          href={"/portal/tenants"}
          path={path}
          classes={
            path === tenantPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <User
              className={
                path === maintanancePath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          title={
            <span
              className={
                path === maintanancePath
                  ? "activeSidebarTitle"
                  : "sidebarLinkTitle"
              }
            >
              {t("Mantenance")}
            </span>
          }
          path={path}
          href={"/portal/maintenance"}
          classes={
            path === maintanancePath
              ? "activePortalLink"
              : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={
            <FaCreditCard
              className={
                path === paymentPath
                  ? "sidebarIconLinkActive"
                  : "sidebarIconLink"
              }
            />
          }
          title={
            <span
              className={
                path === paymentPath ? "activeSidebarTitle" : "sidebarLinkTitle"
              }
            >
              {t("Billing_And_Payment")}
            </span>
          }
          chats={
            allPayments.length === 0
              ? null
              : allPayments.length > 99
              ? "99+"
              : allPayments.length
          }
          path={path}
          href={"/portal/payment"}
          classes={
            path === paymentPath ? "activePortalLink" : "ListingItemConAddList"
          }
        />

        <ListingItems
          icon={<Gear className="sidebarIconLink" />}
          title={<span className="sidebarLinkTitle">{t("Settings")}</span>}
          path={path}
          classes={"ListingItemConAddList"}
          href={"#"}
        />
      </div>

      <button onClick={onTakeBarUp} className="signBarUpArrow">
        <FaAngleUp />
      </button>
    </div>
  );
};

export default Sidebar;
