import React from 'react'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import {
  FaChevronCircleDown,
  FaChevronCircleUp,
  FaDownload,
} from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

import LoadingSpinner from './../Applications/LoadingSpinner'
import { myDateTransform } from '../../Utils/currentDate'

const PaymentTable = ({
  allPayments,
  loadingSearchPayment,
  allPaymentDetails,
  onClickPayment,
  selectedPaymentTable,
  myUserListings,
  ondownloadReceipt,
  selectedPaymentToLandlord,
}) => {
  const { t } = useTranslation()

  const filterListCurrency = (payment) => {
    const theList = myUserListings.filter(
      (list) => list.id === payment.listing_id
    )
    return `${theList[0] && theList[0].currency}`
  }

  //function to add commas in money
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`)

    if (myVal.includes(',')) {
      return val
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
      }
      return val
    }
  }

  return (
    <>
      <div
        data-simplebar
        className='leasesTableCon2 setPositionToAbsolutePaymentTable visibleForLargeScreen'
      >
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th className='myListTh' scope='col'>
                {t('Payer')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Listing Paid')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Amount_Paid')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Date_Paid')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Payment_Method')}
              </th>
            </tr>
          </thead>
          <tbody>
            {allPayments.map((payment) => (
              <React.Fragment key={payment.id}>
                <tr className='table-column'>
                  <th className='myListTd' style={{ padding: '0' }}>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExampleLandlord${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '50px',
                      }}
                    >
                      {selectedPaymentTable.includes(payment) ? (
                        <FaChevronCircleUp
                          style={{ zIndex: '-1' }}
                          onClick={() => onClickPayment(payment)}
                          className='faChevronCircleUp'
                        />
                      ) : (
                        <FaChevronCircleDown
                          style={{ zIndex: '-1' }}
                          onClick={() => onClickPayment(payment)}
                          className='faChevronCircleUp'
                        />
                      )}

                      {payment.payer_name.length > 18
                        ? payment.payer_name.slice(0, 17) + '...'
                        : payment.payer_name}
                    </a>
                  </th>
                  <td className='myListTd' style={{ padding: '0' }}>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExampleLandlord${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '50px',
                      }}
                    >
                      {payment &&
                      payment.listing_name &&
                      payment.listing_name.length > 18
                        ? payment &&
                          payment.listing_name &&
                          payment.listing_name.slice(0, 15) + '...'
                        : payment && payment.listing_name}
                    </a>
                  </td>
                  <td className='myListTd' style={{ padding: '0' }}>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExampleLandlord${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '50px',
                      }}
                    >
                      {`${Math.round(payment.amount * 100) / 100}`.length > 8
                        ? `${Math.round(payment.amount * 100) / 100}`.slice(
                            0,
                            6
                          ) + '..'
                        : `${Math.round(payment.amount * 100) / 100}`}{' '}
                      {filterListCurrency(payment)}
                    </a>
                  </td>
                  <td className='myListTd' style={{ padding: '0' }}>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExampleLandlord${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '50px',
                      }}
                    >
                      {myDateTransform(payment.create_date.slice(0, 10))}
                    </a>
                  </td>
                  <td className='myListTd' style={{ padding: '0' }}>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExampleLandlord${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '50px',
                      }}
                    >
                      {t(payment.payment_method)}
                    </a>
                  </td>
                </tr>

                <div
                  className='collapse dropdownContainerForTableDet'
                  id={`collapseExampleLandlord${payment.id}`}
                >
                  <div className='card card-body'>
                    <table className='table table-striped'>
                      <thead
                      className='myListThead'
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        }}
                      >
                        <tr>
                          <th
                          className='myListTh'
                          scope='col'
                          >
                            {t('Month')}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('Year')}
                          </th>
                          <th
                            className='myListTh giveSomeWidthToAmtPaid'
                            scope='col'
                          >
                            {t('amount_paid')}/{filterListCurrency(payment)}
                          </th>
                          <th
                            className='myListTh giveSomeWidthToAmtPaid'
                            scope='col'
                          >
                            {t('amount_owed/mth')}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('status')}
                          </th>
                          <th
                            className='myListTh giveSomeWidthToAmtPaid'
                            scope='col'
                          >
                            {t('Receipt')}
                          </th>
                        </tr>
                      </thead>

                      {allPaymentDetails &&
                        allPaymentDetails
                          .filter(
                            (detail) =>
                              detail.payments_to_landlord_id === payment.id
                          )
                          .map((pay) => (
                            <tr key={pay.id} className='table-column'>
                              <td className='myListTd'>
                                {pay.month_pay === '1'
                                  ? t('Jan')
                                  : pay.month_pay === '2'
                                  ? t('Feb')
                                  : pay.month_pay === '3'
                                  ? t('Mar')
                                  : pay.month_pay === '4'
                                  ? t('Apr')
                                  : pay.month_pay === '5'
                                  ? t('May')
                                  : pay.month_pay === '6'
                                  ? t('Jun')
                                  : pay.month_pay === '7'
                                  ? t('Jul')
                                  : pay.month_pay === '8'
                                  ? t('Aug')
                                  : pay.month_pay === '9'
                                  ? t('Sep')
                                  : pay.month_pay === '10'
                                  ? t('Oct')
                                  : pay.month_pay === '11'
                                  ? t('Nov')
                                  : t('Dec')}
                              </td>
                              <td className='myListTd'>{pay.year_pay}</td>
                              <td className='myListTd'>
                                {commaSeparateNumber(pay.amount_pay)}
                              </td>
                              <td className='myListTd'>
                                {commaSeparateNumber(pay.amount_owed)}/
                                {pay.month_pay === '1'
                                  ? t('Jan')
                                  : pay.month_pay === '2'
                                  ? t('Feb')
                                  : pay.month_pay === '3'
                                  ? t('Mar')
                                  : pay.month_pay === '4'
                                  ? t('Apr')
                                  : pay.month_pay === '5'
                                  ? t('May')
                                  : pay.month_pay === '6'
                                  ? t('Jun')
                                  : pay.month_pay === '7'
                                  ? t('Jul')
                                  : pay.month_pay === '8'
                                  ? t('Aug')
                                  : pay.month_pay === '9'
                                  ? t('Sep')
                                  : pay.month_pay === '10'
                                  ? t('Oct')
                                  : pay.month_pay === '11'
                                  ? t('Nov')
                                  : t('Dec')}
                              </td>
                              <td className='myListTd'>{t(pay.status_pay)}</td>
                              <td className='myListTd'>
                                {pay === selectedPaymentToLandlord ? (
                                  <div>
                                    <LoadingSpinner />
                                  </div>
                                ) : (
                                  <button
                                    onClick={() => ondownloadReceipt(pay)}
                                    className='btn btn-sm receiptDownloadBtn'
                                    style={{
                                      whiteSpace: "nowrap",
                                      textOverflow: "ellipsis",
                                      }}
                                  >
                                    <FaDownload />
                                    {t(' receipt')}
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                    </table>

                    <button
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      data-target={`#collapseExampleLandlord${payment.id}`}
                      aria-expanded='false'
                      aria-controls={`collapseExampleLandlord${payment.id}`}
                      className='btn closeBtnMadePayment'
                    >
                      {t('close')}
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ))}
            {loadingSearchPayment && <div>{t('.....loading')}</div>}
          </tbody>
        </table>
      </div>

      <div
        data-simplebar
        className='leasesTableCon2 visibleForMobile separationInPaymentTable'
      >
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th className='myListTh' scope='col'>
                {t('Payer')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Listing Paid')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Amount_Paid')}
              </th>
            </tr>
          </thead>
          <tbody>
            {allPayments.map((payment) => (
              <React.Fragment key={payment.id}>
                <tr className='table-column'>
                  <th className='myListTd'>
                    <a
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      href={`#collapseExampleLandlord${payment.id}`}
                      role='button'
                      aria-expanded='false'
                      aria-controls={`collapseExample${payment.id}`}
                      style={{
                        display: 'flex',
                        flex: '1',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '0',
                        height: '50px',
                      }}
                    >
                      {selectedPaymentTable.includes(payment) ? (
                        <FaChevronCircleUp
                          style={{ zIndex: '-1' }}
                          onClick={() => onClickPayment(payment)}
                          className='faChevronCircleUp'
                        />
                      ) : (
                        <FaChevronCircleDown
                          style={{ zIndex: '-1' }}
                          onClick={() => onClickPayment(payment)}
                          className='faChevronCircleUp'
                        />
                      )}

                      {payment &&
                      payment.payer_name &&
                      payment.payer_name.length > 18
                        ? payment &&
                          payment.payer_name &&
                          payment.payer_name.slice(0, 17) + '...'
                        : payment && payment.payer_name}
                    </a>
                  </th>
                  <td className='myListTd'>
                    {payment &&
                    payment.listing_name &&
                    payment.listing_name.length > 18
                      ? payment &&
                        payment.liting_name &&
                        payment.liting_name.slice(0, 15) + '...'
                      : payment && payment.listing_name}
                  </td>
                  <td className='myListTd'>
                    {`${Math.round(payment.amount * 100) / 100}`.length > 8
                      ? `${Math.round(payment.amount * 100) / 100}`.slice(
                          0,
                          6
                        ) + '..'
                      : `${Math.round(payment.amount * 100) / 100}`}{' '}
                    {filterListCurrency(payment)}
                  </td>
                </tr>

                <div
                  className='collapse dropdownContainerForTableDet'
                  id={`collapseExampleLandlord${payment.id}`}
                >
                  <div className='card card-body'>
                    <table className='table table-striped'>
                      <thead className='myListThead'>
                        <tr>
                          <th className='myListTh' scope='col'>
                            {t('Month')}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('Year')}
                          </th>
                          <th
                            className='myListTh giveSomeWidthToAmtPaid'
                            scope='col'
                          >
                            {t('amount_paid')}/{filterListCurrency(payment)}
                          </th>
                          <th
                            className='myListTh giveSomeWidthToAmtPaid'
                            scope='col'
                          >
                            {t('amount_owed/mth')}
                          </th>
                          <th className='myListTh' scope='col'>
                            {t('status')}
                          </th>
                          <th
                            className='myListTh giveSomeWidthToAmtPaid'
                            scope='col'
                          >
                            {t('Receipt')}
                          </th>
                        </tr>
                      </thead>

                      {allPaymentDetails &&
                        allPaymentDetails
                          .filter(
                            (detail) =>
                              detail.payments_to_landlord_id === payment.id
                          )
                          .map((pay) => (
                            <tr key={pay.id} className='table-column'>
                              <td className='myListTd'>
                                {pay.month_pay === '1'
                                  ? t('Jan')
                                  : pay.month_pay === '2'
                                  ? t('Feb')
                                  : pay.month_pay === '3'
                                  ? t('Mar')
                                  : pay.month_pay === '4'
                                  ? t('Apr')
                                  : pay.month_pay === '5'
                                  ? t('May')
                                  : pay.month_pay === '6'
                                  ? t('Jun')
                                  : pay.month_pay === '7'
                                  ? t('Jul')
                                  : pay.month_pay === '8'
                                  ? t('Aug')
                                  : pay.month_pay === '9'
                                  ? t('Sep')
                                  : pay.month_pay === '10'
                                  ? t('Oct')
                                  : pay.month_pay === '11'
                                  ? t('Nov')
                                  : t('Dec')}
                              </td>
                              <td className='myListTd'>{pay.year_pay}</td>
                              <td className='myListTd'>
                                {commaSeparateNumber(pay.amount_pay)}
                              </td>
                              <td className='myListTd'>
                                {commaSeparateNumber(pay.amount_owed)}/
                                {pay.month_pay === '1'
                                  ? t('Jan')
                                  : pay.month_pay === '2'
                                  ? t('Feb')
                                  : pay.month_pay === '3'
                                  ? t('Mar')
                                  : pay.month_pay === '4'
                                  ? t('Apr')
                                  : pay.month_pay === '5'
                                  ? t('May')
                                  : pay.month_pay === '6'
                                  ? t('Jun')
                                  : pay.month_pay === '7'
                                  ? t('Jul')
                                  : pay.month_pay === '8'
                                  ? t('Aug')
                                  : pay.month_pay === '9'
                                  ? t('Sep')
                                  : pay.month_pay === '10'
                                  ? t('Oct')
                                  : pay.month_pay === '11'
                                  ? t('Nov')
                                  : t('Dec')}
                              </td>
                              <td className='myListTd'>{t(pay.status_pay)}</td>
                              <td className='myListTd'>
                                {pay === selectedPaymentToLandlord ? (
                                  <div>
                                    <LoadingSpinner />
                                  </div>
                                ) : (
                                  <button
                                    onClick={() => ondownloadReceipt(pay)}
                                    className='btn btn-sm receiptDownloadBtn'
                                  >
                                    <FaDownload /> receipt
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                    </table>

                    <button
                      onClick={() => onClickPayment(payment)}
                      data-toggle='collapse'
                      data-target={`#collapseExampleLandlord${payment.id}`}
                      aria-expanded='false'
                      aria-controls={`collapseExampleLandlord${payment.id}`}
                      className='btn closeBtnMadePayment'
                    >
                      close
                    </button>
                  </div>
                </div>
              </React.Fragment>
            ))}
            {loadingSearchPayment && <div>.....loading</div>}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default PaymentTable
