import React from 'react'

import './leases.css'

const AgreementCheckbox = ({value, onChange}) => {
return (
<input checked="checked" value={value} onChange={onChange} type="checkbox" className="agreementCheckbox"/>
)
}

export default AgreementCheckbox
