import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

import video from '../../Videos/Office - 80314.mp4'

const CareerHead = () => {
  useEffect(() => {
    AOS.init({ duration: 4000 })
  }, [])
  return (
    <div className='careerHeadCon'>
      <div className='careerHeadInnerCon'>
        <h1 data-aos='flip-up'>
          Here at{' '}
          <a
            style={{ textDecoration: 'none', color: '#ffa500' }}
            href='https://www.captivator.org'
            target='_blank'
            rel='noopener noreferrer'
          >
            CAPTIVATOR TECHNOLOGIES INC.
          </a>
          <span style={{ marginLeft: '10px' }}>
            We are Tech and we use Tech to Revolutionize the Real Estate Sector
            of Africa
          </span>
        </h1>
      </div>
      <video style={{ width: '100%', height: '100%' }} autostart autoPlay loop>
        <source src={video} type='video/mp4' />
      </video>
    </div>
  )
}

export default CareerHead
