import React, { useContext, useEffect } from "react";
import UnreadMsgsContext from "../unreadmessages/context";
import { getStatusByApplicantsId } from "../Services/applicationStatus";
import { getCurrentUser } from "../Services/authService";

const PaypalSuccess = () => {
  const { setMyLeasesApplicationsOwed } = useContext(UnreadMsgsContext);

  useEffect(() => {
    updateContext();
  }, []);

  const updateContext = async () => {
    ////tenant get all leases applications that are owed
    const { data: myLeasesAppl } = await getStatusByApplicantsId(
      getCurrentUser()?.id
    );
    const leaseOwing = myLeasesAppl.filter((p) => Number(p.amount_owed) > 0);
    setMyLeasesApplicationsOwed(leaseOwing);
  };

  return (
    <div
      style={{
        width: "100%",
        height: "30vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        className="alert alert-info"
      >
        Successfully Pay through Paypal
      </div>
    </div>
  );
};

export default PaypalSuccess;
