import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'

import HrContent from '../components/Authentication/HrContent'
import GoogleFbLink from '../components/Authentication/GoogleFbLink'
import Spinner from '../components/Authentication/Spinner'
import { login } from '../Services/authService'
import '../components/Authentication/auth.css'
import { Link } from 'react-router-dom'
import '../components/Authentication/auth.css'
import { trackEvent } from '../Utils/useGAEventTracker'
// import Meta from './../Utils/Meta'
import Meta1 from '../Utils/Meta1'
import { Button, Card } from '@mui/material'

const Login = (props) => {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState('')
  const [loading, setLoading] = useState(false)

  const [langType, setLangType] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0)
    setLangType(cookies.get('i18next') || 'en')
  }, [])

  //function to submit login credentials
  const onLogin = async (e) => {
    e.preventDefault()
    try {
      setErrors('')
      setLoading(true)
      const { data: jwt } = await login({
        email,
        password,
      })

      localStorage.setItem('token', jwt)
      const { state } = props.location
      setLoading(false)

      window.location.assign(state ? state.from.pathname:localStorage.getItem('redirect')? localStorage.getItem('redirect') : '/')
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 400) ||
        (ex.response && ex.response.status === 500)
      ) {
        setErrors(ex.response.data)
        setLoading(false)
      }
    }
  }

  return (
    <>
      <Meta1
        title={`${langType}.${t(`Nawafrica | Login`)}`}
        description='Login into Nawafrica'
        link='/login'
      />

      <Card variant="outlined" style={{ overflow: 'hidden' }} className='loginOuterCon'>
        {loading && (
          <p className='loginLoadingContainer'>
            <Spinner />
          </p>
        )}
        {errors && (
          <div className='alert alert-danger loginErrorContainer'>{errors}</div>
        )}
        <div>
          <p style={{ textAlign: 'center' }}>
            <b className='title'>{t('Login')}</b>
          </p>

          <hr className='headerSeparator'></hr>
        </div>
        <div style={{ textAlign: 'center' }} className='form-group'>
          <form onSubmit={onLogin}>
            <label htmlFor='exampleFormControlFile1'>
              <b>{t('Email_Address')}</b>
            </label>
            <input
              placeholder='you@yahoo.com'
              className='form-control'
              id='exampleFormControlFile1'
              onChange={(e) => {
                e.preventDefault()
                const { value } = e.currentTarget
                setEmail(value.toLowerCase())
              }}
            />

            <label className='passwordLabel' htmlFor='exampleFormControlFile2'>
              <b>{t('Password')}</b>
            </label>
            <input
              placeholder={t('Password_ph')}
              className='form-control'
              id='exampleFormControlFile2'
              type='password'
              onChange={(e) => {
                e.preventDefault()
                const { value } = e.currentTarget
                setPassword(value)
              }}
            />

            <p style={{ textAlign: 'center' }}>
              <Button variant="contained" color="primary" 
                style={{ cursor: 'pointer', outline: 'none', textTransform: 'capitalize' }}
                type='submit'
              >
                {t('Login')}
              </Button>
            </p>
          </form>

          <p
            style={{
              font: 'normal normal 300 12px/15px Merriweather',
              flex: '1',
              marginTop: '0',
            }}
          >
            {t('forgot_password?')}{' '}
            <Link to='/fogot_password'>{t('ClICK_HERE')}</Link>
          </p>
          <div className='loginFooter'>
            <HrContent />

            <GoogleFbLink />
          </div>
          <div className='loginNoAccount'>
            <p style={{ flex: '1' }} className='noAccount'>
              {t("You_don't_have_an_account_?")}{' '}
              <Link to='/signUp'>{t('SIGN_UP')}</Link>
            </p>
          </div>
        </div>
      </Card>
      <div className='letMeGiveSomeSeparationOverAccountable'></div>
    </>
  )
}

export default Login
