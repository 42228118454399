import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLaptop } from "@fortawesome/free-solid-svg-icons";

const OpositeCrewContainer = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="crewImageOuterCon">
      <div className="opCrewCardCon">
        <div data-aos="fade-left" className="crewImagesContent">
          <FontAwesomeIcon className="crewIcon" icon={faLaptop} />
          <p className="nameContainer">
            <b className="names">FELIX NKONGHO NDIFON</b>
          </p>
          <h5 style={{ color: "#ffa500", textAlign: "center" }}>
            CTO/CO-FOUNDER OF CAPTIVATOR TECHNOLOGIES INC.
          </h5>
          <p style={{ margin: "0 1%" }}>
            <b>
              <i>ACADEMIC </i>:
            </b>
            <span>
              <span style={{ marginLeft: "10px", marginRight: "5px" }}>I</span>
              have a scientist-based academic background, having my primary
              school in G.S Lobe Estate, and my secondary school at G.B.H.S
              Ekondo titi and a high school at B.G.S Buea.I have a BSc. degree
              in Biochemistry and a minor in medical lab. Technology from the
              University of Buea.
            </span>
          </p>
          <p style={{ margin: "5px 1%" }}>
            <b>
              <i>SKILLS </i>:
            </b>
            <span>
              <span style={{ marginLeft: "10px", marginRight: "5px" }}>
                Wordpress,
              </span>
              HTML, CSS, Javascript, Bootstrap, React, React-native, Node,
              Postgresql, Mongodb, Websocket, WebRTC, Git, Docker, Linux,
              Geocoding, Reverse geocoding, Data structures and Algorithm
            </span>
          </p>
          <p style={{ margin: "10px 1%" }}>
            <b>
              <i>WORK EXPERIENCE </i>:
            </b>
            <span>
              <span style={{ marginLeft: "10px", marginRight: "5px" }}>I</span>
              have 3 years of experience as a senior Software Engineer at
              Captivator technologies inc. Some of my Job description include
              drawing a markup for a software, build the frontend and building
              the backend of the software, hosting the software and finally
              maintaining and updating the software.
            </span>
          </p>

          <p style={{ margin: "10px 1%" }}>
            <b>
              <i>REMARK </i>:
            </b>
            <span>
              <span style={{ marginLeft: "10px", marginRight: "5px" }}>
                The
              </span>
              Vision of Captivator Technologies inc. is a life changing vision
              for Africa that will help to use technology to bring out changes
              and empowerment for Africa.
            </span>
          </p>
        </div>

        <div className="crewImageCon" data-aos="fade-right">
          <img
            style={{ width: "100%", height: "100%" }}
            src="../../images/felixCTO.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default OpositeCrewContainer;
