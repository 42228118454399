import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import Faqdrop1 from './Faqdrop1'

const FaqDrop = ({
  topic = ' About Captivator Technologies And Nawafrica',
  open,
  onClick,
  content,
}) => {
  useEffect(() => {
    AOS.init({ duration: 500 })
  }, [])
  return (
    <div className='faqDropOuterCon' data-aos='zoom-in-down'>
      <Faqdrop1 topic={topic} open={open} onClick={onClick} />

      <div className={open ? 'showDetails1' : 'showDetails'}>{content}</div>
    </div>
  )
}

export default FaqDrop
