import axios from "axios";
import logger from "../Services/loggerService";

export function currencyExchange(
  currencyfrom,
  currencyTo = "EUR",
  onExchangeData,
  exchangeError,
  setLoading
) {
  setLoading(true);
  try {
    if (currencyfrom === currencyTo) {
      setLoading(false);
      onExchangeData(1);
      return;
    }
    var options = {
      method: "GET",
      url: "https://currency-exchange.p.rapidapi.com/exchange",
      params: { from: currencyfrom, to: currencyTo, q: "1.0" },
      headers: {
        "x-rapidapi-host": "currency-exchange.p.rapidapi.com",
        "x-rapidapi-key": "05279b6b9bmshc8e3d21a4c373ccp13108bjsnbece35678c93",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        logger.log(response);
        onExchangeData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        logger.log(error);
        exchangeError(error);
      });

    {
      /*currencyTo &&
      currencyfrom &&
      axios
        .get(
          `https://api.fastforex.io/fetch-one?from=${currencyfrom}&to=${currencyTo}&api_key=a507b56952-88c7ee96fc-sc0692`
        )
        .then((response) => {
          console.log(
            "HERE IS THE EXCHANGE=======",
            response.data.result[currencyTo],
            currencyfrom,
            currencyTo
          );
          logger.log(response);
          onExchangeData(response.data.result[currencyTo]);
          setLoading(false);
        })
        .catch((error) => {
          console.log("here is the error===", error, currencyTo, currencyfrom);
          logger.log(error);
          setLoading(false);
          exchangeError(error);
        });*/
    }
  } catch (error) {
    logger.log(error);
    setLoading(false);
    exchangeError(error);
  }
}
