import http from "./httpService"

export function postingToCloudinary(data){
return http.post('/cloudinary', data, {
'Content-Type':'application/json'
})
}

export function getCloudinarySignature(){
return http.get('/cloudinarySignature')
}