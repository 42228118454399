import React from 'react'
import Lottie from 'react-lottie'
import { priceTagAnimate } from './price-tag'

const PriceTag = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: priceTagAnimate(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={50} width={50} />
    </div>
  )
}

export default PriceTag
