import React from 'react'
import { Link } from 'react-router-dom'
import { faTrashCan, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getCurrentUser } from './../../Services/authService'

const NewsItems = ({ p, onClickNewsDeleted }) => {
  const getMonth = (month) => {
    if (month == '01') return 'JAN'
    if (month == '02') return 'FEB'
    if (month == '03') return 'MAR'
    if (month == '04') return 'APR'
    if (month == '05') return 'MAY'
    if (month == '06') return 'JUN'
    if (month == '07') return 'JUL'
    if (month == '08') return 'AUG'
    if (month == '09') return 'SEPT'
    if (month == '10') return 'OCT'
    if (month == '11') return 'NOV'
    return 'DEC'
  }

  return (
    <div className='newsItemsCon'>
      <b>
        <span>{`${p && p.create_date.toString().slice(8, 10)} ${getMonth(
          p && p.create_date.toString().slice(5, 7)
        )} ${p && p.create_date.toString().slice(0, 4)}`}</span>
        {getCurrentUser() && getCurrentUser().isadmin && (
          <>
            <span style={{ margin: '0 7px', opacity: '0.7' }}>/</span>
            <span>
              <FontAwesomeIcon
                style={{
                  color: 'red',
                  opacity: '0.8',
                  fontSize: '16px',

                  cursor: 'pointer',
                  margin: '0 7px',
                }}
                icon={faTrashCan}
                onClick={() => onClickNewsDeleted(p.id)}
              />
            </span>
            <span style={{ margin: '0 7px', opacity: '0.7' }}>/</span>
            <span>
              <Link to={`/newsroom/add/${p.id}`}>
                <FontAwesomeIcon
                  style={{
                    color: 'grey',
                    fontSize: '16px',

                    cursor: 'pointer',
                  }}
                  icon={faPenToSquare}
                />
              </Link>
            </span>
          </>
        )}
      </b>
      <Link
        to={`/newsroom/${p.id}`}
        style={{
          color: '#035aa6',
          cursor: 'pointer',
          zIndex: '1',
          textDecoration: 'none',
        }}
      >
        <p>{p.paragraphs[0].slice(0, 200)}</p>
      </Link>
    </div>
  )
}

export default NewsItems
