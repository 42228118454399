import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './career.css'

import WorkEnvCarousel from './WorkEnvCarousel'

const WorkingEnviron = () => {
  useEffect(() => {
    AOS.init({ duration: 4000 })
  }, [])
  return (
    <div className='careerWorkEnv'>
      <h3 data-aos='flip-down'>
        CONDUCIVE AND COMFORTABLE WORKING ENVIRONMENT
      </h3>
      <p
        className='environment_p'
        style={{ width: '80%', margin: '0 auto', fontSize: '20px' }}
      >
        <a
          className='career_content'
          href='https://www.captivator.org'
          target='_blank'
          rel='noopener noreferrer'
        >
          At Captivator Technologies inc.
        </a>{' '}
        We provide a very conducive and comfortable working conditions to our
        employee so that the can be well equipped with the right tools in order
        to increase productivity in the workplaces. We ensure that our employees
        are equippd with
      </p>
      <WorkEnvCarousel />
    </div>
  )
}

export default WorkingEnviron
