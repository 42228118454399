import React from "react";
import { Link } from "react-router-dom";
import { Image, Video } from "cloudinary-react";

import "./searchListing.css";

const Carousel = ({ item, listingId }) => {
  return (
    <div
      id={`carouselExampleControls${item.id}`}
      className="carousel slide searchListingDisplayCarousel"
      data-ride="carousel"
    >
      <Link to={`/listing/${listingId}`}>
        <div className="carousel-inner">
          <div className="carousel-item active">
            {item.main_photo.path.public_id ? (
              <Image
                crossOrigin="anonymous"
                className="searchListingCarouselImages"
                cloudName="captivator"
                publicId={item.main_photo.path.public_id}
                style={{ objectFit: "cover" }}
              />
            ) : (
              <img
                style={{ objectFit: "cover" }}
                crossOrigin="anonymous"
                className="searchListingCarouselImages"
                src={item.main_photo.path.url}
                alt="First slide"
              />
            )}
          </div>
          <div className="carousel-item">
            {item.parlour_photo.path.public_id ? (
              <Image
                crossOrigin="anonymous"
                className="searchListingCarouselImages"
                cloudName="captivator"
                publicId={item.parlour_photo.path.public_id}
                style={{ objectFit: "cover" }}
              />
            ) : (
              <img
                crossOrigin="anonymous"
                className="searchListingCarouselImages"
                src={item.parlour_photo.path.url}
                alt="Second slide"
                style={{ objectFit: "cover" }}
              />
            )}
          </div>
          <div className="carousel-item">
            {item.bedroom_photo.path.public_id ? (
              <Image
                crossOrigin="anonymous"
                className="searchListingCarouselImages"
                cloudName="captivator"
                publicId={item.bedroom_photo.path.public_id}
                style={{ objectFit: "cover" }}
              />
            ) : (
              <img
                crossOrigin="anonymous"
                className="searchListingCarouselImages"
                src={item.bedroom_photo.path.public_id}
                alt="Third slide"
                style={{ objectFit: "cover" }}
              />
            )}
          </div>

          {item.other_photos.map((photo) => (
            <div key={photo.public_id} className="carousel-item">
              {photo.format === "mp4" ? (
                photo.public_id ? (
                  <Video
                    className="searchListingCarouselImages"
                    cloudName="captivator"
                    publicId={photo.public_id}
                  />
                ) : (
                  <video
                    className="searchListingCarouselImages"
                    src={photo.url}
                  />
                )
              ) : photo.public_id ? (
                <Image
                  crossOrigin="anonymous"
                  className="searchListingCarouselImages"
                  cloudName="captivator"
                  publicId={photo.public_id}
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <img
                  crossOrigin="anonymous"
                  className="searchListingCarouselImages"
                  src={photo.url}
                  alt="Third slide"
                  style={{ objectFit: "cover" }}
                />
              )}
            </div>
          ))}
        </div>
      </Link>
      <a
        className="carousel-control-prev searchCarouselPrevBtn"
        href={`#carouselExampleControls${item.id}`}
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next searchCarouselNextBtn"
        href={`#carouselExampleControls${item.id}`}
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

export default Carousel;
