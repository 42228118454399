import React from "react";
import { FaUser } from "react-icons/fa";
import { Image } from "cloudinary-react";
import { useTranslation } from "react-i18next";

import "./messageDetail.css";

const SenderDate = ({ message }) => {
  const { t } = useTranslation();

  const myUrl =
    message.sender_image && message.sender_image.length > 200
      ? JSON.parse(message.sender_image)
      : message.sender_image;

  console.log("HERE IS THE IMAGE===", myUrl.url);

  return (
    <div className="theDateSentMessageDetOuterCon">
      <div>
        <div className="theSenderOuterContainerInMessageDet">
          {myUrl && myUrl.url ? (
            <img src={myUrl.url} style={{ objectFit: "cover" }} />
          ) : (
            <div className="noImageListingDetCon">
              <FaUser className="userIcon" />
            </div>
          )}
          <div className="theSenderAndListingNameMessageDetCon">
            {message.sender_name} @ {message.listing_name}
          </div>
        </div>
      </div>

      <div className="theDateInMessageDetOuterCon">
        <div className="theInnerCon">
          <div>{t("Date_Sent")}:</div>
          <div className="theMessageCreatedDate">{message.create_date}</div>
        </div>
      </div>
    </div>
  );
};

export default SenderDate;
