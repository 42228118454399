import React from "react";

import "./Blog.css";

const BlogHead = () => {
  return (
    <div
      className="blogHeadCon"
      style={{
        backgroundImage: "url('../../images/blog.jpg')",
        backgroundColor: "lightgray",
      }}
    >
      <div className="blogHeadInnerCon">
        <h1>NAWAFRICA'S MARKET TRENDS BLOG</h1>
        <b>Get More Information About Our Products</b>
        <div className="blogHeadDownArrow">
          <div className="scroll-arrow">
            <svg style={{ opacity: "0.5" }} width="30px" height="20px">
              <path
                stroke="#ffffff"
                fill="black"
                strokeWidth="2px"
                d="M2.000,5.000 L15.000,18.000 L28.000,5.000 "
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogHead;
