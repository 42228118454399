import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import GavelIcon from "@mui/icons-material/Gavel";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";

const AgreementLeasesList1 = ({
  myUnderReviews,
  selectedLeaseToSign,
  listings,
  users,
  onClick,
  onDeleteReview,
}) => {
  // const { t } = useTranslation()

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const ListingTitle = (id) => {
    const listing = listings.filter((list) => list.id === id);

    return listing[0]
      ? {
          title:
            listing[0].title.length > 20
              ? capitalize(listing[0].title.slice(0, 20) + "..")
              : capitalize(listing[0].title),
          address: capitalize(listing[0].street_address),
        }
      : "";
  };

  const userName = (id) => {
    const usersss = users && users.filter((user) => user.id === id);
    return capitalize(
      `${usersss[0].first_name} ${usersss[0].last_name}`.length > 17
        ? `${usersss[0].first_name} ${usersss[0].last_name}`.slice(0, 17) + ".."
        : `${usersss[0].first_name} ${usersss[0].last_name}`
    );
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      <nav aria-label="main mailbox folders">
        <List>
          {myUnderReviews.map((under, index) => (
            <div key={`${under.id}`}>
              <ListItem
                sx={{ display: "flex", flexDirection: "row" }}
                onClick={() => {
                  if (under.tenant_sign_lease) {
                    return alert("You already signed this lease");
                  }
                  onClick(under);
                }}
                className={
                  selectedLeaseToSign && selectedLeaseToSign.id === under.id
                    ? "list-group-item active underReviewListItem"
                    : "list-group-item underReviewListItem"
                }
                disablePadding
              >
                <ListItemButton sx={{ flex: "1" }}>
                  {under.tenant_sign_lease && (
                    <GavelIcon sx={{ color: "gray", marginRight: "5px" }} />
                  )}
                  <ListItemText
                    primaryTypographyProps={{ fontSize: "14px" }}
                    primary={`${
                      ListingTitle(under.listing_id).title
                    } from ${userName(under.list_owner_id)}`}
                  />
                </ListItemButton>
                {!under.tenant_sign_lease && (
                  <button
                    style={{
                      width: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={() => onDeleteReview(under)}
                    className="btn btn-light"
                  >
                    <RestoreFromTrashIcon
                      sx={{ color: "rgba(255, 0, 0, 0.8)" }}
                    />
                  </button>
                )}
              </ListItem>
              {index !== myUnderReviews.length - 1 && (
                <Divider sx={{ width: "100%" }} />
              )}
            </div>
          ))}
        </List>
      </nav>
      <Divider />
    </Box>
  );
};

export default AgreementLeasesList1;
