import React from "react";

import BlogHr from "./BlogHr";
import BlogDisplayCard from "./BlogDisplayCard";
import BlogRecentPost from "./BlogRecentPost";
import AddStoryBtn from "./AddStoryBtn";
// import { getCurrentUser } from './../../Services/authService'
// import { getUserById } from './../../Services/registerService'

const BlogBody = ({ items, onClickDelete, onClickEdit, user }) => {
  const getMonth = (month) => {
    if (month == "01") return "JAN";
    if (month == "02") return "FEB";
    if (month == "03") return "MAR";
    if (month == "04") return "APR";
    if (month == "05") return "MAY";
    if (month == "06") return "JUN";
    if (month == "07") return "JUL";
    if (month == "08") return "AUG";
    if (month == "09") return "SEPT";
    if (month == "10") return "OCT";
    if (month == "11") return "NOV";
    return "DEC";
  };

  return (
    <div className="blogBodyCon">
      <BlogHr />
      {user && user.isadmin && <AddStoryBtn />}

      <div className="blogBodyInnerCon">
        <div className="blogNoStory">
          {/* <BlogDisplayCard /> */}
          {items.map((item) => (
            <div key={item.id}>
              <BlogDisplayCard
                id={item.id}
                src={item.image.url}
                blogType={item.catergory}
                blogTitle={item.title}
                blogPoster={item.poster}
                blogDatePosted={`${item.create_date
                  .toString()
                  .slice(8, 10)} ${getMonth(
                  item.create_date.toString().slice(5, 7)
                )} ${item.create_date.toString().slice(0, 4)}`}
                blogContent={item.introduction}
                onClickDelete={() => onClickDelete(item.id)}
                onClickEdit={() => onClickEdit(item.id)}
                isadmin={user && user.isadmin}
              />
            </div>
          ))}
        </div>
        {items.length > 0 && (
          <div className="blogRecentStory">
            <h6 style={{ fontWeight: "bold" }}>RECENT STORIES</h6>
            {items.map((item) => (
              <div key={`${item.id}`}>
                <BlogRecentPost
                  to={`/blog/${item.id}`}
                  recentContent={item.paragraphs[0].slice(0, 110)}
                  recentDate={`${item.create_date
                    .toString()
                    .slice(8, 10)} ${getMonth(
                    item.create_date.toString().slice(5, 7)
                  )} ${item.create_date.toString().slice(0, 4)}`}
                  onClickBlogDeleted={() => onClickDelete(item.id)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogBody;
