import { faPlus, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, TextField } from "@mui/material";

import { trackEvent } from "../../Utils/useGAEventTracker";

import "./listing.css";
import ListingAnalytics from "./ListingAnalytics";

const Search = ({
  onChangeSearch,
  onChangeSort,
  listings,
  onSelectLising,
  selectedListing,
  years,
  barChartData,
  grossPayment,
  tenants,
  lineChartData,
}) => {
  const { t } = useTranslation();

  return (
    <div className="listingSearchCon">
      <div className="addlistCons">
        <Link
          style={{ boxShadow: "3px 1px 3px 0.5px black" }}
          onClick={() =>
            trackEvent(
              "add listing",
              "/portal/add/add_listing",
              "add listing Btn in listings"
            )
          }
          className="searchButtonOhh"
          to="/portal/add/add_listing"
        >
          <span style={{ marginRight: "5px" }}>
            <FontAwesomeIcon icon={faPlusCircle} />
          </span>
          {t("Add_Listing")}
        </Link>
        <div style={{ marginLeft: 30 }}>
          <ListingAnalytics
            listings={listings}
            onSelectLising={onSelectLising}
            selectedListing={selectedListing}
            years={years}
            barChartData={barChartData}
            grossPayment={grossPayment}
            tenants={tenants}
            lineChartData={lineChartData}
          />
        </div>
      </div>

      <div className="inputFieldCon someMarginInListingSearchInput">
        <Box sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
          <SearchIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          <TextField
            inputProps={{
              style: { font: "normal normal 14px/25px Merriweather" },
            }}
            sx={{ width: "100%", font: "normal normal 14px/25px Merriweather" }}
            onChange={onChangeSearch}
            id="input-with-sx"
            label={t("Search_your_listings")}
            variant="standard"
          />
        </Box>
      </div>

      <div className="listingSortDropdown someMarginInListingSearchInput">
        <p>
          <span className="sortedBySpan">{t("Sort_By")}</span>
          <span className="listingSortSelectSpan">
            <select
              className="selectionForSearchListing"
              onChange={onChangeSort}
              style={{ outline: "none" }}
            >
              <option value="dateposted">{t("Date")}</option>
              <option value="title">{t("Title")}</option>
            </select>
          </span>
        </p>
      </div>
    </div>
  );
};

export default Search;
