import { TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const TenantSearch = ({
  onChangeListingTitle,
  onChangeListingType,
  onChangeListingCountry,
  onChangeListingCity,
}) => {
  const { t } = useTranslation();
  return (
    <div className="tenantSearchCon">
      <div className="tenantSearchInputCon">
        <TextField
          style={{ width: "100%" }}
          id="standard-basic"
          label={t("Searh Listing By Title")}
          variant="standard"
          onChange={onChangeListingTitle}
        />
      </div>
      {/* <div className="tenantSearchInputCon">
        <TextField
          style={{ width: "100%" }}
          id="standard-basic"
          label="Search Listing By Listing Type"
          variant="standard"
          onChange={onChangeListingType}
        />
      </div> */}
      <div className="tenantSearchInputCon">
        <TextField
          style={{ width: "100%" }}
          id="standard-basic"
          label={t("Search Listing By Country")}
          variant="standard"
          onChange={onChangeListingCountry}
        />
      </div>
      <div className="tenantSearchInputCon">
        <TextField
          style={{ width: "100%" }}
          id="standard-basic"
          label={t("Search Listing By City")}
          variant="standard"
          onChange={onChangeListingCity}
        />
      </div>
    </div>
  );
};

export default TenantSearch;
