import React from 'react'
import 'simplebar';
import 'simplebar/dist/simplebar.css';
import { useTranslation } from 'react-i18next';

import "./messageDetail.css"

const Reply = ({message}) => {
  const {t}=useTranslation()
return (
<div data-simplebar className="replyOuterContainer">
  <p className="p1"><b className="theboldnessForReply">{t("Reply_For")}: </b> 
  <span className="theHeading">
  {message.reply&&message.reply.subject.toUpperCase()}
  </span></p>
  <p className="p2">
  {message.reply&&message.reply.body}        
  </p>
  </div>
)
}

export default Reply
