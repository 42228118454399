import React from 'react'

const BlogImage = ({ src = '../../images/felix2.jpg' }) => {
  return (
    <div className='blogDisplayImageCon'>
      <img
        crossorigin='anonymous'
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        src={src}
      />
    </div>
  )
}

export default BlogImage
