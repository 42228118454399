export function houseAnimation() {
  return {
    v: '5.4.2',
    fr: 25,
    ip: 0,
    op: 150,
    w: 1000,
    h: 1000,
    nm: 'Lotttie',
    ddd: 0,
    assets: [
      {
        id: 'comp_0',
        layers: [
          {
            ddd: 0,
            ind: 1,
            ty: 4,
            nm: 'Heart',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [373.753, 158, 0], ix: 2 },
              a: { a: 0, k: [-26.247, -142, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                    ],
                    t: 16,
                    s: [0, 0, 100],
                    e: [150, 150, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                    ],
                    t: 21,
                    s: [150, 150, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 27,
                    s: [100, 100, 100],
                    e: [80, 80, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 35,
                    s: [80, 80, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 45,
                    s: [100, 100, 100],
                    e: [80, 80, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 55,
                    s: [80, 80, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 65,
                    s: [100, 100, 100],
                    e: [80, 80, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 75,
                    s: [80, 80, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 85,
                    s: [100, 100, 100],
                    e: [80, 80, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 95,
                    s: [80, 80, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 105,
                    s: [100, 100, 100],
                    e: [80, 80, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 115,
                    s: [80, 80, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 125,
                    s: [100, 100, 100],
                    e: [80, 80, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                      '0p667_1_0p167_0',
                    ],
                    t: 135,
                    s: [80, 80, 100],
                    e: [100, 100, 100],
                  },
                  { t: 145 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [3.343, 3.343],
                          [0, 0],
                          [3.343, -3.343],
                          [3.343, -3.343],
                          [0, 0],
                          [-3.343, -3.343],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [-3.343, -3.343],
                          [-3.343, -3.343],
                          [0, 0],
                          [-3.343, 3.343],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [3.343, -3.343],
                        ],
                        v: [
                          [-14.142, -152.852],
                          [-14.142, -152.852],
                          [-26.247, -152.852],
                          [-38.353, -152.852],
                          [-38.353, -152.852],
                          [-38.353, -140.746],
                          [-38.353, -140.746],
                          [-26.247, -128.64],
                          [-14.142, -140.746],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [1, 0.341176480055, 0.392156869173, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 8,
            op: 383,
            st: 8,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 2,
            ty: 3,
            nm: 'Position Parede',
            sr: 1,
            ks: {
              o: { a: 0, k: 0, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    n: '0p667_1_0p333_0',
                    t: 2,
                    s: [400, 335, 0],
                    e: [400, 301, 0],
                    to: [0, -5.66666650772095, 0],
                    ti: [0, 0, 0],
                  },
                  {
                    i: { x: 1, y: 1 },
                    o: { x: 1, y: 0 },
                    n: '1_1_1_0',
                    t: 8,
                    s: [400, 301, 0],
                    e: [400, 335, 0],
                    to: [0, 0, 0],
                    ti: [0, -5.66666650772095, 0],
                  },
                  { t: 12 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [0, 35, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                    ],
                    t: 3,
                    s: [100, 100, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                    ],
                    t: 11,
                    s: [100, 100, 100],
                    e: [110, 83, 100],
                  },
                  {
                    i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    n: [
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                      '0p667_1_0p333_0',
                    ],
                    t: 12,
                    s: [110, 83, 100],
                    e: [100, 100, 100],
                  },
                  { t: 15 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            ip: 0,
            op: 371,
            st: -4,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 3,
            ty: 4,
            nm: 'Sotão',
            parent: 2,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    n: '0p667_1_0p333_0',
                    t: 7,
                    s: [-23.681, -128.755, 0],
                    e: [-23.681, -57.755, 0],
                    to: [0, 11.8333330154419, 0],
                    ti: [0, -11.8333330154419, 0],
                  },
                  { t: 12 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [-23.681, -60.273, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-57.434, -83.413],
                          [-58.993, -84.722],
                          [-36.048, -112.048],
                          [-15.096, -88.964],
                          [14.298, -88.964],
                          [14.298, -86.927],
                          [-15.998, -86.927],
                          [-35.988, -108.953],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [2.036, 22.744], ix: 2 },
                    p: { a: 0, k: [-18.374, -72.663], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 2,
                cix: 2,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-50.233, -62.309],
                          [-18.983, -62.309],
                          [-0.866, -83.046],
                          [-18.831, -83.017],
                          [-35.762, -102.064],
                          [-50.233, -85.886],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-18.058, -60.273],
                          [-52.269, -60.273],
                          [-52.269, -86.665],
                          [-35.757, -105.124],
                          [-17.917, -85.054],
                          [3.624, -85.089],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 3,
                cix: 2,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [17.252, -19.861],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.983, 1.131],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-17.917, -85.054],
                          [10.154, -85.054],
                          [12.923, -87.922],
                          [-8.71, -113.237],
                          [-37.899, -113.244],
                          [-60.248, -87.515],
                          [-57.795, -85.085],
                          [-52.269, -85.085],
                          [-52.269, -86.665],
                          [-35.757, -105.124],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [-0.061, 0.07],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [11.018, -83.017],
                          [-18.832, -83.017],
                          [-35.762, -102.064],
                          [-50.233, -85.886],
                          [-50.233, -83.049],
                          [-58.632, -83.049],
                          [-63.035, -87.411],
                          [-62.41, -88.131],
                          [-39.133, -114.93],
                          [-38.828, -115.281],
                          [-7.772, -115.273],
                          [15.673, -87.839],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 4',
                np: 3,
                cix: 2,
                ix: 4,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [17.504, 18.102], ix: 2 },
                    p: { a: 0, k: [-36.018, -77.207], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'rc',
                    d: 3,
                    s: { a: 0, k: [21.577, 22.174], ix: 2 },
                    p: { a: 0, k: [-36.018, -77.207], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 2',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 5',
                np: 3,
                cix: 2,
                ix: 5,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [10.586, -84.035],
                          [-18.129, -84.035],
                          [-18.371, -60.44],
                          [-10.131, -60.44],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 6',
                np: 2,
                cix: 2,
                ix: 6,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [19.541, 2.792], ix: 2 },
                    p: { a: 0, k: [-36.018, -85.88], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 7',
                np: 2,
                cix: 2,
                ix: 7,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [3.332, 20.138], ix: 2 },
                    p: { a: 0, k: [-44.122, -77.207], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 8',
                np: 2,
                cix: 2,
                ix: 8,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [19.541, 20.138], ix: 2 },
                    p: { a: 0, k: [-36.018, -77.207], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.40000000596, 0.588235318661, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 9',
                np: 2,
                cix: 2,
                ix: 9,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [12.833, -87.945],
                          [-15.547, -87.945],
                          [-38.363, -114.711],
                          [-8.241, -114.704],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.643137276173, 0.772549033165, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 10',
                np: 2,
                cix: 2,
                ix: 10,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [1.379, -84.067],
                          [-18.374, -61.74],
                          [-18.374, -84.035],
                          [-9.589, -84.035],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 11',
                np: 2,
                cix: 2,
                ix: 11,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-18.374, -84.484],
                          [-35.76, -104.043],
                          [-51.251, -86.725],
                          [-51.251, -61.74],
                          [-18.521, -61.74],
                          [1.379, -84.516],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 12',
                np: 2,
                cix: 2,
                ix: 12,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [-0.003, 0.004],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [14.298, -88.329],
                          [-8.241, -114.704],
                          [-38.363, -114.711],
                          [-61.642, -87.912],
                          [-58.213, -84.516],
                          [-51.251, -84.516],
                          [-51.251, -86.725],
                          [-35.76, -104.043],
                          [-18.374, -84.484],
                          [10.586, -84.484],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 13',
                np: 2,
                cix: 2,
                ix: 13,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 5,
            op: 380,
            st: 5,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 4,
            ty: 4,
            nm: 'Telhado',
            parent: 2,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: {
                a: 1,
                k: [
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.333, y: 0 },
                    n: '0p667_1_0p333_0',
                    t: 8,
                    s: [-57.534, -91.496, 0],
                    e: [-57.534, -43.496, 0],
                    to: [0, 8, 0],
                    ti: [0, -8, 0],
                  },
                  { t: 12 },
                ],
                ix: 2,
              },
              a: { a: 0, k: [-57.534, -46.014, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [10.817, -47.49],
                          [-115.268, -47.49],
                          [-115.268, -49.526],
                          [9.866, -49.526],
                          [60.356, -109.875],
                          [61.917, -108.568],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [113.584, -43.787],
                          [105.283, -43.803],
                          [97.275, -54.257],
                          [60.998, -100.539],
                          [13.332, -44.002],
                          [-112.203, -44.002],
                          [-116.653, -48.453],
                          [-63.929, -110.233],
                          [9.194, -110.234],
                          [9.194, -108.198],
                          [-62.989, -108.196],
                          [-113.883, -48.562],
                          [-111.359, -46.038],
                          [12.386, -46.038],
                          [61.056, -103.766],
                          [98.885, -55.505],
                          [106.29, -45.837],
                          [109.425, -45.832],
                          [97.264, -61.536],
                          [60.641, -108.204],
                          [-3.921, -108.2],
                          [-3.921, -110.236],
                          [61.632, -110.24],
                          [98.87, -62.788],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 2,
                cix: 2,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [10.341, -48.508],
                          [-115.268, -48.508],
                          [-64.349, -108.172],
                          [61.136, -109.222],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.40000000596, 0.588235318661, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 2,
                cix: 2,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-43.136, -108.987],
                          [-63.459, -108.986],
                          [-115.268, -48.279],
                          [-111.781, -44.792],
                          [12.859, -44.792],
                          [61.027, -101.924],
                          [105.786, -44.821],
                          [111.505, -44.81],
                          [61.136, -108.993],
                          [-3.921, -108.989],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 4',
                np: 2,
                cix: 2,
                ix: 4,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 5,
            op: 378,
            st: 3,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 5,
            ty: 4,
            nm: 'Porta',
            parent: 2,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [-67.961, 36.249, 0], ix: 2 },
              a: { a: 0, k: [-67.961, 37.231, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.256, 0.256, 0.256], y: [1, 1, 1] },
                    o: { x: [0.806, 0.806, 0.806], y: [0, 0, 0] },
                    n: [
                      '0p256_1_0p806_0',
                      '0p256_1_0p806_0',
                      '0p256_1_0p806_0',
                    ],
                    t: 3,
                    s: [100, 0, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.999, 0.999, 0.999], y: [1, 1, 1] },
                    o: { x: [0.904, 0.904, 0.904], y: [0, 0, 0] },
                    n: [
                      '0p999_1_0p904_0',
                      '0p999_1_0p904_0',
                      '0p999_1_0p904_0',
                    ],
                    t: 8,
                    s: [100, 100, 100],
                    e: [100, 72, 100],
                  },
                  {
                    i: { x: [0, 0, 0], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    n: ['0_1_0p333_0', '0_1_0p333_0', '0_1_0p333_0'],
                    t: 11,
                    s: [100, 72, 100],
                    e: [100, 100, 100],
                  },
                  { t: 14 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [4.502, 3.293], ix: 2 },
                    p: { a: 0, k: [-28.944, -24.338], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'rc',
                    d: 3,
                    s: { a: 0, k: [8.575, 7.365], ix: 2 },
                    p: { a: 0, k: [-28.944, -24.338], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 2',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 3,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-69.227, 35.446],
                          [-65.99, 35.446],
                          [-65.99, -22.773],
                          [-26.693, -22.773],
                          [-26.693, -25.985],
                          [-69.175, -25.985],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-63.954, 37.483],
                          [-71.266, 37.483],
                          [-71.209, -28.021],
                          [-24.657, -28.021],
                          [-24.657, -20.737],
                          [-63.954, -20.737],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 3,
                cix: 2,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [5.22, 63.147], ix: 2 },
                    p: { a: 0, k: [-67.582, 4.57], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.643137276173, 0.772549033165, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 2,
                cix: 2,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [37.978, 5.329], ix: 2 },
                    p: { a: 0, k: [-51.203, -24.339], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.643137276173, 0.772549033165, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 4',
                np: 2,
                cix: 2,
                ix: 4,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 3,
            op: 378,
            st: 3,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 6,
            ty: 4,
            nm: 'Porta 2',
            parent: 2,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [-42.456, 36.501, 0], ix: 2 },
              a: { a: 0, k: [-42.456, 37.483, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.256, 0.256, 0.256], y: [1, 1, 1] },
                    o: { x: [0.806, 0.806, 0.806], y: [0, 0, 0] },
                    n: [
                      '0p256_1_0p806_0',
                      '0p256_1_0p806_0',
                      '0p256_1_0p806_0',
                    ],
                    t: 3,
                    s: [100, 0, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.999, 0.999, 0.999], y: [1, 1, 1] },
                    o: { x: [0.904, 0.904, 0.904], y: [0, 0, 0] },
                    n: [
                      '0p999_1_0p904_0',
                      '0p999_1_0p904_0',
                      '0p999_1_0p904_0',
                    ],
                    t: 8,
                    s: [100, 100, 100],
                    e: [100, 72, 100],
                  },
                  {
                    i: { x: [0, 0, 0], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    n: ['0_1_0p333_0', '0_1_0p333_0', '0_1_0p333_0'],
                    t: 11,
                    s: [100, 72, 100],
                    e: [100, 100, 100],
                  },
                  { t: 14 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-56.032, 35.446],
                          [-28.717, 35.446],
                          [-28.88, -13.91],
                          [-56.195, -13.91],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ind: 1,
                    ty: 'sh',
                    ix: 2,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-26.674, 37.483],
                          [-58.061, 37.483],
                          [-58.238, -15.946],
                          [-26.85, -15.946],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 2',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 3,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-45.615, 36.346],
                          [-28.265, 18.996],
                          [-28.265, 14.759],
                          [-49.851, 36.346],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 2,
                cix: 2,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-53.934, 36.346],
                          [-28.265, 10.677],
                          [-28.265, 6.44],
                          [-57.094, 35.27],
                          [-57.094, 36.346],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 2,
                cix: 2,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-36.587, 36.346],
                          [-28.265, 28.023],
                          [-28.265, 23.786],
                          [-40.824, 36.346],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 4',
                np: 2,
                cix: 2,
                ix: 4,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-57.094, 26.243],
                          [-57.094, 30.479],
                          [-28.265, 1.649],
                          [-28.265, -2.587],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 5',
                np: 2,
                cix: 2,
                ix: 5,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-57.094, 17.215],
                          [-57.094, 21.452],
                          [-28.265, -7.378],
                          [-28.265, -11.614],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 6',
                np: 2,
                cix: 2,
                ix: 6,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-57.094, -5.631],
                          [-47.819, -14.907],
                          [-52.055, -14.907],
                          [-57.094, -9.868],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 7',
                np: 2,
                cix: 2,
                ix: 7,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-57.094, 12.423],
                          [-29.764, -14.907],
                          [-34.001, -14.907],
                          [-57.094, 8.187],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 8',
                np: 2,
                cix: 2,
                ix: 8,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-57.094, 3.396],
                          [-38.791, -14.907],
                          [-43.028, -14.907],
                          [-57.094, -0.841],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 9',
                np: 2,
                cix: 2,
                ix: 9,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [29.521, 3.559], ix: 2 },
                    p: { a: 0, k: [-42.456, -13.149], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 10',
                np: 2,
                cix: 2,
                ix: 10,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [4.563, 51.072], ix: 2 },
                    p: { a: 0, k: [-54.935, 10.608], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 11',
                np: 2,
                cix: 2,
                ix: 11,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [30.15, 51.393], ix: 2 },
                    p: { a: 0, k: [-42.141, 10.768], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.643137276173, 0.772549033165, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 12',
                np: 2,
                cix: 2,
                ix: 12,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 3,
            op: 378,
            st: 3,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 7,
            ty: 4,
            nm: 'Janela',
            parent: 2,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [65.494, 25.249, 0], ix: 2 },
              a: { a: 0, k: [65.494, 25.249, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0], y: [1, 1, 1] },
                    o: { x: [0.152, 0.152, 0.152], y: [0, 0, 0] },
                    n: ['0_1_0p152_0', '0_1_0p152_0', '0_1_0p152_0'],
                    t: 3,
                    s: [100, 0, 100],
                    e: [100, 100, 100],
                  },
                  {
                    i: { x: [0.833, 0.833, 0.833], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: [
                      '0p833_1_0p167_0',
                      '0p833_1_0p167_0',
                      '0p833_1_0p167_0',
                    ],
                    t: 11,
                    s: [100, 100, 100],
                    e: [100, 92, 100],
                  },
                  {
                    i: { x: [0, 0, 0], y: [1, 1, 1] },
                    o: { x: [0.167, 0.167, 0.167], y: [0, 0, 0] },
                    n: ['0_1_0p167_0', '0_1_0p167_0', '0_1_0p167_0'],
                    t: 12,
                    s: [100, 92, 100],
                    e: [100, 100, 100],
                  },
                  { t: 15 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [40.63, 2.036], ix: 2 },
                    p: { a: 0, k: [59.243, 2.813], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [40.63, 2.036], ix: 2 },
                    p: { a: 0, k: [59.243, -19.502], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 2,
                cix: 2,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [2.036, 62.915], ix: 2 },
                    p: { a: 0, k: [66.396, -7.344], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 2,
                cix: 2,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [2.036, 62.915], ix: 2 },
                    p: { a: 0, k: [52.16, -7.344], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 4',
                np: 2,
                cix: 2,
                ix: 4,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [38.594, 61.457], ix: 2 },
                    p: { a: 0, k: [59.243, -7.516], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'rc',
                    d: 3,
                    s: { a: 0, k: [42.667, 65.53], ix: 2 },
                    p: { a: 0, k: [59.243, -7.516], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 2',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 5',
                np: 3,
                cix: 2,
                ix: 5,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [40.63, 3.871], ix: 2 },
                    p: { a: 0, k: [59.243, -37.328], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 6',
                np: 2,
                cix: 2,
                ix: 6,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [4.335, 63.377], ix: 2 },
                    p: { a: 0, k: [41.095, -7.387], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 7',
                np: 2,
                cix: 2,
                ix: 7,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [38.6, 9.402],
                          [38.6, 13.639],
                          [79.604, -27.365],
                          [79.604, -31.602],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 8',
                np: 2,
                cix: 2,
                ix: 8,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [77.986, -39.011],
                          [38.6, 0.375],
                          [38.6, 4.611],
                          [79.604, -36.393],
                          [79.604, -39.011],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 9',
                np: 2,
                cix: 2,
                ix: 9,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [46.091, 24.203],
                          [79.604, -9.309],
                          [79.604, -13.546],
                          [41.855, 24.203],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 10',
                np: 2,
                cix: 2,
                ix: 10,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [40.63, 63.494], ix: 2 },
                    p: { a: 0, k: [59.243, -7.329], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.40000000596, 0.588235318661, 1, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 11',
                np: 2,
                cix: 2,
                ix: 11,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 3,
            op: 378,
            st: 3,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 8,
            ty: 4,
            nm: 'Parede',
            parent: 2,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [-47.77, 35.446, 0], ix: 2 },
              a: { a: 0, k: [-47.77, 35.446, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0], y: [1, 1, 1] },
                    o: { x: [0.152, 0.152, 0.152], y: [0, 0, 0] },
                    n: ['0_1_0p152_0', '0_1_0p152_0', '0_1_0p152_0'],
                    t: 3,
                    s: [100, 0, 100],
                    e: [100, 100, 100],
                  },
                  { t: 9 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [114.187, 79.449], ix: 2 },
                    p: { a: 0, k: [-47.77, -4.278], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'rc',
                    d: 3,
                    s: { a: 0, k: [118.259, 83.521], ix: 2 },
                    p: { a: 0, k: [-47.77, -4.278], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 2',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 3,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'gr',
                    it: [
                      {
                        ty: 'rc',
                        d: 1,
                        s: { a: 0, k: [4.606, 81.503], ix: 2 },
                        p: { a: 0, k: [7.912, -4.287], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: 'Rectangle Path 1',
                        mn: 'ADBE Vector Shape - Rect',
                        hd: false,
                      },
                      {
                        ty: 'fl',
                        c: {
                          a: 0,
                          k: [
                            0.360784322023, 0.529411792755, 0.901960790157, 1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: 'Fill 1',
                        mn: 'ADBE Vector Graphic - Fill',
                        hd: false,
                      },
                      {
                        ty: 'tr',
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: 'Transform',
                      },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                  },
                  {
                    ty: 'gr',
                    it: [
                      {
                        ty: 'rc',
                        d: 1,
                        s: { a: 0, k: [116.096, 4.645], ix: 2 },
                        p: { a: 0, k: [-47.834, -42.698], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: 'Rectangle Path 1',
                        mn: 'ADBE Vector Shape - Rect',
                        hd: false,
                      },
                      {
                        ty: 'fl',
                        c: {
                          a: 0,
                          k: [
                            0.360784322023, 0.529411792755, 0.901960790157, 1,
                          ],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: 'Fill 1',
                        mn: 'ADBE Vector Graphic - Fill',
                        hd: false,
                      },
                      {
                        ty: 'tr',
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: 'Transform',
                      },
                    ],
                    nm: 'Group 2',
                    np: 2,
                    cix: 2,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 2,
                cix: 2,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [116.223, 81.625], ix: 2 },
                    p: { a: 0, k: [-47.77, -3.979], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 2,
                cix: 2,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 3,
            op: 378,
            st: 3,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 9,
            ty: 4,
            nm: 'Parede 2',
            parent: 2,
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [57.994, 37.483, 0], ix: 2 },
              a: { a: 0, k: [57.994, 37.483, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0], y: [1, 1, 1] },
                    o: { x: [0.152, 0.152, 0.152], y: [0, 0, 0] },
                    n: ['0_1_0p152_0', '0_1_0p152_0', '0_1_0p152_0'],
                    t: 3,
                    s: [100, 0, 100],
                    e: [100, 100, 100],
                  },
                  { t: 9 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [106.783, 37.483],
                          [9.194, 37.483],
                          [9.325, -46.098],
                          [12.386, -46.098],
                          [61.056, -103.765],
                          [98.909, -55.523],
                          [97.307, -54.267],
                          [60.998, -100.54],
                          [13.332, -44.062],
                          [11.357, -44.062],
                          [11.234, 35.446],
                          [104.747, 35.446],
                          [104.757, -44.82],
                          [106.793, -44.82],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [11.348, -40.375],
                          [15.646, -40.375],
                          [61.227, -93.829],
                          [99.613, -45.583],
                          [106.787, -45.583],
                          [61.046, -101.806],
                          [13.86, -46.088],
                          [11.233, -46.088],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.360784322023, 0.529411792755, 0.901960790157, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 2,
                cix: 2,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [61.027, -101.924],
                          [12.859, -44.792],
                          [10.341, -44.792],
                          [10.215, 36.839],
                          [105.765, 36.839],
                          [105.786, -44.821],
                        ],
                        c: true,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 2,
                cix: 2,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 3,
            op: 378,
            st: 3,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 10,
            ty: 4,
            nm: 'Chão',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [399.875, 345.281, 0], ix: 2 },
              a: { a: 0, k: [-0.125, 45.281, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0, 0, 0], y: [1, 1, 1] },
                    o: { x: [0.152, 0.152, 0.152], y: [0, 0, 0] },
                    n: ['0_1_0p152_0', '0_1_0p152_0', '0_1_0p152_0'],
                    t: 1,
                    s: [100, 0, 100],
                    e: [100, 157, 100],
                  },
                  {
                    i: { x: [1, 0, 0], y: [1, 1, 1] },
                    o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                    n: ['1_1_0p333_0', '0_1_0p333_0', '0_1_0p333_0'],
                    t: 12,
                    s: [100, 157, 100],
                    e: [100, 100, 100],
                  },
                  { t: 13 },
                ],
                ix: 6,
              },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [139.938, 5.762], ix: 2 },
                    p: { a: 0, k: [-45.443, 40.364], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'rc',
                    d: 3,
                    s: { a: 0, k: [144.01, 9.834], ix: 2 },
                    p: { a: 0, k: [-45.443, 40.364], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 2',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 3,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'rc',
                    d: 1,
                    s: { a: 0, k: [88.85, 5.762], ix: 2 },
                    p: { a: 0, k: [70.987, 40.364], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 1',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'rc',
                    d: 3,
                    s: { a: 0, k: [92.923, 9.834], ix: 2 },
                    p: { a: 0, k: [70.987, 40.364], ix: 3 },
                    r: { a: 0, k: 0, ix: 4 },
                    nm: 'Rectangle Path 2',
                    mn: 'ADBE Vector Shape - Rect',
                    hd: false,
                  },
                  {
                    ty: 'fl',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 4,
                    },
                    o: { a: 0, k: 100, ix: 5 },
                    r: 1,
                    nm: 'Fill 1',
                    mn: 'ADBE Vector Graphic - Fill',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 2',
                np: 3,
                cix: 2,
                ix: 2,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'gr',
                it: [
                  {
                    ty: 'gr',
                    it: [
                      {
                        ind: 0,
                        ty: 'sh',
                        ix: 1,
                        ks: {
                          a: 0,
                          k: {
                            i: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            o: [
                              [0, 0],
                              [0, 0],
                              [0, 0],
                              [0, 0],
                            ],
                            v: [
                              [25.544, 44.263],
                              [-62.772, 44.263],
                              [-53.899, 36.61],
                              [25.544, 36.61],
                            ],
                            c: true,
                          },
                          ix: 2,
                        },
                        nm: 'Path 1',
                        mn: 'ADBE Vector Shape - Group',
                        hd: false,
                      },
                      {
                        ty: 'fl',
                        c: {
                          a: 0,
                          k: [0.643137276173, 0.772549033165, 1, 1],
                          ix: 4,
                        },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: 'Fill 1',
                        mn: 'ADBE Vector Graphic - Fill',
                        hd: false,
                      },
                      {
                        ty: 'tr',
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: 'Transform',
                      },
                    ],
                    nm: 'Group 1',
                    np: 2,
                    cix: 2,
                    ix: 1,
                    mn: 'ADBE Vector Group',
                    hd: false,
                  },
                  {
                    ty: 'gr',
                    it: [
                      {
                        ty: 'rc',
                        d: 1,
                        s: { a: 0, k: [141.974, 7.798], ix: 2 },
                        p: { a: 0, k: [-45.443, 40.593], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: 'Rectangle Path 1',
                        mn: 'ADBE Vector Shape - Rect',
                        hd: false,
                      },
                      {
                        ty: 'fl',
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: 'Fill 1',
                        mn: 'ADBE Vector Graphic - Fill',
                        hd: false,
                      },
                      {
                        ty: 'tr',
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: 'Transform',
                      },
                    ],
                    nm: 'Group 2',
                    np: 2,
                    cix: 2,
                    ix: 2,
                    mn: 'ADBE Vector Group',
                    hd: false,
                  },
                  {
                    ty: 'gr',
                    it: [
                      {
                        ty: 'rc',
                        d: 1,
                        s: { a: 0, k: [90.886, 7.798], ix: 2 },
                        p: { a: 0, k: [70.987, 40.593], ix: 3 },
                        r: { a: 0, k: 0, ix: 4 },
                        nm: 'Rectangle Path 1',
                        mn: 'ADBE Vector Shape - Rect',
                        hd: false,
                      },
                      {
                        ty: 'fl',
                        c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                        o: { a: 0, k: 100, ix: 5 },
                        r: 1,
                        nm: 'Fill 1',
                        mn: 'ADBE Vector Graphic - Fill',
                        hd: false,
                      },
                      {
                        ty: 'tr',
                        p: { a: 0, k: [0, 0], ix: 2 },
                        a: { a: 0, k: [0, 0], ix: 1 },
                        s: { a: 0, k: [100, 100], ix: 3 },
                        r: { a: 0, k: 0, ix: 6 },
                        o: { a: 0, k: 100, ix: 7 },
                        sk: { a: 0, k: 0, ix: 4 },
                        sa: { a: 0, k: 0, ix: 5 },
                        nm: 'Transform',
                      },
                    ],
                    nm: 'Group 3',
                    np: 2,
                    cix: 2,
                    ix: 3,
                    mn: 'ADBE Vector Group',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 3',
                np: 3,
                cix: 2,
                ix: 3,
                mn: 'ADBE Vector Group',
                hd: false,
              },
            ],
            ip: 1,
            op: 376,
            st: 1,
            bm: 0,
          },
          {
            ddd: 0,
            ind: 11,
            ty: 4,
            nm: 'Line',
            sr: 1,
            ks: {
              o: { a: 0, k: 100, ix: 11 },
              r: { a: 0, k: 0, ix: 10 },
              p: { a: 0, k: [400, 344.492, 0], ix: 2 },
              a: { a: 0, k: [0, 44.492, 0], ix: 1 },
              s: { a: 0, k: [100, 100, 100], ix: 6 },
            },
            ao: 0,
            shapes: [
              {
                ty: 'gr',
                it: [
                  {
                    ind: 0,
                    ty: 'sh',
                    ix: 1,
                    ks: {
                      a: 0,
                      k: {
                        i: [
                          [0, 0],
                          [0, 0],
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                        ],
                        v: [
                          [-158.47, 44.492],
                          [158.47, 44.492],
                        ],
                        c: false,
                      },
                      ix: 2,
                    },
                    nm: 'Path 1',
                    mn: 'ADBE Vector Shape - Group',
                    hd: false,
                  },
                  {
                    ty: 'st',
                    c: {
                      a: 0,
                      k: [0.250980407, 0.090196080506, 0.215686276555, 1],
                      ix: 3,
                    },
                    o: { a: 0, k: 100, ix: 4 },
                    w: { a: 0, k: 3, ix: 5 },
                    lc: 1,
                    lj: 1,
                    ml: 10,
                    ml2: { a: 0, k: 10, ix: 8 },
                    nm: 'Stroke 1',
                    mn: 'ADBE Vector Graphic - Stroke',
                    hd: false,
                  },
                  {
                    ty: 'tr',
                    p: { a: 0, k: [0, 0], ix: 2 },
                    a: { a: 0, k: [0, 0], ix: 1 },
                    s: { a: 0, k: [100, 100], ix: 3 },
                    r: { a: 0, k: 0, ix: 6 },
                    o: { a: 0, k: 100, ix: 7 },
                    sk: { a: 0, k: 0, ix: 4 },
                    sa: { a: 0, k: 0, ix: 5 },
                    nm: 'Transform',
                  },
                ],
                nm: 'Group 1',
                np: 2,
                cix: 2,
                ix: 1,
                mn: 'ADBE Vector Group',
                hd: false,
              },
              {
                ty: 'tm',
                s: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.152], y: [0] },
                      n: ['0_1_0p152_0'],
                      t: 1,
                      s: [50],
                      e: [0],
                    },
                    { t: 7 },
                  ],
                  ix: 1,
                },
                e: {
                  a: 1,
                  k: [
                    {
                      i: { x: [0], y: [1] },
                      o: { x: [0.152], y: [0] },
                      n: ['0_1_0p152_0'],
                      t: 1,
                      s: [50],
                      e: [100],
                    },
                    { t: 7 },
                  ],
                  ix: 2,
                },
                o: { a: 0, k: 0, ix: 3 },
                m: 1,
                ix: 2,
                nm: 'Trim Paths 1',
                mn: 'ADBE Vector Filter - Trim',
                hd: false,
              },
            ],
            ip: 1,
            op: 376,
            st: 1,
            bm: 0,
          },
        ],
      },
    ],
    layers: [
      {
        ddd: 0,
        ind: 1,
        ty: 0,
        nm: '_COMP_Principal',
        refId: 'comp_0',
        sr: 1,
        ks: {
          o: { a: 0, k: 100, ix: 11 },
          r: { a: 0, k: 0, ix: 10 },
          p: { a: 0, k: [500, 592.5, 0], ix: 2 },
          a: { a: 0, k: [400, 300, 0], ix: 1 },
          s: { a: 0, k: [220, 220, 100], ix: 6 },
        },
        ao: 0,
        w: 800,
        h: 600,
        ip: 0,
        op: 375,
        st: 0,
        bm: 0,
      },
    ],
    markers: [],
  }
}
