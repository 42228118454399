import React from 'react'
import { callingCode } from '../../Utils/callingCode'

const PhoneNumberContact = ({ onChangeSelect, onChangeInput }) => {
  return (
    <div className='phoneNumberOuterContainer'>
      <label style={{ color: '#035aa6' }} htmlFor='p' className='form-label'>
        Phone Number <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
      </label>
      <div className='inputFieldsCon'>
        <select
          className='form-control'
          id='p'
          placeholder='contact'
          onChange={onChangeSelect}
        >
          <option value=''>Country Code</option>
          {callingCode().map((x, i) => (
            <option key={`${i}`} value={x.code}>
              {x.ctry}
            </option>
          ))}
        </select>

        <input
          placeholder='Phone Number(No Country code)'
          id='p'
          className='form-control'
          onChange={onChangeInput}
        />
      </div>
    </div>
  )
}

export default PhoneNumberContact
