import { faSackDollar, faXmarkCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import LoadingSpinner from '../Applications/LoadingSpinner'

const LeasesOweNotice = ({
  onClickCancel,
  allLeaseOwed,
  onClickPayDebt,
  loadingPayment,
}) => {
  //function to add commas in money

  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`)

    if (myVal.includes(',')) {
      return val
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2')
      }
      return val
    }
  }
  return (
    <div className='leaseOwedContainer'>
      <FontAwesomeIcon
        className='icon'
        icon={faXmarkCircle}
        onClick={onClickCancel}
      />

      <div
        style={{
          margin: '0 auto',
          padding: '5px',
          background: 'white',
          color: '#035aa6',
          width: '50%',
          font: 'normal normal bold 12px/25px Merriweather',
          textAlign: 'center',
        }}
      >
        PAY LEASE OWED
      </div>
      <div
        style={{
          width: '99%',
          margin: '15px auto 10px auto',
          height: '1px',
          background: 'white',
        }}
      />
      {allLeaseOwed.map((p) => (
        <div key={`${p.id}`} className='leaseOweTableCon'>
          <div
            style={{
              width: '40%',
              padding: '5px 2px',
              border: '1px solid white',
              textAlign: 'center',
              font: 'normal normal normal 14px/25px Merriweather',
            }}
          >
            {p.listing_name}
          </div>
          <div
            style={{
              width: '40%',
              padding: '5px 2px',
              border: '1px solid white',
              color: 'white',
              textAlign: 'center',
              font: 'normal normal normal 14px/25px Merriweather',
            }}
          >
            {commaSeparateNumber(p.amount_owed)}
          </div>
          <div
            style={{
              width: '20%',
              padding: '5px 2px',
              border: '1px solid white',
              textAlign: 'center',
              font: 'normal normal normal 14px/25px Merriweather',
            }}
          >
            {loadingPayment == p.id ? (
              <LoadingSpinner />
            ) : (
              <button
                onClick={() => onClickPayDebt(p)}
                className='btn btn-sm payDebtBtn'
              >
                <span style={{ marginRight: '5px' }}>
                  <FontAwesomeIcon icon={faSackDollar} />
                </span>
                Pay debt
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default LeasesOweNotice
