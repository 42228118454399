import React from 'react'

import './auth.css'

const Spinner = () => {
return (
<div aria-hidden="true" className="spinner-border AuthenticationSpinner" role="status">
  <span className="sr-only">Loading...</span>
</div>
)
}

export default Spinner
