import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

function init() {
  Sentry.init({
    dsn: "https://98ec255c68254df2bd34e3bf1efa3a26@o385086.ingest.sentry.io/4505200417243136",
    integrations: [new Integrations.BrowserTracing()],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
  });
}

export function log(error) {
  Sentry.captureException(error);
}

const logger = {
  init,
  log,
};

export default logger;
