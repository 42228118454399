import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const AddListingLoader = ({ percentage }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'fixed',
        zIndex: '10',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'black',
        opacity: '0.8',
      }}
    >
      <div style={{ width: 200, height: 200 }}>
        <CircularProgressbar
          styles={buildStyles({
            pathColor: '#035aa6',
            textColor: '#035aa6',
          })}
          value={percentage}
          text={`${percentage}%`}
        />
        ;
      </div>
    </div>
  )
}

export default AddListingLoader
