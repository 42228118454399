import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import ScrollToTop from 'react-scroll-to-top'

import AddStoryInput from './../components/BlogAddStory/AddStoryInput'
import AddStoryTextarea from '../components/BlogAddStory/AddStoryTextarea'
import AddStoryFilesInput from '../components/BlogAddStory/AddStoryFilesInput'
import AddStoryModal from '../components/BlogAddStory/AddStoryModal'
import BlogDetBackBtn from '../components/BlogAddStory/BlogDetBackBtn'
import AddStorySelect from '../components/BlogAddStory/AddStorySelect'
import { uploadSingleFileToCloudinary } from '../Utils/imageUploadToCloudinary'
import { getCloudinarySignature } from '../Services/cloudinaryService'
import { getBlogById, postBlog } from '../Services/blogService'
import { getCurrentUser } from '../Services/authService'
import { editBlog } from '../Services/blogService'
import EditParagraphModal from '../components/BlogAddStory/EditParagraphModal'
import ProfileSpinner from './../components/profile/ProfileSpinner'
import AddStoryTextarea1 from '../components/BlogAddStory/AddStoryTextarea1'
import PointsInput from '../components/BlogAddStory/PointsInput'
import logger from '../Services/loggerService'

const BlogAddStory = ({ history, match }) => {
  const [allParagraph, setAllParagraphs] = useState([])
  const [myImageData, setMyImageData] = useState({})
  const [imageLoad, setImageLoad] = useState(false)
  const [imageResult, setImageResult] = useState()
  const [imageTitle, setImageTitle] = useState('')
  const [addParagraph, setAddParagraph] = useState([
    {
      para: (
        <AddStoryTextarea
          onChange={(e) => setAllParagraphs([e.target.value])}
          num='1'
          value={allParagraph[0]}
        />
      ),
      id: 1,
    },
  ])
  const [nums, setNum] = useState(1)
  const [errorBlog, setErrorBlog] = useState('')
  const [error, setError] = useState('')
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')
  const [catergory, setCatergory] = useState('')
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)
  const [showLoader, setShowLoader] = useState(false)
  const [load, setLoad] = useState(false)
  const [createDate, setCreateDate] = useState()
  const [paragraphsFrom, setParagraphFrom] = useState([])
  const [IDS, setIDS] = useState()
  const { t } = useTranslation()
  const [open, setOpen] = useState(true)
  const catergories = [
    'Home Improvement',
    'Home Hygiene',
    'Tips & Advices',
    'Market Trends',
    'Job opportunities',
    'CT80R Update',
    'Scammer Alert',
    'Others',
  ]

  const [introduction, setIntroduction] = useState('')
  const [para1, setPara1] = useState('')
  const [para2, setPara2] = useState('')
  const [para3, setPara3] = useState('')
  const [subtitleKp1, setSubtitleKp1] = useState('')
  const [descriptionKp1, setDescriptionKp1] = useState('')
  const [subtitleKp2, setSubtitleKp2] = useState('')
  const [descriptionKp2, setDescriptionKp2] = useState('')
  const [subtitleKp3, setSubtitleKp3] = useState('')
  const [descriptionKp3, setDescriptionKp3] = useState('')
  const [subtitleConclusion, setSubtitleConclusion] = useState('')
  const [descriptionConclusion, setDescriptionConclusion] = useState('')

  useEffect(() => {
    getData()
    window.scrollTo(0, 0)
  }, [])

  const getData = async () => {
    try {
      setLoad(true)
      const { data: imagesData } = await getCloudinarySignature()
      setMyImageData(imagesData)
      setIDS(match.params.id)
      if (match.params.id) {
        const { data } = await getBlogById(match.params.id)
        setTitle(data.title)
        setSubTitle(data.subtitle)
        setImageResult(data.image)
        setImageTitle(data.image.original_filename)
        setCatergory(data.catergory)
        setParagraphFrom(data.paragraphs)
        setIntroduction(data.introduction)
        setCreateDate(data.create_date)

        setSubtitleConclusion(
          data && data.conclusion && data.conclusion.subtitle
        )
        setDescriptionConclusion(
          data && data.conclusion && data.conclusion.description
        )

        setPara1(data.paragraphs[0])
        data.paragraphs[1] && setPara2(data.paragraphs[1])
        data.paragraphs[2] && setPara3(data.paragraphs[2])

        setSubtitleKp1(data.points[0].subtitle)
        setDescriptionKp1(data.points[0].description)

        data.points[1] && setSubtitleKp2(data.points[1].subtitle)
        data.points[1] && setDescriptionKp2(data.points[1].description)

        data.points[2] && setSubtitleKp3(data.points[2].subtitle)
        data.points[2] && setDescriptionKp3(data.points[2].description)
      }
      setLoad(false)
    } catch (error) {
      logger.log(error)
    }
  }

  const addParagraphs = () => {
    let num = nums
    num = num + 1
    let paraObj = {
      id: num,
      para: (
        <AddStoryTextarea
          onChange={(e) => {
            setAllParagraphs([...allParagraph, e.target.value])
          }}
          num={num}
          value={allParagraph[num - 1]}
        />
      ),
    }
    const para = [...addParagraph]

    para.push(paraObj)
    setAddParagraph(para)
    setNum(num)
  }

  const removeParagraphs = () => {
    if (addParagraph.length > 1) {
      const addPara = [...addParagraph]
      addPara.pop()
      const allPara = [...allParagraph]
      allPara.pop()

      let num = nums - 1
      setNum(num)

      setAddParagraph(addPara)
      setAllParagraphs(allPara)
    }
  }

  //function to upload the signed lease document to the backend of the added tenant
  const onChangeUSL = async (e) => {
    e.preventDefault()
    const filesss = e.currentTarget.files[0]
    if (filesss) {
      setImageTitle(filesss.name)
    }

    if (filesss && filesss.size > 8323863) {
      setErrorBlog(t('large_file,_upload'))
    } else {
      setImageLoad(true)
      const result = await uploadSingleFileToCloudinary(filesss, myImageData)
      setImageResult(result)
      setImageLoad(false)
    }
  }

  const postStory = async (e) => {
    e.preventDefault()
    // if (allParagraph.length === 0) {
    //   setError('Paragraph is not supposed to be empty')
    //   setLoading(false)
    //   setShowLoader(false)
    //   return
    // }
    if (!title) {
      setError('Title is not allowed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    if (!subTitle) {
      setError('Sub title is not allowed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    if (!catergory) {
      setError('Catergory is not allowed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    if (!imageResult) {
      setError('Image is not allowed to be empty')
      setLoading(false)
      setShowLoader(false)
      return
    }
    // if (allParagraph.length === 0) {
    //   setLoading(false)
    //   setError('Paragraph is not allowed to be empty')
    //   setShowLoader(false)
    //   return
    // }

    if (IDS) {
      let para = []
      if (para1) para.push(para1)
      if (para2) para.push(para2)
      if (para3) para.push(para3)

      let point1 = {
        subtitle: subtitleKp1,
        description: descriptionKp1,
      }
      let point2 = {
        subtitle: subtitleKp2,
        description: descriptionKp2,
      }
      let point3 = {
        subtitle: subtitleKp3,
        description: descriptionKp3,
      }

      let points = []
      if (subtitleKp1.length > 0 && descriptionKp1.length > 0)
        points.push(point1)
      if (subtitleKp2.length > 0 && descriptionKp2.length > 0)
        points.push(point2)
      if (subtitleKp3.length > 0 && descriptionKp3.length > 0)
        points.push(point3)

      const data = {
        title,
        subTitle,
        catergory,
        image: imageResult,
        introduction,
        paragraphs: para,
        points,
        conclusion: {
          subtitle: subtitleConclusion,
          description: descriptionConclusion,
        },
        poster: getCurrentUser() && getCurrentUser().first_name,
        createDate,
      }
      try {
        setLoading(true)
        setShowLoader(true)
        await editBlog(data, IDS, (p) => setProgress(p * 100))

        setShowLoader(false)

        setLoading(false)
        setTitle('')
        setSubTitle('')
        setCatergory('')
        setImageResult()
        setAllParagraphs([''])
        setAllParagraphs([''])
        setAllParagraphs([])
        setImageTitle('')
        setPara1('')
        setPara2('')
        setPara3('')

        setSubtitleKp1('')
        setSubtitleKp2('')
        setSubtitleKp3('')

        setDescriptionKp1('')
        setDescriptionKp2('')
        setDescriptionKp3('')

        setSubtitleConclusion('')
        setDescriptionConclusion('')

        setInterval(() => {
          setLoading(false)
          setProgress(0)
        }, 10000)

        history.push('/blog')
      } catch (ex) {
        setProgress(0)
        setError(ex.response.data)
        setLoading(false)
        setShowLoader(false)
      }
    } else {
      if (title.length > 150)
        return setError('Title must not be greater than 150 letter')

      if (subTitle.length > 150)
        return setError('Sub-title must not be greater than 150 letter')

      if (!catergory)
        return setError('Story Catergory is not allow to be empty')

      if (!imageTitle) return setError('Story Image is not allowed to be Empty')

      if (!introduction)
        return setError('Introduction is not allowed to be empty')
      if (introduction.length < 20)
        return setError('Introduction must be greater than 20 character')
      if (introduction.length > 500)
        return setError('introduction must be less than 500 Characters')

      if (!para1) return setError('First Paragraph is not allowed to be empty')
      if (para1.length < 50)
        return setError('First Paragraph must be greater than 50 characters')

      if (!subtitleKp1 || !descriptionKp1)
        return setError(
          'Both Sub-title and Description of Key point 1 must be fill'
        )
      if (descriptionKp1.length < 50)
        return setError('Description of key point 1 must be greater than 50')

      if (!subtitleConclusion || !descriptionConclusion)
        return setError(
          'Both Sub-title and Description of Conclusion must be fill'
        )
      if (descriptionConclusion.length < 50)
        return setError('Description of Conclusion must be greater than 50')

      let para = []
      if (para1) para.push(para1)
      if (para2) para.push(para2)
      if (para3) para.push(para3)

      let point1 = {
        subtitle: subtitleKp1,
        description: descriptionKp1,
      }
      let point2 = {
        subtitle: subtitleKp2,
        description: descriptionKp2,
      }
      let point3 = {
        subtitle: subtitleKp3,
        description: descriptionKp3,
      }

      let points = []
      if (subtitleKp1.length > 0 && descriptionKp1.length > 0)
        points.push(point1)
      if (subtitleKp2.length > 0 && descriptionKp2.length > 0)
        points.push(point2)
      if (subtitleKp3.length > 0 && descriptionKp3.length > 0)
        points.push(point3)

      const data = {
        title,
        subTitle,
        catergory,
        image: imageResult,
        introduction,
        paragraphs: para,
        points,
        conclusion: {
          subtitle: subtitleConclusion,
          description: descriptionConclusion,
        },
        poster: getCurrentUser() && getCurrentUser().first_name,
      }
      try {
        setLoading(true)
        setShowLoader(true)
        await postBlog(data, (p) => setProgress(p * 100))

        setShowLoader(false)

        setLoading(false)
        setTitle('')
        setSubTitle('')
        setCatergory('')
        setImageResult()
        setAllParagraphs([''])
        setAllParagraphs([''])
        setAllParagraphs([])
        setImageTitle('')
        setPara1('')
        setPara2('')
        setPara3('')

        setSubtitleKp1('')
        setSubtitleKp2('')
        setSubtitleKp3('')

        setDescriptionKp1('')
        setDescriptionKp2('')
        setDescriptionKp3('')

        setSubtitleConclusion('')
        setDescriptionConclusion('')

        setInterval(() => {
          setLoading(false)
          setProgress(0)
        }, 10000)

        history.push('/blog')
      } catch (ex) {
        logger.log(ex.response.data)
        setProgress(0)
        setError(ex.response.data)
        setLoading(false)
        setShowLoader(false)
      }
    }
  }

  const onCloseModal = () => {
    if (open) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  if (load)
    return (
      <div className='homeSpinnerContainer'>
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    )

  return (
    <>
      <div className='blogAddStoryOuterCon'>
        {/* {IDS && (
          <button
            onClick={onCloseModal}
            className='btn btn-light blogAddStoryOpenBtn'
          >
            <FontAwesomeIcon
              className='blogAddStoryOpenIcon'
              icon={faDoorOpen}
            />
            open modal
          </button>
        )} */}

        {/* {IDS && open && (
          <EditParagraphModal
            onCloseModal={onCloseModal}
            content={paragraphsFrom}
          />
        )} */}
        {loading && <AddStoryModal progress={progress} loading={showLoader} />}
        <BlogDetBackBtn />
        <div className='addStoryInnerCon'>
          <div className='addStoryTitleCon'>
            <h4>ADD A STORY</h4>
          </div>
          <form onSubmit={postStory}>
            <AddStoryInput
              id='title'
              label='Title'
              placeholder='Title Of The Story'
              onChange={(e) => setTitle(e.target.value)}
              value={title}
            />
            <AddStoryInput
              id='subTitle'
              label='Sub-Title'
              placeholder='Sub-Title Of The Story'
              onChange={(e) => setSubTitle(e.target.value)}
              value={subTitle}
            />
            <AddStorySelect
              value={catergory}
              onChange={(e) => setCatergory(e.target.value)}
              catergories={catergories}
            />

            <AddStoryFilesInput
              id='storyImage'
              onChange={onChangeUSL}
              photoName={imageTitle}
              loading={imageLoad}
              error={errorBlog}
              uploadImage={imageResult}
            />

            <hr style={{ margin: '30px auto' }} />

            <AddStoryTextarea1
              title='Introduction *'
              placeholder='Please, Give an introduction for this blog'
              rows='7'
              onChange={(e) => setIntroduction(e.target.value)}
              value={introduction}
            />
            <AddStoryTextarea1
              title='First Paragraph *'
              placeholder='Description About this blog'
              rows='10'
              onChange={(e) => setPara1(e.target.value)}
              value={para1}
            />
            <AddStoryTextarea1
              title='Second Paragraph'
              placeholder='Description About this blog'
              rows='10'
              onChange={(e) => setPara2(e.target.value)}
              value={para2}
            />
            <AddStoryTextarea1
              title='Third Paragraph'
              placeholder='Description About this blog'
              rows='10'
              onChange={(e) => setPara3(e.target.value)}
              value={para3}
            />

            <hr style={{ margin: '30px auto' }} />

            <PointsInput
              title='key point .1'
              onChangeSubTitle={(e) => setSubtitleKp1(e.target.value)}
              onChange={(e) => setDescriptionKp1(e.target.value)}
              valueSubtitle={subtitleKp1}
              value={descriptionKp1}
            />
            <PointsInput
              title='key point .2'
              onChangeSubTitle={(e) => setSubtitleKp2(e.target.value)}
              onChange={(e) => setDescriptionKp2(e.target.value)}
              valueSubtitle={subtitleKp2}
              value={descriptionKp2}
            />
            <PointsInput
              title='key point .3'
              onChangeSubTitle={(e) => setSubtitleKp3(e.target.value)}
              onChange={(e) => setDescriptionKp3(e.target.value)}
              valueSubtitle={subtitleKp3}
              value={descriptionKp3}
            />
            <PointsInput
              title='conclusion'
              placeholder='Conclusion of this blog'
              onChangeSubTitle={(e) => setSubtitleConclusion(e.target.value)}
              onChange={(e) => setDescriptionConclusion(e.target.value)}
              valueSubtitle={subtitleConclusion}
              value={descriptionConclusion}
            />

            {/* {addParagraph.map((p) => (
              <div key={`${p.id}`} style={{ width: '100%' }}>
                {p.para}
              </div>
            ))} */}

            <p>
              <button className='btn addBlogBtn' type='submit'>
                <FontAwesomeIcon
                  style={{ fontSize: 22, marginRight: 5 }}
                  className='icon'
                  icon={faPlus}
                />
                Post A Story
              </button>

              {error && (
                <p
                  style={{ textAlign: 'center', color: 'red', opacity: '0.8' }}
                >
                  {error}
                </p>
              )}
            </p>
          </form>
          {/* <button
            data-tip='Add a body paragraph'
            className='btn btn-light addBlogPlus'
            onClick={addParagraphs}
            type='reset'
          >
            <FontAwesomeIcon className='iconss' icon={faPlus} />
          </button>
          {addParagraph.length > 1 && (
            <button
              data-tip='Remove a body paragraph'
              className='btn btn-light addStoryMinus'
              type='reset'
              disabled={loading}
              onClick={removeParagraphs}
            >
              <FontAwesomeIcon className='iconsss' icon={faMinus} />
            </button>
          )} */}
        </div>
        <ReactTooltip effect='solid' />
      </div>
      <ScrollToTop smooth />
    </>
  )
}

export default BlogAddStory
