import React from 'react'

import { useTranslation } from 'react-i18next'

import './listingDetails.css'

const Description = ({ description }) => {
  const { t } = useTranslation()

  return (
    <div className='propDetDescriptionCon'>
      <h6>{t('Description/Overview')}</h6>
      <div className='card'>
        {description.slice(0, 700)}
        {/* <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '10px',
            color: '#035aa6',
            padding: '0',
          }}
        >
          <p>{t('Translate_to')}:</p> */}
        {/* <select
            style={{
              fontSize: '10px',
              width: '12%',
              padding: '0',
              borderRadius: '10px',
              height: '20px',
              cursor: 'pointer',
            }}
            value={lang}
            onChange={onChangeLang}
          >
            <option value='en'>{t('English')}</option>
            <option value='fr'>{t('French')}</option>
            <option value='ke'>{t('Swahili')}</option>
            <option value='sa'>{t('Arabic')}</option>
          </select> */}
        {/* </div> */}
      </div>
    </div>
  )
}

export default Description
