import React from "react";
import ReactTooltip from "react-tooltip";

import User from "../../iconComponent/User";
import "../listingDetails/listingDetails.css";
import PriceTag from "../listingDetails/PriceTag";
import { Chip, Tooltip } from "@mui/material";

const TitlePriceLeaseSign = ({
  verified = false,
  title,
  price,
  rentOrSell,
  listing,
}) => {
  //function to add commas in money

  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  return (
    <div className="listingDetailTitleCon1">
      <div style={{ flex: "2", display: "flex", flexDirection: "row" }}>
        {verified && (
          <User
            data-tip="Verified"
            className="DetailsUserIcon theIconforVerified"
          />
        )}
        <div className={"titleSpan"}>{title}</div>
      </div>
      <div
        style={{
          width: "250px",
          display: "flex",
        }}
        className="pricePerMonthSpan1"
      >
        <div style={{ flex: "1" }} className="priceSpan">
          <span>
            {commaSeparateNumber(price).length > 12
              ? commaSeparateNumber(price).slice(0, 12) + ".."
              : commaSeparateNumber(price)}
            {listing && listing.currency}
          </span>
          <span>
            {rentOrSell === "Rental" && <span className="monthSpan">/mo</span>}
          </span>
        </div>
        <div style={{ right: "0px", marginTop: "0" }}>
          <PriceTag />
        </div>
      </div>
      <ReactTooltip effect="solid" />
    </div>
  );
};

export default TitlePriceLeaseSign;
