import logger from '../Services/loggerService'

function translateLang(YOUR_API_KEY, text, fromLang, toLang) {
  const API_KEY = [YOUR_API_KEY]

  let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`
  url += '&q=' + encodeURI(text)
  url += `&source=${fromLang}`
  url += `&target=${toLang}`

  fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then((res) => res.json())
    .then((response) => {
      return response
    })
    .catch((error) => {
      logger.log(error)
    })
}

export default translateLang
