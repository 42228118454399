import React from 'react'

import Chat from '../../iconComponent/Chat'
import './addListing.css'


const Chats = ({chats}) => {
return (
<div className="addListChatCon">
<div className="badge">{chats}</div>
<Chat
height="50px"
width="50px"
/>                                                        
</div>
)
}

export default Chats
