import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'

const DepartmentContainer = ({
  title,
  icon,
  statement,
  src = '../../images/it-team.jpg',
}) => {
  useEffect(() => {
    AOS.init({ duration: 1000 })
  }, [])
  return (
    <div className='crewDDepartmentOuterCon'>
      <div className='crewDepInnerCon'>
        <div data-aos='fade-left' className='crewDepContent'>
          {icon}
          <p>
            <b className='title'>{title}</b>
          </p>
          <p className='crewDepStatement'>{statement}</p>
        </div>
        <div className='crewDepImageCon' data-aos='fade-right'>
          <img style={{ width: '100%', height: '100%' }} src={src} />
        </div>
      </div>
    </div>
  )
}

export default DepartmentContainer
