import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const Internship = () => {
  useEffect(() => {
    AOS.init({ duration: 4000 });
  }, []);
  return (
    <div className="careerInternshipCon">
      <div className="careerInternshipInnerCon">
        <h3 data-aos="flip-up">INTERNSHIPS</h3>
        <p>
          As Intern in Captivator Technologies inc., you will be given the
          opportunity to build new projects that can create positive change for
          society and will have the advantage of working together with experts
          in your field of training.Internship at Captivator Technologies inc.
          Internships are designed to give students real world experience that
          can help them land their dream job when they graduate college. Apply
          Now.
        </p>
      </div>

      <img
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        src="../../images/intern.jpeg"
      />
    </div>
  );
};

export default Internship;
