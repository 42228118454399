export function myDate(){
let currentDate = new Date();
let cDay = currentDate.getDate()
let cMonth = currentDate.getMonth() + 1
let cYear = currentDate.getFullYear()

return {dayAndMonth:`${cDay}/${cMonth}`, year:`${cYear}`}
}

export function myDateTransform(date){
              
let cDay = date.slice(8,10)
let cMonth = date.slice(5,7)
let cYear = date.slice(0,4)
              
return `${cDay}/${cMonth}/${cYear}`
}


export function myDateToSort(date){

const years=date.slice(0,4)
const month=date.slice(5,7)
const day=date.slice(8,10)

return `${day}/${month}/${years}`
}