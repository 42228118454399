import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import DoneAnimate from './DoneAnimate'

const Paid = ({ onClickPaid, paid }) => {
  return (
    <div className='finallyMadePayment'>
          <div onClick={onClickPaid} className='theIconContainer'>
            <DoneAnimate />
          </div>
    </div>
  )
}

export default Paid
