import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWrench,
  faPenToSquare,
  faTrashCan,
  faShower,
  faGlasses,
  faChartLine,
  faDoorOpen,
  faInfo,
  faCircleExclamation,
  faFeather,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const BlogDisplayContent = ({
  id,
  blogType,
  blogTitle,
  blogPoster,
  blogDatePosted,
  blogContent,
  onClickDelete,
  onClickEdit,
  isadmin = false,
}) => {
  return (
    <div className="blogDisplayContentCon">
      <div style={{ display: "flex" }}>
        {blogType === "Home Improvement" ? (
          <FontAwesomeIcon
            style={{
              color: "#035aa6",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            icon={faWrench}
          />
        ) : blogType === "Home Hygiene" ? (
          <FontAwesomeIcon
            style={{
              color: "#035aa6",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            icon={faShower}
          />
        ) : blogType === "Tips & Advices" ? (
          <FontAwesomeIcon
            style={{
              color: "#035aa6",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            icon={faGlasses}
          />
        ) : blogType === "Market Trends" ? (
          <FontAwesomeIcon
            style={{
              color: "#035aa6",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            icon={faChartLine}
          />
        ) : blogType === "Job opportunities" ? (
          <FontAwesomeIcon
            style={{
              color: "#035aa6",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            icon={faDoorOpen}
          />
        ) : blogType === "CT80R Update" ? (
          <FontAwesomeIcon
            style={{
              color: "#035aa6",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            icon={faInfo}
          />
        ) : blogType === "Scammer Alert" ? (
          <FontAwesomeIcon
            style={{
              color: "#035aa6",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            icon={faCircleExclamation}
          />
        ) : (
          <FontAwesomeIcon
            style={{
              color: "#035aa6",
              fontSize: "16px",
              marginBottom: "20px",
            }}
            icon={faFeather}
          />
        )}
        <span className="blogDisplayContentBlogType">{blogType}</span>
        {isadmin && (
          <>
            <span style={{ margin: "0 5px" }}>/</span>
            <button
              style={{
                padding: "0 5px",
                marginTop: "-15px",
                borderRadius: "10px",
              }}
              className="btn btn-light"
              onClick={() => onClickEdit()}
            >
              {/* <span style={{ marginLeft: '5px' }}> */}{" "}
              <FontAwesomeIcon
                style={{
                  color: "grey",
                  fontSize: "16px",
                  // marginBottom: '20px',
                  cursor: "pointer",
                }}
                icon={faPenToSquare}
              />
              {/* </span> */}
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  color: "grey",
                  marginLeft: "5px",
                }}
              >
                {" "}
                EDIT STORY
              </span>
            </button>
            <span style={{ margin: "0 5px" }}>/</span>
          </>
        )}
        <span style={{ margin: "0 5px" }}>
          {isadmin && (
            <button
              style={{ marginTop: "-10px", borderRadius: "100%" }}
              className="btn btn-light"
              onClick={onClickDelete}
            >
              <FontAwesomeIcon
                style={{
                  color: "red",
                  opacity: "0.8",
                  fontSize: "16px",
                  // marginBottom: '20px',
                  cursor: "pointer",
                }}
                icon={faTrashCan}
              />
            </button>
          )}
        </span>
      </div>
      <Link to={`/blog/${id}`} className="blogTitleLink">
        <h3
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            lineClamp: 2,
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {blogTitle}
        </h3>
      </Link>
      <div style={{ display: "flex" }}>
        <span>BY</span>
        <span
          style={{
            color: "#035aa6",
            marginLeft: "5px",
            fontWeight: "bold",
          }}
        >
          {blogPoster}
        </span>
        <span style={{ marginLeft: "5px" }}>ON</span>
        <span
          style={{
            color: "#035aa6",
            marginLeft: "5px",
            fontWeight: "bold",
          }}
        >
          {blogDatePosted}
        </span>
      </div>

      <div
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          lineClamp: 4,
          WebkitLineClamp: 4,
          WebkitBoxOrient: "vertical",
        }}
        className="blogContentContents"
      >
        {blogContent}
      </div>
    </div>
  );
};

export default BlogDisplayContent;
