import React from 'react'
import 'simplebar'
import 'simplebar/dist/simplebar.css'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { getCurrentUser } from '../../Services/authService'

import './messages.css'

const MessagesTable = ({
  messages,
  onDelete,
  path,
  loadingSearchMessage,
  onClickMessage,
}) => {
  const { t } = useTranslation()

  const removeStartConvo = (sender_id) => {
    const allTheUserMessage = messages.filter(
      (msg) => msg.sender_id === sender_id
    )
    return {
      startDate: allTheUserMessage[0].create_date.slice(0, 10),
      lastContact: allTheUserMessage[allTheUserMessage.length - 2]
        ? allTheUserMessage[allTheUserMessage.length - 2].create_date.slice(
            0,
            10
          )
        : allTheUserMessage[0].create_date.slice(0, 10),
    }
  }

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase()
    })
  }


  const letsCreateDate = (data) => {
    return `${data.slice(8, 10)}/${data.slice(5, 7)}/${data.slice(0, 4)}`
  }

  return (
    <>
      <div
        data-simplebar
        className='messageTableContainer visibleForLargeScreen'
      >
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
                className='myListTh' scope='col'>
                {t('Sender')}
              </th>
              <th
              className='myListTh'
              scope='col'
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t('Listing_Name')}
              </th>
              <th
              className='myListTh'
              scope='col'
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t('Message')}
              </th>
              <th
              className='myListTh'
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              scope='col'
              >
                {t('Conv_Start_Date')}
              </th>
              {/* <th className='myListTh' scope='col'>
                {t('Last_Contact_Date')}
              </th> */}
              <th
              className='myListTh'
              scope='col'
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis"
                }}
              >
                {t('Action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {messages &&
              messages.map((message) => (
                <tr key={message.id} className='table-column'>
                  <th className={message.visited ? 'myListTd' : 'myListTd1'}>
                    <Link
                      onClick={() => onClickMessage(message.id)}
                      className='removeTheUnderLine'
                      to={
                        getCurrentUser().post_rent_property ===
                        'LOOKING TO BUY/RENT PROPERTY'
                          ? `/portal/tenants/message/${message.id}`
                          : path === '/management/message'
                          ? `/management/message/${message.id}`
                          : `/portal/messages/message/${message.id}`
                      }
                    >
                      {capitalize(message.sender_name.length > 15
                        ? message.sender_name.slice(0, 13) + '..'
                        : message.sender_name.slice(0, 15))}
                    </Link>
                  </th>
                  <td className={message.visited ? 'myListTd' : 'myListTd1'}>
                    <Link
                      onClick={() => onClickMessage(message.id)}
                      className='removeTheUnderLine'
                      to={
                        getCurrentUser().post_rent_property ===
                        'LOOKING TO BUY/RENT PROPERTY'
                          ? `/portal/tenants/message/${message.id}`
                          : path === '/management/message'
                          ? `/management/message/${message.id}`
                          : `/portal/messages/message/${message.id}`
                      }
                    >
                      {capitalize(message.listing_name)}
                    </Link>
                  </td>
                  <td className={message.visited ? 'myListTd' : 'myListTd1'}>
                    <Link
                      onClick={() => onClickMessage(message.id)}
                      className='removeTheUnderLine'
                      to={
                        getCurrentUser().post_rent_property ===
                        'LOOKING TO BUY/RENT PROPERTY'
                          ? `/portal/tenants/message/${message.id}`
                          : path === '/management/message'
                          ? `/management/message/${message.id}`
                          : `/portal/messages/message/${message.id}`
                      }
                    >
                      {message.body.length > 35
                        ? message.body.slice(0, 35) + '..'
                        : message.body}
                    </Link>
                  </td>
                  <td className={message.visited ? 'myListTd' : 'myListTd1'}>
                    <Link
                      onClick={() => onClickMessage(message.id)}
                      className='removeTheUnderLine'
                      to={
                        getCurrentUser().post_rent_property ===
                        'LOOKING TO BUY/RENT PROPERTY'
                          ? `/portal/tenants/message/${message.id}`
                          : path === '/management/message'
                          ? `/management/message/${message.id}`
                          : `/portal/messages/message/${message.id}`
                      }
                    >
                      {letsCreateDate(message.create_date)}
                    </Link>
                  </td>
                  {/* <td className={message.visited ? 'myListTd' : 'myListTd1'}>
                    <Link
                      onClick={() => onClickMessage(message.id)}
                      className='removeTheUnderLine'
                      to={
                        getCurrentUser().post_rent_property ===
                        'LOOKING TO BUY/RENT PROPERTY'
                          ? `/portal/tenants/message/${message.id}`
                          : path === '/management/message'
                          ? `/management/message/${message.id}`
                          : `/portal/messages/message/${message.id}`
                      }
                    >
                      {removeStartConvo(message.sender_id).lastContact}
                    </Link>
                  </td> */}
                  <td className='approveUnapproveCon'>
                    <div className='approveUnapproveIconContainer'>
                      <button
                      onClick={() => onDelete(message)}
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis"
                        }}
                      >
                        <span style={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                        {t('Delete')}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            {loadingSearchMessage && <div>{t('....loading')}</div>}
          </tbody>
        </table>
      </div>

      <div data-simplebar className='messageTableContainer visibleForMobile'>
        <table className='table table-striped'>
          <thead className='myListThead'>
            <tr>
              <th className='myListTh' scope='col'>
                {t('Sender')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Listing_Name')}
              </th>
              <th className='myListTh' scope='col'>
                {t('Action')}
              </th>
            </tr>
          </thead>
          <tbody>
            {messages &&
              messages.map((message) => (
                <tr key={`${message.id}`} className='table-column'>
                  <th className={message.visited ? 'myListTd' : 'myListTd1'}>
                    <Link
                      onClick={() => onClickMessage(message.id)}
                      className='removeTheUnderLine'
                      to={
                        getCurrentUser().post_rent_property ===
                        'LOOKING TO BUY/RENT PROPERTY'
                          ? `/portal/tenants/message/${message.id}`
                          : path === '/management/message'
                          ? `/management/message/${message.id}`
                          : `/portal/messages/message/${message.id}`
                      }
                    >
                      {message.sender_name.length > 15
                        ? message.sender_name.slice(0, 13) + '..'
                        : message.sender_name.slice(0, 15)}
                    </Link>
                  </th>
                  <td className={message.visited ? 'myListTd' : 'myListTd1'}>
                    <Link
                      onClick={() => onClickMessage(message.id)}
                      className='removeTheUnderLine'
                      to={
                        getCurrentUser().post_rent_property ===
                        'LOOKING TO BUY/RENT PROPERTY'
                          ? `/portal/tenants/message/${message.id}`
                          : path === '/management/message'
                          ? `/management/message/${message.id}`
                          : `/portal/messages/message/${message.id}`
                      }
                    >
                      {message.listing_name}
                    </Link>
                  </td>
                  <td className='approveUnapproveCon'>
                    <div className='approveUnapproveIconContainer'>
                      <button onClick={() => onDelete(message)}>
                        <span style={{ marginRight: '5px' }}>
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </span>
                        {t('Delete')}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            {loadingSearchMessage && <div>{t('....loading')}</div>}
          </tbody>
        </table>
      </div>
    </>
  )
}

export default MessagesTable
