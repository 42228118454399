import React from 'react'
import Lottie from 'react-lottie'
import { leftArrowAnimate } from './left-arrow'

const LeftArrow = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: leftArrowAnimate(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={20} width={20} />
    </div>
  )
}

export default LeftArrow
