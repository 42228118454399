import React from 'react'
import Lottie from 'react-lottie'
import { houseAnimation } from './hoouse-animate'

const HouseAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: houseAnimation(),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div>
      <Lottie options={defaultOptions} height={100} width={100} />
    </div>
  )
}

export default HouseAnimation
